import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// // Depending on whether rollup is used, moment needs to be imported differently.
// // Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// // syntax. However, rollup creates a synthetic default module and we thus need to import it using
// // the `default as` syntax.
// import * as _moment from 'moment';
// // tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatInputModule } from '@angular/material/input';
// import { MatFormFieldModule } from '@angular/material/form-field';

// const moment = _rollupMoment || _moment;

// @Component({
//   selector: 'app-date-input',
//   templateUrl: './date-input.component.html',
//   styleUrls: ['./date-input.component.scss'],
// })
// export class DateInputComponent implements OnInit {
//   date = new FormControl(moment());

import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputResult } from '../table-dialog/table-dialog.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.yyyy',
  },
  display: {
    dateInput: 'DD.MM.yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/** @title Datepicker with custom formats */
@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateInputComponent {
  public defaultFromDate: Date = new Date();
  public defaultToDate: Date = new Date();

  fromDateControl = new UntypedFormControl(moment(this.defaultFromDate).toDate());
  toDateControl = new UntypedFormControl(moment(this.defaultToDate).toDate());

  @Input() data: any;
  private activeFromTouched: boolean = false;
  public activeFromValue: Date;
  public activeFromPlaceHolder: string = '';
  public activeFromLabel: string = '';
  public suffixFromLabel = '';
  public activeFromValueString = '';

  private activeToTouched: boolean = false;
  public activeToValue: Date;
  public activeToPlaceHolder: string = '';
  public activeToLabel: string = '';
  public activeToValueString = '';
  public suffixToLabel = '';
  public validation: Timespan[] = [];
  public showErrorFromField: boolean = false;
  public showErrorToField: boolean = false;
  @Output() changes: EventEmitter<InputResult> = new EventEmitter();

  selectedDate: Date;
  showDatePicker: boolean = false;

  openDatePicker() {
    this.showDatePicker = true;
  }

  closeDatePicker() {
    this.showDatePicker = false;
  }

  constructor() {}
  ngOnInit(): void {
    const element = this.data['element'];
    if (element && element['validation']) {
      this.validation = element['validation'];
    }

    if (element && element['value']) {
      this.defaultFromDate = element['value']['fromDate'];
      this.defaultToDate = element['value']['toDate'];
      this.activeFromValue = this.defaultFromDate;
      this.activeToValue = this.defaultToDate;

      //TODO test if this logic can be removed
      if (this.inputIsInvalid()) {
        this.changes.emit({
          value: {
            from: this.activeFromValue,
            to: this.activeToValue,
          } as Timespan,
          valid: true,
        });
      }
      //

      this.fromDateControl = new UntypedFormControl(
        moment(this.defaultFromDate).toDate()
      );
      this.toDateControl = new UntypedFormControl(moment(this.defaultToDate).toDate());
    }
  }

  public wasTouched() {
    this.activeToTouched = true;
    this.activeFromTouched = true;
  }

  private dateMatchLicenseDates(): boolean {
    for (let timespan of this.validation) {
      if (!this.areDatesOutsideTimespan(timespan.from, timespan.to)) {
        return false;
      }
    }
    return true;
  }

  private areDatesOutsideTimespan(
    timespanFrom: Date,
    timespanTo: Date
  ): boolean {
    if (
      this.activeToValue < timespanFrom &&
      this.activeFromValue < timespanFrom
    ) {
      return true;
    }

    if (this.activeFromValue > timespanTo && this.activeToValue > timespanTo) {
      return true;
    }
    return false;
  }

  public inputIsInvalid(): boolean {
    const inputValid =
      (this.activeFromValue !== undefined &&
        this.activeToValue !== undefined &&
        this.activeFromValue < this.activeToValue &&
        this.activeFromTouched) ||
      this.activeToTouched;

    if (!inputValid) {
      return false;
    }
    if (!this.dateMatchLicenseDates()) {
      //show error message
      this.showErrorFromField = true;
      return false;
    }
    return true;
  }

  onInputChange(element, isFrom: boolean = false) {
    const value = element['value'];
    const other: Date = value['_d'];
    const date: Date = new Date(
      value['_i']['year'],
      value['_i']['month'],
      value['_i']['date']
    );
    if (isFrom) {
      this.activeFromTouched = true;
      this.activeFromValue = date;
    } else {
      this.activeToTouched = true;
      this.activeToValue = date;
    }
    if (this.inputIsInvalid()) {
      this.changes.emit({
        value: {
          from: this.activeFromValue,
          to: this.activeToValue,
        } as Timespan,
        valid: true,
      });
    }
  }
}

export interface Timespan {
  from: Date;
  to: Date;
}
