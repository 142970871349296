import { Component, OnInit } from '@angular/core';
// import SwaggerUI from 'swagger-ui';
import { SwaggerUIBundle as SwaggerUI } from 'swagger-ui-dist';
import { DocumentationHttpService } from '../../services/http/documentation-http.service';

@Component({
  selector: 'app-api-documentation',
  templateUrl: './api-documentation.component.html',
  styleUrls: ['./api-documentation.component.scss'],
})
export class ApiDocumentationComponent implements OnInit {
  public static get route(): string {
    return 'documentation';
  }

  constructor(private documentationHttpService: DocumentationHttpService) {}
  ngOnInit(): void {
    this.documentationHttpService.getSwaggerDocumentation().subscribe((doc) => {
      SwaggerUI({
        spec: doc,
        dom_id: '#swagger-ui-container',
        theme: 'swagger',
      });
    });
  }
}
