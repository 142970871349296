<!-- <app-dashboard-title img="assets/svg/Logotype.png"></app-dashboard-title> -->

<!-- <div class="body-wrapper">
  <p-tabView class="full-width" (onChange)="onChange($event)" [(activeIndex)]="selectedTabIndex">
    <p-tabPanel header="Overview">
      <ng-template pTemplate="content">
        <div class="tab-content">
          <app-overview></app-overview>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Trends">
      <ng-template pTemplate="content">
        <div class="tab-content">
          <app-trends></app-trends>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div> -->
<router-outlet></router-outlet>