import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardTitleComponent } from './dashboard-title.component';

@NgModule({
  declarations: [DashboardTitleComponent],
  exports: [
    DashboardTitleComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class DashboardTitleModule { }
