import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-text-input',
  templateUrl: './icon-text-input.component.html',
  styleUrls: ['./icon-text-input.component.scss'],
})
export class IconTextInputComponent implements OnInit {
  @Input() inputPlaceHolder: string = '';
  @Input() iconSource: string = '';

  public text: string = '';
  private touched: boolean = false;

  @Output() changes: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public clearText() {
    this.text = '';
    this.changes.emit('');
  }

  public inputIsInvalid(): boolean {
    return this.text.length == 0 && this.touched;
  }

  onInputChange(event) {
    this.touched = true;
    this.changes.emit(event);
  }
}
