<div class="select-input dialog-component">
    <label *ngIf="inputLabel">{{inputLabel | translate }}</label>
    <div class="custom-dropdown dashboard-input">
        <div class="input" (click)="toggleDropdown()">
            <input [placeholder]="'usersRoles.table.dialog.role.placeholder' | translate" [(ngModel)]="selectedValue"
                type="text" readonly>
            <button class="dropdown-toggle"><img class="drop-down-arrow" [class.open]="showDropdown"
                    src="/assets/images/shared/drop-down-arrow.svg"></button>
        </div>
        <ul class="dropdown-list" [hidden]="!showDropdown">
            <li *ngFor="let option of dropDownOptions" (click)="selectOption(getTranslatedRole(option))">
                {{'usersRoles.table.dialog.role.'+ option | translate}}
            </li>
        </ul>
    </div>
</div>