import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private authService2: MsalService
  ) {}

  private parseHeadersAndExecute(
    token: string,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
        'Ocp-Apim-Subscription-Key': environment.apimKey,
        // Authorization: `Bearer ${token}`,
      }),
    });
    return next.handle(request);
    // .pipe(
    //   catchError((error) => {
    //     if (error.status === 401) {
    //       return this.authService.refresh().pipe(
    //         switchMap(() => {
    //           var idToken = localStorage.getItem('id-token');
    //           const authenticationRequest = request.clone({
    //             headers: new HttpHeaders({
    //               'Content-Type': 'application/json',
    //               'Access-Control-Allow-Origin': '*',
    //               'Access-Control-Allow-Methods':
    //                 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
    //               'Ocp-Apim-Subscription-Key': environment.apimKey,
    //               // Authorization: `Bearer ${idToken}`,
    //             }),
    //           });
    //           return next.handle(authenticationRequest);
    //         })
    //       );
    //     }
    //     return throwError(error);
    //   })
    // );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.parseHeadersAndExecute(
      localStorage.getItem('id-token')!,
      request,
      next
    );
  }
}
