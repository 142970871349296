import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { ReportGroupedDto } from '../../models/report';
import { ReportGroupedFilter } from '../../models/report-grouped-filter';
import { TokenUtil } from '../../utils/token-util';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ReportHttpService {
  private apiService: ApiService = inject(ApiService);

  getGrouped(filter: ReportGroupedFilter): Observable<ReportGroupedDto[]> {
    var token = localStorage.getItem('id-token');

    var tenantId = TokenUtil.getTenantFromToken(token);
    return this.apiService
      .get({
        path: `${
          APIConst.REPORTS_GROUPED +
          '/' +
          tenantId +
          this.apiService.requestParamsObj(filter)
        }`,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map((reports) => {
          return reports['reports'].map((report) => {
            return {
              weekday: report.weekday,

              month: report.month,

              distance: report.distance,

              time: report.duration,

              transportType: report['transportType'] ?? undefined,

              autoOptinCount: report.autoOptinFlag ? report.autoOptinFlag : 0,

              reportCount: report['reportCount'],

              emissions: report.emissions,

              reason: report.reason,

              office: report.office,

              company: report.company,
            };
          });
        })
      );
  }
}
