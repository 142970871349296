import { Pipe, PipeTransform } from '@angular/core';
import { ReportGrouped } from '../models/report';

@Pipe({
  name: 'sumDistance',
})
export class SumDistancePipe implements PipeTransform {
  constructor() {
  }

  transform(reports: ReportGrouped[]): number {
    return reports ? reports.reduce((acc, report) => acc + (report.distance ? report.distance : 0), 0) : 0;
  }
}
