import { Component, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  selectedTabIndex: number = 0;

  public static get route() {
    return 'dashboard';
  }

  public static get demoRoute() {
    return `${this.route}/demo`;
  }

  constructor(private router: Router, private route: ActivatedRoute) {}

  onChange(event) {
    this.selectedTabIndex = event.index;
    this.router.navigate([event.index === 0 ? 'overview' : 'trends'], {
      relativeTo: this.route,
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.selectedTabIndex = this.selectedTabIndex === 0 ? 1 : 0;
  }
}
