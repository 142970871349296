export enum APIConst {
  REPORTS = '/reports',
  REPORTS_GROUPED = '/grouped-reports',
  GENERATED_DEMO_REPORTS = '/getGenerateDemoReports',
  OFFICE = '/office',
  SUPERVISOR_OFFICE = '/supervisor/office',

  TENANT_SURVEYS = '/tenantSurveys',
  SURVEY = '/survey', //GET, POST, PUT
  SURVEYS = '/surveys',
  CREATE_SURVEY = '/create-survey',
  SURVEY_RESPONSE = '/surveyResponse',
  SURVEY_RESPONSE_RESULT = '/surveyResponseResult',

  COMPANY = '/company',
  SUPERVISOR_COMPANY = '/supervisor/company',
  GET_ENVIRONMENT_TENANTS = '/supervisor/tenant',
  GET_TENANTS = '/supervisor/tenants',
  GET_SINGLE_TENANT = '/tenant',
  SUPERVISOR_TENANT = '/supervisor/tenant',
  DELETE_USER = '/supervisor/users/',
  UPDATE_USER = '/supervisor/user-profile',
  UPDATE_OWNER_APP_ROLE = '/supervisor/user-app-role',
  UPDATE_USER_APP_ROLE = '/manage/user-app-role',
  INVITE_USER = '/manage/tenant-invite',
  INVITE_OWNER = '/supervisor/tenant-invite',
  GET_ACTIVATIONCODES = '/supervisor/activationcodes',
  CHANGE_ACTIVATIONCODES = '/supervisor/activationcodes',
  LICENSES = '/supervisor/licenses',
  GET_BRANDING = '/supervisor/branding',
  PUT_BRANDING = '/supervisor/branding',
  ADD_ACTIVATIONCODES = '/supervisor/activationcodes',
  GET_EMISSIONS = '/Emission-factors',
  POST_EMISSION = '/supervisor/Emission-factors',
  SUPERVISOR_APP_INFO = '/supervisor/app-info',
  DASHBOARD_INFO = '/dashboard-info',
  DOCUMENTATION = '/documentation',
  TENANT_LOGO = '/tenant-logo',
  ACTIVATION_CODE_RESET = '/supervisor/activationcode-reset',
  CREATE_TENANT = '/supervisor/tenant-create',
}
