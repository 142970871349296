<scale-modal
    [heading]="previewMode  === 'Individual Response Preview' ? ('feedbacks.title.surveys.IndividualResponsePreview'|translate) : ('feedbacks.title.surveys.IndividualResponsePreview'|translate) "
    id="modal" class="scale-modal-item" [opened]="showModal" (scaleClose)="closeModal()">
    <h2 *ngIf="survey.title.get(currentLang)">{{survey.title.get(currentLang)}}</h2>
    <div *ngIf="previewMode === 'Individual Response Preview'">
        <div class="company-wrapper margin-top-sm margin-bottom-xs">
            <p class="bold ">{{'feedbacks.title.surveys.company'|translate}}: </p>
            <p class="margin-left" *ngIf="survey.companyNames">{{ survey.companyNames.length ? currentCompany.name :
                'feedbacks.title.surveys.empty'|translate }}</p>
        </div>
        <!-- <div class="office-wrapper">
            <p class="bold ">{{'feedbacks.title.surveys.office'|translate}}: </p>
            <p class="margin-left " *ngIf="currentOffice">{{ currentOffice ? (currentOffice.street + ', ' +
                currentOffice.zip + ', ' +
                currentOffice.city + ', ' + currentOffice.countryCode): 'feedbacks.title.surveys.empty'|translate }}</p>
        </div> -->
        <div class="platform-container p-grid no-padding-up-down" *ngIf="currentResponse">
            <div class="platform-wrapper p-col-4 no-padding-up-down">
                <p class="bold ">{{'feedbacks.title.surveys.platform'|translate}}:</p>
                <p class="title margin-left ">{{currentResponse.app.platform}}</p>
            </div>
            <div class="platform-wrapper  p-col-4 no-padding-up-down">
                <p class="bold ">{{'feedbacks.title.surveys.Language'|translate}}:</p>
                <p class="title margin-left p">{{currentResponse.app.language}}</p>
            </div>
            <div class="platform-wrapper  p-col-4 no-padding-up-down">
                <p class="bold">{{'feedbacks.title.surveys.appVersion'|translate}}:</p>
                <p class="title margin-left ">{{currentResponse.app.version}}</p>
            </div>
        </div>
        <div  class="platform-wrapper" >
            <p class="bold" >{{'feedbacks.title.surveys.from'|translate}}: 
            </p>
            <p class="title margin-left"> {{currentResponse.created_at | date: 'dd.MM.yyyy hh:mm'}}
            </p>
        </div>
    </div>

    <!-- <div *ngIf="previewMode === 'Survey Preview'"> -->
    <div>
        <p *ngIf="survey.description.get(currentLang)" class="description-item">{{survey.description.get(currentLang)}}
        <div *ngIf="previewMode === 'Survey Preview'" class="company-wrapper margin-top-sm">
            <p class="bold ">{{'feedbacks.title.surveys.company'|translate}}: </p>
            <p class="margin-left" *ngIf="survey.companyNames">{{ survey.companyNames }}</p>
        </div>

        <p class="mandatory-field">{{'feedbacks.title.surveys.mandatoryField'|translate}}</p>

        <div *ngFor="let page of survey.pages; let i = index">
            <p class="page-header bold margin-top">
                {{'feedbacks.title.surveys.step'|translate}} {{ i + 1 }}/{{ survey.pages.length }}
            </p>
            <p class="page-description margin-top-sm">{{ page.section.get(currentLang) }}</p>
            <div *ngFor="let input of page.inputs; let j = index">

                <div *ngIf="input.type === 'rating'">
                    <!--properties for type 'rating'  -->
                    <div *ngIf="input.options.style === 'points'; else stars" class=" margin-top" tabindex="-1">
                        <scale-slider show-step-marks label="{{  isMap(input.label) ? input.label.get(currentLang) : input.label[currentLang]}} {{input.required ? '*' :
                            ''}}" step="1" class="scale-slider-item margin-top"
                            [ngClass]="{'disabled-item': previewMode === 'Individual Response Preview'}"
                            [max]="input.options.count" [value]="valueHandler(input) ? valueHandler(input) : 0 "
                            decimals="2">
                        </scale-slider>
                        <span class="rating-labels">
                            <p class="rating-label-min ">{{ isMap(input.options.labels) ?
                                input.options.labels.get('min').get(this.currentLang) :
                                input.options.labels['min'][this.currentLang]}}</p>
                            <p class="rating-label-max ">{{ isMap(input.options.labels) ?
                                input.options.labels.get('max').get(this.currentLang) :
                                input.options.labels['max'][this.currentLang]}}</p>
                        </span>
                    </div>
                    <ng-template #stars>
                        <div class="rating-stars-wrapper margin-top" tabindex="-1">
                            <label class="bold">{{ input.label.get(currentLang)}} {{input.required ? '*' :
                                ''}}</label>
                            <scale-rating-stars class="rating-stars-item"
                                [ngClass]="{'disabled-item': previewMode === 'Individual Response Preview','rating-star-padding-small': input.options.count == '10','rating-star-padding-big': input.options.count == '5'}"
                                size="large" *ngIf="input.options.labels" [rating]="valueHandler(input)" label=""
                                [max]="input.options.count">
                            </scale-rating-stars>
                            <span class="rating-labels">
                                <p class="rating-label-min ">{{ isMap(input.options.labels) ?
                                    input.options.labels.get('min').get(this.currentLang) :
                                    input.options.labels['min'][this.currentLang]}}</p>
                                <p class="rating-label-max ">{{ isMap(input.options.labels) ?
                                    input.options.labels.get('max').get(this.currentLang) :
                                    input.options.labels['max'][this.currentLang]}}</p>
                            </span>
                        </div>
                    </ng-template>

                </div>

                <div *ngIf="input.type === 'select'" class="margin-top">
                    <!--properties for type 'select'  -->
                    <div *ngIf="input.options.style === 'radiobutton-group'; else checkboxGroup" class="select-wrapper">
                        <label class="bold">{{ input.label.get(currentLang)}} {{input.required ? '*' :
                            ''}}
                        </label>
                        <scale-radio-button-group class="margin-top-sm"
                            [ngClass]="{'disabled-item': previewMode === 'Individual Response Preview'}">
                            <div *ngFor="let item of input.options.values">
                                <scale-radio-button class="scale-radio-button-item" checked
                                    label="{{ isMap(item.value) ?  item.value.get(this.currentLang) : item.value[this.currentLang] }}"
                                    name="standard">
                                </scale-radio-button>
                            </div>
                        </scale-radio-button-group>
                    </div>
                    <ng-template #checkboxGroup>
                        <label class="bold">
                            {{ input.label.get(currentLang)}} {{input.required ? '*' :''}}
                        </label>
                        <div *ngFor="let item of input.options.values" class="margin-top-sm scale-checkbox-group-item ">
                            <scale-checkbox class="scale-checkbox-item"
                                [ngClass]="{'disabled-item': previewMode === 'Individual Response Preview'}"
                                [checked]="valueHandler(input).includes(item.key)"
                                label="{{ isMap(item.value) ?  item.value.get(this.currentLang) : item.value[this.currentLang] }}"
                                name="standard">
                            </scale-checkbox>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="input.type === 'text'" class="margin-top">
                    <label class="bold">{{ input.label.get(currentLang)}} {{input.required ? '*' :
                        ''}}</label>
                    <scale-textarea rows="3" resize="vertical" [attr.value]="valueHandler(input)"
                        [ngClass]="{'disabled-item': previewMode === 'Individual Response Preview'}"
                        class="scale-textarea-item"></scale-textarea>
                </div>

            </div>
        </div>

    </div>

    <scale-pagination *ngIf="previewMode === 'Individual Response Preview'" page-size="1" class="scale-pagination-item"
        slot="action" start-element="0" [attr.total-elements]="totalPages" (scale-pagination)="onPageChange($event)">
    </scale-pagination>

</scale-modal>