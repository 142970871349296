import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Survey } from 'src/app/models/survey/survey';

type SurveyWithRespose = Survey & {
  companyNames: string[];
  numberOfSubmits: number;
};
@Component({
  selector: 'app-survey-tile',
  templateUrl: './survey-tile.component.html',
  styleUrls: ['./survey-tile.component.scss'],
})
export class SurveyTileComponent implements OnInit {
  private translateService: TranslateService = inject(TranslateService);

  showSurveyDetails: Map<string, boolean> = new Map();
  currentLang = null;

  @Input() survey: SurveyWithRespose;
  // @Output() companyNames:string[] = [];
  @Output() openDetailsClicked: EventEmitter<SurveyWithRespose> =
    new EventEmitter<SurveyWithRespose>();

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;

    // // Create an Observable array for each company ID
    // const companyObservables = this.survey.appliedCompanies.map(companyId =>
    //   this.companyService.get(companyId)
    // );
    // forkJoin(companyObservables).subscribe((companies: Company[]) => {
    //     this.companyNames = companies.map(company => company.name);
    //   },
    //   (error) => {
    //     console.error('Error in retrieving company data:', error);
    //   }
    // );
  }

  public openDetails(survey: SurveyWithRespose) {
    // this.surveyService.surveyData
    // this.surveyDetailsService.detailsOpen = true;
    this.openDetailsClicked.emit(survey);
  }

  // public get detailsOpen(): boolean {
  //   return false
  //   // return this.surveyDetailsService.detailsOpen;
  // }
}
