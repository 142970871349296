import { AdminComponent } from 'src/app/pages/admin/admin.component';
import { EmissionFactorsComponent } from 'src/app/pages/admin/emission-factors/emission-factors.component';
import { TenantsComponent } from 'src/app/pages/admin/tenants/tenants.component';
import { FeedbackComponent } from 'src/app/pages/feedback/feedback.component';
import { BrandingComponent } from 'src/app/pages/manage/branding/branding.component';
import { DataComponent } from 'src/app/pages/manage/data/data.component';
import { LicenseComponent } from 'src/app/pages/manage/license/license.component';
import { ManageComponent } from 'src/app/pages/manage/manage.component';
import { UsersRolesComponent } from 'src/app/pages/manage/users-roles/users-roles.component';
import { ApiRoleType } from '../enums/api-roles.enum';
import { MenuEntry } from './../header/menu-entry';

export const ROUTE_CONFIG: MenuEntry[] = [
  {
    displayName: new Map<string, string>([
      ['en', 'Overview'],
      ['de', 'Übersicht'],
    ]),
    routePath: 'overview',
  },
  {
    displayName: new Map<string, string>([
      ['en', 'Trends'],
      ['de', 'Trends'],
    ]),
    routePath: 'trends',
  },
  // {
  //   displayName: new Map<string, string>([
  //     ['en', 'Challenges'],
  //     ['de', 'Challenges'],
  //   ]),
  //   routePath: ChallengesComponent.route,
  // },
  {
    displayName: new Map<string, string>([
      ['en', 'Manage'],
      ['de', 'Manage'],
    ]),
    routePath: ManageComponent.route,
    restrictedTo: [
      ApiRoleType.AdminAccess,
      ApiRoleType.DashboardAdmin,
      ApiRoleType.DashobardOwner,
      ApiRoleType.DashboardAccess,
    ],
    subMenuEntries: [
      {
        displayName: new Map<string, string>([
          ['en', 'Branding'],
          ['de', 'Branding'],
        ]),
        routePath: BrandingComponent.route,
        restrictedTo: [
          ApiRoleType.AdminAccess,
          ApiRoleType.DashboardAdmin,
          ApiRoleType.DashobardOwner,
        ],
      },
      {
        displayName: new Map<string, string>([
          ['en', 'Manage data'],
          ['de', 'Daten verwalten'],
        ]),
        routePath: DataComponent.route,
      },
      {
        displayName: new Map<string, string>([
          ['en', 'Users & Roles'],
          ['de', 'Benutzer & Rollen'],
        ]),
        routePath: UsersRolesComponent.route,
        restrictedTo: [
          ApiRoleType.AdminAccess,
          ApiRoleType.DashboardAdmin,
          ApiRoleType.DashobardOwner,
        ],
      },
      {
        displayName: new Map<string, string>([
          ['en', 'License'],
          ['de', 'Lizenz'],
        ]),
        routePath: LicenseComponent.route,
        restrictedTo: [ApiRoleType.AdminAccess, ApiRoleType.DashobardOwner],
      },
    ],
  },
  {
    displayName: new Map<string, string>([
      ['en', 'Administration'],
      ['de', 'Administration'],
    ]),
    restrictedTo: [ApiRoleType.AdminAccess],
    routePath: AdminComponent.route,
    subMenuEntries: [
      {
        displayName: new Map<string, string>([
          ['en', 'Emission factors'],
          ['de', 'Emissionsfaktoren'],
        ]),
        routePath: EmissionFactorsComponent.route,
      },
      {
        displayName: new Map<string, string>([
          ['en', 'Tenants'],
          ['de', 'Kunden'],
        ]),
        routePath: TenantsComponent.route,
      },
    ],
  },
];
ROUTE_CONFIG.splice(2, 0, {
  displayName: new Map<string, string>([
    ['en', 'Feedback'],
    ['de', 'Feedback'],
  ]),
  routePath: FeedbackComponent.route,
});
