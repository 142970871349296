import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Emission } from 'src/app/models/emission';
import { TableData } from 'src/app/models/table-data';
import {
  ADMIN_TENENAT_ID,
  EmissionService,
} from 'src/app/services/emission-service';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import {
  createAddEmissionDialog,
  createDeleteEmissionDialog,
  createEditEmissionDialog,
} from '../../../services/emssission-dialog-factory';
import { emissionTableStructure } from '../../../services/table-structure-factory';

@Component({
  selector: 'app-emission-factors',
  templateUrl: './emission-factors.component.html',
  styleUrls: ['./emission-factors.component.scss'],
})
export class EmissionFactorsComponent implements OnInit, OnDestroy {
  public static get route() {
    return 'emission-factors';
  }

  private emissionSubscription: Subscription | undefined;
  private emissions: Emission[] = [];
  public emissionFactorData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );

  constructor(
    private emissionFactorService: EmissionService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.emissionSubscription =
      this.emissionFactorService.adminEmissionData.subscribe(
        ({ emissionList }) => {
          this.emissions = emissionList;
          this.initializeEmissionTableData();
        }
      );
  }

  ngOnDestroy(): void {
    this.emissionSubscription?.unsubscribe();
  }

  private initializeEmissionTableData() {
    this.emissionFactorData.next(emissionTableStructure(this.emissions, false));
  }

  public addEmissionFactor() {
    const emissionNameKey: string = 'emission-name';
    const emissionDieselKey: string = 'emission-diesel';
    const emissionPetrolKey: string = 'emission-petrol';
    const emissionElectricKey: string = 'emission-electric';

    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createAddEmissionDialog(
        emissionNameKey,
        emissionDieselKey,
        emissionPetrolKey,
        emissionElectricKey
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response.size > 0) {
        this.emissionFactorService.addEmissionToList(
          {
            countryCode:
              response.get(emissionNameKey) === 'Default'
                ? 'code'
                : response.get(emissionNameKey),
            dieselFactor: +response.get(emissionDieselKey),
            electricFactor: +response.get(emissionElectricKey),
            gasolineFactor: +response.get(emissionPetrolKey),
            tenantId: ADMIN_TENENAT_ID,
          },
          true
        );
      }
    });
  }

  public editEmissionFactor(event) {
    const codeKey = 'emission-name';
    const dieselKey = 'emission-diesel';
    const petrolKey = 'emission-petrol';
    const electricKey = 'emission-electric';

    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createEditEmissionDialog(
        codeKey,
        dieselKey,
        petrolKey,
        electricKey,
        event
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response.size > 0) {
        const tenantId = ADMIN_TENENAT_ID;

        const original = this.emissions.filter(
          (e) =>
            e.countryCode === event['entryId'] &&
            e.tenantId.toLocaleLowerCase() === tenantId.toLocaleLowerCase()
        )[0];

        const emission: Emission = {
          countryCode: original.countryCode,
          dieselFactor: response.get(dieselKey)
            ? +response.get(dieselKey)
            : original.dieselFactor,
          electricFactor: response.get(electricKey)
            ? +response.get(electricKey)
            : original.electricFactor,
          gasolineFactor: response.get(petrolKey)
            ? +response.get(petrolKey)
            : original.gasolineFactor,
          tenantId: tenantId,
        };

        if (response.get(codeKey)) {
          if (original.countryCode !== 'Default') {
            const code = response.get(codeKey);
            emission.countryCode = code;
            this.emissionFactorService.replaceEmissionByCode(
              emission,
              original.countryCode,
              true
            );
            return;
          }
        }

        this.emissionFactorService.updateEmissionInService(emission, true);
      }
    });
  }

  public deleteEmissionFactor(event) {
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createDeleteEmissionDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.emissionFactorService.removeEmissionFromList(
          event['entryId'],
          true
        );
      }
    });
  }

  public emissionTableData(): Observable<TableData> {
    return this.emissionFactorData.asObservable();
  }
}
