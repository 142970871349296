import { Component, OnInit } from '@angular/core';
import {
  License,
  LicenseResponse,
  LicenseStatus,
} from '../../../models/license';
import { LicenseService } from '../../../services/license.service';
import { MatDialog } from '@angular/material/dialog';
import {
  createAddLicenseDialog,
  createDeleteLicenseDialog,
  createEditLicenseDialog,
  createIncreaseCodesDialog,
  createRenevalLicenseDialog,
} from '../../../services/license-dialog-factory';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { FetchStatus } from 'src/app/models/enums/fetch-status';
import { TokenUtil } from 'src/app/utils/token-util';
import { TenantService } from 'src/app/services/tenant.service';
import { Timespan } from 'src/app/shared/components/date-input/date-input.component';
import { createIncreaseUsageDialog } from 'src/app/services/activation-code-factory';

@Component({
  selector: 'app-admin-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit {
  public licenses: License[] = [];
  public fetching: boolean = true;

  private nameKey: string = 'license.dialog.create.name.text';
  private dateKey: string = 'license.dialog.create.date.text';
  private codesKey: string = 'license.dialog.create.codes.text';

  constructor(
    private licenseService: LicenseService,
    private dialog: MatDialog,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.fetching = true;

    this.licenseService.selectedTenantLicenses.subscribe(
      (response: LicenseResponse) => {
        this.licenses = response.licenses.sort(
          (a, b) => a.contractFrom.getTime() - b.contractFrom.getTime()
        );
        this.sortLicensesByStatus(this.licenses);
        if (response.fetchStatus === FetchStatus.DONE) {
          this.fetching = false;
        }
      }
    );
  }

  private sortLicensesByStatus(licenses: License[]) {
    let sorted = false;
    do {
      sorted = true;
      for (let i = 0; i < licenses.length - 1; i++) {
        const current = licenses[i];
        const next = licenses[i + 1];
        if (current.status === LicenseStatus.OUTDATED) {
          if (
            next.status === LicenseStatus.ON_HOLD ||
            next.status === LicenseStatus.ACTIVE
          ) {
            licenses[i] = next;
            licenses[i + 1] = current;
            sorted = false;
          }
        }
        if (current.status === LicenseStatus.ON_HOLD) {
          if (next.status === LicenseStatus.ACTIVE) {
            licenses[i] = next;
            licenses[i + 1] = current;
            sorted = false;
          }
        }
      }
    } while (!sorted);
  }

  addLicenseDialog() {
    //Gives the date-input component information which dates are not valid
    const dateValidation = this.licenses.map((license) => {
      return { from: license.contractFrom, to: license.contractTo };
    });
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createAddLicenseDialog(
        this.nameKey,
        this.dateKey,
        this.codesKey,
        dateValidation
      ),
    });
    dialogRef
      .afterClosed()
      .subscribe((response: Map<string, string | { from: Date; to: Date }>) => {
        this.tenantService.selectedTenant.subscribe((tenant) => {
          if (tenant) {
            const date = response.get('date') as { from: Date; to: Date };
            const license = {
              amountOfCodes: Number(response.get('codes')),
              contractFrom: date.from,
              contractTo: date.to,
              contractName: response.get('name') as string,
              status: LicenseStatus.ON_HOLD,
              tenantId: tenant.id,
            };
            this.licenseService.postLicense(license);
          }
        });
      });
  }

  showEditLicenseDialog(license: License) {
    const dateValidation = this.licenses
      .filter((entry) => entry.id !== license.id)
      .map((entry) => {
        return { from: entry.contractFrom, to: entry.contractTo };
      });
    const nameKey = 'contractName';
    const dateKey = 'date';

    const codesKey = 'codes';
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createEditLicenseDialog(
        nameKey,
        dateKey,
        codesKey,
        license,
        dateValidation,
        license.contractFrom,
        license.contractTo
      ),
    });
    dialogRef.afterClosed().subscribe((response) => {
      const timespan: unknown = response.get('date');
      const contractName = response.get('contractName') ?? license.contractName;

      const from = (timespan as Timespan).from ?? license.contractFrom;
      const to = (timespan as Timespan).to ?? license.contractTo;

      const amountOfcodes = response.get('codes') ?? license.amountOfCodes;

      license.contractFrom = from;
      license.contractTo = to;
      license.contractName = contractName;
      license.amountOfCodes = amountOfcodes;

      this.licenseService.updateLicense(license);
    });
  }

  showDeleteLicenseDialog(license: License) {
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createDeleteLicenseDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response == 200) {
        this.licenseService.deleteLicense(license.id);
      }
    });
  }

  showRenewLicenseDialog(license: License) {
    const dateValidation = this.licenses
      .filter((entry) => entry.id !== license.id)
      .map((entry) => {
        return { from: entry.contractFrom, to: entry.contractTo };
      });
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createRenevalLicenseDialog(license, dateValidation),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      const timespan: unknown = response.get('date');
      if (timespan) {
        const contractName =
          response.get('contractName') ?? license.contractName;

        const from = (timespan as Timespan).from ?? new Date();
        var end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        const to = (timespan as Timespan).to ?? end;

        license.contractFrom = from;
        license.contractTo = to;
        license.contractName = contractName;

        this.licenseService.updateLicense(license);
      }
    });
  }

  showIncreaseCodeAmountsDialog(license: License) {
    const codesKey = 'codes';
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createIncreaseCodesDialog(codesKey),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      const amountOfCodes = response.get(codesKey);
      if (amountOfCodes) {
        license.amountOfCodes = license.amountOfCodes + Number(amountOfCodes);
        this.licenseService.updateLicense(license);
      }
    });
  }

  statusIsActive(license: License) {
    return license.status === LicenseStatus.ACTIVE;
  }

  statusIsHold(license: License) {
    return license.status === LicenseStatus.ON_HOLD;
  }

  statusIsOutdated(license: License) {
    return license.status === LicenseStatus.OUTDATED;
  }
}
