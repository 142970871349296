import { Injectable } from '@angular/core';
import { LocalStorageTypes } from '../models/localstorage-types';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private static projectName = 'ecoshift';

  private static preferedRouteKey = 'route';

  /**
   * Get the storage facility
   * @param storageType {LocalStorageTypes}
   * @private
   * @returns {localStorage|sessionStorage}
   */
  private static _getStorage(storageType: LocalStorageTypes) {
    return storageType === LocalStorageTypes.LOCAL
      ? localStorage
      : sessionStorage;
  }

  /**
   * Get a localStorage or sessionStorage item value
   * @param storageType {'local'|'session'}
   * @param key {string}
   */
  static getItem(storageType: LocalStorageTypes, key: string) {
    const storage = LocalStorageService._getStorage(storageType);
    const val = storage.getItem(`${this.projectName}:${key}`);
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }

  /**
   * Set a localStorage or sessionStorage item value
   * @param storageType {LocalStorageTypes}
   * @param key {string}
   * @param value {any}
   */
  static setItem(storageType: LocalStorageTypes, key: string, value: any) {
    const storage = LocalStorageService._getStorage(storageType);
    const val = typeof value === 'string' ? value : JSON.stringify(value);
    storage.setItem(`${this.projectName}:${key}`, val);
  }

  /**
   * Remove an item from localStorage or sessionStorage
   * @param storageType {LocalStorageTypes}
   * @param key {string}
   */
  static removeItem(storageType: LocalStorageTypes, key: string) {
    const storage = LocalStorageService._getStorage(storageType);
    storage.removeItem(`${this.projectName}:${key}`);
  }

  static hasPreferedRoute(): boolean {
    const route = localStorage.getItem(this.preferedRouteKey);
    return route !== undefined && route !== null && route !== '';
  }

  static getPreferedRoute(): string {
    return localStorage.getItem(this.preferedRouteKey);
  }

  static updatePreferedRoute(route: string): void {
    localStorage.setItem(this.preferedRouteKey, route);
  }
}
