import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TenantService } from './tenant.service';
import { MsalService } from '@azure/msal-angular';
import { TokenUtil } from '../utils/token-util';
import { DashboardInfo } from '../models/dashboard-info';
import { DashboardInfoHttpService } from './http/dashboard-info-http.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private _dashboardInfo: BehaviorSubject<DashboardInfo> =
    new BehaviorSubject<DashboardInfo>({
      backendVersion: '-1',
      frontendVersion: '-1',
      environment: '',
    });

  constructor(
    private dashboardInfoHttpService: DashboardInfoHttpService,
    private msalService: MsalService
  ) {}

  get dashboardInfo() {
    if (this._dashboardInfo.value.environment === '') {
      this.initDashboardInfo();
    }
    return this._dashboardInfo.asObservable();
  }

  initDashboardInfo(): void {
    this.dashboardInfoHttpService
      .getDashboardInfo()
      .pipe(
        tap((dashboardInfo: DashboardInfo) => {
          this._dashboardInfo.next(dashboardInfo);
        })
      )
      .subscribe();
  }
}
