<div class="activationcode-wrapper">
    <div class="activationcode-table">
        <div class="header">
            <h3>{{'admin.activationcode.table.title.headline'|translate}}<span>{{'admin.activationcode.table.title.usedCodes'
                    | translate: { usedCodes: usedActivationCodes, totalCodes: activationCodeSum} }} </span></h3>
            <app-text-button [fontSizeFactor]="0.9" [icon]="'assets/svg/download.svg'"
                [text]="'admin.activationcode.table.download'|translate" (click)="exportToCSV()"></app-text-button>
        </div>
        <div class="search-box">
            <app-icon-text-input [inputPlaceHolder]="'admin.activationcode.table.title.code'"
                [iconSource]="'assets/svg/search.svg'" (changes)="onSearchTermChange($event)"></app-icon-text-input>
        </div>
        <app-dashboard-table [elementsPerView]="10" [tableData]="activationcodeTableData()"
            (customPressed)="increaseUsages($event)"
            [customButtonText]="'admin.activationcode.table.button.increaseUsage'"
            [customButtonIcon]="'assets/svg/add.svg'" [customIsTextButton]="true"
            (secondCustomPressed)="resetCodes($event)"
            [secondCustomButtonText]="'admin.activationcode.table.button.reset'"
            [secondCustomButtonIcon]="'assets/svg/reload.svg'" [secondCustomIsTextButton]="true"
            (onSelectOption)="onSelectedOptionsChanged($event)"></app-dashboard-table>
        <div class="reset-button-container">
            <app-button (click)="selectedCodes.length > 0 ? resetSelectedCodes() : null"
                [cancelButtonStyle]="selectedCodes.length === 0" [icon]="'assets/images/shared/dark-reset.svg'"
                [activeIcon]="'assets/images/shared/white-reset.svg'" [fontSizeFactor]="0.75"
                [text]="'admin.activationcode.table.button.resetMultiple' | translate"></app-button>
        </div>
    </div>
</div>