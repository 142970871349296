import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivationCode } from '../../models/activationcode';
import { APIConst } from '../../models/enums/api.enum';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ActivationCodeHttpService {
  private apiService: ApiService = inject(ApiService);

  //reducing month by one because the month for the js date starts with 0
  fetchAllActivationCodesFor(tenantID: string): Observable<ActivationCode[]> {
    return this.apiService
      .get({
        path: `${
          APIConst.GET_ACTIVATIONCODES + this.apiService.requestParamsObj({})
        }/${tenantID}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ activationCodes }) =>
          activationCodes.map((activationCode: ActivationCode) => {
            const dateTimeFrom = activationCode['validFrom']['dateTime'];
            const dateFrom = dateTimeFrom['date'];
            const timeFrom = dateTimeFrom['time'];

            const dateTimeUntil = activationCode['validUntil']['dateTime'];
            const dateUntil = dateTimeUntil['date'];
            const timeUntil = dateTimeUntil['time'];

            const createdAtTimeDate = activationCode['createdAt']['dateTime'];
            const createdAtDate = createdAtTimeDate['date'];
            const createdAtDateTime = createdAtTimeDate['time'];

            return {
              code: activationCode.code,
              validationDateFrom: new Date(
                dateFrom['year'],
                dateFrom['month'] - 1,
                dateFrom['day'],
                timeFrom['hour'],
                timeFrom['minute'],
                timeFrom['second']
              ),
              validationDateTo: new Date(
                dateUntil['year'],
                dateUntil['month'] - 1,
                dateUntil['day'],
                timeUntil['hour'],
                timeUntil['minute'],
                timeUntil['second']
              ),
              amountOfRemainingUses: activationCode['remainingUsages'],
              tenantID: activationCode['tenantId'],
              licenseId: activationCode['licenseId'],
              redeemable: activationCode['redeemable'],
              usagesCounter: activationCode['usagesCounter'],
              createdAt: new Date(
                createdAtDate['year'],
                createdAtDate['month'] - 1,
                createdAtDate['day'],
                createdAtDateTime['hour'],
                createdAtDateTime['minute'],
                createdAtDateTime['second']
              ),
            } as ActivationCode;
          })
        )
      );
  }

  //increasing month by one because the month for the java date offset starts with 1
  postActivationCode(
    activationCode: ActivationCode,
    amountOfCodes: number
  ): Observable<any> {
    const validFromDate = activationCode.validationDateFrom;

    const validUntilDate = activationCode.validationDateTo;
    const body = {
      licenseId: activationCode.licenseId,
      usagesCounter: activationCode.usagesCounter ?? 0,
      tenantId: activationCode.tenantID,
      redeemable: activationCode.redeemable,
      remainingUsages: activationCode.amountOfRemainingUses,
      validFrom: {
        dateTime: {
          date: {
            year: validFromDate.getFullYear(),
            month: validFromDate.getMonth() + 1,
            day: validFromDate.getDate(),
          },
          time: {
            hour: validFromDate.getHours(),
            minute: validFromDate.getMinutes(),
            second: validFromDate.getSeconds(),
            nano: validFromDate.getMilliseconds(),
          },
        },
        offset: {
          totalSeconds: validFromDate.getTimezoneOffset(),
        },
      },
      validUntil: {
        dateTime: {
          date: {
            year: validUntilDate.getFullYear(),
            month: validUntilDate.getMonth() + 1,
            day: validUntilDate.getDate(),
          },
          time: {
            hour: validUntilDate.getHours(),
            minute: validUntilDate.getMinutes(),
            second: validUntilDate.getSeconds(),
            nano: validUntilDate.getMilliseconds(),
          },
        },
        offset: {
          totalSeconds: validUntilDate.getTimezoneOffset(),
        },
      },
    };

    return this.apiService.post({
      path: `${
        APIConst.ADD_ACTIVATIONCODES + this.apiService.requestParamsObj({})
      }${amountOfCodes > 1 ? '/' + amountOfCodes : ''}`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
      body: body,
    });
  }

  resetActivationCode(code: string): Observable<any> {
    return this.apiService
      .put({
        path: `${APIConst.ACTIVATION_CODE_RESET}/${code}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((activationCode) => {
          const dateTimeFrom = activationCode['validFrom']['dateTime'];
          const dateFrom = dateTimeFrom['date'];
          const timeFrom = dateTimeFrom['time'];

          const dateTimeUntil = activationCode['validUntil']['dateTime'];
          const dateUntil = dateTimeUntil['date'];
          const timeUntil = dateTimeUntil['time'];
          return {
            code: activationCode.code,
            validationDateFrom: new Date(
              dateFrom['year'],
              dateFrom['month'] - 1,
              dateFrom['day'],
              timeFrom['hour'],
              timeFrom['minute'],
              timeFrom['second']
            ),
            validationDateTo: new Date(
              dateUntil['year'],
              dateUntil['month'] - 1,
              dateUntil['day'],
              timeUntil['hour'],
              timeUntil['minute'],
              timeUntil['second']
            ),
            amountOfRemainingUses: activationCode['remainingUsages'],
            tenantID: activationCode['tenantId'],
            licenseId: activationCode['licenseId'],
            redeemable: activationCode['redeemable'],
            usagesCounter: activationCode['usagesCounter'],
          };
        })
      );
  }

  updateActivationCode(code: ActivationCode): Observable<any> {
    const validUntil: Date = code.validationDateTo;
    return this.apiService.put({
      path: `${
        APIConst.CHANGE_ACTIVATIONCODES + this.apiService.requestParamsObj({})
      }`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
      body: {
        licenseId: code.licenseId,
        code: code.code,
        remainingUsages: code.amountOfRemainingUses,
        validUntil: {
          dateTime: {
            date: {
              year: validUntil.getFullYear(),
              month: validUntil.getMonth() + 1,
              day: validUntil.getDate(),
            },
            time: {
              hour: validUntil.getHours(),
              minute: validUntil.getMinutes(),
              second: validUntil.getSeconds(),
              nano: validUntil.getMilliseconds(),
            },
          },
          offset: { totalSeconds: validUntil.getTimezoneOffset() },
        },
        redeemable: code.redeemable,
        tenantID: code.tenantID,
        usagesCounter: code.usagesCounter,
      },
    });
  }

  deleteActivationCode(codes: string[]): Observable<any> {
    return this.apiService.delete({
      path: `${
        APIConst.CHANGE_ACTIVATIONCODES +
        this.apiService.requestParamsObj({}) +
        '?codes=' +
        codes.join(',')
      }`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
    });
  }
}
