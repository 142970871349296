import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { AuthenticationService } from '../../authentication/authentication';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public static get route(): string {
    return 'login';
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    registerLocaleData(de);
    registerLocaleData(en);
  }

  ngOnInit(): void {
    if (LocalStorageService.hasPreferedRoute()) {
      this.router.navigateByUrl(LocalStorageService.getPreferedRoute());
    }
  }

  login() {
    this.authenticationService.login();
  }
}
