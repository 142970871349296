<ng-container *ngIf="!showSurveyDetails && !newSurvey" class="container--centered">
  <app-intro-section [surveyCount]="surveyCount" [survey]="exampleSurvey"
    (createNewSurveyClicked)="newSurveyClicked($event)"></app-intro-section>
  <app-survey-filter (filterChanged)="onFilterChanged($event)" [survey]="surveys" (sort)="sortByCriteria(filteredSurveys,$event)"></app-survey-filter>
  <ng-container *ngIf="loading" style="display: flex; align-content: center">
    <scale-loading-spinner class="container-centered"></scale-loading-spinner>
  </ng-container>
  <app-survey-tile *ngFor="let survey of filteredSurveys" [survey]="survey"
    (openDetailsClicked)="onSurveyTileClicked(true,$event)"></app-survey-tile>
</ng-container>
<ng-container *ngIf="showSurveyDetails">
  <app-survey-details [survey]="selectedSurvey" (openDetailsClicked)="onSurveyTileClicked($event)" [resetSwitch]="resetSwitch"
    (isActiveChanged)="surveyActivesToggleHandler($event)" (isEditMode)="isEditMode=$event"></app-survey-details>
</ng-container>

<ng-container *ngIf="newSurvey">
  <app-survey-configurator (isActiveChanged)="surveyActivesToggleHandler($event, true)"></app-survey-configurator>
</ng-container>

<scale-modal heading="{{'feedbacks.title.toggle.note'|translate}}" id="modal" [opened]="isActiveModal" (scale-close)="afterCloseModal()" size="small">
  <p>{{ dialogToggle | translate }}</p>
  <scale-button slot="action" variant="secondary" (click)="closeModal()">
    {{dialogToggleButtonCancel |translate}}
  </scale-button>
  <scale-button slot="action" (click)="confirmToggle()" class="padding-left-xs"> {{dialogToggleButtonConfirm|translate}} </scale-button>
</scale-modal>