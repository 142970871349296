<section class="intro-section p-col-12  no-padding-left">
  <p class="p-col-7 margin-top-small no-padding-left">{{'feedbacks.introText'|translate}}</p>
  <div class="intro p-col-7 no-padding-left">
    <scale-notification heading="{{'feedbacks.notification.oneStatus'|translate}}" opened variant="warning">
    </scale-notification>
     </div>
  <div class="survey-title-section margin-top-large container-survey-tittle no-padding-left">
    <h2 class="intro-survey">{{'feedbacks.title.surveys.headline'|translate}}
      <span>({{ surveyCount | async }})</span>
    </h2>

    <scale-button variant="ghost" class="survey-button-add black-ghost-color" (click)="createNewSurvey()" size="large">
      <scale-icon-action-add></scale-icon-action-add>
      {{'feedbacks.title.buttons.create'|translate}}
    </scale-button>
  </div>
</section>