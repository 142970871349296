import {
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrandColor } from 'src/app/models/brand';
import { DEFAULT_BRANDING, DEFAULT_BRANDING_COLORS } from '../../../../../utils/branding-util';

@Component({
  selector: 'app-brand-color-selector',
  templateUrl: './brand-color-selector.component.html',
  styleUrls: ['./brand-color-selector.component.scss'],
})
export class BrandColorSelectorComponent {
  hue: string = '';
  @Input() color: string = '#ffffff';

  @Input() red: number = 0;
  @Input() green: number = 0;
  @Input() blue: number = 0;

  @ViewChild('palette')
  palette: ElementRef<HTMLCanvasElement>;

  private readonly hex = 'Hex';
  private readonly rgb = 'RGB';

  public selectedValue: string = this.hex;

  private mousedownPalette: boolean = false;
  public selectedPosition: { x: number; y: number } = { x: 80, y: 80 };
  lastRgba: string = '';

  @ViewChild('slider')
  slider: ElementRef<HTMLCanvasElement>;

  private sliderContext: CanvasRenderingContext2D;
  private paletteContext: CanvasRenderingContext2D;

  private mousedownSlider: boolean = false;
  private selectedHeightSlider: number;

  private originalColor: string = '';

  public DEFAULT_BRANDING_COLORS = DEFAULT_BRANDING.brandColors;
  private defaultColor: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BrandColor,
    private dialogRef: MatDialogRef<BrandColorSelectorComponent>
  ) {
    this.color = data.colorValue;
    this.originalColor = data.colorValue;
    this.defaultColor = DEFAULT_BRANDING_COLORS[this.data.key];
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public colorHasChanged(): boolean {
    if (this.defaultColor) {
      return this.color.toLocaleLowerCase() != this.defaultColor.toLocaleLowerCase();
    }
    return false;
  }




  public resetColor() {
    this.color = this.defaultColor;
    if (this.rgbSelected) {
      const rgb = this.hexToRgb(this.originalColor);
      this.red = rgb.r;
      this.green = rgb.g;
      this.blue = rgb.b;
    }
    this.onInputColorChange(this.color);
  }

  public saveColor() {
    this.data.colorValue = this.color;
    this.closeDialog();
  }

  public dropDownOptions: string[] = [this.hex, this.rgb];
  sliderHeight: number;
  pickerWidth: any;
  pickerHeight: number;
  ngAfterViewInit() {
    this.drawSlider();
    this.drawPalette();

    this.onInputColorChange(this.color);
  }

  public hexSelected() {
    return this.selectedValue === this.hex;
  }

  public rgbSelected() {
    return this.selectedValue === this.rgb;
  }

  public updateSelection(option: string) {
    this.selectedValue = option;
    if (this.hexSelected()) {
      if (this.red !== 0 || this.blue !== 0 || this.green !== 0) {
        this.color = this.rgbToHexNumeric(this.red, this.green, this.blue);
      }
    } else {
      if (this.color !== '#FFFFFF') {
        const rgb = this.hexToRgb(this.color);
        this.red = rgb.r;
        this.green = rgb.g;
        this.blue = rgb.b;
      }
    }
  }

  onMouseUpSlider(evt: MouseEvent) {
    this.mousedownSlider = false;
    this.selectedHeightSlider = evt.offsetY;
  }

  onMouseDownSlider(evt: MouseEvent) {
    this.mousedownSlider = true;
    this.selectedHeightSlider = evt.offsetY;
    this.emitColorSlider(evt.offsetX, evt.offsetY);

    this.drawSlider();

    this.drawPalette();
    this.emitColor(this.selectedPosition.x, this.selectedPosition.y);
  }

  onMouseMoveSlider(evt: MouseEvent) {
    if (this.mousedownSlider) {
      this.selectedHeightSlider = evt.offsetY;
      this.emitColorSlider(evt.offsetX, evt.offsetY);

      this.drawSlider();
      this.emitColor(this.selectedPosition.x, this.selectedPosition.y);

      this.drawPalette();
    }
  }

  emitColorSlider(x: number, y: number) {
    if (y < 1) {
      y = 1;
    }

    if (y >= 160) {
      y = 150;
    }
    const rgbaColor = this.getColorAtPositionSlider(x, y);
    this.hue = rgbaColor;
  }

  getColorAtPositionSlider(x: number, y: number) {
    if (this.sliderContext) {
      if (isNaN(y)) {
        y = 1;
      }
      const image = this.sliderContext.getImageData(x, y, 1, 1);
      if (image) {
        const imageData = image.data;
        return this.rgbToHex(
          'rgba(' +
            imageData[0] +
            ',' +
            imageData[1] +
            ',' +
            imageData[2] +
            ',1)'
        );
      }
    }
    return '';
  }
  rgbToHexSlider(rgba: string) {
    const rgb = rgba.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    var color =
      rgb && rgb.length === 4
        ? '#' +
          ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
        : '';
    return color;
  }

  onMouseUpPalette(evt: MouseEvent) {
    this.mousedownPalette = false;
  }

  onMouseDownPalette(evt: MouseEvent) {
    this.mousedownPalette = true;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.drawPalette();

    this.color = this.getColorAtPosition(evt.offsetX, evt.offsetY);
    const { r, g, b } = this.hexToRgb(this.color);
    this.red = r;
    this.green = g;
    this.blue = b;
  }

  onMouseMovePalette(evt: MouseEvent) {
    if (this.mousedownPalette) {
      this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
      this.drawPalette();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color = rgbaColor;
    const { r, g, b } = this.hexToRgb(rgbaColor);
    this.red = r;
    this.green = g;
    this.blue = b;
  }

  getColorAtPosition(x: number, y: number) {
    if (this.paletteContext) {
      const imageData = this.paletteContext.getImageData(x, y, 1, 1).data;
      this.lastRgba = this.rgbToHex(
        'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)'
      );
      return this.lastRgba;
    }
    if (this.lastRgba && this.lastRgba !== '') {
      return this.lastRgba;
    }
    return this.rgbToHex('rgba(' + 0 + ',' + 0 + ',' + 0 + ',1)');
  }

  rgbToHex(rgba: string) {
    const rgb = rgba.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    var color =
      rgb && rgb.length === 4
        ? '#' +
          ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
        : '';
    return color;
  }

  private rgbToHexNumeric(red: number, green: number, blue: number): string {
    // Convert RGB values to the range 0-255
    const r = Math.round(red);
    const g = Math.round(green);
    const b = Math.round(blue);

    // Convert each RGB component to a two-digit hexadecimal string
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    // Combine the hexadecimal components to form the final color string
    const hexColor = `#${rHex}${gHex}${bHex}`;

    return hexColor;
  }

  //drawing

  drawSlider() {
    if (!this.sliderContext) {
      this.sliderContext = this.slider.nativeElement.getContext('2d');
    }

    const width = this.slider.nativeElement.width;
    const height = this.slider.nativeElement.height;
    this.sliderHeight = height;

    this.sliderContext.clearRect(0, 0, width, height);

    const gradient = this.sliderContext.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');

    this.sliderContext.beginPath();
    this.sliderContext.rect(0, 0, width, height);

    this.sliderContext.fillStyle = gradient;
    this.sliderContext.fill();
    this.sliderContext.closePath();

    if (this.selectedHeightSlider) {
      this.sliderContext.beginPath();
      this.sliderContext.strokeStyle = '#C6C6C6';
      this.sliderContext.lineWidth = 2;
      this.sliderContext.rect(0, this.selectedHeightSlider - 5, width, 10);
      this.sliderContext.stroke();
      this.sliderContext.closePath();
    }
  }

  drawPalette() {
    if (!this.paletteContext) {
      if (this.palette) {
        this.paletteContext = this.palette.nativeElement.getContext('2d');
      }
    }
    const width = this.palette ? this.palette.nativeElement.width : 0;
    const height = this.palette ? this.palette.nativeElement.height : 0;

    this.pickerWidth = width;
    this.pickerHeight = height;

    if (this.paletteContext) {
      this.paletteContext.fillStyle = this.hue || 'rgba(255,255,255,1)';
      this.paletteContext.fillRect(0, 0, width, height);
      const whiteGrad = this.paletteContext.createLinearGradient(
        0,
        0,
        width,
        0
      );
      whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
      whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

      this.paletteContext.fillStyle = whiteGrad;
      this.paletteContext.fillRect(0, 0, width, height);

      const blackGrad = this.paletteContext.createLinearGradient(
        0,
        0,
        0,
        height
      );
      blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
      blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

      this.paletteContext.fillStyle = blackGrad;
      this.paletteContext.fillRect(0, 0, width, height);

      if (this.selectedPosition) {
        this.paletteContext.strokeStyle = '#FFFFFF';
        this.paletteContext.beginPath();

        const selectedX =
          this.selectedPosition.x - 5 >= this.pickerWidth
            ? this.selectedPosition.x - 5
            : this.selectedPosition.x;
        const selectedY =
          this.selectedPosition.y - 5 >= this.pickerHeight
            ? this.selectedPosition.y - 5
            : this.selectedPosition.y;

        this.paletteContext.arc(selectedX, selectedY, 5, 0, 2 * Math.PI);
        this.paletteContext.lineWidth = 2;
        this.paletteContext.stroke();
      }
    }
  }

  public onInputColorChange(inputValue: string) {
    let input = inputValue + '';
    let inputIsValid = false;

    if (this.rgbSelected) {
      if (this.red < 0) {
        this.red = 0;
      }
      if (this.red > 255) {
        this.red = 255;
      }

      if (this.green < 0) {
        this.green = 0;
      }
      if (this.green > 255) {
        this.green = 255;
      }

      if (this.blue < 0) {
        this.blue = 0;
      }
      if (this.blue > 255) {
        this.blue = 255;
      }
    }

    if (this.hexSelected()) {
      if (!input.startsWith('#')) {
        input = '#' + input;
      }
      const hexRegex = /^#([0-9a-fA-F]{3}){1,2}$/;
      inputIsValid = hexRegex.test(input);
      if (inputIsValid) {
        this.color = input;

        const hexValue = input.slice(1);
        const r = parseInt(hexValue.substring(0, 2), 16);
        const g = parseInt(hexValue.substring(2, 4), 16);
        const b = parseInt(hexValue.substring(4, 6), 16);
        this.updatePalette(r, g, b);
      }
    } else {
      this.updatePalette(this.red, this.green, this.blue);
    }
  }

  private updatePalette(r: number, g: number, b: number): void {
    const hsvColor = this.rgbToHsvColor(r, g, b);
    const value = hsvColor.h / 360;
    const sliderPos = Math.round(value * this.sliderHeight);

    const pickerPos = {
      x: Math.round(hsvColor.s * this.pickerWidth),
      y: Math.round((1 - hsvColor.v) * this.pickerHeight),
    };

    this.selectedHeightSlider = sliderPos;

    this.emitColorSlider(10, this.selectedHeightSlider);

    this.selectedPosition = { x: pickerPos.x, y: pickerPos.y };

    this.drawSlider();
    this.drawPalette();
  }

  rgbToHsvColor(
    red: number,
    green: number,
    blue: number
  ): { h: number; s: number; v: number } {
    // Convert RGB values to the range 0-1
    const r = red / 255;
    const g = green / 255;
    const b = blue / 255;

    // Find the minimum and maximum values of RGB
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // Calculate the hue
    let h: number;
    const delta = max - min;

    if (max === min) {
      h = 0;
    } else {
      if (max === r) {
        h = ((g - b) / delta) % 6;
      } else if (max === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
      h = Math.round(h * 60);
      if (h < 0) {
        h += 360;
      }
    }

    const s = max === 0 ? 0 : delta / max;

    const v = max;

    const hsvColor: { h: number; s: number; v: number } = {
      h: h,
      s: s,
      v: v,
    };

    return hsvColor;
  }

  private hexToRgb(hex: string): { r; g; b } {
    const hexNum = parseInt(hex.slice(1), 16);
    const r = (hexNum >> 16) & 255;
    const g = (hexNum >> 8) & 255;
    const b = hexNum & 255;

    return { r, g, b };
  }

  private rgbToSv(r: number, g: number, b: number) {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let s = 0;
    let v = max / 255;

    if (max !== 0) {
      s = (max - min) / max;
    }

    return { s, v };
  }

  private rgbToHue(r: number, g: number, b: number): number {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let hue = 0;
    if (max === min) {
      hue = 0;
    } else if (max === r) {
      hue = ((g - b) / (max - min)) % 6;
    } else if (max === g) {
      hue = (b - r) / (max - min) + 2;
    } else if (max === b) {
      hue = (r - g) / (max - min) + 4;
    }
    hue = Math.round(hue * 60);
    if (hue < 0) {
      hue += 360;
    }
    return hue;
  }
}
