import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';

const createIncreaseUsageDialog = (increaseUsageKey: string) => {
  return {
    confirmationTextKey:
      'admin.activationcode.dialog.increaseUsage.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'admin.activationcode.dialog.increaseUsage.placeholder',
        prefix: 'admin.activationcode.dialog.increaseUsage.prefix',
        suffix: 'admin.activationcode.dialog.increaseUsage.suffix',
        key: increaseUsageKey,
      },
    ],
    dialogTitleTextKey: 'admin.activationcode.dialog.increaseUsage.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
  } as TableDialogInput;
};

const createResetDialog = () => {
  return {
    confirmationTextKey: 'admin.activationcode.dialog.reset.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'admin.activationcode.dialog.reset.text',
      },
    ],
    dialogTitleTextKey: 'admin.activationcode.dialog.reset.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

export { createIncreaseUsageDialog, createResetDialog };
