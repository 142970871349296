import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { EmissionFactorsComponent } from './emission-factors/emission-factors.component';
import { TenantsComponent } from './tenants/tenants.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { ActivationcodesComponent } from './activationcodes/activationcodes.component';
import { LicenseComponent } from './license/license.component';
import { DetailViewComponent } from './tenants/detail-view/detail-view.component';

@NgModule({
  declarations: [
    AdminComponent,
    EmissionFactorsComponent,
    TenantsComponent,
    UsersRolesComponent,
    ActivationcodesComponent,
    LicenseComponent,
    DetailViewComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
