import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

class ApiParams {
  path: string;
  endp?: string;
  observe?: boolean;
  body?: any;
  sendIDToken?: boolean;
}

export const IDTokenKey = 'id-token';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected defaultUrl = '';

  constructor(private http: HttpClient) {}

  public static hasIDToken(): boolean {
    return (
      localStorage.getItem(IDTokenKey) &&
      localStorage.getItem(IDTokenKey) !== ''
    );
  }

  public get(params: ApiParams): Observable<any> {
    const response = this.http.get(
      (params.endp ? params.endp : this.defaultUrl) + '' + params.path,
      {
        observe: 'response',
      }
    );
    return response;
  }

  public post(params: ApiParams): Observable<any> {
    const header =
      params.sendIDToken !== undefined && params.sendIDToken === true
        ? {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
            Authorization: 'Bearer ' + localStorage.getItem(IDTokenKey),
          }
        : {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
          };
    return this.http.post(
      (params.endp ? params.endp : this.defaultUrl) + '' + params.path,
      JSON.stringify(params.body ?? {}, (key, value) =>
        value instanceof Map ? Object.fromEntries(value) : value
      ),
      {
        observe: 'response',
        headers: header,
      }
    );
  }

  public put(params: ApiParams): Observable<any> {
    const header =
      params.sendIDToken !== undefined && params.sendIDToken === true
        ? {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
            Authorization: 'Bearer ' + localStorage.getItem(IDTokenKey),
          }
        : {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
          };
    return this.http.put(
      (params.endp ? params.endp : this.defaultUrl) + '' + params.path,
      JSON.stringify(params.body ?? {}, (key, value) =>
        value instanceof Map ? Object.fromEntries(value) : value
      ),
      {
        observe: 'response',
        headers: header,
      }
    );
  }
  public delete(params: ApiParams): Observable<any> {
    const header =
      params.sendIDToken !== undefined && params.sendIDToken === true
        ? {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
            Authorization: 'Bearer ' + localStorage.getItem(IDTokenKey),
          }
        : {
            'Ocp-Apim-Subscription-Key': environment.apimKey,
          };
    return this.http.delete(
      (params.endp ? params.endp : this.defaultUrl) + '' + params.path,
      {
        body: params.body ?? {},
        observe: 'response',
        headers: header,
      }
    );
  }

  public requestParams(requestParameters: any[]): any {
    let requestPath = '?';
    requestParameters.forEach((requestParam) => {
      let value = requestParam.value;
      const key = requestParam.key;
      if (value !== undefined && value != null) {
        if (value instanceof Array) {
          requestPath =
            requestPath +
            key +
            '=' +
            value.map((e) => encodeURIComponent(e)).join(',') +
            '&';
          //value.forEach((element) => requestPath = requestPath + key + '=' + encodeURIComponent(element) + '&');
        } else {
          value =
            value instanceof Date ? value.toISOString() : value.toString();
          if (value !== '' && value !== null) {
            requestPath =
              requestPath + key + '=' + encodeURIComponent(value) + '&';
          }
        }
      }
    });
    return requestPath.substring(0, requestPath.length - 1);
  }

  public requestParamsObj(params: any): any {
    return this.requestParams(
      Object.keys(params).map((it) => {
        return {
          key: it,
          value: params[it],
        };
      })
    );
  }
}
