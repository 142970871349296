// Loop through the component names and dynamically create and render each component
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicComponent } from 'src/app/models/dynamic-component';
import { DialogStructure } from '../../../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../../../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../../../models/enums/dialog/table-dialog-element';
import { DateInputComponent } from '../date-input/date-input.component';
import { SelectInputComponent } from '../select-input/select-input.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss'],
})
export class TableDialogComponent implements OnInit, AfterViewInit {
  public tableDialogInput: TableDialogInput;

  @ViewChild('vc', { read: ViewContainerRef }) set vcRef(vc: ViewContainerRef) {
    if (vc) {
      this.vc = vc;
      this.renderDynamicComponents();
    }
  }
  vc: ViewContainerRef;

  @ViewChild('single', { static: false }) single: TemplateRef<any>;
  @ViewChild('grid', { static: false }) grid: TemplateRef<any>;
  @ViewChild('column', { static: false }) column: TemplateRef<any>;

  private readonly COMPONENT_MAPPING: Map<string, any> = new Map<string, any>([
    ['date', DateInputComponent],
    ['select', SelectInputComponent],
    ['numeric-text', TextInputComponent],
    ['text-input', TextInputComponent],
    ['text', TextComponent],
  ]);
  private inputChanges: Map<string, InputResult> = new Map();
  private componentRefs: DynamicComponent[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private dialogRef: MatDialogRef<TableDialogComponent>,
    private cdref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tableDialogInput = data;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public handleSubmit() {
    if (this.dataFilledIn()) {
      return this.closeAndReturnData();
    } else {
      this.componentRefs.forEach((comp: any) => comp.wasTouched());
    }
  }

  public dataFilledIn(): boolean {
    const dialogElements = this.tableDialogInput.dialogElements;
    if (
      dialogElements.length === 1 &&
      dialogElements[0].dialogStructure === DialogStructure.TEXT
    ) {
      return true;
    }

    if (
      this.inputChanges.size < dialogElements.length &&
      !(this.tableDialogInput.isUpdateDialog && this.inputChanges.size > 0)
    ) {
      return false;
    }

    for (let inputResult of this.inputChanges.values()) {
      if (!inputResult.valid) {
        return false;
      }

      const value = inputResult.value;

      if (!isNaN(+value)) {
        if (+value < 0) {
          return false;
        }
      }
      if (value === '' || 1 > value.length) {
        return false;
      }
    }
    return true;
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  //Sets the "touched" property of all DialogInput Elements to true -> this forces a check for the validation
  public touchDialogFields() {}

  closeAndReturnData() {
    if (this.tableDialogInput.isDeleteDialog) {
      this.closeDialog(200);
      return;
    }
    this.closeDialog(
      new Map<string, string>(
        Array.from(this.inputChanges).map(([key, value]) => [key, value.value])
      )
    );
  }

  get dialogTemplate(): TemplateRef<any> {
    switch (this.tableDialogInput.dialogType) {
      case DialogLayoutType.COLUMN:
        return this.column;
      case DialogLayoutType.GRID:
        return this.grid;
      default:
        return this.single;
    }
  }

  renderDynamicComponents() {
    this.vc.clear();
    for (const element of this.tableDialogInput.dialogElements) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.COMPONENT_MAPPING.get(element.dialogStructure)
        );

      const componentRef = this.vc.createComponent(componentFactory);
      const componentInstance = componentRef.instance as DynamicComponent;
      componentInstance.data = { element };
      // (componentRef as any).instance.wasTouched();

      this.componentRefs.push(componentInstance);

      componentInstance.changes?.subscribe((data) => {
        this.inputChanges.set(element.key, data);
      });
    }
  }
}

export interface InputResult {
  value: any;
  valid: boolean;
}
