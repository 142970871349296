import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { Language } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/authentication/authentication';
import { Tenant } from 'src/app/models/tenant';
import { AdminComponent } from 'src/app/pages/admin/admin.component';
import { DashboardComponent } from 'src/app/pages/dashboard/component/dashboard.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { TenantService } from 'src/app/services/tenant.service';
import { ROUTE_CONFIG } from '../../../models/const/route-config';
import { MenuEntry } from '../../../models/header/menu-entry';

@Component({
  selector: 'dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren('tabHeader') tabHeaders: QueryList<ElementRef>;
  @ViewChild('telekomNavLayout') flyLayout: ElementRef;
  public selectedRouteEntry: MenuEntry;
  private subscription: Subscription;

  public languages: string[] = ['de', 'en'];
  public menuEntries = [];

  selectedLanguage: string = this.languages[0];
  useEnglish: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  public currentRoute: string = '';
  private tenantService: TenantService = inject(TenantService);
  public tenant: Tenant | undefined = undefined;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2
  ) {
    this.routerEvent = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<Event>;
  }

  public slim: boolean = true;

  public currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('/');

  public get landingPageUrl() {
    return '/';
  }

  public get adminPageUrl() {
    return AdminComponent.route;
  }

  public isAdminPage(url: string): boolean {
    return url.includes(this.adminPageUrl);
  }

  public isLoginPage() {
    return `/${LoginComponent.route}`;
  }

  public routeToOverview() {
    this.router.navigate([DashboardComponent.route]);
  }

  public routerEvent;

  ngOnInit(): void {
    this.subscription = this.currentUrl
      .pipe(
        filter((url) => url !== this.landingPageUrl && url !== '/login'),
        switchMap((url) => this.tenantService.loggedInTenant),
        tap((tenant) => {
          this.tenant = tenant;
        })
      )
      .subscribe();

    this.routerEvent.subscribe((event) => {
      this.flyLayout?.nativeElement.hide();
      this.currentUrl.next(event['urlAfterRedirects']);
      const urlSegments: string[] = event['urlAfterRedirects'].split('/');
      this.currentRoute = `${urlSegments[urlSegments.length - 2]}/${
        urlSegments[urlSegments.length - 1]
      }`;
      const url = this.currentUrl.value;
      if (url.includes(this.adminPageUrl)) {
        this.menuEntries = ROUTE_CONFIG.filter((menuEntry) =>
          this.isAdminPage(menuEntry.routePath)
        );
      } else {
        this.menuEntries = ROUTE_CONFIG.filter(
          (menuEntry) => !this.isAdminPage(menuEntry.routePath)
        );
      }

      if (this.currentUrl.value.includes(this.adminPageUrl)) {
        this.selectedRouteEntry = this.getMenuEntryByRoute(urlSegments[1]);
      } else {
        this.selectedRouteEntry = this.getMenuEntryByRoute(
          urlSegments[urlSegments.length - (urlSegments.length === 4 ? 2 : 1)]
        );
      }
    });

    this.useEnglish = this.translateService.currentLang === Language.ENGLISH;
    if (this.useEnglish) {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = 'de';
    }
  }

  ngAfterViewChecked() {
    this.updateSelectedRoute();
  }

  private updateSelectedRoute() {
    if (this.hasSubMenu(this.selectedRouteEntry)) {
      for (const tabHeader of this.tabHeaders.toArray()) {
        const shadow = tabHeader.nativeElement.shadowRoot;
        const shadowChildren: HTMLCollection =
          shadow.children as HTMLCollection;
        const spanElement = shadowChildren.item(0);
        if (spanElement) {
          this.renderer.removeClass(spanElement, 'tab-header--selected');
        }
      }
      const routeEntries = this.selectedRouteEntry.subMenuEntries;
      for (let i = 0; i < routeEntries.length; i++) {
        const applySelected =
          this.currentRoute ==
          this.selectedRouteEntry.routePath + '/' + routeEntries[i].routePath;
        if (applySelected) {
          const tabHeader = this.tabHeaders.toArray()[i];
          const shadow = tabHeader.nativeElement.shadowRoot;
          const shadowChildren: HTMLCollection =
            shadow.children as HTMLCollection;
          const spanElement = shadowChildren.item(0);
          if (spanElement) {
            this.renderer.addClass(spanElement, 'tab-header--selected');
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public navigateTo(routes: string[]) {
    const currentUrl = this.router.url;
    if (
      !this.currentUrl.value.includes(this.adminPageUrl) &&
      !routes.includes(this.adminPageUrl)
    ) {
      routes.unshift('dashboard');
    }
    this.router.navigate(routes).then(() => {
      if (this.router.url === currentUrl) {
        window.location.reload();
        this.updateSelectedRoute();
      }
    });
  }

  onLangSwitch(langKey: string): void {
    this.useEnglish = langKey === 'en';
    localStorage.setItem(
      'lan',
      this.useEnglish ? Language.ENGLISH : Language.GERMANY
    );
    this.translateService
      .use(this.useEnglish ? Language.ENGLISH : Language.GERMANY)
      .pipe(delay(100))
      .subscribe(() => window.location.reload());
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  login() {
    this.authenticationService.login();
  }

  logout() {
    localStorage.clear();
    this.authenticationService.logout();
  }

  public metaLinkNavigation(): void {
    const url = this.router.url;
    if (!this.userHasSupervisorRole || url === '/') {
      this.navigateTo([]);
    } else if (this.currentUrl.value.includes(this.adminPageUrl)) {
      this.navigateTo([DashboardComponent.route]);
    } else {
      this.navigateTo([AdminComponent.route]);
    }
  }

  userHasSupervisorRole() {
    const accessTokenRoles = this.authenticationService.getAccessTokenRoles();
    if (!accessTokenRoles) {
      return false;
    }
    return accessTokenRoles.includes('Admin.All');
  }

  userHasManagementRole() {
    const accessTokenRoles = this.authenticationService.getAccessTokenRoles();
    if (!accessTokenRoles) {
      return false;
    }
    return (
      accessTokenRoles.includes('Admin.All') ||
      accessTokenRoles.includes('Admin.All')
    );
  }

  public getMenuEntryByRoute(route: string) {
    const matches = this.menuEntries.filter(
      (entry) => entry.routePath === route
    );
    if (matches.length > 0) {
      return matches[0];
    }
    return null;
  }

  public hasSubMenu(entry: MenuEntry) {
    return entry?.subMenuEntries !== undefined;
  }
}
