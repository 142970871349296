import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlHandlingStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { VideoPopupService } from '../../../services/video-popup-service';

@Component({
  selector: 'app-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss'],
})
export class VideoSectionComponent implements OnInit {
  public videoUrl: SafeResourceUrl;
  private svgPath: string = '../../assets/svg/';
  public videoPosterUrl: string = this.svgPath + 'videoPoster.svg';
  public closeButton: string = this.svgPath + 'icons/close.svg';

  public videoPopupOpen: boolean = false;
  private apiLoaded: boolean = false;

  public sectionTexts: string[] = [];

  private videoId: string = 'YRKPqeERn4o';
  public thumbnail: string = '../../assets/svg/thumbnail.svg';
  public playButton: string = this.svgPath + '/playbutton.svg';

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private videoPopupService: VideoPopupService
  ) {}

  ngOnInit(): void {
    this.translateService
      .get([
        'landing.videoFirstParagraph',
        'landing.videoSecondParagraph',
        'landing.videoThirdParagraph',
        'landing.videoFourthParagraph',
        'landing.videoId',
      ])
      .subscribe((result) => {
        this.sectionTexts = Object.values(result);
        this.sectionTexts.splice(this.sectionTexts.length - 1, 1);
        this.videoId = result['landing.videoId'];
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/' +
            this.videoId +
            '?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0&autoplay=1'
        );
      });
    this.videoPopupService.videoPopupIsOpen.subscribe((state) => {
      this.videoPopupOpen = state;
    });
  }

  public openVideoPopup() {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
    this.videoPopupService.setVideoPopupOpen(!this.videoPopupOpen);
  }

  public closeVideoPopup() {
    this.videoPopupService.setVideoPopupOpen(false);
  }

  public extractYoutubeId(urlPath: string): string {
    const params = new URL(urlPath).searchParams;
    return params.get('v') ?? '';
  }
}
