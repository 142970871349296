export enum TransportType {
  CarBigDiesel = 'car-big-diesel',
  CarBigPetrol = 'car-big-petrol',
  CarBigElectric = 'car-big-electric',
  CarMediumDiesel = 'car-medium-diesel',
  CarMediumPetrol = 'car-medium-petrol',
  CarMediumElectric = 'car-medium-electric',
  CarSmallDiesel = 'car-small-diesel',
  CarSmallPetrol = 'car-small-petrol',
  CarSmallElectric = 'car-small-electric',
  Bus = 'bus',
  Train = 'train',
  Bike = 'bike',
  EBike = 'e-bike',
  Pedestrian = 'pedestrian',
  EScooterSmall = 'e-scooter-small',
  Tram = 'tram',
  Metro = 'metro',
  LongDistanceBus = 'long-distance-bus',
}

export const TransportTypeAttributes: TransportType[] = Object.keys(
  TransportType
).map((key) => TransportType[key]);
