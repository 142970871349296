import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Tenant } from 'src/app/models/tenant';
import { TenantService } from 'src/app/services/tenant.service';
import { TenantDetailsService } from '../details.service';
import { MatDialog } from '@angular/material/dialog';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { createEditTenantDialog } from '../../../../services/tenant-factory';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
})
export class DetailViewComponent implements OnInit, OnDestroy {
  public readonly licenseInternalName = 'license';
  public readonly activationcodeseInternalName = 'activationcodes';
  public readonly usersRolesInternalName = 'usersRoles';

  public menuItems: { internalName: string; displayKey: string }[] = [
    {
      internalName: this.licenseInternalName,
      displayKey: 'admin.detail.sub.license',
    },
    {
      internalName: this.activationcodeseInternalName,
      displayKey: 'admin.detail.sub.activationcodes',
    },
    {
      internalName: this.usersRolesInternalName,
      displayKey: 'admin.detail.sub.usersRoles',
    },
  ];

  public selectedInternalName: string = this.menuItems[0].internalName;

  public tenant: Tenant;
  private selectedTenantSubscription?: Subscription;

  constructor(
    private tenantService: TenantService,
    private tenantDetailsService: TenantDetailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedTenantSubscription =
      this.tenantService.selectedTenant.subscribe((tenant) => {
        this.tenant = tenant;
      });
  }

  public editTenantName() {
    const tenantNameKey = 'tenant-name';
    this.dialog
      .open(TableDialogComponent, {
        data: createEditTenantDialog(tenantNameKey, this.tenant.name),
      })
      .afterClosed()
      .subscribe((response) => {
        this.tenantService.updateTenant(
          this.tenant.id,
          response.get(tenantNameKey)
        );
      });
  }

  ngOnDestroy(): void {
    this.selectedTenantSubscription.unsubscribe();
  }

  selectMenuItem(menuItem: { internalName: string; displayKey: string }) {
    this.selectedInternalName = menuItem.internalName;
  }

  closeTenantDetailView() {
    this.tenantDetailsService.detailsOpen = false;
  }
}
