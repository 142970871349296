import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outro-section',
  templateUrl: './outro-section.component.html',
  styleUrls: ['./outro-section.component.scss']
})
export class OutroSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
