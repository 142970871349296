import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ActiveDirectoryAppRole,
  ActiveDirectoryUser,
} from 'src/app/models/active-directory-user';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { Tenant } from '../../models/tenant';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class TenantHttpService {
  private apiService: ApiService = inject(ApiService);

  private tenants$ = this.apiService.get({
    path: `${APIConst.GET_TENANTS + this.apiService.requestParamsObj({})}`,
    endp: environment.apiPath,
    observe: true,
    sendIDToken: true,
  });

  private environmentTenants$ = this.apiService.get({
    path: `${
      APIConst.GET_ENVIRONMENT_TENANTS + this.apiService.requestParamsObj({})
    }`,
    endp: environment.apiPath,
    observe: true,
    sendIDToken: true,
  });

  public getTenant(): Observable<Tenant> {
    return this.apiService
      .get({
        path: `${
          APIConst.GET_SINGLE_TENANT + this.apiService.requestParamsObj({})
        }`,
        endp: environment.apiPath,
        observe: true,
        // sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((tenant) => {
          return {
            ...tenant,
            id: (tenant.id as string).toLocaleLowerCase(),
          } as Tenant;
        })
      );
  }

  public getAllTenants(): Observable<Tenant[]> {
    return combineLatest([this.tenants$, this.environmentTenants$]).pipe(
      map(([tenantsValue, environmentTenantsValue]) => ({
        tenants: tenantsValue.body.tenants,
        environmentTenants: environmentTenantsValue.body.tenants,
      })),
      switchMap(({ tenants, environmentTenants }) => {
        const envTenantIds: string[] = environmentTenants.map((envTenant) =>
          (envTenant.id as string).toLocaleLowerCase()
        );
        const filteredTenants: any[] = tenants.filter((tenant) =>
          envTenantIds.includes(tenant.id)
        );

        const mappedTenants: Tenant[] = filteredTenants.map(
          ({ id, name }) =>
            ({
              id,
              name,
              isActive: true,
            } as Tenant)
        );

        return of(mappedTenants);
      })
    );
  }

  // getAllTenants(): Observable<Tenant[]> {
  //   this.getAllTenantsTest().subscribe((test) =>
  //     console.log('test data: ', test)
  //   );
  //   return this.apiService
  //     .get({
  //       path: `${APIConst.GET_TENANTS + this.apiService.requestParamsObj({})}`,
  //       endp: environment.apiPath,
  //       observe: true,
  //       sendIDToken: true,
  //     })
  //     .pipe(
  //       map((value) => value.body),
  //       map(({ tenants }) => {
  //         return tenants.map(
  //           ({ id, name }) =>
  //             ({
  //               id,
  //               name,
  //               isActive: true,
  //             } as Tenant)
  //         );
  //       })
  //     );
  // }

  createTenantInAAD(name: string): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.CREATE_TENANT}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        displayName: name,
      },
    });
  }

  addTenant(id: string, name: string): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.SUPERVISOR_TENANT}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        tenantId: id,
        name: name,
      },
    });
  }

  inviteDirectoryUser(
    name: string,
    surname: string,
    email: string,
    role: string,
    tenantId: string,
    personalRole: string
  ): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.INVITE_USER}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        name: name,
        surname: surname,
        email: email,
        group: tenantId,
        accessRole: role,
        redirectUrl: 'https://ecoshift.t-systems-mms.com',
        senderAccessRole: personalRole,
      },
    });
  }

  inviteDirectoryOwner(
    name: string,
    surname: string,
    email: string,
    tenantId: string
  ): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.INVITE_OWNER}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        name: name,
        surname: surname,
        email: email,
        group: tenantId,
        accessRole: 'e28d1237-b120-47af-8238-7e0d144489cd', //Owner role id (azure ad approle)
        redirectUrl: 'https://ecoshift.t-systems-mms.de',
      },
    });
  }

  updateUserAppRole(
    userId: string,
    oldAppRoleId: string,
    newAppRoleId: string,
    senderRoleId: string
  ) {
    return this.apiService.put({
      path: `${APIConst.UPDATE_USER_APP_ROLE}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        userId: userId,
        oldAppRoleId: oldAppRoleId,
        newAppRoleId: newAppRoleId,
        senderRoleId: senderRoleId,
      },
    });
  }

  updateOwnerAppRole(
    userId: string,
    oldAppRoleId: string,
    newAppRoleId: string
  ) {
    return this.apiService.put({
      path: `${APIConst.UPDATE_OWNER_APP_ROLE}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        userId: userId,
        oldAppRoleId: oldAppRoleId,
        newAppRoleId: newAppRoleId,
      },
    });
  }

  updateUserProfile(
    userId: string,
    name: string,
    surname: string,
    senderRoleId: string
  ) {
    return this.apiService.put({
      path: `${APIConst.UPDATE_USER}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        userId: userId,
        name: name,
        surname: surname,
        senderRoleId: senderRoleId,
      },
    });
  }

  updateTenant(tenantId: string, displayName: string) {
    return this.apiService.put({
      path: `${APIConst.SUPERVISOR_TENANT}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        tenantId: tenantId,
        name: displayName,
        isActive: true,
      },
    });
  }

  deleteUser(userId: string, tenantID: string) {
    return this.apiService.delete({
      path: `${APIConst.DELETE_USER}/${userId}/groups/${tenantID}`,
      endp: environment.apiPath,
      sendIDToken: true,
    });
  }

  deleteTenant(tenantID: string) {
    return this.apiService.delete({
      path: `${APIConst.SUPERVISOR_TENANT}/${tenantID}`,
      endp: environment.apiPath,
      sendIDToken: true,
    });
  }

  fetchAllUsers(
    tenantID: string,
    roleId?: string
  ): Observable<ActiveDirectoryUser[]> {
    let path = `/supervisor/users/${tenantID}`;
    if (roleId) {
      path += `/${roleId}`;
    }
    return this.apiService
      .get({
        path: path,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((result: any) => result.body),
        map(({ adUsers }) =>
          adUsers.map((adUser) => {
            return {
              appRole: {
                appRoleId: adUser['appRole']['appRoleId'],
                principalDisplayName: adUser['appRole']['principalDisplayName'],
              },
              name: adUser['name'] ?? '',
              surname: adUser['surname'] ?? '',
              email: adUser['email'],
              id: adUser['id'],
            } as ActiveDirectoryUser;
          })
        )
      );
  }

  fetchAllActiveDirectoryAppRoles(): Observable<ActiveDirectoryAppRole[]> {
    return this.apiService
      .get({
        path: '/supervisor/app-roles',
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((result: any) => result.body),
        map(({ appRoles }) =>
          appRoles.map((appRole) => {
            return {
              displayName: appRole['displayName'],
              id: appRole['id'],
              isEnabled: appRole['isEnabled'],
            } as ActiveDirectoryAppRole;
          })
        )
      );
  }
}
