import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TransportTypeAttributes } from '../../models/enums/transport-type.enum';
import { ReasonAttributes } from '../../models/enums/reason.enum';
import { TripDistanceAttributes } from '../../models/enums/trip.distance.enum';
import { TripTimeAttributes } from '../../models/enums/trip.time.enum';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { Observable, of } from 'rxjs';
import { Company } from '../../models/company';
import { Companies } from '../../mocks/companies';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trip-filter',
  templateUrl: './trip-filter.component.html',
  styleUrls: ['./trip-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripFilterComponent implements OnInit {
  @Input() filter: UntypedFormGroup;
  @Input() trendShowed = false;
  transportTypeOptions: any[];
  tripDistanceOptions: any[];
  tripTimeOptions: any[];
  tripReasons: any[];

  constructor(private translateService: TranslateService, public companyService: CompanyService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    if (!this.trendShowed) {
      this.translateService.get(TransportTypeAttributes.map(attr => 'common.' + attr)).subscribe((translations) =>
        this.transportTypeOptions = TransportTypeAttributes.map(value => ({name: translations['common.' + value], value}))
      );
      this.translateService.get(TripDistanceAttributes.map(attr => 'common.' + attr)).subscribe((translations) =>
        this.tripDistanceOptions = TripDistanceAttributes.map(value => ({name: translations['common.' + value], value}))
      );
      this.translateService.get(TripTimeAttributes.map(attr => 'common.' + attr)).subscribe((translations) =>
        this.tripTimeOptions = TripTimeAttributes.map(value => ({name: translations['common.' + value], value}))
      );
      this.translateService.get(ReasonAttributes.map(attr => 'common.' + attr)).subscribe((translations) =>
        this.tripReasons = ReasonAttributes.map(value => ({name: translations['common.' + value], value}))
      );
    }
  }

  removeFromGroup(control: string, formGroup: AbstractControl): void {
    formGroup.setValue(formGroup.value.filter(con => con.name !== control));
  }

  getAllCompanies() : Observable<Company[]>{

    return this.companyService.dashboardCompanies
  }
}
