<div *ngIf="transportType !== undefined && transportType !== ''" class="p-grid p-nogutter p-ai-center"
  style="height: 100%">
  <div class="p-col-12 no-padding-up-down">
    <div class="p-grid p-align-start">
      <div class="p-col-12 no-padding-up-down">
        <img [src]="'assets/svg/icons/transport/' + transportType + '.svg'" [alt]="">
      </div>
      <div class="p-col-12 no-padding-up-down">
        {{'common.' + transportType | translate}}
      </div>
    </div>
  </div>
  <div class="p-col-12 no-padding-up-down p-as-end">
    <div class="bold">
      <h2 class="p-d-inline">{{distance | number:'.0-2': translateService.currentLang}}</h2> km
    </div>
  </div>
</div>