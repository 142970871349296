<div class="p-grid no-margin">
  <div class="p-col-12">
    <div class="dashboard-card">
      <div class="logo"><img src="{{img}}" alt="logo"/></div>
      <div class="text-header">
        <div class="title">{{office}}</div>
        <div *ngIf="street">{{street}}</div>
      </div>
    </div>
  </div>
</div>
