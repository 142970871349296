import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChallengesComponent } from '../challenges/challenges.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { ManageComponent } from '../manage/manage.component';
import { OverviewComponent } from '../overview/overview.component';
import { TrendsComponent } from '../trends/trends.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: OverviewComponent,
  },
  {
    path: 'trends',
    component: TrendsComponent,
  },
  {
    path: ChallengesComponent.route,
    component: ChallengesComponent,
  },
  {
    path: FeedbackComponent.route,
    component: FeedbackComponent,
  },
  {
    path: ManageComponent.route,
    loadChildren: () =>
      import('../manage/manage-routing.module').then(
        (m) => m.ManageRoutingModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardRoutingModule {}
