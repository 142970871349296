import { Component, Input, OnInit } from '@angular/core';
import {
  Brand,
  BrandColor,
  BrandColors,
  SurfaceColors,
  TextColors,
} from 'src/app/models/brand';
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-brand-section',
  templateUrl: './brand-section.component.html',
  styleUrls: ['./brand-section.component.scss'],
})
export class BrandSectionComponent implements OnInit {
  @Input() key: string;
  @Input() colors: BrandColors | TextColors | SurfaceColors;
  @Input() hasDarkColors: boolean = true;

  public sortedColors: BrandColor[] = [];

  constructor() {}

  ngOnInit(): void {}

  public instanceOfSufaceColor(object: any): object is SurfaceColors {
    return 'surfaceColor' in object;
  }

  public getSorting() {
    return BrandingService.BRANDING_MAPPING.find((e) => e.key == this.key)
      .values;
  }

  public get sectionHeadlineTranslationKey(): string {
    return `branding.sections.${this.key}.headline`;
  }

  public get sectionDescriptionTranslationKey(): string {
    return `branding.sections.${this.key}.description`;
  }

  public get hasIcon(): boolean {
    return this.key !== 'brandColors';
  }
}
