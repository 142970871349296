<div class="user-roles-wrapper page-wrapper">
    <div class="intro">
        <h2>{{'usersRoles.headline'|translate}}</h2>
        <p>{{'usersRoles.introText'|translate}}
        </p>
    </div>

    <div class="users-table">
        <div class="header">
            <h3>{{'usersRoles.table.title.headline'|translate}}<span>({{this.userCount}})</span></h3>
            <scale-button (click)="addUser()" variant="ghost" size="small" class="black-ghost-color hydrated"
                icon-position="before">
                <img src="assets/svg/add.svg" class="reset-img">{{'usersRoles.table.user.add'|translate}}</scale-button>
        </div>
        <app-dashboard-table [elementsPerView]="8" [tableData]="userTableData()" (editPressed)="editUser($event)"
            (deletePressed)="deleteUser($event)"></app-dashboard-table>
    </div>
</div>