import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReportGrouped } from '../../models/report';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {
  @Input() reports$: Observable<ReportGrouped[]>;
  @Input() filter: UntypedFormGroup;
  @Input() trendShowed = false;
  filterReset: Subject<void> = new Subject<void>();

  constructor(public loaderService: LoaderService) {}

  resetFilter(): void {
    if (this.trendShowed) {
      this.filter.patchValue({
        company: null,
        autoRecorded: false,
        homeOffice: false,
        causedByCompanyCar: 'All',
      });
    } else {
      this.filterReset.next();
      this.filter.patchValue({
        from: null,
        to: null,
        transportType: null,
        tripDistance: null,
        tripTime: null,
        tripReason: null,
        company: null,
        autoRecorded: false,
        homeOffice: false,
        causedByCompanyCar: 'All',
      });
    }
  }

  isDisabled(): boolean {
    const fields = this.trendShowed
      ? ['company']
      : [
          'from',
          'to',
          'transportType',
          'tripDistance',
          'tripTime',
          'tripReason',
          'company',
        ];
    return (
      !fields.reduce(
        (acc, fieldName) =>
          (this.filter.get(fieldName)?.value !== undefined &&
            this.filter.get(fieldName)?.value !== null) ||
          acc,
        false
      ) &&
      !this.filter.get('autoRecorded')?.value &&
      !this.filter.get('homeOffice')?.value
    );
  }
}
