import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrendsComponent } from './trends.component';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FilterModule } from '../../components/filter/filter.module';
import { LoaderModule } from '../../components/loader/loader.module';

@NgModule({
  declarations: [TrendsComponent],
  exports: [TrendsComponent],
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    DropdownModule,
    ChartModule,
    FormsModule,
    MultiSelectModule,
    InputSwitchModule,
    ReactiveFormsModule,
    FilterModule,
    LoaderModule,
  ],
})
export class TrendsModule {}
