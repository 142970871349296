<footer id="footer" [class.footer-spacing]="!isLoginPage()">
  <div class="img-container">
    <img src="assets/svg/logo.svg" alt="Footer logo" routerLink="dashboard">
  </div>
  <div class="footer-title">
    <div class="versioning">
      <p>Dashboard Version: {{dashboardInfo.frontendVersion}}</p>
      <p>Backend Version: {{dashboardInfo.backendVersion}}</p>
    </div>
    <a>© Deutsche Telekom GmbH</a>
  </div>

  <div class="operations">
    <ng-container *ngFor="let item of footerNavigation">
      <div *ngIf="item.mailto === undefined" class="footer-item" [id]="item.id" [routerLink]="item.href"><i
          *ngIf="item.icon" [class]="item.icon"></i> {{'footer.' +
        item.name | translate}}
      </div>
      <a *ngIf="item.mailto !== undefined" href="mailto:{{item.mailto}}" class="footer-item"><i *ngIf="item.icon"
          [class]="item.icon"></i> {{'footer.' + item.name |
        translate}}</a>
    </ng-container>
  </div>
</footer>