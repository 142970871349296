import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputResult } from '../table-dialog/table-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnInit {
  @Input() data: any;

  showDropdown = false;
  public dropDownOptions: string[] = [];
  public selectedValue: string;

  public inputLabel: string;
  public inputPlaceHolder: string = '';
  public suffix: string;

  @Output() changes: EventEmitter<InputResult> = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    const element = this.data['element'];
    if (element) {
      this.inputLabel = element['prefix'];
      this.inputPlaceHolder = element['placeHolder'];
      this.suffix = element['suffix'];
      this.dropDownOptions = element['options'];
      const value = element['value'];

      if (value) {
        this.selectedValue = value;
      }
    }
  }

  //Method neeeds to be implemented and is empty, because the select has no touched state
  public wasTouched() { }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: string) {
    this.selectedValue = option;
    this.showDropdown = false;
    this.changes.emit({ value: option, valid: true });
  }

  getTranslatedRole(option: string): any {
    return this.translate.instant('usersRoles.table.dialog.role.' + option);
  }

}
