import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandingComponent } from './branding/branding.component';
import { DataComponent } from './data/data.component';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { LicenseComponent } from './license/license.component';
import { ManageComponent } from './manage.component';
import { RouterModule, Routes } from '@angular/router';
import { ManageRoutingModule } from './manage-routing.module';
import { LogoDragDropComponent } from './branding/components/logo-drag-drop/logo-drag-drop.component';
import { SharedModule } from '../../shared/shared.module';
import { BrandCardComponent } from './branding/components/brand-card/brand-card.component';
import { BrandSectionComponent } from './branding/components/brand-section/brand-section.component';
import { BrandColorSelectorComponent } from './branding/components/brand-color-selector/brand-color-selector.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    BrandingComponent,
    DataComponent,
    UsersRolesComponent,
    LicenseComponent,
    ManageComponent,
    LogoDragDropComponent,
    BrandCardComponent,
    BrandSectionComponent,
    BrandColorSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ManageRoutingModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    MatDialogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ManageComponent, BrandingComponent],
})
export class ManageModule {}
