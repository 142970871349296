import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortByTransportPipe } from './sort-by-transport.pipe';
import { FilterEcoPipe } from './filter-eco.pipe';
import { AggregateByOfficePipe } from './aggregate-by-office.pipe';
import { FilterSixPipe } from './filter-six.pipe';
import { SumDistancePipe } from './sum-distance.pipe';
import { CountRecordsPipe } from './count-records.pipe';

@NgModule({
  declarations: [
    AggregateByOfficePipe,
    FilterEcoPipe,
    CountRecordsPipe,
    SortByTransportPipe,
    FilterSixPipe,
    SumDistancePipe,
  ],
  exports: [
    AggregateByOfficePipe,
    FilterEcoPipe,
    SortByTransportPipe,
    FilterSixPipe,
    CountRecordsPipe,
    SumDistancePipe,
  ],
  imports: [CommonModule],
  providers: [AggregateByOfficePipe, FilterEcoPipe, SortByTransportPipe],
})
export class PipeModule {}
