import { Pipe, PipeTransform } from '@angular/core';
import { ReportGrouped } from '../models/report';
import { EcoTransportTypes } from '../models/const/common';

@Pipe({
  name: 'filterEco',
})
export class FilterEcoPipe implements PipeTransform {
  constructor() {
  }

  transform(reports: ReportGrouped[]): ReportGrouped[] {
    return reports.filter((report) => EcoTransportTypes.find((eco) => report.transportType === eco));
  }
}
