import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-privacy-page',
  templateUrl: './data-privacy-page.component.html',
  styleUrls: ['./data-privacy-page.component.scss'],
})
export class DataPrivacyPageComponent implements OnInit {
  public static get route(): string {
    return 'privacy-policy';
  }

  private language: string = '';

  public get langIsDe(): boolean {
    return this.language === 'de';
  }

  public get langIsEn(): boolean {
    return this.language === 'en';
  }

  constructor(private translateService: TranslateService) {
    this.language = this.translateService.currentLang;
  }

  ngOnInit(): void {}
}
