import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/models/company';
import { Office } from 'src/app/models/office';
import { SurveyResponse } from 'src/app/models/survey/response/survey-response';
import { Survey } from 'src/app/models/survey/survey';
import { CompanyService } from 'src/app/services/company.service';
import { OfficeService } from 'src/app/services/office.service';

type SurveyWithRespose = Survey & {
  numberOfSubmits: number;
  companyNames: string[];
  responses: SurveyResponse[];
};

@Component({
  selector: 'app-survey-preview-modal',
  templateUrl: './survey-preview-modal.component.html',
  styleUrls: ['./survey-preview-modal.component.scss'],
})
export class SurveyPreviewModalComponent implements OnInit {
  private translateService: TranslateService = inject(TranslateService);
  @Input() showModal: boolean = false;
  @Input() previewMode: string = '';
  @Input() rangeValue: number = 10;
  @Input() surveyResults: any;
  @Input() survey: SurveyWithRespose;
  @Output() isModalOpen = new EventEmitter<boolean>();
  @Input() isSinglePage: boolean = false;
  totalPages: number = 0;
  currentPage: number = 0;
  currentResponse: SurveyResponse;
  officeId: any = null;
  currentOffice: Office;
  companyId: string;
  currentLang: string;
  currentCompany: Company;
 
  constructor(
    private officeService: OfficeService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    if (this.previewMode === 'Individual Response Preview') {
      this.totalPages = this.survey.responses.length;
      if (this.isSinglePage) {
        this.totalPages = 1;
      }

      this.setCurrentResponse();
    }
  }

  closeModal() {
    this.showModal = false;
    this.isModalOpen.emit(false);
    document.body.style.overflow = '';
  }

  onPageChange($event: any) {
    this.currentPage = $event.detail.startElement;
    this.setCurrentResponse();
    this.getOfficeById(this.officeId);
    this.getCompanyById(this.companyId);
  }

  setCurrentResponse() {
    const startIndex = this.currentPage;
    this.currentResponse = this.survey.responses[startIndex];
    this.officeId = this.currentResponse.officeId;
    this.companyId = this.currentResponse.companyId;
    this.getOfficeById(this.officeId);
    this.getCompanyById(this.companyId);
  }

  getOfficeById(officeId: string) {
    this.officeService.get(officeId).subscribe(
      (office: any) => {
        this.currentOffice = office;
      },
      (error: any) => {
        console.error('Error fetching office:', error);
      }
    );
  }

  getCompanyById(companyID: string) {
    this.companyService.get(companyID).subscribe(
      (company: any) => {
        this.currentCompany = company;
      },
      (error: any) => {
        console.error('Error fetching company:', error);
      }
    );
  }

  valueHandler(value) {
    if (this.currentResponse)
      return this.currentResponse.inputs.find(
        (input) => input.key === value.identifier
      ).value;
    return '';
  }

  getTranslationForKey(input: any, key: string): string {
    if (input && key)
      return input.options.values.find((value) => value.key === key).value[
        this.currentLang
      ];
    return '';
  }

  isMap(value: any): value is Map<any, any> {
    return value instanceof Map;
  }
}
