<div class="page">
    <div class="content">
        <h2>{{'welcome.welcome-intro' | translate}}</h2>
        <br>
        <p *ngIf="loggedInTenant$ | async; let tenant">{{('welcome.welcome-text' | translate).replace('%s', tenant !==
            undefined ? tenant.name : '')}}</p>
        <br>
        <scale-button (click)="dashboardLink()" size="small"><span>{{'welcome.dashboard-button' |
                translate}}</span></scale-button>
    </div>
</div>