import { Pipe, PipeTransform } from '@angular/core';
import { ReportGrouped } from '../models/report';
import { ReportAggregate } from '../models/report-aggregate';
import { FilterEcoPipe } from './filter-eco.pipe';
import { Reason } from '../models/enums/reason.enum';
import { EmissionsField, TripDistanceField } from '../models/const/common';
import { ReportUtils } from '../utils/report-utils';
import { Office } from '../models/office';

@Pipe({
  name: 'aggregateByOffice',
})
export class AggregateByOfficePipe implements PipeTransform {
  constructor(private ecoFilter: FilterEcoPipe) {}

  transform(reports: ReportGrouped[]): ReportAggregate[] {
    if (reports) {
      const combinedReports: ReportGrouped[] = [];

      const offices: Office[] = reports.map((report) => report.office);
      return offices
        .map((office) => {
          const officeReports = reports.filter(
            (report) => report.office?.id === office?.id
          );
          const eco = this.ecoFilter.transform(officeReports);
          return {
            office,
            totalEmissions: ReportUtils.sumByReason(
              officeReports,
              undefined,
              EmissionsField
            ),
            commuteEmissions: ReportUtils.sumByReason(
              officeReports,
              Reason.Commute,
              EmissionsField
            ),
            businessEmissions: ReportUtils.sumByReason(
              officeReports,
              Reason.BusinessTrip,
              EmissionsField
            ),
            commuteDistance: ReportUtils.sumByReason(
              officeReports,
              Reason.Commute,
              TripDistanceField
            ),
            businessDistance: ReportUtils.sumByReason(
              officeReports,
              Reason.BusinessTrip,
              TripDistanceField
            ),
            commuteEcoDistance: ReportUtils.sumByReason(
              eco,
              Reason.Commute,
              TripDistanceField
            ),
            businessEcoDistance: ReportUtils.sumByReason(
              eco,
              Reason.BusinessTrip,
              TripDistanceField
            ),
            reportsCount: officeReports.reduce(
              (acc, report) => acc + report.reportCount,
              0
            ),
          };
        })
        .sort(
          (office1, office2) => office2.totalEmissions - office1.totalEmissions
        );
    } else {
      return [];
    }
  }
}
