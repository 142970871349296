<div class="heading-item">
    <h3>{{'feedbacks.title.select.select'|translate}}</h3>
    <scale-checkbox label="{{'feedbacks.title.buttons.mandatory'|translate}}" [attr.checked]="isMandatory"
        class="padding-checkbox" (scale-change)="isMandatoryOnChange($event)"></scale-checkbox>
</div>
<div class="margin-top-20">
    <div class="p-grid">
        <!-- <div class="p-col-6">
            <div class="rating-style">
                <label class="label-title" for="rating-type">{{'feedbacks.title.select.style'|translate}}</label>
                <scale-dropdown-select id="rating-type" (scale-change)="selectStyleChange($event)"
                    [attr.value]="selectStyle" class="scale-dropdown-select-item">
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="checkbox-group">{{'feedbacks.title.select.checkboxGroup'|translate}}</scale-dropdown-select-item>
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="radiobutton-group">{{'feedbacks.title.select.radioButtonGroup'|translate}}</scale-dropdown-select-item>
                </scale-dropdown-select>
            </div>
        </div> -->
        <scale-divider class="scale-divider-item margin-bottom-xl no-padding"></scale-divider>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-12 p-sm-5 p-xs-5">
                    <label class="label-title"
                        for="question-de">{{'feedbacks.title.surveys.questionDE'|translate}}</label>
                    <scale-textarea id="question-en" label="{{'feedbacks.title.surveys.questionDE'|translate}}"
                        [value]="selectQuestionDe" rows="5" max-length="520" counter
                        [invalid]="!(selectQuestionDe || valid)"
                        [attr.helper-text]=" (selectQuestionDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        (scaleChange)="selectQuestionDeAdd($event)">
                    </scale-textarea>
                </div>
                <div class="p-col-12 p-sm-5 p-xs-5 pos-relative">
                    <label class="label-title"
                        for="question-en">{{'feedbacks.title.surveys.questionEN'|translate}}</label>
                    <scale-textarea id="question-en" label="{{'feedbacks.title.surveys.questionEN'|translate}}"
                        [value]="selectQuestionEn" rows="5" max-length="520" counter
                        [invalid]="!(selectQuestionEn || valid)"
                        [attr.helper-text]=" (selectQuestionDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        (scaleChange)="selectQuestionEnAdd($event)">
                    </scale-textarea>
                </div>
            </div>
        </div>

        <div class="p-col-12" *ngFor="let optionItem of optionSets; let i = index">
            <div class="p-grid">
                <div class="p-col-5">
                    <label class="label-title"
                        for="button-label-de">{{'feedbacks.title.select.radioButtonLabel'|translate}} {{i+1}}
                        (DE)</label>
                    <scale-text-field id="button-label-de"
                        label="{{'feedbacks.title.select.radioButtonLabel'|translate}} (DE)" rows="5"
                        [invalid]="!(optionItem.value.get('de') || valid)"
                        [attr.helper-text]=" (optionItem.value.get('de') || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        [value]="optionItem.value.get('de')"
                        (scaleChange)="selectRadioLabelDeAdd($event,i)"></scale-text-field>
                </div>
                <div class="p-col-5">
                    <label class="label-title"
                        for="button-label-en">{{'feedbacks.title.select.radioButtonLabel'|translate}} {{i+1}}
                        (EN)</label>
                    <scale-text-field id="button-label-en"
                        label="{{'feedbacks.title.select.radioButtonLabel'|translate}} (EN)" rows="5"
                        [invalid]="!(optionItem.value.get('en') || valid)"
                        [attr.helper-text]=" (optionItem.value.get('en') || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        [value]="optionItem.value.get('en')"
                        (scaleChange)="selectRadioLabelEnAdd($event,i)"></scale-text-field>
                </div>
                <div class="buttons-options-container p-col-2">
                    <scale-button *ngIf="i > 0" icon-only variant="ghost" (click)="moveOptionSetBackward(i)"
                        class="black-ghost-color"><scale-icon-navigation-collapse-up accessibility-title="up"
                            size="24" />
                    </scale-button>
                    <scale-button *ngIf="i+1 < optionSets.length " icon-only variant="ghost"
                        (click)="moveOptionSetForward(i)" class="black-ghost-color"><scale-icon-navigation-collapse-down
                            size="24" accessibility-title="down" />
                    </scale-button>
                    <ng-container *ngIf="selectStyle === 'radiobutton-group'">
                        <scale-button *ngIf="optionSets.length > 2" icon-only (click)="removeRatingSet(i)"
                            variant="secondary" class="scale-button-del-radio scale-button-secondary black-ghost-color"
                            variant="ghost"><scale-icon-action-remove
                                attr.accessibility-title="{{'feedbacks.title.buttons.remove' | translate}}" size="24" />
                        </scale-button>
                    </ng-container>
                    <ng-container *ngIf="selectStyle === 'checkbox-group'">
                        <scale-button *ngIf="optionSets.length > 1" icon-only (click)="removeRatingSet(i)"
                            variant="secondary" class="scale-button-del-radio scale-button-secondary black-ghost-color"
                            variant="ghost">
                            <scale-icon-action-remove
                                attr.accessibility-title="{{'feedbacks.title.buttons.remove' | translate}}" size="24" />
                        </scale-button>
                    </ng-container>
                </div>
            </div>
        </div>
        <scale-button class="scale-button-secondary black-ghost-color margin-left-xxs" variant="ghost"
            (click)="addSelectSet()"><scale-icon-action-add accessibility-title="add" />
            {{'feedbacks.title.select.addRadioButton'|translate}} </scale-button>
    </div>
</div>