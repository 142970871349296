import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Language } from 'src/app/app.component';
import { SurveyService } from '../../services/survey.service';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyPage } from 'src/app/models/survey/survey-page';
import { SurveyInput } from 'src/app/models/survey/survey-inputs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public headerImage: string = '';
  public footerImage: string = '';

  public tSystemsLogo: string = '';
  public highlightText: string = '';
  public learnMoreTextPieces: string[] = [];
  useEnglish: boolean;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.useEnglish = this.translateService.currentLang === Language.ENGLISH;
    const svgPath = '../../assets/svg';
    this.headerImage = svgPath + '/landing_page_header_background.svg';
    this.footerImage = svgPath + '/landing_page_footer_background.svg';
    this.tSystemsLogo = svgPath + '/logo-t-systems-white.svg-data.svg';

    forkJoin([
      this.translateService.get('landing.learnMore'),
      this.translateService.get('landing.learnMoreLinkHighlight'),
    ]).subscribe((response) => {
      const learnMoreText: string = response[0];
      this.highlightText = response[1];
      this.learnMoreTextPieces = learnMoreText.split(this.highlightText);
    });
  }
}
