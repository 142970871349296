<ng-container *ngIf="currentUrl | async; let url">
    <scale-telekom-header appName="Ecoshift{{tenant !== undefined ? ' - '+tenant.name : ''}}" slot="header"
        class="hydrated" [logoHref]="'/dashboard'" type="slim" *ngIf="url === landingPageUrl || url === isLoginPage()">
        <scale-telekom-nav-list slot="functions" aria-label="Meta Navigation" class="scale-telekom-nav-list hydrated"
            role="menu" alignment="right">
            <scale-telekom-nav-item (click)="metaLinkNavigation()" aria-label="{{'header.secondInternal'|translate}}"
                variant="meta-nav" class="scale-telekom-nav-item hydrated" role="none">
                <a role="menuitem">{{'header.dashboardLink' |translate}}</a>
            </scale-telekom-nav-item>

            <scale-telekom-nav-list aria-label="{{'header.languageSwitch'|translate}}"
                class="scale-telekom-nav-list hydrated">
                <scale-telekom-nav-item *ngFor="let lan of languages" aria-label="{{lan.toUpperCase()}}"
                    variant="lang-switcher" class="scale-telekom-nav-item hydrated lang-switch-item" role="none"
                    (click)="onLangSwitch(lan)" aria-current="true" [active]="selectedLanguage === lan">
                    <a title="{{lan.toUpperCase()}}" role="menuitem">{{lan.toUpperCase()}}</a>
                </scale-telekom-nav-item>
            </scale-telekom-nav-list>
        </scale-telekom-nav-list>
    </scale-telekom-header>
    <ng-container *ngIf="url !== landingPageUrl && url !== '/login'">
        <scale-telekom-header *ngIf="tenant !== undefined"
            appName="Ecoshift - {{tenant !== undefined ? tenant.name : ''}}" slot="header" class="hydrated"
            [logoHref]="'/dashboard'">
            <scale-telekom-nav-list
                *ngIf="selectedRouteEntry !== undefined && selectedRouteEntry !== null && url !== '/privacy-policy' && url !== '/imprint'"
                slot="main-nav" variant="main-nav" aria-label="{{'header.mainNavigation'|translate}}"
                class="scale-telekom-nav-list hydrated" role="menu" alignment="left">
                <ng-container *ngFor="let menuEntry of menuEntries">
                    <scale-telekom-nav-item *appRoleProtected="menuEntry.restrictedTo"
                        [active]="selectedRouteEntry.routePath===menuEntry.routePath"
                        aria-label="{{menuEntry.displayName.get(selectedLanguage)}}" variant="main-nav"
                        class="scale-telekom-nav-item hydrated" role="none">
                        <button (click)="navigateTo([menuEntry.routePath])" aria-current="true" aria-haspopup="true"
                            aria-expanded="false"
                            role="menuitem">{{menuEntry.displayName.get(selectedLanguage)}}</button>

                    </scale-telekom-nav-item>
                </ng-container>
            </scale-telekom-nav-list>


            <scale-telekom-nav-list slot="meta-nav" variant="meta-nav" aria-label="Meta Navigation"
                class="scale-telekom-nav-list hydrated" role="menu" alignment="left">
                <scale-telekom-nav-item (click)="metaLinkNavigation()"
                    aria-label="{{'header.secondInternal'|translate}}" variant="meta-nav"
                    class="scale-telekom-nav-item hydrated" role="none">
                    <a role="menuitem">{{(userHasSupervisorRole() ? (url.includes(this.adminPageUrl) ?
                        'header.dashboardLink' :
                        'header.adminLink'):'header.dashboardLink')|translate}}</a>
                </scale-telekom-nav-item>
            </scale-telekom-nav-list>


            <scale-telekom-nav-list slot="lang-switcher" variant="lang-switcher" alignment="right"
                aria-label="{{'header.languageSwitch'|translate}}" class="scale-telekom-nav-list hydrated" role="menu">
                <scale-telekom-nav-item *ngFor="let lan of languages" aria-label="{{lan.toUpperCase()}}"
                    variant="lang-switcher" class="scale-telekom-nav-item hydrated lang-switch-item" role="none"
                    (click)="onLangSwitch(lan)" aria-current="true" [class.lang-item-active]="selectedLanguage === lan">
                    <a title="{{lan.toUpperCase()}}" role="menuitem">{{lan.toUpperCase()}}</a>
                </scale-telekom-nav-item>
            </scale-telekom-nav-list>

            <scale-telekom-nav-list slot="functions" variant="functions" alignment="right"
                aria-label="{{'header.functionsMenu'|translate}}" class="scale-telekom-nav-list hydrated" role="menu">


                <scale-telekom-nav-item (click)="isLoggedIn()?logout():login()" aria-label="Login" variant="functions"
                    class="scale-telekom-nav-item hydrated" role="none"><a role="menuitem">
                        <scale-badge no-dot="" label="{{isLoggedIn()?'Logout':'Login'}}" class="hydrated">
                            <scale-icon-user-file-user size="24" class="hydrated"
                                style="display: inline-flex;"></scale-icon-user-file-user>
                        </scale-badge>
                    </a>
                </scale-telekom-nav-item>

                <scale-telekom-nav-item hide-on-desktop="" aria-label="Menu" variant="functions"
                    class="scale-telekom-nav-item scl-hide-on-desktop hydrated" role="none">
                    <button aria-haspopup="true" aria-expanded="false" role="menuitem">
                        <scale-badge class="hydrated">
                            <scale-icon-action-menu size="24" class="hydrated" style="display: inline-flex;">
                            </scale-icon-action-menu>
                        </scale-badge>
                    </button>
                    <scale-telekom-nav-flyout variant="mobile" class="hydrated" #telekomNavLayout>
                        <scale-telekom-mobile-flyout-canvas class="hydrated"><scale-telekom-nav-list
                                slot="mobile-before-main-nav" variant="lang-switcher" alignment="left"
                                aria-label="{{'header.languageSwitch'|translate}}"
                                class="scale-telekom-nav-list hydrated" role="menu">

                                <scale-telekom-nav-item *ngFor="let lan of languages" (click)="onLangSwitch(lan)"
                                    title="{{lan.toUpperCase()}}" aria-current="true"
                                    [class.lang-item-active]="selectedLanguage === lan" variant="lang-switcher"
                                    class="scale-telekom-nav-item hydrated" role="none">
                                    <a title="{{lan.toUpperCase()}}" aria-current="true"
                                        role="menuitem">{{lan.toUpperCase()}}</a>
                                </scale-telekom-nav-item>

                            </scale-telekom-nav-list>
                            <scale-telekom-nav-list slot="mobile-before-main-nav" variant="meta-nav"
                                aria-label="Meta Navigation" class="scale-telekom-nav-list hydrated" role="menu"
                                alignment="right">
                                <scale-telekom-nav-item (click)="metaLinkNavigation()"
                                    aria-label="{{'header.secondInternal'|translate}}" variant="meta-nav"
                                    class="scale-telekom-nav-item hydrated" role="none">
                                    <a role="menuitem">{{(userHasSupervisorRole() ? (url.includes(this.adminPageUrl) ?
                                        'header.dashboardLink' :
                                        'header.adminLink'):'header.dashboardLink')|translate}}</a>
                                </scale-telekom-nav-item>
                            </scale-telekom-nav-list>
                            <ng-container *ngIf="url !== landingPageUrl">
                                <scale-telekom-mobile-menu
                                    *ngIf="selectedRouteEntry !== undefined && selectedRouteEntry !== null"
                                    slot="mobile-main-nav" class="hydrated">
                                    <ng-container *ngFor="let menuEntry of menuEntries">
                                        <scale-telekom-mobile-menu-item *appRoleProtected="menuEntry.restrictedTo"
                                            level="0" [active]="selectedRouteEntry.routePath===menuEntry.routePath"
                                            current-level="0" class="hydrated"
                                            aria-label="{{menuEntry.displayName.get(selectedLanguage)}}"><a
                                                (click)="navigateTo([menuEntry.routePath])">
                                                {{menuEntry.displayName.get(selectedLanguage)}}
                                            </a></scale-telekom-mobile-menu-item>
                                    </ng-container>
                                </scale-telekom-mobile-menu>
                            </ng-container>
                        </scale-telekom-mobile-flyout-canvas>
                    </scale-telekom-nav-flyout>
                </scale-telekom-nav-item>
            </scale-telekom-nav-list>
        </scale-telekom-header></ng-container>
</ng-container>
<ng-container *ngIf="currentUrl | async; let url">
    <h1 class="page-title container--centered" *ngIf="selectedRouteEntry !== undefined && selectedRouteEntry !== null">
        {{selectedRouteEntry.displayName.get(selectedLanguage)}}</h1>
    <div class="tab-navigation container--centered" [class.top-spacing]="true"
        *ngIf="url !== landingPageUrl && url !== '/login'">
        <ng-container *ngIf="hasSubMenu(selectedRouteEntry)">
            <scale-tab-nav>
                <ng-container *ngFor="let subMenuEntry of selectedRouteEntry.subMenuEntries">
                    <scale-tab-header slot="tab"
                        *appRoleProtected="subMenuEntry.restrictedTo;forbidden:subMenuEntry.forbiddenFor" #tabHeader
                        (click)="navigateTo([selectedRouteEntry.routePath,subMenuEntry.routePath])">
                        {{subMenuEntry.displayName.get(selectedLanguage)}}
                    </scale-tab-header>
                    <scale-tab-panel slot="panel"></scale-tab-panel>
                </ng-container>
            </scale-tab-nav>
        </ng-container>
    </div>
</ng-container>