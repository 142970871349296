<div *ngIf="hasFilterOptions() && appliedFilters.length > 0" class="filter-tab">
    <div class="applied-filters">
        <ng-container *ngFor="let selectedOption of appliedFilters">
            <scale-tag *ngFor="let option of selectedOptionsToOption(selectedOption)"
                (scaleClose)="removeOption(option)" (click)="removeOption(option)"
                dismissable><span>{{option.selectedOption}}</span></scale-tag>
        </ng-container>
    </div>
    <app-button (click)="deSelectAllFilters()" noPaddingStyle="true" fontSizeFactor="0.875" icon="cancel"
        text="{{'table.filter.reset'|translate}}"></app-button>
</div>
<table class="my-table" *ngIf="viewModel && viewModel.columnHeaders && viewModel.columnHeaders.length > 0">
    <tr>
        <ng-container *ngFor="let column of viewModel.columnHeaders;let col = index">
            <th [class.right-aligned]="column?.rightAligned">
                <div>
                    <input type="checkbox" class="dashboard-checkbox" *ngIf="column.markable"
                        (click)="selectAllOptions()" [(ngModel)]="generalFilter">
                    <svg-icon class="sort" *ngIf="column.sortable" [svgStyle]="{ 'display': 'grid'}"
                        src="/assets/svg/sort.svg" (click)="onSort(col)"></svg-icon>

                    <span (click)="column.sortable ? onSort(col) : null" [class.sortableHover]="column.sortable">
                        {{column.title | translate}}</span>
                    <div class="filter-section" *ngIf="column.filterable">
                        <svg-icon [svgStyle]="{ 'display': 'grid'}" src="/assets/svg/filter.svg"
                            (click)="toggleFilter()"></svg-icon>
                        <app-filter-dialog #filterDialogElement class="filter-component" [hidden]="!showFilter"
                            (onExit)="toggleFilter()" (onSelectOption)="updateSelectedOptions($event, col)"
                            [options]="getFilterOptions(col)"></app-filter-dialog>
                    </div>
                </div>
            </th>
        </ng-container>
        <th></th>
    </tr>
    <tr data-hover-visible-root
        *ngFor="let rowData of usePages? pages[selectedPage] : viewModel.tableRows; let index = index">
        <ng-container *ngFor="let data of rowData.data; let index = index">
            <td [class.right-aligned]="data.rightAligned">
                <div class="check-container"><input *ngIf="index == 0 && viewModel.columnHeaders[0].markable"
                        type="checkbox" class="dashboard-checkbox" (click)="selectOption(data.text)"
                        [checked]="optionIsChecked(data.text)"><span>{{data.text}}</span></div>
            </td>
        </ng-container>
        <td class="edit-panel">
            <div class="buttons">
                <ng-container *ngIf="!data.hasTwoCustomButtons">
                    <button *ngIf="data.editable" (click)="emitEditPressed(index)">
                        <svg-icon [svgStyle]="{ 'display': 'grid'}" src="/assets/svg/edit.svg"></svg-icon>
                    </button>
                    <button
                        *ngIf="(data.hasSingleCustomButton == true && index !== 0)||(data.hasSingleCustomButton == false && data.itemDeletion && ((!data.preventFirstItemDeletion)||(data.preventFirstItemDeletion && index !== 0)))"
                        (click)="emitDeletePressed(index)"> <svg-icon [svgStyle]="{ 'display': 'grid'}"
                            src="/assets/svg/delete_outline.svg"></svg-icon>
                    </button>

                    <scale-button data-hover-visible-target
                        *ngIf="(customIsTextButton == false) && data.includesCustomButton && (index == 0 || (data.hasSingleCustomButton == false))"
                        (click)="emitCustomPressed(index)" variant="primary" size="small"
                        class="primary--gray-background">{{customButtonText}}</scale-button>

                    <app-text-button
                        *ngIf="customIsTextButton && data.includesCustomButton && (index == 0 || (data.hasSingleCustomButton == false))"
                        (click)="emitCustomPressed(index)" [text]="customButtonText | translate"
                        [icon]="customButtonIcon" [tableButtonStyle]="true" [fontSizeFactor]=".8"
                        [style]="'height: max-content;'">>
                    </app-text-button>


                </ng-container>
                <ng-container *ngIf="data.hasTwoCustomButtons">
                    <app-text-button (click)="emitCustomPressed(index)" [text]="customButtonText | translate"
                        [icon]="customButtonIcon" [tableButtonStyle]="true" [fontSizeFactor]=".8"
                        [style]="'height: max-content;'">>
                    </app-text-button>
                    <app-text-button (click)="emitSecondCustomPressed(index)"
                        [text]="secondCustomButtonText | translate" [icon]="secondCustomButtonIcon"
                        [tableButtonStyle]="true" [fontSizeFactor]=".8" [style]="'height: max-content;'">>
                    </app-text-button>
                </ng-container>
            </div>
        </td>
    </tr>
</table>
<div *ngIf="usePages" class="pagination">

    <button [class.disabled-button]="!hasPreviousPage()" (click)="hasPreviousPage() ? selectFirstPage() : null">
        <svg-icon [svgClass]="!hasPreviousPage() ? 'disabled-image' : ''" [class.disabled-image]="!hasPreviousPage()"
            [svgStyle]="{ 'display': 'grid'}" src="assets/images/shared/double-left.svg"></svg-icon>
    </button>
    <button [class.disabled-button]="!hasPreviousPage()" class="border"
        (click)="hasPreviousPage() ? selectPreviousPage():null">
        <svg-icon [svgClass]="!hasPreviousPage() ? 'disabled-image' : ''" [class.disabled-image]="!hasPreviousPage()"
            [svgStyle]="{ 'display': 'grid'}" src="assets/images/shared/left.svg"></svg-icon>
    </button>
    <span>{{this.pages.length > 0 ? selectedPage+1 : 0}}/{{this.pages.length}}</span>
    <button class="border" [class.disabled-button]=" !hasNextPage()" (click)="hasNextPage() ? selectNextPage() : null">
        <svg-icon [svgClass]="!hasNextPage() ? 'disabled-image' : ''" [class.disabled-image]="!hasNextPage()"
            [svgStyle]="{ 'display': 'grid'}" src="assets/images/shared/right.svg"></svg-icon>

    </button>
    <button [class.disabled-button]=" !hasNextPage()" [svgStyle]="{ 'display': 'grid'}"
        (click)="hasNextPage() ? selectLastPage() : null">
        <svg-icon [svgClass]="!hasNextPage() ? 'disabled-image' : ''" [class.disabled-image]="!hasNextPage()"
            [svgStyle]="{ 'display': 'grid'}" src="assets/images/shared/double-right.svg"></svg-icon>
    </button>
</div>