import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import {
  ActiveDirectoryAppRole,
  ActiveDirectoryUser,
  User,
} from 'src/app/models/active-directory-user';
import { TableData, TableRow } from 'src/app/models/table-data';
import { ContentLoadingService } from 'src/app/services/content-loading.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { TokenUtil } from 'src/app/utils/token-util';
import {
  createAddUserDialog,
  createDeleteUserDialog,
  createEditUserDialog,
} from '../../../services/user-roles-dialog-factory';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss'],
})
export class UsersRolesComponent implements OnInit, OnDestroy {
  public static get route(): string {
    return 'users-roles';
  }

  public userData: BehaviorSubject<TableData> = new BehaviorSubject(undefined);

  public directoryUsers: User[] = [];

  public userCount: number = 0;

  private userDataSubscription?: Subscription;

  private avaibleRoles: ActiveDirectoryAppRole[] = [];

  constructor(
    private tenantService: TenantService,
    private dialog: MatDialog,
    private contentLoadingService: ContentLoadingService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initializeUserTableData();
    const fetchUsersObservable = this.tenantService.fetchAllUsers(false, '');
    const fetchAppRolesObservable = this.tenantService.fetchAllAppRoles();

    this.userDataSubscription = forkJoin([
      fetchAppRolesObservable,
      fetchUsersObservable,
    ]).subscribe(
      (response: [ActiveDirectoryAppRole[], ActiveDirectoryUser[]]) => {
        this.avaibleRoles = response[0];
        this.directoryUsers = response[1].map((activeDirectoryUser) => {
          return {
            activeDirectoryAppRole: response[0].filter(
              (activeDirectoryAppRole) =>
                activeDirectoryUser.appRole.appRoleId ===
                activeDirectoryAppRole.id
            )[0],
            activeDirectoryUser: activeDirectoryUser,
          } as User;
        });
        this.userCount = this.directoryUsers.length;
        this.initializeUserTableData();
      }
    );
  }

  ngOnDestroy(): void {
    this.userDataSubscription?.unsubscribe();
  }

  public addUser() {
    const nameKey = 'name-key';
    const surnameKey = 'surname-key';
    const emailKey = 'email-key';
    const roleKey = 'role-key';

    const adminRoles: string[] = [
      'f783c54f-06ad-4007-a093-3e072ce3bdea', //Editor
      'e3f3634c-d4fb-4803-ae3b-208bb9f0e161', //Analyst
      '62e51e31-d6f5-4637-aa8e-62a4199e4be6', //Admin
    ];

    const ownerRoles: string[] = [
      ...adminRoles,
      // 'e28d1237-b120-47af-8238-7e0d144489cd',
    ]; //Owner

    const supervisorRoles: string[] = [
      ...ownerRoles,
      '0bc1e533-530a-4049-9e72-09ff09c350a1',
    ]; //Supervisor

    const roles = TokenUtil.userHasAdminRole()
      ? adminRoles
      : TokenUtil.userHasOwnerRole()
      ? ownerRoles
      : TokenUtil.userHasSupervisorRole()
      ? supervisorRoles
      : [];

    const avaibleRoles: string[] = this.avaibleRoles
      .filter((role) => roles.includes(role.id))
      .map((role) => role.displayName);

    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createAddUserDialog(
        nameKey,
        surnameKey,
        emailKey,
        roleKey,
        avaibleRoles,
        this.directoryUsers.map((user) => user.activeDirectoryUser.email)
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (!response) return;

      const selectedRole = this.avaibleRoles.filter(
        (role) =>
          role.displayName === response.get(roleKey) ||
          this.translate.instant(
            'usersRoles.table.dialog.role.' + role.displayName
          ) === response.get(roleKey)
      );

      if (selectedRole.length == 1) {
        //Default role is analyst
        let roleId = 'e3f3634c-d4fb-4803-ae3b-208bb9f0e161';
        if (selectedRole[0]) {
          roleId = selectedRole[0].id;
        }

        const name = response.get(nameKey);
        const surname = response.get(surnameKey);
        const email = response.get(emailKey);
        this.contentLoadingService.setLoadingState(true);
        this.tenantService
          .inivteDirectoryUser(
            name,
            surname,
            email,
            roleId,
            this.getRoleFromTokenClaim()
          )
          .subscribe((response) => {
            const content = response.body;
            const newUser = {
              activeDirectoryUser: {
                appRole: {
                  appRoleId: content['appRole']['appRoleId'],
                  principalDisplayName:
                    content['appRole']['principalDisplayName'],
                },
                name: content['name'] ?? '',
                surname: content['surname'] ?? '',
                email: content['email'],
                id: content['id'],
              },
              activeDirectoryAppRole: this.avaibleRoles.filter(
                (role) => role.id === content['appRole']['appRoleId']
              )[0],
            } as User;

            this.directoryUsers.push(newUser);
            this.contentLoadingService.setLoadingState(false);
            this.initializeUserTableData();
          });
      }
    });
  }

  private getRoleFromTokenClaim(): string {
    const claim = TokenUtil.getClaimFromToken(
      localStorage.getItem('id-token'),
      'roles'
    )[0];
    if (claim === 'Dashboard.Editor') {
      return 'f783c54f-06ad-4007-a093-3e072ce3bdea'; //Editor
    }
    if (claim === 'Dashboard.Read') {
      return 'e3f3634c-d4fb-4803-ae3b-208bb9f0e161'; //Analyst
    }

    if (claim === 'Dashboard.Manager') {
      return '1adc8f8c-d330-4d14-88e4-61c1ccf93e62'; //Manager
    }

    if (claim === 'Dashboard.Admin') {
      return '62e51e31-d6f5-4637-aa8e-62a4199e4be6'; //Admin
    }

    if (claim === 'Dashboard.Owner') {
      return 'e28d1237-b120-47af-8238-7e0d144489cd'; //Owner
    }
    if (claim === 'Admin.All') {
      return '0bc1e533-530a-4049-9e72-09ff09c350a1'; //supervisor
    }
    return '';
  }

  public editUser(event) {
    const nameKey = 'name-key';
    const surnameKey = 'surname-key';
    const roleKey = 'role-key';

    const user: User = this.directoryUsers.filter(
      (value) => value.activeDirectoryUser.id === event['entryId']
    )[0];

    const userName = user.activeDirectoryUser.name;
    const userSurname = user.activeDirectoryUser.surname;

    const principalNameParts: string[] =
      user.activeDirectoryUser.appRole.principalDisplayName.split(' ');

    const inputName = userName.length > 0 ? userName : principalNameParts[0];
    const inputSurname =
      userSurname.length > 0
        ? userSurname
        : principalNameParts[principalNameParts.length - 1];
    const email = user.activeDirectoryUser.email;
    const role = user.activeDirectoryAppRole.displayName;
    const adminRoles: string[] = [
      // 'f783c54f-06ad-4007-a093-3e072ce3bdea', //Editor
      'e3f3634c-d4fb-4803-ae3b-208bb9f0e161', //Analyst
      '62e51e31-d6f5-4637-aa8e-62a4199e4be6', //Admin
    ];

    const ownerRoles: string[] = [
      ...adminRoles,
      // 'e28d1237-b120-47af-8238-7e0d144489cd',
    ]; //Owner

    const supervisorRoles: string[] = [
      ...ownerRoles,
      '0bc1e533-530a-4049-9e72-09ff09c350a1',
    ]; //Supervisor

    const roles = TokenUtil.userHasAdminRole()
      ? adminRoles
      : TokenUtil.userHasOwnerRole()
      ? ownerRoles
      : TokenUtil.userHasSupervisorRole()
      ? supervisorRoles
      : [];

    const avaibleRoles: string[] = this.avaibleRoles
      .filter((role) => roles.includes(role.id))
      .map((role) => role.displayName);
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createEditUserDialog(
        nameKey,
        surnameKey,
        roleKey,
        inputName,
        inputSurname,
        role,
        avaibleRoles
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (!response) return;

      const name = response.get(nameKey);
      const surname = response.get(surnameKey);

      const selectedRole = response.get(roleKey);

      const userId = event['entryId'];
      const user: User = this.directoryUsers.filter(
        (value) => value.activeDirectoryUser.id === userId
      )[0];
      if (selectedRole) {
        const newRoleId: string = this.avaibleRoles
          .filter((avaibleRole) => roles.includes(avaibleRole.id))
          .filter((value) => value.displayName === selectedRole)[0].id;
        this.contentLoadingService.setLoadingState(true);

        this.tenantService
          .updateUserAppRole(
            userId,
            user.activeDirectoryAppRole.id,
            newRoleId,
            this.getRoleFromTokenClaim()
          )
          .subscribe((_) => {
            const directoryRole = this.avaibleRoles.filter(
              (avaibleRole) => avaibleRole.id == newRoleId
            )[0];
            const directoryUser = this.directoryUsers.filter(
              (user) => user.activeDirectoryUser.id === userId
            )[0];

            directoryUser.activeDirectoryAppRole = { ...directoryRole };

            const userIndex: number = this.directoryUsers.findIndex(
              (user) => user.activeDirectoryUser.id === userId
            );
            this.directoryUsers[userIndex] = directoryUser;
            this.initializeUserTableData();
            this.contentLoadingService.setLoadingState(false);
          });

        if (name || surname) {
          this.contentLoadingService.setLoadingState(true);

          this.tenantService
            .updateUserProfile(
              userId,
              name ??
                (user.activeDirectoryUser.name !== '' ||
                user.activeDirectoryUser.name !== undefined
                  ? user.activeDirectoryUser.name
                  : inputName),
              surname ??
                (user.activeDirectoryUser.surname !== '' ||
                user.activeDirectoryUser.surname !== undefined
                  ? user.activeDirectoryUser.surname
                  : inputSurname),
              this.getRoleFromTokenClaim()
            )
            .subscribe((_) => {
              const directoryUser = this.directoryUsers.filter(
                (user) => user.activeDirectoryUser.id === userId
              )[0];
              const user = directoryUser.activeDirectoryUser;
              user.name = name;
              user.surname = surname;
              directoryUser.activeDirectoryUser = user;

              const userIndex: number = this.directoryUsers.findIndex(
                (user) => user.activeDirectoryUser.id === userId
              );
              this.directoryUsers[userIndex] = directoryUser;
              this.initializeUserTableData();
              this.contentLoadingService.setLoadingState(false);
            });
        }

        return;
      }

      if (name || surname) {
        this.contentLoadingService.setLoadingState(true);

        this.tenantService
          .updateUserProfile(
            userId,
            name ??
              (user.activeDirectoryUser.name !== '' ||
              user.activeDirectoryUser.name !== undefined
                ? user.activeDirectoryUser.name
                : inputName),
            surname ??
              (user.activeDirectoryUser.surname !== '' ||
              user.activeDirectoryUser.surname !== undefined
                ? user.activeDirectoryUser.surname
                : inputSurname),
            this.getRoleFromTokenClaim()
          )
          .subscribe((_) => {
            const directoryUser = this.directoryUsers.filter(
              (user) => user.activeDirectoryUser.id === userId
            )[0];
            const user = directoryUser.activeDirectoryUser;
            user.name = name;
            user.surname = surname;
            directoryUser.activeDirectoryUser = user;

            const userIndex: number = this.directoryUsers.findIndex(
              (user) => user.activeDirectoryUser.id === userId
            );
            this.directoryUsers[userIndex] = directoryUser;
            this.initializeUserTableData();
            this.contentLoadingService.setLoadingState(false);
          });
      }
    });
  }

  public deleteUser(event) {
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createDeleteUserDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.tenantService.loggedInTenant.subscribe((tenant) => {
          if (tenant) {
            this.contentLoadingService.setLoadingState(true);

            this.tenantService
              .deleteUser(event['entryId'], tenant.id)
              .subscribe((_) => {
                this.directoryUsers.splice(
                  this.directoryUsers.findIndex(
                    (user) => user.activeDirectoryUser.id === event['entryId']
                  ),
                  1
                );
                this.initializeUserTableData();
                this.contentLoadingService.setLoadingState(false);
              });
          }
        });
      }
    });
  }

  private initializeUserTableData() {
    this.userCount = this.directoryUsers.length;
    this.userData.next({
      tableRows: this.directoryUsers.map((user) => {
        return {
          data: [
            {
              text:
                user.activeDirectoryUser.name.length > 0 &&
                user.activeDirectoryUser.surname.length > 0
                  ? `${user.activeDirectoryUser.name} ${user.activeDirectoryUser.surname}`
                  : user.activeDirectoryUser.appRole.principalDisplayName,
            },
            {
              text: user.activeDirectoryUser.email,
            },
            {
              text: user.activeDirectoryAppRole.displayName,
            },
          ],
          entryId: user.activeDirectoryUser.id,
        } as TableRow;
      }),
      columnHeaders: [
        {
          title: 'usersRoles.table.title.name',
          filterable: false,
          sortable: true,
        },
        {
          title: 'usersRoles.table.title.email',
          filterable: false,
          sortable: true,
        },
        {
          title: 'usersRoles.table.title.role',
          filterable: true,
          sortable: true,
        },
      ],
      editable: true,
      itemDeletion: true,
      includesCustomButton: false,
      hasSingleCustomButton: false,
      pagination: true,
    });
  }

  public userTableData(): Observable<TableData> {
    return this.userData.asObservable();
  }
}
