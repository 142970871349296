import { Component, Input, OnInit } from '@angular/core';
import { BrandColor } from '../../../../../models/brand';
import { MatDialog } from '@angular/material/dialog';
import { BrandColorSelectorComponent } from '../brand-color-selector/brand-color-selector.component';
import { BrandingType } from 'src/app/models/enums/branding-type.enum';
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.scss'],
})
export class BrandCardComponent implements OnInit {
  @Input() color: BrandColor;
  @Input() key: string;
  @Input() isInverse: boolean = false;
  @Input() disableDescription: boolean = false;
  private originalColorKey: string = '';

  constructor(
    private matDialog: MatDialog,
    private brandingService: BrandingService
  ) {}

  ngOnInit(): void {
    this.originalColorKey = this.color.key;
    if (this.isInverse) {
      this.color = this.color.inverseColor;
    }
  }

  public openColorSelector(): void {
    const dialogRef = this.matDialog.open(BrandColorSelectorComponent, {
      data: this.color
    });
    dialogRef.afterClosed().subscribe((_) => {
      this.brandingService.updateColorBranding(
        this.color,
        this.key,
        this.isInverse,
        this.originalColorKey
      );
    });
  }

  public get titleTranslationKey(): string {
    return `branding.cards.${this.color.key}`;
  }

  public get descriptionTranslationKey(): string {
    return `branding.cards.${this.color.key}CardDescription`;
  }
}
