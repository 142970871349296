import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';
import { EmailRegex, NameRegex } from '../models/const/common';

const createAddUserDialog = (
  nameKey: string,
  surnameKey: string,
  emailKey: string,
  roleKey: string,
  avaibleRoles: string[],
  usedEmails: string[]
) => {
  return {
    confirmationTextKey: 'usersRoles.table.dialog.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.name.placeholder',
        prefix: 'usersRoles.table.dialog.name.prefix',
        suffix: 'usersRoles.table.dialog.name.suffix',
        key: nameKey,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.surname.placeholder',
        prefix: 'usersRoles.table.dialog.surname.prefix',
        suffix: 'usersRoles.table.dialog.surname.suffix',
        key: surnameKey,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.email.placeholder',
        prefix: 'usersRoles.table.dialog.email.prefix',
        suffix: 'usersRoles.table.dialog.email.suffix',
        key: emailKey,
        validation: EmailRegex,
        usedEMails: usedEmails,
      },
      {
        dialogStructure: DialogStructure.SELECT,
        placeHolder: 'usersRoles.table.dialog.role.placeholder',
        prefix: 'usersRoles.table.dialog.role.prefix',
        suffix: 'usersRoles.table.dialog.role.suffix',
        key: roleKey,
        options: avaibleRoles,
      },
    ],
    dialogTitleTextKey: 'usersRoles.table.dialog.create.title',
    dialogType: DialogLayoutType.COLUMN,
  } as TableDialogInput;
};

const createAddOwnerDialog = (
  nameKey: string,
  surnameKey: string,
  emailKey: string,
  usedEmails: string[]
) => {
  return {
    confirmationTextKey: 'usersRoles.table.dialog.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.name.placeholder',
        prefix: 'usersRoles.table.dialog.name.prefix',
        suffix: 'usersRoles.table.dialog.name.suffix',
        key: nameKey,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.surname.placeholder',
        prefix: 'usersRoles.table.dialog.surname.prefix',
        suffix: 'usersRoles.table.dialog.surname.suffix',
        key: surnameKey,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.email.placeholder',
        prefix: 'usersRoles.table.dialog.email.prefix',
        suffix: 'usersRoles.table.dialog.email.suffix',
        key: emailKey,
        validation: EmailRegex,
        usedEMails: usedEmails,
      },
    ],
    dialogTitleTextKey: 'usersRoles.table.dialog.create.title',
    dialogType: DialogLayoutType.COLUMN,
  } as TableDialogInput;
};

const createEditOwnerDialog = (
  nameKey: string,
  surnameKey: string,
  name: string,
  surname: string
) => {
  return {
    confirmationTextKey: 'usersRoles.table.dialog.edit.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.name.placeholder',
        prefix: 'usersRoles.table.dialog.name.prefix',
        suffix: 'usersRoles.table.dialog.name.suffix',
        key: nameKey,
        value: name,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.surname.placeholder',
        prefix: 'usersRoles.table.dialog.surname.prefix',
        suffix: 'usersRoles.table.dialog.surname.suffix',
        key: surnameKey,
        value: surname,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'usersRoles.table.dialog.edit.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createEditUserDialog = (
  nameKey: string,
  surnameKey: string,
  roleKey: string,
  name: string,
  surname: string,
  role: string,
  avaibleRoles: string[]
) => {
  return {
    confirmationTextKey: 'usersRoles.table.dialog.edit.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.name.placeholder',
        prefix: 'usersRoles.table.dialog.name.prefix',
        suffix: 'usersRoles.table.dialog.name.suffix',
        key: nameKey,
        value: name,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'usersRoles.table.dialog.surname.placeholder',
        prefix: 'usersRoles.table.dialog.surname.prefix',
        suffix: 'usersRoles.table.dialog.surname.suffix',
        key: surnameKey,
        value: surname,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.SELECT,
        placeHolder: 'usersRoles.table.dialog.role.placeholder',
        prefix: 'usersRoles.table.dialog.role.prefix',
        suffix: 'usersRoles.table.dialog.role.suffix',
        key: roleKey,
        value: role,
        options: avaibleRoles,
        validation: EmailRegex,
      },
    ],
    dialogTitleTextKey: 'usersRoles.table.dialog.edit.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createDeleteUserDialog = () => {
  return {
    confirmationTextKey: 'usersRoles.table.dialog.delete.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'usersRoles.table.dialog.delete.text',
      },
    ],
    dialogTitleTextKey: 'usersRoles.table.dialog.delete.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

export {
  createEditOwnerDialog,
  createAddUserDialog,
  createDeleteUserDialog,
  createEditUserDialog,
  createAddOwnerDialog,
};
