import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VideoPopupService } from 'src/app/services/video-popup-service';

interface ApplicationSteps {
  heading: string;
  description: string;
  iconPath: string;
  notes?: string[];
}

@Component({
  selector: 'app-explanation-section',
  templateUrl: './explanation-section.component.html',
  styleUrls: ['./explanation-section.component.scss'],
})
export class ExplanationSectionComponent implements OnInit {
  public applicationSteps: ApplicationSteps[] = [];
  public howItWorksImage: string = '';
  public playButton: string = '';
  public bubbleImage: string = '';

  constructor(
    private translateService: TranslateService,
    private videoPopupService: VideoPopupService
  ) {}

  ngOnInit(): void {
    const assetsPath = '../../assets/';
    const svgPath = assetsPath + 'svg';
    this.playButton = svgPath + '/video_pict.svg';
    this.bubbleImage = svgPath + '/bubble.svg';
    this.howItWorksImage =
      assetsPath + 'images/Desktop_How_Does_It_Work_2X.png';
    this.translateService
      .get([
        'landing.firstSectionHeading',
        'landing.firstSectionDescription',
        'landing.firstSectionNote',
        'landing.secondSectionHeading',
        'landing.secondSectionDescription',
        'landing.thirdSectionHeading',
        'landing.thirdSectionDescription',
      ])
      .subscribe((result) => {
        this.applicationSteps = [
          {
            heading: result['landing.firstSectionHeading'],
            description: result['landing.firstSectionDescription'],
            iconPath:
              assetsPath + 'images/Mobile_How_Does_It_Work_1STEP_2X.png',
            notes: [result['landing.firstSectionNote']],
          },
          {
            heading: result['landing.secondSectionHeading'],
            description: result['landing.secondSectionDescription'],
            iconPath:
              assetsPath + 'images/Mobile_How_Does_It_Work_2STEP_2X.png',
          },
          {
            heading: result['landing.thirdSectionHeading'],
            description: result['landing.thirdSectionDescription'],
            iconPath:
              assetsPath + 'images/Mobile_How_Does_It_Work_3STEP_2X.png',
          },
        ];
      });
  }

  public openVideoPopup() {
    this.videoPopupService.setVideoPopupOpen(true);
  }

  public hasNote(applicationStep: ApplicationSteps) {
    return (
      applicationStep.notes !== undefined && applicationStep.notes.length > 0
    );
  }

  public isMobile(): boolean {
    return window.innerWidth < 1280;
  }
}
