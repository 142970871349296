<div class="color-selector-wrapper">
    <div class="header">
        <h2>{{'branding.modal.heading'|translate}}</h2>
        <app-close-button (click)="closeDialog()"></app-close-button>
    </div>
    <p>{{'branding.modal.text'|translate}}</p>
    <div class="selector">
        <div class="color-picker">
            <div class="palette">
                <canvas width="160" height="160" id="palette" #palette class="color-palette"
                    (mousedown)="onMouseDownPalette($event)" (mousemove)="onMouseMovePalette($event)"
                    (mouseup)="onMouseUpPalette($event)">
                </canvas>
            </div>
            <div class="slider">
                <canvas width="20" height="160" class="slider" id="slider" #slider class="color-slider"
                    (mousedown)="onMouseDownSlider($event)" (mouseup)="onMouseUpSlider($event)"
                    (mousemove)="onMouseMoveSlider($event)">
                </canvas>
            </div>
        </div>
        <app-drop-down-button [options]="dropDownOptions" (selected)="updateSelection($event)"></app-drop-down-button>
        <input *ngIf="hexSelected()" class="dashboard-input" type="text" [ngModel]="color"
            (ngModelChange)="onInputColorChange($event)" [placeholder]="'branding.modal.placeholder'|translate">
        <div *ngIf="rgbSelected()" class="rgb-inputs">
            <div>
                <label for="red">Red</label>
                <input (change)="onInputColorChange($event)" name="red" class="dashboard-input" type="number" min="0"
                    max="255" [(ngModel)]="red">
            </div>
            <div>
                <label for="green">Green</label>
                <input (change)="onInputColorChange($event)" name="green" class=" dashboard-input" type="number" min="0"
                    max="255" [(ngModel)]="green">
            </div>
            <div>
                <label for="blue">Blue</label>
                <input (change)="onInputColorChange($event)" name="blue" class=" dashboard-input" type="number" min="0"
                    max="255" [(ngModel)]="blue">
            </div>
        </div>
    </div>
    <div class="controls">
        <scale-button [disabled]="!colorHasChanged()" class="black-ghost-color" variant="ghost"
            (click)="resetColor()"><img
                [src]="colorHasChanged() ? 'assets/svg/icons/reset.svg' : 'assets/svg/icons/reset-gray.svg'"
                [ngStyle]="{marginRight: '8px'}">
            <span>{{'branding.modal.reset'|translate}}</span></scale-button>
        <!-- <app-text-button [fontSizeFactor]="1.1" (click)="resetColor()"
            [text]="'branding.modal.reset'|translate"></app-text-button> -->
        <div>
            <app-button (click)="closeDialog()" cancelButtonStyle="true"
                [text]="'common.cancel'|translate"></app-button>
            <app-button (click)="saveColor()" [text]="'branding.modal.confirm'|translate"></app-button>
        </div>
    </div>
</div>