import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant.service';
import { DashboardComponent } from '../dashboard/component/dashboard.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  public tenantService: TenantService = inject(TenantService);
  public loggedInTenant$ = this.tenantService.loggedInTenant;

  public static get route(): string {
    return 'welcome';
  }

  constructor(private router: Router) {
    registerLocaleData(de);
    registerLocaleData(en);
  }

  ngOnInit(): void {}

  dashboardLink() {
    this.router.navigate([DashboardComponent.route]);
  }
}
