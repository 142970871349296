export class ObjectHelper {
  public static mapToJson(map: Map<string, string>): any {
    return Object.fromEntries(map);
  }

  public static transformMapToJson(
    // map: Map<string, Map<string, string>>
    map: Map<any, any>
  ): object {
    const json = {};
    for (const [outerKey, innerMap] of map.entries()) {
      json[outerKey] = {};
      for (const [innerKey, value] of innerMap.entries()) {
        json[outerKey][innerKey] = value;
      }
    }
    return json;
  }
}
