import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ActivationCode } from '../models/activationcode';
import { TokenUtil } from '../utils/token-util';
import { ContentLoadingService } from './content-loading.service';
import { ActivationCodeHttpService } from './http/activationcode-http.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class ActivationCodeService {
  private _activationCodes: BehaviorSubject<ActivationCode[]> =
    new BehaviorSubject<ActivationCode[]>([]);

  private _loggedInTenantActivationCodes: BehaviorSubject<ActivationCode[]> =
    new BehaviorSubject<ActivationCode[]>([]);

  private _selectedTenantActivationCodes: BehaviorSubject<ActivationCode[]> =
    new BehaviorSubject<ActivationCode[]>([]);

  private _activationCodeCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  private tenantID: string = '';

  constructor(
    private tenantService: TenantService,
    private activationCodeHttpService: ActivationCodeHttpService,
    private contentLoadingService: ContentLoadingService
  ) {
    this.tenantService.selectedTenant.subscribe((tenant) => {
      if (tenant && tenant.id) {
        this.activationCodeHttpService
          .fetchAllActivationCodesFor(tenant.id)
          .subscribe((activationCodes) => {
            this.setSelectedTenantActivationCodes(activationCodes);
          });
      }
    });
    this.tenantService.loggedInTenant.subscribe((tenant) => {
      if (tenant && tenant.id) {
        this.activationCodeHttpService
          .fetchAllActivationCodesFor(tenant.id)
          .subscribe((activationCodes) => {
            this.setLoggedInTenantActivationCodes(activationCodes);
          });
      }
    });
  }

  public initActivationCodes() {
    this.contentLoadingService.setLoadingState(true);
    this.activationCodeHttpService
      .fetchAllActivationCodesFor(
        TokenUtil.getTenantFromToken(localStorage.getItem('id-token'))
      )
      .subscribe((response) => {
        this.setActivationCodes(response);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  updateActivationCodeCount(amount: number) {
    this._activationCodeCount.next(amount);
  }

  get activationCodeCount() {
    return this._activationCodeCount.asObservable();
  }

  get activationCodeUsedCount() {
    return this.activationCodeUsedCount.asObservable();
  }

  public postActivationCodes(
    activationCode: ActivationCode,
    amountOfCodes: number
  ) {
    this.contentLoadingService.setLoadingState(true);
    return this.activationCodeHttpService
      .postActivationCode(activationCode, amountOfCodes)
      .subscribe((response: HttpResponse<any>) => {
        const updatedCodes: ActivationCode[] = this._activationCodes.value;

        if (amountOfCodes > 1) {
          for (let i = 0; i < amountOfCodes; i++) {
            let currentCode = { ...activationCode };
            currentCode.code = response.body['activationCodes'][i]['code'];
            updatedCodes.push(currentCode);
          }
        } else {
          activationCode.code = response.body['activationCodes']['code'];
          updatedCodes.push(activationCode);
        }
        this.setSelectedTenantActivationCodes(updatedCodes);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  public resetActivationCode(activationCode: ActivationCode) {
    this.resetCode(activationCode.code);
  }

  public resetCode(activationCode: string) {
    this.contentLoadingService.setLoadingState(true);
    return this.activationCodeHttpService
      .resetActivationCode(activationCode)
      .subscribe((response: HttpResponse<any>) => {
        const updatedCodes: ActivationCode[] =
          this._selectedTenantActivationCodes.value;
        const updatedCode = updatedCodes.filter(
          (entry) => entry.code === activationCode
        )[0];

        updatedCode.amountOfRemainingUses = response['amountOfRemainingUses'];
        updatedCode.usagesCounter = 0;

        this.setSelectedTenantActivationCodes(updatedCodes);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  public updateActivationCode(activationCode: ActivationCode) {
    this.contentLoadingService.setLoadingState(true);
    this.activationCodeHttpService
      .updateActivationCode(activationCode)
      .subscribe((response) => {
        const updatedCodes: ActivationCode[] =
          this._selectedTenantActivationCodes.value;
        const indexToUpdate = updatedCodes.findIndex(
          (code) => code.code === activationCode.code
        );
        updatedCodes[indexToUpdate] = activationCode;
        this.setSelectedTenantActivationCodes(updatedCodes);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  // public getActivationCodeFromData(
  //   amountOfRemainingUses: number,
  //   code: string,
  //   redeemable: boolean,
  //   tenantID: string,
  //   validationDateFrom: Date,
  //   validationDateTo: Date,
  //   licenseId: string
  // ): ActivationCode {
  //   return {
  //     amountOfRemainingUses: amountOfRemainingUses,
  //     code: code,
  //     redeemable: redeemable,
  //     tenantID: tenantID,
  //     validationDateFrom: validationDateFrom,
  //     validationDateTo: validationDateTo,
  //     licenseId: licenseId,
  //   };
  // }

  private setSelectedTenantActivationCodes(codes: ActivationCode[]) {
    this._selectedTenantActivationCodes.next(codes);
  }

  private setLoggedInTenantActivationCodes(codes: ActivationCode[]) {
    this._loggedInTenantActivationCodes.next(codes);
  }

  private setActivationCodes(codes: ActivationCode[]) {
    this._activationCodes.next(codes);
    this.updateActivationCodeCount(
      this.getAmountOfCodes(this._activationCodes.value)
    );
  }

  public deleteActivationCode(codes: string[]) {
    this.contentLoadingService.setLoadingState(true);
    this.activationCodeHttpService
      .deleteActivationCode(codes)
      .subscribe((response: HttpResponse<any>) => {
        const deleted: string[] = response.body['activationCodes'];
        const updatedList: ActivationCode[] =
          this._activationCodes.value.filter(
            (entry) => !deleted.includes(entry.code)
          );
        this.setActivationCodes(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  private getAmountOfCodes(codes: ActivationCode[]): number {
    if (codes.length > 0) {
      return codes
        .map((code) => code.amountOfRemainingUses)
        .reduce((previous, currentValue) => previous + currentValue);
    }
    return 0;
  }

  get activationCodes() {
    return of([]);
  }

  get loggedInTenantActivationCodes() {
    return this._loggedInTenantActivationCodes.asObservable();
  }

  get selectedTenantActivationCodes() {
    return this._selectedTenantActivationCodes.asObservable();
  }
}
