<div class="filter-container">
    <span class="bold">{{'feedbacks.date.date' | translate}}</span>
    <div class="time-container">
        <div class="p-grid">
            <scale-segmented-button size="medium" class="scale-segments">
                <scale-segment (click)="onSelectTimeRange('lastWeek')"
                    [attr.selected]=" !(customStartDate||customEndDate) && (selectedTimeRange == 'lastWeek') ? '' : null">{{'feedbacks.date.LWeek'
                    | translate}}</scale-segment>
                <scale-segment (click)="onSelectTimeRange('lastMont')"
                    [attr.selected]=" !(customStartDate||customEndDate) && (selectedTimeRange == 'lastMont')  ? '' : null">{{'feedbacks.date.LMonth'
                    | translate}}</scale-segment>
                <scale-segment (click)="onSelectTimeRange('lastQuarter')"
                    [attr.selected]=" !(customStartDate||customEndDate) && (selectedTimeRange == 'lastQuarter')  ? '' : null">
                    {{'feedbacks.date.LQuarter' | translate}}</scale-segment>
                <scale-segment (click)="onSelectTimeRange('lastYear')"
                    [attr.selected]=" !(customStartDate||customEndDate) && (selectedTimeRange == 'lastYear') ? '' : null">
                    {{'feedbacks.date.LYear' | translate}}</scale-segment>
                <scale-segment (click)="onSelectTimeRange('allTime')"
                    [attr.selected]=" !(customStartDate||customEndDate) && selectedTimeRange == 'allTime' ? '' : null">
                    {{'feedbacks.date.AllTime' | translate}}</scale-segment>
                <scale-segment class="custom-button-disabled" (click)="onSelectTimeRange('custom')"
                    [attr.disabled]="!(customStartDate||customEndDate)"
                    [attr.selected]=" (customStartDate||customEndDate) ? '' : null"> {{'feedbacks.date.Custom' |
                    translate}}</scale-segment>
            </scale-segmented-button>
            <div class="date-picker-container scale-segments">
                <scale-date-picker ref="start scale-date-picker-item " #start identifier="start"
                    label="{{'feedbacks.date.Start' | translate}}" class="start-date-picker scale-date-picker"
                    [attr.value]="customStartDate" [max]="customEndDate" size="small"
                    (scaleChange)="scaleChangeStart($event)"></scale-date-picker>
                <scale-date-picker ref="end scale-date-picker-item " #end identifier="end" class="scale-date-picker"
                    label="{{'feedbacks.date.End' | translate}}" [attr.value]="customEndDate" show
                    [min]="customStartDate" (scaleChange)="scaleChangeEnd($event)"></scale-date-picker>
            </div>
        </div>
    </div>

    <div class="p-grid">
        <scale-dropdown-select label="{{'feedbacks.dropdown.Company' | translate}}" class="p-col-3"  [attr.value]="selectedCompany"
            (scale-change)="onSelectCompany($event)">
            <scale-dropdown-select-item value="All">{{'feedbacks.dropdown.all'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item *ngFor="let selectedCompany of uniqueCompanyNames" [attr.value]="selectedCompany">{{
                selectedCompany }}</scale-dropdown-select-item>
        </scale-dropdown-select>
        <scale-dropdown-select label="Status" class="p-col-2" (scale-change)="onSelectStatus($event)" [value]="selectedStatus">
            <scale-dropdown-select-item value="All">{{'feedbacks.dropdown.all'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item value="Active">{{'feedbacks.dropdown.Active'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item value="Inactive">{{'feedbacks.dropdown.Inactive'|translate}}</scale-dropdown-select-item>
        </scale-dropdown-select> 
        <!-- Filters are temporarily disabled -->
        <!-- <scale-dropdown-select label="{{'feedbacks.dropdown.Office' | translate}}" class="p-col-3"
            (scale-change)="onSelectOffice($event)">
            <scale-dropdown-select-item value="All">{{'feedbacks.dropdown.all'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item style="overflow: hidden !important;" *ngFor="let office of uniqueOfficeAdresses"
                [value]="office?.id"> {{ office?.street }} {{ office?.city }} {{ office?.zip
                }}</scale-dropdown-select-item>
        </scale-dropdown-select>
        <scale-dropdown-select label="Platform" class="p-col-2" (scale-change)="onSelectPlatform($event)">
            <scale-dropdown-select-item value="All">{{'feedbacks.dropdown.all'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item *ngFor="let selectedPlatform of uniquePlatforms" [value]="selectedPlatform">{{
                selectedPlatform }}</scale-dropdown-select-item>
        </scale-dropdown-select>
        <scale-dropdown-select label="App Version" class="p-col-2" (scale-change)="onSelectAppVersion($event)">
            <scale-dropdown-select-item value="All">{{'feedbacks.dropdown.all'|translate}}</scale-dropdown-select-item>
            <scale-dropdown-select-item *ngFor="let version of uniqueVersions" [value]="version">{{ version
                }}</scale-dropdown-select-item>
        </scale-dropdown-select> -->
        <scale-button *ngIf="isFilterReset" size="large" icon-only (click)="clearFilter()"
            variant="secondary"  class="scale-button-secondary black-ghost-color"
            variant="ghost">
            <scale-icon-action-close attr.accessibility-title="{{'feedbacks.title.buttons.remove' | translate}}" size="24" class="scale-icon-action-close" />
            {{'feedbacks.title.select.resetAllfilters'|translate}}
        </scale-button>
        
    </div>
    <div class="p-grid flex-end">
        <scale-dropdown-select class="p-col-2" (scale-change)="sortByCriteria($event.detail.value)"
            label="{{'feedbacks.dropdown.sortBy' | translate}}" [attr.value]="'Startdate descending'">
            <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Startdate descending">{{'feedbacks.dropdown.sortBySDesc' | translate}}
            </scale-dropdown-select-item>
            <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Startdate ascending">{{'feedbacks.dropdown.sortBySAsc' | translate}}
            </scale-dropdown-select-item>
            <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Finishdate descending">{{'feedbacks.dropdown.sortByFDesc' | translate}}
            </scale-dropdown-select-item>
            <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Finishdate ascescending">{{'feedbacks.dropdown.sortByFAsc' | translate}}
            </scale-dropdown-select-item>
            <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Name A-Z">{{'feedbacks.dropdown.sortByNameAZ' | translate}}</scale-dropdown-select-item>
                <scale-dropdown-select-item class="scale-dropdown-select-item"
                value="Name Z-A">{{'feedbacks.dropdown.sortByNameZA' | translate}}</scale-dropdown-select-item>
        </scale-dropdown-select>
    </div>
</div>