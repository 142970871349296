import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transport-cell',
  templateUrl: './transport-cell.component.html',
  styleUrls: ['./transport-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportCellComponent implements OnInit {
  @Input() distance: string;
  @Input() transportType: string;

  constructor(public translateService: TranslateService) {
    registerLocaleData(de);
    registerLocaleData(en);
  }
  ngOnInit(): void {
    if (this.transportType) {
      this.transportType = this.transportType.toLocaleLowerCase();
    }
  }
}
