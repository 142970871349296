import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Input } from '@angular/core';
import { HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: '[app-icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges {

  public src: string

  @Input() name: string;
  @Input() iconSrc : string;
  @Input() iconSize : number;
  @Input() position : string;

  public styles : Object;

  @HostBinding('style') get componentStyles() {
    if (!this.iconSize) {
      return
    }
    return this.sanitizer.bypassSecurityTrustStyle(`height: ${this.iconSize}px; width: ${this.iconSize}px; line-height: ${this.iconSize}px`)
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.styles = {
      'height.rem': this.iconSize,
      'width.rem': this.iconSize,
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.iconSrc?.currentValue) {
      const src: string = changes.iconSrc?.currentValue
      if (!src.startsWith('http')) {
        this.src = `./assets/svg/${src}.svg`
      } else {
        this.src = src
      }
    }
  }
}
