<app-filter [trendShowed]="true" [filter]="filter"></app-filter>
<div class="p-grid">
  <div class="p-col-12 p-lg-6 p-xl-6 table">
    <div>
      <p-card class="card">
        <ng-template pTemplate="header">

          <div class="header-spacing">
            <h3 class="no-margin">{{ 'trends.emission-week' | translate}}</h3>
            <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
              (click)="downloadChart($event,'screenshot-element-weekEmissionData')"><img
                [src]="'assets/svg/icons/download-icon.svg'" /></button>

          </div>
        </ng-template>
        <p-chart id="screenshot-element-weekEmissionData" *ngIf="!(loaderService.isLoading | async); else skeleton"
          type="bar" [data]="weekEmissionData" [options]="weeklyEmissionsOptions"></p-chart>
      </p-card>
    </div>
  </div>

  <div class="p-col-12 p-lg-6 p-xl-6">
    <div>

      <p-card>
        <ng-template pTemplate="header">
          <div class="header-spacing">
            <h3 class="no-margin">{{ 'trends.distance-week' | translate}}</h3>
            <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
              (click)="downloadChart($event,'screenshot-element-weekDistanceData')"><img
                [src]="'assets/svg/icons/download-icon.svg'" /></button>
          </div>
        </ng-template>
        <p-chart id="screenshot-element-weekDistanceData" *ngIf="!(loaderService.isLoading | async); else skeleton"
          type="bar" [data]="weekDistanceData" [options]="weeklyDistanceOptions"></p-chart>

      </p-card>
    </div>
  </div>

  <div class="p-col-12 p-lg-6 p-xl-6">
    <div>
      <p-card>
        <ng-template pTemplate="header">
          <div class="header-spacing">
            <h3 class="no-margin">{{ 'trends.commute' | translate}}</h3>
            <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
              (click)="downloadChart($event,'screenshot-element-commuteData')"><img
                [src]="'assets/svg/icons/download-icon.svg'" /></button>
          </div>
        </ng-template>
        <p-chart id="screenshot-element-commuteData" *ngIf="!(loaderService.isLoading | async); else skeleton"
          type="bar" [data]="commuteData" [options]="commuteOptions"></p-chart>
      </p-card>
    </div>
  </div>

  <div class="p-col-12 p-lg-6 p-xl-6">
    <div>
      <p-card>
        <ng-template pTemplate="header">
          <div class="header-spacing">
            <h3 class="no-margin">{{ 'trends.transport-type-percentage' | translate}}</h3>
            <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
              (click)="downloadChart($event,'screenshot-element-transportPercentageData')"><img
                [src]="'assets/svg/icons/download-icon.svg'" /></button>
          </div>
        </ng-template>
        <p-chart id="screenshot-element-transportPercentageData"
          *ngIf="!(loaderService.isLoading | async); else skeleton" id="transportPercentage" type="bar"
          [data]="transportPercentageData" [options]="transportPercentageOptions"></p-chart>
      </p-card>
    </div>
  </div>

  <div class="p-col-12 p-lg-6 p-xl-6">
    <div>
      <p-card>
        <ng-template pTemplate="header">
          <div class="header-spacing">
            <h3 class="no-margin">{{ 'trends.total-business-emissions' | translate}}</h3>
            <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
              (click)="downloadChart($event,'screenshot-element-businessData')"><img
                [src]="'assets/svg/icons/download-icon.svg'" /></button>
          </div>
        </ng-template>

        <p-chart id="screenshot-element-businessData" *ngIf="!(loaderService.isLoading | async); else skeleton"
          type="bar" [data]="businessData" [options]="commuteOptions"></p-chart>
      </p-card>
    </div>
  </div>

  <div class="p-col-12 p-lg-6 p-xl-6">
    <div>
      <p-card>
        <ng-template pTemplate="header">
          <div class="p-grid no-margin">
            <div class="p-col header-spacing">
              <h3 class="no-margin">{{ 'trends.emissions-total' | translate}}</h3>
              <button [attr.data-tooltip]="screenshotToolTipText" data-html2canvas-ignore="true" class="screenshot-btn"
                (click)="downloadChart($event,'screenshot-element-annualData')"><img
                  [src]="'assets/svg/icons/download-icon.svg'" /></button>
            </div>
            <div class="p-col annual-dropdown">
              <p-dropdown [options]="transportTypeOptions" optionLabel="name" [(ngModel)]="selectedType"
                placeholder="{{'common.transportType' | translate}}" [autoDisplayFirst]="false" showClear="true"
                (ngModelChange)="transportTypeChanged()"></p-dropdown>
            </div>
          </div>
        </ng-template>
        <p-chart id="screenshot-element-annualData" *ngIf="!(loaderService.isLoading | async); else skeleton" type="bar"
          [data]="annualData" [options]="annualOptions"></p-chart>
      </p-card>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <app-loader></app-loader>
</ng-template>