import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent implements OnInit {
  @Input() text: string;
  @Input() fontColor: string = '#000000';
  @Input() icon: string;
  @Input() fontSizeFactor: number = 1;
  @Input() iconRightAligned: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
