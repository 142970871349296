import { Component, OnInit } from '@angular/core';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss'],
})
export class ImprintPageComponent implements OnInit {
  public static get route(): string {
    return 'imprint';
  }

  private headline: string = '';

  public get getHeadline(): string {
    return this.headline;
  }

  imprintContent: Section[] = [];

  constructor(private translateService: TranslateService) {
    registerLocaleData(de);
    registerLocaleData(en);
  }

  ngOnInit(): void {
    const title = this.translateService.get('imprint.title');
    const address = this.translateService.get('imprint.address');
    const addressZip = this.translateService.get('imprint.address-zip');
    const postalAddress = this.translateService.get('imprint.postal-address');
    const postalAdressBox = this.translateService.get(
      'imprint.postal-address-box'
    );
    const postalAddressLocation = this.translateService.get(
      'imprint.postal-address-location'
    );
    const telephone = this.translateService.get('imprint.telephone');
    const management = this.translateService.get('imprint.management');
    const supervisor = this.translateService.get('imprint.supervisor');
    const supervisorValue = this.translateService.get(
      'imprint.supervisor-value'
    );
    const register = this.translateService.get('imprint.register');
    const registerCourt = this.translateService.get('imprint.register-court');
    const registerTax = this.translateService.get('imprint.register-tax');
    const authority = this.translateService.get('imprint.authority');
    const authorityNumber = this.translateService.get(
      'imprint.authority-number'
    );

    combineLatest([
      title,
      address,
      addressZip,
      postalAddress,
      postalAdressBox,
      postalAddressLocation,
      telephone,
      management,
      supervisor,
      supervisorValue,
      register,
      registerCourt,
      registerTax,
      authority,
      authorityNumber,
    ])
      .pipe(
        map(
          ([
            title,
            address,
            addressZip,
            postalAddress,
            postalAdressBox,
            postalAddressLocation,
            telephone,
            management,
            supervisor,
            supervisorValue,
            register,
            registerCourt,
            registerTax,
            authority,
            authorityNumber,
          ]) => ({
            title: title,
            address: address,
            addressZip: addressZip,
            postalAddress: postalAddress,
            postalAdressBox: postalAdressBox,
            postalAddressLocation: postalAddressLocation,
            telephone: telephone,
            management: management,
            supervisor: supervisor,
            supervisorValue: supervisorValue,
            register: register,
            registerCourt: registerCourt,
            registerTax: registerTax,
            authority: authority,
            authorityNumber: authorityNumber,
          })
        )
      )
      .subscribe((response) => {
        this.headline = response.title;
        this.imprintContent = [
          {
            headline: response.address + ':',
            paragraphs: ['Riesaer Straße 5', response.addressZip + ' Dresden'],
          },
          {
            headline: response.postalAddress + ':',
            paragraphs: [
              response.postalAdressBox,
              response.postalAddressLocation,
            ],
          },
          {
            headline: '',
            paragraphs: [
              response.telephone + ': +49 3 51 - 28 20 - 0',
              'Fax: +49 3 51 - 28 20 - 51 15',
            ],
          },
          {
            headline: '',
            paragraphs: [
              'Internet: www.telekom-mms.com',
              'E-Mail: mms(at)telekom.de',
            ],
          },
          {
            headline: response.management + ':',
            paragraphs: ['Ralf Pechmann, Marcus Gaube'],
          },
          {
            headline: response.supervisor + ':',
            paragraphs: [response.supervisorValue],
          },
          {
            headline: response.register + ':',
            paragraphs: [response.registerCourt, response.registerTax],
          },
          {
            headline: response.authority + ':',
            paragraphs: [
              'Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post und Eisenbahnen',
              'Tulpenfeld 4, 53113 Bonn',
            ],
          },
          {
            headline: '',
            paragraphs: [response.authorityNumber, 'D-U-N-S: 33-165-5399'],
          },
        ];
      });
  }
}

interface Section {
  headline: string;
  paragraphs: string[];
}
