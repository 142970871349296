import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { License, LicenseResponse, LicenseStatus } from '../models/license';
import { LicenseHttpService } from './http/license-http.service';
import { HttpResponse } from '@angular/common/http';
import { TokenUtil } from '../utils/token-util';
import { TenantService } from './tenant.service';
import { FetchStatus } from '../models/enums/fetch-status';
import { ContentLoadingService } from './content-loading.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private _licenses: BehaviorSubject<LicenseResponse> =
    new BehaviorSubject<LicenseResponse>({
      licenses: [],
      fetchStatus: FetchStatus.RUNNING,
    });

  private _selectedTenantLicenses: BehaviorSubject<LicenseResponse> =
    new BehaviorSubject<LicenseResponse>({
      licenses: [],
      fetchStatus: FetchStatus.RUNNING,
    });

  constructor(
    private tenantService: TenantService,
    private licenseHttpService: LicenseHttpService,
    private contentLoadingService: ContentLoadingService
  ) {
    this.tenantService.loggedInTenant.subscribe((tenant) => {
      if (tenant) {
        this.licenseHttpService
          .fetchLicensesForTenant(tenant.id)
          .subscribe((licenses) => {
            this.setLicenses(licenses);
          });
      }
    });
    this.tenantService.selectedTenant.subscribe((tenant) => {
      this._selectedTenantLicenses.next({
        licenses: [],
        fetchStatus: FetchStatus.RUNNING,
      });
      if (tenant && tenant.id) {
        this.contentLoadingService.setLoadingState(true);

        this.licenseHttpService
          .fetchLicensesForTenant(tenant.id)
          .subscribe((licenses) => {
            this._selectedTenantLicenses.next({
              licenses: licenses,
              fetchStatus: FetchStatus.DONE,
            });
            this.contentLoadingService.setLoadingState(false);
          });
      }
    });
  }

  public getLicensesFromTenant(tenantId: string): Observable<License[]> {
    return this.licenseHttpService.fetchLicensesForTenant(tenantId);
  }

  public postLicense(license: Omit<License, 'id'>) {
    if (license.contractFrom === undefined) {
      license.contractFrom = new Date();
    }
    if (license.contractTo === undefined) {
      license.contractTo = new Date();
    }
    this.contentLoadingService.setLoadingState(true);
    return this.licenseHttpService
      .addLicense(license)
      .subscribe((resultingLicense: License) => {
        resultingLicense.status = ('licenseStatus.' +
          resultingLicense.status.toLowerCase()) as LicenseStatus;
        const updatedLicenses: License[] = this._licenses.value.licenses;

        updatedLicenses.push(resultingLicense);
        this.setLicenses(updatedLicenses);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  public updateLicense(license: Partial<License>) {
    this.contentLoadingService.setLoadingState(true);
    this.licenseHttpService
      .updateLicense(license)
      .subscribe((response: License) => {
        const updatedLicenses: License[] = this._licenses.value.licenses;
        const index = updatedLicenses.findIndex(
          (value) => value.id === response.id
        );
        updatedLicenses[index] = response;
        this.setLicenses(updatedLicenses);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  private setLicenses(licenses: License[]) {
    this._licenses.next({ licenses: licenses, fetchStatus: FetchStatus.DONE });
    this._selectedTenantLicenses.next({
      licenses: licenses,
      fetchStatus: FetchStatus.DONE,
    });
  }

  public deleteLicense(licenseId: string) {
    this.contentLoadingService.setLoadingState(true);
    this.licenseHttpService
      .deleteLicense(licenseId)
      .subscribe((response: HttpResponse<any>) => {
        const updatedLicenses: License[] = this._licenses.value.licenses.filter(
          (license) => license.id !== licenseId
        );
        this.setLicenses(updatedLicenses);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  get licenses() {
    return this._licenses.asObservable();
  }

  get selectedTenantLicenses() {
    return this._selectedTenantLicenses.asObservable();
  }
}
