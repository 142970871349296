<ng-container *ngIf="!editOpened">
  <!-- <div class="path">
  <a (click)="openDetails()">Feedback</a>
  <img src="assets/images/shared/right.svg">
  <a>{{ survey.title.get(currentLang) }}</a>
</div> -->
  <scale-breadcrumb separator=">" class="scale-breadcrumb-item">
    <a href="/dashboard/feedback">Feedback</a>
    <a>{{ survey.title.get(currentLang) }}</a>
  </scale-breadcrumb>
  <div *ngIf="survey">
    <div class="details-title-wrapper">
      <h2 class="intro-survey">{{ survey.title.get(currentLang) }}</h2>
      <scale-button variant="ghost" class="survey-button-edit black-ghost-color margin-right-sm" (click)="onEditClicked()" size="large">
        <scale-icon-action-edit></scale-icon-action-edit>
        {{'feedbacks.title.surveys.editSurvey'|translate}}
      </scale-button>
      <scale-button (click)="opanModal()" variant="secondary">
        <scale-icon-action-show-password class="margin-right-xs"></scale-icon-action-show-password>
        {{'feedbacks.title.surveys.surveyPreview'|translate}}
      </scale-button>
    </div>
    <div class="details-active-wrapper">
      <p> {{'feedbacks.title.surveys.surveyIsActive'|translate}}
        <scale-switch [checked]="survey.isActive" size="small" class="scale-switch-item"
          (scaleChange)="onIsActiveChanged()"></scale-switch>
      <p>
      <div class="intro p-col-7 no-padding-left">
        <scale-notification heading="{{'admin.usersRoles.description'|translate}}" opened variant="warning">
        </scale-notification>
      </div>
    </div>

    <div class="p-grid survey-details-container">
      <div class="p-col-3 survey-tile-table-header">
        <p class="bold margin-bottom-small">{{'feedbacks.title.surveys.description'|translate}}</p>
        <p class="">{{ survey.description.get(currentLang) }}</p>
      </div>
      <div class="p-col-3 survey-tile-table-header">
        <div class="survey-tile-icon margin-bottom-small">
          <scale-icon-content-calendar></scale-icon-content-calendar>
          <p class="bold margin-left-small"> {{'feedbacks.title.surveys.duration'|translate}}</p>
        </div>
        <p>{{ survey.startDate | date: 'dd.MM.yyyy' }} - {{ survey.endDate | date: 'dd.MM.yyyy' }}
        </p>
      </div>
      <div class="p-col-3 survey-tile-table-header">
        <p class="bold margin-bottom-small">{{'feedbacks.title.surveys.companies'|translate}}</p>
        <p>{{ survey.companyNames.length ? survey.companyNames.join(', ') : 'feedbacks.title.surveys.empty'|translate }}
        </p>
      </div>
      <div class="p-col-3 survey-tile-table-header">
        <div class="survey-tile-icon margin-bottom-small">
          <scale-icon-user-file-user accessibility-title="user"></scale-icon-user-file-user>
          <p class="bold margin-left-small">{{'feedbacks.title.surveys.submits'|translate}}</p>
        </div>
        <h2>{{survey.numberOfSubmits}}</h2>
      </div>
    </div>

    <ng-container *ngIf="survey.numberOfSubmits > 0">
      <app-survey-results [surveyResultsId]="survey.id" [survey]="survey"></app-survey-results>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="editOpened">
  <app-survey-configurator [survey]="survey"
    (isActiveChanged)="surveyActivesToggleHandler($event,editOpened)"></app-survey-configurator>
</ng-container>

<!-- Surveypreview modalWindow -->
<div class="modal-wripper">
  <app-survey-preview-modal [showModal]="showPreviewSurveyModal" (isModalOpen)="showPreviewSurveyModal = $event"
    [previewMode]="'Survey Preview'" [surveyResults]="'surveyResults'" [survey]="survey"></app-survey-preview-modal>
</div>