<div class="heading-item">
    <h3>Text</h3>
    <scale-checkbox label="{{'feedbacks.title.buttons.mandatory'|translate}}" [attr.checked]="isMandatory" class="padding-checkbox"
        (scale-change)="isMandatoryOnChange($event)"></scale-checkbox>
</div>
<div class="margin-top-sm">
    <div class="p-grid margin-top-xs">
        <div class="p-col-12 p-sm-6 p-xs-6">
            <label class="label-title" for="questionDE">{{'feedbacks.title.surveys.questionDE'|translate}}</label>
            <scale-textarea id="text-field" label="Survey name DE" rows="5" [attr.value]="questionDe" 
            [invalid]="!(questionDe || valid)"
            [attr.helper-text]=" (questionDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
            (scaleChange)="textQuestionAdd($event,'de')">
            </scale-textarea>
        </div>
        <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
            <label class="label-title"  for="questionEN">{{'feedbacks.title.surveys.questionEN'|translate}}</label>
            <scale-textarea id="text-field" label="Survey name EN" rows="5" [attr.value]="questionEn"
            [invalid]="!(questionEn || valid)" 
            [attr.helper-text]=" (questionEn || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
            (scaleChange)="textQuestionAdd($event,'en')">
            </scale-textarea>
        </div>
    </div>
</div>