import { Pipe, PipeTransform } from '@angular/core';
import { ReportGrouped } from '../models/report';
import { Pair } from '../models/pair';

@Pipe({
  name: 'sortByTransport',
})
export class SortByTransportPipe implements PipeTransform {
  constructor() {}

  transform(reports: ReportGrouped[]): Pair[] {
    const pairs: Map<string, number> = new Map();
    reports?.forEach((report) =>
      pairs.get(report.transportType)
        ? pairs.set(
            report.transportType,
            pairs.get(report.transportType) + report.distance
          )
        : pairs.set(report.transportType, report.distance)
    );
    return Array.from(pairs.keys())
      .map((key) => ({ key, value: pairs.get(key) }))
      .sort((a, b) => b.value - a.value);
  }
}
