import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication';
import { ApiRoleType } from '../models/enums/api-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiDocumentationGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.hasPermission()) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }

  canLoad() {
    if (this.hasPermission()) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }

  public hasPermission() {
    const roles = this.authenticationService.getAccessTokenRoles();
    const containsAllowedClaims = [ApiRoleType.AdminAccess].some((role) =>
      roles.includes(role)
    );
    return containsAllowedClaims && environment.documentation;
  }
}
