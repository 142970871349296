<div class="date-input dialog-component">
    <div class="wrapper">
        <label for="fromDateInput" class="label-date">{{'feedbacks.date.Start' | translate}}</label>
        <div class="datepicker">
            <input matInput [matDatepicker]="dpFrom" [min]="defaultFromDate" [formControl]="fromDateControl"
                (dateChange)="onInputChange($event, true)">
            <button (click)="dpFrom.open()"><img [src]="'assets/svg/calendar.svg'"></button>
            <mat-datepicker #dpFrom></mat-datepicker>

        </div>
        <span *ngIf="suffixFromLabel && inputIsInvalid()">{{suffixFromLabel | translate}}</span>
    </div>
    <div class="wrapper">
        <label for="fromDateInput" class="label-date">{{'feedbacks.date.End' | translate}}</label>
        <div class="datepicker">
            <input matInput [matDatepicker]="dpTo" [min]="defaultFromDate" [formControl]="toDateControl"
                (dateChange)="onInputChange($event)">
            <button (click)="dpTo.open()"><img src="assets/svg/calendar.svg"></button>
            <mat-datepicker #dpTo></mat-datepicker>
        </div>
        <span *ngIf="suffixToLabel && inputIsInvalid()">{{suffixToLabel | translate}}</span>
    </div>
</div>
<span *ngIf="showErrorFromField">{{'admin.license.date.outOfBound'|translate}}</span>