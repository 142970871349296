import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, forwardRef } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { IntroSectionComponent } from './components/intro-section/intro-section.component';
import { RatingConfiguratorComponent } from './components/rating-configurator/rating-configurator.component';
import { SelectConfiguratorComponent } from './components/select-configurator/select-configurator.component';
import { SurveyConfiguratorComponent } from './components/survey-configurator/survey-configurator.component';
import { SurveyDetailsComponent } from './components/survey-details/survey-details.component';
import { SurveyFilterComponent } from './components/survey-filter/survey-filter.component';
import { SurveyPreviewModalComponent } from './components/survey-preview-modal/survey-preview-modal.component';
import { SurveyResultsComponent } from './components/survey-results/survey-results.component';
import { SurveyTileComponent } from './components/survey-tile/survey-tile.component';
import { TextQuestionConfiguratorComponent } from './components/text-question-configurator/text-question-configurator.component';
import { FeedbackComponent } from './feedback.component';

@NgModule({
  declarations: [
    FeedbackComponent,
    IntroSectionComponent,
    SurveyTileComponent,
    SurveyFilterComponent,
    SurveyTileComponent,
    SurveyDetailsComponent,
    SurveyResultsComponent,
    SurveyPreviewModalComponent,
    SurveyConfiguratorComponent,
    RatingConfiguratorComponent,
    SelectConfiguratorComponent,
    TextQuestionConfiguratorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MultiSelectModule,
    DropdownModule,
    InputSwitchModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ''),
      multi: true,
    },
  ],
})
export class FeedbackModule {}
