import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';
import { NameRegex } from '../models/const/common';

const createAddTenantDialog = (tenantNameKey: string) => {
  return {
    confirmationTextKey: 'admin.tenants.dialog.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'admin.tenants.dialog.name.placeholder',
        prefix: 'admin.tenants.dialog.name.prefix',
        suffix: 'admin.tenants.dialog.name.suffix',
        key: tenantNameKey,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'admin.tenants.dialog.create.title',
    dialogType: DialogLayoutType.COLUMN,
  } as TableDialogInput;
};

const createEditTenantDialog = (tenantNameKey: string, tenantName: string) => {
  return {
    confirmationTextKey: 'admin.tenants.dialog.edit.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'admin.tenants.dialog.name.placeholder',
        prefix: 'admin.tenants.dialog.name.prefix',
        suffix: 'admin.tenants.dialog.name.suffix',
        key: tenantNameKey,
        value: tenantName,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'admin.tenants.dialog.edit.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

export { createAddTenantDialog, createEditTenantDialog };
