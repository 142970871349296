<div class="tenant-detail">
    <div class="path">
        <a (click)="closeTenantDetailView()">{{'admin.tenants.headline' |translate }}</a>
        <img src="assets/images/shared/right.svg">
        <a>{{tenant.name}}</a>
    </div>

    <div class="header">
        <h2>{{tenant.name}}</h2>
        <app-text-button [text]="'admin.detail.editTenantName' | translate" [icon]="'assets/svg/edit.svg'"
            [fontSizeFactor]=".8" (click)="editTenantName()"></app-text-button>
    </div>

    <div class="sub-pages">
        <a *ngFor="let menuItem of menuItems" [class.active]="selectedInternalName===menuItem.internalName"
            (click)="selectMenuItem(menuItem)">{{menuItem.displayKey|translate}}</a>
    </div>

    <app-admin-license *ngIf="selectedInternalName===licenseInternalName"></app-admin-license>
    <app-activationcodes *ngIf="selectedInternalName===activationcodeseInternalName"></app-activationcodes>
    <app-users-roles *ngIf="selectedInternalName===usersRolesInternalName"></app-users-roles>
</div>