import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeIcons } from 'primeng/api';
import { DashboardInfo } from 'src/app/models/dashboard-info';
import { DataPrivacyPageComponent } from 'src/app/pages/data-privacy-page/data-privacy-page.component';
import { ImprintPageComponent } from 'src/app/pages/imprint-page/imprint-page.component';
import { Language } from '../../../app.component';

class FooterNavigationItem {
  name: string;
  id: string;
  href?: string;
  icon?: string;
  mailto?: string;
}

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
})
export class LandingFooterComponent implements OnInit {
  footerNavigation: FooterNavigationItem[];
  dashboardInfo: DashboardInfo;
  public mmsLogo: string = '';

  constructor(
    public translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const svgPath = '../../assets/svg';
    this.mmsLogo = svgPath + '/mms-logo.svg';
    const english = this.translateService.currentLang === Language.ENGLISH;
    //Terms of service / conditions are not needed currently
    this.translateService
      .get([
        'landing.footerContact',
        'landing.footerImprint',
        'landing.footerDataPrivacy',
      ])
      .subscribe((result) => {
        this.footerNavigation = [
          {
            name: result['landing.footerContact'],
            id: 'contact',
            mailto: 'ecoshift@t-systems-mms.com',
          },
          // {
          //   name: 'termsAndConditions',
          //   id: 'Terms and conditions',
          //   href: '/terms-and-conditions',
          //   width: english ? '170px' : '180px',
          // },
          {
            name: result['landing.footerImprint'],
            id: 'imprint',
            href: ImprintPageComponent.route,
          },
          {
            name: result['landing.footerDataPrivacy'],
            id: 'dataprivacy',
            href: DataPrivacyPageComponent.route,
            icon: svgPath + '/icons/data-privacy-icon.svg',
          },
        ];
      });
  }

  public navigateTo(href: string) {
    this.router.navigateByUrl('/' + href);
  }
}
