import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageHttpService {
  private apiService: ApiService = inject(ApiService);

  public uploadLogo(image: string): Observable<string> {
    return this.apiService
      .post({
        path: `${APIConst.TENANT_LOGO}`,
        endp: environment.apiPath,
        sendIDToken: true,
        body: image,
      })
      .pipe(
        map((value) => value.body),
        map((response) => response)
      );
  }

  public getTenantLogo(logoId: string): Observable<any> {
    return this.apiService
      .get({
        path: `${APIConst.TENANT_LOGO}/${logoId}`,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((response) => response)
      );
  }

  public deleteTenantLogo(logoId: string): Observable<any> {
    const url = `${APIConst.TENANT_LOGO}/${logoId}`;
    return this.apiService
      .delete({
        path: url,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((response) => response)
      );
  }
}
