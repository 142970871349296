import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconComponent } from './icon/icon.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [IconComponent, AngularSvgIconModule],
})
export class IconModule {}
