import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { log } from 'console';
import { Observable, Subscription } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import { Brand } from 'src/app/models/brand';
import { Branding } from 'src/app/models/branding';
import { Tenant } from 'src/app/models/tenant';
import { BrandingService } from 'src/app/services/branding.service';
import { ContentLoadingService } from 'src/app/services/content-loading.service';
import { BlobStorageHttpService } from 'src/app/services/http/blob-storage-http.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TokenUtil } from 'src/app/utils/token-util';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit, OnDestroy {
  public static get route(): string {
    return 'branding';
  }

  public branding: Brand;
  public originalIcon: string = '';

  private originalId: string = '';

  svg: SafeHtml;

  private brandingSubscription: Subscription;
  private tenantLogoSubscription: Subscription;

  public iconHasChanged: boolean = false;

  constructor(
    public domSanitizer: DomSanitizer,
    private brandingService: BrandingService,
    private blobStorageHttpService: BlobStorageHttpService,
    private contentLoadingService: ContentLoadingService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.contentLoadingService.setLoadingState(true);
    this.brandingSubscription = this.tenantService.loggedInTenant
      .pipe(
        filter((tenant) => tenant !== undefined && tenant.id !== undefined),
        mergeMap((tenant: Tenant) => {
          return this.brandingService.getBrandingFor(tenant.id);
        })
      )
      .subscribe((branding: Brand) => {
        this.branding = branding;
        this.contentLoadingService.setLoadingState(false);
        if (this.branding && this.branding.icon.length > 0) {
          this.contentLoadingService.setLoadingState(true);
          this.tenantLogoSubscription = this.blobStorageHttpService
            .getTenantLogo(this.branding.icon)
            .subscribe((logo) => {
              const parser = new DOMParser();
              const svgDocument = parser.parseFromString(logo, 'image/svg+xml');
              const svgElement = svgDocument.documentElement;

              const serializer = new XMLSerializer();
              const tenantLogo: string =
                'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(serializer.serializeToString(svgElement));

              this.originalId = this.branding.icon;
              this.branding.icon = tenantLogo;
              this.originalIcon = tenantLogo;
            });
          this.contentLoadingService.setLoadingState(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.brandingSubscription) this.brandingSubscription.unsubscribe();

    if (this.tenantLogoSubscription) this.tenantLogoSubscription.unsubscribe();
  }

  updateIcon(icon: string) {
    this.iconHasChanged = this.branding.icon !== icon;
    this.branding.icon = icon;
  }

  public undoIconChange() {
    this.branding.icon = this.originalIcon;
    this.iconHasChanged = false;
  }

  public saveIcon() {
    if (this.branding.icon !== this.originalIcon) {
      const svgString = atob(this.branding.icon.split(',')[1]);

      if (this.originalIcon && this.originalIcon !== '') {
        this.blobStorageHttpService
          .deleteTenantLogo(this.originalId)
          .subscribe((_) => {});
      }

      this.blobStorageHttpService
        .uploadLogo(svgString)
        .subscribe((response) => {
          const iconData = this.branding.icon;
          this.branding.icon = response;
          this.brandingService.putBranding(this.branding);
          this.branding.icon = iconData;
        });
    }
  }

  public getBrandKeys(): Omit<Branding, 'icon'> | {} {
    if (this.branding) {
      const result = [
        {
          key: 'brandColors',
          value: this.branding.brandColors,
        },
        {
          key: 'textColors',
          value: this.branding.textColors,
        },
        {
          key: 'surfaceColors',
          value: this.branding.surfaceColors,
        },
      ];
      return result;
    }
    return [];
  }
}
