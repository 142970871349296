import { Brand } from '../models/brand';
import { BrandingType } from '../models/enums/branding-type.enum';

export class BrandingUtil {
  public static brandingAttributeIsValid(attribute: unknown): boolean {
    return attribute !== undefined && attribute !== null && attribute !== '';
  }

  public static getDefaultColorValue(
    key: string,
    type: number,
    isInverse?: boolean
  ) {
    switch (type) {
      case BrandingType.BRAND_COLORS: {
        if (isInverse) {
          return DEFAULT_BRANDING.brandColors[key].inverseColor.colorValue;
        }
        return DEFAULT_BRANDING.brandColors[key].colorValue;
      }

      case BrandingType.SURFACE_COLORS: {
        if (isInverse) {
          return DEFAULT_BRANDING.surfaceColors[key].inverseColor.colorValue;
        }

        return DEFAULT_BRANDING.surfaceColors[key].colorValue;
      }

      default: {
        if (isInverse) {
          return DEFAULT_BRANDING.textColors[key].inverseColor.colorValue;
        }
        return DEFAULT_BRANDING.textColors[key].colorValue;
      }
    }
  }
}

export enum DEFAULT_BRANDING_COLORS {
  brandPrimary = '#e20074',
  brandDark = '#c00063',
  backdropColor = '#808080',
  backgroundColor = '#FFFFFF',
  backgroundColorInverse = '#212121',
  backgroundLightColor = '#e7e7e9',
  backgroundLightColorInverse = '#3C3C3C',
  backgroundStrongColor = '#747478',
  backgroundStrongColorInverse = '#595959',
  borderColor = '#B63D00',
  borderColorInverse = '#F97012',
  solidColor = '#B63D00',
  surfaceColor = '#F6F6F6',
  surfaceInverseColor = '#3C3C3C',
  textAdditionalColor = '#595959',
  textAdditionalColorInverse = '#4D4D4D',
  textColor = '#000000',
  textInverseColor = '#FFFFFF',
  textDisabledColor = '#999999',
  textDisabledColorInverse = '#4D4D4D',
  textErrorColor = '#D91308',
  textErrorColorInverse = '#F84934',
  textInformationColor = '#1C41EB',
  textInformationColorInverse = '#1D85FF',
  textLinkColor = '#E20074',
  textLinkColorInverse = '#ED479C',
  textSuccessColor = '#03B014',
  textSuccessColorInverse = '#38D047',
  textWarningColor = '#B63D00',
  textWarningColorInverse = '#F97012'


}
export const DEFAULT_BRANDING = {
  tenantID: '',
  icon: '',
  brandColors: {
    brandPrimary: {
      colorValue: DEFAULT_BRANDING_COLORS.brandPrimary,
      key: 'brandPrimary',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.brandDark,
        key: 'brandDark',
      },
    },
  },
  surfaceColors: {
    backdropColor: {
      colorValue: DEFAULT_BRANDING_COLORS.backdropColor,
      key: 'backdropColor',
    },
    backgroundColor: {
      colorValue: DEFAULT_BRANDING_COLORS.backgroundColor,
      key: 'backgroundColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.backgroundColorInverse,
        key: 'backgroundColorInverse',
      },
    },
    backgroundLightColor: {
      colorValue: DEFAULT_BRANDING_COLORS.backgroundLightColor,
      key: 'backgroundLightColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.backgroundLightColorInverse,
        key: 'backgroundLightColorInverse',
      },
    },
    backgroundDarkColor: {
      colorValue: DEFAULT_BRANDING_COLORS.backgroundStrongColor,
      key: 'backgroundStrongColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.backgroundStrongColorInverse,
        key: 'backgroundStrongColorInverse',
      },
    },
    borderColor: {
      colorValue: DEFAULT_BRANDING_COLORS.borderColor,
      key: 'borderColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.borderColorInverse,
        key: 'borderColorInverse',
      },
    },
    solidColor: {
      colorValue: DEFAULT_BRANDING_COLORS.solidColor,
      key: 'solidColor',
    },
    surfaceColor: {
      colorValue: DEFAULT_BRANDING_COLORS.surfaceColor,
      key: 'surfaceColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.surfaceInverseColor,
        key: 'surfaceInverseColor',
      },
    },
  },
  textColors: {
    textAdditionalColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textAdditionalColor,
      key: 'textAdditionalColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textAdditionalColorInverse,
        key: 'textAdditionalColorInverse',
      },
    },
    textColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textColor,
      key: 'textColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textInverseColor,
        key: 'textInverseColor',
      },
    },
    textDisabledColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textDisabledColor,
      key: 'textDisabledColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textDisabledColorInverse,
        key: 'textDisabledColorInverse',
      },
    },
    textErrorColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textErrorColor,
      key: 'textErrorColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textErrorColorInverse,
        key: 'textErrorColorInverse',
      },
    },
    textInformationColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textInformationColor,
      key: 'textInformationColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textInformationColorInverse,
        key: 'textInformationColorInverse',
      },
    },
    textLinkColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textLinkColor,
      key: 'textLinkColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textLinkColorInverse,
        key: 'textLinkColorInverse',
      },
    },
    textSuccessColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textSuccessColor,
      key: 'textSuccessColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textSuccessColorInverse,
        key: 'textSuccessColorInverse',
      },
    },
    textWarningColor: {
      colorValue: DEFAULT_BRANDING_COLORS.textWarningColor,
      key: 'textWarningColor',
      inverseColor: {
        colorValue: DEFAULT_BRANDING_COLORS.textWarningColorInverse,
        key: 'textWarningColorInverse',
      },
    },
  },
} as Brand;
