import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss'],
})
export class IntroSectionComponent implements OnInit {
  public heroImage: string = '';
  public co2Icon: string = '';
  public leafImage: string = '';
  public overlapingElement: string = '';
  public innerWidth: any;
  public introText: string = '';
  public highlightText: string;

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {
    this.innerWidth = document.documentElement.clientWidth;
    const svgPath = '../../assets/svg';
    this.heroImage = svgPath + '/IMG_RIGHT.png';
    this.co2Icon = svgPath + '/icons/co2-logo.svg';
    this.leafImage = svgPath + '/top-leaf.svg';
    this.overlapingElement = svgPath + '/overlaping-element.svg';

    this.translateService
      .get(['landing.intro', 'landing.introHighlight'])
      .subscribe((result) => {
        this.introText = result['landing.intro'];
        this.highlightText = result['landing.introHighlight'];
      });
  }

  public isMobile(): boolean {
    return this.innerWidth < 1280;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = document.documentElement.clientWidth;
  }
}
