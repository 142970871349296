<app-filter [filter]="groupedFilter" [reports$]="reportService.overviewReports$"></app-filter>

<div>
  <div class="p-grid card-container">
    <div class="p-col-12 p-lg-4 p-xl-4">
      <app-card [header]="'cards.emission-header' | translate" imgAlt="Emission icon"
        imgSrc="assets/svg/icons/emissions.svg">
        <ng-container *ngIf="(reportService.overviewReports$ | async)">
          <div class="p-col-12 huge-padding-down">
            {{ 'common.total' | translate}}:
            <div class="p-d-inline bold">
              <h2 class="p-d-inline">{{formatValue(_total)}}</h2>
            </div>
          </div>
          <div class="p-col-12 p-xs-4 p-sm-4">
            <div class="p-grid">
              <div class="p-col-12">
                {{ 'cards.emission-commute' | translate}}
              </div>
              <div class="p-col-12">
                <div class="bold">
                  <h3 class="p-d-inline">{{formatValue(_commute)}}</h3>

                </div>
              </div>
              <div class="p-col-12">
                {{ 'cards.emission-business' | translate}}
              </div>
              <div class="p-col-12">
                <div class="bold">
                  <h3 class="p-d-inline">{{formatValue(_business)}}</h3>

                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-xs-8 p-sm-8">
            <div class="p-grid" style="height: 100%">
              <div class="p-col-3">
                <div class="chart-legend">{{'common.business-trip' | translate}}</div>
                <div class="chart-legend-value">{{businessPercentage}}%</div>
              </div>
              <div class="p-col-6" *ngIf="businessPercentage && commutePercentage">
                <p-chart type="pie" [data]="chartData" [options]="chartsOptions" height="200px" [width]="'100%'">
                </p-chart>
              </div>
              <div class="p-col-3" style="align-self: flex-end">
                <div class="chart-legend">{{'common.commute' | translate}}</div>
                <div class="chart-legend-value margin-bottom">{{commutePercentage}} %</div>
              </div>
            </div>
          </div>
        </ng-container>
      </app-card>
    </div>

    <div class="separator-container horizontal-separator">
      <hr class="separator">
    </div>

    <div class="p-col-12 p-md-6 p-lg-4 p-xl-4">
      <div class="overlay-grey-out" [ngStyle]="{display: hovering ? 'block' : 'none'}"></div>
      <app-card [header]="'common.tripDistance' | translate" imgAlt="Trip distance icon"
        imgSrc="assets/svg/icons/tripDistance.svg" [showToggle]="true"
        [transportTypesCont]="(reportService.overviewReports$ | async | sortByTransport)?.length"
        (hovering)="onHoverChange($event)">
        <ng-container *ngIf="reportService.overviewReports$ | async  as reports">
          <div class="p-col-12 huge-padding-down">
            {{ 'common.total' | translate}}:
            <div class="p-d-inline bold">
              <h2 class="p-d-inline">{{ reports | sumDistance | number:'.0-2': translateService.currentLang }}</h2> km
            </div>
          </div>
          <div class="p-col-6 p-sm-4 p-md-6 p-lg-6 p-lg-4 p-xl-4"
            *ngFor="let pair of (hovering ? (reports | sortByTransport) : (reports | sortByTransport | filterSix))">
            <app-transport-cell [transportType]="pair.key" [distance]="pair.value"></app-transport-cell>
          </div>
        </ng-container>
      </app-card>
    </div>

    <div class="separator-container small-horizontal-separator">
      <hr class="separator">
    </div>

    <div class="p-col-12 p-md-6 p-lg-4 p-xl-4">
      <app-card [header]="'cards.eco-trip-header' | translate" imgAlt="Eco distance icon"
        imgSrc="assets/svg/icons/ecoLeaf.svg">
        <ng-container *ngIf="reportService.overviewReports$ | async  as reports">
          <div class="p-col-12 huge-padding-down">
            {{ 'common.total' | translate}}:
            <div class="p-d-inline bold">
              <h2 class="p-d-inline">{{ reports | filterEco | sumDistance | number:'.0-2': translateService.currentLang
                }}</h2> km
            </div>
          </div>
          <div class="p-col-6 p-sm-4 p-md-6 p-lg-6 p-xl-6 p-xxl-4"
            *ngFor="let pair of reports | filterEco | sortByTransport">
            <app-transport-cell *ngIf="pair.key !== ''" [transportType]="pair.key" [distance]="pair.value">
            </app-transport-cell>
          </div>
        </ng-container>
      </app-card>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" *ngIf="!officeId">
      <app-office-table [filter]="groupedFilter"></app-office-table>
    </div>
  </div>
</div>