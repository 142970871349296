import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantDetailsService {
  private _detailsOpen: boolean = false;

  public get detailsOpen(): boolean {
    return this._detailsOpen;
  }

  public set detailsOpen(status: boolean) {
    this._detailsOpen = status;
  }
}
