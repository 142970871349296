import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TripFilterComponent } from './trip-filter.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [TripFilterComponent],
  exports: [
    TripFilterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MultiSelectModule,
    DropdownModule,
    InputSwitchModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TripFilterModule { }
