import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import {
  DialogElement,
  TableDialogInput,
} from '../models/enums/dialog/table-dialog-element';
import { License } from '../models/license';
import { NameRegex } from '../models/const/common';

const createAddLicenseDialog = (
  nameKey: string,
  dateKey: string,
  codesKey: string,
  validation: any
) => {
  return {
    confirmationTextKey: 'license.dialog.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: nameKey,
        key: 'name',
        prefix: 'license.dialog.create.name.prefix',
        suffix: 'license.dialog.create.name.suffix',
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.DATE,
        placeHolder: dateKey,
        key: 'date',
        prefix:
          'license.dialog.create.date.from.prefix;license.dialog.create.date.to.prefix',
        suffix:
          'license.dialog.create.date.from.suffix;license.dialog.create.date.to.suffix',
        validation: validation,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: codesKey,
        key: 'codes',
        prefix: 'license.dialog.create.codes.prefix',
        suffix: 'license.dialog.create.codes.suffix',
      },
    ],
    dialogTitleTextKey: 'license.dialog.create.title',
    dialogType: DialogLayoutType.GRID,
  } as TableDialogInput;
};

const createEditLicenseDialog = (
  nameKey: string,
  dateKey: string,
  codesKey: string,
  license: License,
  validation: any,
  contractFrom: Date,
  contractTo: Date
) => {
  return {
    confirmationTextKey: 'license.dialog.edit.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: nameKey,
        key: 'name',
        prefix: 'license.dialog.create.name.prefix',
        suffix: 'license.dialog.create.name.suffix',
        value: license.contractName,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.DATE,
        placeHolder: dateKey,
        key: 'date',
        prefix:
          'license.dialog.create.date.from.prefix;license.dialog.create.date.to.prefix',
        suffix:
          'license.dialog.create.date.from.suffix;license.dialog.create.date.to.suffix',
        validation: validation,
        value: { fromDate: contractFrom, toDate: contractTo },
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: codesKey,
        key: 'codes',
        prefix: 'license.dialog.create.codes.prefix',
        suffix: 'license.dialog.create.codes.suffix',
        value: license.amountOfCodes,
      },
    ],
    dialogTitleTextKey: 'license.dialog.edit.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createIncreaseCodesDialog = (codesKey: string) => {
  return {
    confirmationTextKey: 'license.dialog.codes.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'license.dialog.codes.suffix',
        key: codesKey,
        prefix: 'license.dialog.codes.prefix',
        numeric: true,
      } as unknown as DialogElement,
    ],
    dialogTitleTextKey: 'license.dialog.codes.title',
    dialogType: DialogLayoutType.COLUMN,
  } as TableDialogInput;
};

const createRenevalLicenseDialog = (license: License, validation: any) => {
  return {
    confirmationTextKey: 'license.dialog.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        prefix: 'license.dialog.create.name.prefix',
        suffix: 'license.dialog.create.name.suffix',
        value: license.contractName,
        key: 'contractName',
        validation: NameRegex,
      },

      {
        key: 'date',
        dialogStructure: DialogStructure.DATE,
        prefix:
          'license.dialog.create.date.from.prefix;license.dialog.create.date.to.prefix',
        suffix:
          'license.dialog.create.date.from.suffix;license.dialog.create.date.to.suffix',
        validation: validation,
        value: { fromDate: license.contractFrom, toDate: license.contractTo },
      },
    ],
    dialogTitleTextKey: 'license.dialog.create.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createDeleteLicenseDialog = () => {
  return {
    confirmationTextKey: 'license.dialog.delete.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'license.dialog.delete.text',
      },
    ],
    dialogTitleTextKey: 'license.dialog.delete.title',
    dialogType: DialogLayoutType.GRID,
    isDeleteDialog: true,
  } as TableDialogInput;
};

export {
  createIncreaseCodesDialog,
  createRenevalLicenseDialog,
  createAddLicenseDialog,
  createEditLicenseDialog,
  createDeleteLicenseDialog,
};
