import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodFilterComponent implements OnInit, OnDestroy {
  @Input() filter: UntypedFormGroup;
  @Input() filterReset: Subject<void>;
  selectedTag: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.filterReset
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.selectedTag = undefined));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onChooseQuickRange(selectedPeriod: string): void {
    this.selectedTag = selectedPeriod;
    const today = new Date();
    switch (selectedPeriod) {
      case 'week': {
        this.filter.controls.from.setValue(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
        );
        this.filter.controls.to.setValue(today);
        break;
      }
      case 'month': {
        this.filter.controls.from.setValue(
          new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        );
        this.filter.controls.to.setValue(today);
        break;
      }
      case 'quarter': {
        this.filter.controls.from.setValue(
          new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        );
        this.filter.controls.to.setValue(today);
        break;
      }
      case 'year': {
        this.filter.controls.from.setValue(
          new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
        );
        this.filter.controls.to.setValue(today);
        break;
      }
    }
  }
}
