import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenUtil } from 'src/app/utils/token-util';
import { environment } from '../../../environments/environment';
import { Emission } from '../../models/emission';
import { EmissionFetchType } from '../../models/emission-fetch-type';
import { APIConst } from '../../models/enums/api.enum';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class EmissionHttpService {
  private apiService: ApiService = inject(ApiService);

  getAllEmissionFactors(
    tenantId: string = undefined
  ): Observable<EmissionFetchType> {
    return this.apiService
      .get({
        path: `${
          APIConst.GET_EMISSIONS +
          (tenantId ? `/${tenantId}` : '') +
          this.apiService.requestParamsObj({})
        }`,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map(
          ({
            gasolineDefaultFactor,
            electricDefaultFactor,
            dieselDefaultFactor,
            countries,
          }) => {
            const emissionList: Emission[] = countries.map(
              (countryEmission: any) => {
                const emission: Emission = {
                  countryCode: countryEmission.code,
                  dieselFactor: countryEmission.dieselFactor,
                  electricFactor: countryEmission.electricFactor,
                  gasolineFactor: countryEmission.gasolineFactor,
                  tenantId: countryEmission.tenantId ?? '',
                  isEdit: false,
                  isSelected: false,
                };
                return emission;
              }
            );

            return {
              dieselDefaultFactor: dieselDefaultFactor,
              electricDefaultFactor: electricDefaultFactor,
              gasolineDefaultFactor: gasolineDefaultFactor,
              emissionList: emissionList,
            };
          }
        )
      );
  }

  addEmission(emission: Emission): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.POST_EMISSION}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        code: emission.countryCode,
        gasolineFactor: emission.gasolineFactor,
        electricFactor: emission.electricFactor,
        dieselFactor: emission.dieselFactor,
        tenantId: emission.tenantId,
        isDefault: emission.isDefault ?? false,
      },
    });
  }

  updateEmission(emission: Emission): Observable<any> {
    return this.apiService.put({
      path: `${APIConst.POST_EMISSION}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        code: emission.countryCode,
        gasolineFactor: emission.gasolineFactor,
        electricFactor: emission.electricFactor,
        dieselFactor: emission.dieselFactor,
        tenantId: emission.tenantId,
      },
    });
  }

  deleteEmissions(
    countryCode: string,
    tenantId: string = undefined
  ): Observable<any> {
    return this.apiService.delete({
      path: `${
        APIConst.POST_EMISSION +
        this.apiService.requestParamsObj({}) +
        '?code=' +
        countryCode
      }&tenantId=${
        tenantId ??
        TokenUtil.getTenantFromToken(localStorage.getItem('id-token'))
      }`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
    });
  }
}
