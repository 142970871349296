import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand } from 'src/app/models/brand';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { BrandingType } from '../../models/enums/branding-type.enum';
import { BrandingUtil, DEFAULT_BRANDING } from '../../utils/branding-util';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class BrandingHTTPService {
  private apiService: ApiService = inject(ApiService);

  putBranding(branding: Brand) {
    const body = {
      tenantID: branding.tenantID,
      icon: branding.icon,
      branding: {
        primaryColor: branding.brandColors.brandPrimary.colorValue,
        primaryDarkColor:
          branding.brandColors.brandPrimary.inverseColor.colorValue,
        backgroundColor: branding.surfaceColors.backgroundColor.colorValue,
        backgroundColorInverse:
          branding.surfaceColors.backgroundColor.inverseColor.colorValue,
        backgroundLightColor:
          branding.surfaceColors.backgroundLightColor.colorValue,
        backgroundLightColorInverse:
          branding.surfaceColors.backgroundLightColor.inverseColor.colorValue,
        backgroundStrongColor:
          branding.surfaceColors.backgroundDarkColor.colorValue,
        backgroundStrongColorInverse:
          branding.surfaceColors.backgroundDarkColor.inverseColor.colorValue,
        surfaceColor: branding.surfaceColors.surfaceColor.colorValue,
        surfaceColorInverse:
          branding.surfaceColors.surfaceColor.inverseColor.colorValue,
        backdropColor: branding.surfaceColors.backdropColor.colorValue,
        textColor: branding.textColors.textColor.colorValue,
        textColorInverse: branding.textColors.textColor.inverseColor.colorValue,
        textAdditionalColor: branding.textColors.textAdditionalColor.colorValue,
        textAdditionalColorInverse:
          branding.textColors.textAdditionalColor.inverseColor.colorValue,
        textDisabledColorInverse:
          branding.textColors.textDisabledColor.inverseColor.colorValue,
        textLinkColor: branding.textColors.textLinkColor.colorValue,
        textLinkColorInverse:
          branding.textColors.textLinkColor.inverseColor.colorValue,
        textSuccessColor: branding.textColors.textSuccessColor.colorValue,
        textSuccessColorInverse:
          branding.textColors.textSuccessColor.inverseColor.colorValue,
        textErrorColor: branding.textColors.textErrorColor.colorValue,
        textErrorColorInverse:
          branding.textColors.textErrorColor.inverseColor.colorValue,
        textWarningColor: branding.textColors.textWarningColor.colorValue,
        textWarningColorInverse:
          branding.textColors.textWarningColor.inverseColor.colorValue,
        textInformationColor:
          branding.textColors.textInformationColor.colorValue,
        textInformationColorInverse:
          branding.textColors.textInformationColor.inverseColor.colorValue,
        borderColor: branding.surfaceColors.borderColor.colorValue,
        borderColorInverse:
          branding.surfaceColors.borderColor.inverseColor.colorValue,
        solidColor: branding.surfaceColors.solidColor.colorValue,
        textDisabledColor: branding.textColors.textDisabledColor.colorValue,
      },
    };
    return this.apiService.put({
      path: `${APIConst.PUT_BRANDING + this.apiService.requestParamsObj({})}`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
      body: body,
    });
  }

  fetchBranding(tenantID: string): Observable<Brand | undefined> {
    return this.apiService
      .get({
        path: `${
          APIConst.GET_BRANDING + this.apiService.requestParamsObj({})
        }/${tenantID}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((branding) => {
          if (!branding) {
            const defaultBranding: Brand = { ...DEFAULT_BRANDING };
            defaultBranding.tenantID = tenantID;
            return defaultBranding;
          }

          const brandingAttributes = branding['branding'];
          return {
            tenantID: branding['tenantID'],
            icon: branding['icon'],
            brandColors: {
              brandPrimary: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['primaryColor']
                )
                  ? brandingAttributes['primaryColor']
                  : BrandingUtil.getDefaultColorValue(
                      'brandPrimary',
                      BrandingType.BRAND_COLORS
                    ),
                key: 'brandPrimary',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['primaryDarkColor']
                  )
                    ? brandingAttributes['primaryDarkColor']
                    : BrandingUtil.getDefaultColorValue(
                        'brandPrimary',
                        BrandingType.BRAND_COLORS,
                        true
                      ),
                  key: 'brandDark',
                },
              },
            },
            textColors: {
              textColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textColor']
                )
                  ? brandingAttributes['textColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textColorInverse']
                  )
                    ? brandingAttributes['textColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textInverseColor',
                },
              },
              textAdditionalColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textAdditionalColor']
                )
                  ? brandingAttributes['textAdditionalColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textAdditionalColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textAdditionalColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textAdditionalColorInverse']
                  )
                    ? brandingAttributes['textAdditionalColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textAdditionalColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textAdditionalColorInverse',
                },
              },
              textDisabledColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textDisabledColor']
                )
                  ? brandingAttributes['textDisabledColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textDisabledColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textDisabledColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textDisabledColorInverse']
                  )
                    ? brandingAttributes['textDisabledColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textDisabledColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textDisabledColorInverse',
                },
              },
              textErrorColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textErrorColor']
                )
                  ? brandingAttributes['textErrorColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textErrorColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textErrorColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textErrorColorInverse']
                  )
                    ? brandingAttributes['textErrorColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textErrorColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textErrorColorInverse',
                },
              },
              textInformationColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textInformationColor']
                )
                  ? brandingAttributes['textInformationColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textInformationColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textInformationColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textInformationColorInverse']
                  )
                    ? brandingAttributes['textInformationColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textInformationColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textInformationColorInverse',
                },
              },
              textLinkColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textLinkColor']
                )
                  ? brandingAttributes['textLinkColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textLinkColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textLinkColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textLinkColorInverse']
                  )
                    ? brandingAttributes['textLinkColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textLinkColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textLinkColorInverse',
                },
              },
              textSuccessColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textSuccessColor']
                )
                  ? brandingAttributes['textSuccessColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textSuccessColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textSuccessColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textSuccessColorInverse']
                  )
                    ? brandingAttributes['textSuccessColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textSuccessColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textSuccessColorInverse',
                },
              },
              textWarningColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['textWarningColor']
                )
                  ? brandingAttributes['textWarningColor']
                  : BrandingUtil.getDefaultColorValue(
                      'textWarningColor',
                      BrandingType.TEXT_COLORS
                    ),
                key: 'textWarningColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['textWarningColorInverse']
                  )
                    ? brandingAttributes['textWarningColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'textWarningColor',
                        BrandingType.TEXT_COLORS,
                        true
                      ),
                  key: 'textWarningColorInverse',
                },
              },
            },
            surfaceColors: {
              borderColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['borderColor']
                )
                  ? brandingAttributes['borderColor']
                  : BrandingUtil.getDefaultColorValue(
                      'borderColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'borderColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['borderColorInverse']
                  )
                    ? brandingAttributes['borderColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'borderColor',
                        BrandingType.SURFACE_COLORS,
                        true
                      ),
                  key: 'borderColorInverse',
                },
              },
              backdropColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['backdropColor']
                )
                  ? brandingAttributes['backdropColor']
                  : BrandingUtil.getDefaultColorValue(
                      'backdropColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'backdropColor',
              },
              solidColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['solidColor']
                )
                  ? brandingAttributes['solidColor']
                  : BrandingUtil.getDefaultColorValue(
                      'solidColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'solidColor',
              },
              backgroundColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['backgroundColor']
                )
                  ? brandingAttributes['backgroundColor']
                  : BrandingUtil.getDefaultColorValue(
                      'backgroundColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'backgroundColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['backgroundColorInverse']
                  )
                    ? brandingAttributes['backgroundColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'backgroundColor',
                        BrandingType.SURFACE_COLORS,
                        true
                      ),
                  key: 'backgroundColorInverse',
                },
              },
              backgroundLightColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['backgroundLightColor']
                )
                  ? brandingAttributes['backgroundLightColor']
                  : BrandingUtil.getDefaultColorValue(
                      'backgroundLightColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'backgroundLightColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['backgroundLightColorInverse']
                  )
                    ? brandingAttributes['backgroundLightColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'backgroundLightColor',
                        BrandingType.SURFACE_COLORS,
                        true
                      ),
                  key: 'backgroundLightColorInverse',
                },
              },
              backgroundDarkColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['backgroundStrongColor']
                )
                  ? brandingAttributes['backgroundStrongColor']
                  : BrandingUtil.getDefaultColorValue(
                      'backgroundDarkColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'backgroundDarkColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['backgroundStrongColorInverse']
                  )
                    ? brandingAttributes['backgroundStrongColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'backgroundDarkColor',
                        BrandingType.SURFACE_COLORS,
                        true
                      ),
                  key: 'backgroundDarkColorInverse',
                },
              },
              surfaceColor: {
                colorValue: BrandingUtil.brandingAttributeIsValid(
                  brandingAttributes['surfaceColor']
                )
                  ? brandingAttributes['surfaceColor']
                  : BrandingUtil.getDefaultColorValue(
                      'surfaceColor',
                      BrandingType.SURFACE_COLORS
                    ),
                key: 'surfaceColor',
                inverseColor: {
                  colorValue: BrandingUtil.brandingAttributeIsValid(
                    brandingAttributes['surfaceColorInverse']
                  )
                    ? brandingAttributes['surfaceColorInverse']
                    : BrandingUtil.getDefaultColorValue(
                        'surfaceColor',
                        BrandingType.SURFACE_COLORS,
                        true
                      ),
                  key: 'surfaceColorInverse',
                },
              },
            },
          } as Brand;
        })
      );
  }
}
