import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';
import { CountryCodeRegex, NameRegex, ZipRegex } from '../models/const/common';

const createDeleteOfficeDialog = () => {
  return {
    confirmationTextKey: 'data.table.dialog.office.delete.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'data.table.dialog.office.delete.text',
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.office.delete.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

const createEditOfficeDialog = (
  event,
  countryKey: string,
  streetKey: string,
  zipKey: string,
  cityKey: string
) => {
  return {
    confirmationTextKey: 'data.table.dialog.save',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.country.placeholder',
        prefix: 'data.table.dialog.office.country.prefix',
        suffix: 'data.table.dialog.emission.name.suffix',
        key: countryKey,
        value: event['data'][3].text,
        validation: CountryCodeRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.zip.placeholder',
        prefix: 'data.table.dialog.office.zip.prefix',
        suffix: 'data.table.dialog.office.zip.suffix',
        key: zipKey,
        value: event['data'][1].text,
        validation: ZipRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.city.placeholder',
        prefix: 'data.table.dialog.office.city.prefix',
        suffix: 'data.table.dialog.office.city.suffix',
        key: cityKey,
        value: event['data'][2].text,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.street.placeholder',
        prefix: 'data.table.dialog.office.street.prefix',
        suffix: 'data.table.dialog.office.street.suffix',
        key: streetKey,
        value: event['data'][0].text,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.office.edit.title',
    dialogType: DialogLayoutType.GRID,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createAddOfficeDialog = (
  countryKey: string,
  streetKey: string,
  zipKey: string,
  cityKey: string
) => {
  return {
    confirmationTextKey: 'data.table.dialog.office.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.country.placeholder',
        prefix: 'data.table.dialog.office.country.prefix',
        suffix: 'data.table.dialog.emission.name.suffix',
        key: countryKey,
        validation: CountryCodeRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.zip.placeholder',
        prefix: 'data.table.dialog.office.zip.prefix',
        suffix: 'data.table.dialog.office.zip.suffix',
        key: zipKey,
        validation: ZipRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.city.placeholder',
        prefix: 'data.table.dialog.office.city.prefix',
        suffix: 'data.table.dialog.office.city.suffix',
        key: cityKey,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.office.street.placeholder',
        prefix: 'data.table.dialog.office.street.prefix',
        suffix: 'data.table.dialog.office.street.suffix',
        key: streetKey,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.office.create.title',
    dialogType: DialogLayoutType.GRID,
  } as TableDialogInput;
};

export {
  createDeleteOfficeDialog,
  createEditOfficeDialog,
  createAddOfficeDialog,
};
