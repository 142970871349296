<div class="p-grid filter-container">
  <div class="p-col-12 header-margin p-flex">
    <h3 class="bold margin-right">{{'filters.filters' | translate}}</h3>
    <!-- <button class="reset" [disabled]="isDisabled()" (click)="resetFilter()">X {{'filters.reset-all' |
      translate}}</button> -->
    <button class="reset-button" *ngIf="isDisabled() == false" (click)="resetFilter()"><svg-icon
        [svgClass]="'close-button-svg'" src="assets/svg/cancel.svg"></svg-icon><span>{{'filters.reset-all' |
        translate}}</span></button>
  </div>
  <div class="p-col-12" *ngIf="!trendShowed">
    <app-period-filter [filter]="filter" [filterReset]="filterReset"></app-period-filter>
  </div>
  <div class="p-col-12">
    <app-trip-filter [filter]="filter" [trendShowed]="trendShowed"></app-trip-filter>
  </div>
  <div class="p-col-12">
    <div *ngIf="!(this.loaderService.isLoading | async); else skeleton">
      <div *ngIf="reports$ | async as reports">
        <span class="bold">{{reports | countRecords: 'reportCount'}} {{'filters.reports-count' | translate}}</span>
        {{reports | countRecords: 'autoOptinCount'}} {{'filters.auto-recorded-count' | translate}}
      </div>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <p-skeleton width="400px" height="25px"></p-skeleton>
</ng-template>