<div class="emissionfactors-wrapper page-wrapper">
    <div class="intro">
        <h2>{{'admin.emissionfactors.headline'|translate}}</h2>
        <p>{{'admin.emissionfactors.introText'|translate}}</p>
    </div>

    <div class="emission-factors">
        <div class="header">
            <h3>{{'data.table.title.emission.factor'|translate}}</h3>
            <scale-button (click)="addEmissionFactor()" variant="ghost" size="small" class="black-ghost-color"><img
                    class="reset-img" src="assets/svg/add.svg">{{'data.table.emission.add'|translate}}</scale-button>
        </div>
        <app-dashboard-table [tableData]="emissionTableData()" (editPressed)="editEmissionFactor($event)"
            (deletePressed)="deleteEmissionFactor($event)"></app-dashboard-table>
    </div>
</div>