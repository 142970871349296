import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentLoadingService {
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}

  public setLoadingState(value: boolean) {
    this._isLoading.next(value);
  }

  public get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }
}
