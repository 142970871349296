import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcoshiftRoleProtectedDirective } from './ecoshift-role-protected.directive';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [EcoshiftRoleProtectedDirective],
  exports: [EcoshiftRoleProtectedDirective],
  imports: [BrowserModule, CommonModule],
})
export class DirectivesModule {}
