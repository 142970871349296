import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ActivationCode } from 'src/app/models/activationcode';
import { License, LicenseStatus } from 'src/app/models/license';
import { Tenant } from 'src/app/models/tenant';
import { ActivationCodeService } from 'src/app/services/activationcode.service';
import { LicenseService } from 'src/app/services/license.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TenantDetailsService } from './details.service';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { createAddTenantDialog } from 'src/app/services/tenant-factory';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent implements OnInit {
  public static get route() {
    return 'tenants';
  }

  public tenants: Tenant[] = [];

  public data: Map<
    string,
    {
      license: License;
      activationCodesRemaining: number;
    }
  > = new Map<
    string,
    {
      license: License;
      activationCodesRemaining: number;
    }
  >();

  constructor(
    private tenantService: TenantService,
    private activationCodeService: ActivationCodeService,
    private licenseService: LicenseService,
    private tenantDetailsService: TenantDetailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.tenantService.tenantList.subscribe((tenants) => {
      this.tenants = tenants;
      if (tenants) {
        tenants.forEach((tenant) => {
          this.licenseService
            .getLicensesFromTenant(tenant.id)
            .subscribe((licenses) => {
              this.activationCodeService.activationCodes.subscribe(
                (activationCodes) => {
                  const tenantLicenses = licenses.filter((license) => {
                    return (
                      license.tenantId.toUpperCase() === tenant.id.toUpperCase()
                    );
                  });

                  let license = undefined;

                  const activeLicenses = tenantLicenses.filter(
                    (license) => license.status === LicenseStatus.ACTIVE
                  );

                  if (activeLicenses.length == 1) {
                    license = activeLicenses[0];
                  }

                  if (license === undefined) {
                    const holdLicenses = tenantLicenses
                      .filter(
                        (license) => license.status === LicenseStatus.ON_HOLD
                      )
                      .sort(
                        (a, b) =>
                          a.contractFrom.getTime() - b.contractFrom.getTime()
                      );

                    if (holdLicenses.length > 0) {
                      license = holdLicenses[0];
                    }
                  }

                  if (license === undefined) {
                    const outdatedLicenses = tenantLicenses
                      .filter(
                        (license) => license.status === LicenseStatus.OUTDATED
                      )
                      .sort(
                        (a, b) =>
                          a.contractTo.getTime() - b.contractTo.getTime()
                      );

                    if (outdatedLicenses.length > 0) {
                      license = outdatedLicenses[0];
                    }
                  }

                  let activationCodesRemaining: number = 0;
                  if (license === undefined) {
                    this.data.set(tenant.id, {
                      license: license,
                      activationCodesRemaining: 0,
                    });
                    return;
                  }

                  activationCodesRemaining = activationCodes
                    .filter((code) => code.licenseId === license.id)
                    .filter((code) => code.usagesCounter > 0).length;
                  this.data.set(tenant.id, {
                    license: license,
                    activationCodesRemaining: activationCodesRemaining,
                  });
                }
              );
            });
        });
      }
    });
  }

  public addTenant() {
    const tenantNameKey = 'tenant-name';
    this.dialog
      .open(TableDialogComponent, {
        data: createAddTenantDialog(tenantNameKey),
      })
      .afterClosed()
      .subscribe((response) => {
        this.tenantService.addTenant(response.get(tenantNameKey), true);
      });
  }

  public getDateEntry(tenant: Tenant) {
    return this.data.get(tenant.id);
  }

  public getUsedCodes(entry: {
    license: License;
    activationCodesRemaining: number;
  }): number {
    const remaining = entry.activationCodesRemaining;
    return remaining;
  }

  public openDetails(tenant: Tenant) {
    this.tenantService.selectTenant(tenant);

    this.tenantDetailsService.detailsOpen = true;
  }

  public get detailsOpen(): boolean {
    return this.tenantDetailsService.detailsOpen;
  }
}
