import { Component, OnInit, Input } from '@angular/core';
import { License, LicenseStatus } from 'src/app/models/license';

@Component({
  selector: 'app-license-status',
  templateUrl: './license-status.component.html',
  styleUrls: ['./license-status.component.scss'],
})
export class LicenseStatusComponent implements OnInit {
  @Input() licenseStatus: LicenseStatus;
  constructor() {}

  ngOnInit(): void {}

  statusIsActive() {
    return this.licenseStatus === LicenseStatus.ACTIVE;
  }

  statusIsHold() {
    return this.licenseStatus === LicenseStatus.ON_HOLD;
  }

  statusIsOutdated() {
    return this.licenseStatus === LicenseStatus.OUTDATED;
  }
}
