const downloadCSV = (fileName: string, data: string) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  if (typeof navigator['msSaveBlob'] !== 'undefined') {
    // For Internet Explorer
    navigator['msSaveBlob'](blob, fileName);
  } else {
    // For modern browsers
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export { downloadCSV };
