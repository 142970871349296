// intro-section.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyService } from 'src/app/services/survey.service';



@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent  implements OnInit {
  
  ngOnInit(): void {
  }

  @Input() surveyCount: number; 
  @Input( ) survey: Survey;
  @Output() createNewSurveyClicked: EventEmitter<boolean> = new EventEmitter();


  constructor(private surveyService: SurveyService) { }

  createNewSurvey(){
    this.createNewSurveyClicked.emit(true);
  }
  

}