<div class="main">
  <dashboard-header></dashboard-header>
  <div class="main-container" [class.padding]="(currentUrl|async) !== landingPage"
    [class.container--centered]="(currentUrl|async) !== landingPage && (currentUrl|async) !== loginPage">
    <div *ngIf="contentIsLoading | async" class="overlay"><shared-loader></shared-loader></div>
    <router-outlet></router-outlet>
  </div>
  <div class="footer-container">
    <app-footer
      *ngIf="(currentUrl|async) !== landingPage && (currentUrl|async) !== dataPrivacyPage && (currentUrl|async) !== imprintPage"></app-footer>
    <app-landing-footer
      *ngIf="(currentUrl|async) === landingPage || (currentUrl|async) === dataPrivacyPage || (currentUrl|async) === imprintPage"></app-landing-footer>
  </div>
</div>