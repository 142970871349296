import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InputResult } from '../table-dialog/table-dialog.component';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit, AfterViewChecked {
  @Input() data: any;

  public inputLabel: string;
  public inputPlaceHolder: string = '';
  public suffix: string;

  public text: string = '';
  private touched: boolean = false;
  public inputValue: number = 0;
  public isNumericField: boolean = false;

  public regex: RegExp = RegExp('.*');

  @Output() changes: EventEmitter<InputResult> = new EventEmitter();

  private usedEMails: string[] = [];

  public numericInputIsEmpty: boolean = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    const element = this.data['element'];

    if (element) {
      this.inputLabel = element['prefix'];
      this.inputPlaceHolder = element['placeHolder'];
      this.suffix = element['suffix'];
      const recievedText = element['value'];
      if (recievedText) {
        this.text = recievedText;
      }

      if (element.usedEMails) {
        this.usedEMails = element.usedEMails;
      }

      if (element.validation !== undefined && element.validation !== null) {
        this.regex = RegExp(element.validation);
      }

      if (element['dialogStructure'] === 'numeric-text') {
        this.isNumericField = true;
        const recievedNumber = element['value'];
        if (recievedNumber) {
          this.inputValue = recievedNumber;
        } else {
          this.inputValue = 1.0;
          this.touched = true;
          this.changes.emit({ value: 1 + '', valid: this.regex.test(1 + '') });
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public wasTouched() {
    this.touched = true;
  }

  public clearText() {
    if (this.isNumericField) {
      this.inputValue = 0;
      this.changes.emit({
        value: this.inputValue + '',
        valid: this.regex.test(this.inputValue + ''),
      });
    }
    this.text = '';
    this.changes.emit({ value: '', valid: this.regex.test('') });
  }

  public isEmailField(): boolean {
    return this.usedEMails.length > 0;
  }

  public emailIsAlreadyInUse(): boolean {
    return this.usedEMails.includes(this.text);
  }

  public inputIsInvalid(): boolean {
    if (this.isNumericField) {
      if (this.regex.test(this.inputValue + '') == false && this.touched) {
        return true;
      }
      return this.inputValue < 0 && this.touched;
    }
    if (!this.isNumericField) {
      if (this.regex.test(this.text) == false && this.touched) {
        return true;
      }
    }

    if (this.isEmailField() && this.emailIsAlreadyInUse()) {
      return true;
    }
    return this.text.length == 0 && this.touched;
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value === '' && this.isNumericField) {
      target.value = '0';
    }
  }

  onInputChange(event: string) {
    this.touched = true;
    if (this.isNumericField) {
      this.inputValue = +event;
    }
    this.changes.emit({
      value: event,
      valid: this.regex.test(event) && !this.inputIsInvalid(),
    });
  }
}
