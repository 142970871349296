import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardGuard } from './guards/dashboard.guard';
import { DashboardComponent } from './pages/dashboard/component/dashboard.component';
import { DataPrivacyPageComponent } from './pages/data-privacy-page/data-privacy-page.component';
import { DetailComponent } from './pages/detail/detail.component';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';

import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AdminDashboardGuard } from './guards/admin-dashboard.guard';
import { ApiDocumentationGuard } from './guards/api-documentation.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { ApiDocumentationComponent } from './pages/api-documentation/api-documentation.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/login/login.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
  },
  {
    path: LoginComponent.route,
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: DataPrivacyPageComponent.route,
    component: DataPrivacyPageComponent,
    pathMatch: 'full',
  },
  {
    path: ImprintPageComponent.route,
    component: ImprintPageComponent,
    pathMatch: 'full',
  },
  {
    path: WelcomeComponent.route,
    component: WelcomeComponent,
    pathMatch: 'full',
  },
  {
    path: DashboardComponent.route,
    loadChildren: () =>
      import('./pages/dashboard/dashboard-routing.module').then(
        (m) => m.DashboardRoutingModule
      ),
    data: { demo: false },
    canActivate: [MsalGuard, DashboardGuard],
  },
  {
    path: AdminComponent.route,
    loadChildren: () =>
      import('./pages/admin/admin-routing.module').then(
        (m) => m.AdminRoutingModule
      ),
    canActivate: [MsalGuard, AdminDashboardGuard],
  },
  {
    // Dedicated route for redirects
    path: 'auth',
    component: MsalRedirectComponent,
  },
  { path: 'detail/:id', component: DetailComponent },
  {
    path: ApiDocumentationComponent.route,
    canLoad: [ApiDocumentationGuard],
    canActivate: [ApiDocumentationGuard],
    pathMatch: 'full',
    component: ApiDocumentationComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
