import { TransportType } from '../enums/transport-type.enum';

export const EcoTransportTypes: TransportType[] = [
  TransportType.Pedestrian,
  TransportType.Bike,
  TransportType.EBike,
  TransportType.EScooterSmall,
  TransportType.CarBigElectric,
  TransportType.CarMediumElectric,
  TransportType.CarSmallElectric,
];

export const TripDistanceField = 'distance';
export const EmissionsField = 'emissions';

export const TotalColors = ['#8AB8F5', '#5F5A9B'];

export const Colors = ['#935BEA', '#4C90E6', '#FC9A55', '#068788', '#73C354'];

export const AllColors = [
  '#b13706',
  '#ad5e34',
  '#FC9A55',
  '#FFDA97',
  '#92121b',
  '#d72430',
  '#d75761',
  '#970ab1',
  '#c054d5',
  '#c587d4',
  '#0c0000',
  '#554f9a',
  '#828AC4',
  '#B4BCDE',
  '#025a02',
  '#226c22',
  '#32A032',
  '#73C354',
  '#BBDF98',
  '#003972',
  '#27416c',
  '#0061ee',
  '#4C90E6',
  '#8AB8F5',
];
export const CountryCodeRegex = '^[a-z]{2}$';
export const EmissionFactorRegex = '^[0-9]+(\.[0-9]{1,5})?$';
export const NameRegex = '^.{1,128}$';
export const EmailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
export const ZipRegex = '^(?=[A-Z-]*[0-9])[A-Z0-9-]{4,8}$';
