import { ReportGrouped } from '../models/report';
import { Reason } from '../models/enums/reason.enum';

export class ReportUtils {
  static sumByReason(
    reports: ReportGrouped[],
    reason: Reason,
    returnField: string,
    average: boolean = true
  ): number {
    const filteredReports = reports
      .filter((report) => report)
      .filter((report) => (reason ? report.reason === reason : true));
    const sum = filteredReports
      .map((report) => report[returnField])
      .reduce((acc, value) => acc + value, 0);
    return reports && filteredReports.length !== 0
      ? average
        ? sum / filteredReports.length
        : sum
      : 0;
  }
}
