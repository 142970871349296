import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { KeyValueField } from '../../models/key-value-field-data';
import { SurveyResponse } from '../../models/survey/response/survey-response';
import { SurveyResponseResult } from '../../models/survey/response/survey-response-result';
import { Survey } from '../../models/survey/survey';
import { SurveyInput } from '../../models/survey/survey-inputs';
import { SurveyPage } from '../../models/survey/survey-page';
import { TenantSurvey } from '../../models/survey/tenant-survey';
import { ObjectHelper } from '../../utils/object-helper';
import { ApiService } from '../api.service';
@Injectable({
  providedIn: 'root',
})
export class SurveyHttpService {
  private apiService: ApiService = inject(ApiService);

  getTenantSurveys(tenantId: string): Observable<TenantSurvey[]> {
    return this.apiService
      .get({
        path: `${APIConst.TENANT_SURVEYS}/${tenantId}`,
        endp: environment.apiPath,
      })
      .pipe(
        map((value) => value.body),
        map(({ tenantSurveys }: any) => {
          return tenantSurveys as TenantSurvey[];
        })
      );
  }

  getSurveysWithResponses(
    tenantId: string
  ): Observable<Map<Survey, SurveyResponse[]>> {
    const final: Map<Survey, SurveyResponse[]> = new Map();
    return this.apiService
      .get({
        path: `${APIConst.SURVEYS}`,
        endp: environment.apiPath,
      })
      .pipe(
        map((value) => value.body),
        map(({ surveyWithResponses }) => {
          surveyWithResponses.map((surveyWithResponse: any) => {
            final.set(
              this.dataToSurvey(surveyWithResponse.survey),
              this.dataToSurveyResponse(
                surveyWithResponse.surveyResponse.createSurveyResponses
              )
            );
          });
          return final;
        })
      );
  }

  public deleteSurvey(surveyId: string): Observable<boolean> {
    return this.apiService
      .delete({
        path: `${APIConst.SURVEY}/${surveyId}`,
        endp: environment.apiPath,
      })
      .pipe(map((value) => value.body));
  }

  private dataToSurvey(survey: any): Survey {
    const pages = survey.pages;
    pages.forEach((page: SurveyPage) => {
      page.section = new Map<string, string>(Object.entries(page.section));
      const inputs = page.inputs;

      inputs.forEach((input: SurveyInput) => {
        input.label = new Map<string, string>(Object.entries(input.label));
        const options = new Map<string, Map<string, string>>();
        const keys = Object.keys(input.options);
        for (let key of keys) {
          const deValue = input.options[key][KeyValueField.DE_KEY];
          const enValue = input.options[key][KeyValueField.EN_KEY];
          const valuesMap = new Map<string, string>();
          valuesMap.set(KeyValueField.DE_KEY, deValue);
          valuesMap.set(KeyValueField.EN_KEY, enValue);
          options.set(key, valuesMap);
        }
      });
    });
    return {
      appliedCompanies: survey.appliedCompanies ?? [],
      endDate: survey.endDate ?? new Date(),
      startDate: survey.startDate ?? new Date(),
      id: survey.id,
      isActive: survey.isActive ?? false,
      pages: pages ?? [],
      referenceId: survey.referenceId,
      version: survey.version,
      description: new Map<string, string>(Object.entries(survey.description)),
      title: new Map<string, string>(Object.entries(survey.title)),
      createdAt: survey.createdAt,
    } as Survey;
  }

  private dataToSurveyResponse(data: any[]): SurveyResponse[] {
    return data.map((survey) => {
      return {
        inputs: survey.inputs,
        created_at: survey.created_at,
        tenantId: survey.tenantId,
        app: {
          platform: survey.app.platform,
          version: survey.app.version,
          language: survey.app.language,
        },
        companyId: survey.companyId,
        officeId: survey.officeId,
        surveyId: survey.surveyId,
        id: survey.id,
      } as SurveyResponse;
    });
  }

  postSurvey(survey: Survey, tenantId: string) {
    const startDate: Date = survey.startDate;
    startDate.setHours(0, 0, 0, 0);

    const endDate = survey.endDate;
    endDate.setHours(23, 59, 59, 999);
    const surveyJson = {
      tenantId: tenantId,
      survey: {
        id: '',
        referenceId: survey.referenceId,
        title: ObjectHelper.mapToJson(survey.title),
        description: ObjectHelper.mapToJson(survey.description),
        tenantId: tenantId,
        createdAt: survey.createdAt ?? new Date(),
        active: survey.isActive,
        pages: survey.pages,
        version: survey.version,
        startDate: startDate,
        endDate: endDate,
        appliedCompanies: survey.appliedCompanies,
      },
    };
    return this.apiService.post({
      path: APIConst.CREATE_SURVEY,
      endp: environment.apiPath,
      sendIDToken: true,
      body: surveyJson,
    });
  }

  getSurveyById(surveyId: string): Observable<Survey> {
    return this.apiService
      .get({
        path: `${APIConst.SURVEY}/${surveyId}`,
        endp: environment.apiPath,
      })

      .pipe(
        map((value) => value.body),
        map((survey: Survey) => {
          const pages = survey.pages;
          pages.forEach((page: SurveyPage) => {
            page.section = new Map<string, string>(
              Object.entries(page.section)
            );
            const inputs = page.inputs;

            inputs.forEach((input: SurveyInput) => {
              input.label = new Map<string, string>(
                Object.entries(input.label)
              );
              const options = new Map<string, Map<string, string>>();
              const keys = Object.keys(input.options);
              for (let key of keys) {
                const deValue = input.options[key][KeyValueField.DE_KEY];
                const enValue = input.options[key][KeyValueField.EN_KEY];
                const valuesMap = new Map<string, string>();
                valuesMap.set(KeyValueField.DE_KEY, deValue);
                valuesMap.set(KeyValueField.EN_KEY, enValue);
                options.set(key, valuesMap);
              }
            });
          });
          return {
            appliedCompanies: survey.appliedCompanies ?? [],
            endDate: survey.endDate ?? new Date(),
            startDate: survey.startDate ?? new Date(),
            id: survey.id,
            isActive: survey.isActive ?? false,
            pages: pages ?? [],
            referenceId: survey.referenceId,
            version: survey.version,
            description: new Map<string, string>(
              Object.entries(survey.description)
            ),
            title: new Map<string, string>(Object.entries(survey.title)),
            tenantId: survey.tenantId,
            createdAt: survey.createdAt,
          } as Survey;
        })
      );
  }

  updateSurvey(survey: Survey, tenantId: string) {
    const startDate: Date = survey.startDate;
    startDate.setHours(0, 0, 0, 0);

    const endDate = survey.endDate;
    endDate.setHours(23, 59, 59, 999);
    const surveyJson = {
      id: survey.id,
      referenceId: survey.referenceId,
      tenantId: tenantId,
      title: ObjectHelper.mapToJson(survey.title),
      description: ObjectHelper.mapToJson(survey.description),
      createdAt: survey.createdAt ?? new Date(),
      isActive: survey.isActive,
      pages: survey.pages,
      version: survey.version,
      startDate: startDate,
      endDate: endDate,
      appliedCompanies: survey.appliedCompanies,
    } as Survey;
    console.log('surveyjson: ', surveyJson);
    console.log('stringify: ', JSON.stringify(surveyJson));

    return this.apiService.put({
      path: `${APIConst.SURVEY}/${survey.id}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: surveyJson,
    });
  }

  getSurveyResponses(surveyId: string) {
    return this.apiService
      .get({
        path: `${APIConst.SURVEY_RESPONSE + '/' + surveyId}`,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ createSurveyResponses }) => {
          return createSurveyResponses.map((survey) => {
            return {
              inputs: survey.inputs,
              created_at: survey.created_at,
              tenantId: survey.tenantId,
              app: {
                platform: survey.app.platform,
                version: survey.app.version,
                language: survey.app.language,
              },
              companyId: survey.companyId,
              officeId: survey.officeId,
              surveyId: survey.id,
              id: survey.id,
            } as SurveyResponse;
          });
        })
      );
  }

  getSurveyResponsesResults(surveyId: string) {
    return this.apiService
      .get({
        path: `${APIConst.SURVEY_RESPONSE_RESULT + '/' + surveyId}`,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map((results) => {
          return {
            ratings: results.ratings,
            selections: results.selections,
            texts: results.texts,
          } as SurveyResponseResult;
        })
      );
  }
}
