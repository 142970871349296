import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() data: any;

  public text: string = '';

  constructor() {}

  ngOnInit(): void {
    const element = this.data['element'];
    if (element) {
      this.text = element['value'];
    }
  }

  public wasTouched() {}
}
