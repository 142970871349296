import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-why-section',
  templateUrl: './why-section.component.html',
  styleUrls: ['./why-section.component.scss'],
})
export class WhySectionComponent implements OnInit {
  public mobileTimeline: string = '';
  public desktopTimeline: string = '';
  public innerWidth: any;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const svgPath = '../../assets/svg';
    const currentLang: string = this.translateService.currentLang.toLowerCase();
    this.mobileTimeline = svgPath + '/' + currentLang + '/Mobile_Timeline.svg';
    this.desktopTimeline =
      svgPath + '/' + currentLang + '/Desktop_Timeline.svg';
    this.innerWidth = document.documentElement.clientWidth;
  }

  public isMobile(): boolean {
    return this.innerWidth < 1280;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = document.documentElement.clientWidth;
  }
}
