<form [formGroup]="filter">
  <div class="p-grid p-fluid p-ai-center period-filter-padding-bottom">
    <div class="p-col-12 bold no-padding-bottom">
      {{'filters.selectTimePeriod' | translate}}
    </div>
    <div class="p-col-fixed tags-container">
      <div class="tag-bar">
        <button id="week" [ngClass]="{'selected': selectedTag === 'week'}" (click)="onChooseQuickRange('week')">{{'periodFilter.week' | translate}}</button>
        <button id="month" [ngClass]="{'selected': selectedTag === 'month'}" (click)="onChooseQuickRange('month')">{{'periodFilter.month' | translate}}</button>
        <button id="quarter" [ngClass]="{'selected': selectedTag === 'quarter'}" (click)="onChooseQuickRange('quarter')">{{'periodFilter.quarter' | translate}}</button>
        <button id="year" [ngClass]="{'selected': selectedTag === 'year'}" (click)="onChooseQuickRange('year')">{{'periodFilter.year' | translate}}</button>
      </div>
    </div>
    <div><hr class="separator"></div>
    <div class="p-col-12 p-xs-6 p-sm-6 p-md-3 p-lg-3 p-xl-2">
      <p-calendar
        [ngClass]="{'date-error': filter.errors?.dateNotValid}"
        appendTo="body"
        dateFormat="mm-dd-yy"
        formControlName="from"
        [placeholder]="'periodFilter.labels.start' | translate"
        [showIcon]="true"
        firstDayOfWeek="1"
      ></p-calendar>
    </div>
    <div class="p-col-12 p-xs-6 p-sm-6 p-md-3 p-lg-3 p-xl-2">
      <p-calendar
        [ngClass]="{'date-error': filter.errors?.dateNotValid}"
        appendTo="body"
        dateFormat="mm-dd-yy"
        formControlName="to"
        [placeholder]="'periodFilter.labels.end' | translate"
        [showIcon]="true"
        firstDayOfWeek="1"
      ></p-calendar>
    </div>
  </div>
</form>
