<div class="user-roles-wrapper page-wrapper">
    <div class="intro">
        <img [src]="'assets/svg/info_magenta.svg'">
        <p>{{'admin.usersRoles.description'|translate}}
        </p>
    </div>

    <div class="users-table">
        <div class="header">
            <h3>{{'usersRoles.table.title.headline'|translate}}<span>&nbsp;({{this.userCount}})</span></h3>
            <app-text-button [fontSizeFactor]="0.9" [icon]="'assets/svg/add.svg'"
                [text]="'usersRoles.table.user.add'|translate" (click)="addUser()"></app-text-button>
        </div>
        <app-dashboard-table [elementsPerView]="8" [tableData]="userTableData()" (editPressed)="editUser($event)"
            (deletePressed)="deleteUser($event)"></app-dashboard-table>
    </div>
</div>