import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';
import { NameRegex } from '../models/const/common';

const createAddCompanyDialog = (companyNameKey: string) => {
  return {
    confirmationTextKey: 'data.table.dialog.company.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.company.name.placeholder',
        prefix: 'data.table.dialog.company.name.prefix',
        suffix: 'data.table.dialog.company.name.suffix',
        key: companyNameKey,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.company.create.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
  } as TableDialogInput;
};

const createDeleteCompanyDialog = () => {
  return {
    confirmationTextKey: 'data.table.dialog.company.delete.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'data.table.dialog.company.delete.text',
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.company.delete.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

const createEditCompanyDialog = (companyNameKey: string, event) => {
  return {
    confirmationTextKey: 'data.table.dialog.save',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.company.name.placeholder',
        prefix: 'data.table.dialog.company.name.prefix',
        suffix: 'data.table.dialog.company.name.suffix',
        key: companyNameKey,
        value: event['data'][0].text,
        validation: NameRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.company.edit.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isUpdateDialog: true,
  } as TableDialogInput;
};

export {
  createAddCompanyDialog,
  createDeleteCompanyDialog,
  createEditCompanyDialog,
};
