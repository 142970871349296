export const environment = {
  production: true,
  apiPath: '/api',
  tokenPath: '/api',
  apimKey: 'f994bd93b2824cdabb2c168fd4f3fd16',
  authRedirectUri: 'https://ecoshift.telekom-mms.com',
  storageAccountName: '',
  accountKey: '',
  containerName: '',
  documentation: false,
};
