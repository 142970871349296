import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drop-down-button',
  templateUrl: './drop-down-button.component.html',
  styleUrls: ['./drop-down-button.component.scss'],
})
export class DropDownButtonComponent implements OnInit {
  @Input() options: string[] = [];
  @Output() selected: EventEmitter<string> = new EventEmitter();
  selectedValue: string;
  showDropdown = false;

  ngOnInit(): void {
    if (this.options.length > 0) {
      this.selectedValue = this.options[0];
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: string) {
    this.selectedValue = option;
    this.showDropdown = false;
    this.selected.emit(this.selectedValue);
  }
}
