import { DialogStructure } from '../models/enums/dialog/dialog-structure';
import { DialogLayoutType } from '../models/enums/dialog/dialog-type';
import { TableDialogInput } from '../models/enums/dialog/table-dialog-element';
import {
  CountryCodeRegex,
  EmissionFactorRegex,
  NameRegex,
} from '../models/const/common';

const createEditEmissionDialog = (
  codeKey: string,
  dieselKey: string,
  petrolKey: string,
  electricKey: string,
  event
) => {
  return {
    confirmationTextKey: 'data.table.dialog.save',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.name.placeholder',
        prefix: 'data.table.dialog.emission.name.prefix',
        suffix: 'data.table.dialog.emission.name.suffix',
        key: codeKey,
        value: event['data'][0].text,
        validation: NameRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.diesel.placeholder',
        prefix: 'data.table.dialog.emission.diesel.prefix',
        suffix: 'data.table.dialog.emission.diesel.suffix',
        key: dieselKey,
        value: event['data'][1].text,
        validation: EmissionFactorRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.petrol.placeholder',
        prefix: 'data.table.dialog.emission.petrol.prefix',
        suffix: 'data.table.dialog.emission.petrol.suffix',
        key: petrolKey,
        value: event['data'][2].text,
        validation: EmissionFactorRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.electric.placeholder',
        prefix: 'data.table.dialog.emission.electric.prefix',
        suffix: 'data.table.dialog.emission.electric.suffix',
        key: electricKey,
        value: event['data'][3].text,
        validation: EmissionFactorRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.emission.edit.title',
    dialogType: DialogLayoutType.COLUMN,
    isUpdateDialog: true,
  } as TableDialogInput;
};

const createDeleteEmissionDialog = () => {
  return {
    confirmationTextKey: 'data.table.dialog.emission.delete.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'data.table.dialog.emission.delete.text',
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.emission.delete.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

const createRestoreDefaultEmissionDialog = () => {
  return {
    confirmationTextKey: 'data.table.dialog.emission.restore.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT,
        value: 'data.table.dialog.emission.restore.text',
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.emission.restore.title',
    dialogType: DialogLayoutType.SINGLE_ELEMENT,
    isDeleteDialog: true,
  } as TableDialogInput;
};

const createAddEmissionDialog = (
  emissionNameKey: string,
  emissionDieselKey: string,
  emissionPetrolKey: string,
  emissionElectricKey: string
) => {
  return {
    confirmationTextKey: 'data.table.dialog.emission.create.confirmation',
    dialogElements: [
      {
        dialogStructure: DialogStructure.TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.name.placeholder',
        prefix: 'data.table.dialog.emission.name.prefix',
        suffix: 'data.table.dialog.emission.name.suffix',
        key: emissionNameKey,
        validation: CountryCodeRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.diesel.placeholder',
        prefix: 'data.table.dialog.emission.diesel.prefix',
        suffix: 'data.table.dialog.emission.diesel.suffix',
        key: emissionDieselKey,
        numeric: true,
        validation: EmissionFactorRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.petrol.placeholder',
        prefix: 'data.table.dialog.emission.petrol.prefix',
        suffix: 'data.table.dialog.emission.petrol.suffix',
        key: emissionPetrolKey,
        numeric: true,
        validation: EmissionFactorRegex,
      },
      {
        dialogStructure: DialogStructure.NUMERIC_TEXT_INPUT,
        placeHolder: 'data.table.dialog.emission.electric.placeholder',
        prefix: 'data.table.dialog.emission.electric.prefix',
        suffix: 'data.table.dialog.emission.electric.suffix',
        key: emissionElectricKey,
        numeric: true,
        validation: EmissionFactorRegex,
      },
    ],
    dialogTitleTextKey: 'data.table.dialog.emission.create.title',
    dialogType: DialogLayoutType.COLUMN,
  } as TableDialogInput;
};

export {
  createEditEmissionDialog,
  createDeleteEmissionDialog,
  createRestoreDefaultEmissionDialog,
  createAddEmissionDialog,
};
