import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataPrivacyPageComponent } from './pages/data-privacy-page/data-privacy-page.component';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { ContentLoadingService } from './services/content-loading.service';
import { LocalStorageService } from './services/localstorage.service';
import { TokenUtil } from './utils/token-util';

export enum Language {
  GERMANY = 'de',
  ENGLISH = 'en',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CO2-footprint';
  language = '';

  private contentLoadingService: ContentLoadingService = inject(
    ContentLoadingService
  );
  private router: Router = inject(Router);

  private readonly _destroying$ = new Subject<void>();

  // we just define contentIsLoadint to the contentLoadingService and subscribe in the template with the async pipe
  public contentIsLoading = this.contentLoadingService.isLoading;

  public currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public routerEvent: Observable<Event> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd)
  );

  constructor(
    private translateService: TranslateService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      window.console.log = () => {};
    }
    this.routerEvent.subscribe((event) => {
      this.currentUrl.next(event['urlAfterRedirects']);
    });

    this.translateService.addLangs([Language.ENGLISH, Language.GERMANY]);
    this.translateService.setDefaultLang(Language.ENGLISH);
    this.language = localStorage.getItem('lan');
    if (!this.language) {
      const browserLang = this.translateService.getBrowserLang();
      this.translateService.use(
        browserLang.match(/en|de/) ? browserLang : Language.ENGLISH
      );
    } else {
      this.translateService.use(this.language);
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (
          !activeAccount &&
          this.msalService.instance.getAllAccounts().length > 0
        ) {
          let accounts = this.msalService.instance.getAllAccounts();
          this.msalService.instance.setActiveAccount(accounts[0]);

          this.msalService.instance
            .acquireTokenSilent({ scopes: [] })
            .then((response) => {
              const accessToken = response.accessToken;
              if (accessToken) {
                localStorage.setItem('id-token', accessToken);
                const role = TokenUtil.getClaimFromToken(
                  accessToken,
                  'roles'
                )[0];
                const prefered = LocalStorageService.getPreferedRoute();

                let route: string = `/${WelcomeComponent.route}`;
                if (prefered && prefered !== null && prefered !== '') {
                  route = prefered;
                }

                this.router.navigateByUrl(route);
              }
            });
        }
      });
  }

  ngAfterContentChecked() {
    // we need to check if content has changed so it can be updated properly
    this.cd.detectChanges();
  }

  public get landingPage(): string {
    return '/';
  }

  public get loginPage(): string {
    return '/login';
  }

  public get dataPrivacyPage(): string {
    return `/${DataPrivacyPageComponent.route}`;
  }
  public get imprintPage(): string {
    return `/${ImprintPageComponent.route}`;
  }
}
