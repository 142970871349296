<div class="video-section">
    <div class="landing-page-wrapper">
        <h3>{{'landing.videoSectionHeading'|translate}}</h3>
        <div class="text-section">
            <p *ngFor="let text of sectionTexts">{{text}}</p>
        </div>

        <div class="video">

            <div class="video-popup" (click)="openVideoPopup()">
                <img [src]="thumbnail">
            </div>
            <div class="video-popup-content-wrapper" (click)="closeVideoPopup()" *ngIf="videoPopupOpen">
                <button class="close-button"><img [src]="closeButton"></button>
                <div class="video-popup-content">
                    <iframe [src]="videoUrl" allow="fullscreen" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>

</div>