<div class="intro-section">
    <div class="hero-wrapper">
        <img class="hero" [src]="heroImage">
    </div>
    <div class="text-section">
        <img [src]="co2Icon">
        <h1 class="heading">{{'landing.ecoshift'|translate}}</h1>
        <p>
            <ng-container *ngIf="introText">
                <ng-container *ngFor="let word of introText.split(' ')">
                    <span [class.highlight]="word === highlightText">{{word}} </span>
                </ng-container>
            </ng-container>
        </p>
    </div>
    <img class="upper-leaf" *ngIf="!isMobile()" [src]="leafImage">
    <img class="overlaping-element" [src]="overlapingElement">
</div>