import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoPopupService {
  private _videoPopupIsOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public setVideoPopupOpen(state: boolean): void {
    this._videoPopupIsOpen.next(state);
  }

  public get videoPopupIsOpen() {
    return this._videoPopupIsOpen.asObservable();
  }
}
