import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() smallButtonStyle: boolean = false;
  // @Input() backgroundColor: string = '#e20074';
  // @Input() fontColor: string = '#ffffff';
  // @Input() backgroundColorHover: string = '#ffffff';
  // @Input() fontColorHover: string = '#e20074';
  // @Input() disableFontHover: boolean = false;
  // @Input() disableBackgroundHover: boolean = false;
  @Input() fontSizeFactor: number = 1;
  @Input() cancelButtonStyle: boolean = false;
  @Input() tableButtonStyle: boolean = false;
  @Input() activeIcon: string;
  @Input() disabled: boolean = false;
  @Input() noPaddingStyle: boolean = false;

  tempBackgroundHoverColor: string = '';
  tempFontHoverColor: string = '';

  constructor() {}

  ngOnInit(): void {
    // if (this.disableBackgroundHover) {
    //   this.backgroundColorHover = this.backgroundColor;
    // }
    // if (this.disableFontHover) {
    //   this.fontColorHover = this.fontColor;
    // }
    // this.tempBackgroundHoverColor = this.backgroundColor;
    // this.tempFontHoverColor = this.fontColor;
  }
}
