import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Survey } from 'src/app/models/survey/survey';
import { CompanyService } from 'src/app/services/company.service';
import { SurveyService } from 'src/app/services/survey.service';
type SurveyWithRespose = Survey & {
  companyNames: string[];
  numberOfSubmits: number;
};
interface PageElement {

  inputs: {
    type: 'rating' | 'select' | 'text';
    identifier?: string;
    checkboxValue?: boolean;
    radioValue?: boolean;
  }[];

}

const SCROLL_THRESHOLD = 5;

@Component({
  selector: 'app-survey-configurator',
  templateUrl: './survey-configurator.component.html',
  styleUrls: ['./survey-configurator.component.scss'],
  providers: [FormsModule],
})
export class SurveyConfiguratorComponent implements OnInit {
  @Input() survey: SurveyWithRespose | null = null;
  @Output() isActiveChanged: EventEmitter<Survey> = new EventEmitter<Survey>();
  animationDirection: string[] = [''];

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const height = scrollHeight - clientHeight;
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    this.isPanelFixed = height - Math.floor(scrollTop) < SCROLL_THRESHOLD;
  }
  private translateService: TranslateService = inject(TranslateService);
  customEndDate: string = '';
  customStartDate: string = '';
  currentLang = this.translateService.currentLang;
  formGroup!: FormGroup;
  inValidMessage: boolean = false;
  isActive: boolean = false;
  inputCounters: { [key: string]: number } = { rating: 0, select: 0, text: 0 };
  isPanelFixed: boolean = false;
  isFormFilled: boolean = false;
  itemToDel: number;
  isToggleConfirm: boolean;
  myOptions: { id: string; name: string }[];
  missingFirstQuestion: boolean = false;
  openModalActvieStatus: boolean = false;
  pageTitleDE: string;
  pageTitleEN: string;
  pages: Survey['pages'] = [];
  previewSurvey: any;
  selectedOptions: string[] = [];
  showDeleteModal: boolean = false;
  showPreviewSurveyModal: boolean = false;
  bufferPages: PageElement[] = [];
  successMessage: string | null = null;
  successSaved: boolean = false;
  successSubm: boolean = false;
  surveyCreatedId: string = '';
  surveyDescriptionDE: string = '';
  surveyDescriptionEN: string = '';
  surveyNameDE: string = '';
  surveyNameEN: string = '';
  valid: boolean = true;
  minDateRule: Date = new Date();
  maxDateRule: Date = new Date();
  endDateMaxRule: Date = new Date();

  constructor(
    private companyService: CompanyService,
    private surveyService: SurveyService
  ) { }

  ngOnInit() {
    this.maxDateRule.setFullYear(this.maxDateRule.getFullYear() + 1);
    this.endDateMaxRule.setFullYear(this.maxDateRule.getFullYear() + 2);
    this.previewSurvey = {
      id: '',
      referenceId: '',
      title: new Map<string, string>(),
      description: new Map<string, string>(),
      pages: [],
      createdAt: new Date(),
      appliedCompanies: [],
      isActive: false,
      startDate: new Date(),
      endDate: new Date(),
      version: '0',
      companyNames: [''],
    };
    this.companyService.dashboardCompanies.subscribe((companies) => {
      this.myOptions = companies.map((company) => ({
        id: company.id,
        name: company.name,
      }));
    });
    this.formGroup = new FormGroup({
      selectedOptions: new FormControl<{ id: string; name: string }[]>([]),
    });

    if (this.survey != null) {
      this.initializeEditMode();
    } else {
      this.addStep(0); //default Step-1 exist
    }

    if (this.currentLang === 'de') {
      let pickerStart = document.querySelectorAll('scale-date-picker')[2] as HTMLScaleDatePickerElement;
      let pickerEnd = document.querySelectorAll('scale-date-picker')[3] as HTMLScaleDatePickerElement;
      if(this.survey){
        pickerStart = document.querySelectorAll('scale-date-picker')[0] as HTMLScaleDatePickerElement;
        pickerEnd = document.querySelectorAll('scale-date-picker')[1] as HTMLScaleDatePickerElement;
      }
      pickerEnd.localization = pickerStart.localization = {
        buttonLabel: 'Datum wählen',
        placeholder: 'TT.MM.JJJJ',
        selectedDateMessage: 'Gewähltes Datum',
        prevMonthLabel: 'Vorheriger Monat',
        nextMonthLabel: 'Nächster Monat',
        monthSelectLabel: 'Monat',
        yearSelectLabel: 'Jahr',
        closeLabel: 'Fenster schließen',
        keyboardInstruction:
          'Sie können mit den Pfeiltasten vor und zurück navigieren',
        calendarHeading: 'Datum wählen',
        dayNames: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        monthNames: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
        today: 'heute',
        locale: 'de-DE',
      };

      const DATE_FORMAT = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
      pickerStart.dateAdapter = pickerEnd.dateAdapter = {
        parse(value = '', createDate) {
          const matches = value.match(DATE_FORMAT);
          if (matches) {
            return createDate(matches[3], matches[2], matches[1]);
          }
        },
        format(date) {
          return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        },
      };
    }
  }

  dateToString(date: Date): string {
    return formatDate(new Date(date), 'yyyy-MM-dd', 'en-US');
  }

  openPreviewSurveyModal() {
    this.previewSurvey = this.constructSurveyObject();
    this.previewSurvey.companyNames =
      this.formGroup.controls.selectedOptions.value
        .map((option) => option.name)
        .join(', ');
    this.showPreviewSurveyModal = !this.showPreviewSurveyModal;
    document.body.style.overflow = 'hidden';
  }

  handlePreviewSurveyModal(e) {
    this.showPreviewSurveyModal = e;
  }

  scaleChangeEnd(e): void {
    this.customEndDate = e.detail.value;
  }

  scaleChangeStart(e): void {
    this.customStartDate = e.detail.value;

    const selectedStartDateMax: Date = new Date(this.customStartDate);
    selectedStartDateMax.setFullYear(selectedStartDateMax.getFullYear() + 2);
    this.endDateMaxRule = selectedStartDateMax;

    const currentEndDate = new Date(this.customEndDate);
    if (currentEndDate > this.endDateMaxRule) {
      this.customEndDate = this.dateToString(this.endDateMaxRule);
    }
  }

  removeFromGroup(control: string, formGroup: AbstractControl): void {
    formGroup.setValue(formGroup.value.filter((con) => con.name !== control));
  }

  onIsActiveChanged(): void {
    this.isActive = !this.isActive;
    if (this.isActive)
      this.openModalActvieStatus = true;
  }
  closeModal() {
    this.isActive = !this.isActive;
    this.openModalActvieStatus = false;

  }
  confirmToggle() {
    this.isToggleConfirm = true;
    this.openModalActvieStatus = false;
  }
  afterCloseModal() {
    if (this.isToggleConfirm) {
      this.isActive = true;
    }
    else this.isActive = false;
    this.openModalActvieStatus = false;
  }

  handleSurveyTitle($event: any, language: string, target: string) {
    if (language === 'de') {
      this[target + 'DE'] = $event.detail.value;
    } else if (language === 'en') {
      this[target + 'EN'] = $event.detail.value;
    }
  }

  stepNameAdd($event: any, language: string, i: number): void {
    if (language === 'de') {
      this.pageTitleDE = $event.detail.value;
    } else if (language === 'en') {
      this.pageTitleEN = $event.detail.value;
    }
    this.pages[i].section.set('de', this.pageTitleDE);
    this.pages[i].section.set('en', this.pageTitleEN);
  }

  addStep(index): void {
    this.pages.splice(index, 0, { section: new Map<string, string>(), inputs: [] });
    this.bufferPages.splice(index, 0, { inputs: [] });

    this.isPanelFixed = false;
  }

  removePageElement(): void {
    this.pages.splice(this.itemToDel, 1);
    this.bufferPages.splice(this.itemToDel, 1);
    this.closeDeleteConfirmationModal();
  }

  addInput(
    pageNumber: number,
    inputType: 'rating' | 'select' | 'text' = 'rating', style: string = null
  ): void {
    // this.inputCounters[inputType]++;
    const newInput: {
      type: 'rating' | 'select' | 'text';
      identifier?: string;
      checkboxValue?: boolean;
      radioValue?: boolean;
      options:{
        style:string
      }
    } = { type: inputType, options:{ style:style} };
    // newInput.identifier = `${inputType}-${this.inputCounters[inputType]}`;
    this.bufferPages[pageNumber].inputs.push(newInput);
    this.isPanelFixed = false;
    this.valid = true;
    if (!this.checkFormFilling(this.constructSurveyObject())) {
      this.valid = false;
    }
  }

  moveFormForward(pageNumber: number, inputIndex: number): void {
    // Проверяем, что есть следующая форма для перемещения
    if (inputIndex < this.pages[pageNumber].inputs.length - 1) {
      // Обмениваем текущую форму с следующей
      [this.pages[pageNumber].inputs[inputIndex], this.pages[pageNumber].inputs[inputIndex + 1]] =
        [this.pages[pageNumber].inputs[inputIndex + 1], this.pages[pageNumber].inputs[inputIndex]];
  
      [this.bufferPages[pageNumber].inputs[inputIndex], this.bufferPages[pageNumber].inputs[inputIndex + 1]] =
        [this.bufferPages[pageNumber].inputs[inputIndex + 1], this.bufferPages[pageNumber].inputs[inputIndex]];
    }
  }
  
  moveFormBackward(pageNumber: number, inputIndex: number): void {
    // Проверяем, что есть предыдущая форма для перемещения
    if (inputIndex > 0) {
      // Обмениваем текущую форму с предыдущей
      [this.pages[pageNumber].inputs[inputIndex], this.pages[pageNumber].inputs[inputIndex - 1]] =
        [this.pages[pageNumber].inputs[inputIndex - 1], this.pages[pageNumber].inputs[inputIndex]];
  
      [this.bufferPages[pageNumber].inputs[inputIndex], this.bufferPages[pageNumber].inputs[inputIndex - 1]] =
        [this.bufferPages[pageNumber].inputs[inputIndex - 1], this.bufferPages[pageNumber].inputs[inputIndex]];
    }
  }

  removeInput(pageNumber: number, inputIndex: number): void {
    this.pages[pageNumber].inputs.splice(inputIndex, 1);
    this.bufferPages[pageNumber].inputs.splice(inputIndex, 1);
    this.pages = [...this.pages];
    //change ids of the remaining inputs
    for (let i = inputIndex; i < this.pages[pageNumber].inputs.length; i++) {
      const currentIdentifier = this.pages[pageNumber].inputs[i].identifier;
      const numericPart = +currentIdentifier.split('-')[1];
      this.pages[pageNumber].inputs[i].identifier = `${this.pages[pageNumber].inputs[i].type}-${numericPart - 1}`;
    }
  }

  openModal(i: number): void {
    this.itemToDel = i;
    this.showDeleteModal = true;
  }

  closeDeleteConfirmationModal(): void {
    this.showDeleteModal = false;
  }

  handleInput(params, i): void {
    const existingInput = this.pages[i].inputs.find(
      (input) => input.identifier === params.identifier
    );

    if (existingInput) {
      // If the input with the same identifier already exists, update its properties
      existingInput.identifier = params.identifier;
      existingInput.label = params.label;
      existingInput.options = params.options;
      existingInput.type = params.type;
      existingInput.required = params.required;
    } else {
      // If the input with the given identifier doesn't exist, push the new input
      this.pages[i].inputs.push(params);
    }

  }

  handleRating(ratingParams, i): void {
    this.handleInput(ratingParams, i);
  }

  handleSelect(selectParams, i): void {
    this.handleInput(selectParams, i);
  }

  handleTextQuestion(textParams, i): void {
    this.handleInput(textParams, i);
  }

  initializeEditMode(): void {
    this.isActive = this.survey.isActive;
    this.customStartDate = formatDate(
      new Date(this.survey.startDate),
      'yyyy-MM-dd',
      'en-US'
    );
    this.customEndDate = formatDate(
      new Date(this.survey.endDate),
      'yyyy-MM-dd',
      'en-US'
    );
    this.surveyNameDE = this.survey.title.get('de');
    this.surveyNameEN = this.survey.title.get('en');
    this.surveyDescriptionDE = this.survey.description.get('de');
    this.surveyDescriptionEN = this.survey.description.get('en');
    const selectedOptionsFromSurvey = this.survey.appliedCompanies.map(
      (id, index) => ({ id, name: this.survey.companyNames[index] })
    );
    this.formGroup.setValue({ selectedOptions: selectedOptionsFromSurvey });
    this.pages = this.survey.pages;
    // Clear bufferPages before filling with new data
    this.bufferPages = [];

    // Go through all pages and add the appropriate input fields
    for (let i = 0; i < this.pages.length; i++) {
      const page = this.pages[i];
      const stepElement: PageElement = { inputs: [] };
      for (let j = 0; j < page.inputs.length; j++) {
        const input = page.inputs[j];
        // Add the current input to the inputs array of the current page
        stepElement.inputs.push(input);
      }
      // Add the current page to the bufferPages array
      this.bufferPages.push(stepElement);
    }
  }

  constructSurveyObject(): Survey {
    const selectedOptions = this.formGroup.controls.selectedOptions.value;
    const startTime = new Date(this.customStartDate);
    let endTime: Date;

    if (this.customEndDate === '') {
      endTime = new Date(startTime);
      endTime.setMonth(endTime.getMonth() + 3);
    } else {
      endTime = new Date(this.customEndDate);
    }
    const survey: Survey = {
      id: '',
      referenceId: '',
      tenantId: '',
      title: new Map<string, string>([
        ['de', this.surveyNameDE],
        ['en', this.surveyNameEN],
      ]),
      description: new Map<string, string>([
        ['de', this.surveyDescriptionDE],
        ['en', this.surveyDescriptionEN],
      ]),
      pages: this.pages,
      createdAt: new Date(),
      appliedCompanies: selectedOptions.map(
        (selectedOptions) => selectedOptions.id
      ),
      isActive: this.isActive,
      startDate: startTime,
      endDate: endTime,
      version: '0',
    };
    return survey;
  }

  areAllInputsEqual(bufferPages: any[], pages: any[]): boolean {
    // Check that both arrays exist
    if (!bufferPages || !pages || bufferPages.length !== pages.length) {
      return false;
    }

    // Go through each page and check the length of the inputs array
    for (let i = 0; i < bufferPages.length; i++) {
      if (!bufferPages[i] || !pages[i] || !bufferPages[i].inputs || !pages[i].inputs) {
        return false;
      }

      if (bufferPages[i].inputs.length !== pages[i].inputs.length) {
        return false;
      }
    }
    return true;
  }

  isMap(value: any): value is Map<any, any> {
    return value instanceof Map;
  }

  checkFormFilling(survey: any) {

    for (const page of survey.pages) {
      for (const input of page.inputs) {
        console.log("input", input)

        if (!input.label.get('en') || !input.label.get('de')) {
          return false;
        }
        //rating validation
        if (input.type === 'rating' && this.isMap(input.options.labels) && (
          !input.options.labels.get('min').get('en') ||
          !input.options.labels.get('min').get('de') ||
          !input.options.labels.get('max').get('en') ||
          !input.options.labels.get('max').get('de')
        )) {
          return false;
        }
        if (input.type === 'rating' && !this.isMap(input.options.labels) && (
          !input.options.labels.min.en ||
          !input.options.labels.min.de ||
          !input.options.labels.max.en ||
          !input.options.labels.max.de
        )) {
          return false;
        }

        //checkbox-group validation if map or object
        if (input.type === 'select' && input.options.style === 'checkbox-group' && this.isMap(input.options.values[0].value) && (!input.options.values[0].value.get('de') || !input.options.values[0].value.get('en'))) {
          return false;
        }
        if (input.type === 'select' && input.options.style === 'checkbox-group' && !this.isMap(input.options.values[0].value) && (!input.options.values[0].value.de || !input.options.values[0].value.en)) {
          return false;
        }

        //radiobutton-group validation if map or object
        if (input.type === 'select' && input.options.style === 'radiobutton-group' && this.isMap(input.options.values[0].value) && (!input.options.values[0].value.get('de') || !input.options.values[0].value.get('en') || !input.options.values[1].value.get('de') || !input.options.values[1].value.get('en'))) {
          return false;
        }
        //radiobutton-group validation if map or object
        if (input.type === 'select' && input.options.style === 'radiobutton-group' && !this.isMap(input.options.values[0].value) && (!input.options.values[0].value.de || !input.options.values[0].value.en || !input.options.values[1].value.de || !input.options.values[1].value.en)) {
          return false;
        }
      }
    }
    return true;
  }

  saveChanges(): void {
    console.log('🧾new Survey: ', this.constructSurveyObject());
    // this.checkFormFilling(this.constructSurveyObject());
    const isGeneralInfoValid =
      this.customStartDate &&
      this.surveyNameEN &&
      this.surveyNameDE &&
      this.surveyDescriptionEN &&
      this.surveyDescriptionDE &&
      this.formGroup.controls.selectedOptions.value &&
      this.pages[0].section.size > 1 && //one page required
      this.checkFormFilling(this.constructSurveyObject()) &&
      this.areAllInputsEqual(this.bufferPages, this.constructSurveyObject().pages);


    const isQuestionsValid = this.pages[0].inputs.length > 0;
    if (!isGeneralInfoValid) {
      this.inValidMessage = true;
      setTimeout(() => {
        this.inValidMessage = false;
      }, 7000);
    }
    if (!isQuestionsValid) {
      this.missingFirstQuestion = true;
      setTimeout(() => {
        this.missingFirstQuestion = false;
      }, 5000);
    }

    if (isGeneralInfoValid && isQuestionsValid) {
      this.inValidMessage = false;
      const survey = this.constructSurveyObject();
      if (this.survey) {
        survey.id = this.survey.id;
        this.createOrUpdateSurvey(survey, 'update');
      } else if (this.surveyCreatedId) {
        survey.id = this.surveyCreatedId;
        this.createOrUpdateSurvey(survey, 'update');
      } else {
        this.createOrUpdateSurvey(survey, 'create');
      }
      this.successSaved = true;
      setTimeout(() => {
        this.successSaved = false;
      }, 2000);
    } else {
      this.valid = false;
    }
  }

  moveStepForward(index: number): void {
    // Проверяем, что есть следующий шаг для перемещения
    if (index < this.pages.length - 1) {
      // Обмениваем текущий шаг с следующим

      console.log("this.animationDirection",this.animationDirection);
      [this.pages[index], this.pages[index + 1]] = [this.pages[index + 1], this.pages[index]];
      [this.bufferPages[index], this.bufferPages[index + 1]] = [this.bufferPages[index + 1], this.bufferPages[index]];
    }
  }
  
  moveStepBackward(index: number): void {
    // Проверяем, что есть предыдущий шаг для перемещения
    if (index > 0) {
      // Обмениваем текущий шаг с предыдущим

      [this.pages[index], this.pages[index - 1]] = [this.pages[index - 1], this.pages[index]];
      [this.bufferPages[index], this.bufferPages[index - 1]] = [this.bufferPages[index - 1], this.bufferPages[index]];
    }
  }

  private createOrUpdateSurvey(
    survey: Survey,
    operationType: 'create' | 'update'
  ): void {
    try {
      let updatedSurvey;
      if (
        this.survey == null ||
        this.survey.numberOfSubmits == 0 ||
        this.survey.numberOfSubmits == undefined
      ) {
        if (operationType === 'create') {
          updatedSurvey = this.surveyService
            .createSurvey(survey)
            .subscribe((createdSurvey) => {
              this.surveyCreatedId = createdSurvey.id;
              console.log('Survey created:', createdSurvey);
              survey.id = this.surveyCreatedId;

              this.isActiveChanged.emit(survey);
            });
        } else {
          updatedSurvey = this.surveyService
            .updateSurvey(survey)
            .subscribe((updatedSurvey) => {
              console.log('Survey updated:', updatedSurvey);
              this.isActiveChanged.emit(survey);
            });
        }
      } else {
        updatedSurvey = this.surveyService
          .createSurvey(survey)
          .subscribe((createdSurvey) => {
            this.surveyCreatedId = createdSurvey.id;
            console.log('Survey created:', createdSurvey);
            survey.id = this.surveyCreatedId;
            this.isActiveChanged.emit(survey);
          });
      }

      this.successSubm = true;
      console.log(`Survey ${operationType}d successfully:`, updatedSurvey);
    } catch (error) {
      console.error(`Error while ${operationType}ing survey:`, error);
    }
  }
}
