import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Emission } from '../models/emission';
import { EmissionHttpService } from './http/emission-http.service';
import { EmissionFetchType } from '../models/emission-fetch-type';
import { TenantService } from './tenant.service';
import { ContentLoadingService } from './content-loading.service';

interface EmissionData {
  emissionList: Emission[];
  gasolineDefaultFactor: number;
  electricDefaultFactor: number;
  dieselDefaultFactor: number;
}

export const ADMIN_TENENAT_ID = '3cfe4e1b-8e51-49d5-be27-3a40d74cce1d';

@Injectable({
  providedIn: 'root',
})
export class EmissionService {
  private _adminEmissionData = new BehaviorSubject<EmissionData>({
    emissionList: [],
    gasolineDefaultFactor: 0,
    dieselDefaultFactor: 0,
    electricDefaultFactor: 0,
  });

  private _emissionDataForLoggedInData = new BehaviorSubject<EmissionData>({
    emissionList: [],
    gasolineDefaultFactor: 0,
    dieselDefaultFactor: 0,
    electricDefaultFactor: 0,
  });

  constructor(
    private emissionHttpService: EmissionHttpService,
    private tenantService: TenantService,
    private contentLoadingService: ContentLoadingService
  ) {
    this.fetchEmissions();
  }

  fetchEmissionsForTenant(): void {
    if (ApiService.hasIDToken()) {
      this.contentLoadingService.setLoadingState(true);

      this.tenantService.loggedInTenant.subscribe((tenant) => {
        if (tenant) {
          this.emissionHttpService
            .getAllEmissionFactors(tenant.id)
            .subscribe((fetchedEmissionData: EmissionFetchType) => {
              // TODO-EMISSION fetch actuakl admiEmissionFactor

              const dieselFactor =
                fetchedEmissionData.dieselDefaultFactor ??
                this._adminEmissionData.value.dieselDefaultFactor;
              const electricFactor =
                fetchedEmissionData.electricDefaultFactor ??
                this._adminEmissionData.value.electricDefaultFactor;
              const gasolineFactor =
                fetchedEmissionData.gasolineDefaultFactor ??
                this._adminEmissionData.value.gasolineDefaultFactor;

              this._emissionDataForLoggedInData.next({
                dieselDefaultFactor: fetchedEmissionData.dieselDefaultFactor,
                electricDefaultFactor:
                  fetchedEmissionData.electricDefaultFactor,
                gasolineDefaultFactor:
                  fetchedEmissionData.gasolineDefaultFactor,
                emissionList: [
                  {
                    tenantId: fetchedEmissionData.dieselDefaultFactor
                      ? tenant.id
                      : ADMIN_TENENAT_ID,
                    countryCode: 'Default',
                    dieselFactor,
                    electricFactor,
                    gasolineFactor,
                  },
                  ...fetchedEmissionData.emissionList,
                ],
              });
              this.contentLoadingService.setLoadingState(false);
            });
        }
      });
    }
  }

  fetchEmissions(): void {
    if (ApiService.hasIDToken()) {
      this.emissionHttpService
        .getAllEmissionFactors(ADMIN_TENENAT_ID)
        .subscribe((fetchedEmissionData: EmissionFetchType) => {
          this._adminEmissionData.next({
            dieselDefaultFactor: fetchedEmissionData.dieselDefaultFactor,
            electricDefaultFactor: fetchedEmissionData.electricDefaultFactor,
            gasolineDefaultFactor: fetchedEmissionData.gasolineDefaultFactor,
            emissionList: [
              {
                tenantId: ADMIN_TENENAT_ID,
                countryCode: 'Default',
                dieselFactor: fetchedEmissionData.dieselDefaultFactor,
                electricFactor: fetchedEmissionData.electricDefaultFactor,
                gasolineFactor: fetchedEmissionData.gasolineDefaultFactor,
                // adminEmissionFactor: adminEmissionFactor,
              },
              ...fetchedEmissionData.emissionList,
            ],
          });
          this.fetchEmissionsForTenant(); // TODO-EMISSION is this needed anywhere?
        });
    }
  }

  updateEmission(emission: Emission) {
    // const updatedList: Emission[] = [...this._emissionList.value];
    // const mappedList = updatedList.map((e) => e.countryCode);
    // const index: number = mappedList.indexOf(emission.countryCode);
    // if (index === -1) {
    //   this.addEmissionToList(emission);
    // } else {
    //   this.updateEmissionInService(emission);
    // }
  }

  setEmissionListForAdmin(emissionList: Emission[]) {
    this._adminEmissionData.next({
      ...this._adminEmissionData.value,
      emissionList: emissionList,
    });
  }

  setEmissionListForLoggedInTenant(emissionList: Emission[]) {
    this._emissionDataForLoggedInData.next({
      ...this._emissionDataForLoggedInData.value,
      emissionList: emissionList,
    });
  }

  addEmissionToList(emission: Emission, updateAdmin: boolean) {
    this.contentLoadingService.setLoadingState(true);
    this.emissionHttpService.addEmission(emission).subscribe((_) => {
      this.tenantService.loggedInTenant.subscribe((tenant) => {
        if (updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
          const adminEmissionListCopy: Emission[] = [
            ...this._adminEmissionData.value.emissionList,
            emission,
          ];
          this.setEmissionListForAdmin(adminEmissionListCopy);
        }

        if (!updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
          const tenantEmissionListCopy: Emission[] = [
            ...this._emissionDataForLoggedInData.value.emissionList.filter(
              (e) => !emission.isDefault || e.countryCode !== 'Default' // filter out the default entry if emission default has been overriden
            ),
            emission,
          ].sort((a, b) =>
            a.countryCode === 'Default'
              ? -1
              : b.countryCode === 'Default'
              ? 1
              : 0
          );
          this.setEmissionListForLoggedInTenant(tenantEmissionListCopy);
        }
        this.contentLoadingService.setLoadingState(false);
      });
    });
  }

  updateEmissionInService(emission: Emission, updateAdmin: boolean) {
    this.contentLoadingService.setLoadingState(true);
    this.emissionHttpService.updateEmission(emission).subscribe((_) => {
      if (updateAdmin || emission?.tenantId === ADMIN_TENENAT_ID) {
        const emissionListCopy: Emission[] = [
          ...this._adminEmissionData.value.emissionList,
        ];
        const index: number = emissionListCopy.findIndex(
          ({ countryCode }) => countryCode === emission.countryCode
        );
        emissionListCopy[index] = emission;
        this.setEmissionListForAdmin(emissionListCopy);
      }

      if (!updateAdmin || emission?.tenantId === ADMIN_TENENAT_ID) {
        const emissionListCopy: Emission[] = [
          ...this._emissionDataForLoggedInData.value.emissionList,
        ];
        const index: number = emissionListCopy.findIndex(
          ({ countryCode }) => countryCode === emission.countryCode
        );
        emissionListCopy[index] = emission;
        this.setEmissionListForLoggedInTenant(emissionListCopy);
      }
      this.contentLoadingService.setLoadingState(false);
    });
  }

  replaceEmissionByCode(
    emission: Emission,
    oldCode: string,
    updateAdmin: boolean
  ) {
    this.tenantService.loggedInTenant.subscribe((tenant) => {
      const tenantToDelete = updateAdmin ? ADMIN_TENENAT_ID : tenant.id;
      this.contentLoadingService.setLoadingState(true);
      this.emissionHttpService
        .deleteEmissions(oldCode, tenantToDelete)
        .subscribe((_) => {
          this.emissionHttpService
            .addEmission({ ...emission, tenantId: tenantToDelete })
            .subscribe((_) => {
              if (updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
                const emissionListCopy: Emission[] = [
                  ...this._adminEmissionData.value.emissionList,
                ];
                const index: number = emissionListCopy.findIndex(
                  ({ countryCode }) => countryCode === oldCode
                );
                emissionListCopy[index] = emission;
                this.setEmissionListForAdmin(emissionListCopy);
              }

              if (!updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
                const emissionListCopy: Emission[] = [
                  ...this._emissionDataForLoggedInData.value.emissionList,
                ];
                const index: number = emissionListCopy.findIndex(
                  ({ countryCode }) => countryCode === oldCode
                );
                emissionListCopy[index] = emission;

                this.setEmissionListForLoggedInTenant(emissionListCopy);
              }
              this.contentLoadingService.setLoadingState(false);
            });
        });
    });
  }

  removeEmissionFromList(countryCode: string, updateAdmin: boolean) {
    this.contentLoadingService.setLoadingState(true);
    this.tenantService.loggedInTenant.subscribe((tenant) => {
      const tenantToDelete = updateAdmin ? ADMIN_TENENAT_ID : tenant.id;
      this.emissionHttpService
        .deleteEmissions(countryCode, tenantToDelete)
        .subscribe((_) => {
          if (updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
            const adminUpdatedList =
              this._adminEmissionData.value.emissionList.filter(
                (emission) => emission.countryCode !== countryCode
              );
            this.setEmissionListForAdmin(adminUpdatedList);
          }

          if (!updateAdmin || tenant?.id === ADMIN_TENENAT_ID) {
            const tenantUpdatedList =
              this._emissionDataForLoggedInData.value.emissionList.filter(
                (emission) => emission.countryCode !== countryCode
              );

            this.setEmissionListForLoggedInTenant(tenantUpdatedList);
            this.contentLoadingService.setLoadingState(false);
          }
        });
    });
  }

  get defaultValues(): number[] {
    return [
      // this._dieselDefaultFactor.value,
      // this._electricDefaultFactor.value,
      // this._gasolineDefaultFactor.value,
    ];
  }

  get gasolineDefaultFactor() {
    return -1;
    // return this._gasolineDefaultFactor.asObservable();
  }

  get electricDefaultFactor() {
    return -1;
    // return this._electricDefaultFactor.asObservable();
  }

  get dieselDefaultFactor() {
    return -1;
    // return this._dieselDefaultFactor.asObservable();
  }

  get adminEmissionData() {
    this.fetchEmissions();
    return this._adminEmissionData.asObservable();
  }

  get emissionDataForTenant() {
    return this._emissionDataForLoggedInData.asObservable();
  }

  get currentAdmissionData() {
    return this._adminEmissionData.getValue();
  }
}
