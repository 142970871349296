<div class="p-grid">
  <div class="p-col office-table-header-container">
    <h1 class="no-margin">{{ 'office.office-header' | translate}}</h1>
    <span class="bold">{{ 'common.data-per-employee' | translate}}</span>&nbsp;
    <span>{{overviewReports.length}} {{ 'office.reports-count' | translate}}</span>
  </div>
  <div class="p-col-fixed office-table-header-container" style="width: 300px">
    <div class="p-grid p-ai-center p-fluid">
      <div class="p-col">
        <form [formGroup]="officeSearch" style="position: relative">
          <p-autoComplete pInputText [placeholder]="'office.search' | translate" formControlName="search"
            dataKey='value' field="label" [showEmptyMessage]="true" emptyMessage="{{'office.no-offices' | translate}}"
            [suggestions]="offices" (completeMethod)="suggestOffices($event)">
            <ng-template let-item pTemplate="item">
              <div class="p-d-flex p-ai-center">
                <span [ngClass]="{'group-header': item.title}">{{item.label}}</span>
              </div>
            </ng-template>
          </p-autoComplete>
          <button *ngIf="officeSearch.controls.search.value" (click)="resetOfficeSearch()" class="clear-icon"><i
              class="pi pi-times-circle"></i></button>
        </form>
      </div>
      <div class="p-col-fixed" style="width: 55px">
        <button pButton class="search-button" type="button" icon="pi pi-search" (click)="searchOffices()"></button>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <div *ngIf="!(loaderService.isLoading | async); else skeleton">
      <p-table [value]="overviewReports" class="office-table" responsiveLayout="stack" [paginator]="true" [rows]="10"
        [showFirstLastIcon]="true" [rowsPerPageOptions]="[10, 15, 30]" (sortFunction)="reportSortFunction($event)"
        [customSort]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="white-cell"></th>
            <th class="emission-cell left-align" colspan="3">
              <div class="p-grid p-jc-sm-center">
                <div class="p-col-fixed icon-width">
                  <img src="assets/svg/icons/emissions.svg" alt="Emission icon">
                </div>
                <div class="p-col">
                  {{ 'office.emissions-per-employee' | translate}}
                </div>
              </div>
            </th>
            <th class="white-cell left-align" colspan="2">
              <div class="p-grid p-jc-sm-center">
                <div class="p-col-fixed icon-width">
                  <img src="assets/svg/icons/tripDistance.svg" alt="Distance icon">
                </div>
                <div class="p-col-8">
                  {{ 'office.distance-per-employee' | translate}}
                </div>
              </div>
            </th>
            <th class="eco-cell left-align" colspan="2">
              <div class="p-grid p-jc-sm-center">
                <div class="p-col-fixed icon-width">
                  <img src="assets/svg/icons/ecoLeaf.svg" alt="Eco icon">
                </div>
                <div class="p-col-10">
                  {{ 'office.eco-distance-per-employee' | translate}}
                </div>
              </div>
            </th>
            <th class="white-cell"></th>
          </tr>
          <tr class="table-header">
            <th style="width: 20%" class="left-align">{{ 'office.location' | translate}}</th>
            <th style="width: 5%" pSortableColumn="totalEmissions">{{ 'common.total' | translate}}
              <p-sortIcon field="totalEmissions"></p-sortIcon>
            </th>
            <th style="width: 5%" pSortableColumn="commuteEmissions">{{ 'common.commute' | translate}}
              <p-sortIcon field="commuteEmissions"></p-sortIcon>
            </th>
            <th style="width: 7%" pSortableColumn="businessEmissions">{{ 'common.business-trip' | translate}}
              <p-sortIcon field="businessEmissions"></p-sortIcon>
            </th>
            <th style="width: 10%" pSortableColumn="commuteDistance">{{ 'common.commute' | translate}}
              <p-sortIcon field="commuteDistance"></p-sortIcon>
            </th>
            <th style="width: 15%" pSortableColumn="businessDistance">{{ 'common.business-trip' | translate}}
              <p-sortIcon field="businessDistance"></p-sortIcon>
            </th>
            <th style="width: 10%" pSortableColumn="ecologicalCommuteDistance">{{ 'common.commute' | translate}}
              <p-sortIcon field="ecologicalCommuteDistance"></p-sortIcon>
            </th>
            <th style="width: 15%" pSortableColumn="ecologicalBusinessDistance">{{ 'common.business-trip' | translate}}
              <p-sortIcon field="ecologicalBusinessDistance"></p-sortIcon>
            </th>
            <th style="width: 13%">{{ 'office.reports-count-table' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-report>
          <tr (click)="navigateToDetail(report.office?.id)">
            <td class="left-align">
              <span class="p-column-title responsive-header">{{ 'office.location' | translate}}</span>
              <div>
                <div>{{report.office.zip}} {{report.office.city}}</div>
                <div class="street-label"><img src="assets/svg/icons/place.svg" alt=""> {{report.office.street}}</div>
              </div>
            </td>
            <td class="emission-cell"><span class="p-column-title responsive-header">{{ 'office.emission-avg' |
                translate}}
                - {{ 'common.total' | translate}}</span><span
                class="p-text-right">{{formatValue(report.totalEmissions)}}</span>
            </td>
            <td class="emission-cell"><span class="p-column-title responsive-header">{{ 'office.emission-avg' |
                translate}}
                - {{ 'common.commute' | translate}}</span><span
                class="p-text-right">{{formatValue(report.commuteEmissions)}}</span>
            </td>
            <td class="emission-cell"><span class="p-column-title responsive-header">{{ 'office.emission-avg' |
                translate}}
                - {{ 'common.business-trip' | translate}}</span><span
                class="p-text-right">{{formatValue(report.businessEmissions)}}</span>
            </td>
            <td><span class="p-column-title responsive-header">{{ 'office.distance-avg' | translate}}
                - {{ 'common.commute' | translate}}</span><span class="p-text-right">{{report.commuteDistance |
                number:'.0-2': translateService.currentLang}} km</span>
            </td>
            <td><span class="p-column-title responsive-header">{{ 'office.distance-avg' | translate}}
                - {{ 'common.business-trip' | translate}}</span><span class="p-text-right">{{report.businessDistance |
                number:'.0-2': translateService.currentLang}} km</span>
            </td>
            <td class="eco-cell"><span class="p-column-title responsive-header">{{ 'office.eco-distance-avg' |
                translate}}
                - {{ 'common.commute' | translate}}</span><span class="p-text-right">{{report.ecologicalCommuteDistance
                |
                number:'.0-2': translateService.currentLang}} km</span>
            </td>
            <td class="eco-cell"><span class="p-column-title responsive-header">{{ 'office.eco-distance-avg' |
                translate}}
                - {{ 'common.business-trip' | translate}}</span><span
                class="p-text-right">{{report.ecologicalBusinessDistance
                | number:'.0-2': translateService.currentLang}}
                km</span></td>
            <td class="p-text-right"><span class="p-column-title responsive-header">{{ 'office.reports-count-table' |
                translate}}</span>{{report.reportCount}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9" style="text-align: center;">
              {{'office.no-reports' | translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <app-loader skeletonHeight="50px"></app-loader>
</ng-template>