import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-title',
  templateUrl: './dashboard-title.component.html',
  styleUrls: ['./dashboard-title.component.scss']
})
export class DashboardTitleComponent implements OnInit {
  @Input() img: string;
  @Input() office = 'Footprint';
  @Input() street;
  title: string;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.translateService.get('common.dashboard').subscribe((translation) => this.title = `${this.street} ${translation}`);
  }

}
