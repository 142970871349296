import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { License, LicenseStatus } from '../../models/license';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseHttpService {
  private apiService: ApiService = inject(ApiService);

  fetchLicensesForTenant(tenantID: string): Observable<License[]> {
    return this.apiService
      .get({
        path: `${
          APIConst.LICENSES + this.apiService.requestParamsObj({})
        }/${tenantID}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ licenses }) =>
          licenses.map((license: License) => {
            const dateTimeFrom = license['contractFrom']['dateTime'];
            const dateFrom = dateTimeFrom['date'];
            const timeFrom = dateTimeFrom['time'];

            const dateTimeUntil = license['contractTo']['dateTime'];
            const dateUntil = dateTimeUntil['date'];
            const timeUntil = dateTimeUntil['time'];
            return {
              id: license['id'],
              tenantID: license['tenantId'],
              contractFrom: new Date(
                dateFrom['year'],
                dateFrom['month'] - 1,
                dateFrom['day'],
                timeFrom['hour'],
                timeFrom['minute'],
                timeFrom['second']
              ),
              contractTo: new Date(
                dateUntil['year'],
                dateUntil['month'] - 1,
                dateUntil['day'],
                timeUntil['hour'],
                timeUntil['minute'],
                timeUntil['second']
              ),
              amountOfCodes: license['amountOfCodes'],
              contractName: license['contractName'],
              status: LicenseStatus[license['status']],
              tenantId: license['tenantId'],
            } as License;
          })
        )
      );
  }

  public addLicense(license: Omit<License, 'id'>): Observable<License> {
    const body = {
      ...license,
      status: license.status.replace('licenseStatus.', '').toUpperCase(),
      contractFrom: {
        dateTime: {
          date: {
            year: license.contractFrom.getFullYear(),
            month: license.contractFrom.getMonth() + 1,
            day: license.contractFrom.getDate(),
          },
          time: {
            hour: license.contractFrom.getHours(),
            minute: license.contractFrom.getMinutes(),
            second: license.contractFrom.getSeconds(),
            nano: license.contractFrom.getMilliseconds(),
          },
        },
        offset: {
          totalSeconds: license.contractFrom.getTimezoneOffset(),
        },
      },
      contractTo: {
        dateTime: {
          date: {
            year: license.contractTo.getFullYear(),
            month: license.contractTo.getMonth() + 1,
            day: license.contractTo.getDate(),
          },
          time: {
            hour: license.contractTo.getHours(),
            minute: license.contractTo.getMinutes(),
            second: license.contractTo.getSeconds(),
            nano: license.contractTo.getMilliseconds(),
          },
        },
        offset: {
          totalSeconds: license.contractTo.getTimezoneOffset(),
        },
      },
    };
    return this.apiService
      .post({
        path: `${APIConst.LICENSES + this.apiService.requestParamsObj({})}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
        body: body,
      })
      .pipe(
        map((value) => value.body),
        map((license) => {
          const dateTimeFrom = license['contractFrom']['dateTime'];
          const dateFrom = dateTimeFrom['date'];
          const timeFrom = dateTimeFrom['time'];

          const dateTimeUntil = license['contractTo']['dateTime'];
          const dateUntil = dateTimeUntil['date'];
          const timeUntil = dateTimeUntil['time'];
          return {
            id: license['id'],
            tenantID: license['tenantId'],
            contractFrom: new Date(
              dateFrom['year'],
              dateFrom['month'] - 1,
              dateFrom['day'],
              timeFrom['hour'],
              timeFrom['minute'],
              timeFrom['second']
            ),
            contractTo: new Date(
              dateUntil['year'],
              dateUntil['month'] - 1,
              dateUntil['day'],
              timeUntil['hour'],
              timeUntil['minute'],
              timeUntil['second']
            ),
            amountOfCodes: license['amountOfCodes'],
            contractName: license['contractName'],
            status: license['status'],
            tenantId: license['tenantId'],
          } as License;
        })
      );
  }

  public updateLicense(license: Partial<License>): Observable<License> {
    return this.apiService
      .put({
        path: `${APIConst.LICENSES + this.apiService.requestParamsObj({})}`,
        endp: environment.apiPath,
        observe: true,
        sendIDToken: true,
        body: {
          ...license,
          status: license.status.replace('licenseStatus.', '').toUpperCase(),
          contractFrom: {
            dateTime: {
              date: {
                year: license.contractFrom.getFullYear(),
                month: license.contractFrom.getMonth() + 1,
                day: license.contractFrom.getDate(),
              },
              time: {
                hour: license.contractFrom.getHours(),
                minute: license.contractFrom.getMinutes(),
                second: license.contractFrom.getSeconds(),
                nano: license.contractFrom.getMilliseconds(),
              },
            },
            offset: {
              totalSeconds: license.contractFrom.getTimezoneOffset(),
            },
          },
          contractTo: {
            dateTime: {
              date: {
                year: license.contractTo.getFullYear(),
                month: license.contractTo.getMonth() + 1,
                day: license.contractTo.getDate(),
              },
              time: {
                hour: license.contractTo.getHours(),
                minute: license.contractTo.getMinutes(),
                second: license.contractTo.getSeconds(),
                nano: license.contractTo.getMilliseconds(),
              },
            },
            offset: {
              totalSeconds: license.contractTo.getTimezoneOffset(),
            },
          },
        },
      })
      .pipe(
        map((value) => value.body),
        map((license) => {
          const dateTimeFrom = license['contractFrom']['dateTime'];
          const dateFrom = dateTimeFrom['date'];
          const timeFrom = dateTimeFrom['time'];

          const dateTimeUntil = license['contractTo']['dateTime'];
          const dateUntil = dateTimeUntil['date'];
          const timeUntil = dateTimeUntil['time'];
          return {
            id: license['id'],
            tenantID: license['tenantId'],
            contractFrom: new Date(
              dateFrom['year'],
              dateFrom['month'] - 1,
              dateFrom['day'],
              timeFrom['hour'],
              timeFrom['minute'],
              timeFrom['second']
            ),
            contractTo: new Date(
              dateUntil['year'],
              dateUntil['month'] - 1,
              dateUntil['day'],
              timeUntil['hour'],
              timeUntil['minute'],
              timeUntil['second']
            ),
            amountOfCodes: license['amountOfCodes'],
            contractName: license['contractName'],
            status: license['status'],
            tenantId: license['tenantId'],
          } as License;
        })
      );
  }

  public deleteLicense(licenseId: string) {
    return this.apiService.delete({
      path: `${
        APIConst.LICENSES + this.apiService.requestParamsObj({})
      }/${licenseId}`,
      endp: environment.apiPath,
      observe: true,
      sendIDToken: true,
    });
  }
}
