import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Company } from 'src/app/models/company';
import { Emission } from 'src/app/models/emission';
import { Office } from 'src/app/models/office';
import { TableData } from 'src/app/models/table-data';
import { CompanyService } from 'src/app/services/company.service';
import {
  ADMIN_TENENAT_ID,
  EmissionService,
} from 'src/app/services/emission-service';
import { OfficeService } from 'src/app/services/office.service';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { TokenUtil } from 'src/app/utils/token-util';
import {
  createAddCompanyDialog,
  createDeleteCompanyDialog,
  createEditCompanyDialog,
} from '../../../services/company-dialog-factory';
import {
  createAddEmissionDialog,
  createDeleteEmissionDialog,
  createEditEmissionDialog,
  createRestoreDefaultEmissionDialog,
} from '../../../services/emssission-dialog-factory';
import {
  createAddOfficeDialog,
  createDeleteOfficeDialog,
  createEditOfficeDialog,
} from '../../../services/office-dialog-factory';
import {
  companyTableStructure,
  emissionTableStructure,
  officeTableStructure,
} from '../../../services/table-structure-factory';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent implements OnInit, OnDestroy {
  public static get route(): string {
    return 'data';
  }

  private companySubscription: Subscription | undefined;
  private officeSubscription: Subscription | undefined;
  private emissionSubscription: Subscription | undefined;
  private adminEmissionSubscription: Subscription | undefined;

  private companies: Company[] = [];
  private offices: Office[] = [];
  private emissions: Emission[] = [];

  public emissionCount: number = 0;
  public officeCount: number = 0;
  public companyCount: number = 0;

  public emissionFactorData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );
  public officeData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );
  public companyData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );

  public userIsAnalyst: boolean = false;

  constructor(
    private companyService: CompanyService,
    private officeService: OfficeService,
    private emissionFactorService: EmissionService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userIsAnalyst = TokenUtil.userHasAnalystRole();
    this.emissionSubscription =
      this.emissionFactorService.emissionDataForTenant.subscribe(
        ({ emissionList }) => {
          this.emissions = emissionList;
          this.initializeEmissionTableData();
        }
      );

    if (!this.userIsAnalyst) {
      this.companySubscription =
        this.companyService.dashboardCompanies.subscribe((companies) => {
          this.companies = companies;

          this.initializeCompanyTableData();
        });
      this.officeSubscription = this.officeService.dashboardOffices.subscribe(
        (offices) => {
          this.offices = offices;
          this.initializeOfficeTableData();
        }
      );
    }
  }
  ngOnDestroy(): void {
    if (!this.userIsAnalyst) {
      this.companySubscription?.unsubscribe();
      this.officeSubscription?.unsubscribe();
    }
    this.emissionSubscription?.unsubscribe();
    this.adminEmissionSubscription?.unsubscribe();
  }

  private initializeCompanyTableData() {
    this.companyCount = this.companies.length;
    this.companyData.next(companyTableStructure(this.companies));
  }
  private initializeOfficeTableData() {
    this.officeCount = this.offices.length;
    this.officeData.next(officeTableStructure(this.offices));
  }
  private initializeEmissionTableData() {
    const emissions = this.emissions.map((emission) => {
      if (emission.countryCode === 'Default') {
        return {
          ...emission,
          adminEmissionFactor: {
            dieselFactor:
              this.emissionFactorService.currentAdmissionData
                .dieselDefaultFactor,
            electricFactor:
              this.emissionFactorService.currentAdmissionData
                .electricDefaultFactor,
            gasolineFactor:
              this.emissionFactorService.currentAdmissionData
                .gasolineDefaultFactor,
          },
        };
      }
      return emission;
    });
    this.emissionCount = emissions.length;

    this.emissionFactorData.next(
      emissionTableStructure(
        emissions,
        this.emissions.some(
          (e) => e.countryCode === 'Default' && e.tenantId !== ADMIN_TENENAT_ID
        )
      )
    );
  }

  //#region Dialogs for emissions, offices and companies

  public addEmissionFactor() {
    const emissionNameKey: string = 'emission-name';
    const emissionDieselKey: string = 'emission-diesel';
    const emissionPetrolKey: string = 'emission-petrol';
    const emissionElectricKey: string = 'emission-electric';

    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createAddEmissionDialog(
        emissionNameKey,
        emissionDieselKey,
        emissionPetrolKey,
        emissionElectricKey
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response && response.size > 0) {
        this.emissionFactorService.addEmissionToList(
          {
            countryCode: response.get(emissionNameKey),
            dieselFactor: +response.get(emissionDieselKey),
            electricFactor: +response.get(emissionElectricKey),
            gasolineFactor: +response.get(emissionPetrolKey),
            tenantId: TokenUtil.getTenantFromToken(
              localStorage.getItem('id-token')
            ),
          },
          false
        );
      }
    });
  }

  public editEmissionFactor(event) {
    const codeKey = 'emission-name';
    const dieselKey = 'emission-diesel';
    const petrolKey = 'emission-petrol';
    const electricKey = 'emission-electric';

    const copyEvent = { ...event };

    if (
      'data' in copyEvent &&
      copyEvent.data.some((e) => e.text.includes(' '))
    ) {
      copyEvent.data = copyEvent.data.map((e) => ({
        ...e,
        text: e.text.split(' ')[0],
      }));
    }
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createEditEmissionDialog(
        codeKey,
        dieselKey,
        petrolKey,
        electricKey,
        copyEvent
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response && response.size > 0) {
        const tenantId = TokenUtil.getTenantFromToken(
          localStorage.getItem('id-token')
        );

        const isInitialDefaultValueUpdated =
          event['entryId'] === 'Default' &&
          !this.emissions.some(
            (entry) =>
              entry.countryCode === 'Default' && entry.tenantId === tenantId
          );

        const original = this.emissions.filter(
          (e) =>
            e.countryCode === event['entryId'] &&
            e.tenantId.toLocaleLowerCase() ===
              (isInitialDefaultValueUpdated
                ? ADMIN_TENENAT_ID.toLocaleLowerCase()
                : tenantId.toLocaleLowerCase())
        )[0];

        const emission: Emission = {
          countryCode: original.countryCode,
          dieselFactor: response.get(dieselKey)
            ? +response.get(dieselKey)
            : original.dieselFactor,
          electricFactor: response.get(electricKey)
            ? +response.get(electricKey)
            : original.electricFactor,
          gasolineFactor: response.get(petrolKey)
            ? +response.get(petrolKey)
            : original.gasolineFactor,
          tenantId: tenantId,
        };

        if (isInitialDefaultValueUpdated) {
          this.emissionFactorService.addEmissionToList(
            { ...emission, tenantId, isDefault: true },
            false
          );
          return;
        } else if (response.get(codeKey)) {
          const code = response.get(codeKey);
          emission.countryCode = code;
          this.emissionFactorService.replaceEmissionByCode(
            emission,
            original.countryCode,
            false
          );
          return;
        } else {
          this.emissionFactorService.updateEmissionInService(emission, false);
        }
      }
    });
  }

  public deleteEmissionFactor(event) {
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createDeleteEmissionDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.emissionFactorService.removeEmissionFromList(
          event['entryId'],
          false
        );
      }
    });
  }

  public restoreInitialEmissionValue(event) {
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createRestoreDefaultEmissionDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.emissionFactorService.removeEmissionFromList('Default', false);
        this.emissionFactorService.fetchEmissions();
      }
    });
  }

  public deleteOffice(event) {
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createDeleteOfficeDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.officeService.deleteOffice(event['entryId']);
      }
    });
  }

  public editOffice(event) {
    const countryKey = 'office-country';
    const streetKey = 'office-street';
    const zipKey = 'office-zip';
    const cityKey = 'office-city';

    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createEditOfficeDialog(
        event,
        countryKey,
        streetKey,
        zipKey,
        cityKey
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (!response) return;

      const office: Office = this.offices.find(
        (office) => office.id === event['entryId']
      );

      if (response.size > 0) {
        this.officeService.updateOffice({
          id: office.id,
          city: response.get(cityKey) ?? office.city,
          street: response.get(streetKey) ?? office.street,
          countryCode: response.get(countryKey) ?? office.countryCode,
          zip: response.get(zipKey) ?? office.zip,
          tenantID: office.tenantID,
        } as Office);
      }
    });
  }

  public addOffice() {
    const countryKey = 'office-country';
    const streetKey = 'office-street';
    const zipKey = 'office-zip';
    const cityKey = 'office-city';

    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createAddOfficeDialog(countryKey, streetKey, zipKey, cityKey),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response && response.size > 0) {
        this.officeService.addOffice(
          response.get(cityKey),
          response.get(streetKey),
          response.get(zipKey),
          response.get(countryKey)
        );
      }
    });
  }

  public addCompany() {
    const companyNameKey = 'company-name';
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createAddCompanyDialog(companyNameKey),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response.size > 0) {
        this.companyService.addCompany(response.get(companyNameKey));
      }
    });
  }

  public deleteCompany(event) {
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createDeleteCompanyDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      const companyId: string = '' + event['entryId'];
      if (response === 200) {
        this.companyService.deleteCompany(companyId);
      }
    });
  }

  public editCompany(event) {
    const key = 'company-name';
    const dialogRef = this.matDialog.open(TableDialogComponent, {
      data: createEditCompanyDialog(key, event),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      if (response && response.size > 0) {
        this.companyService.updateCompany(event['entryId'], response.get(key));
      }
    });
  }

  //#endregion

  public companyTableData(): Observable<TableData> {
    return this.companyData.asObservable();
  }

  public officeTableData(): Observable<TableData> {
    return this.officeData.asObservable();
  }

  public emissionTableData(): Observable<TableData> {
    return this.emissionFactorData.asObservable();
  }
}
