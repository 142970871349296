import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Survey } from 'src/app/models/survey/survey';


type SurveyWithRespose = Survey & {
  companyNames: string[];
  numberOfSubmits: number;
};

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
})
export class SurveyDetailsComponent implements OnInit {
  private translateService: TranslateService = inject(TranslateService);

  @Input() survey: SurveyWithRespose | null = null;
  @Input() resetSwitch: boolean = false;
  @Output() isActiveChanged: EventEmitter<SurveyWithRespose> = new EventEmitter<SurveyWithRespose>();
  @Output() openDetailsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  editOpened: boolean = false;
  isActive: boolean = false;
  isModalOpen: boolean = false;
  currentLang = this.translateService.currentLang;
  showPreviewSurveyModal: boolean;

  opanModal() {
    this.showPreviewSurveyModal = true;
    document.body.style.overflow = 'hidden';
    
  }

  public onIsActiveChanged() {
    this.survey.isActive = !this.survey.isActive;
    this.isActiveChanged.emit(this.survey);
  }
  surveyActivesToggleHandler(e, isEditMode?: boolean) {
    this.isEditMode.emit(isEditMode);
    this.isActiveChanged.emit(e);
  }

  public openDetails() {
    this.openDetailsClicked.emit(false);
  }

  public onEditClicked() {
    this.editOpened = true;
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.isActive = this.survey.isActive;
  }
}
