import { Component, Output, EventEmitter } from '@angular/core';
import { BlobStorageHttpService } from '../../../../../services/http/blob-storage-http.service';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './logo-drag-drop.component.html',
  styleUrls: ['./logo-drag-drop.component.scss'],
})
export class LogoDragDropComponent {
  private readonly ALLOWED_UPLOAD_TYPES: string[] = ['image/svg+xml'];
  public hasError: boolean = false;

  @Output() updateIcon: EventEmitter<string> = new EventEmitter<string>();

  /*

TODO
- only save-changes when changes were made
- cache data
- routing
--- no route change when uploading
--- default route is /dashboard
*/

  constructor(private blobStorageHttpService: BlobStorageHttpService) {}

  handleDrop(event: DragEvent) {
    event.preventDefault();

    const files = event.dataTransfer.files;

    if (files.length > 0) {
      //error
    }
    const file = files[0];

    if (!(file && this.ALLOWED_UPLOAD_TYPES.includes(file.type))) {
      //error
    }
    this.handleFile(file);
  }

  handleFile(file: File) {
    if (file.type !== 'image/svg+xml') {
      this.hasError = true;
      setTimeout(() => {
        this.hasError = false;
      }, 1000 * 5);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      this.updateIcon.emit(result);
    };
    reader.readAsDataURL(file);
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  selectFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: Event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files.length > 0) {
        this.handleFile(files[0]);
      }
    };
    input.click();
  }
}
