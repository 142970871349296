<div class="branding-wrapper page-wrapper">
    <div class="intro section">
        <h2>{{'branding.pageContent.pageHeadline' | translate}}</h2>
        <p>{{'branding.pageContent.pageIntro' | translate}}</p>

    </div>

    <div class="icon section">
        <h3>{{'branding.sections.icon.headline' | translate}}</h3>
        <p>{{'branding.sections.icon.description' | translate}}</p>
        <div class="icon-container">
            <h6 class="preview-text">{{'branding.sections.icon.preview' | translate}}</h6>
            <div class="icon-selector">
                <div class="icon-frame">
                    <app-loader *ngIf="branding === undefined || !branding.icon.includes('data:image')"></app-loader>
                    <img *ngIf="branding !== undefined && branding.icon.includes('data:image')"
                        [src]="domSanitizer.bypassSecurityTrustResourceUrl(branding.icon)">
                    <!-- <div *ngIf="branding !== undefined && branding.icon.includes('data:image')" class="svg"
                        [innerHTML]="svg"></div> -->
                </div>
                <app-drag-and-drop (updateIcon)="updateIcon($event)"></app-drag-and-drop>
            </div>
            <div class="controls" *ngIf="iconHasChanged">
                <app-button [disabled]="iconHasChanged === false" (click)="iconHasChanged ? undoIconChange() : null"
                    cancelButtonStyle="true" [text]="'common.cancel'|translate"></app-button>
                <app-button [cancelButtonStyle]="iconHasChanged === false" [disabled]="iconHasChanged === false"
                    (click)="iconHasChanged ? saveIcon() : null" [text]="'common.saveChanges'|translate"></app-button>
            </div>
        </div>
    </div>

    <div class="color-scheme section">
        <h3>{{'branding.sections.colorScheme.headline' | translate}}</h3>
        <p>{{'branding.sections.colorScheme.description' | translate}}</p>
    </div>
    <div class="centered">
        <app-loader *ngIf="branding === undefined"></app-loader>
    </div>
    <ng-container *ngFor="let item of getBrandKeys(); let last = last; let index = index">
        <app-brand-section [key]="item.key" [colors]="item.value"
            [hasDarkColors]="item.key !== 'brandColors'"></app-brand-section>
        <div [style.margin-bottom.px]="!last ? 16 : 0"></div>
    </ng-container>
</div>