import { Pipe, PipeTransform } from '@angular/core';
import { Pair } from '../models/pair';

@Pipe({
  name: 'filterSix'
})
export class FilterSixPipe implements PipeTransform {

  transform(reportPairs: Pair[]): Pair[] {
    return reportPairs.slice(0, 6);
  }

}
