export interface KeyValueFieldData {
  optionName: string;
  deValue: string;
  enValue: string;
}

export class KeyValueField {
  public static DE_KEY: string = 'de';
  public static EN_KEY: string = 'en';
}
