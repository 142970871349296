export class UnitConverter {
  private static toFixedNumberFormat(num: number, fixedSpaces: number) {
    return (Math.round(num * 100) / 100).toFixed(fixedSpaces);
  }

  public static getFormattedWeight(grams: number): string {
    if (grams < 1000) return `${this.toFixedNumberFormat(grams, 0)} g`;

    if (1000 <= grams && grams < 10000)
      return `${this.toFixedNumberFormat(grams / 1000, 2)} kg`;

    if (10000 <= grams && grams < 100000)
      return `${this.toFixedNumberFormat(grams / 1000, 1)} kg`;

    if (100000 <= grams && grams < 1000000)
      return `${this.toFixedNumberFormat(grams / 1000, 0)} kg`;

    return `${this.toFixedNumberFormat(grams / 1000000, 3)} t`;
  }
}
