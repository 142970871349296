import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivationCode } from 'src/app/models/activationcode';
import { LicenseResponse } from 'src/app/models/license';
import { TableData, TableRow } from 'src/app/models/table-data';
import { ActivationCodeService } from 'src/app/services/activationcode.service';
import { downloadCSV } from 'src/app/services/download.service';
import { LicenseService } from 'src/app/services/license.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit {
  public static get route(): string {
    return 'license';
  }

  private datepipe: DatePipe = new DatePipe('en-US');

  public licenseData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );

  public activationCodes: BehaviorSubject<ActivationCode[]> =
    new BehaviorSubject([]);

  public licenseCount: number = 0;

  constructor(
    private activationcodeService: ActivationCodeService,
    private licenseService: LicenseService
  ) {}

  ngOnInit(): void {
    this.activationcodeService.loggedInTenantActivationCodes.subscribe(
      (activationCodes: ActivationCode[]) => {
        this.activationCodes.next(activationCodes);
      }
    );

    this.licenseService.licenses.subscribe((response: LicenseResponse) => {
      this.licenseCount = response.licenses.length;
      this.licenseData.next({
        tableRows: response.licenses.map((license) => {
          return {
            entryId: license.id,
            data: [
              {
                text: license.contractName,
              },
              {
                text: license.amountOfCodes,
                rightAligned: true,
              },
              {
                text: this.activationCodes.value
                  .filter((code) => code.licenseId === license.id)
                  .map((code) => code.amountOfRemainingUses)
                  .reduce((prev, curr) => prev + curr, 0),
                rightAligned: true,
              },
              {
                text: `${this.datepipe.transform(
                  license.contractFrom,
                  'dd.MM.YYYY'
                )} - ${this.datepipe.transform(
                  license.contractTo,
                  'dd.MM.YYYY'
                )}`,
                rightAligned: true,
              },
            ],
          } as TableRow;
        }),
        columnHeaders: [
          {
            title: 'license.table.title.type',
            filterable: false,
            sortable: false,
          },
          {
            title: 'license.table.title.codes',
            filterable: false,
            sortable: true,
            rightAligned: true,
          },
          {
            title: 'license.table.title.unusedCodes',
            filterable: false,
            sortable: true,
            rightAligned: true,
          },
          {
            title: 'license.table.title.active',
            filterable: false,
            sortable: true,
            rightAligned: true,
          },
        ],
        editable: false,
        itemDeletion: false,
        includesCustomButton: true,
        hasSingleCustomButton: false,
        pagination: false,
      });
    });
  }

  ngOnDestroy(): void {}

  public downloadActivationCodes(event: TableRow) {
    const codesToDownload = this.activationCodes.value.filter(
      (e) => e.licenseId === event.entryId
    );
    downloadCSV(
      `${event.data[0].text.replace(' ', '_')}-activation-codes.csv`,
      this.convertToCSV(codesToDownload)
    );
  }

  convertToCSV(data: ActivationCode[]): string {
    if (data.length === 0) {
      return '';
    }

    const separator = ','; // Separator character
    const excludedKeys = ['tenantID', 'licenseId', 'usagesCounter']; // Keys to exclude from CSV
    const keys = Object.keys(data[0]).filter(
      (key) => !excludedKeys.includes(key)
    ); // Filter keys excluding excludedKeys

    const header = keys.join(separator);
    const rows = data.map((item) => {
      return keys
        .map((key) => {
          if (key === 'validationDateFrom' || key === 'validationDateTo') {
            const date = new Date(item[key]);
            return this.formatDate(date);
          } else {
            return item[key];
          }
        })
        .join(separator);
    });

    return header + '\n' + rows.join('\n');
  }

  private formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  public licenseTableData(): Observable<TableData> {
    return this.licenseData.asObservable();
  }
}
