import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import {
  ActiveDirectoryAppRole,
  ActiveDirectoryUser,
  User,
} from 'src/app/models/active-directory-user';
import { TableData, TableRow } from 'src/app/models/table-data';
import { ContentLoadingService } from 'src/app/services/content-loading.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import { TokenUtil } from 'src/app/utils/token-util';
import {
  createAddOwnerDialog,
  createDeleteUserDialog,
  createEditOwnerDialog,
} from '../../../services/user-roles-dialog-factory';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss'],
})
export class UsersRolesComponent implements OnInit {
  public userData: BehaviorSubject<TableData> = new BehaviorSubject(undefined);

  public directoryUsers: User[] = [];

  public userCount: number = 0;

  private userDataSubscription?: Subscription;

  constructor(
    private tenantService: TenantService,
    private dialog: MatDialog,
    private contentLoadingService: ContentLoadingService,
    private translate: TranslateService
  ) {}

  public ownerRole: ActiveDirectoryAppRole | undefined;
  private ownerRoleId = 'e28d1237-b120-47af-8238-7e0d144489cd';

  ngOnInit(): void {
    this.initializeUserTableData();
    const fetchUsersObservable = this.tenantService.fetchAllUsers(
      true,
      this.ownerRoleId
    );
    const fetchAppRolesObservable = this.tenantService.fetchAllAppRoles();

    this.userDataSubscription = forkJoin([
      fetchAppRolesObservable,
      fetchUsersObservable,
    ]).subscribe(
      (response: [ActiveDirectoryAppRole[], ActiveDirectoryUser[]]) => {
        this.ownerRole = response[0].filter(
          (appRole) => appRole.id === this.ownerRoleId
        )[0];
        this.directoryUsers = response[1].map((activeDirectoryUser) => {
          return {
            activeDirectoryAppRole: response[0].filter(
              (activeDirectoryAppRole) =>
                activeDirectoryUser.appRole.appRoleId ===
                activeDirectoryAppRole.id
            )[0],
            activeDirectoryUser: activeDirectoryUser,
          } as User;
        });
        this.userCount = this.directoryUsers.length;
        this.initializeUserTableData();
      }
    );
  }

  ngOnDestroy(): void {
    this.userDataSubscription?.unsubscribe();
  }

  public addUser() {
    const nameKey = 'name-key';
    const surnameKey = 'surname-key';
    const emailKey = 'email-key';

    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createAddOwnerDialog(
        nameKey,
        surnameKey,
        emailKey,
        this.directoryUsers.map((user) => user.activeDirectoryUser.email)
      ),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      this.contentLoadingService.setLoadingState(true);
      this.tenantService
        .inivteDirectoryOwner(
          response.get(nameKey),
          response.get(surnameKey),
          response.get(emailKey)
        )
        .subscribe((invitationResponse: any) => {
          this.contentLoadingService.setLoadingState(false);
          const content = invitationResponse.body;
          const newUser = {
            activeDirectoryUser: {
              appRole: {
                appRoleId: content['appRole']['appRoleId'],
                principalDisplayName:
                  content['appRole']['principalDisplayName'],
              },
              name: content['name'] ?? '',
              surname: content['surname'] ?? '',
              email: content['email'],
              id: content['id'],
            },
            activeDirectoryAppRole: this.ownerRole,
          } as User;

          this.directoryUsers.push(newUser);
          this.contentLoadingService.setLoadingState(false);
          this.initializeUserTableData();
        });
    });
  }

  public editUser(event) {
    const nameKey = 'name-key';
    const surnameKey = 'surname-key';

    const user: User = this.directoryUsers.filter(
      (value) => value.activeDirectoryUser.id === event['entryId']
    )[0];

    const userName = user.activeDirectoryUser.name;
    const userSurname = user.activeDirectoryUser.surname;

    const principalNameParts: string[] =
      user.activeDirectoryUser.appRole.principalDisplayName.split(' *');

    const name = userName.length > 0 ? userName : principalNameParts[0];
    const surname =
      userSurname.length > 0
        ? userSurname
        : principalNameParts[principalNameParts.length - 1];
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createEditOwnerDialog(nameKey, surnameKey, name, surname),
    });
    dialogRef.afterClosed().subscribe((response: Map<string, string>) => {
      const name = response.get(nameKey);
      const surname = response.get(surnameKey);

      const userId = event['entryId'];
      const user: User = this.directoryUsers.filter(
        (value) => value.activeDirectoryUser.id === userId
      )[0];
      if (name || surname) {
        this.tenantService
          .updateUserProfile(
            userId,
            name ?? user.activeDirectoryUser.name,
            surname ?? user.activeDirectoryUser.surname,
            this.getRoleFromTokenClaim()
          )
          .subscribe((response) => {
            user.activeDirectoryUser.name = response.body.name;
            user.activeDirectoryUser.surname = response.body.surname;
            this.initializeUserTableData();
          });
      }
    });
  }

  public deleteUser(event) {
    const dialogRef = this.dialog.open(TableDialogComponent, {
      data: createDeleteUserDialog(),
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 200) {
        this.tenantService.deleteUser(
          event['entryId'],
          TokenUtil.getTenantFromToken(localStorage.getItem('id-token'))
        );
      }
    });
  }

  private initializeUserTableData() {
    this.userData.next({
      tableRows: this.directoryUsers.map((user) => {
        return {
          data: [
            {
              text:
                user.activeDirectoryUser.name.length > 0 &&
                user.activeDirectoryUser.surname.length > 0
                  ? `${user.activeDirectoryUser.name} ${user.activeDirectoryUser.surname}`
                  : user.activeDirectoryUser.appRole.principalDisplayName,
            },
            {
              text: user.activeDirectoryUser.email,
            },
            {
              text: this.translate.instant(
                'usersRoles.table.dialog.role.' +
                  user.activeDirectoryAppRole.displayName
              ), //TODO replace displayname here
            },
          ],
          entryId: user.activeDirectoryUser.id,
        } as TableRow;
      }),
      columnHeaders: [
        {
          title: 'usersRoles.table.title.name',
          filterable: false,
          sortable: true,
        },
        {
          title: 'usersRoles.table.title.email',
          filterable: false,
          sortable: true,
        },
        {
          title: 'usersRoles.table.title.role',
          filterable: false,
          sortable: true,
        },
      ],
      editable: true,
      itemDeletion: true,
      includesCustomButton: false,
      hasSingleCustomButton: false,
      pagination: true,
    });
  }

  public userTableData(): Observable<TableData> {
    return this.userData.asObservable();
  }

  private getRoleFromTokenClaim(): string {
    const claim = TokenUtil.getClaimFromToken(
      localStorage.getItem('id-token'),
      'roles'
    )[0];
    if (claim === 'Dashboard.Editor') {
      return 'f783c54f-06ad-4007-a093-3e072ce3bdea'; //Editor
    }
    if (claim === 'Dashboard.Read') {
      return 'e3f3634c-d4fb-4803-ae3b-208bb9f0e161'; //Analyst
    }

    if (claim === 'Dashboard.Manager') {
      return '1adc8f8c-d330-4d14-88e4-61c1ccf93e62'; //Manager
    }

    if (claim === 'Dashboard.Admin') {
      return '62e51e31-d6f5-4637-aa8e-62a4199e4be6'; //Admin
    }

    if (claim === 'Dashboard.Owner') {
      return 'e28d1237-b120-47af-8238-7e0d144489cd'; //Owner
    }
    if (claim === 'Admin.All') {
      return '0bc1e533-530a-4049-9e72-09ff09c350a1'; //supervisor
    }
    return '';
  }
}
