import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenUtil } from 'src/app/utils/token-util';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { Office } from '../../models/office';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class OfficeHttpService {
  private apiService: ApiService = inject(ApiService);

  getOffices(): Observable<Office[]> {
    return this.apiService
      .get({
        path: `${
          APIConst.SUPERVISOR_OFFICE + this.apiService.requestParamsObj({})
        }`,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ list }) =>
          list.map((office) => {
            return {
              id: office.id,
              zip: office.officeZip,
              city: office.officeCity,
              street: office.officeStreet,
              countryCode: office.officeCountryCode,
            } as Office;
          })
        )
      );
  }
  getAllOfficesFor(tenantID: string): Observable<Office[]> {
    return this.apiService
      .get({
        path: `${APIConst.SUPERVISOR_OFFICE + '/' + tenantID}`,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ list }) =>
          list.map((office: Office) => {
            return {
              id: office.id,
              zip: office['officeZip'],
              city: office['officeCity'],
              street: office['officeStreet'],
              tenantID: tenantID,
              countryCode: office['officeCountryCode'],
            } as Office;
          })
        )
      );
  }

  addOffice(office: Office) {
    return this.apiService.post({
      path: `${APIConst.SUPERVISOR_OFFICE + '/' + office.tenantID}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        officeZip: office.zip,
        officeStreet: office.street,
        officeCity: office.city,
        officeCountryCode: office.countryCode,
      },
    });
  }

  updateOffice(office: Office) {
    return this.apiService.put({
      path: `${
        APIConst.SUPERVISOR_OFFICE +
        '/' +
        TokenUtil.getTenantFromToken(localStorage.getItem('id-token'))
      }`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        id: office.id,
        officeZip: office.zip,
        officeStreet: office.street,
        officeCity: office.city,
        officeCountryCode: office.countryCode,
      },
    });
  }

  deleteOffice(officeID: string) {
    return this.apiService.delete({
      path: `${APIConst.SUPERVISOR_OFFICE + '/' + officeID}`,
      endp: environment.apiPath,
      sendIDToken: true,
    });
  }
}
