import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication';
import { ApiRoleType } from '../models/enums/api-roles.enum';
import { AdminComponent } from '../pages/admin/admin.component';
import { LoginComponent } from '../pages/login/login.component';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminDashboardGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate() {
    const roles = this.authenticationService.getAccessTokenRoles();
    const containsAdminClaim = roles.includes(ApiRoleType.AdminAccess);
    if (containsAdminClaim) {
      return true;
    }
    LocalStorageService.updatePreferedRoute(AdminComponent.route);
    this.router.navigate([LoginComponent.route]);
    return false;
  }
}
