import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectInputValue } from 'src/app/models/survey/select-input-value';
import { createLogicalAnd } from 'typescript';

@Component({
  selector: 'app-select-configurator',
  templateUrl: './select-configurator.component.html',
  styleUrls: ['./select-configurator.component.scss']
})
export class SelectConfiguratorComponent implements OnInit {
  @Output() selectDefined = new EventEmitter<any>();
  @Input() identifier: number = 0;
  @Input() value: any = null;
  @Input() valid: boolean = true;
  selectStyle: string = "checkbox-group";
  isMandatory: boolean = false;
  selectQuestionDe: string = "";
  selectQuestionEn: string = "";
  optionSets: SelectInputValue[] = [];


  ngOnInit() {
    console.log(this.value)
    if (this.value.options) {
      // this.identifier++;
      this.isMandatory = this.value.required;
      this.selectStyle = this.value.options.style;
      // Check if 'de' and 'en' keys exist in the 'label' map
      if (this.value.label && this.value.label.has('de')) {
        this.selectQuestionDe = this.value.label.get('de');
      }
      if (this.value.label && this.value.label.has('en')) {
        this.selectQuestionEn = this.value.label.get('en');
      }
      if (this.value.options && this.value.options.values) {
        this.optionSets = this.value.options.values.map((option) => {
          return {
            key: option,
            value: new Map<string, string>([
              ['de', option.value.de],
              ['en', option.value.en]
            ]),
            identifierEquals(identifier) { return true },
          };
        });
      }
    } else this.selectStyle = this.value;
    if (!this.value.options?.values || !this.value.options) {
      if (this.selectStyle === 'radiobutton-group') {
        for (let i = 0; i < 2; i++) {
          this.addSelectSet();
        }
      }
      else this.addSelectSet();
    }
  }

  isMandatoryOnChange(e) {
    this.isMandatory = e.detail.checked;
    this.selectDefined.emit(this.getSelectParams());
  }

  addSelectSet() {
    const newSelectSet: SelectInputValue = {
      key: this.generateRandomId(),
      value: new Map<string, string>([['de', ''], ['en', '']]), // Initialize value
      identifierEquals(identifier) { return true },
    };
    this.optionSets.push(newSelectSet);
  }

  removeRatingSet(index: number) {
    this.optionSets.splice(index, 1);
    this.getSelectParams();
  }

  moveOptionSetForward(index: number): void {
    if (index < this.optionSets.length - 1) {
      [this.optionSets[index], this.optionSets[index + 1]] =
        [this.optionSets[index + 1], this.optionSets[index]];
      this.getSelectParams();
    }
  }
  
  moveOptionSetBackward(index: number): void {
    if (index > 0) {
      [this.optionSets[index], this.optionSets[index - 1]] =
        [this.optionSets[index - 1], this.optionSets[index]];
      this.getSelectParams();
    }
  }


  selectQuestionDeAdd($event: any) {
    this.selectQuestionDe = $event.detail.value;
    this.selectDefined.emit(this.getSelectParams());


  }
  selectQuestionEnAdd($event: any) {
    this.selectQuestionEn = $event.detail.value;
    this.selectDefined.emit(this.getSelectParams());


  }

  selectStyleChange($event: any) {
    this.selectStyle = $event.detail.value;
    this.selectDefined.emit(this.getSelectParams());
    this.optionSets = [];
    if (this.selectStyle === 'radiobutton-group') {
      for (let i = 0; i < 2; i++) {
        this.addSelectSet();
      }
    }
    else this.addSelectSet();
  }

  selectRadioLabelDeAdd($event: any, index: number) {
    const value = $event.detail.value;
    this.optionSets[index].value.set('de', value);
    this.selectDefined.emit(this.getSelectParams());
  }

  selectRadioLabelEnAdd($event: any, index: number) {
    const value = $event.detail.value;
    this.optionSets[index].value.set('en', value);
    this.selectDefined.emit(this.getSelectParams());
  }

  generateRandomId(): string {
    return Math.random().toString(36).substring(2);
  }


  private getSelectParams() {
    return {
      identifier: 'select-' + this.identifier,
      label: new Map<string, string>([
        ['de', this.selectQuestionDe],
        ['en', this.selectQuestionEn],
      ]),
      options: {
        max_length: null,
        style: this.selectStyle,
        count: null,
        labels: null,
        values: this.optionSets,
      },
      type: 'select',
      required: this.isMandatory,
    };
  }
}

