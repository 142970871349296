import { Pipe, PipeTransform } from '@angular/core';
import { ReportGrouped } from '../models/report';

@Pipe({
  name: 'countRecords',
})
export class CountRecordsPipe implements PipeTransform {
  constructor() {
  }

  transform(reports: ReportGrouped[], field: string): number {
    return reports.reduce((acc, report) => acc + report[field], 0);
  }
}
