import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';
import { Office } from 'src/app/models/office';
import { ReportGrouped } from 'src/app/models/report';
import { EcoTransportTypes } from '../../models/const/common';
import { Reason } from '../../models/enums/reason.enum';
import { LoaderService } from '../../services/loader.service';
import { OfficeService } from '../../services/office.service';
import { ReportService } from '../../services/report.service';
import { UnitConverter } from '../../utils/unit-converter';

interface OverviewReport {
  office: Office;
  totalEmissions: number;
  commuteEmissions: number;
  businessEmissions: number;
  commuteDistance: number;
  businessDistance: number;
  ecologicalCommuteDistance;
  ecologicalBusinessDistance;
  reportCount;
}

@Component({
  selector: 'app-office-table',
  templateUrl: './office-table.component.html',
  styleUrls: ['./office-table.component.scss'],
})
export class OfficeTableComponent implements OnInit {
  private formBuilder: FormBuilder = inject(FormBuilder);

  @Input() filter: FormGroup;
  reasons = Reason;
  officeSearch = this.formBuilder.group({
    search: null,
  });
  offices: SelectItem[];
  private demo: boolean = false;

  constructor(
    private officeService: OfficeService,
    public reportService: ReportService,
    private router: Router,
    public translateService: TranslateService,
    public loaderService: LoaderService,
    private route: ActivatedRoute
  ) {
    registerLocaleData(de);
    registerLocaleData(en);
    this.route.data.subscribe((data) => {
      this.demo = data['demo'];
    });
  }

  public reports: ReportGrouped[] = [];
  public overviewReports: OverviewReport[] = [];

  public avg(value: number, overviewReport: OverviewReport) {
    return value / overviewReport.reportCount;
  }

  ngOnInit(): void {
    this.officeService.getAllOffices().subscribe((offices) => {
      this.reportService.overviewReports$.subscribe((groupedReports) => {
        this.overviewReports = [];
        this.reports = groupedReports;
        if (groupedReports.length === 0) {
          this.overviewReports = [];
        } else {
          groupedReports.forEach((groupedReport) => {
            const office: Office = groupedReport.office;
            if (office) {
              const officeId: string = office.id;
              const matchIndex = this.overviewReports.findIndex(
                (value) => value.office.id === officeId
              );
              if (matchIndex === -1) {
                const newEntry: OverviewReport = {
                  office: office,
                  businessDistance:
                    groupedReport.reason === Reason.BusinessTrip
                      ? groupedReport.distance
                      : 0,
                  businessEmissions:
                    groupedReport.reason === Reason.BusinessTrip
                      ? groupedReport.emissions
                      : 0,

                  commuteDistance:
                    groupedReport.reason === Reason.Commute
                      ? groupedReport.distance
                      : 0,
                  commuteEmissions:
                    groupedReport.reason === Reason.Commute
                      ? groupedReport.emissions
                      : 0,

                  ecologicalBusinessDistance:
                    groupedReport.reason === Reason.BusinessTrip &&
                    EcoTransportTypes.includes(groupedReport.transportType)
                      ? groupedReport.distance
                      : 0,
                  ecologicalCommuteDistance:
                    groupedReport.reason === Reason.Commute &&
                    EcoTransportTypes.includes(groupedReport.transportType)
                      ? groupedReport.distance
                      : 0,

                  reportCount: groupedReport.reportCount,
                  totalEmissions: groupedReport.emissions,
                };

                this.overviewReports.push(newEntry);
              } else {
                const updatedEntry: OverviewReport = {
                  ...this.overviewReports[matchIndex],
                };
                if (groupedReport.reason === Reason.BusinessTrip) {
                  updatedEntry.businessDistance += groupedReport.distance;
                  updatedEntry.businessEmissions += groupedReport.emissions;
                  if (EcoTransportTypes.includes(groupedReport.transportType)) {
                    updatedEntry.ecologicalBusinessDistance +=
                      groupedReport.distance;
                  }
                } else if (groupedReport.reason === Reason.Commute) {
                  updatedEntry.commuteDistance += groupedReport.distance;
                  updatedEntry.commuteEmissions += groupedReport.emissions;
                  if (EcoTransportTypes.includes(groupedReport.transportType)) {
                    updatedEntry.ecologicalCommuteDistance +=
                      groupedReport.distance;
                  }
                }
                updatedEntry.totalEmissions += groupedReport.emissions;
                updatedEntry.reportCount += groupedReport.reportCount;
                this.overviewReports[matchIndex] = updatedEntry;
              }
            }
          });
          this.overviewReports.map((overviewReport: OverviewReport) => {
            overviewReport.businessDistance =
              overviewReport.businessDistance / overviewReport.reportCount;
            overviewReport.businessEmissions =
              overviewReport.businessEmissions / overviewReport.reportCount;
            overviewReport.commuteDistance =
              overviewReport.commuteDistance / overviewReport.reportCount;
            overviewReport.commuteEmissions =
              overviewReport.commuteEmissions / overviewReport.reportCount;
            overviewReport.ecologicalBusinessDistance =
              overviewReport.ecologicalBusinessDistance /
              overviewReport.reportCount;
            overviewReport.ecologicalCommuteDistance =
              overviewReport.ecologicalCommuteDistance /
              overviewReport.reportCount;
            overviewReport.totalEmissions =
              overviewReport.totalEmissions / overviewReport.reportCount;
            return overviewReport;
          });
        }
      });
    });
  }

  public reportSortFunction(event: any) {
    const data = event.data;
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  formatValue(value: number) {
    return UnitConverter.getFormattedWeight(value);
  }

  suggestOffices(event: any): void {
    this.officeService
      .getByCity(event.query)
      .pipe(take(1))
      .subscribe((offices) => {
        const grouped = offices.reduce((r, o) => {
          (r[o.city] = r[o.city] || []).push(o);
          return r;
        }, {});
        const officeArray: SelectItem[] = [];
        Object.keys(grouped).forEach((key) => {
          officeArray.push({
            label: key,
            value: { id: undefined, city: key, street: undefined },
            title: key,
          });
          grouped[key].forEach((office) =>
            officeArray.push({
              label: office.street,
              value: office,
            })
          );
        });
        this.offices = officeArray;
      });
  }

  searchOffices(): void {
    if (
      this.officeSearch.get('search').value !== null &&
      (this.officeSearch.get('search').value as any).label === undefined
    ) {
      const foundOffice = this.offices.find(
        (office) => office.label === this.officeSearch.get('search').value
      );
      if (foundOffice) {
        this.filter.get('office').setValue(foundOffice.value);
      } else {
        this.officeSearch.get('search').setErrors({ noOffice: true });
      }
    } else {
      this.filter
        .get('office')
        .setValue((this.officeSearch.get('search').value as any).value);
    }
  }

  navigateToDetail(event: any): void {
    //if (!this.demo) {
    this.router.navigate(['/detail', event]);
    //}
  }

  resetOfficeSearch(): void {
    this.officeSearch.get('search').reset();
    this.searchOffices();
  }
}
