import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeTableComponent } from './office-table.component';
import { TableModule } from 'primeng/table';
import { PipeModule } from '../../pipes/pipe.module';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RouterModule } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { LoaderModule } from '../loader/loader.module';



@NgModule({
  declarations: [
    OfficeTableComponent
  ],
  exports: [
    OfficeTableComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    PipeModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    AutoCompleteModule,
    TranslateModule,
    RouterModule,
    CheckboxModule,
    LoaderModule,
    ReactiveFormsModule
  ]
})
export class OfficeTableModule { }
