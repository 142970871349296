<scale-breadcrumb separator=">" class="scale-breadcrumb-item">
    <a href="/dashboard/feedback">Feedback</a>
    <ng-container *ngIf="survey; else newSurvey">
        <a>{{'feedbacks.title.surveys.editSurvey' | translate}}</a>
    </ng-container>
    <ng-template #newSurvey>
        <a>{{'feedbacks.title.surveys.newSurvey' | translate}}</a>
    </ng-template>
</scale-breadcrumb>
<div class="page-content">
    <div class="title">
        <h2 class="intro-survey">{{'feedbacks.title.surveys.newSurvey'|translate}}</h2>
    </div>
    <div class="status-active-wrapper">
        <p> {{'feedbacks.title.surveys.surveyIsActive'|translate}}
            <scale-switch [checked]="isActive" size="small" class="scale-switch-item margin-left"
                (scaleChange)="onIsActiveChanged()">
            </scale-switch>
        </p>

        <div class="margin-top-sm p-col-8">
            <scale-notification heading="{{'admin.usersRoles.description'|translate}}" opened>
            </scale-notification>
        </div>
        <div class="margin-top-xs  p-col-8">
            <scale-notification heading="{{'feedbacks.title.surveys.allFieldsRequired'|translate}}" opened
                variant="warning">
            </scale-notification>
        </div>

    </div>
    <div class="form-container margin-top-md">
        <form [formGroup]="formGroup" class="p-col-6">
            <div class="p-grid p-fluid">
                <label for="selected-options"
                    class="label-title">{{'feedbacks.title.surveys.companyNames'|translate}}</label>

                <div class="p-col-12 pos-relative form-item">
                    <span for="selectedOptions"
                        class="static-label">{{'feedbacks.title.surveys.companyNames'|translate}}</span>
                    <p-multiSelect [options]="myOptions" formControlName="selectedOptions" class="multiselect-item"
                        id="selected-options" [placeholder]="'feedbacks.title.surveys.companyNames'|translate"
                        optionLabel="name" display="chip"
                        [ngClass]="{'multiselect-invalid': !(formGroup.controls.selectedOptions.value.length || valid)}">
                    </p-multiSelect>
                    <p class="helper-text-error"
                        [ngClass]="{'hidden': (formGroup.controls.selectedOptions.value.length  || valid)}">
                        <scale-icon-alert-error class="margin-right-small" size="11" accessibility-title="error" />
                        {{'feedbacks.title.surveys.required'|translate}}
                    </p>

                    <div class="p-grid no-margin no-padding-top chips-label p-multiselect-panel">
                        <div class="p-col chip-container"
                            *ngFor="let control of formGroup.controls.selectedOptions.value">
                            <scale-tag dismissable
                                (scaleClose)="removeFromGroup(control.name, formGroup.controls.selectedOptions)">{{control.name}}</scale-tag>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="p-grid p-col-6 no-margin">
            <div class="p-col-6 pos-relative date-wrapper">
                <label class="label-title" for="start-date">{{'feedbacks.date.Start'|translate}}</label>
                <scale-date-picker label="{{'feedbacks.date.Start' | translate}}" id="start-date"
                    class="scale-date-picker" [attr.value]="customStartDate" [min]="dateToString(minDateRule)"
                    [max]="dateToString(maxDateRule)" (scaleChange)="scaleChangeStart($event)"
                    [invalid]="!(customStartDate || valid)"
                    [attr.helper-text]="(customStartDate || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"></scale-date-picker>
            </div>
            <div class="p-col-6 pos-relative date-wrapper">
                <label class="label-title" for="end-date">{{'feedbacks.date.End'|translate}} </label>
                <scale-date-picker label="{{'feedbacks.date.End' | translate}}" [attr.value]="customEndDate" show
                    class="scale-date-picker" id="end-date" [min]="customStartDate" [max]="dateToString(endDateMaxRule)"
                    (scaleChange)="scaleChangeEnd($event)"></scale-date-picker>
            </div>
        </div>

    </div>



    <div class="p-grid p-fluid margin-top-sm">
        <div class="p-col-12 p-sm-6 p-xs-6  pos-relative">
            <label class="label-title" for="survey-name-de">{{'feedbacks.title.surveys.surveyName'|translate}}
                (DE)</label>
            <scale-text-field id="text-field" label="{{'feedbacks.title.surveys.surveyNamePlaceholderDe'|translate}}"
                [attr.value]="surveyNameDE" id="survey-name-de" max-length="80" counter
                [attr.helper-text]="(surveyNameDE || valid)  ? ('feedbacks.title.surveys.maxCharsTitle'|translate) : 'feedbacks.title.surveys.required'|translate"
                rows="5" (scaleChange)="handleSurveyTitle($event,'de','surveyName')"
                [invalid]="!(surveyNameDE || valid)"></scale-text-field>
        </div>
        <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
            <label class="label-title" for="survey-name-en">{{'feedbacks.title.surveys.surveyName'|translate}}
                (EN)</label>
            <scale-text-field id="text-field" label="{{'feedbacks.title.surveys.surveyNamePlaceholderEn'|translate}}"
                [attr.value]="surveyNameEN" id="survey-name-en" max-length="80" counter
                [attr.helper-text]="(surveyNameEN || valid)  ? ('feedbacks.title.surveys.maxCharsTitle'|translate) : 'feedbacks.title.surveys.required'|translate"
                rows="5" (scaleChange)="handleSurveyTitle($event,'en','surveyName')"
                [invalid]="!(surveyNameEN || valid)"></scale-text-field>
        </div>
    </div>
    <div class="p-grid p-fluid margin-top-sm">
        <div class="p-col-12 p-sm-6 p-xs-6  pos-relative">
            <label class="label-title" for="description-de">{{'feedbacks.title.surveys.surveyDescription'|translate}}
                (DE)</label>
            <scale-textarea label="{{'feedbacks.title.surveys.surveyDescriptionDe'|translate}}" max-length="520" counter
                [attr.value]="surveyDescriptionDE" id="description-de"
                [attr.helper-text]="(surveyDescriptionDE || valid)  ? ('feedbacks.title.surveys.maxChars'|translate) : ('feedbacks.title.surveys.required'|translate) "
                rows="5" (scaleChange)="handleSurveyTitle($event,'de','surveyDescription')"
                [invalid]="!(surveyDescriptionDE || valid)"></scale-textarea>
        </div>
        <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
            <label class="label-title" for="description-en">{{'feedbacks.title.surveys.surveyDescription'|translate}}
                (EN)</label>
            <scale-textarea label="{{'feedbacks.title.surveys.surveyDescriptionEn'|translate}}" max-length="520" counter
                [attr.value]="surveyDescriptionEN" id="description-en"
                [attr.helper-text]="(surveyDescriptionEN || valid)  ? ('feedbacks.title.surveys.maxChars'|translate) : ('feedbacks.title.surveys.required'|translate)"
                rows="5" (scaleChange)="handleSurveyTitle($event,'en','surveyDescription')"
                [invalid]="!(surveyDescriptionEN || valid)"></scale-textarea>
        </div>
    </div>

    <!-- Page Steps -->

    <div class="dynamic-element-container">
        <div *ngFor="let stepElement of bufferPages; let i = index" class="dynamic-element">
            <scale-divider class="scale-divider-item-top  margin-top-xl margin-bottom-xl"></scale-divider>

            <div class="step-title  margin-top-xl">
                <h2>{{'feedbacks.title.surveys.step'|translate}} {{i + 1}}</h2>
                <!-- Buttons for moving up/down del-->
                <div class="buttons-step-container">
                    <scale-button *ngIf="i+1 < bufferPages.length " icon-only variant="ghost"
                        (click)="moveStepForward(i)" class="black-ghost-color"><scale-icon-navigation-collapse-down
                            accessibility-title="down" size="24" />
                    </scale-button>
                    <scale-button *ngIf="i > 0" icon-only variant="ghost" (click)="moveStepBackward(i)"
                        class="black-ghost-color"><scale-icon-navigation-collapse-up accessibility-title="up"
                            size="24" />
                    </scale-button>
                    <div class="vertical-divider"></div>
                    <scale-button *ngIf="bufferPages.length > 1" icon-only (click)="openModal(i)" variant="ghost"
                        class="black-ghost-color"><scale-icon-action-remove
                            attr.accessibility-title="{{'feedbacks.title.buttons.remove' | translate}}" size="24" />
                    </scale-button>
                </div>
            </div>
            <!-- modal window -->
            <scale-modal heading="{{'feedbacks.title.buttons.deleteStepTitle' | translate}}" id="modal"
                [opened]="showDeleteModal" (scaleClose)="closeDeleteConfirmationModal()" size="small">
                <p> {{'feedbacks.title.buttons.deleteStep' | translate}}</p>
                <scale-button slot="action" variant="secondary" (click)="closeDeleteConfirmationModal()">
                    {{'feedbacks.title.buttons.cancel' | translate}}
                </scale-button>
                <scale-button class="margin-left" slot="action" (click)="removePageElement()">
                    {{'feedbacks.title.buttons.delete' | translate}} </scale-button>
            </scale-modal>
            <!-- steps -->
            <div class="p-grid p-fluid ">
                <div class="p-col-12 p-sm-6 p-xs-6  pos-relative margin-top-sm">
                    <label class="label-title" for="text-field-de"> {{'feedbacks.title.step.headline' | translate}}
                        (DE)</label>
                    <scale-text-field id="text-field-de"
                        attr.label="{{'feedbacks.title.step.headline' | translate}} (DE)" rows="1"
                        [attr.value]="survey ? survey.pages[i].section.get('de') : ''"
                        [attr.helper-text]=" (pageTitleDE || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        [invalid]=" !(pageTitleDE || valid)"
                        (scaleChange)="stepNameAdd($event,'de',i)"></scale-text-field>
                </div>
                <div class="p-col-12 p-sm-6 p-xs-6 pos-relative margin-top-sm">
                    <label class="label-title" for="text-field-en">{{'feedbacks.title.step.headline' | translate}}
                        (EN)</label>
                    <scale-text-field id="text-field-en"
                        attr.label="{{'feedbacks.title.step.headline' | translate}} (EN)" rows="1"
                        [attr.value]="survey ? survey.pages[i].section.get('en') : ''"
                        [attr.helper-text]="(pageTitleEN || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        [invalid]="  !(pageTitleEN || valid)  "
                        (scaleChange)="stepNameAdd($event,'en',i)"></scale-text-field>
                </div>
            </div>
            <!-- forms -->
            <div *ngFor="let input of stepElement.inputs; let j = index"
                class="form-elements p-grid no-margin margin-top-xxl custom-form">
                <div class="p-col-12">
                    <div class="forms-button">
                        <div class="forms-button-up-down">
                            <scale-button *ngIf=" j > 0 " class="black-ghost-color" variant="ghost" icon-only
                                (click)="moveFormBackward(i, j)"><scale-icon-navigation-collapse-up
                                    accessibility-title="up" size="24" /></scale-button>
                            <scale-button *ngIf=" j+1 < stepElement.inputs.length" class="black-ghost-color"
                                variant="ghost" icon-only
                                (click)="moveFormForward(i, j)"><scale-icon-navigation-collapse-down
                                    accessibility-title="down" size="24" /></scale-button>
                            <div class="vertical-divider-small"></div>

                        </div>
                        <scale-button class="black-ghost-color" variant="ghost" icon-only
                            (click)="removeInput(i, j)"><scale-icon-action-remove
                                attr.accessibility-title="{{'feedbacks.title.buttons.remove' | translate}}" size="24" />
                        </scale-button>
                    </div>
                    <div *ngIf="input.type === 'rating'">
                        <app-rating-configurator (ratingDefined)="handleRating($event,i)"
                            [value]="survey ? input : input.options.style" [valid]="valid"
                            [identifier]="j+1"></app-rating-configurator>
                    </div>
                    <div *ngIf="input.type === 'select'">
                        <app-select-configurator (selectDefined)="handleSelect($event,i)"
                            [value]="survey ? input : input.options.style" [valid]="valid"
                            [identifier]="j+1"></app-select-configurator>
                    </div>
                    <div *ngIf="input.type === 'text'">
                        <app-text-question-configurator [value]="survey ? input : ''" [valid]="valid"
                            (textQuestionDefined)="handleTextQuestion($event,i)"
                            [identifier]="j+1"></app-text-question-configurator>
                    </div>

                </div>
            </div>
            <!-- form add button -->
            <div class="form-elements-add-container p-grid margin-top-xl">
                <!-- <scale-divider class="p-col-3"></scale-divider>
                <div class="form-elements-add-wrapper p-col-6">
                    <p class="bold"> {{'feedbacks.title.buttons.addFormHeadline' | translate}}</p>
                    <div class="form-elements-add p-grid p-fluid ">
                        <scale-dropdown-select (scale-change)="stepElement.type=$event.detail.value" value="rating"
                            class="form-elements-dropdown p-col-12 p-sm-5 p-xs-4  pos-relative">
                            <scale-dropdown-select-item class="scale-dropdown-select-item"
                                value="rating">Rating</scale-dropdown-select-item>
                            <scale-dropdown-select-item class="scale-dropdown-select-item"
                                value="select">Select</scale-dropdown-select-item>
                            <scale-dropdown-select-item class="scale-dropdown-select-item" value="text">Text
                            </scale-dropdown-select-item>
                        </scale-dropdown-select>
                        <scale-button class="p-col-12 p-sm-5 p-xs-4  pos-relative" variant="secondary"
                            (click)="addInput(i, stepElement.type)"><scale-icon-action-add accessibility-title="add" />
                            {{'feedbacks.title.buttons.addForm' | translate}}</scale-button>
                    </div>
                </div> -->
                <div class="add-new-step-button-wrapper">
                    <scale-menu-flyout>
                        <scale-button slot="trigger" class="add-new-step-button disable-button"><scale-icon-action-add
                                accessibility-title="add" /> {{'feedbacks.title.buttons.addForm' | translate}}
                        </scale-button>
                        <scale-menu-flyout-list>

                            <scale-menu-flyout-item>Rating
                                <scale-menu-flyout-list slot="sublist">
                                    <scale-menu-flyout-item
                                        (click)="addInput(i, 'rating','stars')">{{'feedbacks.title.rating.stars'|translate}}</scale-menu-flyout-item>
                                    <scale-menu-flyout-item
                                        (click)="addInput(i, 'rating','points')">{{'feedbacks.title.rating.points'|translate}}</scale-menu-flyout-item>
                                </scale-menu-flyout-list>
                            </scale-menu-flyout-item>

                            <scale-menu-flyout-item>Select
                                <scale-menu-flyout-list slot="sublist">
                                    <scale-menu-flyout-item
                                        (click)="addInput(i, 'select','radiobutton-group')">{{'feedbacks.title.select.radioButtonGroup'|translate}}</scale-menu-flyout-item>
                                    <scale-menu-flyout-item
                                        (click)="addInput(i, 'select','checkbox-group')">{{'feedbacks.title.select.checkboxGroup'|translate}}</scale-menu-flyout-item>
                                </scale-menu-flyout-list>
                            </scale-menu-flyout-item>

                            <scale-menu-flyout-item (click)="addInput(i, 'text')">Text</scale-menu-flyout-item>
                            <scale-menu-flyout-divider></scale-menu-flyout-divider>
                            <scale-menu-flyout-item (click)="addStep(i+1)"> {{'feedbacks.title.buttons.addStep' |
                                translate}}</scale-menu-flyout-item>

                        </scale-menu-flyout-list>
                    </scale-menu-flyout>
                </div>
                <div *ngIf="i == bufferPages.length-1" class="empty-space"></div>
            </div>
        </div>
    </div>
    <!-- add step
    <div class="add-new-step-button-wrapper">
        <p *ngIf="bufferPages.length != 0" class="bold">{{'feedbacks.title.surveys.or'|translate}}</p>
        <scale-button (click)="addStep('i')" class="add-new-step-button disable-button"><scale-icon-action-add
                accessibility-title="add" />
            {{'feedbacks.title.buttons.addStep' | translate}}
        </scale-button>
    </div> -->
</div>
<!-- bottom-panel -->
<div #scrollContainer class="bottom-panel" [ngClass]="{'bottom-panel-fixed': isPanelFixed}">
    <div class="left-buttons">
        <scale-button (click)="openPreviewSurveyModal()" variant="secondary">
            <scale-icon-action-show-password class="margin-right"></scale-icon-action-show-password>
            {{'feedbacks.title.surveys.surveyPreview'|translate}}
        </scale-button>
    </div>
    <div class="right-buttons">
        <scale-button href="dashboard/feedback"
            class="disable-button">{{'feedbacks.title.surveys.discardChanges'|translate}}</scale-button>
        <scale-button class="save-button margin-left"
            (click)="saveChanges()">{{'feedbacks.title.surveys.saveChanges'|translate}}</scale-button>
    </div>
</div>
<!-- scale-notifications -->
<div *ngIf="successSaved" class="scl-toast-stack">
    <scale-notification heading="{{'feedbacks.notification.successfullySaved'|translate}}" type="toast" dismissible
        delay="3000" variant="success" opened>
        <span slot="text">{{'feedbacks.notification.successfullySavedMessage'|translate}}</span>
    </scale-notification>
</div>
<div class="notifications">
    <div class="scl-toast-stack">
        <scale-notification *ngIf="inValidMessage" heading="{{'feedbacks.notification.warning'|translate}}"
            variant="danger" type="toast" dismissible delay="7000" opened>
            <span slot="text">{{'feedbacks.notification.fillRequiredFieldsWarning'|translate}}</span>
        </scale-notification>
        <scale-notification *ngIf="missingFirstQuestion" heading="{{'feedbacks.notification.warning'|translate}}"
            variant="warning" type="toast" dismissible delay="7000" opened>
            <span slot="text">{{'feedbacks.notification.fillRequiredQuestion'|translate}}</span>
        </scale-notification>
    </div>
</div>

<!-- Surveypreview modalWindow -->
<div class="modal-wripper">
    <app-survey-preview-modal [showModal]="showPreviewSurveyModal" (isModalOpen)="showPreviewSurveyModal = $event"
        [previewMode]="'Survey Preview'" [surveyResults]="'surveyResults'"
        [survey]="previewSurvey"></app-survey-preview-modal>
</div>

<!-- Status Active modalWindow -->
<scale-modal id="modal" heading="{{'feedbacks.title.toggle.note'|translate}}" [opened]="openModalActvieStatus"
    (scale-close)="afterCloseModal()" size="small">
    <p>{{'feedbacks.title.toggle.confirmationActiveInEdit'|translate}}</p>
    <scale-button slot="action" variant="secondary" (click)="closeModal()" class="margin-right">
        {{'feedbacks.title.buttons.cancel' |translate}}
    </scale-button>
    <scale-button slot="action" (click)="confirmToggle()" class="padding-left-xs">
        {{'feedbacks.title.toggle.setAsActive'|translate}} </scale-button>
</scale-modal>