<div class="license-container">
    <ng-container *ngIf="fetching; else licenseView">
        <app-loader></app-loader>
    </ng-container>
    <ng-template #licenseView>
        <ng-container *ngIf="(licenses.length > 0); else licenseNotAvaible">
            <div class="license-entry" *ngFor="let license of licenses">
                <div class="license-information">
                    <div class="license">
                        <h5 class="license-name bold">{{license.contractName}}</h5>
                        <app-license-status [licenseStatus]="license.status"></app-license-status>
                    </div>
                    <div class="license-data">
                        <img src="assets/svg/calendar.svg">
                        <span class="bold">Active: <span class="unbold">{{license.contractFrom | date: 'dd.MM.yyyy'}} -
                                {{license.contractTo | date: 'dd.MM.yyyy'}}</span></span>
                        <span class="bold">Activation codes total: <span
                                class="unbold">{{license.amountOfCodes}}</span></span>
                    </div>
                </div>
                <div class="functionality">
                    <app-button *ngIf="statusIsActive(license)" [text]="'Increase codes amount'"
                        [icon]="'assets/svg/add.svg'" [cancelButtonStyle]="true" [smallButtonStyle]="true"
                        [fontSizeFactor]=".8" (click)="showIncreaseCodeAmountsDialog(license)"></app-button>
                    <app-button *ngIf="statusIsHold(license)" [text]="'Edit the license'" [icon]="'assets/svg/edit.svg'"
                        [cancelButtonStyle]="true" [smallButtonStyle]="true" [fontSizeFactor]=".8"
                        (click)="showEditLicenseDialog(license)"></app-button>
                    <app-button *ngIf="statusIsHold(license)" [text]="'Delete the license'"
                        [icon]="'assets/svg/delete_outline.svg'" [cancelButtonStyle]="true" [smallButtonStyle]="true"
                        [fontSizeFactor]=".8" (click)="showDeleteLicenseDialog(license)"></app-button>
                    <app-button *ngIf="statusIsActive(license) || statusIsOutdated(license)" [text]="'Renew license'"
                        [icon]="'assets/svg/reload.svg'" [cancelButtonStyle]="true" [smallButtonStyle]="true"
                        [fontSizeFactor]=".8" (click)="showRenewLicenseDialog(license)"></app-button>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #licenseNotAvaible>
        <div class="no-license-container">
            <img [src]="'assets/svg/info_magenta.svg'">
            <span>{{'license.notAvailable'|translate}}</span>
        </div>
    </ng-template>
    <app-button [text]="'license.add.title'|translate" [icon]="'assets/svg/add.svg'" [cancelButtonStyle]="true"
        [smallButtonStyle]="true" [fontSizeFactor]=".8" (click)="addLicenseDialog()"></app-button>
</div>