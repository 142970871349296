import { WeekDay } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  AnnualOptions,
  CommuteOptions,
  MonthlyFilter,
  TransportPercentageOptions,
  WeeklyDistance,
  WeeklyEmissions,
  WeeklyFilter,
} from '../../models/const/charts-consts';
import { AllColors, Colors, TotalColors } from '../../models/const/common';
import { Reason } from '../../models/enums/reason.enum';
import { TransportTypeAttributes } from '../../models/enums/transport-type.enum';
import { ReportGrouped } from '../../models/report';
import { LoaderService } from '../../services/loader.service';
import { ReportService } from '../../services/report.service';

class Dataset {
  type: string;
  label: string;
  backgroundColor: string;
  data: number[];
}

export class Data {
  labels: string[];
  datasets: Dataset[];
}

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
})
export class TrendsComponent implements OnInit, OnDestroy {
  screenshotToolTipText: string = '';
  transportTypeOptions: any[];
  selectedType: any;
  quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  years = [new Date().getFullYear() - 1, new Date().getFullYear()];
  destroy$: Subject<boolean> = new Subject<boolean>();
  weekEmissionData: Data;
  weeklyEmissionsOptions;
  weekDistanceData: Data;
  weeklyDistanceOptions;
  commuteData: Data;
  commuteOptions;
  transportPercentageData: Data;
  transportPercentageOptions: typeof TransportPercentageOptions;
  businessData: Data;
  annualData: Data;
  annualOptions;
  filter;
  trendReports$: Observable<{
    trans: any;
    weekly: ReportGrouped[];
    monthlyThisYear: ReportGrouped[];
    monthlyPrevYear: ReportGrouped[];
  }>;
  localPrevYear: ReportGrouped[];
  localThisYear: ReportGrouped[];

  demo: boolean;

  private filterValue: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private reportService: ReportService,
    public loaderService: LoaderService,
    private route: ActivatedRoute
  ) {
    // @ts-ignore
    Chart.registry.getPlugin('tooltip').positioners.custom = (
      elements,
      eventPosition
    ) => {
      return {
        x: eventPosition.x,
        y: eventPosition.y - 50,
      };
    };

    this.route.data.subscribe((data) => {
      this.demo = data['demo'];
    });
    this.weeklyEmissionsOptions = WeeklyEmissions(
      this.translateService.currentLang
    );
    this.weeklyDistanceOptions = WeeklyDistance(
      this.translateService.currentLang
    );
    this.commuteOptions = CommuteOptions(this.translateService.currentLang);
    this.annualOptions = AnnualOptions(this.translateService.currentLang);
    this.filter = this.formBuilder.group({
      from: new Date(
        new Date(new Date().setUTCHours(0, 0, 0, 0)).setFullYear(
          new Date().getFullYear() - 1
        )
      ),
      to: new Date(new Date().setUTCHours(23, 59, 59, 999)),
      company: null,
      autoRecorded: false,
      homeOffice: false,
      causedByCompanyCar: 'All',
    }) as FormGroup;
  }

  ngOnInit(): void {
    this.translateService
      .get('trends.screenshot-tool-tip')
      .subscribe((text) => {
        this.screenshotToolTipText = text;
      });
    let currMonth = new Date().getMonth();
    const months = [...Array(12).keys()].map(() => {
      if (currMonth === 11) {
        currMonth = -1;
      }
      return ++currMonth;
    });

    this.translateService
      .getTranslation(this.translateService.currentLang)
      .subscribe((response) => {
        this.transportPercentageOptions = TransportPercentageOptions(
          this.translateService.currentLang,
          this.translateService.translations[this.translateService.currentLang]
            .common
        );
      });

    const days = [...Array(5).keys()];
    const translations$ = this.translateService.get([
      ...days.map((day) => `common.days.${day}`),
      ...months.map((month) => `common.months.${month}`),
      ...TransportTypeAttributes.map((attr) => 'common.' + attr),
    ]);

    this.trendReports$ = combineLatest([
      translations$,
      this.filter.valueChanges,
    ]).pipe(
      takeUntil(this.destroy$),
      mergeMap(([translations]) => {
        this.transportTypeOptions = TransportTypeAttributes.map((value) => ({
          name: translations['common.' + value],
          value,
        }));
        this.filterValue = this.filter.getRawValue();
        const prevFilterValue = {
          ...this.filterValue,
          from: new Date(new Date().getFullYear() - 1, 0, 1),
          to: new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59, 999),
        };

        this.filterValue.company = this.filterValue.company?.map(
          (company) => company?.id
        );
        return forkJoin({
          trans: of(translations),
          weekly: this.reportService
            .getTrendReports({ ...this.filterValue, ...WeeklyFilter })
            .pipe(
              tap((data) => {
                if (data) {
                  this.weekEmissionData = {
                    labels: months.map(
                      (month) => translations[`common.months.${month}`]
                    ),
                    datasets: this.sumByDayDatasets(
                      'emissions',
                      days,
                      months,
                      data,
                      translations
                    ),
                  };
                  this.weekDistanceData = {
                    labels: months.map(
                      (month) => translations[`common.months.${month}`]
                    ),
                    datasets: this.sumByDayDatasets(
                      'distance',
                      days,
                      months,
                      data,
                      translations
                    ),
                  };
                }
              })
            ),
          monthlyThisYear: this.reportService
            .getTrendReports({
              ...this.filterValue,
              ...MonthlyFilter,
            })
            .pipe(
              tap((data) => {
                if (data) {
                  this.commuteData = {
                    labels: months.map(
                      (month) => translations[`common.months.${month}`]
                    ),
                    datasets: [
                      {
                        type: 'bar',
                        data: months.map(
                          (month) =>
                            data.find((report) => {
                              return (
                                this.monthNames.findIndex(
                                  (name) => name === report.month
                                ) === month && report.reason === Reason.Commute
                              );
                            })?.emissions ?? 0
                        ),
                        label: 'Emissions',
                        backgroundColor: TotalColors[0],
                      },
                    ],
                  };
                  this.businessData = {
                    labels: months.map(
                      (month) => translations[`common.months.${month}`]
                    ),
                    datasets: [
                      {
                        type: 'bar',
                        data: months.map((month) => {
                          const reports: ReportGrouped[] = data.filter(
                            (report) => {
                              return (
                                this.monthNames.findIndex(
                                  (name) => name === report.month
                                ) === month &&
                                report.reason === Reason.BusinessTrip
                              );
                            }
                          );
                          return reports
                            .filter((report) => report !== undefined)
                            .map((report) =>
                              report.emissions !== undefined
                                ? report.emissions
                                : 0
                            )
                            .reduce((acc, current) => acc + current, 0);
                        }),
                        label: 'Emissions',
                        backgroundColor: TotalColors[0],
                      },
                    ],
                  };
                  const transportTypes: string[] = [
                    ...new Set(
                      data.map((report) => report.transportType as string)
                    ),
                  ].filter(
                    (transportType) =>
                      transportType !== undefined &&
                      transportType !== null &&
                      transportType !== ''
                  );

                  this.transportPercentageData = {
                    labels: months.map(
                      (month) => translations[`common.months.${month}`]
                    ),
                    datasets: transportTypes.map((type) => {
                      const transportReports = data.filter(
                        (report) => report.transportType === type
                      );
                      return {
                        type: 'bar',
                        label: translations[`common.${type}`],
                        backgroundColor:
                          AllColors[transportTypes.indexOf(type)],
                        data: months.map((mth) => {
                          const transportCount = transportReports.filter(
                            (report) =>
                              this.monthNames.findIndex(
                                (name) => name === report.month
                              ) === mth
                          ).length;
                          const monthCount = data.filter(
                            (report) =>
                              this.monthNames.findIndex(
                                (name) => name === report.month
                              ) === mth
                          ).length;
                          return monthCount !== 0
                            ? (transportCount / monthCount) * 100
                            : 0;
                        }),
                      };
                    }),
                  };
                }
              })
            ),
          monthlyPrevYear: this.reportService.getTrendReports({
            ...prevFilterValue,
            ...MonthlyFilter,
          }),
        });
      })
    );
    this.trendReports$.subscribe((data) => {
      this.localPrevYear = data.monthlyPrevYear;
      this.localThisYear = data.monthlyThisYear;
      const currentFilterValue = {
        ...this.filterValue,
        from: new Date(new Date().getFullYear(), 0, 1),
        to: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999),
      };
      this.reportService
        .getTrendReports({
          ...currentFilterValue,
          ...MonthlyFilter,
        })
        .subscribe((reports) => {
          this.createQuarterData(data.monthlyPrevYear, reports);
        });
    });
    this.filter.updateValueAndValidity();
  }

  adjustCanvasWidth(canvas: HTMLCanvasElement) {
    let maxWidth = Math.round((canvas.clientHeight * 16) / 9);
    canvas.parentElement.style.width = 1770 + 'px';
    canvas.parentElement.style.height = '1000px';
    return this.resizeChart(canvas);
  }

  resizeChart(canvas) {
    let charts = Object.values(Chart.instances).filter((chart) => {
      return chart.canvas == canvas;
    });
    if (charts.length == 1) {
      let chart = charts[0];
      chart.resize();
      return true;
    }
  }

  downloadChart(event: any, id: string) {
    const buttonElement = event.target;
    const headerTextElement = buttonElement
      .closest('.header-spacing')
      .querySelector('h3');
    const headerText = headerTextElement.textContent;

    let canvas = document.getElementById(id).childNodes[0]
      .childNodes[0] as HTMLCanvasElement;
    const resized = this.adjustCanvasWidth(canvas);

    const finalCanvas: HTMLCanvasElement = document.createElement('canvas');
    finalCanvas.width = 1920;
    finalCanvas.height = 1080;

    const imageWidth = 1770;
    const imageHeight = 1000;

    const imageXPadding = 150;
    const imageYPadding = 100;

    const context = finalCanvas.getContext('2d');
    context.font = '20px Roboto';
    context.fillStyle = '#696969';

    const textWidth = context.measureText(headerText).width;
    context.fillText(
      headerText,
      finalCanvas.width / 2 - textWidth / 2,
      imageXPadding / 2 / 2,
      finalCanvas.width
    );

    context.drawImage(
      canvas,
      imageXPadding / 2,
      imageYPadding / 2,
      imageWidth,
      imageHeight
    );

    context.font = '15px Roboto';
    context.fillText(
      `Telekom MMS ${new Date().getFullYear()} - Ecoshift`,
      imageXPadding * 2,
      finalCanvas.height - imageYPadding / 2 / 2
    );

    finalCanvas.toBlob((blob) => {
      const img = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = img;
      downloadLink.download = headerText + '.png';
      downloadLink.click();

      if (resized) {
        canvas.parentElement.style.width = 'auto';
        canvas.parentElement.style.height = 'auto';
        this.resizeChart(canvas);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private sumByDayDatasets(
    field: string,
    days: number[],
    months: number[],
    reports: ReportGrouped[],
    translations: any
  ): Dataset[] {
    return days.map((day) => {
      const dayReports = reports.filter(
        (report) => WeekDay[report.weekday!] === day
      );
      return {
        type: 'bar',
        label: translations[`common.days.${day}`],
        backgroundColor: Colors[day],
        data: months.map((mth) =>
          dayReports
            .filter((report) => {
              return (
                this.monthNames.findIndex((name) => {
                  return name === report.month;
                }) === mth
              );
            })
            .reduce((acc, report) => acc + report[field], 0)
        ),
      };
    });
  }

  createQuarterData(
    reportsPrevYear: ReportGrouped[],
    reportsThisYear: ReportGrouped[]
  ): void {
    this.annualData = {
      labels: this.quarters,
      datasets: [reportsPrevYear, reportsThisYear].map(
        (thisYearReports, index) => {
          const reports =
            thisYearReports === undefined
              ? []
              : thisYearReports.filter(
                  (report) =>
                    this.selectedType === undefined ||
                    this.selectedType === null ||
                    report.transportType === this.selectedType?.value
                );

          return {
            type: 'bar',
            label: this.years[index].toString(),
            backgroundColor: TotalColors[index],
            //quarters = Q1, Q2, Q3, Q4
            data: this.quarters.map((quarter, quarterIndex) => {
              return reports
                .filter((report) => {
                  let reportIndex = this.monthNames.findIndex(
                    (name) => name === report.month
                  );
                  if (report.month === this.monthNames[0]) {
                    reportIndex = 1;
                  }
                  return Math.floor((reportIndex + 2) / 3) === quarterIndex + 1;
                })
                .reduce((acc, report) => acc + report.emissions, 0);
            }),
          };
        }
      ),
    };
  }

  transportTypeChanged(): void {
    this.createQuarterData(this.localPrevYear, this.localThisYear);
  }
}
