import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Brand, BrandColor } from '../models/brand';
import { ContentLoadingService } from './content-loading.service';
import { BrandingHTTPService } from './http/branding-http.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private _selectedBranding: BehaviorSubject<Brand> =
    new BehaviorSubject<Brand>(undefined);

  private tenantID: string = '';

  constructor(
    private brandingHttpService: BrandingHTTPService,
    private tenantService: TenantService,
    private contentLoadingService: ContentLoadingService
  ) {
    this.contentLoadingService.setLoadingState(true);

    this.tenantService.loggedInTenant.subscribe((tenant) => {
      if (tenant) {
        this.brandingHttpService
          .fetchBranding(tenant.id)
          .subscribe((branding) => {
            this.setSelectedBranding(branding);
            this.contentLoadingService.setLoadingState(false);
          });
      }
    });
  }

  public updateColorBranding(
    brandColor: BrandColor,
    key: string,
    isInverse: boolean,
    originalKey: string
  ) {
    const branding = this._selectedBranding.value;

    if (branding) {
      if (isInverse) {
        branding[key][originalKey].inverseColor.colorValue =
          brandColor.colorValue;
      } else {
        branding[key][originalKey].colorValue = brandColor.colorValue;
      }
      this.putBranding(branding);
    }
  }

  putBranding(branding: Brand) {
    this.contentLoadingService.setLoadingState(true);

    this.brandingHttpService.putBranding(branding).subscribe((response) => {
      this.setSelectedBranding(branding);
      this.contentLoadingService.setLoadingState(false);
    });
  }

  get selectedBranding() {
    return this._selectedBranding.asObservable();
  }

  public getBrandingFor(tenantId: string): Observable<Brand> {
    return this.brandingHttpService.fetchBranding(tenantId);
  }

  public setSelectedBranding(branding: Brand) {
    this._selectedBranding.next(branding);
  }

  public static readonly BRANDING_MAPPING = [
    {
      key: 'brandColors',
      values: ['brandPrimary'],
    },
    {
      key: 'textColors',
      values: [
        'textColor',
        'textAdditionalColor',
        'textLinkColor',
        'textSuccessColor',
        'textErrorColor',
        'textWarningColor',
        'textInformationColor',
        'textDisabledColor',
      ],
    },
    {
      key: 'surfaceColors',
      values: [
        'borderColor',
        'backdropColor',
        'solidColor',
        'backgroundColor',
        'backgroundLightColor',
        'backgroundDarkColor',
        'surfaceColor',
      ],
    },
  ];
}
