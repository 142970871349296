import {
  Input,
  Directive,
  OnInit,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { TokenUtil } from '../utils/token-util';

@Directive({
  selector: '[appRoleProtected]',
})
export class EcoshiftRoleProtectedDirective implements OnInit {
  @Input() appRoleProtected: string[];
  appRoleForbidden: string[];

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input() set appRoleProtectedForbidden(forbidden: string[]) {
    this.appRoleForbidden = forbidden;
  }

  ngOnInit(): void {
    if (!this.appRoleProtected && !this.appRoleForbidden) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }

    const accessToken = localStorage.getItem('id-token');
    if (!accessToken) {
      return this.viewContainerRef.clear();
    }

    const roles = TokenUtil.getClaimFromToken(accessToken, 'roles');

    let hasAccess = true;
    if (this.appRoleProtected && this.appRoleProtected.length > 0) {
      hasAccess = roles.some((role) => this.appRoleProtected.includes(role));
    }

    if (this.appRoleForbidden && this.appRoleForbidden.length > 0) {
      hasAccess = !this.appRoleForbidden.some((role) => roles.includes(role));
    }

    if (hasAccess) {
      // If it is already visible (which can happen if
      // his roles changed) we do not need to add it a second time
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
}
