<div class="license-wrapper page-wrapper">
    <div class="intro">
        <h2>{{'license.headline'|translate}}</h2>
        <p>{{'license.introText'|translate}}</p>
    </div>
    <div class="license-table">
        <div class="header">
            <h3>{{'license.table.title.headline'|translate}}<span>({{this.licenseCount}})</span></h3>
        </div>
        <app-dashboard-table [tableData]="licenseTableData()" (customPressed)="downloadActivationCodes($event)"
            [customButtonText]="'license.table.customButtonText'" [customButtonIcon]="'assets/svg/download.svg'"
            [customIsTextButton]="true"></app-dashboard-table>
    </div>
    <scale-button href="mailto:ecoshift@t-systems-mms.com" target="_blank">
        <h6>{{'license.shop.button'|translate}} &nbsp;</h6>
        <scale-icon-navigation-external-link
            accesibility-title="External link, opens in new tab"></scale-icon-navigation-external-link>
    </scale-button>
</div>