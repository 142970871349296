import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenUtil } from 'src/app/utils/token-util';
import { environment } from '../../../environments/environment';
import { Company } from '../../models/company';
import { APIConst } from '../../models/enums/api.enum';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getCompanies(tenantId: string): Observable<Company[]> {
    return this.apiService
      .get({
        path: `${
          '/supervisor' +
          APIConst.COMPANY +
          '/' +
          tenantId +
          this.apiService.requestParamsObj({})
        }`,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ list }) =>
          list.map((company: Company) => {
            return {
              id: company.id,
              name: company.name,
              tenantID: company.tenantID,
            };
          })
        )
      );
  }

  getAllCompaniesFor(tenantID: string): Observable<Company[]> {
    return this.apiService
      .get({
        path: `${APIConst.SUPERVISOR_COMPANY + '/' + tenantID}`,
        endp: environment.apiPath,
        sendIDToken: true,
      })
      .pipe(
        map((value) => value.body),
        map(({ list }) =>
          list.map(({ id, name }) => ({
            id,
            name,
            tenantID,
          }))
        )
      );
  }

  addCompany(tenantID: string, companyName: string): Observable<any> {
    return this.apiService.post({
      path: `${APIConst.SUPERVISOR_COMPANY + '/' + tenantID}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        name: companyName,
      },
    });
  }

  updateCompany(company: Company): Observable<any> {
    const tenantId = TokenUtil.getTenantFromToken(
      localStorage.getItem('id-token')
    );
    return this.apiService.put({
      path: `${APIConst.SUPERVISOR_COMPANY + '/' + tenantId}`,
      endp: environment.apiPath,
      sendIDToken: true,
      body: {
        id: company.id,
        name: company.name,
      },
    });
  }

  deleteCompany(companyID: string) {
    return this.apiService.delete({
      path: `${APIConst.SUPERVISOR_COMPANY + '/' + companyID}`,
      endp: environment.apiPath,
      sendIDToken: true,
    });
  }
}
