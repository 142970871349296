export class TokenUtil {
  public static getClaimFromToken(
    accessToken: string,
    claimName: string
  ): any[] {
    return (
      accessToken?.includes('.') &&
      JSON.parse(atob(accessToken.split('.')[1]))[claimName]
    );
  }

  public static userHasAdminRole() {
    return this.tokenContainsRole(
      localStorage.getItem('id-token'),
      'Dashboard.Admin'
    );
  }

  public static userHasSupervisorRole() {
    return this.tokenContainsRole(
      localStorage.getItem('id-token'),
      'Admin.All'
    );
  }

  public static userHasOwnerRole() {
    return this.tokenContainsRole(
      localStorage.getItem('id-token'),
      'Dashboard.Owner'
    );
  }

  public static userHasAnalystRole() {
    return this.tokenContainsRole(
      localStorage.getItem('id-token'),
      'Dashboard.Read'
    );
  }

  private static tokenContainsRole(
    accessToken: string,
    rolePermission: string
  ) {
    const result = this.getClaimFromToken(accessToken, 'roles');

    if (result === undefined) {
      return false;
    }
    return result.includes(rolePermission);
  }

  public static getTenantFromToken(token: string) {
    const groupIds = TokenUtil.getClaimFromToken(token, 'groups') || [];
    return groupIds.length > 1 ? groupIds[1] : groupIds[0];
  }
}
