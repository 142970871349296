<div class="page">
    <ng-container *ngIf="langIsDe">
        <h1>Datenschutzhinweise für EcoShift App, Stand 10.08.2022</h1>
        <h3>Allgemeines</h3>
        <p>
            Der Schutz Ihrer persönlichen Daten hat für die Deutsche Telekom AG einen hohen Stellenwert. Es ist uns
            wichtig,
            Sie
            darüber zu informieren, welche persönlichen Daten erfasst werden, wie diese verwendet werden und welche
            Gestaltungsmöglichkeiten Sie dabeihaben. Die EcoShift App wird im folgenden App genannt. Die Verteilung der
            App
            erfolgt
            über den Corporate Mobile Service (AirWatch).
        </p>
        <h3>1. Wo finde ich die Informationen, die für mich wichtig sind?</h3>
        <p>
            Dieser <strong>Datenschutzhinweis</strong> gibt einen Überblick über die
            Punkte, die für die Verarbeitung Ihrer Daten in dieser App durch die
            Telekom gelten.
        </p>
        <p>
            Weitere Informationen, auch zum Datenschutz in speziellen Produkten,
            erhalten Sie auf
            <a href="https://www.telekom.com/de/verantwortung/datenschutz-und-datensicherheit/datenschutz"
                target="_blank">
                <strong>
                    https://www.telekom.com/de/verantwortung/datenschutz-und-datensicherheit/datenschutz
                </strong>
            </a>
            und unter
            <a href="http://www.telekom.de/datenschutzhinweise" target="_blank">
                <strong>http://www.telekom.de/datenschutzhinweise</strong>
            </a>
            .
        </p>
        <h3>2. Wer ist verantwortlich für die Datenverarbeitung? Wer ist mein
            Ansprechpartner, wenn ich Fragen zum Datenschutz bei der Telekom habe?</h3>
        <p>
            Datenverantwortliche ist die Deutsche Telekom AG in Friedrich-Ebert-Allee
            71-77, 53113 Bonn. Bei Fragen können Sie sich an unseren Kundenservice
            wenden oder an unseren Datenschutzbeauftragten, Herrn Dr. Claus D. Ulmer,
            Friedrich-Ebert-Allee 140, 53113 Bonn,
            <a href="mailto:datenschutz@telekom.de" target="_blank">
                <strong>datenschutz@telekom.de</strong>
            </a>
            .
        </p>
        <h3>3. Welche Rechte habe ich?</h3>
        <p>Sie haben das Recht,</p>
        <p>
            a. <strong>Auskunft</strong> zu verlangen zu Kategorien der verarbeiteten
            Daten, Verarbeitungszwecken, etwaigen Empfängern der Daten, der geplanten
            Speicherdauer (Art. 15 DSGVO);
        </p>
        <p>
            b. die <strong>Berichtigung</strong> bzw. Ergänzung unrichtiger bzw.
            unvollständiger Daten zu verlangen (Art. 16 DSGVO);
        </p>
        <p>
            c. eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu
            <strong>widerrufen</strong> (Art. 7 Abs. 3 DSGVO); der Widerruf kann über die EcoShift App oder per Mail an
            ecoshift@t-systems-mms.com.
        </p>
        <p>
            d. einer Datenverarbeitung, die aufgrund eines berechtigten Interesses
            erfolgen soll, aus Gründen zu <strong>widersprechen</strong>, die sich aus
            Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DSGVO);
        </p>
        <p>
            e. in bestimmten Fällen im Rahmen des Art. 17 DSGVO die
            <strong>Löschung</strong> von Daten zu verlangen - insbesondere soweit die
            Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw.
            unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben (c)
            widerrufen oder einen Widerspruch gemäß oben (d) erklärt haben;
        </p>
        <p>
            f. unter bestimmten Voraussetzungen die <strong>Einschränkung</strong> von
            Daten zu verlangen, soweit eine Löschung nicht möglich bzw. die
            Löschpflicht streitig ist (Art. 18 DSGVO);
        </p>
        <p>
            g. auf <strong>Datenübertragbarkeit</strong>, d.h. Sie können Ihre Daten,
            die Sie uns bereitgestellt haben, in einem gängigen maschinenlesbaren
            Format wie z.B. CSV erhalten und ggf. an andere übermitteln (Art. 20
            DSGVO;)
        </p>
        <p>
            h. sich bei der zuständigen <strong>Aufsichtsbehörde</strong> über die
            Datenverarbeitung zu <strong>beschweren</strong> (für
            Telekommunikationsverträge: Bundesbeauftragte für den Datenschutz und die
            Informationsfreiheit; im Übrigen: Landesbeauftragte für den Datenschutz
            und die Informationsfreiheit Nordrhein-Westfalen).
        </p>
        <h3>4. An wen gibt die Telekom meine Daten weiter?</h3>
        <p>
            <strong>An Auftragsverarbeiter,</strong> das sind Unternehmen, die wir im
            gesetzlich vorgesehenen Rahmen mit der Verarbeitung von Daten beauftragen,
            Art. 28 DSGVO (Dienstleister, Erfüllungsgehilfen). Die Telekom bleibt auch
            in dem Fall weiterhin für den Schutz Ihrer Daten verantwortlich. Wir
            beauftragen Unternehmen insbesondere in folgenden Bereichen: IT, Vertrieb,
            Marketing, Finanzen, Beratung, Kundenservice, Personalwesen, Logistik,
            Druck.
        </p>
        <p>
            Auftragsverarbeiter und verantwortlich für die zur Verfügungsstellung des Services sowie der
            Weiterentwicklung
            der
            EcoShift App ist die <br />
            Deutsche Telekom MMS GmbH <br />
            Riesaer Str. 5 <br />
            01129 Dresden.
        </p>
        <p>
            <strong>Aufgrund gesetzlicher Verpflichtung:</strong> In bestimmten Fällen
            sind wir gesetzlich verpflichtet, bestimmte Daten an die anfragende
            staatliche Stelle zu übermitteln. Beispiel: Nach Vorlage eines
            Gerichtsbeschlusses sind wir gemäß § 101 Urheberrechtsgesetz verpflichtet,
            Inhabern von Urheber- und Leistungsschutzrechten Auskunft über Kunden zu
            geben, die urheberrechtlich geschützte Werke in Internet-Tauschbörsen
            angeboten haben sollen.
        </p>
        <h3>5. Wo werden meine Daten verarbeitet?</h3>
        <p>
            Ihre personenbezogenen Daten werden ausschließlich auf Ihrem genutzten Endgerät verarbeitet. In der App
            ermittelte
            nicht personenbezogene Daten werden außerdem in der Azure Cloud in Irland verarbeitet.
        </p>
        <h3>6. Welche Daten werden erfasst, wie werden sie verwendet und wie lange
            werden sie gespeichert?
        </h3>
        <p>
            a. Die Nutzung der App erfolgt anonym und ohne Personenbezug. Die App bietet Ihnen die Möglichkeit
            Fahrzeugdaten
            zu
            Ihrem genutzten PKW in der App permanent in den Einstellungen zu hinterlegen. Das Hinterlegen der Daten ist
            optional. Sollte Sie keine Daten zum Fahrzeug hinterlegen, dann werden Standarddaten für Sie genutzt. Bei
            den
            Daten
            handelt es sich um Leergewicht des Fahrzeugs, Fahrzeugklasse sowie Antriebsart. Die Eingabe dieser Daten
            kann
            jederzeit durch die Standardwerte ersetzt werden. Zusätzlich haben Sie die Möglichkeit Ihre
            Regelarbeitstätte
            aus
            einer Liste von Standorten Ihres Unternehmens auszuwählen und zu speichern. Die Daten werden so lange
            gespeichert,
            bis Sie nicht mehr für die App administriert sind bzw. die App von Ihrem Gerät gelöscht wurde oder selbst
            gelöscht
            haben.
        </p>
        <p>
            b. Die App speichert nach Start einer Aufzeichnung die Standortdaten Ihres Endgeräts bis zum Stopp der
            Aufzeichnung.
            Der Abruf der Standortdaten erfolgt 1x pro Sekunde. Nach dem Abschluss der Aufzeichnung wird mit Hilfe der
            Strecken-
            und Fahrzeugdaten die Streckenlänge sowie der CO₂-Aussoß berechnet und auf dem Endgerät gespeichert. Die
            Daten
            werden so lange gespeichert, bis Sie nicht mehr für die App administriert sind bzw. die App von Ihrem Gerät
            gelöscht
            wurde oder selbst gelöscht haben. Die gespeicherten Aufzeichnungen können auch einzeln in der der
            Aufzeichnungsliste
            durch Sie gelöscht werden.
        </p>
        <p>
            c. Die App erstellt einmal pro Woche einen Bericht über alle Fahrten, die der Kategorie Arbeitsweg oder
            Dienstreise
            entsprechen. Dieser Report wird Ihnen zur Überprüfung angezeigt und für einen Upload angeboten. Sollten Sie
            dem
            Upload zustimmen, werden folgende Daten anonym zur weiteren Analyse übermittelt:<br />
        <ul>
            <li>Regelarbeitsstätte</li>
            <li>Datum einer Aufzeichnung</li>
            <li>Zeit einer Aufzeichnung</li>
            <li>generischer Fahrzeugtyp (ÖPNV, PKW diesel/benzin/elektro, Fahrrad usw.)</li>
            <li>Fahrtlänge in km</li>
            <li>erechnete CO₂-Emission</li>
            <li>Auzeichnungstyp (Dienstreise oder Arbeitsweg)</li>
        </ul>
        <br />
        <p>
            Die App kann auch ohne Upload genutzt werden. Eine Zustimmung zum Upload von Daten ist freiwillig und kann
            auch
            verweigert werden.
        </p>
        <h3>7. Berechtigungen</h3>
        <p>
            Um die App auf Ihrem Gerät nutzen zu können, muss die App auf verschiedene
            Funktionen und Daten Ihres Endgeräts zugreifen können. Dazu ist es
            erforderlich, dass Sie bestimmte Berechtigungen erteilen (Art. 6 Abs. 1 a
            DSGVO).
        </p>
        <p>
            Die Berechtigungskategorien sind von den verschiedenen Herstellern
            unterschiedlich programmiert. So werden z. B. bei Android
            Einzelberechtigungen zu Berechtigungskategorien zusammengefasst und Sie
            können auch nur der Berechtigungskategorie insgesamt zustimmen.
        </p>
        <p>
            Bitte beachten Sie dabei aber, dass Sie im Falle eines Widerspruchs
            gegebenenfalls nicht sämtliche Funktionen unserer App nutzen können.
        </p>
        <p>
            Soweit Sie Berechtigungen erteilt haben, nutzen wir diese nur im
            nachfolgend beschriebenen Umfang:
        </p>
        <p>
            <strong>Standortdaten</strong> <br />
            Die Integration in den Corporate Mobile Service benötigt Informationen zu Ihrem aktuellen Standort. Die App
            fragt
            nach dem Start und bis zum Stop einer Aufzeichnung durch Sie den aktuellen Gerätestandort auf und speichert
            die
            Daten auf dem Endgerät. Da die Aufzeichnung nach Start auch weiterlaufen soll, wenn die App sich im
            Hintergrund
            befindet, wird die Hintergrundberechtigung für die Standortabfrage benötigt.
        </p>
        <p>
            <strong>Internetkommunikation</strong> <br />
            Die App benötigt Zugriff auf das Internet über W-LAN oder Mobilfunk für
            den Zugriff und die Anzeige von Kartendaten von Google Maps.
        </p>
        <p>
            <strong>Kontakte / Adressbuch</strong> <br />
            Die App hat keinerlei Zugriff auf Kontakte oder das Adressbuch.
        </p>
        <p>
            <strong>
                Kamera, Mikrofon, USB, Fotos, Videos, Nachrichteninhalte etc.
            </strong>
            <br />
            Aufgrund der Integration des Corporate Mobile Service greift die App auf
            diese Module Ihres Gerätes zu.
        </p>
        <p>
            <strong>Weitere Berechtigungen (z.B. Zugriff auf Apps)</strong> <br />
            Die App benötigt keine weiteren Berechtigungen
        </p>
        <h3>8. Sendet die App Push-Benachrichtigungen?</h3>
        <p>Nein die App sendet keine Push-Benachrichtigungen.</p>
        <h3>9. Wird mein Nutzungsverhalten ausgewertet, (technische Optimierung)?
        </h3>
        <p>
            Die App dient ausschließlich zur Nutzung von internen Funktionen, es wird
            kein Nutzungsverhalten ausgewertet.
        </p>
        <p>
            <strong>Erläuterungen und Definitionen:</strong> <br />
            Im Rahmen der gesetzlichen Regelungen leiten wir die Daten an die von uns
            im Rahmen einer Auftragsdatenverarbeitung beauftragte Unternehmen weiter.
            Ein unmittelbarer Rückschluss auf Sie ist dabei nicht möglich. Nachfolgend
            informieren wir Sie allgemein über die verschiedenen Zwecke und Techniken.
        </p>
        <p>
            a.
            <strong>Verwendungszwecke (Art. 6 Abs. 1f DSGVO / §15 Abs. 3 TMG)</strong>
        </p>
        <p>
            <strong>Marktforschung / Reichweitenmessung</strong> <br />
            Marktforschung und Reichweitenmessung wird in der App nicht genutzt.
        </p>
        <p>
            <strong>Profile zur Verbesserung der technischen App-Qualität</strong>
            <br />
            Um die Qualität der Programmierung einer App zu messen oder Abstürze und
            deren Ursache zu registrieren, werden Programmablauf und Fehlverhalten
            ausgewertet. Einzelne Nutzer werden dabei nicht identifiziert.
        </p>
        <h3>10. Dienste von Firmen, die nicht durch eine Auftragsdatenverarbeitung
            gebunden sind und ihre Dienste als Verantwortliche erbringen
        </h3>

        <p>
            <strong>Google</strong> <br />
            Einbindung von <strong>Google Maps</strong>: Auf einzelnen Seiten der App
            setzen wir Google Maps zur Darstellung von Karten, Standorten und für die
            Routenplanung. Betrieben wird Google Maps von Google Inc., 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA. Durch die Einbettung
            von Google Maps wird ihre IP-Adresse an Google übertragen und ein Cookie
            gespeichert. Sie können sich jederzeit über die Datenverarbeitung durch
            Google unter
            <a href="http://www.google.de/intl/de/policies/privacy" target="_blank">
                <strong>http://www.google.de/intl/de/policies/privacy</strong>
            </a>
            informieren und dieser widersprechen.
        </p>
    </ng-container>
    <ng-container *ngIf="langIsEn">
        <p>
            <strong>Data Protection Notice for the EcoShift App, last updated August 10, 2022</strong>
        </p>
        <p><strong>General</strong></p>
        <p>
            Your privacy is very important to Deutsche Telekom AG. It is important to us to inform you about what
            personal data are collected, how they are used, and what options you have for determining how this is done.
            The EcoShift app is called the “App” in the following. The App is available from the Corporate Mobile
            Service
            (AirWatch).
        </p>
        <p>
            <strong>1. </strong>
            <strong>Where can I find the information important to me?</strong>
        </p>
        <p>
            This Data Protection Notice provides an overview of the points that apply to the processing of your data in
            this App by Deutsche Telekom.
        </p>
        <p>
            Further information—including on data protection in specific products—can be found at
            <a href="https://www.telekom.com/en/corporate-responsibility/data-protection-data-security/data-protection"
                target="_blank">
                <strong>
                    https://www.telekom.com/en/corporate-responsibility/data-protection-data-security/data-protection
                </strong>
            </a>
            and at
            <a href="http://www.telekom.de/datenschutzhinweise" target="_blank">
                <strong>http://www.telekom.de/datenschutzhinweise</strong>
            </a>
            .
        </p>
        <p>
            <strong>2. </strong>
            <strong>
                Who controls the data processing? Who do I contact if I have questions about data protection at Deutsche
                Telekom?
            </strong>
        </p>
        <p>
            The Data Controller is Deutsche Telekom AG at Friedrich-Ebert-Allee 71–77, 53113 Bonn, Germany. If you have
            any questions, you can contact our customer service or our Data Protection Officer, Dr. Claus D. Ulmer,
            Friedrich-Ebert-Allee 140, 53113 Bonn,
            <a href="mailto:datenschutz@telekom.de" target="_blank">
                <strong>datenschutz@telekom.de</strong>
            </a>
            .
        </p>
        <p><strong>3. </strong> <strong>What rights do I have?</strong></p>
        <p>You have the right</p>
        <p>
            a. to request <b>information</b> about the categories of data processed, the processing purposes, possible
            recipients of the data, and the planned storage period (Art. 15 GDPR);
        </p>
        <p>
            b. to request the <b>rectification</b> or completion of inaccurate or incomplete data (Art. 16 GDPR);
        </p>
        <p>
            c. to the <b>revocation</b> of given consent at any time with effect for the future (Art. 7 (3) GDPR); the
            revocation can be made via the EcoShift app or by email to <a
                href="mailto:ecoshift@t-systems-mms.com">ecoshift@t-systems-mms.com</a>;
        </p>
        <p>
            d. to lodge an <b>objection</b> to data processing based on a legitimate interest for reasons arising from
            your particular situation (Art. 21 (1) GDPR);
        </p>
        <p>
            e. to request the <b>deletion</b> of data in certain cases within the framework of Art. 17 GDPR—in
            particular if the data are no longer required for the intended purpose or are being unlawfully processed, or
            you have revoked your consent in accordance with (c) above or have objected in accordance with (d) above;
        </p>
        <p>
            f. to demand the <b>restriction</b> of data, under certain conditions, if deletion is not possible or the
            obligation to delete is disputed (Art. 18 GDPR);
        </p>
        <p>
            g. to the <b>portability</b> of data, which means you can receive your data, which you have provided us
            with, in a commonly-used machine-readable format, such as CSV, and, if necessary, transmit it to others
            (Art. 20 GDPR;)
        </p>
        <p>
            h. to <b>complain</b> to the competent supervisory authority about the data processing (for
            telecommunication contracts: Bundesbeauftragte für den Datenschutz und die Informationsfreiheit [Federal
            Commissioner for Data Protection and Freedom of Information]; otherwise: Landesbeauftragte für den
            Datenschutz und die Informationsfreiheit Nordrhein-Westfalen [State Commissioner for Data Protection and
            Freedom of Information for North Rhine-Westphalia]).
        </p>
        <p>
            <strong>4. </strong>
            <strong>Who does Deutsche Telekom disclose my data to?</strong>
        </p>
        <p>
            <strong>To Data Processors</strong> - companies that we commission to process data within the legal
            framework of Art. 28 GDPR (service providers, vicarious agents). In this case, Deutsche Telekom continues to
            be responsible for the protection of your data as Data Controller. We commission companies particularly in
            the following areas: IT, Sales, Marketing, Finance, Consulting, Customer Service, Human Resources,
            Logistics, Printing.
        </p>
        <p>
            The Data Processor and entity responsible for providing the service and the further development of the
            EcoShift
            app is
        </p>
        <p>
            Deutsche Telekom MMS GmbH<br>
            Riesaer Str. 5<br>
            01129 Dresden, Germany.
        </p>
        <p>
            <strong>Due to legal obligation:</strong> In certain cases, we are required by law to transmit certain data
            to the requesting government agency. Example: After submission of a court order, we are obliged under
            Section 101 of the German Copyright Act [Urheberrechtsgesetz] to provide holders of copyrights and ancillary
            copyrights with information about customers who are said to have offered copyright-protected works on
            Internet exchange sites.
        </p>
        <p>
            <strong>5. </strong> <strong>Where are my data processed?</strong>
        </p>
        <p>
            Your personal data will only be processed on the device you use. Non-personal data determined in the App are
            also processed in the Azure Cloud in Ireland.
        </p>
        <p>
            <strong>6. </strong>
            <strong>
                What data will be collected, how will they be used, and for how long will they be stored?
            </strong>
        </p>
        <p>
            a. The App is used anonymously and without personal reference. The App offers you the option to permanently
            store vehicle data for the car you use in the settings of the App. Storing the data is optional. If you do
            not enter any data about the vehicle, default data will be used for you. The data include the unladen weight
            of the vehicle, the vehicle category and the type of drive. The input of these data can be replaced at any
            time by the default values. You also have the option of selecting and saving your regular workplace from a
            list of locations of your company. The data will be stored until you are no longer administrated for the App
            or the App has been deleted from your device or you have deleted the App yourself.
        </p>
        <p>
            b. After starting a recording, the App stores the location data of your device until the recording stops.
            Location data are retrieved once per second. After the recording has been completed, the route length and
            CO₂ emissions are calculated using the route and vehicle data, and are stored on the device. The data will
            be stored until you are no longer administrated for the App or the App has been deleted from your device or
            you have deleted the App yourself. You can also delete the saved recordings individually in the list of
            recordings.
        </p>
        <p>
            c. Once a week, the App compiles a report on all trips that fall into the commute or business-trip
            categories. This report is displayed to you for review and offered for upload. If you agree to the upload,
            the following data will be transmitted anonymously for further analysis:
        </p>
        <ul>
            <li>regular workplace</li>
            <li>date of recording</li>
            <li>time of recording</li>
            <li>generic vehicle type (public transport, diesel/gasoline/electric car, bicycle, etc.)</li>
            <li>trip length in km</li>
            <li>calculated CO₂ emission</li>
            <li>type of recording (business trip or commuting)</li>
        </ul>
        <p>
            The App can also be used without uploading. Consent to the uploading of data is voluntary and may be
            withheld.
        </p>
        <p><strong>7. </strong> <strong>Permissions</strong></p>
        <p>
            In order to be able to use the App on your device, the App must be able to access various functions and data
            of your device. To enable this, you must grant certain permissions (Art. 6 (1a) GDPR).
        </p>
        <p>
            The authorization categories are programmed differently by the various manufacturers. On an Android device,
            for example, individual permissions are combined into permission categories, and you can only agree to the
            permission category as a whole.
        </p>
        <p>
            Please note, however, that if you do not agree, you may not be able to use all the functions of our App.
        </p>
        <p>
            If you have granted permissions, we will only use them to the extent described below:
        </p>
        <p>
            <strong>Location data</strong> <br />
            Integration with the Corporate Mobile Service requires information on your current location. The App queries
            the current device location after you start the recording and until you stop the recording, and saves the
            data on the end device. Since the recording is intended to continue to run after the start if the App is in
            the background, the background permission for the location query is required.
        </p>
        <p>
            <strong>Internet communication</strong> <br />
            The App requires access to the Internet via Wi-Fi or cellphone networks in order to access and display map
            data from Google Maps.
        </p>
        <p>
            <strong>Contacts / address book</strong> <br />
            The App has no access to your contacts or address book.
        </p>
        <p>
            <strong>
                Camera, microphone, USB, photos, videos, news content, etc.
            </strong>
            <br />
            Due to the integration of the Corporate Mobile Service, the App accesses these modules of your device.
        </p>
        <p>
            <strong>Additional permissions (such as access to apps)</strong> <br />
            The App does not require any additional permissions
        </p>
        <p>
            <strong>8. </strong>
            <strong>Does the App send push notifications?</strong>
        </p>
        <p>No, the App does not send push notifications.</p>
        <p>
            <strong>9. </strong>
            <strong>
                Will my usage behavior be evaluated (technical optimization)?
            </strong>
        </p>
        <p>
            The App is intended exclusively for the use of internal functions; no usage behavior is evaluated.
        </p>
        <p>
            <strong>Explanations and definitions:</strong> <br />
            Under the legal regulations, we forward the data to the companies commissioned by us in connection with
            Contract Data Processing. It is not possible to identify you directly. The following is general information
            on the various purposes and techniques.
        </p>
        <p>
            a.
            <strong>Purposes for use (Art. 6 (1f) GDPR / Section 15[3] TMG [German Telemedia Act])</strong>
        </p>
        <p>
            <strong>Market research / reach measurement</strong> <br />
            Market research and reach measurement are not used in the App.
        </p>
        <p>
            <strong>Profiles for improving the technical quality of the App</strong>
            <br />
            Profiles for improving the technical quality of the App are not used in the App.
        </p>
        <p>
            <strong>10. </strong>
            <strong>
                Services of companies not bound by Contract Data Processing that provide their services as Data
                Controllers
            </strong>
        </p>
        <p>
            <strong>Google</strong> <br />
            Integration with <strong>Google Maps</strong>: On individual pages of the App, we use Google Maps to display
            maps and locations, and for planning routes. Google Maps is operated by Google Inc., 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA. The embedding of Google Maps transfers your IP address to Google and
            saves it in a cookie. You can find out about and object to the data processing by Google at any time at
            <a href="https://policies.google.com/privacy?hl=us&gl=en" target="_blank">
                <strong>https://policies.google.com/privacy?hl=us&gl=en</strong>
            </a>
            .
        </p>
    </ng-container>
</div>