import { TableColumn, TableData, TableRow } from '../models/table-data';
import { Office } from '../models/office';
import { Emission } from '../models/emission';
import { Company } from '../models/company';

const officeTableStructure = (offices: Office[]) => {
  return {
    tableRows: offices.map((office) => {
      return {
        data: [{text: office.street}, {text: office.zip}, {text: office.city}, {text: office.countryCode}],
        entryId: office.id,
      } as TableRow;
    }),
    columnHeaders: [
      {
        title: 'data.table.title.office.street',
        filterable: false,
        sortable: false,
      },
      {
        title: 'data.table.title.office.zip',
        filterable: false,
        sortable: true,
      },
      {
        title: 'data.table.title.office.city',
        filterable: false,
        sortable: true,
      },
      {
        title: 'data.table.title.office.country',
        filterable: true,
        sortable: true,
      },
    ],
    editable: true,
    itemDeletion: true,
    includesCustomButton: false,
    hasSingleCustomButton: false,
    pagination: false,
  };
};

const determineEmissionValues = ({
  dieselFactor,
  gasolineFactor,
  electricFactor,
  adminEmissionFactor,
}: Emission): TableColumn[] => {
  if (!adminEmissionFactor) {
    return  [
      {text: dieselFactor.toString(), rightAligned: true},
      {text:  gasolineFactor.toString(), rightAligned: true},
      {text:  electricFactor.toString(), rightAligned: true}
    ]
  }

  return [
    // if dieselFactor is overriden by tenant and they are different display in format oldvalue ( initial: newValue )
    // if dieselFactor is overriden by tenant and they are equal display dieselFactor
    // if dieselFactor is not overriden display admin emission factor
    {text: dieselFactor !== adminEmissionFactor.dieselFactor
      ? formatEmissionValue(dieselFactor, adminEmissionFactor.dieselFactor).toString()
      : dieselFactor.toString() ?? adminEmissionFactor.dieselFactor.toString(),
      rightAligned: true
    },
    {text: gasolineFactor !== adminEmissionFactor.gasolineFactor
      ? formatEmissionValue(gasolineFactor, adminEmissionFactor.gasolineFactor).toString()
      : gasolineFactor.toString() ?? adminEmissionFactor.gasolineFactor.toString(),
      rightAligned: true
    },
    {text: electricFactor !== adminEmissionFactor.electricFactor
      ? formatEmissionValue(electricFactor, adminEmissionFactor.electricFactor).toString()
      : electricFactor.toString() ?? adminEmissionFactor.electricFactor.toString(),
      rightAligned: true
    },
  ];
};

const formatEmissionValue = (newValue: number, oldValue: number) =>
  `${newValue} (initial ${oldValue})`;

const emissionTableStructure = (
  emissions: Emission[],
  customButton: boolean = true
) => {
  return {
    tableRows: emissions.map((emission) => {
      return {
        data: [
          {
            text: emission.countryCode
          }, ...determineEmissionValues(emission)],
        entryId: emission.countryCode,
      } as TableRow;
    }),
    columnHeaders: [
      {
        title: 'data.table.title.emission.factor',
        filterable: false,
        sortable: false,
      },
      {
        title: 'data.table.title.emission.diesel',
        filterable: false,
        sortable: false,
        rightAligned: true
      },
      {
        title: 'data.table.title.emission.petrol',
        filterable: false,
        sortable: false,
        rightAligned: true
      },
      {
        title: 'data.table.title.emission.electric',
        filterable: false,
        sortable: false,
        rightAligned: true
      },
    ],
    editable: true,
    itemDeletion: true,
    includesCustomButton: customButton,
    hasSingleCustomButton: customButton,
    preventFirstItemDeletion: true,
    pagination: false,
  } as TableData;
};

const companyTableStructure = (companies: Company[]) => {
  return {
    tableRows: companies.map((company) => {
      return { data: [{text: company.name}], entryId: company.id } as TableRow;
    }),
    columnHeaders: [
      {
        title: 'data.table.title.company.name',
        filterable: false,
        sortable: true,
      },
    ],
    editable: true,
    itemDeletion: true,
    includesCustomButton: false,
    hasSingleCustomButton: false,
    pagination: false,
  } as TableData;
};

export { officeTableStructure, emissionTableStructure, companyTableStructure };
