<div class="branding-wrapper page-wrapper">
    <div class="intro">
        <h2>{{'data.headline'|translate}}</h2>
        <p>{{'data.introText'|translate}}</p>
    </div>

    <div class="emission-factors">
        <div class="header">
            <h3>{{'data.title.emissionfactor'|translate}}<span>&nbsp;({{this.emissionCount}})</span></h3>
            <scale-button (click)="addEmissionFactor()" variant="ghost" size="small" class="black-ghost-color"><img
                    class="reset-img" src="assets/svg/add.svg">{{'data.table.emission.add'|translate}}</scale-button>
        </div>
        <app-dashboard-table [tableData]="emissionTableData()"
            [customButtonText]="'data.table.emission.restore'|translate" (editPressed)="editEmissionFactor($event)"
            (deletePressed)="deleteEmissionFactor($event)"
            (customPressed)="restoreInitialEmissionValue($event)"></app-dashboard-table>
    </div>

    <div class="companies" *ngIf="userIsAnalyst == false">
        <div class="header">
            <h3>{{'data.table.title.company.headline'|translate}}<span>&nbsp;({{this.companyCount}})</span></h3>
            <scale-button (click)="addCompany()" variant="ghost" size="small" class="black-ghost-color"><img
                    class="reset-img" src="assets/svg/add.svg">{{'data.table.company.add'|translate}}</scale-button>
        </div>
        <app-dashboard-table [tableData]="companyTableData()" (editPressed)="editCompany($event)"
            (deletePressed)="deleteCompany($event)"></app-dashboard-table>
    </div>

    <div class="offices" *ngIf="userIsAnalyst == false">
        <div class="header">
            <h3>{{'data.table.title.office.headline'|translate}}<span>&nbsp;({{this.officeCount}})</span></h3>
            <scale-button (click)="addOffice()" variant="ghost" size="small" class="black-ghost-color"><img
                    class="reset-img" src="assets/svg/add.svg">{{'data.table.office.add'|translate}}</scale-button>
        </div>
        <app-dashboard-table [tableData]="officeTableData()" (editPressed)="editOffice($event)"
            (deletePressed)="deleteOffice($event)"></app-dashboard-table>
    </div>
</div>