import { FetchStatus } from './enums/fetch-status';

interface License {
  id: string;
  tenantId: string;
  contractName: string;
  contractFrom: Date;
  contractTo: Date;
  amountOfCodes: number;
  status: LicenseStatus;
}

interface LicenseResponse {
  licenses: License[];
  fetchStatus: FetchStatus;
}

enum LicenseStatus {
  ACTIVE = 'licenseStatus.active',
  OUTDATED = 'licenseStatus.outdated',
  ON_HOLD = 'licenseStatus.on_hold',
}

export { License, LicenseStatus, LicenseResponse };
