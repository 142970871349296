import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SurveyResponse } from 'src/app/models/survey/response/survey-response';
import {
  SelectInputRespnseResult,
  SurveyResponseResult,
} from 'src/app/models/survey/response/survey-response-result';
import { Survey } from 'src/app/models/survey/survey';
import { SurveyService } from 'src/app/services/survey.service';
type SurveyWithRespose = Survey & {
  numberOfSubmits: number; //
  companyNames: string[]; //
  responses: SurveyResponse[]; //
};

type SurveyTextResponseView = {
  allTiles: {
    createdAt: Date;
    textInput: Map<string, string>;
    responseId: string;
  }[];
  currentTiles: {
    createdAt: Date;
    textInput: Map<string, string>;
    responseId: string;
  }[];
};
@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.scss'],
})
export class SurveyResultsComponent implements OnInit {
  private surveyService: SurveyService = inject(SurveyService);
  private translateService: TranslateService = inject(TranslateService);
  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input() surveyResultsId: string;
  @Input() survey: SurveyWithRespose;
  copiedSurvey: SurveyWithRespose = null;
  surveyResults: any;
  currentSurveyResult: SurveyResponseResult;
  loading: boolean = true;
  error: any;
  showModal: boolean = false;
  isSingularModalOpen: boolean = false;
  currentLang = null;

  public readonly SELECTIONS_TYPE = 'selections';

  public textInputMap: Map<string, SurveyTextResponseView> = new Map();

  constructor() {}

  ngOnInit(): void {
    this.copiedSurvey = this.survey;
    this.currentLang = this.translateService.currentLang;
    this.loadSurveyResults();

    this.survey.responses
      .filter((response) =>
        response.inputs.filter((input) => input.key.startsWith('text'))
      )
      .forEach((response) => {
        response.inputs.forEach((input) => {
          const current: SurveyTextResponseView = this.textInputMap.get(
            input.key
          );
          if (!current) {
            this.textInputMap.set(input.key, {
              allTiles: [
                {
                  createdAt: response.created_at,
                  textInput: input.value,
                  responseId: response.id,
                },
              ],
              currentTiles: [
                {
                  createdAt: response.created_at,
                  textInput: input.value,
                  responseId: response.id,
                },
              ],
            });
          } else {
            const allTiles = current.allTiles;
            allTiles.push({
              createdAt: response.created_at,
              textInput: input.value,
              responseId: response.id,
            });

            const currentTiles = current.currentTiles;
            if (currentTiles.length < 3) {
              currentTiles.push({
                createdAt: response.created_at,
                textInput: input.value,
                responseId: response.id,
              });
            }
            this.textInputMap.set(input.key, current);
          }
        });
      });
  }

  isMap(value: any): value is Map<any, any> {
    return value instanceof Map;
  }

  public paginationDirecton: 'NEXT' | 'PREVIOUS' = 'NEXT';

  public onPageChange(event: any, inputIdentifier: string) {
    const start = event.detail.startElement;
    this.paginationDirecton = event.detail.direction;
    const end = start + 3;
    const entry = this.textInputMap.get(inputIdentifier);
    entry.currentTiles = entry.allTiles.slice(start, end);
  }

  valueHandler(value, type) {
    if (this.surveyResults) {
      const matchingElement = this.surveyResults[type].find(
        (input) => input.key === value.identifier
      );
      if (type === this.SELECTIONS_TYPE) {
        return {
          amountOfResponses: matchingElement.amountOfResponses,
          separateResponseWithCount: matchingElement.separateResponseWithCount,
        } as SelectInputRespnseResult;
      }
      return matchingElement.value;
    }
    return '';
  }

  selectHandler(
    input,
    selections: SelectInputRespnseResult
  ): {
    amountOfResponses: number;
    separateResponseWithCount: string[];
  } {
    return {
      amountOfResponses: selections.amountOfResponses,
      separateResponseWithCount: Array.from(
        new Map<string, number>(
          Object.entries(selections.separateResponseWithCount)
        ),
        ([key, value]) =>
          `${
            input.options.values.find((value) => value.key === key).value[
              this.currentLang
            ]
          }: ${value}x`
      ),
    };
  }

  openModal() {
    this.showModal = true;
    document.body.style.overflow = 'hidden';
  }

  showSingularModal(textResponse: {
    createdAt: Date;
    textInput: Map<string, string>;
    responseId: string; 
  }) {
    const responses = this.survey.responses.filter(
      (response) => response.id === textResponse.responseId
    );

    if (responses.length > 0) {
      const response: SurveyResponse = responses[0];
      this.copiedSurvey = { ...this.survey, responses: [response] };
      this.isSingularModalOpen = true;
      document.body.style.overflow = 'hidden';
    }
  }

  closeModal(isModalOpen) {
    document.body.style.overflow = '';
    this.showModal = isModalOpen;
  }

  closeSingularModal(isModalOpen) {
    document.body.style.overflow = '';
    this.isSingularModalOpen = isModalOpen;
  }

  loadSurveyResults() {
    this.surveyService.surveyResponseResult(this.surveyResultsId).subscribe(
      (results) => {
        this.surveyResults = results;
        this.loading = false;
        this.cdRef.detectChanges();
      },
      (error) => {
        this.error = error;
        this.loading = false;
        console.error('Error loading survey results:', error);
        this.cdRef.detectChanges();
      }
    );
  }

  getTranslationForKey(input: any, key: string): string {
    // const selectedInput = inputs.find(input => input.identifier === key);
    return input.options.values.find((value) => value.key === key).value[
      this.currentLang
    ];
    //selectedInput ? selectedInput.options.values.find(value => value.key === 'daily').value.en : '';
  }
}
