<div class="text-input dialog-component">
    <label *ngIf=" inputLabel">{{inputLabel | translate }}</label>
    <div>
        <input [type]="isNumericField ? 'number' : 'text'" [(ngModel)]="isNumericField ? inputValue : text"
            [min]="isNumericField ? 1 : 0" [placeholder]="inputPlaceHolder  | translate"
            (ngModelChange)="onInputChange($event)" (keyup)="onChange($event)">
        <app-close-button (click)="clearText()" class="close-button" [class.visible]="text.length > 0"
            [maxHeight]="'16px'" [maxWidth]="'16px'"></app-close-button>
    </div>
    <ng-container *ngIf="isEmailField() && emailIsAlreadyInUse()">
        <span>{{ 'usersRoles.table.dialog.email.inUse' | translate }}</span>
    </ng-container>
    <ng-container *ngIf="!isEmailField() || !emailIsAlreadyInUse()">
        <span *ngIf="suffix && inputIsInvalid()">{{ suffix | translate }}</span>
    </ng-container>

</div>