import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Offices } from '../mocks/offices';
import { Company } from '../models/company';
import { TransportType } from '../models/enums/transport-type.enum';
import { Office } from '../models/office';
import { fromDto, ReportGrouped } from '../models/report';
import { ReportGroupedFilter } from '../models/report-grouped-filter';
import { CompanyService } from './company.service';
import { ReportHttpService } from './http/report-http.service';
import { OfficeService } from './office.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private overviewReports: BehaviorSubject<ReportGrouped[]> =
    new BehaviorSubject<ReportGrouped[]>([]);

  constructor(
    private reportHttpService: ReportHttpService,
    private officeService: OfficeService,
    private companyService: CompanyService
  ) {
    this.officeService.dashboardOffices.subscribe();
  }

  static stripSign(value: string): number | undefined {
    return value ? Number(value?.split(/[<]/)[1]) : undefined;
  }

  static filterGroupToObject(filter: UntypedFormGroup): ReportGroupedFilter {
    let officeFilter;
    if (filter.get('office')?.value) {
      const value = filter.get('office')?.value as Office;
      officeFilter = value.id
        ? [value.id]
        : Offices.filter((office) => office.city === value.city).map(
            (office) => office.id
          );
    }

    let causedByCompanyCar: boolean | undefined = undefined;
    const value = filter.get('causedByCompanyCar')?.value;

    if (value) {
      if (value === 'Not') {
        causedByCompanyCar = false;
      } else if (value === 'Only') {
        causedByCompanyCar = true;
      }
    }

    return {
      from: filter.get('from')?.value
        ? new Date(new Date(filter.get('from')?.value).setHours(0, 0, 0, 0))
        : undefined,
      to: filter.get('to')?.value
        ? new Date(new Date(filter.get('to')?.value).setHours(23, 59, 59, 999))
        : undefined,
      transportType: filter
        .get('transportType')
        ?.value?.map(
          (val: { name: string; value: TransportType }) => val.value
        ),
      'max-distance': this.stripSign(filter.get('tripDistance')?.value?.value),
      'max-time': this.stripSign(filter.get('tripTime')?.value?.value),
      reason: filter
        .get('tripReason')
        ?.value?.map((reason: any) => reason.value),
      company_id: filter
        .get('company')
        ?.value?.map((company: Company) => company.id),
      office_id: officeFilter,
      autoRecorded: filter.get('autoRecorded')?.value ? true : undefined,
      homeOffice: filter.get('homeOffice')?.value ? true : undefined,
      causedByCompanyCar: causedByCompanyCar,
      'group-by': filter.get('groupBy')?.value,
    };
  }

  get overviewReports$(): Observable<ReportGrouped[]> {
    return this.overviewReports.asObservable();
  }

  getReportsGrouped(
    filter: ReportGroupedFilter,
    overview: boolean = false,
    demo: boolean = false
  ): Observable<ReportGrouped[]> {
    const response: Observable<ReportGrouped[]> = this.reportHttpService
      .getGrouped(filter)
      .pipe(
        mergeMap((dtoReports) => {
          const obs = dtoReports.map((report) => {
            const company$ = report.company
              ? this.companyService.get(report.company)
              : of(undefined);
            const office$ = report.office
              ? this.officeService.get(report.office)
              : of(undefined);
            return forkJoin([company$, office$]).pipe(
              map((data) => {
                return fromDto(report, data[1]!, data[0]!);
              })
            );
          });
          return obs.length > 0 ? forkJoin(obs) : of([]);
        })
      );

    return response;
  }

  getTrendReports(filter: ReportGroupedFilter): Observable<ReportGrouped[]> {
    const causedByCompanyCar: boolean = filter.causedByCompanyCar;
    filter.causedByCompanyCar = causedByCompanyCar == true ? true : undefined;

    const autoRecorded: boolean = filter.autoRecorded;
    filter.autoRecorded = autoRecorded == true ? true : undefined;

    const homeOffice: boolean = filter.homeOffice;
    filter.homeOffice = homeOffice == true ? true : undefined;

    if (filter['company']) {
      const company = filter['company'];
      if (company[0] && company[0]['id']) {
        filter.company_id = company[0]['id'];
      } else {
        filter.company_id = company[0];
      }
      filter['company'] = undefined;
    }
    return this.getReportsGrouped(filter);
  }

  filterReportsByOffice(filter: UntypedFormGroup, demo: boolean): void {
    this.getReportsByFilter(filter, demo);
  }

  getReportsByFilter(filter: UntypedFormGroup, demo: boolean = false): void {
    const response: Observable<ReportGrouped[]> = this.getReportsGrouped(
      ReportService.filterGroupToObject(filter),
      true,
      demo
    );
    response.subscribe((res: ReportGrouped[]) => {
      const reports = res.filter(
        (report) =>
          report !== undefined &&
          report.transportType !== undefined &&
          report.transportType !== null &&
          (report.transportType as string) !== ''
      );
      this.overviewReports.next(reports);
    });
  }
}
