import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../services/localstorage.service';
import { TokenUtil } from '../utils/token-util';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private silentRequest: SilentRequest = {
    scopes: [],
    forceRefresh: true,
    account: undefined,
  };

  private loginRequest = {
    scopes: [
      'openid',
      'api://1846eefe-5a38-4c9d-aa6c-80227245488e/Reports.UserRead',
      'api://1846eefe-5a38-4c9d-aa6c-80227245488e/Admin.DoAll',
      'group.Read.All',
    ],
  };
  constructor(private msalService: MsalService, public router: Router) {
    this.silentRequest.forceRefresh = true;
  }

  login(): Observable<any> {
    return this.msalService.loginRedirect(this.loginRequest).pipe();
  }

  logout() {
    this.msalService.logout().subscribe(() => {
      localStorage.setItem('id-token', '');
      this.msalService.instance.setActiveAccount(null);
      LocalStorageService.updatePreferedRoute(null);
    });

    this.silentRequest.account = undefined;
    this.silentRequest.scopes = [];
  }

  refresh(): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silentRequest).pipe(
      tap((result) => {
        localStorage.setItem('id-token', result.accessToken);
      })
    );
  }

  getAccessTokenRoles(): any[] {
    if (this.msalService.instance.getActiveAccount() === null) {
      return [];
    }
    const accessToken = localStorage.getItem('id-token');
    if (accessToken === null || accessToken === '') {
      return [];
    }

    return TokenUtil.getClaimFromToken(accessToken, 'roles');
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() !== null;
  }

  getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }
}
