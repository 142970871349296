import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { Office } from 'src/app/models/office';
import { SurveyResponse } from 'src/app/models/survey/response/survey-response';
import { OfficeService } from 'src/app/services/office.service';
type SurveyWithRespose = {
  title: string,
  description: string,
  isActive: boolean,
  createdAt: Date,
  startDate: Date,
  endDate: Date,
  appliedCompanies: string[],
  numberOfSubmits: number,
  companyNames: string[],
  responses: SurveyResponse[],
  officeAdresses: Office[]
};

@Component({
  selector: 'app-survey-filter',
  templateUrl: './survey-filter.component.html',
  styleUrls: ['./survey-filter.component.scss']
})
export class SurveyFilterComponent implements OnInit {
  @Input() survey: SurveyWithRespose[] = [];
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<any> = new EventEmitter();
  private translateService: TranslateService = inject(TranslateService);
  currentLang = this.translateService.currentLang;


  uniquePlatforms: string[] = [];
  uniqueVersions: string[] = [];
  uniqueOffices: string[] = [];
  isCustomRangeSelected: any;
  uniqueCompanyNames: string[] = [];
  uniqueOfficeAdresses: Office[] = [];
  selectedStatus: string = '';
  selectedCompany: string = '';
  selectedOffice: string = '';
  selectedPlatform: string = '';
  selectedAppVersion: string = '';
  selectedFeedbackForm: string = '';
  selectedTimeRange: string = ''; // 'lastWeek', 'lastMonth', etc.
  customStartDate: Date;
  customEndDate: Date;
  selectedSort: any;
  isFilterReset:boolean = false;



  constructor(
    private officeService: OfficeService,
  ) { }

  ngOnInit(): void {
    this.selectedTimeRange = 'allTime';
    this.uniqueVersions = [];
    this.uniquePlatforms = [];
    this.uniqueOffices = [];
    this.extractUniqueValues();

    if (this.currentLang === 'de') {
      const pickerStart = document.querySelectorAll('scale-date-picker')[0] as HTMLScaleDatePickerElement;
      const pickerEnd = document.querySelectorAll('scale-date-picker')[1] as HTMLScaleDatePickerElement;
      pickerEnd.localization = pickerStart.localization = {
        buttonLabel: 'Datum wählen',
        placeholder: 'TT.MM.JJJJ',
        selectedDateMessage: 'Gewähltes Datum',
        prevMonthLabel: 'Vorheriger Monat',
        nextMonthLabel: 'Nächster Monat',
        monthSelectLabel: 'Monat',
        yearSelectLabel: 'Jahr',
        closeLabel: 'Fenster schließen',
        keyboardInstruction:
          'Sie können mit den Pfeiltasten vor und zurück navigieren',
        calendarHeading: 'Datum wählen',
        dayNames: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        monthNames: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
        today: 'heute',
        locale: 'de-DE',
      };

      const DATE_FORMAT = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
      pickerStart.dateAdapter = pickerEnd.dateAdapter = {
        parse(value = '', createDate) {
          const matches = value.match(DATE_FORMAT);
          if (matches) {
            return createDate(matches[3], matches[2], matches[1]);
          }
        },
        format(date) {
          return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        },
      };
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.survey && !changes.survey.firstChange) {
      this.extractUniqueValues();
    }
  }



  private extractUniqueValues() {
    // Go through the responses and add unique values to the corresponding arrays
    this.uniquePlatforms = [];
    this.uniqueVersions = [];
    this.uniqueOffices = [];
    this.uniqueCompanyNames = [];
    this.uniqueOfficeAdresses = [];

    const officeObservables: Observable<Office>[] = [];

    this.survey.forEach(survey => {
      survey.companyNames.forEach(companyName => {
        if (!this.uniqueCompanyNames.includes(companyName)) {
          this.uniqueCompanyNames.push(companyName);
        }
      });

      survey.responses.forEach(response => {
        const { officeId } = response;
        const { platform, version } = response.app;

        if (!this.uniquePlatforms.includes(platform)) {
          this.uniquePlatforms.push(platform);
        }

        if (!this.uniqueVersions.includes(version)) {
          this.uniqueVersions.push(version);
        }

        if (!this.uniqueOffices.includes(officeId)) {
          this.uniqueOffices.push(officeId);
          if (officeId) officeObservables.push(this.getOfficeObservable(officeId));
        }
      });
    });

    if (officeObservables.length > 0) {
      // Wait for all officeObservables to complete
      forkJoin(officeObservables).subscribe(offices => {
        this.uniqueOfficeAdresses = offices;
        this.filterChanged.emit(this.getFilterParams());
      });
    } else {
      // If there are no officeObservables, emit filterChanged immediately
      this.filterChanged.emit(this.getFilterParams());
    }
  }

  private getOfficeObservable(officeId: string): Observable<Office> {
    return this.officeService.get(officeId);
  }

  // Implement methods to handle time-based filter changes
  onSelectTimeRange(range: string) {
    this.selectedTimeRange = range;
    this.customStartDate = this.customEndDate = null;
    this.filterChanged.emit(this.getFilterParams());
    if(this.selectedTimeRange != 'allTime')
    this.isFilterReset = true;
    else this.isFilterReset = false;
  }


  scaleChangeStart(e) {
    this.customStartDate = e.detail.value;
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }
  scaleChangeEnd(e) {
    this.customEndDate = e.detail.value;
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  // Implement methods to handle attribute-based filter changes
  onSelectCompany(e) {
    this.selectedCompany = this.selectedValue(e.detail.value);
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  onSelectOffice(e) {
    this.selectedOffice = this.selectedValue(e.detail.value);
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  onSelectStatus(e) {
    this.selectedStatus = this.selectedValue(e.detail.value);
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  onSelectPlatform(e) {
    this.selectedPlatform = this.selectedValue(e.detail.value);
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  onSelectAppVersion(e) {
    this.selectedStatus = this.selectedValue(e.detail.value);
    this.filterChanged.emit(this.getFilterParams());
    this.isFilterReset =true;

  }

  onSelectFeedbackForm(e) {
    this.filterChanged.emit(this.getFilterParams());
  }

  selectedValue(selectedValue: string, resetValue: string = ''): string {
    return selectedValue === 'All' ? resetValue : selectedValue;
  }
  sortByCriteria(e){
    this.selectedSort = e;
    this.sort.emit(e);
  }

  clearFilter() {
    this.selectedStatus = '';
    this.selectedCompany = '';
    this.onSelectTimeRange('allTime');
    this.filterChanged.emit(this.getFilterParams(true));
    this.sortByCriteria(this.selectedSort);
    this.isFilterReset = false;

  }

  private getFilterParams(clear?: boolean) {
    if (clear)
      return {};
    return {
      isActive: this.selectedStatus,
      timeRange: this.selectedTimeRange,
      customStartDate: this.customStartDate,
      customEndDate: this.customEndDate,
      company: this.selectedCompany,
      office: this.selectedOffice,
      platform: this.selectedPlatform,
      appVersion: this.selectedAppVersion,
      feedbackForm: this.selectedFeedbackForm,
    };
  }

}
