import { Office } from '../models/office';

export const Offices: Office[] = [
    {
      "zip": "01067",
      "city": "Dresden",
      "street": "Annenstr. 5",
      "id": "3868f31336f1d159dcc7c24657458821"
    },
    {
      "zip": "01067",
      "city": "Dresden",
      "street": "Webergasse 1",
      "id": "ff41c3bc32a34128f5c695d47aef4f50"
    },
    {
      "zip": "01069",
      "city": "Dresden",
      "street": "Lingner Allee 3",
      "id": "c07fc5f4c9c74a481f1775e25e676219"
    },
    {
      "zip": "01069",
      "city": "Dresden",
      "street": "Prager Str. 7",
      "id": "96cb21563041f9bcbf24679fdfa54da7"
    },
    {
      "zip": "01099",
      "city": "Dresden",
      "street": "Melitta-Bentz-Str. 10",
      "id": "4353abf61e0f1e3ead57c5f1cc41b853"
    },
    {
      "zip": "01099",
      "city": "Dresden",
      "street": "Melitta-Bentz-Str. 8",
      "id": "de92c28aae657a741a8d00cb565ab1f3"
    },
    {
      "zip": "01129",
      "city": "Dresden",
      "street": "Riesaer Str. 5",
      "id": "013f029a9c75cebcab2b6204c70342a4"
    },
    {
      "zip": "01129",
      "city": "Dresden",
      "street": "Riesaer Str. 7",
      "id": "39d83610115a3d94188d622892f457ec"
    },
    {
      "zip": "01129",
      "city": "Dresden",
      "street": "Riesaer Strasse 5",
      "id": "4c0b468bb0d862a967ef9ae54a902b25"
    },
    {
      "zip": "01139",
      "city": "Dresden",
      "street": "Washingtonstraße 16a",
      "id": "7cf202e9552af11cb60e9283e548bc4b"
    },
    {
      "zip": "01157",
      "city": "Dresden",
      "street": "Warthaer Str. 35",
      "id": "3abe119d2e365fe1b70c088e43f43ab3"
    },
    {
      "zip": "01187",
      "city": "Dresden",
      "street": "Zwickauer Str. 41-43",
      "id": "732aca35a67ab3b7ed27aa93c48bbcd5"
    },
    {
      "zip": "01239",
      "city": "Dresden",
      "street": "Dohnaer Str. 246",
      "id": "425ed8d91aab443177d2ca7288f2e60f"
    },
    {
      "zip": "01259",
      "city": "Dresden",
      "street": "Pirnaer Landstr. 248",
      "id": "2496828ed03ce3b9e271e3b41c1baf16"
    },
    {
      "zip": "01309",
      "city": "Dresden",
      "street": "Loschwitzer Str. 52",
      "id": "0592be470f1d18d34c4971d9e41b11e7"
    },
    {
      "zip": "01326",
      "city": "Dresden",
      "street": "Winzerstr. 6",
      "id": "35d101851d7f22ade3981fbb480ad15e"
    },
    {
      "zip": "01445",
      "city": "Radebeul",
      "street": "Dresdner Str. 78",
      "id": "123097983a666283c450d0a26c221f69"
    },
    {
      "zip": "01445",
      "city": "Radebeul",
      "street": "Dresdner Str. 78a/b",
      "id": "4a719080bc5784100fb0c9045c7d83d0"
    },
    {
      "zip": "01458",
      "city": "Ottendorf-Okrilla",
      "street": "Neue Str. 1",
      "id": "03b6bd5f843a794180cd71a4b360e99c"
    },
    {
      "zip": "01458",
      "city": "Ottendorf-Okrilla",
      "street": "Schutterwälder Str. 11",
      "id": "a6d4a1051b89f9e5960708550346b8dd"
    },
    {
      "zip": "01587",
      "city": "Riesa",
      "street": "Berliner Str. 27",
      "id": "885069f150878d0b78b7b317620617ba"
    },
    {
      "zip": "01662",
      "city": "Meißen",
      "street": "Dresdner Str. 3",
      "id": "b27114c611fb2e09f1f796c7d06e8cd6"
    },
    {
      "zip": "01662",
      "city": "Meißen",
      "street": "Fabrikstr. 18a",
      "id": "12a2f79c67b4638bfd870e19124e8f33"
    },
    {
      "zip": "01705",
      "city": "Freital",
      "street": "Güterstr. 1",
      "id": "bfbc50d2b3c151533a5bf18de8618aa5"
    },
    {
      "zip": "01796",
      "city": "Pirna",
      "street": "Prof.-Roßmäßler-Str. 45",
      "id": "d7f95260fa7d728a7daba4856b5acad8"
    },
    {
      "zip": "01917",
      "city": "Kamenz",
      "street": "Lessingplatz 20",
      "id": "aa753a0e714a8bd658f3506b42e11943"
    },
    {
      "zip": "01968",
      "city": "Senftenberg",
      "street": "Laugkfeld 5",
      "id": "98206eb209538a57bd2f1de514753fc8"
    },
    {
      "zip": "02625",
      "city": "Bautzen",
      "street": "Kornmarkt 7",
      "id": "0ecc8ae464b4923913f9899e58caac77"
    },
    {
      "zip": "02625",
      "city": "Bautzen",
      "street": "Löbauer Str. 63",
      "id": "ac54a73180e9d21e91b5f8aba0818818"
    },
    {
      "zip": "02625",
      "city": "Bautzen",
      "street": "Löbauerstr. 63",
      "id": "10aec776abb70ff8bf7a642f28017e7f"
    },
    {
      "zip": "02708",
      "city": "Löbau",
      "street": "äußere Zittauer Str. 56a",
      "id": "15eeb819910d40bb0051ad743aa6f52e"
    },
    {
      "zip": "02763",
      "city": "Zittau",
      "street": "Nordstr. 17",
      "id": "f6ba9981f017f6a58025e4b84495498f"
    },
    {
      "zip": "02826",
      "city": "Görlitz",
      "street": "Berliner Str. 63",
      "id": "4a1dc587b05c12b1b4ae843362c0886f"
    },
    {
      "zip": "02828",
      "city": "Görlitz",
      "street": "Am Klinikum 7",
      "id": "5eebea7d04589e4f0e4555abd92bd487"
    },
    {
      "zip": "02977",
      "city": "Hoyerswerda",
      "street": "Albert-Einstein-Str. 48",
      "id": "6746c7e4828df0c3e57b38ec535eda03"
    },
    {
      "zip": "02977",
      "city": "Hoyerswerda",
      "street": "Lausitzer Platz 1-3",
      "id": "c3df52a82ef44c0edf1dcb97c4d9a2f3"
    },
    {
      "zip": "03044",
      "city": "Cottbus",
      "street": "Heinrich-Hertz-Str. 6",
      "id": "a5ddb7661032fe891090b486e93dbad3"
    },
    {
      "zip": "03046",
      "city": "Cottbus",
      "street": "Karl-Liebknecht-Str. 136",
      "id": "422c456400c1977abd0bcb3eaa2afd61"
    },
    {
      "zip": "03050",
      "city": "Cottbus",
      "street": "Hänchener Str. 16",
      "id": "87c31a202f4ffb543aff1bc329341616"
    },
    {
      "zip": "03172",
      "city": "Guben",
      "street": "Am Gehege 26",
      "id": "fa9572874aa89c448008d0b53a4d7abf"
    },
    {
      "zip": "03205",
      "city": "Calau",
      "street": "Altnauer Strasse 138",
      "id": "44012c6246554fb67666026e70f4f0c3"
    },
    {
      "zip": "03238",
      "city": "Finsterwalde",
      "street": "Am Schackeplatz 8",
      "id": "be8ec7fbb8789615a92b604f7a6cb4dd"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Brandenburger Str. 11",
      "id": "c5c88b4d9e4340dd9505876f384440d6"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Querstr. 1",
      "id": "1729318213d998ac4812c6fc6965c649"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Querstr. 1-11",
      "id": "2f7144002bc40cb0775d4a34033b65a1"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Querstr. 1-9",
      "id": "16f6232c74c80daa20210c4118d323b7"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Querstr. 3",
      "id": "b79ccd9a8e5a2c7730a3e4d10945f820"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Querstraße 1",
      "id": "f5a798c343ff12c2232b407e647c2b14"
    },
    {
      "zip": "04103",
      "city": "Leipzig",
      "street": "Sachsenseite 2",
      "id": "7ff99d919a483b915a4f0b3e724eec44"
    },
    {
      "zip": "04105",
      "city": "Leipzig",
      "street": "Nordstr. 3-15",
      "id": "2c5fe4136f4fc6359a45feade57dd4e4"
    },
    {
      "zip": "04109",
      "city": "Leipzig",
      "street": "Dittrichring 22",
      "id": "0757a8e52f1b49997af9062a1ce615a6"
    },
    {
      "zip": "04109",
      "city": "Leipzig",
      "street": "Petersstr. 12-14",
      "id": "6c901e6b0987a367e5e4c18ffa72c8ed"
    },
    {
      "zip": "04109",
      "city": "Leipzig",
      "street": "Richard-Wagner-Platz 1",
      "id": "abdbfd580612fbe45bb86200c59cb5db"
    },
    {
      "zip": "04158",
      "city": "Leipzig",
      "street": "Hermann-Keller-Str. 72",
      "id": "af4589f914f8e97d02564415216a0216"
    },
    {
      "zip": "04179",
      "city": "Leipzig",
      "street": "Silcherstr. 2a",
      "id": "d104be2f3b35120dc44a9d9b9c68b625"
    },
    {
      "zip": "04229",
      "city": "Leipzig",
      "street": "Zschochersche Str. 69",
      "id": "c01cb8411fc47fda25931a05aa6b516e"
    },
    {
      "zip": "04277",
      "city": "Leipzig",
      "street": "Gustav-Freytag-Str. 43-45",
      "id": "c6af0c3436ec3e0c5c8817360abf421c"
    },
    {
      "zip": "04279",
      "city": "Leipzig",
      "street": "Newtonstr. 2",
      "id": "e2aa828123fa00de909f3808d6e9edb9"
    },
    {
      "zip": "04288",
      "city": "Leipzig",
      "street": "Kärrnerstr. 66",
      "id": "fe66e5291b9873bb7b7a80b66e363d77"
    },
    {
      "zip": "04328",
      "city": "Leipzig",
      "street": "Lehdenweg 71",
      "id": "22dfb347e360799965d0cff38110b1be"
    },
    {
      "zip": "04329",
      "city": "Leipzig",
      "street": "Paunsdorfer Allee 1",
      "id": "1a1f35ecc7deb935e0e82a092562fc3c"
    },
    {
      "zip": "04552",
      "city": "Borna Stadt",
      "street": "Altenburger Str. 14",
      "id": "6fccae488d13b1778a4656415ac2601d"
    },
    {
      "zip": "04552",
      "city": "Borna Stadt",
      "street": "Brauhausstr. 2",
      "id": "e63c762ef2ee51855257c6857953ef91"
    },
    {
      "zip": "04600",
      "city": "Altenburg",
      "street": "Keplerplatz 5",
      "id": "56f19cca542f88fb5f5ae539edaa5a01"
    },
    {
      "zip": "04668",
      "city": "Grimma",
      "street": "Leipziger Str. 19",
      "id": "efa251817929d02fc890bae7dcb96bf5"
    },
    {
      "zip": "04720",
      "city": "Döbeln",
      "street": "Ziegelstr. 12",
      "id": "308dffe0abc961b56a3131f947e9f23e"
    },
    {
      "zip": "04758",
      "city": "Oschatz",
      "street": "Am Langen Rain 85",
      "id": "ece73aedffb5a90924c6cf361ff6f132"
    },
    {
      "zip": "04808",
      "city": "Wurzen",
      "street": "Industriestr. 4",
      "id": "2a76f0c797b8b3c01d071d6d2704d5ec"
    },
    {
      "zip": "04808",
      "city": "Wurzen",
      "street": "Lüptitzer Str. 30",
      "id": "f39b5a09fcb10c4e4653c4c3e8315fad"
    },
    {
      "zip": "04838",
      "city": "Eilenburg",
      "street": "Kospaer Landstr. 18",
      "id": "cb8c8fe05a30930ac0a406995bffd5a8"
    },
    {
      "zip": "04860",
      "city": "Torgau",
      "street": "Gewerbering 3",
      "id": "8807c50dbbb2d3fb2ac486deb0f21e14"
    },
    {
      "zip": "04910",
      "city": "Elsterwerda",
      "street": "Lessingstr. 18",
      "id": "37f170238fd9b85a1769f8614252e3e0"
    },
    {
      "zip": "06108",
      "city": "Halle",
      "street": "Hansering 20",
      "id": "58756459f06a3a1ac51f0f62a23c1fa4"
    },
    {
      "zip": "06108",
      "city": "Halle",
      "street": "Leipziger Str. 87-92",
      "id": "df2ecc2d0b20a70ad5d41f2eb99acbc7"
    },
    {
      "zip": "06118",
      "city": "Halle",
      "street": "Dessauer Str. 26",
      "id": "512a70376659bd16f9b933c90e25ff62"
    },
    {
      "zip": "06118",
      "city": "Halle",
      "street": "Franzosensteinweg 104",
      "id": "cfa79c92c8683ad308eafc4197735857"
    },
    {
      "zip": "06120",
      "city": "Halle",
      "street": "Kolkturmring 40",
      "id": "c225915e37940f663505c50205a1c0d6"
    },
    {
      "zip": "06128",
      "city": "Halle",
      "street": "Kaiserslauterer Str. 75",
      "id": "1375c79ff1a3ad94ca7787031254f42b"
    },
    {
      "zip": "06179",
      "city": "Angersdorf",
      "street": "Die Langen Klägen",
      "id": "69e1cc64960cfda4d72698b1ddbcbe45"
    },
    {
      "zip": "06258",
      "city": "Schkopau",
      "street": "Friedrich-Wöhler-Str. 12",
      "id": "ca32bda6710100a659587f96c1b4be8b"
    },
    {
      "zip": "06295",
      "city": "Eisleben",
      "street": "Schloßplatz 6",
      "id": "6ae8b9cdb0a6de5b05a75634a4cf2b7b"
    },
    {
      "zip": "06385",
      "city": "Aken (elbe)",
      "street": "Kaiserstr. 30",
      "id": "57f5837a4d84c2fb3bd6feee4acb1d04"
    },
    {
      "zip": "06406",
      "city": "Bernburg (saale)",
      "street": "Eichenweg 1",
      "id": "677ff4e7232f6337c5e6472d052c7e3a"
    },
    {
      "zip": "06449",
      "city": "Aschersleben",
      "street": "Herrenbreite 7",
      "id": "55e64ed931cf8d72b3a2bb6dd20a785a"
    },
    {
      "zip": "06484",
      "city": "Quedlinburg",
      "street": "Klopstockweg 47",
      "id": "b43b05224f60f35add1c686b933a19e3"
    },
    {
      "zip": "06526",
      "city": "Sangerhausen",
      "street": "Fröbelstr. 19a",
      "id": "1428faaf547e5bfdb586861707cf35ba"
    },
    {
      "zip": "06526",
      "city": "Sangerhausen",
      "street": "Kleines Kirchholz 2",
      "id": "a76a0e2fb8f9c0f769e642b4a748515e"
    },
    {
      "zip": "06556",
      "city": "Artern",
      "street": "Weinbergstr. 4",
      "id": "e5d1a1e6b7a75cea56a2262127a0a9a2"
    },
    {
      "zip": "06618",
      "city": "Naumburg",
      "street": "Steinkreuzweg 12",
      "id": "662041fb258693cc0c2a306f872ded89"
    },
    {
      "zip": "06667",
      "city": "Weißenfels",
      "street": "Tagewerbener Str. 92",
      "id": "8ceacd8918c5f61264d2a92b3b04102f"
    },
    {
      "zip": "06686",
      "city": "Lützen",
      "street": "Hallesche Str. 8a",
      "id": "33c0366d55f55e4bdc691f74acde26ab"
    },
    {
      "zip": "06712",
      "city": "Zeitz",
      "street": "Rasberger Str. 5",
      "id": "7187a73e72c925e8f54d67741814b1ed"
    },
    {
      "zip": "06749",
      "city": "Bitterfeld",
      "street": "Lindenstr. 12b",
      "id": "7c1a98100230d7e17850dc8f6cd05846"
    },
    {
      "zip": "06844",
      "city": "Dessau-Roßlau",
      "street": "Kavalierstr. 24-26",
      "id": "21363c38bb360a47198b0a2e73917f15"
    },
    {
      "zip": "06844",
      "city": "Dessau-Roßlau",
      "street": "Kavalierstr. 49",
      "id": "f7f45840489fc46dfb6e338486099941"
    },
    {
      "zip": "06847",
      "city": "Dessau-Roßlau",
      "street": "Kochstedter Kreisstr. 11",
      "id": "3b1cc36986f2b66dffd42642636baaf7"
    },
    {
      "zip": "06886",
      "city": "Lutherstadt Wittenberg",
      "street": "Arsenalplatz 1",
      "id": "0cedeea305d976196e07a1f029366def"
    },
    {
      "zip": "06886",
      "city": "Wittenberg",
      "street": "Triftstr. 15",
      "id": "e47c445ccf663e57faf774cc3026ea1d"
    },
    {
      "zip": "07318",
      "city": "Saalfeld",
      "street": "Am Brendelsgarten 1",
      "id": "d4ad4bf721ecfe1f8980d45eb6991f42"
    },
    {
      "zip": "07318",
      "city": "Saalfeld",
      "street": "Am Brendelsgarten 1,3, 5",
      "id": "21bedcac611471b0379ff4f847ec49ec"
    },
    {
      "zip": "07381",
      "city": "Pößneck",
      "street": "Mittelweg 37",
      "id": "e11b3ccec92059f3da1348f237e3bedc"
    },
    {
      "zip": "07545",
      "city": "Gera",
      "street": "Heinrichstr. 30",
      "id": "1f040154f7b7ead88c8078dba51ce06d"
    },
    {
      "zip": "07548",
      "city": "Gera",
      "street": "Strasse Des Friedens 200",
      "id": "63c4e809063fcd5da082d2d9b5a24736"
    },
    {
      "zip": "07548",
      "city": "Gera",
      "street": "Straße Des Friedens 200",
      "id": "888e4af008b6be407790689c480dd499"
    },
    {
      "zip": "07552",
      "city": "Gera",
      "street": "Jacob-A.-Morand-Str. 4",
      "id": "48fc6b0ecc8c6be60470e79f16608a80"
    },
    {
      "zip": "07552",
      "city": "Gera",
      "street": "Otto-Oettel-Str. 3e",
      "id": "892796439345f8bae756f9a2f7984ab3"
    },
    {
      "zip": "07743",
      "city": "Jena",
      "street": "Goethestr. 3",
      "id": "e8bd462e3786c8fd9fc4896b1411cb97"
    },
    {
      "zip": "07743",
      "city": "Jena",
      "street": "Goethestr. 3b",
      "id": "16ee1b9a96cd4d32c2a0309ab0b1b98b"
    },
    {
      "zip": "07743",
      "city": "Jena",
      "street": "Goethestraße 3b",
      "id": "c7f492b548216dd25fb07c6857b15bd4"
    },
    {
      "zip": "07747",
      "city": "Jena",
      "street": "Erich-Hallbauer-Weg 8",
      "id": "d859b23a2b035a5215de03124d2a75f6"
    },
    {
      "zip": "07768",
      "city": "Kahla",
      "street": "Hohe Str. 14",
      "id": "a691f918efda7b5d056e44996127ed59"
    },
    {
      "zip": "07907",
      "city": "Schleiz",
      "street": "Fröbelstr. 13",
      "id": "22ec4ea3a2b37c18a0979c43ba97dc2f"
    },
    {
      "zip": "08056",
      "city": "Zwickau",
      "street": "Innere Plauensche Str. 6",
      "id": "936f6e39b3b298396b98fc8fb43a5205"
    },
    {
      "zip": "08058",
      "city": "Zwickau",
      "street": "Rudolf-Ehrlich-Str. 7",
      "id": "8d84782aa9090cfd9d04969d7746a34d"
    },
    {
      "zip": "08060",
      "city": "Zwickau",
      "street": "Bülaustr. 33a",
      "id": "66561051bcfdbd75fd0148dc115af894"
    },
    {
      "zip": "08062",
      "city": "Zwickau",
      "street": "Pestalozzistr. 22a",
      "id": "431db6d4a7bb352901cae309e62a19d7"
    },
    {
      "zip": "08064",
      "city": "Cainsdorf",
      "street": "Wilkauer Str. 25",
      "id": "6beed460224be7757f189329107dba1a"
    },
    {
      "zip": "08228",
      "city": "Rodewisch",
      "street": "Busbahnhof 2",
      "id": "92a003cc61623711c1cf5f5f252c9933"
    },
    {
      "zip": "08258",
      "city": "Markneukirchen",
      "street": "Clara-Wieck-Ring 30",
      "id": "a923c1a51be3af48d08a2fcaa023109e"
    },
    {
      "zip": "08280",
      "city": "Aue",
      "street": "Poststr. 2",
      "id": "f4cefcec29047103bec553c1dab98ca3"
    },
    {
      "zip": "08289",
      "city": "Schneeberg",
      "street": "Lessingstr. 7",
      "id": "51b6165a6dc0b636c7c68514f8ac2b13"
    },
    {
      "zip": "08340",
      "city": "Schwarzenberg",
      "street": "Schneeberger Str. 11",
      "id": "e2b7cfa4db2a6bd25eab239a80caa8c4"
    },
    {
      "zip": "08371",
      "city": "Glauchau",
      "street": "Leipziger Str. 62",
      "id": "f6f5964ff3f61e1870bfaf56ccf06c5c"
    },
    {
      "zip": "08451",
      "city": "Crimmitschau",
      "street": "August-Colditz-Str. 3",
      "id": "9775d3d0dcfd180e62cc06534337de4b"
    },
    {
      "zip": "08523",
      "city": "Plauen",
      "street": "Bahnhofstr. 11-15",
      "id": "83b02d7a495d4dda2bdcad5c4713e470"
    },
    {
      "zip": "08523",
      "city": "Plauen",
      "street": "Wildstr. 1",
      "id": "319eb807ead45b4ef43efae58ca16f0f"
    },
    {
      "zip": "08529",
      "city": "Plauen",
      "street": "Alte Reichenbacher Str. 1",
      "id": "55a94fc650932eb2e87881fe35fe8999"
    },
    {
      "zip": "09111",
      "city": "Chemnitz",
      "street": "Minna-Simon-Str. 1",
      "id": "89170890b6b1cbf1578289edfa64771f"
    },
    {
      "zip": "09111",
      "city": "Chemnitz",
      "street": "Minna-Simon-Str. 1-5",
      "id": "4e21f878f0a17159f38cced936a48dcd"
    },
    {
      "zip": "09111",
      "city": "Chemnitz",
      "street": "Rathausstr. 7",
      "id": "9782442d2bee1295721f4809de95b6a8"
    },
    {
      "zip": "09111",
      "city": "Chemnitz",
      "street": "Strasse Der Nationen 76",
      "id": "1cdbe7bd1995a8197cdc016097dd006f"
    },
    {
      "zip": "09111",
      "city": "Chemnitz",
      "street": "Straße Der Nationen 76",
      "id": "9a7aa0ddf08410a48737e1f4b0260fb3"
    },
    {
      "zip": "09112",
      "city": "Chemnitz",
      "street": "Reichsstr. 15",
      "id": "63fed694bf5a7f2ef443314e7a62b56e"
    },
    {
      "zip": "09120",
      "city": "Chemnitz",
      "street": "Scheffelstr. 2",
      "id": "01b6f09dc540fb1853d5f134dcd5ad47"
    },
    {
      "zip": "09126",
      "city": "Chemnitz",
      "street": "Clausstr. 3",
      "id": "f151968e873f1069bcb0be9e38587307"
    },
    {
      "zip": "09126",
      "city": "Chemnitz",
      "street": "Reichenhainer Str. 68",
      "id": "0d9bfdd40d30d746aa297fcabce12503"
    },
    {
      "zip": "09126",
      "city": "Chemnitz",
      "street": "Reichenhainer Str. 68a",
      "id": "ffc01d3e3c787b4ca7fde06566686b63"
    },
    {
      "zip": "09130",
      "city": "Chemnitz",
      "street": "Thomas-Mann-Platz 1b",
      "id": "0bb0cb2257d8a5d0c0d5eeb903a461f5"
    },
    {
      "zip": "09212",
      "city": "Limbach-Oberfrohna",
      "street": "Weststr. 51",
      "id": "89da44db3bdce8dbb707f1ae87932feb"
    },
    {
      "zip": "09247",
      "city": "Chemnitz",
      "street": "Ringstr. 52",
      "id": "9a71bd43a87cf50c7f7874dab2875f5b"
    },
    {
      "zip": "09306",
      "city": "Rochlitz",
      "street": "Lindenallee 4",
      "id": "c4dab18ed0ed939f026de98868fbc82c"
    },
    {
      "zip": "09337",
      "city": "Hohenstein-Ernstthal",
      "street": "Grenzweg 13",
      "id": "d3753da126fd33daedd562e88165b40f"
    },
    {
      "zip": "09366",
      "city": "Stollberg/erzgeb.",
      "street": "Zu Den Teichen",
      "id": "d3d50939693256e00d0818b05c2305b7"
    },
    {
      "zip": "09405",
      "city": "Zschopau",
      "street": "Fritz-Heckert-Str. 24",
      "id": "eef854113ebaa7f08589d7d31ca265f9"
    },
    {
      "zip": "09456",
      "city": "Annaberg-Buchholz",
      "street": "Gewerbering 18",
      "id": "7c2eb94b23cca5aaf49b307c9b8c5f9c"
    },
    {
      "zip": "09456",
      "city": "Annaberg-Buchholz",
      "street": "Gewerbering 2",
      "id": "c164f6b126a9c58fadb3c7125a5ed8ea"
    },
    {
      "zip": "09456",
      "city": "Annaberg-Buchholz",
      "street": "Straße Der Freundschaft 5",
      "id": "0573b960c42bd306029d2a5733c5ef4f"
    },
    {
      "zip": "09487",
      "city": "Schlettau",
      "street": "Buchholzer Str. 41",
      "id": "a916b77b1aa6ea08de802b1f1846c959"
    },
    {
      "zip": "09496",
      "city": "Marienberg",
      "street": "Poststr. 13",
      "id": "dd4defc207f211577af1fc0ef92cb40a"
    },
    {
      "zip": "09599",
      "city": "Freiberg",
      "street": "Franz-Kögler-Ring 18h",
      "id": "47ab7440e4ffb05596942b48845ff1e0"
    },
    {
      "zip": "09661",
      "city": "Hainichen",
      "street": "Frankenberger Str. 8",
      "id": "aaad402e113e01c35fbd290bc57435b9"
    },
    {
      "zip": "10117",
      "city": "Berlin",
      "street": "Französische Str. 33",
      "id": "5ab21b00bdd0b34514d7ea2823fcf21d"
    },
    {
      "zip": "10117",
      "city": "Berlin",
      "street": "Französische Str. 33a-C",
      "id": "ecd76c11f9326cc85144bf2a1c88cdb8"
    },
    {
      "zip": "10117",
      "city": "Berlin",
      "street": "Friedrichstr. 155-156",
      "id": "a63ca1b4c6afe539d7400e8586ca666b"
    },
    {
      "zip": "10117",
      "city": "Berlin",
      "street": "Leipziger Platz 12",
      "id": "5494589a0ee6824c48f870eba35659e5"
    },
    {
      "zip": "10117",
      "city": "Berlin",
      "street": "Wilhelmstr. 55",
      "id": "d912a530f7401cdac9d061684e5249ef"
    },
    {
      "zip": "10178",
      "city": "Berlin",
      "street": "Alexanderplatz 8",
      "id": "967c86390ccbd8afaec01f24464113f5"
    },
    {
      "zip": "10319",
      "city": "Berlin",
      "street": "Sewanstr. 260",
      "id": "a9d18f1f0b4f8a30ddfc7ee966d2cfad"
    },
    {
      "zip": "10365",
      "city": "Berlin",
      "street": "Buchberger Str. 4",
      "id": "6993b79cbcf527762343a9cd432f32a3"
    },
    {
      "zip": "10365",
      "city": "Berlin",
      "street": "Buchberger Str. 4-12",
      "id": "ae6bdc52e79522d4735271c17bcc008a"
    },
    {
      "zip": "10365",
      "city": "Berlin",
      "street": "Frankfurter Allee 113",
      "id": "1445f81beb828afc0beb57050c6c747a"
    },
    {
      "zip": "10367",
      "city": "Berlin",
      "street": "Dottistr. 1-4",
      "id": "73dba0335b893c01bbe0789295454526"
    },
    {
      "zip": "10367",
      "city": "Berlin",
      "street": "Dottistr. 3-4",
      "id": "f1d8f043acc7514b9b3b50331182be6b"
    },
    {
      "zip": "10407",
      "city": "Berlin",
      "street": "Storkower Str. 110",
      "id": "5756b76ded43578260e6145d57f2dac7"
    },
    {
      "zip": "10439",
      "city": "Berlin",
      "street": "Schönhauser Allee 78-82",
      "id": "0a7337eb3070c32e5817dde629af171f"
    },
    {
      "zip": "10555",
      "city": "Berlin",
      "street": "Levetzowstr. 11",
      "id": "d5ed3c21021ea15e37b105d2d11c55da"
    },
    {
      "zip": "10557",
      "city": "Berlin",
      "street": "Rahel-Hirsch-Str. 10",
      "id": "ef36b35f7bbdb7d39ea0169a75730218"
    },
    {
      "zip": "10587",
      "city": "Berlin",
      "street": "Ernst-Reuter-Platz 7",
      "id": "4aaa4df7cbead287a73e4a97e2ff2447"
    },
    {
      "zip": "10587",
      "city": "Berlin",
      "street": "Pascalstr. 11",
      "id": "7d3f7ba09eb2f7a982c79e8eb1d1f3d5"
    },
    {
      "zip": "10627",
      "city": "Berlin",
      "street": "Wilmersdorfer Str. 50",
      "id": "dbae981d4f5efefa560aebe982c0400c"
    },
    {
      "zip": "10627",
      "city": "Berlin",
      "street": "Wilmersdorfer Str. 55",
      "id": "0a6d08fe1ec9c1054850f3aad17c9213"
    },
    {
      "zip": "10717",
      "city": "Berlin",
      "street": "Bundesallee 23",
      "id": "5ba07b7ef868d72e45ccef77d10462b7"
    },
    {
      "zip": "10719",
      "city": "Berlin",
      "street": "Kurfürstendamm 200",
      "id": "f3777d3e4dcb922a2085e81c69c6b28a"
    },
    {
      "zip": "10719",
      "city": "Berlin",
      "street": "Kurfürstendamm 229",
      "id": "3b1a45cde625f2790b9299ed7f7ee156"
    },
    {
      "zip": "10719",
      "city": "Berlin",
      "street": "Kurfürstendamm 235",
      "id": "53128ba8f1ca9e539f76771edca10a6a"
    },
    {
      "zip": "10781",
      "city": "Berlin",
      "street": "Winterfeldstraße 21",
      "id": "12cfdfaa29bd864e7d6565fcc25c13e3"
    },
    {
      "zip": "10781",
      "city": "Berlin",
      "street": "Winterfeldtstr. 21",
      "id": "c231ac4c463f2c3941f6f0f1ea7fb888"
    },
    {
      "zip": "10781",
      "city": "Berlin",
      "street": "Winterfeldtstr. 27",
      "id": "21a64f922f0489323107f86f1b215593"
    },
    {
      "zip": "10785",
      "city": "Berlin",
      "street": "Körnerstr. 7-10",
      "id": "26c432b7f7c666298f8d013349bbbd4f"
    },
    {
      "zip": "10785",
      "city": "Berlin",
      "street": "Pohlstr. 40",
      "id": "93acf6d05b70869e7e0b52cfa3f1870c"
    },
    {
      "zip": "10967",
      "city": "Berlin",
      "street": "Hermannplatz/mittelinsel 0",
      "id": "a1d7cfa603ea87c4b4cbb0fbba2c3a84"
    },
    {
      "zip": "12103",
      "city": "Berlin",
      "street": "Kaiserin-Augusta-Str. 76-77",
      "id": "f4fa672ee04998a9880f0eb5902e51b9"
    },
    {
      "zip": "12103",
      "city": "Berlin",
      "street": "Kaiserin-Augusta-Str. 76-77",
      "id": "68663a6677ec9ee37916ddf968b225de"
    },
    {
      "zip": "12103",
      "city": "Berlin",
      "street": "Ringbahnstr. 126-134",
      "id": "d11897c45d4d186f22cc390d98d55463"
    },
    {
      "zip": "12103",
      "city": "Berlin",
      "street": "Ringbahnstr. 130",
      "id": "12b424f5a93921204149957cfa433db3"
    },
    {
      "zip": "12163",
      "city": "Berlin",
      "street": "Schloßstr. 34-36",
      "id": "2bb62d36d795ed37245229351c7f78e6"
    },
    {
      "zip": "12167",
      "city": "Berlin",
      "street": "Birkbuschstr. 49-51",
      "id": "a6eee477ea98f417eeef79dd9bfa1513"
    },
    {
      "zip": "12209",
      "city": "Berlin",
      "street": "Lankwitzer Str. 13-17",
      "id": "e4b3054ffe3d0c7a6e15e79865d648c6"
    },
    {
      "zip": "12347",
      "city": "Berlin",
      "street": "Hannemannstr. 6-14",
      "id": "89175b1dc3d0c9b53cc55126f8d1f993"
    },
    {
      "zip": "12351",
      "city": "Berlin",
      "street": "Johannisthalerchaussee 295-327",
      "id": "e8e286dd53df190192a063412d062735"
    },
    {
      "zip": "12487",
      "city": "Berlin",
      "street": "Groß-Berliner-Damm 80A",
      "id": "68bf356c4c684116cbdb04fca7e869e2"
    },
    {
      "zip": "12555",
      "city": "Berlin",
      "street": "Bahnhofstr. 33-38",
      "id": "3650ef84707edb8350e6884455b9624a"
    },
    {
      "zip": "12619",
      "city": "Berlin",
      "street": "Hellersdorfer Str. 80",
      "id": "7c71eef0975dcae177ef2bc81736d592"
    },
    {
      "zip": "12679",
      "city": "Berlin",
      "street": "Marzahner Promenade 1",
      "id": "d7487243a83fed2bd5509181c8e9dded"
    },
    {
      "zip": "13051",
      "city": "Berlin",
      "street": "Ribnitzer Str. 45",
      "id": "9712f67a6813cf96062c2fca1f94a0ba"
    },
    {
      "zip": "13055",
      "city": "Berlin",
      "street": "Landsberger Allee 207",
      "id": "12dbedb2d5063dd6b4675f0c8de2bfc8"
    },
    {
      "zip": "13089",
      "city": "Berlin",
      "street": "Tino-Schwierzina-Str. 36",
      "id": "7eb6f473e442adac92feb7bc6e205fc7"
    },
    {
      "zip": "13089",
      "city": "Berlin",
      "street": "Tino-Schwierzina-Str. 38",
      "id": "ab3646578c248415030f80e3ae3d87fd"
    },
    {
      "zip": "13347",
      "city": "Berlin",
      "street": "Gerichtstr. 50-51",
      "id": "1fc18dfd1e43655c092c6c8461287b62"
    },
    {
      "zip": "13357",
      "city": "Berlin",
      "street": "Badstr. 4",
      "id": "95986de1e7038ec48217d893b7eeab2f"
    },
    {
      "zip": "13403",
      "city": "Berlin",
      "street": "General-Woyna-Str. 46",
      "id": "b3ddc84dd9050ca56f28d4f6ed864926"
    },
    {
      "zip": "13407",
      "city": "Berlin",
      "street": "Emmentaler Str. 76E",
      "id": "d2014728625f70daed8ce42fbb249882"
    },
    {
      "zip": "13509",
      "city": "Berlin",
      "street": "Holzhauser Str. 4-8",
      "id": "a919f9d898ba901bd097459ba9feddfc"
    },
    {
      "zip": "13597",
      "city": "Berlin",
      "street": "Carl-Schurz-Str. 40",
      "id": "039765a95821c86c53dc3baad48beb3a"
    },
    {
      "zip": "13627",
      "city": "Berlin",
      "street": "Letterhausweg 1",
      "id": "6814fe6491ff461c1abac1897666c870"
    },
    {
      "zip": "14052",
      "city": "Berlin",
      "street": "Thüringer Allee 12",
      "id": "87c65a5ab8cffa900ccf8aee9ba34128"
    },
    {
      "zip": "14057",
      "city": "Berlin",
      "street": "Dernburgstr. 50",
      "id": "e4b7ee483229ce1e64e55fd93ddb62ff"
    },
    {
      "zip": "14163",
      "city": "Berlin",
      "street": "Forststr. 2-4",
      "id": "1033ca6850a53a0cf915cbcb22b0ed74"
    },
    {
      "zip": "14467",
      "city": "Potsdam",
      "street": "Behlertstr. 3A",
      "id": "b967af22511c2c00696135f01ec2fbb0"
    },
    {
      "zip": "14469",
      "city": "Potsdam",
      "street": "Verlängerte Amtsstr. 4",
      "id": "36ce5e8cbbf610d6a5ab45b992f2b6d6"
    },
    {
      "zip": "14473",
      "city": "Potsdam",
      "street": "Babelsbergerstr. 16",
      "id": "763eed46b6562ecbb51fe64ea03126f0"
    },
    {
      "zip": "14478",
      "city": "Potsdam",
      "street": "An Der Alten Zauche 4A",
      "id": "72fce650707b542019ee346b5c10ef0f"
    },
    {
      "zip": "14480",
      "city": "Potsdam",
      "street": "Zum Kirchsteigfeld 2",
      "id": "7066b99261d368b750f103068a7216eb"
    },
    {
      "zip": "14482",
      "city": "Potsdam",
      "street": "Konsumhof 1-5",
      "id": "b664c51db58c40bc0590db20d17c3f3b"
    },
    {
      "zip": "14513",
      "city": "Teltow",
      "street": "Schönower Str. 3",
      "id": "7d69320e56b3fbab2c07a9eb99b10f4e"
    },
    {
      "zip": "14532",
      "city": "Stahnsdorf",
      "street": "Güterfelder Damm 87-91",
      "id": "8c0562eb7915d7be1287aa6071631d7a"
    },
    {
      "zip": "14542",
      "city": "Werder (havel)",
      "street": "Wachtelwinkel 6-8",
      "id": "5c4fb80bc297550f0173de10d433bf86"
    },
    {
      "zip": "14547",
      "city": "Beelitz",
      "street": "Berliner Str. 182A",
      "id": "f7727e6de76aab3c2b25791b4e78c52e"
    },
    {
      "zip": "14552",
      "city": "Michendorf",
      "street": "Flottsteller Str. 43",
      "id": "8d4e1188dbcec75706d780a8963610f2"
    },
    {
      "zip": "14612",
      "city": "Falkensee",
      "street": "Scharenbergstr. 7",
      "id": "4f94f8a58227752a759c05502e5d79f4"
    },
    {
      "zip": "14624",
      "city": "Dallgow-Döberitz",
      "street": "Döberitzer Weg 3",
      "id": "b149ad9fe1f02d3a6642f6173c572416"
    },
    {
      "zip": "14641",
      "city": "Nauen",
      "street": "Parkstr. 5",
      "id": "30e6758c683f5c58530a2972cfe20bd1"
    },
    {
      "zip": "14712",
      "city": "Rathenow",
      "street": "Wilhelm-Külz-Str. 1",
      "id": "1052ba79b630c76e4766413ec3acbc21"
    },
    {
      "zip": "14776",
      "city": "Brandenburg An Der Havel",
      "street": "Deutsches Dorf 48",
      "id": "3b7de7bf8793b05d3935480c297043d7"
    },
    {
      "zip": "14776",
      "city": "Brandenburg An Der Havel",
      "street": "Sankt-Annen-Str. 23",
      "id": "4379b4cce11695cc626ecc4b5efd3b08"
    },
    {
      "zip": "14776",
      "city": "Brandenburg An Der Havel",
      "street": "Warschauer Str. 15B",
      "id": "1af8507638940479b9e7db166341c2ef"
    },
    {
      "zip": "14797",
      "city": "Kloster Lehnin Ot Krahne",
      "street": "Am Wald 1",
      "id": "3be812aacc281f94632399ec38418698"
    },
    {
      "zip": "14943",
      "city": "Luckenwalde",
      "street": "Dessauer Str. 25",
      "id": "94127db756e4f31806571027d48dc049"
    },
    {
      "zip": "14943",
      "city": "Luckenwalde",
      "street": "Dessauer Str. 25B",
      "id": "a34cdc20f3981ef8664861f7dea63dfb"
    },
    {
      "zip": "14959",
      "city": "Trebbin",
      "street": "Bahnhofstr. 29",
      "id": "176a081aee354b4e254f39efe3d20ac4"
    },
    {
      "zip": "15230",
      "city": "Frankfurt (oder)",
      "street": "Karl-Marx-Str. 192-193",
      "id": "54c72ff071502a8781f0de6577e1f78a"
    },
    {
      "zip": "15232",
      "city": "Frankfurt (oder)",
      "street": "Heinrich-Hildebrand-Str. 10",
      "id": "cef881cb803a51a695ef03a2220a6395"
    },
    {
      "zip": "15234",
      "city": "Frankfurt (oder)",
      "street": "Spitzkrugring 10",
      "id": "8d20ea486d74d8612359e74c076e70c8"
    },
    {
      "zip": "15306",
      "city": "Seelow",
      "street": "Straße Der Jugend 38",
      "id": "cadb7a4cdb2972630ee5d403295d4732"
    },
    {
      "zip": "15344",
      "city": "Strausberg",
      "street": "Landhausstr. 20",
      "id": "2372823bb6c9eba5f52c9f09b36d0d5a"
    },
    {
      "zip": "15344",
      "city": "Strausberg",
      "street": "Mühlenweg 4",
      "id": "805bd3d1ee4237d1e65310058674b207"
    },
    {
      "zip": "15517",
      "city": "Fürstenwalde",
      "street": "Eisenbahnstr. 158-159",
      "id": "094f7bee84bc9d7e5dddd03e57d801fc"
    },
    {
      "zip": "15537",
      "city": "Erkner",
      "street": "Friedensplatz 7",
      "id": "85474403b75c20e7369156c112d34c40"
    },
    {
      "zip": "15562",
      "city": "Rüdersdorf",
      "street": "Schulzenhöher Weg 1",
      "id": "6c82646c12ed7f49505bf19ba987b6cd"
    },
    {
      "zip": "15711",
      "city": "Königs Wusterhausen",
      "street": "Funkerberg 21",
      "id": "bd4478abb7cf825c5ba51c4c556a1291"
    },
    {
      "zip": "15711",
      "city": "Königs Wusterhausen",
      "street": "Funkerberg 6",
      "id": "d0a8d2aa3cfc6cea744d347e58f8e68f"
    },
    {
      "zip": "15745",
      "city": "Wildau",
      "street": "Chausseestr. 1",
      "id": "40677d41b023fb06003a4e737339000f"
    },
    {
      "zip": "15745",
      "city": "Wildau",
      "street": "Freiheitstr. 124-126",
      "id": "db50556cde55382f74630ace3ac733f6"
    },
    {
      "zip": "15806",
      "city": "Zossen",
      "street": "Am Scheunenviertel 1A",
      "id": "7a536798a11d2c479921d48766717152"
    },
    {
      "zip": "15831",
      "city": "Mahlow",
      "street": "Glasower Damm 3",
      "id": "75ee0deb3bf010f91c388f7a5c0651b9"
    },
    {
      "zip": "15848",
      "city": "Beeskow",
      "street": "Fürstenwalder Str. 6A",
      "id": "30d542d78be51576af864d485e07c866"
    },
    {
      "zip": "15890",
      "city": "Eisenhüttenstadt",
      "street": "Poststr. 13A",
      "id": "752d32e534d494d85e16d952e1431c9a"
    },
    {
      "zip": "15907",
      "city": "Lübben",
      "street": "Puschkinstr. 14",
      "id": "75ea47a983d1dab2a40eca53319410ed"
    },
    {
      "zip": "15926",
      "city": "Luckau",
      "street": "Nordpromenade 14",
      "id": "32b1a7cf3c7987097335b392de0e680c"
    },
    {
      "zip": "16225",
      "city": "Eberswalde",
      "street": "Berger Str. 111",
      "id": "81fa3bfc75bd620189a867dd95f3f2b0"
    },
    {
      "zip": "16278",
      "city": "Angermünde",
      "street": "Schwedter Str. 34",
      "id": "d684a9ef2aad33cf8d62bcbcdd8edace"
    },
    {
      "zip": "16321",
      "city": "Bernau",
      "street": "Börnicker Chaussee 1",
      "id": "9ffc1d43e699b75aefe82853b32d3ae6"
    },
    {
      "zip": "16321",
      "city": "Bernau",
      "street": "Rüdnitzer Chaussee 46",
      "id": "9b506eccc1288aac7a53203a3b602b16"
    },
    {
      "zip": "16356",
      "city": "Ahrensfelde",
      "street": "Landsberger Chaussee 17",
      "id": "8f1628c5a3833b8bf8ae91c1ddcc8511"
    },
    {
      "zip": "16359",
      "city": "Biesenthal",
      "street": "Grüner Weg 45",
      "id": "65dd7b98728aa77cfdb6051941f43d8c"
    },
    {
      "zip": "16515",
      "city": "Oranienburg",
      "street": "Schulstr. 4-8",
      "id": "ec98a3e4bfe6f9d9017e07c8890e57cb"
    },
    {
      "zip": "16547",
      "city": "Birkenwerder",
      "street": "Clara-Zetkin-Str. 14",
      "id": "3c150f43f9fc9738944de691146f2ab1"
    },
    {
      "zip": "16727",
      "city": "Velten",
      "street": "Poststr. 1/bahnstr.",
      "id": "230c03c94493d9fa99528c41eb1e0e24"
    },
    {
      "zip": "16792",
      "city": "Zehdenick",
      "street": "Steindammer Weg 50-51",
      "id": "915e2453af9f23bdcac33b52ed5b5774"
    },
    {
      "zip": "16816",
      "city": "Neuruppin",
      "street": "Junkerstr. 0",
      "id": "399b33ec8189f31af393eb410fadb1bc"
    },
    {
      "zip": "16816",
      "city": "Neuruppin",
      "street": "Martin-Ebell-Str. 15",
      "id": "f384fae2e22e3fed348ae2f0d5145027"
    },
    {
      "zip": "16816",
      "city": "Neuruppin",
      "street": "Wilhelm-Bartelt-Str. 2-6",
      "id": "ed3c19b4f83d8c45b0e2eb69eb4aa054"
    },
    {
      "zip": "17033",
      "city": "Neubrandenburg",
      "street": "Bergstr. 76",
      "id": "60dc1727b6b3a2e50953fa04cf6c9661"
    },
    {
      "zip": "17033",
      "city": "Neubrandenburg",
      "street": "Marktplatz 2",
      "id": "934fcd64b531b2da0dc9a6b764996e56"
    },
    {
      "zip": "17034",
      "city": "Neubrandenburg",
      "street": "Ponyweg 33",
      "id": "7ddbb33ce8a595ba6ffe6bee83eb2988"
    },
    {
      "zip": "17036",
      "city": "Neubrandenburg",
      "street": "An Der Hochstr. 1-8",
      "id": "41f80162491a99afb725ec88dcae467e"
    },
    {
      "zip": "17036",
      "city": "Neubrandenburg",
      "street": "An Der Hochstr. 8",
      "id": "746c26f089bc17006d055ffdcd177167"
    },
    {
      "zip": "17094",
      "city": "Burg Stargard",
      "street": "Am Rowaer Forst 1",
      "id": "315a1a1a7cea4fa5ad6bd898940f8bda"
    },
    {
      "zip": "17109",
      "city": "Demmin",
      "street": "Gartenstr. 7",
      "id": "bf42916409aa9ab06c9dc93406f2c2bd"
    },
    {
      "zip": "17139",
      "city": "Malchin",
      "street": "Poststr. 1",
      "id": "fde2bf33e360240f40cb72026784074d"
    },
    {
      "zip": "17192",
      "city": "Waren (müritz)",
      "street": "Dietrich-Bonhoeffer-Str. 15",
      "id": "beceeb23f1776643ab222d60db40ec29"
    },
    {
      "zip": "17192",
      "city": "Waren (müritz)",
      "street": "Mecklenburger Str. 30",
      "id": "b8f267baf553ae326705ac8a7c5b7490"
    },
    {
      "zip": "17235",
      "city": "Neustrelitz",
      "street": "Ernst-Moritz-Arndt-Str. 51",
      "id": "e1433e933d54e68fe669cdf7a261e629"
    },
    {
      "zip": "17268",
      "city": "Templin",
      "street": "Vietmannsdorfer Str. 27",
      "id": "23f072deecb043b3bf839c718fd44a77"
    },
    {
      "zip": "17291",
      "city": "Prenzlau",
      "street": "Brüssower Allee 99",
      "id": "d1ce086fa6da2b37ddd71f6b458a0643"
    },
    {
      "zip": "17358",
      "city": "Torgelow",
      "street": "Feldstr. 10",
      "id": "33abf8651b592ac54c8b93b75c0e2efa"
    },
    {
      "zip": "17358",
      "city": "Torgelow",
      "street": "Feldstr. 7A",
      "id": "8b3f7cd1a0522a8b25442282996c57aa"
    },
    {
      "zip": "17389",
      "city": "Anklam",
      "street": "Eckstr. 7",
      "id": "de7a5571da3b8ca2d155f8c4b95e895f"
    },
    {
      "zip": "17424",
      "city": "Ostseebad Heringsdorf",
      "street": "Schulstr. 30",
      "id": "573b67be9ecf9ae87ea6f82279f4c1fb"
    },
    {
      "zip": "17438",
      "city": "Wolgast",
      "street": "Holzweg 2",
      "id": "fa9c7571ca7281c52833aa234f1f5436"
    },
    {
      "zip": "17489",
      "city": "Greifswald Hansestadt",
      "street": "Herrenhufen Str. 9",
      "id": "ce82ab877d4c364073a47843cadc64e7"
    },
    {
      "zip": "17489",
      "city": "Greifswald Hansestadt",
      "street": "Schuhhagen 21",
      "id": "b8d7dc499a51d6eed7e4df4b408b20a2"
    },
    {
      "zip": "17491",
      "city": "Greifswald Hansestadt",
      "street": "Hans-Beimler-Str. 16A",
      "id": "230930999ef652fad46fac8d62b4f3d3"
    },
    {
      "zip": "18055",
      "city": "Rostock",
      "street": "Kröpeliner Str. 43",
      "id": "5becb480a66822a3b734007bb7ba490d"
    },
    {
      "zip": "18055",
      "city": "Rostock",
      "street": "Kröpeliner Str. 9",
      "id": "ba09b6ca9ec3fb9ba6b77f1c59aa43da"
    },
    {
      "zip": "18069",
      "city": "Rostock",
      "street": "Schwarzer Weg 2",
      "id": "eda0240524cad94b00507f478113ffd2"
    },
    {
      "zip": "18146",
      "city": "Rostock",
      "street": "Kurt-Schumacher-Ring 1A",
      "id": "f44506f830ca98c4c36b6eca1e12014c"
    },
    {
      "zip": "18198",
      "city": "Kritzmow",
      "street": "Biestower Weg 20",
      "id": "64104c96f5f6366242523b0575251176"
    },
    {
      "zip": "18198",
      "city": "Kritzmow",
      "street": "Biestower Weg 6A",
      "id": "13231334eba35f9c20a1a5c32be00c04"
    },
    {
      "zip": "18198",
      "city": "Kritzmow",
      "street": "Klein Schwaßer Weg 7-8",
      "id": "71cd30341babf4667a37e6c0b8477679"
    },
    {
      "zip": "18273",
      "city": "Güstrow",
      "street": "Neukruger Str. 7",
      "id": "c5a6b024e73748c7898c2b20c736088b"
    },
    {
      "zip": "18273",
      "city": "Güstrow",
      "street": "Neukruger Str. 9",
      "id": "08c5babbf3f87654ff9e6c636e19aedf"
    },
    {
      "zip": "18311",
      "city": "Ribnitz-Damgarten",
      "street": "Scheunenweg 3B",
      "id": "d4164e9066519df00147e08e71052a2d"
    },
    {
      "zip": "18437",
      "city": "Stralsund",
      "street": "Barther Str. 72",
      "id": "564bd1e64d41d8370ee8109b0ec08cc2"
    },
    {
      "zip": "18439",
      "city": "Stralsund",
      "street": "Ossenreyer Str. 44/ 45",
      "id": "76ec3878a1ac3353547c28ca54253bd6"
    },
    {
      "zip": "18528",
      "city": "Bergen Auf Rügen",
      "street": "Clementstr. 8",
      "id": "2b7f06b7cc05d1c3fce7cb620343286b"
    },
    {
      "zip": "18528",
      "city": "Bergen Auf Rügen",
      "street": "Königsstr. 23D",
      "id": "52df4603c6593afe3566a3144066b4d0"
    },
    {
      "zip": "18569",
      "city": "Schaprode",
      "street": "Hafenweg 44B",
      "id": "deacf3c539ea68fb9615d20fd57b197b"
    },
    {
      "zip": "19053",
      "city": "Schwerin",
      "street": "Marienplatz 5-6",
      "id": "b569d000506c3d3a8dc26900a37b6ad3"
    },
    {
      "zip": "19057",
      "city": "Schwerin",
      "street": "Grevesmühlener Str. 36",
      "id": "9ecab417ca43b1414eed26a82c85caf2"
    },
    {
      "zip": "19230",
      "city": "Hagenow",
      "street": "Bekower Weg 8",
      "id": "f1385c8eec5e9e4be41695b93259d2aa"
    },
    {
      "zip": "19348",
      "city": "Perleberg",
      "street": "Kurmärker Str. 10",
      "id": "b2d6a8fbc6ac0e52d9e413c29e470101"
    },
    {
      "zip": "19370",
      "city": "Parchim",
      "street": "Pestalozziweg 23",
      "id": "8c9e177016c7483fcb2beb8d38a5e284"
    },
    {
      "zip": "19386",
      "city": "Lübz",
      "street": "Industriestr. 2",
      "id": "a8c40cbb4c68a4bebac3aafb4f787240"
    },
    {
      "zip": "20095",
      "city": "Hamburg",
      "street": "Mönckebergstr. 20",
      "id": "85cbd50b0d7e09d220952f4afd6c508c"
    },
    {
      "zip": "20095",
      "city": "Hamburg",
      "street": "Spitalerstr. 9",
      "id": "219c1dd72b32101cda62558e18ead04f"
    },
    {
      "zip": "20249",
      "city": "Hamburg",
      "street": "Eppendorfer Landstr. 23",
      "id": "610e8985ff861a65b7325dbf0e14e8b9"
    },
    {
      "zip": "20249",
      "city": "Hamburg",
      "street": "Eppendorfer Landstr. 77",
      "id": "e9229ce6ff16498e8d9091ffd46851ae"
    },
    {
      "zip": "20251",
      "city": "Hamburg",
      "street": "Christoph-Probst-Weg 28",
      "id": "7ac0b8866b17766dd75780c24beb8904"
    },
    {
      "zip": "20255",
      "city": "Hamburg",
      "street": "Heußweg 37-39",
      "id": "440c5bceb17b6311782c2c43c06c546b"
    },
    {
      "zip": "20357",
      "city": "Hamburg",
      "street": "Messeplatz 1",
      "id": "f8cb3026a29cb11386add2d1c3d3a4b6"
    },
    {
      "zip": "20359",
      "city": "Hamburg",
      "street": "Budapester Str. 18",
      "id": "b8af25d184512a845ee318fd8a1a3afc"
    },
    {
      "zip": "20535",
      "city": "Hamburg",
      "street": "Carl-Petersen-Str. 5",
      "id": "30a1298c07f487658160b4b54ce82f73"
    },
    {
      "zip": "20539",
      "city": "Hamburg",
      "street": "Billhorner Mühlenweg 14",
      "id": "7a6532da0169c92ed67ca9293f513684"
    },
    {
      "zip": "21029",
      "city": "Hamburg",
      "street": "Sachsentor 8",
      "id": "c2ce34467b2348c6657b4c0be9064eeb"
    },
    {
      "zip": "21029",
      "city": "Hamburg",
      "street": "Stuhlrohrstr. 15",
      "id": "e10098b111809d8bcfbc9ef694c69de1"
    },
    {
      "zip": "21033",
      "city": "Hamburg",
      "street": "Oberer Landweg 27",
      "id": "0f570c34310b9216f053087990883b9e"
    },
    {
      "zip": "21035",
      "city": "Hamburg",
      "street": "Rungedamm 3",
      "id": "2faad8400ee079fa3952aafeeb3f07de"
    },
    {
      "zip": "21035",
      "city": "Hamburg",
      "street": "Rungedamm 37B",
      "id": "16011347fc39d3e54c70c1eee04c90ca"
    },
    {
      "zip": "21073",
      "city": "Hamburg",
      "street": "Buxtehuder Str. 25",
      "id": "c501fb3927c8e70417a9dd254dc4818f"
    },
    {
      "zip": "21073",
      "city": "Hamburg",
      "street": "Lüneburger Str. 41",
      "id": "545fb4e74f93595d64b821673bba51bd"
    },
    {
      "zip": "21075",
      "city": "Hamburg",
      "street": "Weusthoffstr. 52",
      "id": "2779be4b3a3ac9349e050d17aaf2efae"
    },
    {
      "zip": "21077",
      "city": "Hamburg",
      "street": "Elfenwiese 4",
      "id": "ff1347dbe84c48a6d54e72fe5993bccd"
    },
    {
      "zip": "21079",
      "city": "Hamburg",
      "street": "Großmoordamm 63",
      "id": "2dabb7134651b193585a04febc0da4ba"
    },
    {
      "zip": "21079",
      "city": "Hamburg",
      "street": "Hannoversche Str. 86",
      "id": "9c30f39fda78a8a3c078da964253ddcd"
    },
    {
      "zip": "21079",
      "city": "Hamburg",
      "street": "Schellerdamm 16",
      "id": "d15bf7c00b09116b07c1b647ad5c69ce"
    },
    {
      "zip": "21149",
      "city": "Hamburg",
      "street": "Cuxhavener Str. 96",
      "id": "3465d751aac96f8cf8abe966f28f07b5"
    },
    {
      "zip": "21217",
      "city": "Seevetal",
      "street": "Höpenstr. 9",
      "id": "baa6bd21503f0e0715f64ad54444b8d1"
    },
    {
      "zip": "21244",
      "city": "Buchholz",
      "street": "Bürgerm.-Adolf-Meyer-Str. 3",
      "id": "fd25356c26a48c5c8c784227ca0f958d"
    },
    {
      "zip": "21266",
      "city": "Jesteburg",
      "street": "Klecker Waldweg 12",
      "id": "600decd6320262368c06663be780b506"
    },
    {
      "zip": "21335",
      "city": "Lüneburg",
      "street": "Große Bäckerstr. 15",
      "id": "cfa6a28e00948735396c5313af5459e6"
    },
    {
      "zip": "21335",
      "city": "Lüneburg",
      "street": "Soltauer Str. 6",
      "id": "a4cdc8b53e054807acfcdc34c2a50f05"
    },
    {
      "zip": "21339",
      "city": "Lüneburg",
      "street": "Arenskule 10",
      "id": "5a0cc77b6767804ee2d799c89e0541f3"
    },
    {
      "zip": "21423",
      "city": "Winsen",
      "street": "Ernststr. 5-7",
      "id": "14d20efe875c76de5e89a1eb6b234665"
    },
    {
      "zip": "21502",
      "city": "Geesthacht",
      "street": "Trift 32",
      "id": "c78e90407b9f51bd10691363bb2bb6d8"
    },
    {
      "zip": "21509",
      "city": "Glinde",
      "street": "Oher Weg 12",
      "id": "c9e55e1fd1d49c5fb99feb6120f515cf"
    },
    {
      "zip": "21614",
      "city": "Buxtehude",
      "street": "Bahnhofstr. 6",
      "id": "66ca8db97fa36d7df64b5406eeaf740d"
    },
    {
      "zip": "21614",
      "city": "Buxtehude",
      "street": "Goethestr. 1",
      "id": "b8bcb7477cae9e1339f8d818f5e020c6"
    },
    {
      "zip": "21614",
      "city": "Buxtehude",
      "street": "Lange Str. 14",
      "id": "48a0689d2a4e7d315964c6b0d0c4810c"
    },
    {
      "zip": "21682",
      "city": "Stade",
      "street": "Glückstädter Str. 23",
      "id": "b4de7f41abc7922aac9522e53319df65"
    },
    {
      "zip": "21682",
      "city": "Stade",
      "street": "Pferdemarkt 4",
      "id": "33172a62aec9dd430a8b994a9bde2db9"
    },
    {
      "zip": "21745",
      "city": "Hemmoor",
      "street": "Am Heidberg 2",
      "id": "9da6422facf50e0df51f6c3ed07a2440"
    },
    {
      "zip": "22041",
      "city": "Hamburg",
      "street": "Wandsbeker Marktstr. 79",
      "id": "5a819d5148f999583ced877fd5c4491f"
    },
    {
      "zip": "22047",
      "city": "Hamburg",
      "street": "Friedrich-Ebert-Damm 111",
      "id": "a44669e20ab775353b8b89f9d86696b5"
    },
    {
      "zip": "22089",
      "city": "Hamburg",
      "street": "Schellingstr. 23-25",
      "id": "7636323dadc7a24cc90e5d5b974b5f0d"
    },
    {
      "zip": "22111",
      "city": "Hamburg",
      "street": "Bauerbergweg 23-25",
      "id": "5b6ea8fe03b4967e9e99ddfc5646848a"
    },
    {
      "zip": "22111",
      "city": "Hamburg",
      "street": "Billstedter Platz 29",
      "id": "d069e14ffdd16c541ffea3ea38177760"
    },
    {
      "zip": "22143",
      "city": "Hamburg",
      "street": "Buchwaldstr. 5",
      "id": "d5f0d548736a9ce6ddc8fcac1f1ee26a"
    },
    {
      "zip": "22297",
      "city": "Hamburg",
      "street": "überseering 2",
      "id": "6ba09aa577e468debef67780b8a24ff7"
    },
    {
      "zip": "22305",
      "city": "Hamburg",
      "street": "Fuhlsbüttler Str. 29",
      "id": "bcb7058115fa6589771ddec26b751e9b"
    },
    {
      "zip": "22309",
      "city": "Hamburg",
      "street": "Schmachthäger Str. 10",
      "id": "e8f74340eb10dbfddd6ae69e2ab6eb89"
    },
    {
      "zip": "22335",
      "city": "Hamburg",
      "street": "Heschredder 14-20",
      "id": "55e741e3007b2278fafed621a80893c5"
    },
    {
      "zip": "22391",
      "city": "Hamburg",
      "street": "Heegbarg 33",
      "id": "5b83d70e966ec6887cab197b7955b279"
    },
    {
      "zip": "22399",
      "city": "Hamburg",
      "street": "Schulbergredder 23",
      "id": "605ec0a01f9c01ca9625afd67389eebb"
    },
    {
      "zip": "22459",
      "city": "Hamburg",
      "street": "Tibarg 41",
      "id": "c32a3b0cdb2777d7044dd11006fed530"
    },
    {
      "zip": "22525",
      "city": "Hamburg",
      "street": "Kieler Str. 499",
      "id": "45dad196f5fc85b10edb64d102d243ec"
    },
    {
      "zip": "22547",
      "city": "Hamburg",
      "street": "Lüttkamp 47",
      "id": "ef6b89f1acdb001fee71df20099019ee"
    },
    {
      "zip": "22589",
      "city": "Hamburg",
      "street": "Osdorfer Landstr. 394",
      "id": "306d8ff391cd44f536d6f33513888b69"
    },
    {
      "zip": "22609",
      "city": "Hamburg",
      "street": "Osdorfer Landstr. 131",
      "id": "6535aec88d738ba7a2e6b27931040b39"
    },
    {
      "zip": "22761",
      "city": "Hamburg",
      "street": "Lyserstr. 18-20",
      "id": "2481ee2dfd9e4978338e2d5db3a7b274"
    },
    {
      "zip": "22765",
      "city": "Hamburg",
      "street": "Ottenser Hauptstr. 27",
      "id": "c989245c1abf49b20b0e214e7f1270b6"
    },
    {
      "zip": "22769",
      "city": "Hamburg",
      "street": "Langenfelder Str. 122",
      "id": "07ab3be24762556e78169d548caced15"
    },
    {
      "zip": "22850",
      "city": "Norderstedt",
      "street": "Berliner Allee 38",
      "id": "d2f86a3349b92d36897e8163fda8c725"
    },
    {
      "zip": "22880",
      "city": "Wedel",
      "street": "Bahnhofstr. 31",
      "id": "2168e455021d1d3975983f0e22ce9d1e"
    },
    {
      "zip": "22880",
      "city": "Wedel",
      "street": "Eichendorffweg 4",
      "id": "45319625ca40eb59d08364651ff4ed8f"
    },
    {
      "zip": "22926",
      "city": "Ahrensburg",
      "street": "Hamburger Str. 4-8",
      "id": "7e864149f6db5e1aefb96fabba1beaf1"
    },
    {
      "zip": "23552",
      "city": "Lübeck",
      "street": "Breite Str. 50",
      "id": "37c9ced044c4812f0d8f8399c3be06ab"
    },
    {
      "zip": "23554",
      "city": "Lübeck",
      "street": "Fackenburger Allee 31B",
      "id": "cbb0cb93b132f3b127de1e5d1f47f761"
    },
    {
      "zip": "23554",
      "city": "Lübeck",
      "street": "Fackenburger Allee 31b- 33",
      "id": "06489a2c45da4cdc547746981a0cd9af"
    },
    {
      "zip": "23556",
      "city": "Lübeck",
      "street": "Herrenholz 18",
      "id": "6a6d2aa1b00e4f9752888280683ba0f2"
    },
    {
      "zip": "23701",
      "city": "Eutin",
      "street": "Am Mühlenberg 2",
      "id": "e9147657c2c885a50f14d224da1eb91e"
    },
    {
      "zip": "23730",
      "city": "Neustadt/holstein",
      "street": "Ziegeleiweg 40",
      "id": "3ff5374e948196b5d8c0e5c127732134"
    },
    {
      "zip": "23795",
      "city": "Bad Segeberg",
      "street": "Gieschenhagen 4",
      "id": "7efbf4b6bc29229f037f4541737ea374"
    },
    {
      "zip": "23843",
      "city": "Bad Oldesloe",
      "street": "Hagenstr. 5-12",
      "id": "4d57509cfcd9c208f71bd2694c0fa7e7"
    },
    {
      "zip": "23879",
      "city": "Mölln",
      "street": "Feldstr. 19",
      "id": "8c693fbbbe6a09903af46e5c0c084e05"
    },
    {
      "zip": "23936",
      "city": "Grevesmühlen",
      "street": "Grüner Weg 0",
      "id": "c88019c5f7d8bdcb70036ca3ffce78f1"
    },
    {
      "zip": "23966",
      "city": "Wismar",
      "street": "Hinter Dem Rathaus 10-12",
      "id": "7471b0e8f0bf0340923ac8e335576b65"
    },
    {
      "zip": "23966",
      "city": "Wismar",
      "street": "Philipp-Müller-Str. 42",
      "id": "1ed3329588ff4d9595fd19331142a2a1"
    },
    {
      "zip": "24103",
      "city": "Kiel",
      "street": "Holstenstr. 96",
      "id": "c29782af93353e89e2046c2199833b60"
    },
    {
      "zip": "24107",
      "city": "Kiel",
      "street": "Suchskrug 1-3",
      "id": "a493f77dbcb5ebc4fd77d77e818ff8da"
    },
    {
      "zip": "24116",
      "city": "Kiel",
      "street": "Kronshagener Weg 101-107",
      "id": "bab423af6bb8045064f58123340ddac2"
    },
    {
      "zip": "24116",
      "city": "Kiel",
      "street": "Kronshagener Weg 105",
      "id": "d5495cbe93bff61f5d9dc40bce32dc2c"
    },
    {
      "zip": "24116",
      "city": "Kiel",
      "street": "Kronshagener Weg 105-107",
      "id": "e21a5b0edd58a0b7c6e3394a39a923af"
    },
    {
      "zip": "24116",
      "city": "Kiel",
      "street": "Kronshagener Weg 107",
      "id": "1f0fb0e5c0732a6e550f600fad068467"
    },
    {
      "zip": "24116",
      "city": "Kiel",
      "street": "Krusenrotter Weg 71",
      "id": "c06fb803f16d80f4b9433a4fdf81d2a0"
    },
    {
      "zip": "24119",
      "city": "Kronshagen",
      "street": "Zum Posthorn 1",
      "id": "0d3e8a1a4267a511591d1e9ee87da4c1"
    },
    {
      "zip": "24143",
      "city": "Kiel",
      "street": "Zur Helling 4",
      "id": "5506ed1075d43788bc77ab704127cefd"
    },
    {
      "zip": "24146",
      "city": "Kiel",
      "street": "Preetzer Chaussee 200",
      "id": "93d396b8f9110d8287cd85936f24a2f5"
    },
    {
      "zip": "24223",
      "city": "Raisdorf",
      "street": "Liebigstr. 3",
      "id": "6da63b351734c36f2d6f7c236f1a5500"
    },
    {
      "zip": "24223",
      "city": "Schwentinental",
      "street": "Liebigstr. 3",
      "id": "98db985da96838566741ef11bafb1976"
    },
    {
      "zip": "24340",
      "city": "Eckernförde",
      "street": "Kieler Str. 22",
      "id": "6e54cc1f6f21d4bd6223765948d64e61"
    },
    {
      "zip": "24340",
      "city": "Eckernförde",
      "street": "Kolm 6",
      "id": "e2a248b01f67c3f73652489c0e5b3f39"
    },
    {
      "zip": "24392",
      "city": "Süderbrarup",
      "street": "Bismarckstr. 21",
      "id": "146bbec28928d665fd9ae3a7fb42e0ca"
    },
    {
      "zip": "24534",
      "city": "Neumünster",
      "street": "Gänsemarkt 1",
      "id": "44d030f798d47f16351952eb489a5af3"
    },
    {
      "zip": "24534",
      "city": "Neumünster",
      "street": "Sedanstr. 15-15B",
      "id": "f373c062c5c1d2e21ac7ef73a208662a"
    },
    {
      "zip": "24568",
      "city": "Kaltenkirchen",
      "street": "Brookweg 48",
      "id": "8abbae64cafd64de6aac279663f93d47"
    },
    {
      "zip": "24768",
      "city": "Rendsburg",
      "street": "Am Holstentor 14-16",
      "id": "668c9bb91e5d1ef6373c23eae405de81"
    },
    {
      "zip": "24768",
      "city": "Rendsburg",
      "street": "Mühlenstr. 1",
      "id": "172507e74e9109ad772100cfb5a34172"
    },
    {
      "zip": "24768",
      "city": "Rendsburg",
      "street": "Wyker Str. 11-17",
      "id": "f6677f676bb67950b9a3d70c6d8f6636"
    },
    {
      "zip": "24837",
      "city": "Schleswig",
      "street": "Schwarzer Weg 13-17",
      "id": "e09aac2c6b55e3e0818f7734e6dbd636"
    },
    {
      "zip": "24837",
      "city": "Schleswig",
      "street": "Stadtweg 52",
      "id": "31b2a2b695f301d0a915cf0e4c86443f"
    },
    {
      "zip": "24937",
      "city": "Flensburg",
      "street": "Holm 60",
      "id": "2d3517f88c0070dfab6da6eb1c8d609b"
    },
    {
      "zip": "24941",
      "city": "Flensburg",
      "street": "Eckernförder Landstr. 65",
      "id": "4f54c8816b79ad6dd70d3183e32a51d1"
    },
    {
      "zip": "24941",
      "city": "Flensburg",
      "street": "Schleswiger Str. 130",
      "id": "6ef96d015ef3bd939e755f28dcb744d0"
    },
    {
      "zip": "25335",
      "city": "Elmshorn",
      "street": "Königstr. 25",
      "id": "a3e1ea47c5e4e3fe435ff92226630771"
    },
    {
      "zip": "25336",
      "city": "Elmshorn",
      "street": "Heinrich-Hertz-Str. 18-32A",
      "id": "4ae82637e617d6cb05a483fd6ca8b8ae"
    },
    {
      "zip": "25336",
      "city": "Elmshorn",
      "street": "Heinrich-Hertz-Str. 20",
      "id": "26e319f2ce1931df1b74950c2a89224c"
    },
    {
      "zip": "25336",
      "city": "Elmshorn",
      "street": "Heinrich-Hertz-Str. 32A",
      "id": "96a2c8879658ecaa8ec367e43a18b6a9"
    },
    {
      "zip": "25337",
      "city": "Elmshorn",
      "street": "Hamburger Str. 147",
      "id": "50121b87a39ecd07143245bc361bd02d"
    },
    {
      "zip": "25421",
      "city": "Pinneberg",
      "street": "Fahltskamp 22",
      "id": "8ba12e69fbf6c911c0212008ce06bd5a"
    },
    {
      "zip": "25524",
      "city": "Itzehoe",
      "street": "Feldschmiede 50",
      "id": "b61c744c1b732607d003ca6a97130741"
    },
    {
      "zip": "25524",
      "city": "Itzehoe",
      "street": "Poststr. 9",
      "id": "7e2e9bdd1480f2cbe36a7ebd0fe89a5d"
    },
    {
      "zip": "25524",
      "city": "Itzehoe",
      "street": "Suder Allee 22",
      "id": "39a98d1148db11fedde8e2c6e49375a5"
    },
    {
      "zip": "25709",
      "city": "Marne",
      "street": "Claus-Harms-Str. 3-7",
      "id": "7c25d2a0287e6d7a7f6af7fd9eb376d8"
    },
    {
      "zip": "25746",
      "city": "Heide",
      "street": "Am Kleinbahnhof 18-30",
      "id": "1d29f95b488fa126229ee58666d8a1d2"
    },
    {
      "zip": "25746",
      "city": "Heide",
      "street": "Markt 10",
      "id": "161307542e9390eed444e2aaefb7feb2"
    },
    {
      "zip": "25813",
      "city": "Husum",
      "street": "Industriestr. 36-42",
      "id": "5615c3ecdda1230376cbb4150a7b8dff"
    },
    {
      "zip": "25813",
      "city": "Husum",
      "street": "Schiffbrücke 16",
      "id": "dd34b6710559003a87860aefb6afec58"
    },
    {
      "zip": "25980",
      "city": "Westerland",
      "street": "Friedrichstr. 32",
      "id": "0a30c03cb19d3d4ab592a28a411160ab"
    },
    {
      "zip": "25980",
      "city": "Westerland",
      "street": "Stephanstr. 14",
      "id": "c2a9c5cf5e4cd790a6af19ce9d410632"
    },
    {
      "zip": "26122",
      "city": "Oldenburg",
      "street": "Lange Str. 15",
      "id": "d569717ccabb77d9e72e09509ae7a66b"
    },
    {
      "zip": "26122",
      "city": "Oldenburg",
      "street": "Lange Str. 47",
      "id": "bcceba52bc78656a427c0a667c316ec7"
    },
    {
      "zip": "26122",
      "city": "Oldenburg",
      "street": "Poststr. 1",
      "id": "c11c0ca6536cb2ae779da462c0f37265"
    },
    {
      "zip": "26122",
      "city": "Oldenburg",
      "street": "Poststr. 1-3",
      "id": "d56ea0dbe01451371b9b1f0c7f9bd00d"
    },
    {
      "zip": "26122",
      "city": "Oldenburg",
      "street": "Schlossplatz 3",
      "id": "b940508795cf22e7a15541a68185807f"
    },
    {
      "zip": "26129",
      "city": "Oldenburg",
      "street": "Ammerländer Heerstr. 138",
      "id": "7da4f645c3e7b4568e7197a0c35757cb"
    },
    {
      "zip": "26129",
      "city": "Oldenburg",
      "street": "Ammerländer Heerstr. 138-140",
      "id": "9a37b9891cbfcb12683da479a99b5836"
    },
    {
      "zip": "26129",
      "city": "Oldenburg",
      "street": "Posthalterweg 17",
      "id": "6f7ebc83d4f3f785f4c250a26a1c78f0"
    },
    {
      "zip": "26169",
      "city": "Friesoythe",
      "street": "Burkamp 12",
      "id": "93e263889a9008cf06a6cb1a35801052"
    },
    {
      "zip": "26316",
      "city": "Varel",
      "street": "Bürgerm.-Heidenreich-Str. 2",
      "id": "533e5e9f4a982a7c566a5e4ed440dee9"
    },
    {
      "zip": "26340",
      "city": "Zetel",
      "street": "Neuenburger Str. 7",
      "id": "9ac9f67c7eb00eaff8485e2227fed22a"
    },
    {
      "zip": "26382",
      "city": "Wilhelmshaven",
      "street": "Goethestr. 19",
      "id": "e20f25323bc053f7304ca4292736cfe0"
    },
    {
      "zip": "26382",
      "city": "Wilhelmshaven",
      "street": "Marktstr. 60",
      "id": "ad0cc33bc40ee8a0e061a7a3ab13a612"
    },
    {
      "zip": "26382",
      "city": "Wilhelmshaven",
      "street": "Schillerstr. 30",
      "id": "59ce6c2afb2909405939b599f658caea"
    },
    {
      "zip": "26409",
      "city": "Wittmund",
      "street": "Osterstr. 5A",
      "id": "2cff2381d3415a6b3e55ee058b969730"
    },
    {
      "zip": "26427",
      "city": "Esens",
      "street": "Vor Dem Drostentor 1",
      "id": "2447992b52d072170ef6a0ce866167ed"
    },
    {
      "zip": "26441",
      "city": "Jever",
      "street": "Anton-Günther-Str. 69",
      "id": "e7f8bbc605ebb221a46dce82c21fe1f4"
    },
    {
      "zip": "26506",
      "city": "Norden",
      "street": "Große Mühlenstr. 10-12",
      "id": "7d6f6fc5711fffce9d8943a7fe6e2629"
    },
    {
      "zip": "26603",
      "city": "Aurich",
      "street": "Oldesumer Str. 54-60",
      "id": "64e08aa3de4598e1d32cd5b61b735c7e"
    },
    {
      "zip": "26603",
      "city": "Aurich",
      "street": "Oldesumer Str. 58A",
      "id": "8c27ade35185036825c5dd4eb23aac75"
    },
    {
      "zip": "26629",
      "city": "Großefehn",
      "street": "Kanalstraße Süd 57A",
      "id": "e6749d915c02ba5e758061a581bc66cd"
    },
    {
      "zip": "26655",
      "city": "Westerstede",
      "street": "Eichendorffstr. 40",
      "id": "8fea61391e95720f8bea42e74ed08984"
    },
    {
      "zip": "26655",
      "city": "Westerstede",
      "street": "Poststr. 2",
      "id": "e6f0408f21d9351b2dc95cc4aec676e1"
    },
    {
      "zip": "26721",
      "city": "Emden",
      "street": "Neutorstr. 18",
      "id": "105db5ce30228a64c1b6c8dcbb1d9ca7"
    },
    {
      "zip": "26725",
      "city": "Emden",
      "street": "Osterstr. 38",
      "id": "6af9d9d75e90f4d11b5bb3ef7951ba22"
    },
    {
      "zip": "26789",
      "city": "Leer",
      "street": "Bavinkstr. 23",
      "id": "71b14e22a38f1f42601ee7d04476bb22"
    },
    {
      "zip": "26789",
      "city": "Leer",
      "street": "Jahnstr. 5",
      "id": "a62038c5e4e30826396d13a33b365fb1"
    },
    {
      "zip": "26789",
      "city": "Leer",
      "street": "Mühlenstr. 104",
      "id": "7e15b0d6873874b88d7371d9fcbb762a"
    },
    {
      "zip": "26802",
      "city": "Moormerland",
      "street": "Königsstr. 52",
      "id": "a6355436539139a82f6348eca5c8d1e8"
    },
    {
      "zip": "26817",
      "city": "Rhauderfehn",
      "street": "Untenende 9",
      "id": "24828eb110cdc870b158a15931ea97a1"
    },
    {
      "zip": "26871",
      "city": "Papenburg",
      "street": "Dechant-Schütte-Str. 6",
      "id": "95ab0045b74dc14585cc777a9a77a78d"
    },
    {
      "zip": "27211",
      "city": "Bassum",
      "street": "Alte Poststr. 6",
      "id": "30c6910ed9ab77651e101679fd96c2ec"
    },
    {
      "zip": "27283",
      "city": "Verden",
      "street": "Cluventhalstr. 17/südstr. 34",
      "id": "8ad4a251f826ce2abd42aac384d368e6"
    },
    {
      "zip": "27283",
      "city": "Verden",
      "street": "Südstr. 34",
      "id": "d2814d07557fffb408161b52c7e9e1f9"
    },
    {
      "zip": "27318",
      "city": "Hoya",
      "street": "Knesestr. 23",
      "id": "40333905aa0daa356b0fcf419f20ff52"
    },
    {
      "zip": "27356",
      "city": "Rotenburg",
      "street": "Nagelschmiedsweg 1-7",
      "id": "03d3c362c20df381520d4cbc20b44fb9"
    },
    {
      "zip": "27404",
      "city": "Zeven",
      "street": "Kattrepel 12",
      "id": "86bce0bf52a167ec4502335df990eeb5"
    },
    {
      "zip": "27432",
      "city": "Bremervörde",
      "street": "Ritterstr. 5-7",
      "id": "e8b7b28197ff256254d36fbb0165311f"
    },
    {
      "zip": "27472",
      "city": "Cuxhaven",
      "street": "Nordersteinstr. 51",
      "id": "fd6bc1c705a3005979718ac865d6718e"
    },
    {
      "zip": "27474",
      "city": "Cuxhaven",
      "street": "Wiesenkamp 3-7",
      "id": "9add11e04d40ad84c30686e51e15b227"
    },
    {
      "zip": "27568",
      "city": "Bremerhaven",
      "street": "Obere Bürger 38",
      "id": "a586361842487e49614995e568e1f085"
    },
    {
      "zip": "27570",
      "city": "Bremerhaven",
      "street": "Friedrich-Ebert-Str. 27-33",
      "id": "0ac57aa9a0ec6e8b1f5eed6dfe0e2dea"
    },
    {
      "zip": "27570",
      "city": "Bremerhaven",
      "street": "Friedrich-Ebert-Str. 33",
      "id": "19ef47dc0bba593d6fb1f91e13a4d9d2"
    },
    {
      "zip": "27616",
      "city": "Beverstedt",
      "street": "Bremer Str. 19A",
      "id": "b8dbf019244b6abe144f5e0e5acfed5a"
    },
    {
      "zip": "27711",
      "city": "Osterholz-Schambeck",
      "street": "Freißenbütteler Weg 13",
      "id": "73a31e104696886cf2a0ceb5b9044ba1"
    },
    {
      "zip": "27753",
      "city": "Delmenhorst",
      "street": "Ludwig-Kaufmann-Str. 32",
      "id": "ac3fa98f808f4c4e3dc0ab6e4200659f"
    },
    {
      "zip": "28195",
      "city": "Bremen",
      "street": "Lloydpassage 37",
      "id": "6ba8a8883468c262ccbfab20578e953e"
    },
    {
      "zip": "28195",
      "city": "Bremen",
      "street": "Neuenstr. 76-80",
      "id": "3c3c0455178d6ebbe6aeb8da526513e7"
    },
    {
      "zip": "28205",
      "city": "Bremen",
      "street": "Friedrich-Karl-Str. 55",
      "id": "439bbb9e91d0f80437d6f294dabd4bdb"
    },
    {
      "zip": "28207",
      "city": "Bremen",
      "street": "Lötzener Str. 9",
      "id": "de4acc4e8b6b21abbd3b3cfbcf20267e"
    },
    {
      "zip": "28207",
      "city": "Bremen",
      "street": "Stresemannstr. 4",
      "id": "dfa8acd8eef3c87627b2acb5da16201c"
    },
    {
      "zip": "28207",
      "city": "Bremen",
      "street": "Stresemannstr. 4-10",
      "id": "6781a6aa299bb70cf8f1e08593439368"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Eduard-Schopf-Allee 1",
      "id": "d882c046f9528005eb2f6436fe409db7"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Lloydstr. 4-6",
      "id": "c45060847a83cc3f94dee30c514d6597"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Utbremer Str. 90",
      "id": "84a1ebba599337f3b906c0a779360360"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Utbremer Str. 91",
      "id": "75dfe85e99641b78b5e3b6cd9343884e"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Utbremer Str. 94",
      "id": "ae3bd906a661e0704844a6017c362008"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Utbremer Str. 97-99",
      "id": "ff29e0eb2682a010b0895284cd0e1700"
    },
    {
      "zip": "28217",
      "city": "Bremen",
      "street": "Utbremer Straße 94",
      "id": "2f11d7390921ab054be5ccd90eec2da8"
    },
    {
      "zip": "28259",
      "city": "Bremen",
      "street": "Alter Dorfweg 30-50",
      "id": "573d3342ccf7b02597f9f1afd4469953"
    },
    {
      "zip": "28259",
      "city": "Bremen",
      "street": "Obervielander Str. 11",
      "id": "6ad636d707ca623a37cab30de0150c8e"
    },
    {
      "zip": "28307",
      "city": "Bremen",
      "street": "Hans Bredow Str. 19",
      "id": "cd8b8060bdb85b4042bf92f4510855a0"
    },
    {
      "zip": "28307",
      "city": "Bremen",
      "street": "Neue Heimat 20",
      "id": "1a7e8e49093b2a48295588c985167813"
    },
    {
      "zip": "28325",
      "city": "Bremen",
      "street": "Züricher Str. 48",
      "id": "077c1a9de7027fc04ff1e6b32087b417"
    },
    {
      "zip": "28757",
      "city": "Bremen",
      "street": "Hohle Str. 15",
      "id": "3f6de8aa3ae28c9b25e350b042eac110"
    },
    {
      "zip": "28816",
      "city": "Stuhr",
      "street": "Heiligenroder Str. 95A",
      "id": "d8b8d49962d84689e2db36f9e81685fa"
    },
    {
      "zip": "28832",
      "city": "Achim",
      "street": "Goethestr. 13-14",
      "id": "46639eb8dd12dce72ad95da2bb03fbe5"
    },
    {
      "zip": "28844",
      "city": "Weyhe",
      "street": "Kleine Heide 3",
      "id": "37b86826731d873d936e27d213fa71ea"
    },
    {
      "zip": "29221",
      "city": "Celle",
      "street": "Sägemühlenstr. 6",
      "id": "3f4cbd4c59dcc5ffa07d7d11d88bbe44"
    },
    {
      "zip": "29221",
      "city": "Celle",
      "street": "Zöllnerstr. 21-22",
      "id": "39e5dd6381d414e4b43c765b2a20f96e"
    },
    {
      "zip": "29303",
      "city": "Bergen",
      "street": "Belsener Str. 9",
      "id": "985d00a3dddb997b1a0148487692be6f"
    },
    {
      "zip": "29410",
      "city": "Salzwedel",
      "street": "Vor Dem Lüchower Tor 12",
      "id": "f8dd3624d584cdaf44f24e5e1102bcd0"
    },
    {
      "zip": "29451",
      "city": "Dannenberg",
      "street": "Marschtorstr. 35",
      "id": "143c242dc033f4f538b7e7ad028341d9"
    },
    {
      "zip": "29525",
      "city": "Uelzen",
      "street": "Bahnhofstr. 16",
      "id": "7d86e7d49e593d106b85f6b370dd3855"
    },
    {
      "zip": "29525",
      "city": "Uelzen",
      "street": "Ringstr. 13",
      "id": "54e0f2a0b5743369704990d0a280e6aa"
    },
    {
      "zip": "29549",
      "city": "Bad Bevensen",
      "street": "Lindenstr. 15",
      "id": "3e658d126c5a713646489a997a079ac2"
    },
    {
      "zip": "29614",
      "city": "Soltau",
      "street": "Am Weinberg 31-33",
      "id": "11a0a1653143ee966507fcbf90763fe9"
    },
    {
      "zip": "29614",
      "city": "Soltau",
      "street": "Herzog-Bernd-Str. 2-4",
      "id": "0a8e1ac631d00ba152604f98b7ea89db"
    },
    {
      "zip": "29633",
      "city": "Munster",
      "street": "Neue Str. 2",
      "id": "d68bb81f7bba6ac74807cd0f3d11f1bf"
    },
    {
      "zip": "29664",
      "city": "Walsrode",
      "street": "Saarstr. 12",
      "id": "705722165afa658ad0cfb108e1ad42ab"
    },
    {
      "zip": "30159",
      "city": "Hannover",
      "street": "Bahnhofstr. 3",
      "id": "c6b37e29839f5d5148b992e96e03db3e"
    },
    {
      "zip": "30159",
      "city": "Hannover",
      "street": "Herrenstr. 7",
      "id": "7c0f8c58d25598dff6ec140cf405c71d"
    },
    {
      "zip": "30161",
      "city": "Hannover",
      "street": "Drostestr. 11",
      "id": "27259b6b0146492d5d1ba15fc6bee804"
    },
    {
      "zip": "30163",
      "city": "Hannover",
      "street": "Gradestr. 18",
      "id": "8f1bb0f654365f964f7a2847e1f62af1"
    },
    {
      "zip": "30163",
      "city": "Hannover",
      "street": "Gradestr. 20",
      "id": "2f8dacb224a9b35b974934686d296794"
    },
    {
      "zip": "30179",
      "city": "Hannover",
      "street": "Industrieweg 19",
      "id": "c98b1915e97d98a61f502bda2094750b"
    },
    {
      "zip": "30179",
      "city": "Hannover",
      "street": "Vahrenwalder Str. 240-247",
      "id": "25c4db6e7f6ddab2a520083c66874259"
    },
    {
      "zip": "30419",
      "city": "Hannover",
      "street": "Am Winkelberge 1-3",
      "id": "15a186a2ca24e253857591583e66b795"
    },
    {
      "zip": "30449",
      "city": "Hannover",
      "street": "Göttinger Str. 14",
      "id": "2b04ed0be446dea9b436f601ae4ab488"
    },
    {
      "zip": "30449",
      "city": "Hannover",
      "street": "Marianne-Baecker-Allee 15",
      "id": "d452ad9c302e5d53bf361d048b64c295"
    },
    {
      "zip": "30455",
      "city": "Hannover",
      "street": "Am Immenkampe 3",
      "id": "e4da1ba3b7d99ffe66d0103e59a35f48"
    },
    {
      "zip": "30519",
      "city": "Hannover",
      "street": "Am Tüv 5",
      "id": "bf46de08131df2fbd62a2c7a86f4b302"
    },
    {
      "zip": "30519",
      "city": "Hannover",
      "street": "Holthusenstr. 2",
      "id": "09e5882591bab4ec43c032420d9497da"
    },
    {
      "zip": "30521",
      "city": "Hannover",
      "street": "Messegelände Halle 14",
      "id": "b13774c0945c36c6bdbb60e5277fecc9"
    },
    {
      "zip": "30625",
      "city": "Hannover",
      "street": "Misburger Str. 81D",
      "id": "6507b46454fdeb1ea13c4f61139f19bb"
    },
    {
      "zip": "30625",
      "city": "Hannover",
      "street": "Neue-Land-Str. 4+ 6",
      "id": "cdd5bcc9847940413d44b4eb02e35de0"
    },
    {
      "zip": "30625",
      "city": "Hannover",
      "street": "Neue-Land-Str. 6",
      "id": "ba9fc68dbfd64bf7da6cd6f641028ea1"
    },
    {
      "zip": "30625",
      "city": "Hannover",
      "street": "Neue-Land-Straße 6",
      "id": "66aab4e782e926341ee4588d2e695484"
    },
    {
      "zip": "30823",
      "city": "Garbsen",
      "street": "Havelser Str. 1",
      "id": "8d2c4e2c937a58a45a593fe1e6f7353e"
    },
    {
      "zip": "30853",
      "city": "Langenhagen",
      "street": "Marktplatz 7",
      "id": "833d0e15823761228ea9a1f796248a20"
    },
    {
      "zip": "30880",
      "city": "Laatzen",
      "street": "Schmiedestr. 12A",
      "id": "deb2d674f1c6f13d5b44c8bcfcfd015e"
    },
    {
      "zip": "30890",
      "city": "Barsinghausen",
      "street": "Poststr. 3-5",
      "id": "6d1636c6cbd4ce94fcd29b4bd07e3a7d"
    },
    {
      "zip": "31028",
      "city": "Gronau",
      "street": "Steintorstr. 14",
      "id": "a1814cfc1b197b0e750f84e1fd8319bf"
    },
    {
      "zip": "31061",
      "city": "Alfeld",
      "street": "Brunnenweg 10",
      "id": "481cb4e544ca10e090cbd8aeb33c6cf9"
    },
    {
      "zip": "31134",
      "city": "Hildesheim",
      "street": "Almsstr. 50",
      "id": "cda54ecb73601ac202ef9f43409d374a"
    },
    {
      "zip": "31135",
      "city": "Hildesheim",
      "street": "Herbert-Quandt-Str. 1",
      "id": "2dd2baee3e5e1b7b4d9a3535d1013e6d"
    },
    {
      "zip": "31137",
      "city": "Hildesheim",
      "street": "Am Kupferstrange 1D",
      "id": "f5135cf530bc617a31d3151c685546cd"
    },
    {
      "zip": "31174",
      "city": "Schellerten",
      "street": "Berliner Str. 59",
      "id": "ef1263243ebe7949ae2bd822ad4af319"
    },
    {
      "zip": "31224",
      "city": "Peine",
      "street": "Breite Str. 12",
      "id": "dbfa6cedb6c95d6bb92f07903d32963a"
    },
    {
      "zip": "31224",
      "city": "Peine",
      "street": "Duttenstedter Str. 136",
      "id": "338e721761d3f45a8a161845473f710c"
    },
    {
      "zip": "31275",
      "city": "Lehrte",
      "street": "Feldstr. 72",
      "id": "3dfaa8fb5fef0eb9060ce4836d505315"
    },
    {
      "zip": "31515",
      "city": "Wunstorf",
      "street": "Hannoversche Str. 19",
      "id": "da8b50272b1bd36ef186e9b38e223352"
    },
    {
      "zip": "31535",
      "city": "Neustadt",
      "street": "Königsberger Str. 11",
      "id": "e31064800d892c7dff1fe2ec8e4f8077"
    },
    {
      "zip": "31582",
      "city": "Nienburg",
      "street": "Kleine Drakenburger Str. 7A",
      "id": "e697e9a7f8242a10c8aed5fe03c8f6a7"
    },
    {
      "zip": "31582",
      "city": "Nienburg",
      "street": "Lange Str. 46",
      "id": "892f53837c38f0c2bfeb55e4c9c1a252"
    },
    {
      "zip": "31595",
      "city": "Steyerberg",
      "street": "Im Orte 5",
      "id": "1c16c7bcac0030c2ff4bf3e34bdfd3c8"
    },
    {
      "zip": "31655",
      "city": "Stadthagen",
      "street": "Bahnhofstr. 15B",
      "id": "9e50e9ed16c72cb36d62b03e6e297c39"
    },
    {
      "zip": "31675",
      "city": "Bückeburg",
      "street": "Bahnhofstr. 22",
      "id": "fb1e93e8f8c581d08a028ed2f8abb7c9"
    },
    {
      "zip": "31737",
      "city": "Rinteln",
      "street": "Bahnhofsallee 3",
      "id": "84f05055248e19fa4579886b55686825"
    },
    {
      "zip": "31785",
      "city": "Hameln",
      "street": "Osterstr. 49",
      "id": "3ab20f9669f8eba61202a0f9fdb93634"
    },
    {
      "zip": "31789",
      "city": "Hameln",
      "street": "Springer Landstr. 4",
      "id": "900947746cc8570ef3e46fb9206c1dc1"
    },
    {
      "zip": "31812",
      "city": "Bad Pyrmont",
      "street": "Dringenauer Str. 14",
      "id": "f8b33b2f4a19d72e1fde405f2235aafe"
    },
    {
      "zip": "31832",
      "city": "Springe",
      "street": "Philipp-Reis-Str. 4",
      "id": "38a4a3c5a3025bff0f752a6a8d17117d"
    },
    {
      "zip": "32052",
      "city": "Herford",
      "street": "Auf Der Freiheit 1-3",
      "id": "0e81133f03651251b056ccb9868c4655"
    },
    {
      "zip": "32052",
      "city": "Herford",
      "street": "Auf Der Freiheit 3",
      "id": "ae010d668d2a798fa9b9c4154623dc94"
    },
    {
      "zip": "32052",
      "city": "Herford",
      "street": "Höckerstr. 8",
      "id": "8c20674aa0588f5b21742ff6d79ac962"
    },
    {
      "zip": "32257",
      "city": "Bünde",
      "street": "Eschstr. 36",
      "id": "d22edd540f23b364ed97053c9f56d295"
    },
    {
      "zip": "32257",
      "city": "Bünde",
      "street": "Kaiser-Wilhelm-Str. 22-24",
      "id": "b604302515937b4f5e9e2e5633a84e84"
    },
    {
      "zip": "32312",
      "city": "Lübbecke",
      "street": "Augustaweg 9",
      "id": "28422619ea1935d9bd8c33382392cf53"
    },
    {
      "zip": "32423",
      "city": "Minden",
      "street": "Bäckerstr. 27",
      "id": "64f7de3945e5eddda0b34528387ace07"
    },
    {
      "zip": "32427",
      "city": "Minden",
      "street": "Gelindeweg 31-35",
      "id": "13ab620146bb35e21b03c0681308a99e"
    },
    {
      "zip": "32457",
      "city": "Porta Westfalica",
      "street": "Feldstr. 20B",
      "id": "8916e3dc36b88f6ece374b099c63e746"
    },
    {
      "zip": "32457",
      "city": "Porta Westfalica",
      "street": "Flurweg 11",
      "id": "6e4cd7089b96a2f4400b6ee18c6730b4"
    },
    {
      "zip": "32547",
      "city": "Bad Oeynhausen",
      "street": "Mindener Str. 22",
      "id": "94710ba2e1acf6c7448e45f70cbcb44a"
    },
    {
      "zip": "32676",
      "city": "Lügde",
      "street": "Prozessionsweg 1",
      "id": "608e8b6338bf8b9aad36e4bacb8acbe4"
    },
    {
      "zip": "32756",
      "city": "Detmold",
      "street": "Bruchstr. 4",
      "id": "e2b7f4caf9aa5219bb91b61200d161e2"
    },
    {
      "zip": "32758",
      "city": "Detmold",
      "street": "Braunenbrucher Weg 16-18",
      "id": "a3cce6dcb0b8c765f224a00b3f596d07"
    },
    {
      "zip": "32758",
      "city": "Detmold",
      "street": "Klingenbergstr. 23-27",
      "id": "a0caab946cc9484e60997e0b5172e4ce"
    },
    {
      "zip": "33098",
      "city": "Paderborn",
      "street": "Westernstr. 25",
      "id": "7e075bf4ff76c9999df25d7d4218f90c"
    },
    {
      "zip": "33100",
      "city": "Paderborn",
      "street": "Detmolder Str. 47",
      "id": "42108e540d826e183256a2155d63d61f"
    },
    {
      "zip": "33102",
      "city": "Paderborn",
      "street": "Rathenaustr. 26-28",
      "id": "b358f8e3998f34ddb8aeccfc0b099cf1"
    },
    {
      "zip": "33102",
      "city": "Paderborn",
      "street": "Rathenaustr. 26-30",
      "id": "1b59601e11f171351a622fe2e84d2157"
    },
    {
      "zip": "33102",
      "city": "Paderborn",
      "street": "Rathenaustr. 28",
      "id": "a8498b6986b042f1f74d39c36b01fad8"
    },
    {
      "zip": "33330",
      "city": "Gütersloh",
      "street": "Berliner Str. 29-31",
      "id": "c6066f991ad3f9573947902d3f0eda0e"
    },
    {
      "zip": "33330",
      "city": "Gütersloh",
      "street": "Eickhoffstr. 4-6",
      "id": "c18282e3bed844f8e6cbf13cf31c7593"
    },
    {
      "zip": "33602",
      "city": "Bielefeld",
      "street": "Bahnhofstr. 8",
      "id": "abc6e6798a7d33dc1ae2acfc932712b5"
    },
    {
      "zip": "33602",
      "city": "Bielefeld",
      "street": "Philipp-Reis-Platz 1",
      "id": "70aa13fe6fdbcbd47dabb8bfddef57a3"
    },
    {
      "zip": "33602",
      "city": "Bielefeld",
      "street": "Philipp-Reis-Platz 1 (hh)",
      "id": "bd1f58efe52240329bc0a075d5a437e5"
    },
    {
      "zip": "33605",
      "city": "Bielefeld",
      "street": "Detmolder Str. 380",
      "id": "bb6bc93c361ea1e6514f8ec6a8661c52"
    },
    {
      "zip": "33605",
      "city": "Bielefeld",
      "street": "Stieghorster Str. 115",
      "id": "60189af025286515dfb5dd2e01480a1d"
    },
    {
      "zip": "34117",
      "city": "Kassel",
      "street": "Akazienweg 17",
      "id": "1e3bb09188973310eb5a2e4da8f2ec35"
    },
    {
      "zip": "34117",
      "city": "Kassel",
      "street": "Friedrich-Ebert-Str. 24",
      "id": "61e5cabf6166a5c56627e89e6b1b30ae"
    },
    {
      "zip": "34117",
      "city": "Kassel",
      "street": "Friedrich-Ebert-Str. 25",
      "id": "f5d38edae50d8a183c16f991dcd28079"
    },
    {
      "zip": "34117",
      "city": "Kassel",
      "street": "Königsplatz 59",
      "id": "ef984718acf9cd17a8b733f7e1607d49"
    },
    {
      "zip": "34117",
      "city": "Kassel",
      "street": "Obere Königsstr. 10",
      "id": "e2e7ba193b9bf68684ff4eb8364d698c"
    },
    {
      "zip": "34123",
      "city": "Kassel",
      "street": "Speeler Weg 26",
      "id": "c761978724a255c58677efea27048447"
    },
    {
      "zip": "34134",
      "city": "Kassel",
      "street": "Frankfurter Str. 225",
      "id": "f4f3bf51cf5adf7050859daebc328ec1"
    },
    {
      "zip": "34212",
      "city": "Melsungen",
      "street": "Am Kesselberg 50",
      "id": "84147a0b9dc8619baee23706ccf52df3"
    },
    {
      "zip": "34253",
      "city": "Lohfelden",
      "street": "Am Fieseler Werk 19-23",
      "id": "c37c2f62921a9ca5eafa22cbcad31b50"
    },
    {
      "zip": "34346",
      "city": "Hann. Münden",
      "street": "Beethovenstr. 24-26",
      "id": "b871a616a324967e6f049557c017ac43"
    },
    {
      "zip": "34369",
      "city": "Hofgeismar",
      "street": "Neue Str. 7-9",
      "id": "5fc905e2dd3788e03edfa866d7e28b72"
    },
    {
      "zip": "34454",
      "city": "Bad Arolsen",
      "street": "Pyrmonter Str. 5",
      "id": "9dc30af2fc0564bdae720cbeaf75ea95"
    },
    {
      "zip": "34497",
      "city": "Korbach",
      "street": "Prof.-Bier-Str. 5",
      "id": "ecbf32e44a0898528da7ddb32249ed6c"
    },
    {
      "zip": "34497",
      "city": "Korbach",
      "street": "Strother Str. 41",
      "id": "941532288bd1d241e83fb0e349d6a219"
    },
    {
      "zip": "34537",
      "city": "Bad Wildungen",
      "street": "Brunnenallee 26",
      "id": "a0af76386a9371c5dc2fadc87e54a9fa"
    },
    {
      "zip": "34576",
      "city": "Homberg",
      "street": "Ziegenhainer Str. 17A",
      "id": "174993ee9944f86e96da1c4fae1218be"
    },
    {
      "zip": "34613",
      "city": "Schwalmstadt",
      "street": "Stephanstr. 52",
      "id": "7dc9cc73c70df472b082a2df6f1de6d2"
    },
    {
      "zip": "34632",
      "city": "Jesberg",
      "street": "Kellerwaldstr. 1",
      "id": "b51c1ce6bc42f7456253741cebd24f56"
    },
    {
      "zip": "35037",
      "city": "Marburg",
      "street": "Gutenbergstr. 9",
      "id": "5153764ecf11a8c312c2f71da2f79b23"
    },
    {
      "zip": "35043",
      "city": "Marburg",
      "street": "Marburger Str. 98",
      "id": "3be6aa7a249f5320c1f9378a79b5973c"
    },
    {
      "zip": "35066",
      "city": "Frankenberg",
      "street": "Marburger Str. 19-21",
      "id": "45d27dc67c37bf0eb6b361c8484c479c"
    },
    {
      "zip": "35216",
      "city": "Biedenkopf",
      "street": "Hospitalstr. 51",
      "id": "78ea42d61601dac8549998517dfc754e"
    },
    {
      "zip": "35305",
      "city": "Grünberg",
      "street": "Stangenröder Weg 4",
      "id": "faf9888bbb5a75480088e284ef2f724e"
    },
    {
      "zip": "35390",
      "city": "Gießen",
      "street": "Liebigstr. 14-16",
      "id": "318601f2887a34c4e822b7c6c85873a5"
    },
    {
      "zip": "35390",
      "city": "Gießen",
      "street": "Seltersweg 11",
      "id": "21cb3e0a6ad0d02e75b8fd43ddf66fd4"
    },
    {
      "zip": "35398",
      "city": "Gießen",
      "street": "Philipp-Reis-Str. 4",
      "id": "7b0960133ba2c80890fcd2baa4440036"
    },
    {
      "zip": "35440",
      "city": "Linden",
      "street": "Robert-Bosch-Str. 6",
      "id": "8321341a7ba45dbfc3286b229ac63421"
    },
    {
      "zip": "35510",
      "city": "Butzbach",
      "street": "Marktplatz 18",
      "id": "e39733589822205d7225b584f8a383bc"
    },
    {
      "zip": "35510",
      "city": "Butzbach",
      "street": "Wetzlarer Str. 75",
      "id": "ccc3f099cd33055337c71e769f3fd832"
    },
    {
      "zip": "35576",
      "city": "Wetzlar",
      "street": "Bahnhofstr. 20",
      "id": "4bbf6c8add73e05cc022736d2fb36559"
    },
    {
      "zip": "35576",
      "city": "Wetzlar",
      "street": "Philipsstr. 2",
      "id": "5232bc9a22b04fc97da8855178a895f0"
    },
    {
      "zip": "35683",
      "city": "Dillenburg",
      "street": "Westfalenstr. 4",
      "id": "fa2749b691eeba56c9c84c5042b2566f"
    },
    {
      "zip": "35781",
      "city": "Weilburg",
      "street": "Limburger Str. 27",
      "id": "4e9714130a9f5461d99bd2402bf03f7c"
    },
    {
      "zip": "36037",
      "city": "Fulda",
      "street": "Bahnhofstr. 11",
      "id": "ab5dd7da48c5fd01b5ca128ecf7869b2"
    },
    {
      "zip": "36037",
      "city": "Fulda",
      "street": "Petersberger Str. 76",
      "id": "cf22d71a57e8e98cc1be5f11469d070e"
    },
    {
      "zip": "36037",
      "city": "Fulda",
      "street": "Unterm Heilig Kreuz 3-5",
      "id": "27b736288f58f3c01df9d2a2e40cbcb8"
    },
    {
      "zip": "36043",
      "city": "Fulda",
      "street": "Eigilstr. 2",
      "id": "c810c23e3be9bf08ea66f6ffd006f36e"
    },
    {
      "zip": "36251",
      "city": "Bad Hersfeld",
      "street": "Erfurter Str. 5",
      "id": "a7d4cf9d9e9db24cb3cdb9694e084420"
    },
    {
      "zip": "36304",
      "city": "Alsfeld",
      "street": "Im Grund 7",
      "id": "cb09e51ba56a086b8cfd8b6724f44089"
    },
    {
      "zip": "36381",
      "city": "Schlüchtern",
      "street": "Lotichiusstr. 43",
      "id": "430d47e73663903f39e1d7536a31fc41"
    },
    {
      "zip": "36433",
      "city": "Bad Salzungen",
      "street": "Langenfelder Str. 13",
      "id": "45db35be39e6fc0cac478063bc72c3a6"
    },
    {
      "zip": "37073",
      "city": "Göttingen",
      "street": "Weender Str. 27",
      "id": "04856e9c390d797b8081b9fc68415c5b"
    },
    {
      "zip": "37075",
      "city": "Göttingen",
      "street": "Philipp-Reis-Str. 2A",
      "id": "140424942a1c243bdf708b4edea4a1d3"
    },
    {
      "zip": "37077",
      "city": "Göttingen",
      "street": "Reinhard-Rube-Str. 17",
      "id": "e2e72e7854007b61ab8c57c76dfdf893"
    },
    {
      "zip": "37079",
      "city": "Göttingen",
      "street": "Am Kauf Park 2",
      "id": "412f7e92c55ccf30c92da38b0eba49cc"
    },
    {
      "zip": "37115",
      "city": "Duderstadt",
      "street": "Am Gropenmarkt 3",
      "id": "7409f53cff0515a1a5f1b1428f12b918"
    },
    {
      "zip": "37154",
      "city": "Northeim",
      "street": "Grafenhof 1",
      "id": "29c8a09c22d81b6a5c8f9ba3b57584d3"
    },
    {
      "zip": "37154",
      "city": "Northeim",
      "street": "Göttinger Str. 31-33",
      "id": "b0f5568b5d5cd683d00762583124d357"
    },
    {
      "zip": "37170",
      "city": "Uslar",
      "street": "Bahnhofstr. 6/ 8",
      "id": "d99d70396c87f15078fc6ed4369fa19a"
    },
    {
      "zip": "37213",
      "city": "Witzenhausen",
      "street": "Parkweg 1A",
      "id": "f18ff662684e5b6658d8238da46656a3"
    },
    {
      "zip": "37269",
      "city": "Eschwege",
      "street": "Kurt-Holzapfel-Str. 15/ 15A",
      "id": "0c962be076027182273343d0d5c16523"
    },
    {
      "zip": "37269",
      "city": "Eschwege",
      "street": "Stad 46",
      "id": "da8b43334f2526a5f3c568f54b62a8bf"
    },
    {
      "zip": "37327",
      "city": "Leinefelde",
      "street": "Jahnstr. 19",
      "id": "5afac9015785e2d24607f20e670daf0e"
    },
    {
      "zip": "37412",
      "city": "Herzberg Am Harz",
      "street": "Am Philipps 4",
      "id": "b3d342d199e5c72811386feb47ab8af7"
    },
    {
      "zip": "37431",
      "city": "Bad Lauterberg",
      "street": "Sebastian-Kneipp-Promenade 3-5",
      "id": "b06b6421f1117cbc3c7fefbd0182c1ef"
    },
    {
      "zip": "37520",
      "city": "Osterode",
      "street": "Am Posthof 1",
      "id": "a608288ba9b2e9e6ff55a10d06843b78"
    },
    {
      "zip": "37574",
      "city": "Einbeck",
      "street": "Altendorfer Str. 43",
      "id": "d3e5b6fa8c87ba16d5167be18de4e2c3"
    },
    {
      "zip": "37603",
      "city": "Holzminden",
      "street": "Neue Str. 10",
      "id": "2d72d50bad1573ee7fd0d8b4a65f9404"
    },
    {
      "zip": "37627",
      "city": "Stadtoldendorf",
      "street": "Twete 32-34",
      "id": "854ba4cf4d06b6a61f95dbb3629bb39d"
    },
    {
      "zip": "37671",
      "city": "Höxter",
      "street": "Ludwig-Eichholz-Str. 17",
      "id": "177872c34129af76b24db04e16200270"
    },
    {
      "zip": "38100",
      "city": "Braunschweig",
      "street": "Hutfiltern 9",
      "id": "c841c4d2f13790c1c3d6f8e33b9d1ff2"
    },
    {
      "zip": "38100",
      "city": "Braunschweig",
      "street": "Platz Am Ritterbrunnen 1",
      "id": "8947b630754a4bf2aef47d3fdcadc264"
    },
    {
      "zip": "38100",
      "city": "Braunschweig",
      "street": "Schuhstr. 5",
      "id": "b7cd4e9fa7f38a4832317996dd18dd5c"
    },
    {
      "zip": "38102",
      "city": "Braunschweig",
      "street": "Willy-Brandt-Platz 4",
      "id": "feeea095484786e316802f94031774c7"
    },
    {
      "zip": "38122",
      "city": "Braunschweig",
      "street": "Friedrich-Seele-Str. 7",
      "id": "3c9d38306070fcb9a4c51d687ef95948"
    },
    {
      "zip": "38122",
      "city": "Braunschweig",
      "street": "Theodor-Heuss-Str. 4",
      "id": "e5e20dca16bc64b2091407cf4eeb9867"
    },
    {
      "zip": "38154",
      "city": "Königslutter",
      "street": "Poststr. 1",
      "id": "6aec74b832c0a665cb77be94a1af2df2"
    },
    {
      "zip": "38170",
      "city": "Schöppenstedt",
      "street": "Neue Str. 52",
      "id": "d36f3dca5603515a4f9abd1f0d11efc6"
    },
    {
      "zip": "38226",
      "city": "Salzgitter",
      "street": "Fischzug 12-14",
      "id": "a9454da0695c6ffa40908761a90cf04d"
    },
    {
      "zip": "38229",
      "city": "Salzgitter",
      "street": "Rumburger Str. 7",
      "id": "34fcaf16ec39c006b37491d65049fa66"
    },
    {
      "zip": "38239",
      "city": "Salzgitter",
      "street": "An Der Landwehr 6-10",
      "id": "07fcfe1504b53f8543eef57fca340d08"
    },
    {
      "zip": "38300",
      "city": "Wolfenbüttel",
      "street": "Schiffswall 2",
      "id": "274572fb5074a7ccc822e2c68b0039f6"
    },
    {
      "zip": "38350",
      "city": "Helmstedt",
      "street": "Beendorf Str. 3",
      "id": "0e30375efb63c7522c9a827874706928"
    },
    {
      "zip": "38350",
      "city": "Helmstedt",
      "street": "Neumärker Str. 11",
      "id": "e8d104638985e714fd56edc3668d9d5e"
    },
    {
      "zip": "38364",
      "city": "Schöningen",
      "street": "Ohrsleber Weg 10",
      "id": "8281759832a063b4b2f43c18951a74f3"
    },
    {
      "zip": "38440",
      "city": "Wolfsburg",
      "street": "Alessandro-Volta-Str. 11",
      "id": "c84e633aea7595ed3ac0c5728c3e1454"
    },
    {
      "zip": "38440",
      "city": "Wolfsburg",
      "street": "Alessandro-Volta-Str. 7",
      "id": "4f95cb73332eab7b5edc0c4c17a05d52"
    },
    {
      "zip": "38440",
      "city": "Wolfsburg",
      "street": "Porschestr. 37",
      "id": "286048afdfc5a9e1d940426f002b4917"
    },
    {
      "zip": "38440",
      "city": "Wolfsburg",
      "street": "Porschestr. 43a- C",
      "id": "c9743757fb933eb59a4654d68e365d3b"
    },
    {
      "zip": "38440",
      "city": "Wolfsburg",
      "street": "Porschestr. 45",
      "id": "4038319db588f563e176d900fdb7be0e"
    },
    {
      "zip": "38444",
      "city": "Wolfsburg",
      "street": "Dag-Hammarskjöld-Weg 1",
      "id": "74824b57336385f8990ca71f74bcc62e"
    },
    {
      "zip": "38486",
      "city": "Klötze",
      "street": "Hagenstr. 13",
      "id": "2dca146e21b5aa908e574743cd81c456"
    },
    {
      "zip": "38518",
      "city": "Gifhorn",
      "street": "Ribbesbütteler Weg 4",
      "id": "b1e2d07b8d95d335e133dc4bd2f62aa2"
    },
    {
      "zip": "38518",
      "city": "Gifhorn",
      "street": "Steinweg 71",
      "id": "ca4eff41510e2aca00315de6e0e9dd67"
    },
    {
      "zip": "38640",
      "city": "Goslar",
      "street": "Fischemäkerstr. 15",
      "id": "1a5dade84804689aa3d028d108fa61ba"
    },
    {
      "zip": "38644",
      "city": "Goslar",
      "street": "Ottostr. 9A",
      "id": "77435589514716a7cb2f8451c27d6e73"
    },
    {
      "zip": "38723",
      "city": "Seesen",
      "street": "Triftstr. 15",
      "id": "204bca766e7c53802d50d17811856132"
    },
    {
      "zip": "38820",
      "city": "Halberstadt",
      "street": "Fischmarkt 4",
      "id": "7261c2010e04ca4ed762b18833813d04"
    },
    {
      "zip": "38820",
      "city": "Halberstadt",
      "street": "Huylandstr. 18",
      "id": "3fd074f48f71865e84569b06bd46a4c6"
    },
    {
      "zip": "38855",
      "city": "Wernigerode",
      "street": "Breite Str. 35",
      "id": "bc27b373453f2cb4a09427e3c0a4d1ca"
    },
    {
      "zip": "38855",
      "city": "Wernigerode",
      "street": "Halberstädter Chaussee 5",
      "id": "0df031ba41ec88572d88f74d569300ce"
    },
    {
      "zip": "39104",
      "city": "Magdeburg",
      "street": "Ernst-Reuter-Allee 11",
      "id": "54a6f7fdb923779d35e94212e11438c4"
    },
    {
      "zip": "39104",
      "city": "Magdeburg",
      "street": "Listemannstr. 6",
      "id": "5607fd8075aacf427be5be411e31cd5e"
    },
    {
      "zip": "39106",
      "city": "Magdeburg",
      "street": "Joseph-Von-Fraunhofer-Str. 2",
      "id": "4e61dd975b6f4b6f80d47f842840888a"
    },
    {
      "zip": "39106",
      "city": "Magdeburg",
      "street": "Otto-Hahn-Str. 1A",
      "id": "73327ea62efad574d08914327422c6d6"
    },
    {
      "zip": "39110",
      "city": "Magdeburg",
      "street": "Beimsstr. 21",
      "id": "a40375205586db3ac5c67d8831f2a104"
    },
    {
      "zip": "39112",
      "city": "Magdeburg",
      "street": "Matthiasstr. 12",
      "id": "e928e1233ea146e89d189f1a82b9c7f7"
    },
    {
      "zip": "39114",
      "city": "Magdeburg",
      "street": "Friedrich-Ebert-Str. 68",
      "id": "ab476c0bb279228d6a362850d30acfdc"
    },
    {
      "zip": "39120",
      "city": "Magdeburg",
      "street": "Schilfbreite 2",
      "id": "0533664cddd29fb67d35836d93b79df2"
    },
    {
      "zip": "39124",
      "city": "Magdeburg",
      "street": "Lübecker Str. 2",
      "id": "1200b4dea4908420f2de6d43ad87632c"
    },
    {
      "zip": "39128",
      "city": "Magdeburg",
      "street": "Mechthildstr. 54",
      "id": "791af0527258c637f144b47453a4982c"
    },
    {
      "zip": "39130",
      "city": "Magdeburg",
      "street": "Olvenstedter Chaussee 38",
      "id": "d17f2f6d28387148ad447f59ad8148d6"
    },
    {
      "zip": "39171",
      "city": "Sülzetal",
      "street": "Lange Göhren 7",
      "id": "8b470296495fd48f86875142f10179b6"
    },
    {
      "zip": "39221",
      "city": "Biere",
      "street": "Am Schiens 10-12",
      "id": "b084aedbb7677d56fa3767f658646765"
    },
    {
      "zip": "39261",
      "city": "Zerbst",
      "street": "Am Krimmling 7",
      "id": "f12bc025904ce415540f16c95b6e119f"
    },
    {
      "zip": "39288",
      "city": "Burg",
      "street": "Grabower Landstr. 39",
      "id": "01c6db195d95dc9d1ea60b1058eaa833"
    },
    {
      "zip": "39340",
      "city": "Haldensleben",
      "street": "Krohnesruh 8A",
      "id": "48f0a353a381c0337138b7128356efe1"
    },
    {
      "zip": "39387",
      "city": "Oschersleben",
      "street": "Halberstädter Str. 114",
      "id": "5552dfc3744b87edc2d271dc10ede536"
    },
    {
      "zip": "39418",
      "city": "Staßfurt",
      "street": "Str. Der Elektronik 05",
      "id": "dde491c82962dd2450f4f508be936365"
    },
    {
      "zip": "39576",
      "city": "Stendal",
      "street": "Breite Str. 10",
      "id": "2287a9cd783bfadb173b600a792efc91"
    },
    {
      "zip": "39576",
      "city": "Stendal",
      "street": "Hallstr. 42-46",
      "id": "d2037ab4875e75bba07b52c2adbee5e0"
    },
    {
      "zip": "39638",
      "city": "Gardelegen",
      "street": "An Der Breiten Gehre 1",
      "id": "6c94b74f177c812a8b8287d19f73d0fb"
    },
    {
      "zip": "40212",
      "city": "Düsseldorf",
      "street": "Schadowstr. 39",
      "id": "142c9e3d34b3125e7420702e07805cc9"
    },
    {
      "zip": "40213",
      "city": "Düsseldorf",
      "street": "Graf-Adolf-Platz 14",
      "id": "2cab9860ffa5d51b891626dd93eeb0e6"
    },
    {
      "zip": "40217",
      "city": "Düsseldorf",
      "street": "Friedrichstr. 129",
      "id": "ff9d57cfdebd20568521ad4a3c354d3d"
    },
    {
      "zip": "40223",
      "city": "Düsseldorf",
      "street": "Brachtstr. 28",
      "id": "b0e57bd6c7da7c744dcef497b3337e80"
    },
    {
      "zip": "40231",
      "city": "Düsseldorf",
      "street": "Erkrather Str. 377",
      "id": "52ab570794e76b242fe6884cb43fd448"
    },
    {
      "zip": "40231",
      "city": "Düsseldorf",
      "street": "Erkrather Str. 377-389",
      "id": "27426f86e3da3fec23b77a92f486ca8b"
    },
    {
      "zip": "40477",
      "city": "Düsseldorf",
      "street": "Nordstr. 38",
      "id": "f4590df4c2851e8581d894d67b37a73b"
    },
    {
      "zip": "40547",
      "city": "Düsseldorf",
      "street": "Am Seestern 3",
      "id": "e993c2284c7548467e5bbee5f0f9a934"
    },
    {
      "zip": "40549",
      "city": "Düsseldorf",
      "street": "Böhlerstr. 69",
      "id": "d99955555da9dbdd900c46e217e7c852"
    },
    {
      "zip": "40549",
      "city": "Düsseldorf",
      "street": "Hansaallee 299",
      "id": "7f064eacdfba1c28ea28c3ce7267e12d"
    },
    {
      "zip": "40597",
      "city": "Düsseldorf",
      "street": "Hauptstr. 19",
      "id": "48757dfb8ef9e22d7f792a0de62f1e12"
    },
    {
      "zip": "40721",
      "city": "Hilden",
      "street": "Mittelstr. 28",
      "id": "cb3cdfdecb77b70abb488cc9001999da"
    },
    {
      "zip": "40764",
      "city": "Langenfeld",
      "street": "Marktplatz 2",
      "id": "2f4d648faed19a3dc573a13ae30036da"
    },
    {
      "zip": "40822",
      "city": "Mettmann",
      "street": "Am Königshof 14",
      "id": "7af45cd4e10920aa8e9a6d9046fb847b"
    },
    {
      "zip": "40878",
      "city": "Ratingen",
      "street": "Oberstr. 7",
      "id": "35d525cc1728298433c978c7e42d9e38"
    },
    {
      "zip": "41061",
      "city": "Mönchengladbach",
      "street": "Hindenburgstr. 120",
      "id": "f000dc5800a0a77c7ba7df560e3e1018"
    },
    {
      "zip": "41061",
      "city": "Mönchengladbach",
      "street": "Hindenburgstr. 75",
      "id": "f70ce0e307afcf59cede48aea3e8c689"
    },
    {
      "zip": "41065",
      "city": "Mönchengladbach",
      "street": "Pescher Str. 185-19",
      "id": "b155547fdd14570bb509555d3f4d6c8e"
    },
    {
      "zip": "41065",
      "city": "Mönchengladbach",
      "street": "Pescher Str. 185-191",
      "id": "b98198827658a2692c1e047a5dc057c3"
    },
    {
      "zip": "41065",
      "city": "Mönchengladbach",
      "street": "Pescher Str. 187-191",
      "id": "6e9716657ef500e69d0a52814a6e7b58"
    },
    {
      "zip": "41066",
      "city": "Mönchengladbach",
      "street": "Hovener Str. 175",
      "id": "dcf89370a1f0065642be13b06c0f8417"
    },
    {
      "zip": "41236",
      "city": "Mönchengladbach",
      "street": "Stresemannstr. 27",
      "id": "8cfea1b4f7b7cc5072b729f066708705"
    },
    {
      "zip": "41460",
      "city": "Neuss",
      "street": "Büchel 51",
      "id": "632d1008a36271c2a19a9589b1f77648"
    },
    {
      "zip": "41460",
      "city": "Neuss",
      "street": "Hellerbergstr. 35",
      "id": "a591e420a33b529979569a0f59f81025"
    },
    {
      "zip": "41460",
      "city": "Neuss",
      "street": "Hellersbergstr. 35",
      "id": "e5b9618b78ea93d7588330d159fd4213"
    },
    {
      "zip": "41468",
      "city": "Neuss",
      "street": "Humboldtstr. 2",
      "id": "ffd3fc91b9d7fec08ddcc614b0436911"
    },
    {
      "zip": "41515",
      "city": "Grevenbroich",
      "street": "Deutsch-Ritter-Allee 31",
      "id": "8d374d70a879569a8edd203428df56fc"
    },
    {
      "zip": "41515",
      "city": "Grevenbroich",
      "street": "Lindenstr. 38",
      "id": "a53bc95488d1320dfaf72219af5b235b"
    },
    {
      "zip": "41747",
      "city": "Viersen",
      "street": "Hauptstr. 18",
      "id": "51ae3c0d86653e779a019313b2f24839"
    },
    {
      "zip": "41844",
      "city": "Wegberg",
      "street": "Kreuzherrenstr. 11",
      "id": "eb746173bd3a7d7937b7a99034ac8fa3"
    },
    {
      "zip": "42103",
      "city": "Wuppertal",
      "street": "Alte Freiheit 9",
      "id": "97dec188b74ee1173622ed6b12b15b89"
    },
    {
      "zip": "42103",
      "city": "Wuppertal",
      "street": "Poststr. 15",
      "id": "ca6d88bbdf85b26ce054399f84c59b79"
    },
    {
      "zip": "42105",
      "city": "Wuppertal",
      "street": "Briller Str. 35-39",
      "id": "9e96b963e9919fb9d602dfc09c2ac25f"
    },
    {
      "zip": "42105",
      "city": "Wuppertal",
      "street": "Briller Str. 39",
      "id": "0a31e3629bb1c7284e91cdf12ebb7fe8"
    },
    {
      "zip": "42275",
      "city": "Wuppertal",
      "street": "Werth 14",
      "id": "8c7d12959af37fc14dd39b2e45804bb8"
    },
    {
      "zip": "42549",
      "city": "Velbert",
      "street": "Rheinlandstr. 24",
      "id": "c5606f85466c7a522312c8a63d5baa3f"
    },
    {
      "zip": "42551",
      "city": "Velbert",
      "street": "Friedrichstr. 169",
      "id": "584ab7aa64a1aec4919052f54f528e9f"
    },
    {
      "zip": "42651",
      "city": "Solingen",
      "street": "Hauptstr. 52",
      "id": "bdb826541bec528cfa7752d7068494ee"
    },
    {
      "zip": "42653",
      "city": "Solingen",
      "street": "Schlagbaumer Str. 113-115",
      "id": "aeb368fe5b197e3d01436a9fe430e542"
    },
    {
      "zip": "42853",
      "city": "Remscheid",
      "street": "Alleestr. 64",
      "id": "b9f5c89c8a92de3433b723edb0b0a074"
    },
    {
      "zip": "42855",
      "city": "Remscheid",
      "street": "Hohenhagener Str. 26-28",
      "id": "9f2185814db2859ee9fb2b898858c941"
    },
    {
      "zip": "42859",
      "city": "Remscheid",
      "street": "Greulingstr. 39",
      "id": "7bb0b8ca18c51239cfcee40a987f264d"
    },
    {
      "zip": "44137",
      "city": "Dortmund",
      "street": "Kampstr. 106",
      "id": "10d24939825e06bb41f66ac35b5067e8"
    },
    {
      "zip": "44137",
      "city": "Dortmund",
      "street": "Kampstraße 106",
      "id": "3bf4202964612d8e0f5b2a6de5ff92a6"
    },
    {
      "zip": "44137",
      "city": "Dortmund",
      "street": "Westenhellweg 5",
      "id": "9c99eea59dd9a1d5aaa3eeb835d0717a"
    },
    {
      "zip": "44139",
      "city": "Dortmund",
      "street": "Hohe Str. 80",
      "id": "9af8f08084989c4b4bb0c2b0cbd89ee1"
    },
    {
      "zip": "44139",
      "city": "Dortmund",
      "street": "Saarlandstr. 51-67",
      "id": "fa47168cf542450768bdcfc306353d79"
    },
    {
      "zip": "44141",
      "city": "Dortmund",
      "street": "Westfalendamm 87",
      "id": "fe9dfae353ebad24ecb14691fa24d5ce"
    },
    {
      "zip": "44143",
      "city": "Dortmund",
      "street": "Düsterstr. 32",
      "id": "c96aa144a1cd770ed2b3d7a9cd11cc70"
    },
    {
      "zip": "44147",
      "city": "Dortmund",
      "street": "Mallinckrodtstr. 237",
      "id": "613168c2f04dd55dc46a15d52c88d923"
    },
    {
      "zip": "44147",
      "city": "Dortmund",
      "street": "Schützenstr. 94",
      "id": "3b7c399707f1cb4488c1c3d63ca4830b"
    },
    {
      "zip": "44263",
      "city": "Dortmund",
      "street": "Hermannstr. 48",
      "id": "ff86e47a89df4b570777e79c0dc9f9b4"
    },
    {
      "zip": "44379",
      "city": "Dortmund",
      "street": "Planetenfeldstr. 115",
      "id": "758782731a90719f5e033f5cf4fe3a2f"
    },
    {
      "zip": "44532",
      "city": "Lünen",
      "street": "Lange Str. 29",
      "id": "c2433434dfc3a19dc9fb86d7995a4792"
    },
    {
      "zip": "44534",
      "city": "Lünen",
      "street": "Kurt Schumacher Str. 5",
      "id": "1f178d2b72749dee46a388a7f0e9d8ce"
    },
    {
      "zip": "44575",
      "city": "Castrop-Rauxel",
      "street": "Münsterstr. 2C",
      "id": "e197a039152d187e082c7f7947af42c2"
    },
    {
      "zip": "44623",
      "city": "Herne",
      "street": "Bahnhofstr. 66",
      "id": "a1fec3ab4ce722ba36a8071530899229"
    },
    {
      "zip": "44651",
      "city": "Herne",
      "street": "Röhlinghauser Str. 28",
      "id": "3216302fef5b99680c6f351adfb10fde"
    },
    {
      "zip": "44787",
      "city": "Bochum",
      "street": "Kortumstr. 76",
      "id": "acf3ea6b7904f8f88f7c13f2e2c03756"
    },
    {
      "zip": "44791",
      "city": "Bochum",
      "street": "Am Einkaufszentrum 1",
      "id": "13cc84ecd28c3fc9a364f7df25f03e20"
    },
    {
      "zip": "44791",
      "city": "Bochum",
      "street": "Karl-Lange-Str. 15-29",
      "id": "307a00797aeef8e96b48ee3b2c81b31a"
    },
    {
      "zip": "44791",
      "city": "Bochum",
      "street": "Karl-Lange-Str. 19",
      "id": "3a9a8466d8733179b5817ca4ba30b944"
    },
    {
      "zip": "44791",
      "city": "Bochum",
      "street": "Karl-Lange-Str. 23",
      "id": "3fd4cd757c20a02bfd32d0d0dbcbaa09"
    },
    {
      "zip": "44791",
      "city": "Bochum",
      "street": "Karl-Lange-Str. 29",
      "id": "6eb7c841a77334148943705a60defc6b"
    },
    {
      "zip": "44807",
      "city": "Bochum",
      "street": "Walburgastr. 1A",
      "id": "95f420c9dd4559a364a8814421457c61"
    },
    {
      "zip": "45127",
      "city": "Essen",
      "street": "Kettwiger Str. 29A",
      "id": "e45a0837ea86961ec21794e7efb01d5b"
    },
    {
      "zip": "45130",
      "city": "Essen",
      "street": "Rüttenscheider Str. 117",
      "id": "ff2b87f7505edb83cc3901e00d2fd219"
    },
    {
      "zip": "45145",
      "city": "Essen",
      "street": "Am Fernmeldeamt 10",
      "id": "6de773247d9afa834116e25764a4b6fb"
    },
    {
      "zip": "45145",
      "city": "Essen",
      "street": "Am Fernmeldeamt 20",
      "id": "24a7405440d1b2ac308d55196e021b8a"
    },
    {
      "zip": "45276",
      "city": "Essen",
      "street": "Bochumer Str. 2-6",
      "id": "8e741feb3d0035d70d123741219dfcac"
    },
    {
      "zip": "45329",
      "city": "Essen",
      "street": "Altenessener Str. 411",
      "id": "6d5b7ced076cbb77cbf8e4cbbd5b9708"
    },
    {
      "zip": "45468",
      "city": "Mülheim An Der Ruhr",
      "street": "Hans-Böckler-Platz 10",
      "id": "054ee9995aeafeefac1429286ea8f319"
    },
    {
      "zip": "45472",
      "city": "Mülheim An Der Ruhr",
      "street": "Humboldtring 13",
      "id": "5a85dae1b7623d2863d9e770f2bef7bd"
    },
    {
      "zip": "45527",
      "city": "Hattingen",
      "street": "Heiskampstr. 2",
      "id": "3e53338885c86424a0fb4f1fdf09a9cf"
    },
    {
      "zip": "45657",
      "city": "Recklinghausen",
      "street": "Am Steintor 3",
      "id": "ea95c15f49b350ad1eda91427fd2d51c"
    },
    {
      "zip": "45657",
      "city": "Recklinghausen",
      "street": "Lessingstr. 49",
      "id": "cca641a50fe8ac6879b3fb9ee388bdf9"
    },
    {
      "zip": "45657",
      "city": "Recklinghausen",
      "street": "Löhrhof 1",
      "id": "16defe9c6312954ce7c701113845fdac"
    },
    {
      "zip": "45768",
      "city": "Marl",
      "street": "Bergstr. 228",
      "id": "1e6f0205de1eb1fae048038d4390ac22"
    },
    {
      "zip": "45879",
      "city": "Gelsenkirchen",
      "street": "Bahnhofstr. 31",
      "id": "e7bc7507eaca8c4b991bfa950f155928"
    },
    {
      "zip": "45879",
      "city": "Gelsenkirchen",
      "street": "Feldhofstr. 1",
      "id": "0a06f0ca6aaf34dca60addab9e029bd7"
    },
    {
      "zip": "45894",
      "city": "Gelsenkirchen",
      "street": "Mühlenstr. 2",
      "id": "ac365edc29c8d0e9e413e18b945fdcdf"
    },
    {
      "zip": "46045",
      "city": "Oberhausen",
      "street": "Paul-Reusch-Str. 2",
      "id": "cc7164abb7dbd2d4d7af5fd0db634bb5"
    },
    {
      "zip": "46045",
      "city": "Oberhausen",
      "street": "Paul-Reusch-Str. 4",
      "id": "78e0ae7f2477f24b62de4ee852ed1c76"
    },
    {
      "zip": "46047",
      "city": "Oberhausen",
      "street": "Centroallee 227",
      "id": "e03601739411dd6217e848fc9303b6d1"
    },
    {
      "zip": "46047",
      "city": "Oberhausen",
      "street": "Essener Str. 5",
      "id": "0b88e4075a1a9279085548f3ef7882b9"
    },
    {
      "zip": "46236",
      "city": "Bottrop",
      "street": "Kirchplatz 5",
      "id": "c21f0d2fa5baa614de068b8dd362b506"
    },
    {
      "zip": "46238",
      "city": "Bottrop",
      "street": "Siemensstr. 40",
      "id": "ff73a9997197ab236c76549ed1c53536"
    },
    {
      "zip": "46282",
      "city": "Dorsten",
      "street": "Lippestr. 3",
      "id": "7bcc0de8c78d98c107cc5010f499aac1"
    },
    {
      "zip": "46286",
      "city": "Dorsten",
      "street": "Beckenkamp 18",
      "id": "ac545d49e331383cfd13e87a41266516"
    },
    {
      "zip": "46325",
      "city": "Borken",
      "street": "Bahnhofstr. 8",
      "id": "eaafa58d4597b20cf0d9a4f8de72e034"
    },
    {
      "zip": "46395",
      "city": "Bocholt",
      "street": "Berliner Platz 2",
      "id": "fcd21acade0b3a733c46b4f5c6dc9a4f"
    },
    {
      "zip": "46395",
      "city": "Bocholt",
      "street": "Im Königsesch 43-49",
      "id": "4c4355c76db4c7de2f85c1aaab93b636"
    },
    {
      "zip": "46395",
      "city": "Bocholt",
      "street": "Im Königsesch 45",
      "id": "0ce2bf8d70bc19a50b17c398f764e7f3"
    },
    {
      "zip": "46483",
      "city": "Wesel",
      "street": "Berliner-Tor-Platz 1",
      "id": "6342de68aee5566f3f77067f2157592c"
    },
    {
      "zip": "46483",
      "city": "Wesel",
      "street": "Friedrichstr. 1",
      "id": "31608a6a7ed5fde863e690a99c5f1675"
    },
    {
      "zip": "46483",
      "city": "Wesel",
      "street": "Hohe Str. 56",
      "id": "11ed538c49572bd8ddf45ecca8731d5f"
    },
    {
      "zip": "46485",
      "city": "Wesel",
      "street": "Philipp-Reis-Str. 7-9",
      "id": "8fc53dc131333a073dcf7af0f7f4456e"
    },
    {
      "zip": "46535",
      "city": "Dinslaken",
      "street": "Neustr. 6",
      "id": "4c7b0a389d0ad52d6907351be78ce462"
    },
    {
      "zip": "47051",
      "city": "Duisburg",
      "street": "Königstr. 10",
      "id": "9378589cc2c4edddc10d46e771896b48"
    },
    {
      "zip": "47051",
      "city": "Duisburg",
      "street": "Königstr. 48",
      "id": "adba6d7c37d9d9aca295a79cb79627c8"
    },
    {
      "zip": "47053",
      "city": "Duisburg",
      "street": "Düsseldorfer Str. 181-184",
      "id": "004f59748e8e902e6f127ee6951d1a50"
    },
    {
      "zip": "47058",
      "city": "Duisburg",
      "street": "Saarstr. 12",
      "id": "74784d74dc2f1cb30840bb843ee3df01"
    },
    {
      "zip": "47058",
      "city": "Duisburg",
      "street": "Saarstr. 12-14",
      "id": "627a3667e537451d3f6ad96fae0b5595"
    },
    {
      "zip": "47166",
      "city": "Duisburg",
      "street": "Jägerstr. 74",
      "id": "cf944761a4b3c694a73d010ea27a1cab"
    },
    {
      "zip": "47167",
      "city": "Duisburg",
      "street": "Am Inzerfeld 45",
      "id": "5607c194731e52115f79c8a168e7b4fe"
    },
    {
      "zip": "47441",
      "city": "Moers",
      "street": "Homberger Str. 4",
      "id": "85db66bfb10f9c59ca815e6b2c24d4d5"
    },
    {
      "zip": "47443",
      "city": "Moers",
      "street": "Königsberger Str. 64",
      "id": "0154a91d5cd364c144b6b3e9aa80b469"
    },
    {
      "zip": "47533",
      "city": "Kleve",
      "street": "Große Str. 55",
      "id": "63d6b5b82425ca4ea8edbaecf61e437c"
    },
    {
      "zip": "47533",
      "city": "Kleve",
      "street": "Huissener Str. 5",
      "id": "c7890bfafe24bee34afdafa1ee7f842e"
    },
    {
      "zip": "47608",
      "city": "Geldern",
      "street": "Issumer Str. 1",
      "id": "54a9a496c4f58de080556e5273e41b93"
    },
    {
      "zip": "47798",
      "city": "Krefeld",
      "street": "Moerser Str. 1",
      "id": "c4b4d5321ce5aa832fe1c3386a0b87ca"
    },
    {
      "zip": "47798",
      "city": "Krefeld",
      "street": "Moerser Str. 1-7",
      "id": "721462356c0e2b8bdf6dcd790d3f98bf"
    },
    {
      "zip": "47798",
      "city": "Krefeld",
      "street": "Moerser Str. 7",
      "id": "e7e0ea1d6a78506474aefa191e2b03c5"
    },
    {
      "zip": "47798",
      "city": "Krefeld",
      "street": "Schwanenmarkt 42",
      "id": "5b6499d7a0decafe25b37833bf09e66b"
    },
    {
      "zip": "47906",
      "city": "Kempen",
      "street": "Moorenring 1",
      "id": "387cffb5fc42adbc3a650f8f65ead786"
    },
    {
      "zip": "48143",
      "city": "Münster",
      "street": "Ludgeristr. 30-31",
      "id": "d82b5cacf345d206df10f5bd690631a3"
    },
    {
      "zip": "48145",
      "city": "Münster",
      "street": "Oststr. 2",
      "id": "2737b297030a873949643342580b6c3e"
    },
    {
      "zip": "48147",
      "city": "Münster",
      "street": "Gartenstr. 215",
      "id": "e7f78d6b5a5a3849e704111f830ae20a"
    },
    {
      "zip": "48147",
      "city": "Münster",
      "street": "Gartenstr. 215-21",
      "id": "da729169e371f8537b73e1227b840ad1"
    },
    {
      "zip": "48147",
      "city": "Münster",
      "street": "Gartenstr. 215-217",
      "id": "01923bea78de14f1cf4206aef9918621"
    },
    {
      "zip": "48147",
      "city": "Münster",
      "street": "Gartenstr. 217",
      "id": "aa4f5f888dc2815ec4a6a56d93ca9935"
    },
    {
      "zip": "48153",
      "city": "Münster",
      "street": "Dahlweg 100",
      "id": "6766e5a8fb211d3e347b43d80ff40110"
    },
    {
      "zip": "48153",
      "city": "Münster",
      "street": "Dahlweg 100-102",
      "id": "0ab7f246be8e1bf84aff64028df50ea9"
    },
    {
      "zip": "48153",
      "city": "Münster",
      "street": "Friedrich-Ebert-Str. 143",
      "id": "de57cd29567c3f30721ab97bd2b27d12"
    },
    {
      "zip": "48155",
      "city": "Münster",
      "street": "Wolbecker Str. 268",
      "id": "4b5b1af56b72cb860299d10bb03ab369"
    },
    {
      "zip": "48159",
      "city": "Münster",
      "street": "Neuer Heidkamp 1",
      "id": "416cea8f0c115422784c796c170272ba"
    },
    {
      "zip": "48231",
      "city": "Warendorf",
      "street": "Ostwall 29-31",
      "id": "b62e6b331e84b946b43d8e24d315557b"
    },
    {
      "zip": "48282",
      "city": "Emsdetten",
      "street": "Bonhoefferstr. 5",
      "id": "99c3a6b09db34a03c0c943f7ece23172"
    },
    {
      "zip": "48282",
      "city": "Emsdetten",
      "street": "Rheiner Str. 4",
      "id": "c683a9e83294078487a3fe09345f86a2"
    },
    {
      "zip": "48429",
      "city": "Rheine",
      "street": "Altenrheiner Str. 34",
      "id": "93ebe732b6b6aa9345b64154a591bfb0"
    },
    {
      "zip": "48431",
      "city": "Rheine",
      "street": "Emsstr. 19",
      "id": "1aad16ff3ca189af136d81977e5025cf"
    },
    {
      "zip": "48431",
      "city": "Rheine",
      "street": "Pappelstr. 6",
      "id": "81391f9cbfdaedcc43061cf71f8dbfab"
    },
    {
      "zip": "48529",
      "city": "Nordhorn",
      "street": "Bahnhofstr. 1",
      "id": "82bfad86304bde4f8ef7c0b6f4b38ce2"
    },
    {
      "zip": "48529",
      "city": "Nordhorn",
      "street": "Hauptstr. 40",
      "id": "e14cb0dcbd7d82296fb6d63b0b2c0ba5"
    },
    {
      "zip": "48565",
      "city": "Steinfurt",
      "street": "Gartenstr. 30",
      "id": "66dc670897ebb389cce36987c7c10e3e"
    },
    {
      "zip": "48565",
      "city": "Steinfurt",
      "street": "Gartenstr. 30-36",
      "id": "736749893aeea46d928577087547fb9d"
    },
    {
      "zip": "48565",
      "city": "Steinfurt",
      "street": "Gartenstr. 36",
      "id": "3a1f653dd0065843d105c31f40dd97c8"
    },
    {
      "zip": "48599",
      "city": "Gronau (westfalen)",
      "street": "Konrad-Adenauer-Str. 12",
      "id": "018b0ff2252b3bea0bec58eb2f625d37"
    },
    {
      "zip": "48599",
      "city": "Gronau (westfalen)",
      "street": "Schweringstr. 1",
      "id": "f3904667e5fb073392201d6f946379ba"
    },
    {
      "zip": "48653",
      "city": "Coesfeld",
      "street": "Borkener Str. 77",
      "id": "fa5fb62ef4af49191344716f8c2d5fc6"
    },
    {
      "zip": "48653",
      "city": "Coesfeld",
      "street": "Süringstr. 2",
      "id": "439b47899f72d8bcdd08024bc3689859"
    },
    {
      "zip": "48683",
      "city": "Ahaus",
      "street": "Bahnhofstr. 104",
      "id": "af945c5a6cb1ba56bc1c92ed6d357a11"
    },
    {
      "zip": "49074",
      "city": "Osnabrück",
      "street": "Große Str. 4",
      "id": "e6454ca6367215afeb0b238ba961403c"
    },
    {
      "zip": "49074",
      "city": "Osnabrück",
      "street": "Möserstr. 19",
      "id": "4fe928e219913f1c8522aac9f09295d5"
    },
    {
      "zip": "49084",
      "city": "Osnabrück",
      "street": "Hannoversche Str. 6-8",
      "id": "5cb0adf5247d077f9c2dc22f7ab001b6"
    },
    {
      "zip": "49124",
      "city": "Georgsmarienhütte",
      "street": "Kolpingstr. 6-7",
      "id": "53392d71b0f9fa1bce9c2fb990c29194"
    },
    {
      "zip": "49163",
      "city": "Bohmte",
      "street": "Hauweg 43",
      "id": "1b649f0d4e56e85f3aa7969d4f45659f"
    },
    {
      "zip": "49324",
      "city": "Melle",
      "street": "Dürrenberger Ring 14",
      "id": "16aec3e6f0212d72852790b48fb90621"
    },
    {
      "zip": "49356",
      "city": "Diepholz",
      "street": "Lappenberg 7",
      "id": "c420098ee55eca9449948b856d3f4967"
    },
    {
      "zip": "49377",
      "city": "Vechta",
      "street": "Große Str. 60",
      "id": "5ec063d3b1cccb421d697482de7905e2"
    },
    {
      "zip": "49377",
      "city": "Vechta",
      "street": "Ravensberger Str. 4",
      "id": "6e7599d997e68892f202394fd3875019"
    },
    {
      "zip": "49401",
      "city": "Damme",
      "street": "Rüschendorfer Str. 19-21",
      "id": "cc5fa7e299d8147f437f764890044386"
    },
    {
      "zip": "49477",
      "city": "Ibbenbüren",
      "street": "An Der Reichsbahn 4",
      "id": "fb30e7ef0196edc168571a09f975e8c7"
    },
    {
      "zip": "49477",
      "city": "Ibbenbüren",
      "street": "Bachstr. 7",
      "id": "99ea338d8698eb54d52c6ac2c50a592c"
    },
    {
      "zip": "49565",
      "city": "Bramsche",
      "street": "Lindenstr. 3",
      "id": "14a2aa85c7eaaa581750fd481077024a"
    },
    {
      "zip": "49584",
      "city": "Fürstenau",
      "street": "Schorfteichstr. 1A",
      "id": "68988cd322bea09b22ee8967817c5c87"
    },
    {
      "zip": "49610",
      "city": "Quakenbrück",
      "street": "Hohe Str. 3",
      "id": "2a0f86d9f4aa636790dd078bf914d79b"
    },
    {
      "zip": "49661",
      "city": "Cloppenburg",
      "street": "Emsteker Str. 2",
      "id": "ae20953650ec5f3c17b023deaa5fdf26"
    },
    {
      "zip": "49716",
      "city": "Meppen",
      "street": "Schillerring 28",
      "id": "242a3e32adafe75273666c8148f62c45"
    },
    {
      "zip": "49733",
      "city": "Haren",
      "street": "Nordstr. 50A",
      "id": "413d1361ebb07568a05386250bdecbfc"
    },
    {
      "zip": "49757",
      "city": "Werlte",
      "street": "Molkereistr. 6",
      "id": "f8005137544f1b26b74ceac712b5404c"
    },
    {
      "zip": "49808",
      "city": "Lingen",
      "street": "Kiefernstr. 14-16",
      "id": "918429a378e668ed4ba2f06ccbf80c3c"
    },
    {
      "zip": "50126",
      "city": "Bergheim",
      "street": "Kölner Str. 1",
      "id": "989ed9f26fd9ebadf92b88ef66b680ae"
    },
    {
      "zip": "50171",
      "city": "Kerpen",
      "street": "Hahnenstr. 22A",
      "id": "e00c7380a1369ac262ca4da5b6dd4aeb"
    },
    {
      "zip": "50226",
      "city": "Frechen",
      "street": "Bartmann Str. 21",
      "id": "86befcdc42ff8d836436f07e46046837"
    },
    {
      "zip": "50226",
      "city": "Frechen",
      "street": "Hauptstr. 95",
      "id": "b18517949c31c64c8de2ec1cec53a1a2"
    },
    {
      "zip": "50321",
      "city": "Brühl",
      "street": "Bergerstr. 80",
      "id": "bc772da2e5d8d24795c9de5728f74e8f"
    },
    {
      "zip": "50321",
      "city": "Brühl",
      "street": "Comestr. 18",
      "id": "03922a9f27560ab13aecb2acfec3d3de"
    },
    {
      "zip": "50321",
      "city": "Brühl",
      "street": "Sürther Str. 168",
      "id": "b8b158ccd39a1632f005e13c68f9bb54"
    },
    {
      "zip": "50354",
      "city": "Hürth",
      "street": "Theresienhöhe 24",
      "id": "50661e4f0f024aa769e4f2b88a5d8c44"
    },
    {
      "zip": "50667",
      "city": "Köln",
      "street": "Schildergasse 84",
      "id": "0de51e98de14c55ed42f339e8a9ea980"
    },
    {
      "zip": "50667",
      "city": "Köln",
      "street": "Schwertnergasse 1",
      "id": "5cf1c8f913e75b587a0a58a7f1f5e4f5"
    },
    {
      "zip": "50672",
      "city": "Köln",
      "street": "Innere Kanalstr. 98",
      "id": "0311df9bb1d1065fe1d4a89e503fb8ea"
    },
    {
      "zip": "50672",
      "city": "Köln",
      "street": "Innere Kanalstraße 98",
      "id": "56eb3cd185667371032f5cb846269d4b"
    },
    {
      "zip": "50676",
      "city": "Köln",
      "street": "Sternengasse 14-16",
      "id": "0dac13fcf7e62d39571f7b9b5ae179a2"
    },
    {
      "zip": "50678",
      "city": "Köln",
      "street": "Bayenwerft 12-14",
      "id": "c609416555479a17ffd55c65433d4bb0"
    },
    {
      "zip": "50765",
      "city": "Köln",
      "street": "Mailänder Passage 1",
      "id": "9bd9f753ddd6d66c7dc10f9658db2a36"
    },
    {
      "zip": "50765",
      "city": "Köln",
      "street": "Merianstr. 98",
      "id": "b21b1dd4c7c82acc7873417fd8203c56"
    },
    {
      "zip": "50823",
      "city": "Köln",
      "street": "Barthelstr. 75",
      "id": "12592511a5c25e63cf7fc454cebdcd11"
    },
    {
      "zip": "50823",
      "city": "Köln",
      "street": "Weinsbergstr. 70",
      "id": "1bcb31395e972ab74ffd5aef203dac77"
    },
    {
      "zip": "50859",
      "city": "Köln",
      "street": "Aachener Str. 1253",
      "id": "65295d738cb2b6269417b8bb9867a1eb"
    },
    {
      "zip": "50933",
      "city": "Köln",
      "street": "Scheidtweilerstr. 4",
      "id": "4454be21299ae4ea68da7c589747d6a1"
    },
    {
      "zip": "51065",
      "city": "Köln",
      "street": "Wiener Platz 1",
      "id": "ddbcd534be2ee95bb00c5d8462a4c03d"
    },
    {
      "zip": "51103",
      "city": "Köln",
      "street": "Kalker Hauptstr. 55",
      "id": "9d32cb37887c47f73353b2c781e9e25f"
    },
    {
      "zip": "51105",
      "city": "Köln",
      "street": "Poll-Vingster-Str. 132",
      "id": "9d1839f9627f5b85152d173a5e0edf16"
    },
    {
      "zip": "51105",
      "city": "Köln",
      "street": "Poll-Vingster-Str. 132-134",
      "id": "2700e0e4ea754afaf89605da47ccf7f5"
    },
    {
      "zip": "51107",
      "city": "Köln",
      "street": "Henri-Dunant-Str. 1",
      "id": "f2f7a75ca67cdf9a7db8593bb66de296"
    },
    {
      "zip": "51373",
      "city": "Leverkusen",
      "street": "Wiesdorfer Platz 31",
      "id": "790065c9f251dfc843728cee5424e052"
    },
    {
      "zip": "51375",
      "city": "Leverkusen",
      "street": "Bergische Landstr. 71",
      "id": "7b984a7bf8e8e4062ca1f4e6b23bc7ec"
    },
    {
      "zip": "51377",
      "city": "Leverkusen",
      "street": "Am Mühlenbach 3",
      "id": "32e0063eb01376ab70424b0e93e6586c"
    },
    {
      "zip": "51379",
      "city": "Leverkusen",
      "street": "Reusrather Str. 36-38",
      "id": "1a464d27bec2332fc12966329d7d975c"
    },
    {
      "zip": "51465",
      "city": "Bergisch Gladbach",
      "street": "Hauptstr. 142",
      "id": "6a1787e2757f5b506139a6d6632acdf7"
    },
    {
      "zip": "51465",
      "city": "Bergisch Gladbach",
      "street": "Hauptstr. 176",
      "id": "7069ed109b594218f01ab1e2beaab617"
    },
    {
      "zip": "51465",
      "city": "Bergisch Gladbach",
      "street": "Poststr. 9",
      "id": "22de5bb27f898269f4ebf316e6ff7459"
    },
    {
      "zip": "51643",
      "city": "Gummersbach",
      "street": "Feldstr. 1A",
      "id": "577595d8040299d53f641729e15211e3"
    },
    {
      "zip": "51643",
      "city": "Gummersbach",
      "street": "Moltkestr. 1",
      "id": "c421587c777d36541be08bfe3c1b3a6e"
    },
    {
      "zip": "51643",
      "city": "Gummersbach",
      "street": "Moltkestr. 20",
      "id": "6ea7195bf4d1bbcd663aa8cf467c0dca"
    },
    {
      "zip": "51645",
      "city": "Gummersbach",
      "street": "Dieringhauser Str. 97",
      "id": "dd01ac007aa5bff80ea77c69206ead72"
    },
    {
      "zip": "52062",
      "city": "Aachen",
      "street": "Adalbertstr. 100",
      "id": "85a6f7fc603aeba2768230d89632fa03"
    },
    {
      "zip": "52062",
      "city": "Aachen",
      "street": "Adalbertstr. 27",
      "id": "98c549c805a07166bbfabb9ed21525f1"
    },
    {
      "zip": "52062",
      "city": "Aachen",
      "street": "Holzgraben 4-6",
      "id": "e57e3ec0a5e7cd7861e0c95fb34fb2d0"
    },
    {
      "zip": "52070",
      "city": "Aachen",
      "street": "Am Gut Wolf 9A",
      "id": "fee1c90783bc8f23c905c8fbabd8a4d1"
    },
    {
      "zip": "52078",
      "city": "Aachen",
      "street": "Auf Krummerück 24-26",
      "id": "fe2f1cd0bfa9121e416385407eccbfe4"
    },
    {
      "zip": "52146",
      "city": "Würselen",
      "street": "St.-Jobser-Str. 47",
      "id": "3299695174ccdd6816593a362b44e020"
    },
    {
      "zip": "52152",
      "city": "Simmerath",
      "street": "Mathias-Zimmermann-Str. 8-10",
      "id": "9030d422abd3ff6cc9a0a9e7d14bc992"
    },
    {
      "zip": "52249",
      "city": "Eschweiler",
      "street": "Grabenstr. 49-51",
      "id": "b8ff0dcbc5660177f77065b7de5e5584"
    },
    {
      "zip": "52349",
      "city": "Düren",
      "street": "Im Weyerfeld 7",
      "id": "6cb1eb9bca7adc32f14a60908aa5e7bf"
    },
    {
      "zip": "52349",
      "city": "Düren",
      "street": "Kölnstr. 20",
      "id": "02113dec2804f8068858adb09bb7a9fb"
    },
    {
      "zip": "52349",
      "city": "Düren",
      "street": "Walzmühle 3",
      "id": "03cea1e1d6d28cf1c6d48b1b56f9343c"
    },
    {
      "zip": "52351",
      "city": "Düren",
      "street": "Kölnstr. 74A",
      "id": "233a04277ce81befd78997c36edc676f"
    },
    {
      "zip": "52351",
      "city": "Düren",
      "street": "Wernersstr. 21-23",
      "id": "b58ab5fdef22aaa7ae7cf521210aee88"
    },
    {
      "zip": "52355",
      "city": "Düren",
      "street": "Am Ellernbusch 18-20",
      "id": "da582ac9056dff628d51c0e3648b9eba"
    },
    {
      "zip": "52355",
      "city": "Düren",
      "street": "In Der Mühlenau 107",
      "id": "e20c5c154434eed0b052cad2b9d37359"
    },
    {
      "zip": "52428",
      "city": "Jülich",
      "street": "Bastionstr. 11-19",
      "id": "0292b6fb012c780db4769b47849b733f"
    },
    {
      "zip": "52428",
      "city": "Jülich",
      "street": "Kölnstr. 44",
      "id": "25433ca117f5aaa027813a0a54b1a943"
    },
    {
      "zip": "52511",
      "city": "Geilenkirchen",
      "street": "Stettiner Str. 4",
      "id": "959352739d113172f2f3dc0b252f0f52"
    },
    {
      "zip": "52525",
      "city": "Heinsberg",
      "street": "Hochstr. 124",
      "id": "8f2e332a7faf2cb08a693947352b7793"
    },
    {
      "zip": "52525",
      "city": "Heinsberg",
      "street": "Schützenstr. 16",
      "id": "7421ff88e52612090676c21d216be794"
    },
    {
      "zip": "53111",
      "city": "Bonn",
      "street": "Remigiusstr. 11",
      "id": "d8a6d4b760d7dbb7d97e1b9f7ab18e49"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Bonner Talweg 100",
      "id": "6cfeb8df6f83cf31aab5519303036117"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Friedrich-Ebert-Allee 140",
      "id": "f5405382a67b2fba26cf1ff64913a7d1"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Friedrich-Ebert-Allee 41",
      "id": "506166b14525384f50075bca45f7b883"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Friedrich-Ebert-Allee 71-77",
      "id": "86d2bb02b9592f6ae360bf505e19517a"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Friedrich-Ebert-Allee 75",
      "id": "dadd7b7c84799c3a84fabbf3f2ac8524"
    },
    {
      "zip": "53113",
      "city": "Bonn",
      "street": "Karl-Marx-Str. 11",
      "id": "8631446f48f21245cb6c261769a5d910"
    },
    {
      "zip": "53117",
      "city": "Bonn",
      "street": "Graurheindorfer Str. 153",
      "id": "f16002776e10c8ddb39934821ae7568a"
    },
    {
      "zip": "53119",
      "city": "Bonn",
      "street": "Lievelingsweg 125",
      "id": "0a105883798ebb63085ad4d2ace8bd6f"
    },
    {
      "zip": "53121",
      "city": "Bonn",
      "street": "Am Propsthof 10",
      "id": "44a97dddcfede3ee5a8a57fd0a7795fc"
    },
    {
      "zip": "53121",
      "city": "Bonn",
      "street": "Am Propsthof 49",
      "id": "127ded9be5e24a1eace223f8ba7ff203"
    },
    {
      "zip": "53173",
      "city": "Bonn",
      "street": "Koblenzer Str. 48",
      "id": "d086d9107666c93b12fe9c50d4dbed0e"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 152",
      "id": "2e313667dbc60e49ef4e034346d6ca6b"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 154",
      "id": "aeb70727f88cfa9b31d9df54c739dc6e"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 83",
      "id": "cfd906c7666528971eb0c4a84986d385"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 83-91",
      "id": "80c3d3c957e5ec342a9f00a390bf93ce"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 87-91",
      "id": "46757ca8526cebef3816e3e8627dd08d"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 93",
      "id": "a98312d31597a8df762664759252850e"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Godesberger Allee 99",
      "id": "9acd26458bec1c80c96e86a3208e1013"
    },
    {
      "zip": "53175",
      "city": "Bonn",
      "street": "Langer Grabenweg 34",
      "id": "14340558c170b511b21dadf28a134132"
    },
    {
      "zip": "53177",
      "city": "Bonn",
      "street": "Am Kurpark 2",
      "id": "fab1256d0eec23a8f66100118e2448d4"
    },
    {
      "zip": "53225",
      "city": "Bonn",
      "street": "Kreuzstr. 24",
      "id": "82afbdd4d8138f90382dec09fc7f9e1e"
    },
    {
      "zip": "53227",
      "city": "Bonn",
      "street": "Landgrabenweg 147",
      "id": "cc377ee44d66351415b1ed00204c4796"
    },
    {
      "zip": "53227",
      "city": "Bonn",
      "street": "Landgrabenweg 151",
      "id": "0cf03a878d6d2cdd59ba1c1ed6337ced"
    },
    {
      "zip": "53227",
      "city": "Bonn",
      "street": "Oberkasseler Str. 2",
      "id": "1da126639ad88deffc1369fb6b75ad76"
    },
    {
      "zip": "53474",
      "city": "Bad Neuenahr",
      "street": "Ravensberger Str. 49",
      "id": "05d99d2bfd2a49870db4b8de8fc9899f"
    },
    {
      "zip": "53518",
      "city": "Adenau",
      "street": "Hauptstr. 17A",
      "id": "5d68621ebcca2b10ebbaedfb3e3bcaf0"
    },
    {
      "zip": "53518",
      "city": "Adenau",
      "street": "Hauptstr. 19",
      "id": "c58e036ed14ca270cd47621f90985c7a"
    },
    {
      "zip": "53572",
      "city": "Unkel",
      "street": "Anton-Limbach-Str. 3",
      "id": "f617d883c47646878a2ad83413d6f1a9"
    },
    {
      "zip": "53604",
      "city": "Bad Honnef",
      "street": "Limbicher Weg 55",
      "id": "84a4a84a54447db598d500e82f37135e"
    },
    {
      "zip": "53721",
      "city": "Siegburg",
      "street": "Markt 13",
      "id": "85a015c4e738825013acd2406673e1b6"
    },
    {
      "zip": "53721",
      "city": "Siegburg",
      "street": "Zeithstr. 73",
      "id": "3acb577da7b8ba9afebff7c764cd304e"
    },
    {
      "zip": "53757",
      "city": "Sankt Augustin",
      "street": "Rathausallee 16",
      "id": "d080cb57588d4c28cb64f4bca6065fc7"
    },
    {
      "zip": "53879",
      "city": "Euskirchen",
      "street": "Frauenberger Str. 152",
      "id": "43699b743d68f52d3055aec0ea5fd680"
    },
    {
      "zip": "53879",
      "city": "Euskirchen",
      "street": "In Den Herrenbenden 29",
      "id": "94488c4b8c0b3f9351f9a065a9c3654a"
    },
    {
      "zip": "53879",
      "city": "Euskirchen",
      "street": "In Den Herrenbenden 29-31",
      "id": "8dd63b9baec3252536157c881e2fb676"
    },
    {
      "zip": "53879",
      "city": "Euskirchen",
      "street": "Neustr. 15",
      "id": "b2c4a0c211166643c886a48950dabc8b"
    },
    {
      "zip": "53925",
      "city": "Kall",
      "street": "Gemünder Str. 2-4",
      "id": "9b46dc58ba8e88b8c90eb0a7b10ad68e"
    },
    {
      "zip": "53947",
      "city": "Nettersheim",
      "street": "Grabenweg 11",
      "id": "3a6e1af3743abc39701c9a11d6cc2518"
    },
    {
      "zip": "54290",
      "city": "Trier",
      "street": "Fleischstr. 56-60",
      "id": "c040926d1908f966c24dde15fb56c35c"
    },
    {
      "zip": "54292",
      "city": "Trier",
      "street": "Castelfortestr. 2",
      "id": "a9e1c1af165d53d38637f46c74d99fa3"
    },
    {
      "zip": "54292",
      "city": "Trier",
      "street": "Wasserweg 7-9",
      "id": "a9756d6ac3bfc52b0a32a663275dfe5e"
    },
    {
      "zip": "54411",
      "city": "Hermeskeil",
      "street": "Alte Kirchstr. 25",
      "id": "cfbdb410119c7f6c5e7303344fe4ab62"
    },
    {
      "zip": "54439",
      "city": "Saarburg",
      "street": "Brückenstr. 6",
      "id": "c233ec8575737a7c5cfc07fe69fb377e"
    },
    {
      "zip": "54516",
      "city": "Wittlich",
      "street": "Kalkturmstr. 47A",
      "id": "627b024b38553e285b274b8386c0e509"
    },
    {
      "zip": "54516",
      "city": "Wittlich",
      "street": "Trierer Str. 6",
      "id": "4de454baedf0a3fd0ab8f205ceee1a7a"
    },
    {
      "zip": "54568",
      "city": "Gerolstein",
      "street": "Brunnenstr. 2-5",
      "id": "54e7333e485d6084a35ec33cf05e07e4"
    },
    {
      "zip": "54634",
      "city": "Bitburg",
      "street": "Güterstr. 2-4",
      "id": "f8bfd27d71e1b15cd1cc2d0bade6b032"
    },
    {
      "zip": "54634",
      "city": "Bitburg",
      "street": "Trierer Str. 36",
      "id": "ecb689c0e8093def32095ff7da4eff6b"
    },
    {
      "zip": "55116",
      "city": "Mainz",
      "street": "Am Brand 36",
      "id": "0417adbb189e7a08c2a32a8c8f766ca3"
    },
    {
      "zip": "55116",
      "city": "Mainz",
      "street": "Münsterplatz 2",
      "id": "24280134ca5d6a53c62cd2a64642b544"
    },
    {
      "zip": "55116",
      "city": "Mainz",
      "street": "Münsterplatz 2-6",
      "id": "3eb57363fc434ecb2389f55b1aa73404"
    },
    {
      "zip": "55122",
      "city": "Mainz",
      "street": "Wallstr. 88",
      "id": "9b2a657298908b4924e13825f332b3a6"
    },
    {
      "zip": "55129",
      "city": "Mainz",
      "street": "Robert-Koch-Str. 50",
      "id": "b3c32bc936bc5c5896a7a02d46a231f0"
    },
    {
      "zip": "55131",
      "city": "Mainz",
      "street": "Jägerstr. 21",
      "id": "29c84459bcebf0c33a62423f170c7fa3"
    },
    {
      "zip": "55232",
      "city": "Alzey",
      "street": "Bahnhofstr. 71-75",
      "id": "62a4e2cbadbe6ba731cac5193af1cd98"
    },
    {
      "zip": "55232",
      "city": "Alzey",
      "street": "Bahnhofstr. 71-77",
      "id": "e048c4cfcd9726aa1eb5f7d6401fab01"
    },
    {
      "zip": "55246",
      "city": "Mainz-Kostheim",
      "street": "Waldhofstr. 9",
      "id": "6b62156cd6fbe40c3097cabc66f55e07"
    },
    {
      "zip": "55268",
      "city": "Nieder-Olm",
      "street": "Kuher Weg 3-5",
      "id": "e131270c0707191e1c4d47b2b42e379c"
    },
    {
      "zip": "55294",
      "city": "Bodenheim",
      "street": "Hilgestr. 21-23",
      "id": "a4c9f90b3ea1957e00f2b7ae20668f67"
    },
    {
      "zip": "55469",
      "city": "Simmern",
      "street": "Mutterschieder Str. 3",
      "id": "241bb0ebfa639b5a333090d5a791cf7c"
    },
    {
      "zip": "55469",
      "city": "Simmern",
      "street": "Schloßstr. 24",
      "id": "565b7bc0087b8541c423d6e710836b7d"
    },
    {
      "zip": "55543",
      "city": "Bad Kreuznach",
      "street": "George-Marshall-Str. 10",
      "id": "6c9cab33b8f97925abf6411e4852beb3"
    },
    {
      "zip": "55543",
      "city": "Bad Kreuznach",
      "street": "Mannheimer Str. 163",
      "id": "1dea8d0a67a88972aebe7be2e98c2d47"
    },
    {
      "zip": "55545",
      "city": "Bad Kreuznach",
      "street": "Poststr. 20-28",
      "id": "08956129cbef4d551368df8bacd5bdec"
    },
    {
      "zip": "55545",
      "city": "Bad Kreuznach",
      "street": "Poststr. 20-32",
      "id": "0c6833de66913273d5bbcde1f8d4c84e"
    },
    {
      "zip": "55566",
      "city": "Bad Sobernheim",
      "street": "Poststr. 22",
      "id": "1b7ae6e39a92a92ec821a43ad2fe9713"
    },
    {
      "zip": "55566",
      "city": "Bad Sobernheim",
      "street": "Poststr. 22-24",
      "id": "5ce4e2b36cf9ae3e177cfc424fcd7e1e"
    },
    {
      "zip": "55743",
      "city": "Idar-Oberstein",
      "street": "Auf Der Acht 2",
      "id": "2e08fd1697f5e7312927f2151819b68e"
    },
    {
      "zip": "55743",
      "city": "Idar-Oberstein",
      "street": "Hauptstr. 338",
      "id": "18b2a8ede060f84b5604345f45645c26"
    },
    {
      "zip": "56068",
      "city": "Koblenz",
      "street": "Hohenfelder Str. 22",
      "id": "f64d4b71eb3dcb53bfd1322e65f93295"
    },
    {
      "zip": "56068",
      "city": "Koblenz",
      "street": "Löhrstr. 22",
      "id": "77ca404acb4aed11680a9b8e4dfdf95c"
    },
    {
      "zip": "56068",
      "city": "Koblenz",
      "street": "Zentralplatz 1",
      "id": "9b037a148727b1b07386669ee81a8061"
    },
    {
      "zip": "56070",
      "city": "Koblenz",
      "street": "Von-Kuhl-Str. 51",
      "id": "316866a5a2c1ef012d050b0071b73304"
    },
    {
      "zip": "56073",
      "city": "Koblenz",
      "street": "Blücherstr. 6-8",
      "id": "34c90a073946706e6af6ccbea3a38d8f"
    },
    {
      "zip": "56073",
      "city": "Koblenz",
      "street": "Moselweißer Str. 70",
      "id": "f808d5a204eb62fcf02d3e00d9511329"
    },
    {
      "zip": "56218",
      "city": "Mülheim-Kärlich",
      "street": "Industriestr. 15",
      "id": "dca4bdef45dff4c9ab33c24c1905a70c"
    },
    {
      "zip": "56235",
      "city": "Ransbach-Baumbach",
      "street": "Berliner Str. 18",
      "id": "0891124c29edd2a589997e2039d84583"
    },
    {
      "zip": "56329",
      "city": "St. Goar",
      "street": "Am Rheinweg 7-9",
      "id": "17da1b9cace5765e61e5f36e15af8853"
    },
    {
      "zip": "56346",
      "city": "Sankt Goarshausen",
      "street": "Wellmicherstr. 19",
      "id": "cd72b137008fd81c2a6ffecb43cf2e4a"
    },
    {
      "zip": "56410",
      "city": "Montabaur",
      "street": "An Der Bahlsmühle 2",
      "id": "d1aa564a6abd420335890072dce36f0e"
    },
    {
      "zip": "56470",
      "city": "Bad Marienberg",
      "street": "Jahnstr. 36",
      "id": "ac655e8f3e5de37ac0a750fac22209b2"
    },
    {
      "zip": "56564",
      "city": "Neuwied",
      "street": "Andernacher Str. 19",
      "id": "357bc82697d295e80015b8cfd0ae8944"
    },
    {
      "zip": "56564",
      "city": "Neuwied",
      "street": "Mittelstr. 80",
      "id": "0e7728f881ba84a546a207b88c99da3c"
    },
    {
      "zip": "56564",
      "city": "Neuwied",
      "street": "Weißenthurmer Str. 4",
      "id": "3464752a568ccc0934c8cc030d81475d"
    },
    {
      "zip": "56727",
      "city": "Mayen",
      "street": "Marktstr. 9",
      "id": "bb8d17ff0915ab9441ec699a590e220d"
    },
    {
      "zip": "56727",
      "city": "Mayen",
      "street": "Polcher Str. 15-19",
      "id": "162e1d5c0a465ad60fe0a4f1f55a0e3f"
    },
    {
      "zip": "56812",
      "city": "Cochem",
      "street": "Endertstr. 84-88",
      "id": "39265ac60f6bab9e84ebc19497d77e76"
    },
    {
      "zip": "56841",
      "city": "Traben-Trarbach",
      "street": "Sponheimer Str. 28-30",
      "id": "e1962b08c77d9eec5fa29de428bfa99d"
    },
    {
      "zip": "57072",
      "city": "Siegen",
      "street": "Am Bahnhof 40",
      "id": "59986b61f62526b658d2a453a7cf9138"
    },
    {
      "zip": "57072",
      "city": "Siegen",
      "street": "Koblenzer Str. 87-93",
      "id": "631c1d8e5cffe0503a43f1b78736e658"
    },
    {
      "zip": "57076",
      "city": "Siegen",
      "street": "Bismarckstr. 15",
      "id": "012095bd9eeaedb53c4e80a503c14702"
    },
    {
      "zip": "57080",
      "city": "Siegen",
      "street": "Am Anger 4",
      "id": "b4d48d17d1fecab5d2151df9c43e1528"
    },
    {
      "zip": "57250",
      "city": "Netphen",
      "street": "Untere Industriestr. 20",
      "id": "f9245b40a024d115436bdc0e737c7986"
    },
    {
      "zip": "57368",
      "city": "Lennestadt",
      "street": "Bahnhofplatz 3-5",
      "id": "bc7b7b2612e5ecdc4e8dabe90dea1312"
    },
    {
      "zip": "57368",
      "city": "Lennestadt",
      "street": "Bahnhofplatz 4",
      "id": "c39480cbe2d3e59f14a9090f6b7fa2e8"
    },
    {
      "zip": "57462",
      "city": "Olpe",
      "street": "Falkenweg 19",
      "id": "0563aabb32243c67e528ab6999c2d0ed"
    },
    {
      "zip": "57610",
      "city": "Altenkirchen",
      "street": "Bahnhofstr. 6",
      "id": "a4a065a3efce9f95d188a01731269aa4"
    },
    {
      "zip": "57610",
      "city": "Altenkirchen",
      "street": "Philipp-Reis-Str. 1",
      "id": "47a58a9f54f710d683df43452bda99cf"
    },
    {
      "zip": "58095",
      "city": "Hagen",
      "street": "Elberfelder Str. 35",
      "id": "9731303dbdc9b5d998cb01f663930768"
    },
    {
      "zip": "58095",
      "city": "Hagen",
      "street": "Friedrich-Ebert-Platz 3",
      "id": "d4e81cd659768e9a6842f5af1da75a9a"
    },
    {
      "zip": "58095",
      "city": "Hagen",
      "street": "Neumarktstr. 17",
      "id": "275e49428c218b0836246443e16b9a28"
    },
    {
      "zip": "58452",
      "city": "Witten",
      "street": "Bahnhofstr. 28",
      "id": "cda08f7059a92a116879684410fbbb37"
    },
    {
      "zip": "58507",
      "city": "Lüdenscheid",
      "street": "Bahnhofstr. 1-7",
      "id": "574f908efc4c6590442ef66733bb4d02"
    },
    {
      "zip": "58511",
      "city": "Lüdenscheid",
      "street": "Wilhelmstr. 26",
      "id": "627dd32b71b92a2a32b88008d03f421a"
    },
    {
      "zip": "58515",
      "city": "Lüdenscheid",
      "street": "Kalver Str. 22-22A",
      "id": "7d2bba170e604f056dc7d301c2d8d35f"
    },
    {
      "zip": "58636",
      "city": "Iserlohn",
      "street": "Dürerstr. 53",
      "id": "c0c29580e62b9eece81560434b75a8f8"
    },
    {
      "zip": "58636",
      "city": "Iserlohn",
      "street": "Wermingser Str. 6",
      "id": "bf48f5437a15c668d6db101f2306d770"
    },
    {
      "zip": "58636",
      "city": "Iserlohn",
      "street": "Wermingser Str. 7",
      "id": "b50e05a976784b52306d9c2f89eff89b"
    },
    {
      "zip": "58675",
      "city": "Hemer",
      "street": "Feldstr. 3",
      "id": "441af68fe587a48f073e7dfdfdaae6aa"
    },
    {
      "zip": "58706",
      "city": "Menden",
      "street": "Hauptstr. 39",
      "id": "fe0319857dec5fc2b3983f79e8c2cf2c"
    },
    {
      "zip": "59063",
      "city": "Hamm",
      "street": "Tom-Mutters-Weg 3",
      "id": "19abd716f06aee8bb0071cfc0751797a"
    },
    {
      "zip": "59065",
      "city": "Hamm",
      "street": "Weststr. 52",
      "id": "6c2a4e28f957ab68d70160484bb19159"
    },
    {
      "zip": "59227",
      "city": "Ahlen",
      "street": "Zeppelinstr. 14",
      "id": "6c01fe49a39010effc1a786ec16fb743"
    },
    {
      "zip": "59269",
      "city": "Beckum",
      "street": "Poststr. 6-8",
      "id": "d0bce744ba91474b23e6f636eb131634"
    },
    {
      "zip": "59348",
      "city": "Lüdinghausen",
      "street": "Graf-Wedel-Str. 8",
      "id": "0d9e9a17369668047de2c05fb0407257"
    },
    {
      "zip": "59387",
      "city": "Ascheberg-Herbern",
      "street": "Rankenstr. 32",
      "id": "315beb4ccc30f3ec62e6c513070af840"
    },
    {
      "zip": "59423",
      "city": "Unna",
      "street": "Bahnhofstr. 20",
      "id": "41dbd626d284ccab9222d877a4d368f6"
    },
    {
      "zip": "59425",
      "city": "Unna",
      "street": "Kantstr. 17",
      "id": "519b55e8ff1583dec2153c0cd660e417"
    },
    {
      "zip": "59494",
      "city": "Soest",
      "street": "Brüderstr. 33",
      "id": "9668e583c07a8789dde442a971be2ad2"
    },
    {
      "zip": "59494",
      "city": "Soest",
      "street": "Windmühlenweg 27A",
      "id": "b24cb647109a5aad1d840b427a2d35f2"
    },
    {
      "zip": "59555",
      "city": "Lippstadt",
      "street": "Lange Str. 40",
      "id": "0fc4265315106506b5fb21925ed1785f"
    },
    {
      "zip": "59557",
      "city": "Lippstadt",
      "street": "Heinrich-Hertz-Str. 1",
      "id": "104a701f26d7878bbd1c4b5a77777db5"
    },
    {
      "zip": "59755",
      "city": "Arnsberg",
      "street": "Hauptstr. 25",
      "id": "6730d9d772cd1361ad692652b7d5fc7e"
    },
    {
      "zip": "59872",
      "city": "Meschede",
      "street": "Feldstr. 34",
      "id": "d81786a243b7b1b0e3c1c9d98f46b776"
    },
    {
      "zip": "59872",
      "city": "Meschede",
      "street": "Winziger Platz 12",
      "id": "61efb4d2a69ccf09a6ea9474dd4afca9"
    },
    {
      "zip": "59929",
      "city": "Brilon",
      "street": "Bahnhofstr. 21",
      "id": "818da58749f19c7de13954493944736f"
    },
    {
      "zip": "59929",
      "city": "Brilon",
      "street": "Scharfenberger Str. 17",
      "id": "83cf38a8594d9714b78e6efee5ec3889"
    },
    {
      "zip": "60313",
      "city": "Frankfurt",
      "street": "Stiftstr. 29",
      "id": "07fdd931582dd63212bb27dd7c5d595d"
    },
    {
      "zip": "60313",
      "city": "Frankfurt",
      "street": "Stiftstr. 31",
      "id": "6c26682bf1f7167b7bf495294bd6c197"
    },
    {
      "zip": "60313",
      "city": "Frankfurt",
      "street": "Zeil 106-110",
      "id": "aa69a18064cc77081be0f083b5519588"
    },
    {
      "zip": "60314",
      "city": "Frankfurt",
      "street": "Danziger Platz 12",
      "id": "6a989b1a64d7ca73c253cefb039c7b7b"
    },
    {
      "zip": "60314",
      "city": "Frankfurt",
      "street": "Hanauer Landstr. 298",
      "id": "ce7bbb8549e8c4620d8047beb8f838ad"
    },
    {
      "zip": "60327",
      "city": "Frankfurt",
      "street": "Europa Allee 6",
      "id": "ea7a49a8d71d28c2bfb7092a3f5959ae"
    },
    {
      "zip": "60327",
      "city": "Frankfurt",
      "street": "Messegelände Torhau S",
      "id": "091897830a1a5e0a035557b2de72d904"
    },
    {
      "zip": "60388",
      "city": "Frankfurt",
      "street": "Borsigallee 26",
      "id": "f1579ee87bab5e025999aa66360ae0ae"
    },
    {
      "zip": "60388",
      "city": "Frankfurt",
      "street": "Kruppstr. 105-126",
      "id": "346b600cdd9da3d484f7a8904bfe6cb2"
    },
    {
      "zip": "60431",
      "city": "Frankfurt",
      "street": "Ginnheimer Stadtweg 88",
      "id": "00a3d4a4c3c52da6157acc4371d3d388"
    },
    {
      "zip": "60431",
      "city": "Frankfurt",
      "street": "Ginnheimer Stadtweg 90",
      "id": "d4c5f85fe57cf4b237b62c44ed7bb8ba"
    },
    {
      "zip": "60431",
      "city": "Frankfurt",
      "street": "Raimundstr. 48-54",
      "id": "e67feb1f5d0574e43f36194467fdffca"
    },
    {
      "zip": "60431",
      "city": "Frankfurt/main",
      "street": "Raimundstraße 48-54",
      "id": "2c0b9e7b30fdede42811938ed9f9e343"
    },
    {
      "zip": "60439",
      "city": "Frankfurt",
      "street": "Nidacorso 8",
      "id": "40eacdf17fcaa669222dfba8f1463ded"
    },
    {
      "zip": "60487",
      "city": "Frankfurt",
      "street": "Leipziger Str. 25",
      "id": "71be429ead1110bbb6d626140d226017"
    },
    {
      "zip": "60489",
      "city": "Frankfurt",
      "street": "Eschborner Landstr. 100",
      "id": "0771116fe5958d88f82c68cf8aabf969"
    },
    {
      "zip": "60528",
      "city": "Frankfurt",
      "street": "Hahnstr. 43",
      "id": "d442d3733956418889e63f6db6eaf05d"
    },
    {
      "zip": "60528",
      "city": "Frankfurt",
      "street": "Hahnstr. 43A",
      "id": "99a5fb6f0837784fb3ac3db809237a34"
    },
    {
      "zip": "60528",
      "city": "Frankfurt",
      "street": "Hahnstr. 43C",
      "id": "833b630e8dbbe25eab6689b826c64245"
    },
    {
      "zip": "60528",
      "city": "Frankfurt",
      "street": "Hahnstr. 43D",
      "id": "d92dde8e326c3d5db8b1e76bcd901314"
    },
    {
      "zip": "60528",
      "city": "Frankfurt/main",
      "street": "Hahnstr. 43",
      "id": "c26c942039b8d2127b2ab48c0bcea8ab"
    },
    {
      "zip": "60549",
      "city": "Frankfurt",
      "street": "Hahnstr. 43",
      "id": "a12f6f84fa6518bc44f1a7cfde9b30d0"
    },
    {
      "zip": "60594",
      "city": "Frankfurt",
      "street": "Schweizer Str. 40",
      "id": "f6bced5eb5004871826bffa0eda7be2e"
    },
    {
      "zip": "61118",
      "city": "Bad Vilbel",
      "street": "Marktplatz 4",
      "id": "8fc5b5a16531e330d15d1c4554504824"
    },
    {
      "zip": "61169",
      "city": "Friedberg",
      "street": "Kaiserstr. 91",
      "id": "0e91d8d63f1227bef34376af7d7cb1c4"
    },
    {
      "zip": "61169",
      "city": "Friedberg",
      "street": "Saarstr. 16-18",
      "id": "ccfc446ccd53d441d81960fade3718c8"
    },
    {
      "zip": "61250",
      "city": "Usingen",
      "street": "Albert-Franke-Str. 6-8",
      "id": "9d1908335cf7c27ab106d30ffaa6974f"
    },
    {
      "zip": "61348",
      "city": "Bad Homburg",
      "street": "Louisenstr. 92",
      "id": "f77df87136934a736e2c11d055a4dfc6"
    },
    {
      "zip": "61352",
      "city": "Bad Homburg",
      "street": "Erlenweg 2",
      "id": "b5ca0908d52e92dd36fdb0ec90a1be86"
    },
    {
      "zip": "61440",
      "city": "Oberursel",
      "street": "Gattenhöferweg 41",
      "id": "45e69f6d15d140835cf0aea814c26c97"
    },
    {
      "zip": "63065",
      "city": "Offenbach",
      "street": "Frankfurter Str. 13",
      "id": "f2ee5874414cd326d869381f9d97a81c"
    },
    {
      "zip": "63150",
      "city": "Heusenstamm",
      "street": "Jahnstr. 54-64",
      "id": "08642fd58caca670c6380a0412b7d98f"
    },
    {
      "zip": "63263",
      "city": "Neu-Isenburg",
      "street": "Hermesstr. 4",
      "id": "72a7fd073f459c08f89927c557de012e"
    },
    {
      "zip": "63450",
      "city": "Hanau",
      "street": "Langstr. 62",
      "id": "5a3d55176a8583c0bc7ee94a6dd53d6f"
    },
    {
      "zip": "63450",
      "city": "Hanau",
      "street": "Nürnberger Str. 26",
      "id": "74cc9f950ebd51080c86bf80bc64fcbc"
    },
    {
      "zip": "63452",
      "city": "Hanau",
      "street": "Alter Rückinger Weg 55",
      "id": "f77a95374d7f1c326b232ad439bb138c"
    },
    {
      "zip": "63452",
      "city": "Hanau",
      "street": "Elsa-Brandström-Str. 65",
      "id": "530a0c8f42f2f4c554b8fd9ee2d68d2e"
    },
    {
      "zip": "63571",
      "city": "Gelnhausen",
      "street": "Frankfurter Str. 55",
      "id": "002e7818f4609a03545866087076c21c"
    },
    {
      "zip": "63571",
      "city": "Gelnhausen",
      "street": "Im Ziegelhaus 13",
      "id": "ee29a8325cb05c13eb7073414d424fea"
    },
    {
      "zip": "63579",
      "city": "Freigericht",
      "street": "Rathausstr. 13",
      "id": "40f86c08c007fbd3e458575417d44110"
    },
    {
      "zip": "63607",
      "city": "Wächtersbach",
      "street": "Poststr. 20-22",
      "id": "3cc6fd00bcac49808b128af52ff9c8bd"
    },
    {
      "zip": "63695",
      "city": "Glauburg",
      "street": "Bahnhofstr. 62",
      "id": "22d0243502a8d57690cc95e04fd80a5f"
    },
    {
      "zip": "63739",
      "city": "Aschaffenburg",
      "street": "Herstallstr. 30",
      "id": "40d02705f3b7a385874ff86392287693"
    },
    {
      "zip": "63739",
      "city": "Aschaffenburg",
      "street": "Hofgartenstr. 16",
      "id": "58fd50949a5279583615ff7ccfb4c076"
    },
    {
      "zip": "63739",
      "city": "Aschaffenburg",
      "street": "Würzburger Str. 62-68",
      "id": "0dd44328df4654e58b1f8f598469ae47"
    },
    {
      "zip": "63741",
      "city": "Aschaffenburg",
      "street": "Weichertstr. 12",
      "id": "a61439f83f1f0ce528dc486fbd583fe2"
    },
    {
      "zip": "63897",
      "city": "Miltenberg",
      "street": "Fährweg 37",
      "id": "7a42af21c58753116439fd4ea1ae2487"
    },
    {
      "zip": "64283",
      "city": "Darmstadt",
      "street": "Eschollbrücker Str. 12",
      "id": "2b34ad2241a729d9efb40dd469cb639d"
    },
    {
      "zip": "64283",
      "city": "Darmstadt",
      "street": "Luisenplatz 3",
      "id": "8c626b8370979431c3c445bd0615143b"
    },
    {
      "zip": "64285",
      "city": "Darmstadt",
      "street": "Artilleriestr. 9",
      "id": "090e0d860f06449988d73b3fb3570fed"
    },
    {
      "zip": "64293",
      "city": "Darmstadt",
      "street": "Gräfenhäuser Str. 85B",
      "id": "cf74613e68c7ba7fe107be4a59c18d69"
    },
    {
      "zip": "64293",
      "city": "Darmstadt",
      "street": "Otto-Röhm-Str. 71C",
      "id": "e875d753566294af638e0aa3c55b495d"
    },
    {
      "zip": "64293",
      "city": "Darmstadt",
      "street": "Pallaswiesenstr. 174-203",
      "id": "daefa13d24ed8e79b1d035178d01506d"
    },
    {
      "zip": "64293",
      "city": "Darmstadt",
      "street": "Pallaswiesenstr. 176",
      "id": "81aab568d7396ff8ad0bdf3106efc732"
    },
    {
      "zip": "64293",
      "city": "Darmstadt",
      "street": "Pallaswiesenstr. 178",
      "id": "d65f1e4d2e0a6e669d65eaa046933c34"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Deutsche-Telekom-Allee 1",
      "id": "e2630dc7e99f94d93f618b04146048ea"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Deutsche-Telekom-Allee 1-7",
      "id": "3860e2c5fc78076e26f7751c5e285578"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Deutsche-Telekom-Allee 5",
      "id": "5419fccc18a92352d7ff61eada44b81b"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Deutsche-Telekom-Allee 7",
      "id": "aa9345027d9e648a2d8288ea20512daf"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Deutsche-Telekom-Allee 9",
      "id": "e898dfae413d731232186b5f555fc755"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Heinrich-Hertz-Str. 1",
      "id": "64f454cfe9cc7b3593b54f0b0257cc7a"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Heinrich-Hertz-Str. 3-7",
      "id": "a364193b68bf2e36aabd9119e3ffb966"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Hilpertstr. 27",
      "id": "29d8cc33c5e288e81ce2e9a5d84c6cc6"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Ida-Rhodes-Str. 2",
      "id": "9c6319759cf32409a5dd3f2b52ec0328"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Mina-Rees-Str. 1-15",
      "id": "002ac1e5235b05c6adefd90ae222e806"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Mina-Rees-Str. 10",
      "id": "85938730d4f8bfb0dc7817eb4397ec17"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Mina-Rees-Str. 12",
      "id": "0ded462b1b27ac9a8740e7d07b598151"
    },
    {
      "zip": "64295",
      "city": "Darmstadt",
      "street": "Mina-Rees-Str. 6",
      "id": "959c26dee2e3d9ebebb20e4f5a00fc76"
    },
    {
      "zip": "64342",
      "city": "Seeheim-Jugenheim",
      "street": "Sandstr. 2",
      "id": "5c23464aa6fdda34d9641bbf3be6dad6"
    },
    {
      "zip": "64372",
      "city": "Ober-Ramstadt",
      "street": "Neuweg 23",
      "id": "b71bfe1d18fe5d3c68af1c93d83d42d1"
    },
    {
      "zip": "64560",
      "city": "Riedstadt-Goddelau",
      "street": "Freiherr-Von-Stein-Str. 3",
      "id": "19559fc22ca78e626f5d4431f4073756"
    },
    {
      "zip": "64584",
      "city": "Biebesheim",
      "street": "Wilhelm Leuschner Str. 4",
      "id": "18b85d8669637426ff87e051dcd90b0a"
    },
    {
      "zip": "64625",
      "city": "Bensheim",
      "street": "Fehlheimer Str. 86",
      "id": "4e9286201118a75c5f801acdca58f178"
    },
    {
      "zip": "64625",
      "city": "Bensheim",
      "street": "Hauptstr. 19",
      "id": "d934981565e5331aa4a89503e0603fbd"
    },
    {
      "zip": "64646",
      "city": "Heppenheim",
      "street": "Am Steinern Weg 5",
      "id": "91b6e4f1ad1d5becb11169d6d77d3f94"
    },
    {
      "zip": "64658",
      "city": "Fürth",
      "street": "Schulstr. 3",
      "id": "090dbbe5ae38e46641fc7289caa907a3"
    },
    {
      "zip": "64720",
      "city": "Michelstadt",
      "street": "Rudolf-Marburg-Str. 40",
      "id": "0ef5582cfe52a8d85da592b7a33e66e2"
    },
    {
      "zip": "64807",
      "city": "Dieburg",
      "street": "Max-Planck-Str. 19",
      "id": "5b459a53f211d96397aed81f56a7f013"
    },
    {
      "zip": "64807",
      "city": "Dieburg",
      "street": "Max-Planck-Str. 25",
      "id": "d69c36f73e7a58d47d0074c1f5fa2c0e"
    },
    {
      "zip": "64832",
      "city": "Babenhausen",
      "street": "Bürgermeister-Willand-Str. 2",
      "id": "812331304a1ddf0bc50427e4a78f84cf"
    },
    {
      "zip": "65185",
      "city": "Wiesbaden",
      "street": "Kirchgasse 27",
      "id": "3e835db16ad6ff5d975372dcfee850fe"
    },
    {
      "zip": "65185",
      "city": "Wiesbaden",
      "street": "Kirchgasse 81",
      "id": "cad34e0c41569dd89e56ad96fac5f055"
    },
    {
      "zip": "65187",
      "city": "Wiesbaden",
      "street": "Dostojewskistr. 15",
      "id": "c9cfbc597a1eb516f5f483319e2039d9"
    },
    {
      "zip": "65187",
      "city": "Wiesbaden",
      "street": "Konrad-Adenauer-Ring 33",
      "id": "41391392395e8e79aa6cac39fd9a319c"
    },
    {
      "zip": "65189",
      "city": "Wiesbaden",
      "street": "Bahnhofsplatz 3",
      "id": "83f8c5744fd08cee0f8dd7050aabd665"
    },
    {
      "zip": "65195",
      "city": "Wiesbaden",
      "street": "Carl-Von-Ibell-Weg 6",
      "id": "35773fef1d0b57d3582c8a230b440a21"
    },
    {
      "zip": "65232",
      "city": "Taunusstein",
      "street": "Hohlweg 11",
      "id": "5a7390bf233514a7495497cd76a49520"
    },
    {
      "zip": "65343",
      "city": "Eltville",
      "street": "Rheingauer Str. 50-52",
      "id": "24e3ee087a03ff0131e1919098929e75"
    },
    {
      "zip": "65396",
      "city": "Walluf",
      "street": "Hohlweg 7",
      "id": "c8eb02852f400848582eb06567f972fb"
    },
    {
      "zip": "65428",
      "city": "Rüsselsheim",
      "street": "Kölner Str. 4",
      "id": "8ae3b00ba8f69867abb8c15f06ad0fa1"
    },
    {
      "zip": "65428",
      "city": "Rüsselsheim",
      "street": "Silberstr. 23",
      "id": "0f60c544637d1afa5d3a722dfa556d9a"
    },
    {
      "zip": "65510",
      "city": "Idstein",
      "street": "Wagenerstr. 8",
      "id": "387ca0b8bdf153f1f2a92b5512ad20e5"
    },
    {
      "zip": "65549",
      "city": "Limburg",
      "street": "Bahnhofstr. 6",
      "id": "333305e57a56976a466510d5e5a03ad3"
    },
    {
      "zip": "65549",
      "city": "Limburg",
      "street": "Ste.-Foy-Str. 35-39",
      "id": "1013da65ae1e1c0747530db5024cb0c4"
    },
    {
      "zip": "65760",
      "city": "Eschborn",
      "street": "Alfred-Herrhausen-Allee 7",
      "id": "9ebe27910dccec49f70458b1cf83b856"
    },
    {
      "zip": "65779",
      "city": "Kelkheim",
      "street": "Lessingstr. 28",
      "id": "4846aee4fda3a0b5f9725d2a11048504"
    },
    {
      "zip": "65843",
      "city": "Sulzbach",
      "street": "Main-Taunus-Zentrum 1",
      "id": "916bc57047a08d3980e5db60781df360"
    },
    {
      "zip": "65934",
      "city": "Frankfurt",
      "street": "Oeserstr. 111",
      "id": "9d83b5014b6a0516a2f768fc85c5ddf1"
    },
    {
      "zip": "65936",
      "city": "Frankfurt",
      "street": "Wilhelm-Fay-Str. 54",
      "id": "962f7febc2ce4040d2f964f4952a633c"
    },
    {
      "zip": "66111",
      "city": "Saarbrücken",
      "street": "Bahnhofstr. 66",
      "id": "fb7c5c26cf323d30eb1d22c368bb909e"
    },
    {
      "zip": "66115",
      "city": "Saarbrücken",
      "street": "Klausenerstr. 4-6",
      "id": "5e37a22f4b613db3d34639976983847d"
    },
    {
      "zip": "66121",
      "city": "Saarbrücken",
      "street": "Mecklenburgring 25",
      "id": "efeafb1f81663da9fabd68bc588998eb"
    },
    {
      "zip": "66280",
      "city": "Sulzbach/saar",
      "street": "Sulzbachtalstr. 49",
      "id": "a6cd86f32420f374341aeeea8cc0601e"
    },
    {
      "zip": "66424",
      "city": "Homburg",
      "street": "Eisenbahnstr. 21",
      "id": "4b7cbf27a1033579cc9331be482490aa"
    },
    {
      "zip": "66538",
      "city": "Neunkirchen",
      "street": "Stummplatz 2",
      "id": "1461591618d35892ef1dd98d8877ce4e"
    },
    {
      "zip": "66538",
      "city": "Neunkirchen",
      "street": "Wellesweilerstr. 100",
      "id": "b7947d846c94a56bb7e4bc803384c092"
    },
    {
      "zip": "66606",
      "city": "St. Wendel",
      "street": "Tholeyer Str. 30",
      "id": "da2cf48b3b4d953ad2d2f59c88e28385"
    },
    {
      "zip": "66606",
      "city": "St. Wendel",
      "street": "Tholeyer Straße 30",
      "id": "1ed1f099065466b08138187103d02b2d"
    },
    {
      "zip": "66663",
      "city": "Merzig",
      "street": "Poststr. 18",
      "id": "851ec8a00c71d02df028f2d47274a7a4"
    },
    {
      "zip": "66663",
      "city": "Merzig",
      "street": "Rieffstr. 2-4",
      "id": "05ac44858d0ed09c94e0db6d5e86264f"
    },
    {
      "zip": "66740",
      "city": "Saarlouis",
      "street": "Choisyring 3",
      "id": "57f69e0aefdd9394b155a6ac4867734e"
    },
    {
      "zip": "66740",
      "city": "Saarlouis",
      "street": "Französische Str. 26",
      "id": "741751f2e56ed5b1cc5810142e8b4d15"
    },
    {
      "zip": "66822",
      "city": "Lebach",
      "street": "Poststr. 20",
      "id": "9b7c27313c924c72f0618e27fa4e4353"
    },
    {
      "zip": "66849",
      "city": "Landstuhl",
      "street": "Kaiserstr. 135",
      "id": "228973beb4815dfb918322208551f5ec"
    },
    {
      "zip": "66953",
      "city": "Pirmasens",
      "street": "Hauptstr. 9",
      "id": "535d39e8e721424d20f144eb4591bec4"
    },
    {
      "zip": "66954",
      "city": "Pirmasens",
      "street": "Emil-Kömmerling-Str. 41-43",
      "id": "8fa8b8ca2ae97d19abdbd3c9d65df1da"
    },
    {
      "zip": "67059",
      "city": "Ludwigshafen",
      "street": "Bismarkstr. 108-110",
      "id": "afe74d8947804071ac1499c0a6e0d846"
    },
    {
      "zip": "67059",
      "city": "Ludwigshafen",
      "street": "Maxstr. 82",
      "id": "f6c1382ffdd83b5e9d31f71e12588b02"
    },
    {
      "zip": "67059",
      "city": "Ludwigshafen",
      "street": "Schulstr. 6",
      "id": "ce9f736b40d6eaad6bbc310873f9b128"
    },
    {
      "zip": "67061",
      "city": "Ludwigshafen",
      "street": "Rheinallee 11",
      "id": "d015f64321161cd5fd1563bf9a54fcd7"
    },
    {
      "zip": "67061",
      "city": "Ludwigshafen",
      "street": "Zollhofstr. 4",
      "id": "49c8b533e13e92ce733943641b5367dd"
    },
    {
      "zip": "67071",
      "city": "Ludwigshafen",
      "street": "Hedwig Laudien Ring 3",
      "id": "c9b8bb7b413a98d614f126dbdcc48aac"
    },
    {
      "zip": "67227",
      "city": "Frankenthal",
      "street": "Speyerer Str. 12",
      "id": "5a5e0a1b39cba8b32366f5a1a30a69a3"
    },
    {
      "zip": "67269",
      "city": "Grünstadt",
      "street": "Friedrich-Ebert-Str. 6",
      "id": "657ad81e3c9b72be8a2fd2c04d12de6e"
    },
    {
      "zip": "67346",
      "city": "Speyer",
      "street": "Draisstr. 10",
      "id": "cba12799593d8369626168f0134de25e"
    },
    {
      "zip": "67346",
      "city": "Speyer",
      "street": "Wormser Landstr. 192",
      "id": "ee9ec45b10612cdcdf7a5719f863ab6b"
    },
    {
      "zip": "67433",
      "city": "Neustadt",
      "street": "Chemnitzer Str. 2",
      "id": "edf8b7f6c84cbba5bcd4724ebf9d8332"
    },
    {
      "zip": "67433",
      "city": "Neustadt",
      "street": "Hauptstr. 51",
      "id": "838023cf289ab2e7133f2794a2e4cdb0"
    },
    {
      "zip": "67547",
      "city": "Worms",
      "street": "Kämmerer Str. 19",
      "id": "aed0e34f2653633a33e5f3676b0517ec"
    },
    {
      "zip": "67547",
      "city": "Worms",
      "street": "Mainzer Str. 54",
      "id": "f794f6788a2518f5f541ccc450fe2911"
    },
    {
      "zip": "67655",
      "city": "Kaiserslautern",
      "street": "Fackelrondell 1",
      "id": "05a20f3d8354afa08ac7d6863e30712d"
    },
    {
      "zip": "67655",
      "city": "Kaiserslautern",
      "street": "Marktstr. 52",
      "id": "44d62cd483c47b7270800f6261431b41"
    },
    {
      "zip": "67655",
      "city": "Kaiserslautern",
      "street": "Pirmasenser Str. 65",
      "id": "c1193b82f6ce3c7fb9becce323e17ac2"
    },
    {
      "zip": "67655",
      "city": "Kaiserslautern",
      "street": "Rummelstr. 11",
      "id": "58e0692228c757dbf5dd91b77aa65686"
    },
    {
      "zip": "67655",
      "city": "Kaiserslautern",
      "street": "Rummelstr. 9-11",
      "id": "f863796db323167ca6fcb5a06014d30f"
    },
    {
      "zip": "67663",
      "city": "Kaiserslautern",
      "street": "Merkurstr. 25",
      "id": "12dda7a8aa74bd3ee0f51e418c5569e6"
    },
    {
      "zip": "68161",
      "city": "Mannheim",
      "street": "P 411-12",
      "id": "7e83691c38f5cad986e17e32cf618bf3"
    },
    {
      "zip": "68163",
      "city": "Mannheim",
      "street": "Seckenheimer Landstr. 210-220",
      "id": "3c9d1f23f2c29bd2bda352906108be04"
    },
    {
      "zip": "68165",
      "city": "Mannheim",
      "street": "Dynamostr. 5",
      "id": "3cb90e025ad02d4f46382de005db29cd"
    },
    {
      "zip": "68165",
      "city": "Mannheim",
      "street": "Erzbergerstr. 18",
      "id": "29533540c35853a7d87708c75e305898"
    },
    {
      "zip": "68165",
      "city": "Mannheim",
      "street": "Karl-Ludwig-Str. 36",
      "id": "63617ef62ff89f8a9b4cf6edfbe4f0cd"
    },
    {
      "zip": "68199",
      "city": "Mannheim",
      "street": "Salzachstr. 4-8",
      "id": "970ab85b1230599f1a445afdbfbeaa77"
    },
    {
      "zip": "68519",
      "city": "Viernheim",
      "street": "Ketteler Str. 6A",
      "id": "c426efeedb5c06a24e7bf1d1a85bd2fc"
    },
    {
      "zip": "68519",
      "city": "Viernheim",
      "street": "Robert-Schumann-Str. 1",
      "id": "bed394581c7241f8a2551e82352507e7"
    },
    {
      "zip": "68526",
      "city": "Ladenburg",
      "street": "Boveristr. 24",
      "id": "ae924f46654ca487d8d27ef4567d20c7"
    },
    {
      "zip": "68623",
      "city": "Lampertheim",
      "street": "Bürstädter Str. 22",
      "id": "e14498cd456c13eae5a38bd8ea26e5e8"
    },
    {
      "zip": "68647",
      "city": "Biblis",
      "street": "Erlenstr. 12-14",
      "id": "224811c9cd8816e305913a09bb613bef"
    },
    {
      "zip": "68766",
      "city": "Hockenheim",
      "street": "Wilhelm-Leuschner-Str. 1",
      "id": "3537eb9ccf61c44462f7c65f48321434"
    },
    {
      "zip": "69115",
      "city": "Heidelberg",
      "street": "Eppelheimer Str. 13-15",
      "id": "b817c3096d66e20aa1c50e3fc92b59ac"
    },
    {
      "zip": "69117",
      "city": "Heidelberg",
      "street": "Hauptstr. 55",
      "id": "82e378a8bb1c35cd1d41c25a7af5df60"
    },
    {
      "zip": "69120",
      "city": "Heidelberg",
      "street": "Erwin-Rohde-Str. 18",
      "id": "b4d1a1619fcd47538c275352d838c659"
    },
    {
      "zip": "69126",
      "city": "Heidelberg",
      "street": "Hertzstr. 12",
      "id": "8bc5ff3dfefb6e117e166413fb863d66"
    },
    {
      "zip": "69168",
      "city": "Wiesloch",
      "street": "Hauptstr. 142",
      "id": "0a198c41b8135d861c3009d106f4b511"
    },
    {
      "zip": "69190",
      "city": "Walldorf",
      "street": "Altrottstr. 31",
      "id": "f0f3ad411abc6cd1b71b4815364f3936"
    },
    {
      "zip": "69469",
      "city": "Weinheim",
      "street": "Weschnitzstr. 1",
      "id": "541e1fed4f91ef5e54e1c740d40f4e40"
    },
    {
      "zip": "69483",
      "city": "Wald-Michelbach",
      "street": "Poststr. 8",
      "id": "dad2bfc45a48d08b5801ffe437f28937"
    },
    {
      "zip": "70173",
      "city": "Stuttgart",
      "street": "Königstr. 34",
      "id": "2ea72f9468040646e3fa4f40e2daf508"
    },
    {
      "zip": "70182",
      "city": "Stuttgart",
      "street": "Blumenstr. 8-14",
      "id": "a3c8628ece3c23287f117c71741efaff"
    },
    {
      "zip": "70199",
      "city": "Stuttgart",
      "street": "Adlerstr. 32",
      "id": "3a41e5d5169748e1195ab2dbb8ea7dd0"
    },
    {
      "zip": "70327",
      "city": "Stuttgart",
      "street": "Heiligenwiesen 34",
      "id": "038c1636598eced04ab692e1e0915761"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Deckerstr. 35",
      "id": "a4e6a26df68a2e89561402d27f54665f"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Deckerstr. 41",
      "id": "7788ee98d414eb302fd3788d6aca11c6"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Marktstr. 39",
      "id": "d11bad8e74c94980a32f0b2e126a4f81"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Nauheimer Str. 101",
      "id": "0ca802d03307f5188cf196fa4d4d5432"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Nauheimer Str. 78-80",
      "id": "3bef3622c12430d7fa981f52bc3135ac"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Nauheimer Str. 98",
      "id": "8d18715a91d2806f82eba6577db35310"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Nauheimer Str. 98-101",
      "id": "a4e89d0a92467c7234fa24a8cb65fbd4"
    },
    {
      "zip": "70372",
      "city": "Stuttgart",
      "street": "Nauheimer Str. 99",
      "id": "96d45ffb9fe26cc87f71f7e46c193b4d"
    },
    {
      "zip": "70376",
      "city": "Stuttgart",
      "street": "Löwentorbogen 11",
      "id": "04256f7b69a1e109c7cad66fe7c0e0b3"
    },
    {
      "zip": "70376",
      "city": "Stuttgart",
      "street": "Löwentorstr. 48",
      "id": "2cab51a2fc76d49312b6ecdcf267c0c3"
    },
    {
      "zip": "70469",
      "city": "Stuttgart",
      "street": "Maybachstr. 57",
      "id": "d1cee1658927c2ab46b0aee87cbe4327"
    },
    {
      "zip": "70499",
      "city": "Stuttgart",
      "street": "Spechtweg 25",
      "id": "1f45c1899ad9702fb1cfa990ad80136b"
    },
    {
      "zip": "70563",
      "city": "Stuttgart",
      "street": "Curiestr. 5",
      "id": "e20a22a40ca488c2005ed544bec9fa5c"
    },
    {
      "zip": "70563",
      "city": "Stuttgart",
      "street": "Hauptstr. 23",
      "id": "463b5fc37009c3422cbff08b03edfe9a"
    },
    {
      "zip": "70565",
      "city": "Stuttgart",
      "street": "Schockenriedstr. 16",
      "id": "ec600ce263bf0271985629d14753d1e4"
    },
    {
      "zip": "70569",
      "city": "Stuttgart",
      "street": "Universitätsstr. 34",
      "id": "ed1a963ed8f8009e6a515afc236f203b"
    },
    {
      "zip": "70771",
      "city": "Leinfelden-Echterdingen",
      "street": "Fasanenweg 5",
      "id": "933cc60c3d4fedcf24b8c070cd3b7931"
    },
    {
      "zip": "71032",
      "city": "Böblingen",
      "street": "Schönbuchstr. 35",
      "id": "5681dc826e936d6734a0e51d69a1c5dc"
    },
    {
      "zip": "71034",
      "city": "Böblingen",
      "street": "Karlstr. 12",
      "id": "ea308027165d9e9b29830e78acb558fb"
    },
    {
      "zip": "71065",
      "city": "Sindelfingen",
      "street": "Tilsiter Str. 15",
      "id": "162bd4ae58c53637bb964b4ee20aab11"
    },
    {
      "zip": "71069",
      "city": "Sindelfingen",
      "street": "Almendweg 2/ 1",
      "id": "4e9ef6634c745a95d828b432b11521c6"
    },
    {
      "zip": "71083",
      "city": "Herrenberg",
      "street": "Seestr. 15",
      "id": "917cd3e33bc1676539352d9729321e02"
    },
    {
      "zip": "71229",
      "city": "Leonberg",
      "street": "Leonberger Str. 98-100",
      "id": "b6ca202daaeb71b6b54c6a80e81b62df"
    },
    {
      "zip": "71332",
      "city": "Waiblingen",
      "street": "Marktgasse 3",
      "id": "ad9ea1466d639445c19efaec126cd86e"
    },
    {
      "zip": "71404",
      "city": "Korb",
      "street": "Fritz-Klett-Str. 53",
      "id": "b964845ceb106b5e650b8dab7d9cb8f4"
    },
    {
      "zip": "71522",
      "city": "Backnang",
      "street": "Annonay-Str. 2",
      "id": "aeef961cc3f270cf05ebf533d9ca19d2"
    },
    {
      "zip": "71522",
      "city": "Backnang",
      "street": "Bahnhofstr. 12",
      "id": "5f73394859270a80d8ba8fb14a2b8a59"
    },
    {
      "zip": "71634",
      "city": "Ludwigsburg",
      "street": "Heinkelstr. 1-11",
      "id": "0ab4dee02cfc03c32f952bff78719794"
    },
    {
      "zip": "71634",
      "city": "Ludwigsburg",
      "street": "Kirchstr. 29",
      "id": "d1bfbb21047d91947c401c0f9aa14e0d"
    },
    {
      "zip": "71636",
      "city": "Ludwigsburg",
      "street": "Strombergstr. 25-29",
      "id": "910023b035214b9b7f5d0c29a0535028"
    },
    {
      "zip": "72070",
      "city": "Tübingen",
      "street": "Neckargasse 22",
      "id": "99280ba209ad14b91e33b4852a96a331"
    },
    {
      "zip": "72074",
      "city": "Tübingen",
      "street": "Wächterstr. 35",
      "id": "90bee392551c41be27add94c88702670"
    },
    {
      "zip": "72108",
      "city": "Rottenburg",
      "street": "Theodor Heuss Str. 32",
      "id": "eccfb74a52e7ab1cad76497320c4e88c"
    },
    {
      "zip": "72108",
      "city": "Rottenburg",
      "street": "Tübinger Str. 68",
      "id": "1cc4aeac66841e1b87bbe57a835b1545"
    },
    {
      "zip": "72160",
      "city": "Horb",
      "street": "Steigle 34",
      "id": "322d2c056fcddafafe1ea1494bf11647"
    },
    {
      "zip": "72202",
      "city": "Nagold",
      "street": "Haiterbacher Str. 66/ 1",
      "id": "7b25bb32e356e957f43407c9e786a86d"
    },
    {
      "zip": "72250",
      "city": "Freudenstadt",
      "street": "Ludwig-Jahn-Str. 19-21",
      "id": "f37a6dff5cf0b3bf764598fa5daf7556"
    },
    {
      "zip": "72250",
      "city": "Freudenstadt",
      "street": "Marktplatz 19",
      "id": "e0a4a0285b550f89da318f0c306779aa"
    },
    {
      "zip": "72336",
      "city": "Balingen",
      "street": "Friedrichstr. 19",
      "id": "6e955fb76dc178866649ab673fb6b7a6"
    },
    {
      "zip": "72336",
      "city": "Balingen",
      "street": "Hindenburgstr. 17",
      "id": "7b826f3a957efa5c1b78a87bc1b8876e"
    },
    {
      "zip": "72379",
      "city": "Hechingen",
      "street": "Ermelesstr. 12-14",
      "id": "121ec0ec3ee2b6c68d1224677e1b9a73"
    },
    {
      "zip": "72458",
      "city": "Albstadt",
      "street": "Neuer Weg 28",
      "id": "b28aaa92782e51ad9af640301c2f0eed"
    },
    {
      "zip": "72458",
      "city": "Albstadt",
      "street": "Sonnenstr. 35",
      "id": "1583a0b426a5c7fefbeebae72a838666"
    },
    {
      "zip": "72525",
      "city": "Münsingen",
      "street": "Wilhelmstr. 4-6",
      "id": "7697711ed4b7d54f528e0006f001b24d"
    },
    {
      "zip": "72622",
      "city": "Nürtingen",
      "street": "Apothekerstr. 10",
      "id": "e9b8c4674804af9b4f387da61e23c4b2"
    },
    {
      "zip": "72764",
      "city": "Reutlingen",
      "street": "Wilhelmstr. 5",
      "id": "683aec2b505fd2d8da80824833c21859"
    },
    {
      "zip": "72766",
      "city": "Reutlingen",
      "street": "Karlstr. 84",
      "id": "7e4aee0cf946d889cda1198d4ba546f8"
    },
    {
      "zip": "72770",
      "city": "Reutlingen",
      "street": "Markwiesenstr. 6",
      "id": "b713604b05bfb91955cba0269c73f0bb"
    },
    {
      "zip": "73033",
      "city": "Göppingen",
      "street": "Immanuel Hohlbauchstr. 20",
      "id": "35501527f56c0f3ed29936406358ca1a"
    },
    {
      "zip": "73033",
      "city": "Göppingen",
      "street": "Immanuel-Hohlbauch-Str. 20",
      "id": "b7771b6cd8fe4973ea46eb1a81bd13f6"
    },
    {
      "zip": "73033",
      "city": "Göppingen",
      "street": "Marktstr. 15",
      "id": "2d3d01eb015f10c4e584fe331bbe5276"
    },
    {
      "zip": "73035",
      "city": "Göppingen",
      "street": "Salamanderstr. 25-31",
      "id": "047fb513ad892d70077ff3c558f2d9ff"
    },
    {
      "zip": "73035",
      "city": "Göppingen",
      "street": "Salamanderstr. 27",
      "id": "12abf7b5bf295d1c755c2742dbefcb61"
    },
    {
      "zip": "73035",
      "city": "Göppingen",
      "street": "Salamanderstr. 31",
      "id": "27356945c277fd5eefc3567e90c5a087"
    },
    {
      "zip": "73230",
      "city": "Kirchheim",
      "street": "Faberweg 21",
      "id": "c4075cbe3cec3feac64b6fbf4e18acf1"
    },
    {
      "zip": "73230",
      "city": "Kirchheim",
      "street": "Max-Eyth-Str. 5",
      "id": "4d5ec7a0afd62b6708a5a3b6f9145b09"
    },
    {
      "zip": "73430",
      "city": "Aalen",
      "street": "Hopfenstr. 31",
      "id": "44efba7a451450ff886438695c6ae2b9"
    },
    {
      "zip": "73430",
      "city": "Aalen",
      "street": "Marktplatz 14",
      "id": "aacf6f42735a5f2b1ed1af59e6454083"
    },
    {
      "zip": "73525",
      "city": "Schwäbisch Gmünd",
      "street": "Bocksgasse 13",
      "id": "3eadc1f494f70c9fd650c6314221a9c9"
    },
    {
      "zip": "73525",
      "city": "Schwäbisch Gmünd",
      "street": "Robert-Von-Ostertag-Str. 4",
      "id": "fcade0195ed04a37acffe95e8f1dcade"
    },
    {
      "zip": "73728",
      "city": "Esslingen",
      "street": "Berliner Str. 2",
      "id": "f1279822b4e5a94379d868bd31cd0044"
    },
    {
      "zip": "73734",
      "city": "Esslingen",
      "street": "Hohenloherstr. 28",
      "id": "4280eb6f06b5a49f41ff430f1a67d2b7"
    },
    {
      "zip": "74072",
      "city": "Heilbronn",
      "street": "Am Wollhaus 2",
      "id": "e2cdae6d5d2335eef2ea120790d999b8"
    },
    {
      "zip": "74072",
      "city": "Heilbronn",
      "street": "Sülmerstr. 3",
      "id": "48f8ee94d5dd233722253c7d2afd99dd"
    },
    {
      "zip": "74074",
      "city": "Heilbronn",
      "street": "Knorrstr. 22",
      "id": "d6a29f364fd341f8e27e2c804edaa59e"
    },
    {
      "zip": "74074",
      "city": "Heilbronn",
      "street": "Rosenbergstr. 59",
      "id": "7d276d2bf93b503f9480671ccd078d5c"
    },
    {
      "zip": "74405",
      "city": "Gaildorf",
      "street": "Eugen-Heller-Str. 1",
      "id": "c084fa3903491622964e6fb19ea7a44c"
    },
    {
      "zip": "74523",
      "city": "Schwäbisch Hall",
      "street": "Dreimühlengasse 1",
      "id": "d2e02233b7f4f7d33bd7048f0a70156c"
    },
    {
      "zip": "74523",
      "city": "Schwäbisch Hall",
      "street": "In Den Herrenäckern 11",
      "id": "65a765a63cfa9d7c8ea1a9c39f8b92fe"
    },
    {
      "zip": "74564",
      "city": "Crailsheim",
      "street": "Gaildorfer Str. 58",
      "id": "1591d59b90296627495f7d335759e8bf"
    },
    {
      "zip": "74564",
      "city": "Crailsheim",
      "street": "Gartenstr. 25",
      "id": "f0ea19bf68ace1e6c5385413a0bf6085"
    },
    {
      "zip": "74613",
      "city": "öhringen",
      "street": "Austr. 5",
      "id": "6d6af14f21c6a896cfeed542c3832f94"
    },
    {
      "zip": "74613",
      "city": "öhringen",
      "street": "Pfedelbacher Str. 47",
      "id": "f42a750945a6351dad3a15b22ac23d3a"
    },
    {
      "zip": "74722",
      "city": "Buchen",
      "street": "Hettinger Str. 11",
      "id": "98c488200fb384d9dba775d684aea0ba"
    },
    {
      "zip": "74821",
      "city": "Mosbach",
      "street": "Eisenbahnstr. 26",
      "id": "719ff42e9c8f88295611517d1652abcb"
    },
    {
      "zip": "74821",
      "city": "Mosbach",
      "street": "Eisenbahnstr. 26-28",
      "id": "3a07259baa9fe1763979c6d21b6c312f"
    },
    {
      "zip": "74889",
      "city": "Sinsheim",
      "street": "Jahnstr. 7-9",
      "id": "ace7434e76d6d06eaf7d2ce5da740694"
    },
    {
      "zip": "75053",
      "city": "Gondelsheim",
      "street": "Industriestr. 7",
      "id": "e638581ad44345c4a7dcc19bab487cab"
    },
    {
      "zip": "75172",
      "city": "Pforzheim",
      "street": "Museumstr. 11",
      "id": "b5ce07008aadcd59890df6e0395e62ba"
    },
    {
      "zip": "75172",
      "city": "Pforzheim",
      "street": "Westl. Karl-Friedrich-Str. 2",
      "id": "df9f9767eccf372ea8111c0bc1caab7d"
    },
    {
      "zip": "75179",
      "city": "Pforzheim",
      "street": "Mannheimer Str. 2",
      "id": "72d1e0922f8f9247b9a2649e5df80bf1"
    },
    {
      "zip": "75365",
      "city": "Calw",
      "street": "Breite Heerstr. 7-11",
      "id": "e7e96a90df068b770561601337d436a6"
    },
    {
      "zip": "75417",
      "city": "Mühlacker",
      "street": "Bahnhofstr. 68",
      "id": "c84c639ced279a169b6bdf419cda50db"
    },
    {
      "zip": "76133",
      "city": "Karlsruhe",
      "street": "Ettlinger-Tor-Platz 1",
      "id": "ef8122453a7e106565a60649474762b8"
    },
    {
      "zip": "76133",
      "city": "Karlsruhe",
      "street": "Kaiserstr. 144",
      "id": "d9a466ddf8dedd96f745ced9d4ce7347"
    },
    {
      "zip": "76135",
      "city": "Karlsruhe",
      "street": "Schenkenburgstr. 2",
      "id": "43e70d5d961ad011fa3a069fa3ce9498"
    },
    {
      "zip": "76137",
      "city": "Karlsruhe",
      "street": "Philipp-Reis-Str. 2",
      "id": "d7a0c7be24a7df03bc3f50a2415e835b"
    },
    {
      "zip": "76139",
      "city": "Karlsruhe",
      "street": "Im Eichbäumle 1",
      "id": "4bf190decdbce7fa5c83a55027b8d6e0"
    },
    {
      "zip": "76185",
      "city": "Karlsruhe",
      "street": "Bannwaldallee 42",
      "id": "def1e602aa2ee75f163afe5c4d7bf862"
    },
    {
      "zip": "76185",
      "city": "Karlsruhe",
      "street": "Bannwaldallee 42 1",
      "id": "f006990eca918208dcddcc486c748042"
    },
    {
      "zip": "76532",
      "city": "Baden-Baden",
      "street": "Ooser Bahnhofstr. 6",
      "id": "2e224a9476a20ff180ac72949f1e6b06"
    },
    {
      "zip": "76547",
      "city": "Sinzheim",
      "street": "Industriestr. 28",
      "id": "3f6d02ebb5d7a2fb287de7cabb44abca"
    },
    {
      "zip": "76646",
      "city": "Bruchsal",
      "street": "Hildastr. 1",
      "id": "5e35d6f4c1ceea2ffbe70277d6fe35bb"
    },
    {
      "zip": "76646",
      "city": "Bruchsal",
      "street": "Kaiserstr. 36",
      "id": "82330e625730f99f485b641cced4797e"
    },
    {
      "zip": "76676",
      "city": "Graben-Neudorf",
      "street": "Schloßstr. 1",
      "id": "27ab76024c9528d68fbd0b2dfbfdb4f7"
    },
    {
      "zip": "76829",
      "city": "Landau",
      "street": "Gerberstr. 29",
      "id": "df3aa62e6076ed0a5cf4a094cfedc19b"
    },
    {
      "zip": "76829",
      "city": "Landau",
      "street": "Weißquartierstr. 15",
      "id": "9a05b86ab89e05b845c47f1e21b20f5e"
    },
    {
      "zip": "76887",
      "city": "Bad Bergzabern",
      "street": "Luitpoldstr. 53",
      "id": "b8cf59ea1e221206615d4276bf4eee7f"
    },
    {
      "zip": "77652",
      "city": "Offenburg",
      "street": "Okenstr. 25",
      "id": "e255e025b9a9684fac54478d61004dff"
    },
    {
      "zip": "77652",
      "city": "Offenburg",
      "street": "Okenstr. 25-27",
      "id": "b12afe50ec6cbee3424afb19de045218"
    },
    {
      "zip": "77652",
      "city": "Offenburg",
      "street": "Okenstr. 27",
      "id": "62dd1613a22c8f414279c68aa8e073f4"
    },
    {
      "zip": "77652",
      "city": "Offenburg",
      "street": "Steinstr. 10",
      "id": "b9d827f291ba744b9265fd4edd08e4c8"
    },
    {
      "zip": "77656",
      "city": "Offenburg",
      "street": "Heinrich Hertz Str. 1",
      "id": "8bb882a7213693ad17cd8bf5a60fbaab"
    },
    {
      "zip": "77855",
      "city": "Achern",
      "street": "Plaukelmatte 5",
      "id": "8a99618de19e473a69b193954d05d24a"
    },
    {
      "zip": "77933",
      "city": "Lahr",
      "street": "Lotzbeckstr. 22",
      "id": "bc882e5a3d6dd86294752639b76a8df3"
    },
    {
      "zip": "78050",
      "city": "Villingen-Schwenningen",
      "street": "Benediktinerring 6A",
      "id": "6944ca0a289a91772d6d299a5918146e"
    },
    {
      "zip": "78050",
      "city": "Villingen-Schwenningen",
      "street": "Rietstr. 2",
      "id": "b5b3134f38b489f4a9191eacb4c9213e"
    },
    {
      "zip": "78073",
      "city": "Bad Dürrheim",
      "street": "Schwenninger Str. 40",
      "id": "ccfbb2f2b135d3120f0798c2b53363fb"
    },
    {
      "zip": "78166",
      "city": "Donaueschingen",
      "street": "Adolf-Kolping-Str. 2-4",
      "id": "9b9fa9661f91269bfa01d2007c311d29"
    },
    {
      "zip": "78224",
      "city": "Singen",
      "street": "August-Ruf-Str. 12A",
      "id": "ff0ec813b549c6e904146c5c249dbe6e"
    },
    {
      "zip": "78224",
      "city": "Singen",
      "street": "Bahnhofstr. 16-18",
      "id": "5b3672eec2c09de84c7101237942e83c"
    },
    {
      "zip": "78462",
      "city": "Konstanz",
      "street": "Rosgartenstr. 26",
      "id": "244d668ff75a239865c823c18aac66e2"
    },
    {
      "zip": "78462",
      "city": "Konstanz",
      "street": "Schulthaißstr. 4",
      "id": "81ac46ebaf06d10fae119dd418dc7184"
    },
    {
      "zip": "78467",
      "city": "Konstanz",
      "street": "Moltkestr. 2-6",
      "id": "3ff71571f9ad8fb06bf899ac681daf76"
    },
    {
      "zip": "78467",
      "city": "Konstanz",
      "street": "Moltkestr. 6",
      "id": "4a9a4b8eb108864ff1a27a59df149fc1"
    },
    {
      "zip": "78467",
      "city": "Konstanz",
      "street": "Mühlenweg 9",
      "id": "188c8b52116cb03b88f49b884443e7bf"
    },
    {
      "zip": "78532",
      "city": "Tuttlingen",
      "street": "Bahnhofstr. 14",
      "id": "2934baa9bb905f449999b36fab65784f"
    },
    {
      "zip": "78628",
      "city": "Rottweil",
      "street": "Hauptstr. 26/ 28",
      "id": "c77ffd465d034368120e744522d9aad1"
    },
    {
      "zip": "78628",
      "city": "Rottweil",
      "street": "Steig 27",
      "id": "cc84fe5f9b37f6e6f0cfc439de0ccc91"
    },
    {
      "zip": "78713",
      "city": "Schramberg",
      "street": "Weihergasse 11",
      "id": "fdc7f7b0e2cf95242abf3e8ab9c70994"
    },
    {
      "zip": "79098",
      "city": "Freiburg",
      "street": "Friedrichstr. 52-54",
      "id": "f4a5b182f15d4ef7815865c835eaa0e8"
    },
    {
      "zip": "79098",
      "city": "Freiburg",
      "street": "Kaiser-Joseph-Str. 203",
      "id": "3dcde97bbce3e3069196ff9034aaae2e"
    },
    {
      "zip": "79110",
      "city": "Freiburg",
      "street": "Linnestraße 7",
      "id": "82dcf702b735ee65783c7959f713eb8e"
    },
    {
      "zip": "79110",
      "city": "Freiburg",
      "street": "Linnéstr. 7",
      "id": "c70ca46b10b74ac29b1dfc5115c77b0e"
    },
    {
      "zip": "79111",
      "city": "Freiburg",
      "street": "Munzinger Str. 1",
      "id": "65449fb31d5046b022349f121a871bc1"
    },
    {
      "zip": "79114",
      "city": "Freiburg",
      "street": "Berliner Allee 1",
      "id": "1a6f80caab5336a3e3f5e6c360bce959"
    },
    {
      "zip": "79312",
      "city": "Emmendingen",
      "street": "Karl-Friedrich-Str. 44",
      "id": "3a357ccc73d3a8a83a336c59fce9a567"
    },
    {
      "zip": "79379",
      "city": "Müllheim",
      "street": "Auf Der Breite 2",
      "id": "202f795b4f15ac075ad4305226cacf9c"
    },
    {
      "zip": "79539",
      "city": "Lörrach",
      "street": "Turmstr. 17",
      "id": "73ad561d3f54473c48b3d25df01fce60"
    },
    {
      "zip": "79588",
      "city": "Efringen-Kirchen",
      "street": "Bahnhofstr. 12",
      "id": "1564100e2d15a92ea23f8da76355600f"
    },
    {
      "zip": "79650",
      "city": "Schopfheim",
      "street": "Schwarzwaldstr. 54",
      "id": "b69a8aa41ead998bacb5730b13a8bc21"
    },
    {
      "zip": "79761",
      "city": "Waldshut-Tiengen",
      "street": "Industriestr. 2",
      "id": "e2d204ce9395251d22f50b5d82ab6a86"
    },
    {
      "zip": "80331",
      "city": "München",
      "street": "Marienplatz 22",
      "id": "7e6f00c2c331a00efc8db2e3e8bb95d6"
    },
    {
      "zip": "80331",
      "city": "München",
      "street": "Sendlinger Str. 40",
      "id": "f676af09b93d012f3c3563334a5ac68c"
    },
    {
      "zip": "80333",
      "city": "Munich",
      "street": "Salvatorplat Z",
      "id": "3273aab1174d990d2f0ff43034bcae70"
    },
    {
      "zip": "80335",
      "city": "München",
      "street": "Marsplatz 4",
      "id": "5096e2798326e4a4d06e55a76ac7cad9"
    },
    {
      "zip": "80469",
      "city": "München",
      "street": "Fraunhoferstr. 32",
      "id": "4cd4a24c2351262789fa6c2e70c49127"
    },
    {
      "zip": "80634",
      "city": "München",
      "street": "Rotkreuzplatz 1",
      "id": "da5ed10268a70157c1868319f8537a82"
    },
    {
      "zip": "80686",
      "city": "München",
      "street": "Fürstenrieder Str. 49",
      "id": "def20c7a84f81f9dd595d900a78b2f4c"
    },
    {
      "zip": "80802",
      "city": "München",
      "street": "Leopoldstr. 80",
      "id": "3efabfdb9b1a3bee7716bb28d51029b4"
    },
    {
      "zip": "80933",
      "city": "München",
      "street": "Schleißheimer Str. 487",
      "id": "cf4f2cbcf3ac471e248721b22270352b"
    },
    {
      "zip": "80939",
      "city": "München",
      "street": "Elisabeth-Selbert-Str. 1",
      "id": "052a64f9b71cd6842eff9d5c99f33882"
    },
    {
      "zip": "80992",
      "city": "München",
      "street": "Breslauerstr. 9",
      "id": "7e2ddaaffdf7d35b44b62f0bd4818dee"
    },
    {
      "zip": "80993",
      "city": "München",
      "street": "Riesstr. 59",
      "id": "32ecc530d23de4de681e8df811089ac3"
    },
    {
      "zip": "80995",
      "city": "München",
      "street": "Dachauer Str. 651",
      "id": "a95a82f37e6f220e850d356e103f08fe"
    },
    {
      "zip": "80995",
      "city": "München",
      "street": "Dachauer Str. 665",
      "id": "4a973899c3e6302f9cebcb1ff07bde82"
    },
    {
      "zip": "81241",
      "city": "München",
      "street": "Manzinger Weg 12",
      "id": "0d212fc6b1c79ae786fc2269c8b856cf"
    },
    {
      "zip": "81241",
      "city": "München",
      "street": "Pasinger Bahnhofsplatz 2",
      "id": "f302b7a24276dad23f7edf62eabc4328"
    },
    {
      "zip": "81247",
      "city": "München",
      "street": "Prangerlstr. 12",
      "id": "0132748a4ea7924c8ceb35278015dafd"
    },
    {
      "zip": "81373",
      "city": "München",
      "street": "Am Harras 16",
      "id": "cd56bde2b44cab8e15c68f90c629646a"
    },
    {
      "zip": "81373",
      "city": "München",
      "street": "Ortlerstr. 11",
      "id": "5ed928dc8e6a9777a2cb3df3b1d8bab9"
    },
    {
      "zip": "81375",
      "city": "München",
      "street": "Hans-Grässel-Weg 3",
      "id": "88a6d5f53d2f4b92e906e3084564a300"
    },
    {
      "zip": "81539",
      "city": "München",
      "street": "Wirtstr. 19",
      "id": "4aa5a6c11a62cce6393e956544e343a5"
    },
    {
      "zip": "81541",
      "city": "München",
      "street": "Tegernseer Platz 7",
      "id": "6e819b92c291ee467423cefd3ce363eb"
    },
    {
      "zip": "81543",
      "city": "München",
      "street": "Untere Weidenstr. 30",
      "id": "77db565f9a7588842b5dc49125bebb89"
    },
    {
      "zip": "81549",
      "city": "München",
      "street": "Schwarzbergstr. 4",
      "id": "4a778066dcb5e6694fd1e530c0576cc9"
    },
    {
      "zip": "81667",
      "city": "München",
      "street": "Weißenburger Str. 50",
      "id": "9fe50c8f99ab14a254f3af19799bda4a"
    },
    {
      "zip": "81669",
      "city": "München",
      "street": "Adelholzener Str. 2",
      "id": "d5698b0a9102b31c242634b5079780d1"
    },
    {
      "zip": "81671",
      "city": "München",
      "street": "Führichstr. 1",
      "id": "b8c8ca2b0e34cf603746027fc670f72b"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 1",
      "id": "c6c7f29025c609c84e16952cfd89330a"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 1-11",
      "id": "59ba6f11cd795d12dd3efc8c9c382385"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 1-15",
      "id": "c71b61792853ef1c1697083071aaab2f"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 11",
      "id": "52512c34d8ee38cce5fb7286e99fbf7a"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 3",
      "id": "902a4c6fe553f19c04a9e1f2b9a7dbd3"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 5",
      "id": "9a49770c0fb0cc5a4d15f4725d59d0cd"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Str. 9",
      "id": "fd4661883cd541ae7441ab5030ea75a6"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Straße 1 - 11",
      "id": "482a2a7d3563dd8d26dbcb1ae4458b7a"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfinger Straße 1-15",
      "id": "f51c341ef7b1236166f4f847c8338c4d"
    },
    {
      "zip": "81673",
      "city": "München",
      "street": "Dingolfingerstr. 1-11",
      "id": "d4d9ca9ec5edcc0ce95998dd15af94a3"
    },
    {
      "zip": "81737",
      "city": "München",
      "street": "Heinrich-Lübke-Str. 25",
      "id": "c3a62745fad050ffb0b30db25f03f4f0"
    },
    {
      "zip": "81737",
      "city": "München",
      "street": "Ollenhauerstr. 6",
      "id": "4b40e47ddd661d8af41845d9fcc08970"
    },
    {
      "zip": "81763",
      "city": "München",
      "street": "Dingolfinger Str. 9",
      "id": "c70e16f7015e42204fe9b5569a70a7b6"
    },
    {
      "zip": "81829",
      "city": "München",
      "street": "Willy-Brandt-Platz 5",
      "id": "236a073d94e73b023b2afdba71c882ad"
    },
    {
      "zip": "82024",
      "city": "Taufkirchen",
      "street": "Bahnhofstr. 21",
      "id": "bdbcd3221f8889f9b65ef4b71ac9e3cd"
    },
    {
      "zip": "82140",
      "city": "Olching",
      "street": "Wolfstr. 14",
      "id": "45e5f0633ac7dc4ee067922b8892fc00"
    },
    {
      "zip": "82166",
      "city": "Gräfelfing",
      "street": "Steubstr. 18",
      "id": "287c16c801dc6acca2c1e8f4a39e425e"
    },
    {
      "zip": "82256",
      "city": "Fürstenfeldbruck",
      "street": "Schöngeisinger Str. 24",
      "id": "b895fbb234070b0a17a049f405cd3618"
    },
    {
      "zip": "82256",
      "city": "Fürstenfeldbruck",
      "street": "Unfaltstr. 7",
      "id": "ccfd6b239176e71ed2bf9ccddcd3aef4"
    },
    {
      "zip": "82319",
      "city": "Starnberg",
      "street": "Gautinger Str. 1A",
      "id": "637e942a109b00703207203dfeeca557"
    },
    {
      "zip": "82362",
      "city": "Weilheim",
      "street": "Bahnhofstr. 4",
      "id": "a7e2afbe8d053f17c46610433133c9d7"
    },
    {
      "zip": "82362",
      "city": "Weilheim",
      "street": "Rathausplatz 1",
      "id": "9e4f08ad2f6fa9df68fac792ea096a54"
    },
    {
      "zip": "82377",
      "city": "Penzberg",
      "street": "Meichelbeckstr. 2A",
      "id": "5dcb40d74b77d5f0ec8b735d8f010e16"
    },
    {
      "zip": "82380",
      "city": "Peißenberg",
      "street": "Alte Kohlenwäsche 1",
      "id": "9ce146bc1a4ac0f9dc146b972e98c291"
    },
    {
      "zip": "82418",
      "city": "Murnau",
      "street": "Bahnhofstr. 5",
      "id": "2721e2d50d93d34977394b0f09e6332f"
    },
    {
      "zip": "82467",
      "city": "Garmisch-Partenkirchen",
      "street": "Bahnhofstr. 34",
      "id": "7c51431275cb30ddf89183af5bb4f3e9"
    },
    {
      "zip": "82467",
      "city": "Garmisch-Partenkirchen",
      "street": "Marienplatz 3",
      "id": "7c52a8330715b3c4a43e48c656c14b8c"
    },
    {
      "zip": "82487",
      "city": "Oberammergau",
      "street": "Ambronengasse 3",
      "id": "1b47318e05e4a978369fd9c27636ecca"
    },
    {
      "zip": "82515",
      "city": "Wolfratshausen",
      "street": "Sauerlacher Str. 28",
      "id": "7227aa898728409292a5f8d2dae41e3a"
    },
    {
      "zip": "82538",
      "city": "Geretsried",
      "street": "Adalbert-Stifter-Str. 44",
      "id": "e01930d12b081028aed290d223e05e19"
    },
    {
      "zip": "83022",
      "city": "Rosenheim",
      "street": "Anton-Kathrein-Str. 4",
      "id": "ee458f321d79e39b490d5a7c912b7afc"
    },
    {
      "zip": "83022",
      "city": "Rosenheim",
      "street": "Bahnhofstr. 23",
      "id": "0633d6e5c0cfe15c5188b91e0a0a953e"
    },
    {
      "zip": "83022",
      "city": "Rosenheim",
      "street": "Bahnhofstr. 23-27",
      "id": "f09b16dcdef1096ed9a966582c56e0ef"
    },
    {
      "zip": "83022",
      "city": "Rosenheim",
      "street": "Bahnhofstr. 3",
      "id": "2a4a41168486a3f3a05bcd4be42fb7b3"
    },
    {
      "zip": "83024",
      "city": "Rosenheim",
      "street": "Am Breitenfeld 1",
      "id": "76f1239a7bb4eadfeba6d72ca34decf9"
    },
    {
      "zip": "83026",
      "city": "Rosenheim",
      "street": "Grubholzer Str. 1",
      "id": "1bca4bf6220d42be4f6d28c9b568c608"
    },
    {
      "zip": "83043",
      "city": "Bad Aibling",
      "street": "Pentenriederstr. 2-4",
      "id": "28607621b87f038fe7d5968aba968ddb"
    },
    {
      "zip": "83098",
      "city": "Brannenburg",
      "street": "Steinbeisstr. 21",
      "id": "278d3d0a8ff9e729d102aa3b5c65d63e"
    },
    {
      "zip": "83209",
      "city": "Prien",
      "street": "Bachstr. 7-9",
      "id": "55d97f9c5cbf8b0db7db426574a2cf5a"
    },
    {
      "zip": "83224",
      "city": "Grassau",
      "street": "Marktstr. 24",
      "id": "077a7499a04675492d1c2e040bbb26a5"
    },
    {
      "zip": "83278",
      "city": "Traunstein",
      "street": "Rosenheimer Str. 9",
      "id": "37a2855ff8057c551ab3cf08589889c4"
    },
    {
      "zip": "83278",
      "city": "Traunstein",
      "street": "Seuffertstr. 10",
      "id": "3cb7e480fbfebba8845b73422a871b49"
    },
    {
      "zip": "83278",
      "city": "Traunstein",
      "street": "Seuffertstr. 12",
      "id": "9b3e5a8784241eb136c6133999b1e538"
    },
    {
      "zip": "83278",
      "city": "Traunstein",
      "street": "Seuffertstr. 2",
      "id": "26c41001602f85f748e1d225d0de28a4"
    },
    {
      "zip": "83278",
      "city": "Traunstein",
      "street": "Seuffertstr. 6-8",
      "id": "8af50de6531bc71b5cb24bddf9fb50cc"
    },
    {
      "zip": "83308",
      "city": "Trostberg",
      "street": "Adolf-Schwarzer-Str. 6",
      "id": "c5ff8b6a1da07ec508a8adfc0c9e44da"
    },
    {
      "zip": "83329",
      "city": "Waging",
      "street": "Ludwig-Felber-Str. 6",
      "id": "0bcd16c5b262101ba736d119b1969753"
    },
    {
      "zip": "83395",
      "city": "Freilassing",
      "street": "Lindenstr. 1",
      "id": "dc4e1aa29cdf0eaa413f42c6764699e8"
    },
    {
      "zip": "83435",
      "city": "Bad Reichenhall",
      "street": "Frühlingstr. 30",
      "id": "d91702c8d32054aaed0234d07531577a"
    },
    {
      "zip": "83512",
      "city": "Wasserburg",
      "street": "Bahnhofplatz 10",
      "id": "6586a1701c582fc4bc363e466330213c"
    },
    {
      "zip": "83512",
      "city": "Wasserburg",
      "street": "Schmerbeckstr. 5",
      "id": "49352c02c61016c1c59d7cc516a02cbf"
    },
    {
      "zip": "83666",
      "city": "Waakirchen",
      "street": "Alex-Gugler-Str. 7",
      "id": "7d4220ccbb9c4da56692d57d675d0db7"
    },
    {
      "zip": "83714",
      "city": "Miesbach",
      "street": "Schinharlstr. 3",
      "id": "2c6f89acb863607636c25f9b924bc255"
    },
    {
      "zip": "84028",
      "city": "Landshut",
      "street": "Altstadt 92",
      "id": "4c2eba4560438718d53fb105d52cc29c"
    },
    {
      "zip": "84030",
      "city": "Landshut",
      "street": "Siemensstr. 18-20",
      "id": "48c949e220d049eef8ff2545980efa64"
    },
    {
      "zip": "84030",
      "city": "Landshut",
      "street": "Siemensstr. 20",
      "id": "9a70e2bad26adb333eee8c312ca3b339"
    },
    {
      "zip": "84030",
      "city": "Landshut",
      "street": "Siemensstr. 24",
      "id": "85dc5b406d7b7c7979606da5569d0019"
    },
    {
      "zip": "84056",
      "city": "Rottenburg",
      "street": "Seidererstr. 12",
      "id": "0566a4e57e6af6b097b8a0f2131d9e1d"
    },
    {
      "zip": "84130",
      "city": "Dingolfing",
      "street": "Reichenberger Str. 52",
      "id": "71e6d2899b4cf7804d75020c8b8f8d2b"
    },
    {
      "zip": "84130",
      "city": "Dingolfing",
      "street": "Tannenstr. 43",
      "id": "d2029f30567b04784ad1b594ca431d07"
    },
    {
      "zip": "84137",
      "city": "Vilsbiburg",
      "street": "Stadtplatz 29",
      "id": "497db590f21a977c1db1b72d4c6c8a7c"
    },
    {
      "zip": "84307",
      "city": "Eggenfelden",
      "street": "Blumenstr. 3",
      "id": "3cd79dbf6d6a50e1788b5ea053323eb3"
    },
    {
      "zip": "84347",
      "city": "Pfarrkirchen",
      "street": "Seilerweg 4C",
      "id": "10e7ad22f4c826c59a41a6f45e022c7d"
    },
    {
      "zip": "84347",
      "city": "Pfarrkirchen",
      "street": "Seilerweg 4D",
      "id": "bc76526db8450fe45735e840cc84d245"
    },
    {
      "zip": "84359",
      "city": "Simbach",
      "street": "Adolf-Kolping-Str. 1",
      "id": "e48a866adc59245bc57c0a1440daab3f"
    },
    {
      "zip": "84405",
      "city": "Dorfen",
      "street": "Jahnstr. 15",
      "id": "e983c75aea5467e456278c7a1ceed7df"
    },
    {
      "zip": "84453",
      "city": "Mühldorf",
      "street": "Bonimeierring 6",
      "id": "a428e8c9790d9ba87280d7af89a15367"
    },
    {
      "zip": "84453",
      "city": "Mühldorf",
      "street": "Stadtplatz 51",
      "id": "72d615163f760baf7e6aacfa1dfc92f9"
    },
    {
      "zip": "84478",
      "city": "Waldkraiburg",
      "street": "Berliner Platz 3",
      "id": "bedf548202b295f08a8881f07f1edb44"
    },
    {
      "zip": "84489",
      "city": "Burghausen",
      "street": "Pettenkoferstr. 43",
      "id": "3b17e79626274105c101afad90a39bff"
    },
    {
      "zip": "84503",
      "city": "Altötting",
      "street": "Beckstr. 42",
      "id": "3847775bb9af50bee41f1c5a0024ff49"
    },
    {
      "zip": "84524",
      "city": "Neuötting",
      "street": "Bischof-Sailer-Str. 7",
      "id": "52abbe3c08bbf53b64d739036d0b0f1e"
    },
    {
      "zip": "85049",
      "city": "Ingolstadt",
      "street": "Am Stein 5",
      "id": "5a3c077d7b014bb445caffc551cc023e"
    },
    {
      "zip": "85051",
      "city": "Ingolstadt",
      "street": "Karlskroner Str. 32",
      "id": "2f33098aab5dc9af6b632d4364244fa3"
    },
    {
      "zip": "85051",
      "city": "Ingolstadt",
      "street": "Steiglehnerstr. 6",
      "id": "4b89c7ea3f4b5d4e012c785c39f050b7"
    },
    {
      "zip": "85057",
      "city": "Ingolstadt",
      "street": "Am Westpark 6",
      "id": "301b661b08747f8b19570c8fe94f76d5"
    },
    {
      "zip": "85057",
      "city": "Ingolstadt",
      "street": "Gaimersheimer Str. 49",
      "id": "aa8c2ca01eda8936228f3323370ecdd1"
    },
    {
      "zip": "85072",
      "city": "Eichstätt",
      "street": "Webergasse 18",
      "id": "e20247065213b73596e69c4b44a171af"
    },
    {
      "zip": "85221",
      "city": "Dachau",
      "street": "Bahnhofstr. 22",
      "id": "18bbef1a394ca83061189a0f726b863b"
    },
    {
      "zip": "85221",
      "city": "Dachau",
      "street": "Grobmühlstr. 15",
      "id": "4d3c937e307893d51fd0d32a517a6ea8"
    },
    {
      "zip": "85276",
      "city": "Pfaffenhofen",
      "street": "Kellerstr. 10",
      "id": "9ba344423d1611bf7ede588f5b9ec57a"
    },
    {
      "zip": "85354",
      "city": "Freising",
      "street": "Bahnhofstr. 2",
      "id": "44393f91e988c58d5ac5543cb824cc66"
    },
    {
      "zip": "85354",
      "city": "Freising",
      "street": "Gartenstr. 36",
      "id": "b7bbbc2ba84c219b32eba97615d1a730"
    },
    {
      "zip": "85368",
      "city": "Moosburg",
      "street": "Poststr. 4",
      "id": "c58fd539d62d14488b0ba20c5afb8bba"
    },
    {
      "zip": "85435",
      "city": "Erding",
      "street": "Riverastr. 16",
      "id": "64d44cfa793bd767ba72962b44507354"
    },
    {
      "zip": "85445",
      "city": "Oberding",
      "street": "Lohstr. 32",
      "id": "70341a89c044fa3dd574746a713b490a"
    },
    {
      "zip": "85521",
      "city": "Ottobrunn",
      "street": "Rosenheimer Landstr. 32",
      "id": "9f3fe6c51535d463411b167ba909bbb0"
    },
    {
      "zip": "85521",
      "city": "Ottobrunn",
      "street": "Willy-Messerschmitt-Str. 1",
      "id": "21e5c0bec9471cbbecaf317b1844ea9d"
    },
    {
      "zip": "85567",
      "city": "Grafing",
      "street": "Bahnhofstr. 32",
      "id": "e67ca7de2ec597880818f76e35e4d05b"
    },
    {
      "zip": "85570",
      "city": "Markt Schwaben",
      "street": "Widderweg 10",
      "id": "b24f5a1017ccee4508f487e12c3d74ae"
    },
    {
      "zip": "85586",
      "city": "Poing",
      "street": "Plieninger Str. 3",
      "id": "6b6e09f3da7f74cb72b9aa0e1f9f0abe"
    },
    {
      "zip": "85598",
      "city": "Baldham",
      "street": "Schwalbenstr. 2",
      "id": "c27134de6e67fb5a1956cda67eb7d651"
    },
    {
      "zip": "85614",
      "city": "Kirchseeon",
      "street": "Ahornstr. 31",
      "id": "7a078a201eca93f467ac4bf903d09c9c"
    },
    {
      "zip": "85622",
      "city": "Feldkirchen",
      "street": "Bahnhofstr. 6",
      "id": "122277117188d5a42afba9fe8e44007e"
    },
    {
      "zip": "85716",
      "city": "Unterschleißheim",
      "street": "Johann-Schmid-Str. 43",
      "id": "226fa944d9db3e36196d62c67b4296d1"
    },
    {
      "zip": "85737",
      "city": "Ismaning",
      "street": "Reisingerstr. 6",
      "id": "f5d26e8e2750f9d024352d0a45fa2369"
    },
    {
      "zip": "85748",
      "city": "Garching",
      "street": "Forschungsgeländ E",
      "id": "2b820e5e8aba30044ddfe48659f3e32f"
    },
    {
      "zip": "85774",
      "city": "Unterföhring",
      "street": "Münchner Str. 66",
      "id": "12d8c97406c0e3168f2a6aa4f500ec4d"
    },
    {
      "zip": "86150",
      "city": "Augsburg",
      "street": "Bürgermeister-Fischer-Str. 12",
      "id": "1de33c039b442404e02bbaa8aa400ef1"
    },
    {
      "zip": "86152",
      "city": "Augsburg",
      "street": "Stadtjägerstr. 10",
      "id": "f4c6b4790da6c647d248c37e7b298d24"
    },
    {
      "zip": "86153",
      "city": "Augsburg",
      "street": "Böheimstr. 8",
      "id": "4777ba2f678d76fca0451fd818e71046"
    },
    {
      "zip": "86153",
      "city": "Augsburg",
      "street": "Langenmantelstr. 1",
      "id": "d641f880d20b47d890189621664a6e1f"
    },
    {
      "zip": "86153",
      "city": "Augsburg",
      "street": "Willy-Brandt-Platz 1",
      "id": "36c0de74ff98b0c263bb11fe7e60d689"
    },
    {
      "zip": "86368",
      "city": "Gersthofen",
      "street": "Gablinger Str. 2",
      "id": "c04d435205e0f0b2747dedda064c9a04"
    },
    {
      "zip": "86405",
      "city": "Meitingen",
      "street": "Hauptstr. 26",
      "id": "a798e7814fc8f40622a5da2e36cec07f"
    },
    {
      "zip": "86470",
      "city": "Thannhausen",
      "street": "Schweizer Str. 10",
      "id": "031d5af5be3d9e325d6b293e2d501cea"
    },
    {
      "zip": "86551",
      "city": "Aichach",
      "street": "Forellenweg 7",
      "id": "900dad3effa0d13b5b917cf4145bf99c"
    },
    {
      "zip": "86609",
      "city": "Donauwörth",
      "street": "Dr.-Ludwig-Bölkow-Str. 1",
      "id": "385d1e4c82db28b832449d3f9e4ede6c"
    },
    {
      "zip": "86609",
      "city": "Donauwörth",
      "street": "Offizial-Schmid-Str. 6",
      "id": "81ac0476a53607bc1ad2af8aa758bae2"
    },
    {
      "zip": "86633",
      "city": "Neuburg",
      "street": "Hadergasse 155-1/ 2",
      "id": "1fe53c9b255a78a578560231d9e79717"
    },
    {
      "zip": "86720",
      "city": "Nördlingen",
      "street": "Bürgermeister Reiger Str. 4A",
      "id": "83a14c5f0e6ac74683a4979f9d176f0f"
    },
    {
      "zip": "86807",
      "city": "Buchloe",
      "street": "An Der Halde 2",
      "id": "4d9b29c2f2ff10721d60aa93936a7161"
    },
    {
      "zip": "86830",
      "city": "Schwabmünchen",
      "street": "Bahnhofstr. 36",
      "id": "efdf795ba06797fd9cb9bed740237e25"
    },
    {
      "zip": "86899",
      "city": "Landsberg",
      "street": "Spöttingerstr. 16",
      "id": "e98d178d387df70b32c530cbf7d71466"
    },
    {
      "zip": "86899",
      "city": "Landsberg Am Lech",
      "street": "Hauptplatz 175",
      "id": "788f1c5ea641c1ef225d55b4d06198c4"
    },
    {
      "zip": "86956",
      "city": "Schongau",
      "street": "Sonnenstr. 11-13",
      "id": "7eafb77e5d9f5a55564cfae7c9e19f5c"
    },
    {
      "zip": "86971",
      "city": "Peiting",
      "street": "Pfarrer-Anleitner-Str. 2",
      "id": "b34e3b2076ad329b033777e01e96c134"
    },
    {
      "zip": "87435",
      "city": "Kempten",
      "street": "Alpenstr. 8",
      "id": "f59d8d599a96c8f477d6f350d9b1f378"
    },
    {
      "zip": "87435",
      "city": "Kempten",
      "street": "Am Schlößle 2",
      "id": "0ee8d13e95c5f6defbc50bb4727f7009"
    },
    {
      "zip": "87435",
      "city": "Kempten",
      "street": "Bahnhofstr. 35",
      "id": "345ac24774f8b6e8713c9b779f9570a3"
    },
    {
      "zip": "87435",
      "city": "Kempten",
      "street": "Hirschstr. 5",
      "id": "d551fb645b532a1053c5675e81d0c59f"
    },
    {
      "zip": "87437",
      "city": "Kempten",
      "street": "Dieselstr. 3",
      "id": "ea05a34c3c5d18be476b14fea2c40477"
    },
    {
      "zip": "87527",
      "city": "Sonthofen",
      "street": "Immenstädter Str. 19",
      "id": "7167d5c67300f90f8c39c3c727a34b29"
    },
    {
      "zip": "87600",
      "city": "Kaufbeuren",
      "street": "Salzmarkt 5",
      "id": "0aa7d2f8f81c0b33032e11b37bd2a9d6"
    },
    {
      "zip": "87600",
      "city": "Kaufbeuren",
      "street": "Schraderstr. 10",
      "id": "80451501b4628604aa3dd304478c136c"
    },
    {
      "zip": "87616",
      "city": "Marktoberdorf",
      "street": "Simon-Baumann-Str. 17",
      "id": "9d6e227b87be31d6aab5888a230eded7"
    },
    {
      "zip": "87700",
      "city": "Memmingen",
      "street": "Goldhoferstr. 9",
      "id": "6d368c895668fc63d5b37542d470a14e"
    },
    {
      "zip": "87700",
      "city": "Memmingen",
      "street": "Kohlschanzstr. 2",
      "id": "2ab0f401f4c9f70539462fa972de32e0"
    },
    {
      "zip": "87700",
      "city": "Memmingen",
      "street": "Kramerstr. 35",
      "id": "a5a35d8b774bc44b262c0a41f4abc02a"
    },
    {
      "zip": "87719",
      "city": "Mindelheim",
      "street": "Bourg-De-Peage-Str. 88",
      "id": "258ac71cf46ffd04c86f75f29d600ca2"
    },
    {
      "zip": "87727",
      "city": "Babenhausen",
      "street": "Auf Der Wies 35",
      "id": "2ce11770e6e46c6a0a0da7e60c8689af"
    },
    {
      "zip": "88045",
      "city": "Friedrichshafen",
      "street": "Friedrichstr. 29/ 1",
      "id": "4deaae6a0d7ce68a6b74d1b859728b7d"
    },
    {
      "zip": "88045",
      "city": "Friedrichshafen",
      "street": "Wilhelmstr. 6",
      "id": "bb3e81b2cc0af61eda0f7801d64a7a84"
    },
    {
      "zip": "88069",
      "city": "Tettnang",
      "street": "Albert-Schweitzer-Str. 6",
      "id": "b4463cd4a15dce6238f148db02a1ff3d"
    },
    {
      "zip": "88131",
      "city": "Lindau",
      "street": "Bürstergasse 2",
      "id": "c78b21d571b714ea81e3359ec8db455a"
    },
    {
      "zip": "88131",
      "city": "Lindau",
      "street": "Wackerstr. 20/ 22",
      "id": "342192ff594d46c08c816d832e99e5c8"
    },
    {
      "zip": "88161",
      "city": "Lindenberg",
      "street": "Poststr. 7",
      "id": "e28589e4a42c0b9a899c2f99e80b1ee8"
    },
    {
      "zip": "88212",
      "city": "Ravensburg",
      "street": "Gartenstr. 107",
      "id": "7b8898902608801ca2846d4f163e0465"
    },
    {
      "zip": "88212",
      "city": "Ravensburg",
      "street": "Marienplatz 63",
      "id": "d32e543cfdea26cccd7fb2e2fbc2482b"
    },
    {
      "zip": "88239",
      "city": "Wangen",
      "street": "Bahnhofstr. 4",
      "id": "a0b17ca78bfc803c6f0ee4c0abe0dfdc"
    },
    {
      "zip": "88250",
      "city": "Weingarten",
      "street": "Brielmayerstr. 2",
      "id": "e7e8ba8c2a9002204b090ded02c2689e"
    },
    {
      "zip": "88250",
      "city": "Weingarten",
      "street": "Franz-Beer-Str. 111-115",
      "id": "a7fbc3077ca767e64c457da3b68a10bc"
    },
    {
      "zip": "88250",
      "city": "Weingarten",
      "street": "Sauterleutestr. 36",
      "id": "b687da9df844604f6ee6a1abab01013b"
    },
    {
      "zip": "88299",
      "city": "Leutkirch",
      "street": "Im Schleifrad 12",
      "id": "b21db9951d86f1dbb7458bb8f4ea5d09"
    },
    {
      "zip": "88348",
      "city": "Bad Saulgau",
      "street": "Landhausstr. 8",
      "id": "312236922ab73212cf3301159798d010"
    },
    {
      "zip": "88400",
      "city": "Biberach",
      "street": "Marktplatz 17",
      "id": "7716b37e4490145ed9dec23a2752dfad"
    },
    {
      "zip": "88400",
      "city": "Biberach",
      "street": "Vollmerstr. 17",
      "id": "802c2339441037d14c4ae82d37878a93"
    },
    {
      "zip": "88410",
      "city": "Bad Wurzach",
      "street": "Uhlandstr. 21",
      "id": "e42d71425e82793d90cbdc937703b13c"
    },
    {
      "zip": "88416",
      "city": "Ochsenhausen",
      "street": "Ulmer Str. 37",
      "id": "38296dc124e3c910f9d47d5bdc132b7a"
    },
    {
      "zip": "88499",
      "city": "Riedlingen",
      "street": "Ziegelhüttenstr. 38",
      "id": "d58d3558c909155073508da82fbb8150"
    },
    {
      "zip": "88662",
      "city": "überlingen",
      "street": "Langgasse 5",
      "id": "c619e0f48651c0f6ad9f865d1023d420"
    },
    {
      "zip": "88682",
      "city": "Salem",
      "street": "Bodenseestr. 8",
      "id": "d90f0871cba97fecca27a1a39080235f"
    },
    {
      "zip": "89073",
      "city": "Ulm",
      "street": "Bahnhofplatz 2",
      "id": "95a08c3a2d87300f150996386d75f682"
    },
    {
      "zip": "89073",
      "city": "Ulm",
      "street": "Hirschstr. 24",
      "id": "b8cc4cc33910951f91488aa377b90be6"
    },
    {
      "zip": "89073",
      "city": "Ulm",
      "street": "Olgastr. 63-67/bahnhofplatz 2",
      "id": "dd8d81d26696c2b9937912abaf065f97"
    },
    {
      "zip": "89073",
      "city": "Ulm",
      "street": "Olgastr. 67",
      "id": "af71e460c524d364d73eb94609454a0c"
    },
    {
      "zip": "89077",
      "city": "Ulm",
      "street": "Blaubeurer Str. 95",
      "id": "4a172fb919ac66be157329f5b133a2ce"
    },
    {
      "zip": "89231",
      "city": "Neu-Ulm",
      "street": "Im Starkfeld 11",
      "id": "05e724406147b3df9c98455cdb65c507"
    },
    {
      "zip": "89264",
      "city": "Weißenhorn",
      "street": "Bodelschwinghstr. 17",
      "id": "dddb64185b5c9a9720e2ff74257d07ad"
    },
    {
      "zip": "89312",
      "city": "Günzburg",
      "street": "Schlachthausstr. 59A",
      "id": "97f07a3b82f0a658aa2e148fe821896c"
    },
    {
      "zip": "89331",
      "city": "Burgau",
      "street": "Zengerlestr. 8",
      "id": "ee2009e71e5c23a3511f029daed6c6a5"
    },
    {
      "zip": "89407",
      "city": "Dillingen",
      "street": "Große Allee 23",
      "id": "fb91d0074d06df9db0a503ca0ca6c271"
    },
    {
      "zip": "89518",
      "city": "Heidenheim",
      "street": "Theodor-Heuss-Str. 1",
      "id": "2a7581502fca55a6a2c96aa0ed597cf4"
    },
    {
      "zip": "89522",
      "city": "Heidenheim",
      "street": "Hauptstr. 27",
      "id": "c3c29fa84e057d87cdbe301b731e579a"
    },
    {
      "zip": "90402",
      "city": "Nürnberg",
      "street": "Breite Gasse 13-15",
      "id": "05fe20cf14e59f6560fa81693467e647"
    },
    {
      "zip": "90402",
      "city": "Nürnberg",
      "street": "Karolinenstr. 34",
      "id": "00348bce3f100c857e6dfea72df6faa5"
    },
    {
      "zip": "90402",
      "city": "Nürnberg",
      "street": "Kressengartenstr. 4",
      "id": "78d1cbb3fe448fe683390f1dc3638867"
    },
    {
      "zip": "90409",
      "city": "Nürnberg",
      "street": "Bayreuther Str. 1",
      "id": "012fade74d80e008451147b661da1cfb"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Am Fernmeldeturm 2",
      "id": "679874d6619977ead5b0fd28b31233ea"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Dieselstr. 43",
      "id": "cda532dc4e373087fe72c5b77b1ba6fe"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Georg-Elser-Str. 4",
      "id": "528824deab293ef37f60876244d6ef52"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Georg-Elser-Str. 6",
      "id": "6f4b789cce1c1f79cd631a71a3b25213"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Georg-Elser-Str. 7",
      "id": "1ef83322fbdb4e4572bb869f9dc081f5"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Georg-Elser-Str. 8",
      "id": "8b90fbf3051fbabe0bf3418f3c45fc76"
    },
    {
      "zip": "90441",
      "city": "Nürnberg",
      "street": "Georg-Elser-Str. 9",
      "id": "acacefd17a1ce6b0805a0559effd1f25"
    },
    {
      "zip": "90471",
      "city": "Nürnberg",
      "street": "Herzogstandstr. 5-7",
      "id": "1eafb99aafbeaaf5f54cda73b4b0c143"
    },
    {
      "zip": "90473",
      "city": "Nürnberg",
      "street": "Glogauer Str. 30",
      "id": "d9b69306f619ff92427a5121fdb87d68"
    },
    {
      "zip": "90491",
      "city": "Nürnberg",
      "street": "äußere Bayreuther Str. 100",
      "id": "128f8182749887494d31799fc0f0a396"
    },
    {
      "zip": "90762",
      "city": "Fürth",
      "street": "Schwabacher Str. 38",
      "id": "86e4cec0fcd57908e0433f1203c7a53c"
    },
    {
      "zip": "90768",
      "city": "Fürth",
      "street": "Hardenbergstr. 39",
      "id": "aedd673269dd78147944bb175a8c9071"
    },
    {
      "zip": "91052",
      "city": "Erlangen",
      "street": "Michael-Vogel-Str. 3",
      "id": "aae8a29cb301ebd17df77a5a8fd37666"
    },
    {
      "zip": "91054",
      "city": "Erlangen",
      "street": "Hauptstr. 17",
      "id": "5fb662a9999fc9c051eca92be684d19f"
    },
    {
      "zip": "91154",
      "city": "Roth",
      "street": "Friedrich-Ebert-Str. 12",
      "id": "4aaafea7f32aea7b1320075dc0320895"
    },
    {
      "zip": "91301",
      "city": "Forchheim",
      "street": "Egerlandstr. 1",
      "id": "49cc560dc64b683658018448e8181ed4"
    },
    {
      "zip": "91301",
      "city": "Forchheim",
      "street": "Steinbühlstr. 1",
      "id": "85f66e5449cbd6f6c12cc1f98eaec903"
    },
    {
      "zip": "91413",
      "city": "Neustadt",
      "street": "Nägeleinsgasse 36",
      "id": "c8974b80a69cd7d23a888e277ab682f6"
    },
    {
      "zip": "91438",
      "city": "Bad Windsheim",
      "street": "Johanniterstr. 29",
      "id": "3f6afe5ab120655c7d9488d7ab2cfa3f"
    },
    {
      "zip": "91522",
      "city": "Ansbach",
      "street": "Meinhardswindener Str. 4",
      "id": "6b931d77bb873cee1a8109646e7931c1"
    },
    {
      "zip": "91522",
      "city": "Ansbach",
      "street": "Meinhardswindener Str. 4A",
      "id": "5647c6819be5ea2134a75df47885b929"
    },
    {
      "zip": "91522",
      "city": "Ansbach",
      "street": "Rettistr. 56",
      "id": "6a7a31b0d4e57b78f6a91800d5eee96c"
    },
    {
      "zip": "91522",
      "city": "Ansbach",
      "street": "Uzstr. 12",
      "id": "b362ae4ca0561214af95abe8b0bec2fb"
    },
    {
      "zip": "91555",
      "city": "Feuchtwangen",
      "street": "Ringstr. 74",
      "id": "ece45906829b6cc1266f1a28b809e11a"
    },
    {
      "zip": "91710",
      "city": "Gunzenhausen",
      "street": "Alemannenstr. 8B",
      "id": "5b11071a9060a10cc65e482703c81e41"
    },
    {
      "zip": "91781",
      "city": "Weißenburg",
      "street": "Westliche Ringstr. 2",
      "id": "0961c482c02f2dde3e184dfdbbf3117b"
    },
    {
      "zip": "91781",
      "city": "Weißenburg",
      "street": "Westliche Ringstr. 2A",
      "id": "a4a725865f6d5866897700fbec1017b9"
    },
    {
      "zip": "92224",
      "city": "Amberg",
      "street": "Georgenstr. 25",
      "id": "8f4e12ad6ba589f7e0c33957a5eb2ff4"
    },
    {
      "zip": "92224",
      "city": "Amberg",
      "street": "Liebengrabenweg 9",
      "id": "0f684b2e107ecbaad7fb3e14b9a18fc7"
    },
    {
      "zip": "92224",
      "city": "Amberg",
      "street": "Marienstr. 8",
      "id": "35db27a42256f4212c0bbaa759b8368d"
    },
    {
      "zip": "92318",
      "city": "Neumarkt",
      "street": "Stephanstr. 12",
      "id": "d8441b4b8c8b333cf4e9cfef99842f48"
    },
    {
      "zip": "92339",
      "city": "Beilngries",
      "street": "Max-Prinstner-Str. 21",
      "id": "522639f133c1ffef2ab123d14f797ac1"
    },
    {
      "zip": "92421",
      "city": "Schwandorf",
      "street": "Augustinstr. 1",
      "id": "7cb0ad98f4ef175b68518912638b5cf5"
    },
    {
      "zip": "92421",
      "city": "Schwandorf",
      "street": "Bellstr. 2-4",
      "id": "dc50990e246740d48c45cb1080516a7a"
    },
    {
      "zip": "92421",
      "city": "Schwandorf",
      "street": "Friedrich-Ebert-Str. 14",
      "id": "29177708962db3086ba908cfb6f4e55d"
    },
    {
      "zip": "92526",
      "city": "Oberviechtach",
      "street": "Zahlweingarten 1",
      "id": "96bc782f0ce7bca03edbb03e29411e4d"
    },
    {
      "zip": "92533",
      "city": "Wernberg-Köblitz",
      "street": "Neunaigenerstr. 17",
      "id": "c98d44c0ad1e407d9aec3098a659dffe"
    },
    {
      "zip": "92637",
      "city": "Weiden",
      "street": "Max-Reger-Str. 7",
      "id": "be23ab89e521631e3fc56b79ad79dfc4"
    },
    {
      "zip": "92637",
      "city": "Weiden",
      "street": "Obere Bauscherstr. 14",
      "id": "68789c4c6982e007b14fdf17c5bdff43"
    },
    {
      "zip": "92637",
      "city": "Weiden",
      "street": "Oberer Markt 5",
      "id": "fd899c570bdf7f506ce86d432077914a"
    },
    {
      "zip": "93051",
      "city": "Regensburg",
      "street": "Schmellerstr. 63",
      "id": "d5a6b7be86b90819f7ad691ba53c8c32"
    },
    {
      "zip": "93053",
      "city": "Regensburg",
      "street": "Bajuwarenstr. 4",
      "id": "cc283de419dd4f5fcc28b2200042c764"
    },
    {
      "zip": "93053",
      "city": "Regensburg",
      "street": "Friedenstr. 23",
      "id": "174d7ad33a32b51257e2fc3c6580a163"
    },
    {
      "zip": "93059",
      "city": "Regensburg",
      "street": "Weichser Weg 5",
      "id": "104467a66dd7902db97e723ab69ede6f"
    },
    {
      "zip": "93309",
      "city": "Kelheim",
      "street": "Schloßweg 2",
      "id": "d2e3bf78a0d619fda5fcc84f88133c97"
    },
    {
      "zip": "93413",
      "city": "Cham",
      "street": "Sudetenstr. 2",
      "id": "e3c208dff5e6966e02030be684f4b68c"
    },
    {
      "zip": "94032",
      "city": "Passau",
      "street": "Lambergstr. 4",
      "id": "10964e726cca255eef9d0c7dc15a6e6a"
    },
    {
      "zip": "94032",
      "city": "Passau",
      "street": "Ludwigsplatz 8",
      "id": "01461203404d3d24032dacbb0b86721e"
    },
    {
      "zip": "94060",
      "city": "Pocking",
      "street": "St.-Georgen Str. 7",
      "id": "136cd51c9bda77904946f739b4a2ae11"
    },
    {
      "zip": "94078",
      "city": "Freyung",
      "street": "Am Bahnhof 6A",
      "id": "00e7975ad5321c2402b7524a3ebb40a8"
    },
    {
      "zip": "94209",
      "city": "Regen",
      "street": "Zwieseler Str. 46-48",
      "id": "1be4d52a6cfb4a40d8f8f27555b18d6c"
    },
    {
      "zip": "94315",
      "city": "Straubing",
      "street": "Kolbstr. 10",
      "id": "5a19a2cb9923625b7744e3ba8f197d79"
    },
    {
      "zip": "94315",
      "city": "Straubing",
      "street": "Ludwigsplatz 42",
      "id": "cc895d4666ccd35f41e98e0f8b52ddfe"
    },
    {
      "zip": "94405",
      "city": "Landau",
      "street": "Schneiderberg 10",
      "id": "ebccbf28d534d60dc1e41cc29e6890af"
    },
    {
      "zip": "94469",
      "city": "Deggendorf",
      "street": "Friedrich-Gauß-Str. 1",
      "id": "f947e867dd1bdcee9b19afc26115c4c7"
    },
    {
      "zip": "94474",
      "city": "Vilshofen",
      "street": "Altdorfer Str. 12",
      "id": "f9de808a4b1952edecbfdcefd08001cd"
    },
    {
      "zip": "94491",
      "city": "Hengersberg",
      "street": "Scharwächter Str. 5",
      "id": "90bfa3cbea24748ad66448e80dd7e065"
    },
    {
      "zip": "94518",
      "city": "Spiegelau",
      "street": "Ludwig-Stanglweg 9",
      "id": "f829f329a4f5f123e60f69718fa43324"
    },
    {
      "zip": "95028",
      "city": "Hof",
      "street": "Konrad-Adenauer-Platz 1",
      "id": "2e4f9a37e7fc8d2323e276619e3a39a5"
    },
    {
      "zip": "95028",
      "city": "Hof",
      "street": "Oberes Tor 5",
      "id": "968b31199b9c4f52e88cba2ea7a567d4"
    },
    {
      "zip": "95213",
      "city": "Münchberg",
      "street": "Schneebergstr. 6",
      "id": "7ceb665aba0b716c0892cc5665c544ed"
    },
    {
      "zip": "95326",
      "city": "Kulmbach",
      "street": "Albert-Ruckdeschel-Str. 2",
      "id": "1355f3392794cf97ad9255d65ce5a165"
    },
    {
      "zip": "95326",
      "city": "Kulmbach",
      "street": "Langgasse 31",
      "id": "d6bdca8d90429fb68ad7e96da69e2627"
    },
    {
      "zip": "95444",
      "city": "Bayreuth",
      "street": "Maximilianstr. 71",
      "id": "52353a735bdbb817c0d84c3eff2d0fd7"
    },
    {
      "zip": "95445",
      "city": "Bayreuth",
      "street": "Spinnereistr. 5",
      "id": "f3f5dfb04499043f021170c1e6d7510e"
    },
    {
      "zip": "95448",
      "city": "Bayreuth",
      "street": "Wilhelm-Pitz-Str. 1",
      "id": "7601c836d2b9f08497fe86f846882ad4"
    },
    {
      "zip": "95448",
      "city": "Bayreuth",
      "street": "Ziegelleite 2-4",
      "id": "58834ecb496fec8e84f3123333af7a84"
    },
    {
      "zip": "95615",
      "city": "Marktredwitz",
      "street": "Fritz-Thomas-Str. 1",
      "id": "728749a3cdc0833020f618e22374d2cf"
    },
    {
      "zip": "95615",
      "city": "Marktredwitz",
      "street": "Leopoldstr. 30",
      "id": "2a248b08eaa3fb560c88081d8d35a64e"
    },
    {
      "zip": "95643",
      "city": "Tirschenreuth",
      "street": "Goethestr. 1",
      "id": "7739cd004e6596bf81b130b1cfd9117d"
    },
    {
      "zip": "96047",
      "city": "Bamberg",
      "street": "Hauptwachstr. 13",
      "id": "443321a336864d8e18e1be77a005966c"
    },
    {
      "zip": "96050",
      "city": "Bamberg",
      "street": "Erlichstr. 51",
      "id": "770a3da889d7ff5295ece7d7894af3eb"
    },
    {
      "zip": "96050",
      "city": "Bamberg",
      "street": "Gutenbergstr. 13",
      "id": "50925f32f0923b94b1dd3ccc1f751b13"
    },
    {
      "zip": "96052",
      "city": "Bamberg",
      "street": "Memmelsdorfer Str. 209A",
      "id": "98025b735848da6aefc396f731bb435c"
    },
    {
      "zip": "96052",
      "city": "Bamberg",
      "street": "Memmelsdorfer Str. 209B",
      "id": "f5157ac25d2d81189d057af4baa69840"
    },
    {
      "zip": "96103",
      "city": "Hallstadt",
      "street": "Emil-Kemmer-Str. 9",
      "id": "368a00c7285a6b5b74e35433b7d37c17"
    },
    {
      "zip": "96215",
      "city": "Lichtenfels",
      "street": "Bamberger Str. 11",
      "id": "c2e77927216e268dcf484cb2c7e7858e"
    },
    {
      "zip": "96224",
      "city": "Burgkunstadt",
      "street": "Dr.-Sattler-Str. 2",
      "id": "2147d35b672393180f362acd7c93aa1f"
    },
    {
      "zip": "96317",
      "city": "Kronach",
      "street": "Max-Von-Welsch-Str. 9",
      "id": "17a5f32bee9bb215d4eebdf0f58267fc"
    },
    {
      "zip": "96450",
      "city": "Coburg",
      "street": "Neustadter Str. 16-18",
      "id": "fd035bda58430080c01d4c127a43db12"
    },
    {
      "zip": "96450",
      "city": "Coburg",
      "street": "Spitalgasse 1",
      "id": "dac74bb8427f9ce297bc3b845ff272b5"
    },
    {
      "zip": "96515",
      "city": "Sonneberg",
      "street": "Coburger Allee 43",
      "id": "692383688e82f591151a3e54342cd3ff"
    },
    {
      "zip": "96515",
      "city": "Sonneberg",
      "street": "Neustadter Str. 199",
      "id": "324c92adadad057fdcd7fb49298cce49"
    },
    {
      "zip": "97070",
      "city": "Würzburg",
      "street": "Dominikanerplatz 3A",
      "id": "ffbdcf3d868ffb8c0c0f63f8dbf7d7ea"
    },
    {
      "zip": "97070",
      "city": "Würzburg",
      "street": "Kürschnerhof 1",
      "id": "569a732faaccf6573c6a00e0aa1be326"
    },
    {
      "zip": "97070",
      "city": "Würzburg",
      "street": "Paradeplatz 4",
      "id": "9dedc4ce9da49a7f463601d4fd5b7895"
    },
    {
      "zip": "97076",
      "city": "Würzburg",
      "street": "Nürnberger Str. 57",
      "id": "c5b9950ebadc6d0706f86d77af87ed32"
    },
    {
      "zip": "97080",
      "city": "Würzburg",
      "street": "Schürerstr. 5-9A",
      "id": "f1cd0a3c2a452dab73088e88c5737dfa"
    },
    {
      "zip": "97080",
      "city": "Würzburg",
      "street": "Schürerstr. 7",
      "id": "887faabb01706e1f6442e9ed3f960d15"
    },
    {
      "zip": "97080",
      "city": "Würzburg",
      "street": "Schürerstr. 9",
      "id": "44ff6400c479d6a605f57d3a0cdcfb7f"
    },
    {
      "zip": "97080",
      "city": "Würzburg",
      "street": "Schürerstr. 9A",
      "id": "def96af694493934c920717ad87e5711"
    },
    {
      "zip": "97318",
      "city": "Kitzingen",
      "street": "Mozartstr. 12",
      "id": "34f7a5aedce0ba456c709109b35936c7"
    },
    {
      "zip": "97421",
      "city": "Schweinfurt",
      "street": "Lange Zehntstr. 1",
      "id": "217b451dd2c119226f8ae548003f77d9"
    },
    {
      "zip": "97421",
      "city": "Schweinfurt",
      "street": "Lange Zehntstr. 14",
      "id": "58489a8c7991da404f7a9ae89a0b6632"
    },
    {
      "zip": "97421",
      "city": "Schweinfurt",
      "street": "Schopperstr. 33",
      "id": "7b8d95342748d99285ba7a112744c03f"
    },
    {
      "zip": "97424",
      "city": "Schweinfurt",
      "street": "Amsterdamstr. 4",
      "id": "b135bf72617a613cac123c25dc7787b5"
    },
    {
      "zip": "97424",
      "city": "Schweinfurt",
      "street": "Stresemannstr. 14-16",
      "id": "ab05051c08adef17c71d1bb45deb92b5"
    },
    {
      "zip": "97440",
      "city": "Werneck",
      "street": "Rudolf-Diesel-Str. 6",
      "id": "9ee47a518bcfd8749a17ac49914de3dc"
    },
    {
      "zip": "97520",
      "city": "Röthlein",
      "street": "Spiesheimerweg 15",
      "id": "673835371fe081de1bf6dd7d07010cb4"
    },
    {
      "zip": "97616",
      "city": "Bad Neustadt",
      "street": "Am Zollberg 8",
      "id": "de81e6cf326a25c7b1695bb86ea56e20"
    },
    {
      "zip": "97688",
      "city": "Bad Kissingen",
      "street": "Martin-Luther-Str. 6",
      "id": "9eddec18c09d34e93901800df77c933d"
    },
    {
      "zip": "97737",
      "city": "Gemünden",
      "street": "Friedenstr. 23",
      "id": "c582ee8496f3556716437506d3aecbb3"
    },
    {
      "zip": "97769",
      "city": "Bad Brückenau",
      "street": "Zum Alten Fc-Sportplatz 1",
      "id": "bee77a2eac5ddd879fc0b6c87a43dc7a"
    },
    {
      "zip": "97941",
      "city": "Tauberbischofsheim",
      "street": "Hauptstr. 45",
      "id": "68343afe78bc64c80449e6cf28ab3311"
    },
    {
      "zip": "97941",
      "city": "Tauberbischofsheim",
      "street": "Schmiederstr. 17",
      "id": "98751a22de1a603100bf40ff7bfff29c"
    },
    {
      "zip": "97980",
      "city": "Bad Mergentheim",
      "street": "Max-Planck-Str. 12",
      "id": "e58a460982745c74d0d58b28e93a8141"
    },
    {
      "zip": "98527",
      "city": "Suhl",
      "street": "Bahnhofstr. 3",
      "id": "14849d7cc6a0feebdc6d643277731da3"
    },
    {
      "zip": "98527",
      "city": "Suhl",
      "street": "Schleusinger Str. 2-8",
      "id": "235e2196032c70591358f7bbd27a098c"
    },
    {
      "zip": "98527",
      "city": "Suhl",
      "street": "Schleusinger Str. 6-8",
      "id": "d2b893e091878baadec2db6881e60f68"
    },
    {
      "zip": "98574",
      "city": "Schmalkalden",
      "street": "Am Eichelbach 2",
      "id": "e6d2d181260669c262e7d8549e7ece67"
    },
    {
      "zip": "98617",
      "city": "Meiningen",
      "street": "Leipziger Str. 68A",
      "id": "9dd65bf61ba63cb1c4938e13b1008aad"
    },
    {
      "zip": "98646",
      "city": "Hildburghausen",
      "street": "Waldstr. 3",
      "id": "12c6425bb00e84c7caf24709db35b5dc"
    },
    {
      "zip": "98693",
      "city": "Ilmenau",
      "street": "Mittelfeld 2",
      "id": "9d9c275e220c7c0769b240f02d48bfad"
    },
    {
      "zip": "98724",
      "city": "Neuhaus",
      "street": "Am Herrnberg 23",
      "id": "630e850cfaa65b27ed568c480263ea87"
    },
    {
      "zip": "99084",
      "city": "Erfurt",
      "street": "Andreasstr. 38",
      "id": "a348710833bb204bb55d14fc54014b67"
    },
    {
      "zip": "99084",
      "city": "Erfurt",
      "street": "Andreasstr. 38A",
      "id": "a3cbfb8f0f260936c5929710baffd6b6"
    },
    {
      "zip": "99084",
      "city": "Erfurt",
      "street": "Anger 13",
      "id": "3cdf65d3f9eebc14fac33722e6d9aea2"
    },
    {
      "zip": "99084",
      "city": "Erfurt",
      "street": "Hirschlach Ufer 1",
      "id": "e000926746aa4629795d104fae8df18f"
    },
    {
      "zip": "99084",
      "city": "Erfurt",
      "street": "Juri-Gagarin-Ring 105-107",
      "id": "1a2daf72492f2b05be589c6e800bcaf3"
    },
    {
      "zip": "99091",
      "city": "Erfurt",
      "street": "Mühlweg 16",
      "id": "af0e1745eaf0f74fce4adb072d2a0c70"
    },
    {
      "zip": "99091",
      "city": "Erfurt",
      "street": "Nordhäuser Str. 73T",
      "id": "aaca2fa3d8640142569ec269366b4568"
    },
    {
      "zip": "99310",
      "city": "Arnstadt",
      "street": "Karl-Liebknecht-Str. 61",
      "id": "ecacd73687fa37b3d1207bbd15ce6a68"
    },
    {
      "zip": "99423",
      "city": "Weimar",
      "street": "Goetheplatz 7-8",
      "id": "086a0233ae0acb3d59a2cec67b75f953"
    },
    {
      "zip": "99427",
      "city": "Weimar",
      "street": "Ettersburger Str. 40",
      "id": "dcd3c7029f3ac9d5de8a79f21b1a7a53"
    },
    {
      "zip": "99610",
      "city": "Sömmerda",
      "street": "Strasse Des Aufbaus 29",
      "id": "cea6f86f6307124cdc0371e7bd7f094d"
    },
    {
      "zip": "99610",
      "city": "Sömmerda",
      "street": "Straße Des Aufbaus 29",
      "id": "e7ca0dc05d4f2164bc2b42a5d6385765"
    },
    {
      "zip": "99706",
      "city": "Sondershausen",
      "street": "Parkstr. 1",
      "id": "4f9af92447e297165ad5162e9f17ed3f"
    },
    {
      "zip": "99734",
      "city": "Nordhausen",
      "street": "Wilhelm-Carl-Schreiber-Str. 3",
      "id": "bfc50f506951b71ba2919713ab176f3a"
    },
    {
      "zip": "99817",
      "city": "Eisenach",
      "street": "Alexanderstr. 3-11",
      "id": "3c2fecdb6cb8f8983bf67dccd03298cd"
    },
    {
      "zip": "99817",
      "city": "Eisenach",
      "street": "Ernst-Thälmann-Str. 53-57",
      "id": "945e5e3df083ae646f3d8805150f9c39"
    },
    {
      "zip": "99817",
      "city": "Eisenach",
      "street": "Karlstr. 7",
      "id": "4fa905df5378313a2c2eea90acbad220"
    },
    {
      "zip": "99867",
      "city": "Gotha",
      "street": "Kantstr. 2",
      "id": "742ea048aa3b5f2295138ae74bba9812"
    },
    {
      "zip": "99867",
      "city": "Gotha",
      "street": "Marktstr. 17",
      "id": "b9c33f0576bc1ed62713f9072a86abac"
    },
    {
      "zip": "99947",
      "city": "Bad Langensalza",
      "street": "Gothaer Landstr. 17",
      "id": "9ed75b7135d513efb89a6320ce0170d9"
    },
    {
      "zip": "99974",
      "city": "Mühlhausen",
      "street": "An Der Burg 1",
      "id": "8aa7f42f962f3d214a42187707d3927a"
    }
  ];
