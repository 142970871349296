import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview.component';
import { FilterModule } from '../../components/filter/filter.module';
import { CardModule } from 'primeng/card';
import { OfficeTableModule } from '../../components/office-table/office-table.module';
import { PipeModule } from '../../pipes/pipe.module';
import { CardComponent } from '../../components/card/card.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'primeng/chart';
import { TransportCellComponent } from '../../components/transport-cell/transport-cell.component';
import { SkeletonModule } from 'primeng/skeleton';
import { LoaderModule } from '../../components/loader/loader.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [OverviewComponent, CardComponent, TransportCellComponent],
  exports: [OverviewComponent],
  imports: [
    CommonModule,
    FilterModule,
    CardModule,
    OfficeTableModule,
    PipeModule,
    TranslateModule,
    ChartModule,
    SkeletonModule,
    LoaderModule,
    RouterModule,
  ],
})
export class OverviewModule {}
