<div class="wrapper">
    <div class="header">
        <input type="checkbox" class="dashboard-checkbox" (click)="selectAllOptions()" [(ngModel)]="generalFilter">
        <input type="text" class="dashboard-input" placeholder="{{ 'common.search' | translate }}"
            [ngModel]="filterInput" (ngModelChange)="onFilterUpdate($event)">
        <app-close-button (click)="exit()"></app-close-button>
    </div>
    <div class="list" *ngFor="let option of filteredOptions">
        <input type="checkbox" class="dashboard-checkbox" (click)="selectOption(option)"
            [checked]="optionIsChecked(option)">
        <label>{{option}}</label>
    </div>
</div>