import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rating-configurator',
  templateUrl: './rating-configurator.component.html',
  styleUrls: ['./rating-configurator.component.scss']
})
export class RatingConfiguratorComponent implements OnInit {
  @Input() identifier: number = 0;
  @Input() value: any = null;
  @Input() valid: boolean = true;
  @Output() ratingDefined = new EventEmitter<any>();
  ratingStyle: string = 'stars';
  numberOfOptions: number; 
  questionDe: string = '';
  questionEn: string = '';
  minValueLabelDe: string = '';
  maxValueLabelDe: string = '';
  minValueLabelEn: string = '';
  maxValueLabelEn: string = '';
  isMandatory: boolean = false;

  ngOnInit() {
    if (this.value.options) {
      // this.identifier++;
      this.ratingStyle = this.value.options.style;
      this.numberOfOptions = this.value.options.count;
      this.isMandatory = this.value.required;

      // Check if 'de' and 'en' keys exist in the 'label' map
      if (this.value.label && this.value.label.has('de')) {
        this.questionDe = this.value.label.get('de');
      }

      if (this.value.label && this.value.label.has('en')) {
        this.questionEn = this.value.label.get('en');
      }
      // Check and access 'min' and 'max' labels for 'de' and 'en'
      if (this.value.options.labels && this.value.options.labels.min) {
        this.minValueLabelDe = this.value.options.labels.min.de;
        this.minValueLabelEn = this.value.options.labels.min.en;
      }

      if (this.value.options.labels && this.value.options.labels.max) {
        this.maxValueLabelDe = this.value.options.labels.max.de;
        this.maxValueLabelEn = this.value.options.labels.max.en;
      }
    }
    else {
      this.ratingStyle = this.value;
      this.numberOfOptions = 5;
    }
  }


  ratingQuestionAdd(e: any, l10n: string) {
    if (l10n == 'de') {
      this.questionDe = e.detail.value;
    }
    if (l10n == 'en') {
      this.questionEn = e.detail.value;
    }
    this.ratingDefined.emit(this.getRatingParams());
  }

  isValidText(text: string): boolean {
    // regular expression to check if the input contains only letters
    const regex = /\D/;
    return text === '' || regex.test(text);
  }

  maxValueAdd(e: any, l10n: string) {
    if (l10n == 'de') {
      this.maxValueLabelDe = e.detail.value;
    }
    if (l10n == 'en') {
      this.maxValueLabelEn = e.detail.value;
    }
    this.ratingDefined.emit(this.getRatingParams());
  }

  minValueAdd(e: any, l10n: string) {
    if (l10n === 'de') {
      this.minValueLabelDe = e.detail.value;
    }
    if (l10n === 'en') {
      this.minValueLabelEn = e.detail.value;
    }
    this.ratingDefined.emit(this.getRatingParams());
  }

  numberOfOptionsChange(e: any) {
    this.numberOfOptions = e.detail.value;
    this.ratingDefined.emit(this.getRatingParams());

  }
  ratingStyleChange(e: any) {
    this.ratingStyle = e.detail.value;
    this.ratingDefined.emit(this.getRatingParams());

  }

  isMandatoryOnChange(e) {
    this.isMandatory = e.detail.checked;
    this.ratingDefined.emit(this.getRatingParams());
  }


  private getRatingParams() {
    return {
      identifier: 'rating-' + this.identifier,
      label: new Map<string, string>([
        ['de', this.questionDe],
        ['en', this.questionEn],
      ]),
      options: {
        max_length: 520,
        style: this.ratingStyle,
        count: this.numberOfOptions,
        labels: new Map<string, Map<string, string>>([
          ['min', new Map<string, string>([['de', this.minValueLabelDe], ['en', this.minValueLabelEn]])],
          ['max', new Map<string, string>([['de', this.maxValueLabelDe], ['en', this.maxValueLabelEn]])],
        ]),

        values: null,
      },
      type: 'rating',
      required: this.isMandatory,
    };
  }
}
