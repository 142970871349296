<div class="how-it-works-section">

    <div class="landing-page-wrapper">
        <h3>{{'landing.howItWorks'|translate}}</h3>

        <div class="entries">
            <div class="entry" *ngFor="let applicationStep of applicationSteps; let index = index">
                <div class="hero-text">
                    <h2 class="step">{{index+1}}</h2>
                    <section class="text">
                        <h4 class="heading">{{applicationStep.heading}}</h4>
                        <p class="description">{{applicationStep.description}}</p>
                    </section>
                </div>
                <section class="image" *ngIf="isMobile()">
                    <img class="icon" [src]="applicationStep.iconPath" [class.large-icon]="index==2">
                    <div class="notes" *ngIf="hasNote(applicationStep)">
                        <div class="note" (click)="openVideoPopup()">
                            <img class="bubble-image" [src]="bubbleImage">
                            <div class="absolute-element">
                                <img [src]="playButton">
                                <p>{{applicationStep.notes[0]}}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="how-it-works-image-wrapper" *ngIf="!isMobile()">
            <img class="how-it-works-image" [src]="howItWorksImage">
            <div class="notes" *ngIf="applicationSteps.length > 0 && hasNote(applicationSteps[0])">
                <div class="note" (click)="openVideoPopup()">
                    <div class="absolute-element">
                        <p>{{applicationSteps[0].notes[0]}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>