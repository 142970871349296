import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { DropDownButtonComponent } from './components/drop-down-button/drop-down-button.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ChipComponent } from './components/chip/chip.component';
import { TableDialogComponent } from './components/table-dialog/table-dialog.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from './components/text/text.component';
import { IconModule } from './components/icon/icon.module';
import {
  DateInputComponent,
  MY_FORMATS,
} from './components/date-input/date-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import {
  MatDatepickerModule,
  matDatepickerAnimations,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { LicenseStatusComponent } from './components/license-status/license-status.component';
import { IconTextInputComponent } from './components/icon-text-input/icon-text-input.component';
import { DirectivesModule } from '../directives/directives.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    HeaderComponent,
    ButtonComponent,
    DropDownButtonComponent,
    TextButtonComponent,
    CloseButtonComponent,
    DashboardTableComponent,
    FilterDialogComponent,
    LoaderComponent,
    ChipComponent,
    TableDialogComponent,
    TextInputComponent,
    TextComponent,
    DateInputComponent,
    SelectInputComponent,
    LicenseStatusComponent,
    IconTextInputComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    IconModule,
  ],
  exports: [
    HeaderComponent,
    ButtonComponent,
    DropDownButtonComponent,
    TextButtonComponent,
    CloseButtonComponent,
    DashboardTableComponent,
    LoaderComponent,
    ChipComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    LicenseStatusComponent,
    IconTextInputComponent,
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
