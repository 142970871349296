<div style="position: relative; height: 100%">
  <p-card [ngClass]="{'overlay-card': hover, 'card-relative': !hover}">
    <ng-template pTemplate="header">
      <div class="container-padding">
        <div class="p-grid no-margin p-ai-center p-jc-center">
          <div class="p-col-fixed icon">
            <img [src]="imgSrc" [alt]="imgAlt">
          </div>
          <div class="p-col" style="padding: 0">
            <h3 class="no-margin">{{header}}</h3>
          </div>
          <div class="p-col-fixed" *ngIf="showToggle && transportTypesCont > 6">
            <button *ngIf="hover" class="close-button" (click)="setHover(false)">
              <span class="pi pi-times"></span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="!(loaderService.isLoading | async); else skeleton"
      class="p-grid no-padding-up-down p-align-stretch bottom-spacing">
      <ng-content select="ng-container"></ng-content>

    </div>
    <button *ngIf="showToggle && transportTypesCont > 6 && !hover" class="all-toggle p-align-self-end"
      (click)="setHover(true)"><i class="pi pi-eye"></i><span>{{ 'cards.see-all-toggle' |
        translate}}</span></button>
  </p-card>
</div>

<ng-template #skeleton>
  <app-loader skeletonHeight="50px"></app-loader>
</ng-template>