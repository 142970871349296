import { TransportType } from './enums/transport-type.enum';
import { Reason } from './enums/reason.enum';
import { Office } from './office';
import { Company } from './company';

export interface ReportGrouped {
  weekday?: string;
  month?: string;
  distance: number;
  time: number;
  transportType?: TransportType;
  autoOptinCount?: number;
  reportCount: number;
  emissions: number;
  reason?: Reason;
  office?: Office;
  company?: Company;
}

export function fromDto(
  dto: ReportGroupedDto,
  office: Office,
  company: Company
): ReportGrouped {
  return {
    ...dto,
    office,
    company,
  };
}

export interface ReportGroupedDto {
  weekday: string;
  month: string;
  distance: number;
  time: number;
  transportType: TransportType;
  autoOptinCount: number;
  reportCount: number;
  emissions: number;
  reason: Reason;
  office: string;
  company: string;
}
