import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

import { FormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AppComponent } from './app.component';
import { DashboardTitleModule } from './components/dashboard-title/dashboard-title.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AdminDashboardGuard } from './guards/admin-dashboard.guard';
import { LoadingInterceptor } from './interceptors/loader-interceptor.service';
import { TokenInterceptor } from './interceptors/token-interceptor.service';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DetailComponent } from './pages/detail/detail.component';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { OverviewModule } from './pages/overview/overview.module';
import { TrendsModule } from './pages/trends/trends.module';
import { PipeModule } from './pipes/pipe.module';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { msalConfig } from './authentication/auth-config';
import { DirectivesModule } from './directives/directives.module';
import { HttpRequestInterceptor } from './interceptors/cache-interceptor.service';
import { AdminModule } from './pages/admin/admin.module';
import { ApiDocumentationModule } from './pages/api-documentation/api-documentation.module';
import { ChallengesModule } from './pages/challenges/challenges.module';
import { DataPrivacyPageComponent } from './pages/data-privacy-page/data-privacy-page.component';
import { FeedbackModule } from './pages/feedback/feedback.module';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';
import { ExplanationSectionComponent } from './pages/landing-page/explanation-section/explanation-section.component';
import { IntroSectionComponent } from './pages/landing-page/intro-section/intro-section.component';
import { LandingFooterComponent } from './pages/landing-page/landing-footer/landing-footer.component';
import { LandingHeaderComponent } from './pages/landing-page/landing-header/landing-header.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { OutroSectionComponent } from './pages/landing-page/outro-section/outro-section.component';
import { VideoSectionComponent } from './pages/landing-page/video-section/video-section.component';
import { WhySectionComponent } from './pages/landing-page/why-section/why-section.component';
import { ManageModule } from './pages/manage/manage.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { IconModule } from './shared/components/icon/icon.module';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  protectedResourceMap.set('/api/dashboard-info', null);
  protectedResourceMap.set('/api/*', [
    'api://1846eefe-5a38-4c9d-aa6c-80227245488e/Reports.UserRead',
    'api://1846eefe-5a38-4c9d-aa6c-80227245488e/Admin.DoAll',
    'group.Read.All',
  ]);

  //  protectedResourceMap.set('https://jsonplaceholder.typicode.com/', [
  //    'api://custom api consent'
  //  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent,
    DetailComponent,
    HeaderComponent,
    FooterComponent,
    DataPrivacyPageComponent,
    ImprintPageComponent,
    WelcomeComponent,
    LandingPageComponent,
    IntroSectionComponent,
    ExplanationSectionComponent,
    VideoSectionComponent,
    WhySectionComponent,
    OutroSectionComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DashboardModule,
    BreadcrumbModule,
    HttpClientModule,
    PipeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DashboardTitleModule,
    TabViewModule,
    OverviewModule,
    TrendsModule,
    NotFoundModule,
    ToggleButtonModule,
    FormsModule,
    MsalModule,
    ApiDocumentationModule,
    YouTubePlayerModule,
    SharedModule,
    ChallengesModule,
    FeedbackModule,
    ManageModule,
    AdminModule,
    DirectivesModule,
    AngularSvgIconModule.forRoot({}),
    IconModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },

    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AdminDashboardGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
