import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { TableData, TableRow } from '../../../models/table-data';
import {
  FilterDialogComponent,
  SelectedOption,
  SelectedOptions,
} from '../filter-dialog/filter-dialog.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent implements OnInit, OnDestroy {
  @Input() tableData: Observable<TableData>;
  @Input() customButtonText: string;
  @Input() customButtonIcon: string;
  @Input() customIsTextButton: boolean = false;

  @Input() secondCustomButtonText: string;
  @Input() secondCustomButtonIcon: string;
  @Input() secondCustomIsTextButton: boolean = false;
  @Input() elementsPerView: number = -1;

  @Output() editPressed: EventEmitter<any> = new EventEmitter();
  @Output() deletePressed: EventEmitter<any> = new EventEmitter();
  @Output() customPressed: EventEmitter<any> = new EventEmitter();
  @Output() secondCustomPressed: EventEmitter<any> = new EventEmitter();
  @Output() onSelectOption: EventEmitter<any> = new EventEmitter();

  public filterInput: string = '';
  public generalFilter: boolean = false;

  @ViewChild(FilterDialogComponent)
  filterDialogComponent: FilterDialogComponent;

  @ViewChild('filterDialogElement', { read: ElementRef })
  filterDialogElement: ElementRef;

  public data: TableData;
  public viewModel: TableData;
  public appliedFilters: SelectedOptions[] = [];

  private isOrderedAscending = false;
  private tableDataSubscription: Subscription;
  showFilter: boolean = false;
  isDialogOpening = false;

  public usePages: boolean = false;
  public hasData: boolean = false;

  public pages: TableRow[][] = [];
  public selectedPage: number = 0;

  public codes: string[] = [];
  public selectedCodes: string[] = [];
  public filteredOptions: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.tableDataSubscription = this.tableData.subscribe((recievedData) => {
      this.codes = recievedData.tableRows.map(
        (rows) => rows.data[0].text as string
      );
      this.filteredOptions = this.codes;

      this.data = recievedData;
      this.viewModel = { ...this.data };
      this.hasData = true;
      this.usePages = recievedData.pagination;
      this.initializePages(this.viewModel.tableRows);
    });

    this.isOrderedAscending = false;
  }

  ngOnDestroy(): void {
    this.tableDataSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (this.showFilter) {
      const dialogElement = this.filterDialogElement.nativeElement;
      const isClickedInsideComponent = dialogElement.contains(
        event.target as Node
      );

      // Check if the dialog is not in the process of opening or closing
      if (!this.isDialogOpening && !isClickedInsideComponent) {
        // Clicked outside the component, so hide it
        this.showFilter = false;
      } else {
        // Reset the flag after handling the click
        this.isDialogOpening = false;
      }
    }
  }

  toggleFilter() {
    if (!this.showFilter) {
      // Dialog is about to open, set the flag
      this.isDialogOpening = true;
    } else {
      // Dialog is about to close, reset the flag
      this.isDialogOpening = false;
    }

    this.showFilter = !this.showFilter;
  }

  private initializePages(tableRows: TableRow[]) {
    if (this.usePages) {
      this.selectedPage = 0;
      let pageIndex = 0;
      this.pages = [];
      for (let tableRow of tableRows) {
        if (!this.pages[pageIndex]) this.pages[pageIndex] = [];

        if (this.pages[pageIndex].length == this.elementsPerView) {
          pageIndex++;
          this.pages[pageIndex] = [];
        }

        this.pages[pageIndex].push(tableRow);
      }
      if (this.pages && this.pages[this.selectedPage]) {
        this.filteredOptions = this.pages[this.selectedPage].map(
          (row) => row.data[0].text as string
        );
      }
    }
  }

  public emitEditPressed(event) {
    this.editPressed.emit(
      this.usePages
        ? this.pages[this.selectedPage][event]
        : this.viewModel.tableRows[event]
    );
  }

  public emitDeletePressed(event) {
    this.deletePressed.emit(
      this.usePages
        ? this.pages[this.selectedPage][event]
        : this.viewModel.tableRows[event]
    );
  }
  public emitCustomPressed(index) {
    const element = this.usePages
      ? this.pages[this.selectedPage][index]
      : this.viewModel.tableRows[index];
    if (this.selectedCodes.length > 0) {
      const codes = [...this.selectedCodes];
      const code = element.data[0].text as string;
      if (!this.selectedCodes.includes(code)) {
        codes.push(code);
      }
      this.customPressed.emit({ codes: codes });
      return;
    }
    this.customPressed.emit(element);
  }
  public emitSecondCustomPressed(index) {
    const element = this.usePages
      ? this.pages[this.selectedPage][index]
      : this.viewModel.tableRows[index];
    if (this.selectedCodes.length > 0) {
      const codes = [...this.selectedCodes];
      const code = element.data[0].text as string;
      if (!this.selectedCodes.includes(code)) {
        codes.push(code);
      }
      this.secondCustomPressed.emit({ codes: codes });
      return;
    }
    this.secondCustomPressed.emit(element);
  }
  onSort(columnIndex: number): void {
    this.isOrderedAscending = !this.isOrderedAscending;
    const sorted = (
      this.usePages ? this.pages.flat() : this.viewModel.tableRows
    ).sort((a, b) => {
      const aTitle = (a.data[columnIndex].text as string) ?? '';
      const bTitle = (b.data[columnIndex].text as string) ?? '';
      return this.isOrderedAscending
        ? aTitle.localeCompare(bTitle)
        : bTitle.localeCompare(aTitle);
    });
    this.initializePages(sorted);
  }

  updateSelectedOptions(options: SelectedOptions, columnIndex: number) {
    const originalTableRows: TableRow[] = [...this.data.tableRows];

    const selectedOptions = options.selectedOptions;
    ({ selectedOptions });

    const dialogRef = options.dialogReference;

    const filtered = this.appliedFilters.filter(
      (selectOption) => selectOption.dialogReference === dialogRef
    );

    const chip: SelectedOptions | undefined =
      filtered !== undefined ? filtered[0] : undefined;
    if (chip === undefined) {
      this.appliedFilters.push(options);
    } else {
      this.appliedFilters.splice(this.appliedFilters.indexOf(chip));
      this.appliedFilters.push(chip);
    }

    if (selectedOptions.length == 0) {
      this.appliedFilters = [];
    }

    let rows: TableRow[] = [];

    if (selectedOptions.length == 0) {
      rows = originalTableRows;
      this.viewModel.tableRows = rows;
      this.initializePages(rows);
      return;
    }

    rows = originalTableRows.filter((tableRow) => {
      return selectedOptions.includes(
        tableRow.data[columnIndex].text as string
      );
    });

    if (this.usePages) {
      this.initializePages(rows);
      return;
    }

    this.viewModel.tableRows = rows;
  }

  getFilterOptions(columnIndex: number) {
    const columnEntries: string[] = [];
    for (let row of this.data.tableRows) {
      for (let i = 0; i < row.data.length; i++) {
        if (columnIndex === i) {
          columnEntries.push(row.data[i].text as string);
        }
      }
    }
    return columnEntries.filter(
      (value, index, self) => self.indexOf(value) === index
    );
  }

  // showFilterDialog() {
  //   this.showFilter = true;
  // }

  // closeFilterDialog() {
  //   this.showFilter = false;
  // }

  hasFilterOptions() {
    return this.data.columnHeaders.some((header) => header.filterable);
  }

  selectedOptionsToOption(options: SelectedOptions): SelectedOption[] {
    const optionList: SelectedOption[] = [];
    for (let option of options.selectedOptions) {
      optionList.push({
        selectedOption: option,
        dialogReference: options.dialogReference,
      });
    }
    return optionList;
  }

  testEventMEthod(event) {}

  removeOption(option: SelectedOption) {
    if (option) {
      option.dialogReference.deSelectOption(option.selectedOption);
    }
    const selectedOptions: string[] = [].concat.apply(
      [],
      this.appliedFilters.map((options) => options.selectedOptions)
    );
    if (selectedOptions.length == 0) {
      this.appliedFilters = [];
    }
  }

  deSelectAllFilters() {
    for (let filter of this.appliedFilters) {
      for (let selectedOption of filter.options) {
        filter.dialogReference.deSelectOption(selectedOption);
      }
    }
    this.appliedFilters = [];
  }

  hasPreviousPage(): boolean {
    return this.selectedPage > 0;
  }

  hasNextPage(): boolean {
    return this.selectedPage < this.pages.length - 1;
  }

  selectFirstPage() {
    if (this.selectedPage > 0) {
      this.selectedPage = 0;
      this.onPageChange();
    }
  }

  selectPreviousPage() {
    if (this.selectedPage > 0) {
      this.selectedPage--;
      this.onPageChange();
    }
  }

  selectNextPage() {
    if (this.selectedPage < this.pages.length - 1) {
      this.selectedPage++;
      this.onPageChange();
    }
  }

  selectLastPage() {
    if (this.selectedPage < this.pages.length - 1) {
      this.selectedPage = this.pages.length - 1;
      this.onPageChange();
    }
  }

  onPageChange() {
    this.filteredOptions = this.pages[this.selectedPage].map(
      (row) => row.data[0].text as string
    );
  }

  public deSelectOption(option: string) {
    this.selectedCodes.splice(this.selectedCodes.indexOf(option), 1);
    if (this.selectedCodes.length === 0) {
      this.generalFilter = false;
    }
    this.onSelectOption.emit({
      dialogReference: this,
      selectedOptions: this.selectedCodes,
      options: this.codes,
    });
  }

  public deSelectAllOptions() {
    this.selectedCodes = [];
    this.generalFilter = false;
    this.onSelectOption.emit({
      options: this.codes,
      selectedOptions: this.selectedCodes,
      dialogReference: this,
    });
  }

  public selectAllOptions() {
    const missing: string[] = this.filteredOptions.filter(
      (option) => !this.selectedCodes.includes(option)
    );

    if (missing.length == 0) {
      for (let i = this.selectedCodes.length - 1; i >= 0; i--) {
        const option = this.selectedCodes[i];
        if (this.filteredOptions.includes(option)) {
          this.selectedCodes.splice(i, 1);
        }
      }
    } else {
      if (this.generalFilter) {
        this.selectedCodes = [];
      } else {
        this.selectedCodes.push(...this.filteredOptions);
      }
    }

    this.selectedCodes = [...new Set(this.selectedCodes)];

    this.generalFilter = !this.generalFilter;
    this.onSelectOption.emit({
      options: this.codes,
      selectedOptions: this.selectedCodes,
      dialogReference: this,
    });
  }

  public optionIsChecked(option: string) {
    return this.selectedCodes.includes(option);
  }

  public selectOption(option: string) {
    if (this.selectedCodes.includes(option)) {
      this.selectedCodes.splice(this.selectedCodes.indexOf(option), 1);
      if (this.selectedCodes.length == 0) {
        this.generalFilter = false;
      }
    } else {
      this.selectedCodes.push(option);
      if (this.selectedCodes.length === this.codes.length) {
        this.generalFilter = true;
      }
    }
    this.onSelectOption.emit({
      options: this.codes,
      selectedOptions: this.selectedCodes,
      dialogReference: this,
    });
  }
}
