import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { APIConst } from '../../models/enums/api.enum';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DashboardInfo } from '../../models/dashboard-info';
import packageJson from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class DashboardInfoHttpService {
  constructor(private apiService: ApiService, private http: HttpClient) {}
  getDashboardInfo(): Observable<DashboardInfo> {
    return this.apiService
      .get({
        path: `${APIConst.DASHBOARD_INFO}`,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map((dashboardInfo) => {
          return {
            backendVersion: dashboardInfo.backendVersion,
            frontendVersion: packageJson.version,
            environment: environment.production ? 'Prod' : 'Dev',
          } as DashboardInfo;
        })
      );
  }
}
