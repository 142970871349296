import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, takeUntil } from 'rxjs/operators';
import { OfficeService } from 'src/app/services/office.service';
import { UnitConverter } from 'src/app/utils/unit-converter';
import { EmissionsPercentage } from '../../models/const/charts-consts';
import { EmissionsField } from '../../models/const/common';
import { Reason } from '../../models/enums/reason.enum';
import { ReportService } from '../../services/report.service';
import { ReportUtils } from '../../utils/report-utils';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  @Input() officeId: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  groupedFilter;

  reasons = Reason;
  private _total: number;
  private _business: number;
  businessPercentage: string;
  private _commute: number;
  commutePercentage: string;
  chartData: any;
  chartsOptions;
  hovering: boolean;

  demo: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public reportService: ReportService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private officeService: OfficeService
  ) {
    registerLocaleData(de);
    registerLocaleData(en);
    // @ts-ignore
    Chart.registry.getPlugin('tooltip').positioners.custom = (
      elements,
      eventPosition
    ) => {
      return {
        x: eventPosition.x,
        y: eventPosition.y - 50,
      };
    };
    this.route.data.subscribe((data) => {
      this.demo = data['demo'];
    });
    this.chartsOptions = EmissionsPercentage(this.translateService.currentLang);
    this.groupedFilter = this.formBuilder.group(
      {
        from: null,
        to: null,
        transportType: null,
        tripDistance: null,
        tripTime: null,
        tripReason: null,
        company: null,
        autoRecorded: false,
        homeOffice: false,
        causedByCompanyCar: 'All',
        office: null,
        groupBy: new UntypedFormControl([
          'reason',
          'transportType',
          'office',
          'company',
        ]),
      },
      {
        validators: [
          (control: AbstractControl): ValidationErrors | null => {
            const from = control.get('from');
            const to = control.get('to');
            return (from.value === null && to.value === null) ||
              (from.value && to.value && from.value <= to.value)
              ? null
              : { dateNotValid: true };
          },
        ],
      }
    ) as FormGroup;
  }

  ngOnInit(): void {
    this.officeService.dashboardOffices.subscribe();
    if (this.officeId) {
      this.groupedFilter.controls.office.setValue({ id: this.officeId });
    }
    this.reportService.filterReportsByOffice(this.groupedFilter, this.demo);

    this.groupedFilter.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe(() => {
        if (this.groupedFilter.valid) {
          this.reportService.filterReportsByOffice(
            this.groupedFilter,
            this.demo
          );
        }
      });
    this.reportService.overviewReports$
      .pipe(
        filter((reports) => reports !== undefined),
        takeUntil(this.destroy$),
        delay(100)
      )
      .subscribe((reports) => {
        this._total = ReportUtils.sumByReason(
          reports,
          undefined,
          EmissionsField,
          false
        );

        this._commute = ReportUtils.sumByReason(
          reports,
          Reason.Commute,
          EmissionsField,
          false
        );
        this.commutePercentage =
          this._total === 0
            ? '0'
            : ((this._commute / this._total) * 100).toFixed(2);
        this._business = ReportUtils.sumByReason(
          reports,
          Reason.BusinessTrip,
          EmissionsField,
          false
        );
        this.businessPercentage =
          this._total === 0
            ? '0'
            : ((this._business / this._total) * 100).toFixed(2);
        this.chartData = {
          labels: ['Commute', 'Business trips'],
          datasets: [
            {
              data: [this._commute, this._business],
              borderWidth: 0,
              backgroundColor: ['#3D8CFF', '#00C3C1', '#aaaa00'],
              hoverBackgroundColor: ['#64B5F6', '#81C784', '#bbbb00'],
            },
          ],
        };
      });
  }

  formatValue(value: number) {
    return UnitConverter.getFormattedWeight(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onHoverChange(hovering: boolean): void {
    this.hovering = hovering;
  }
}
