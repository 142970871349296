<div class="drag-and-drop-area" [class.hasError]="hasError" (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)" data-allowed-types=".svg">
    <div class="content">
        <img [src]="!hasError ? 'assets/svg/document_upload.svg' : 'assets/svg/error.svg'">
        <p *ngIf="!hasError">{{'branding.sections.icon.fileSelectorDescription' | translate}}
            <a (click)="selectFile()">{{'branding.sections.icon.chooseFileAnchorText' | translate}}</a>
        </p>
        <p *ngIf="hasError">{{'branding.sections.icon.fileSelectorDescriptionError' | translate}}</p>

        <span>{{'branding.sections.icon.fileSelectorFormatText' | translate}}</span>
    </div>
</div>