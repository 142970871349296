import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication';
import { ApiRoleType } from '../models/enums/api-roles.enum';
import { DashboardComponent } from '../pages/dashboard/component/dashboard.component';
import { LoginComponent } from '../pages/login/login.component';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate() {
    const roles = this.authenticationService.getAccessTokenRoles();
    const containsAllowedClaims = [
      ApiRoleType.AdminAccess,
      ApiRoleType.DashboardAccess,
      ApiRoleType.DashboardAdmin,
      ApiRoleType.DashobardOwner,
      ApiRoleType.DashboardEditor,
    ].some((role) => roles.includes(role));

    if (containsAllowedClaims) {
      return true;
    }

    LocalStorageService.updatePreferedRoute(DashboardComponent.route);
    this.router.navigate([LoginComponent.route]);
    return false;
  }
}
