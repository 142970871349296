<div class="wrapper">
    <div class="header">
        <h4>{{tableDialogInput.dialogTitleTextKey | translate}}</h4>
        <app-close-button (click)="closeDialog()"></app-close-button>
    </div>
    <div class="content" *ngTemplateOutlet="dialogTemplate"></div>
    <div class="footer">
        <app-button [cancelButtonStyle]="true" (click)="closeDialog()"
            [text]="'common.cancel'  | translate"></app-button>
        <app-button [cancelButtonStyle]="!dataFilledIn()" [text]="tableDialogInput.confirmationTextKey  | translate "
            (click)="handleSubmit()"></app-button>
    </div>
</div>

<ng-template #single>
    <div class="single">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</ng-template>

<ng-template #grid>
    <div class="grid">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</ng-template>

<ng-template #column>
    <div class="column">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</ng-template>

<ng-template #content>
    <ng-container #vc></ng-container>
</ng-template>