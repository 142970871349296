<section class="survey-results p-col-12">
  <div class="margin-top-large container-survey-results">
    <h3 class="intro-survey">{{'feedbacks.title.surveys.results'|translate}}</h3>
    <scale-button variant="secondary" class="survey-button-add black-ghost-color"
      disabled="{{survey.responses.length < 5}}" size="large" (click)="openModal()">
      {{'feedbacks.title.buttons.show'|translate}}
    </scale-button>
  </div>
  <div *ngIf="loading">Loading...</div>
  <div *ngIf="error">Error loading survey results. Check the console for details.</div>
  <div *ngIf="surveyResults && survey.responses.length > 5; else dataMissing">

    <div *ngFor="let page of survey.pages; let i = index">
      <p class="page-header bold margin-top-40">
        {{page.section.get(currentLang)}}
      </p>
      <div *ngFor="let input of page.inputs; let j = index">
        <div class="inline margin-top-24">
          <p class="inline margin-right-small">{{j+1}}. </p>

          <div *ngIf="input.type === 'rating'">
            <p>{{ isMap(input.label) ? input.label.get(currentLang) : input.label[currentLang]}}</p>
            <p class="margin-top-small"><span class="average-symbol">⌀</span> {{
              +valueHandler(input,'ratings').toFixed(2) }} {{'feedbacks.title.rating.of'|translate}}
              {{input.options.count}}</p>
          </div>

          <div *ngIf="input.type === 'select'" class="margin-top">
            <p>{{ input.label.get(currentLang)}} {{input.required ? '*' :
              ''}}
            </p>
            <ng-container *ngIf="selectHandler(input, valueHandler(input, SELECTIONS_TYPE)) as handlerResult">
              <p class="margin-top-small">{{'feedbacks.title.select.type'|translate}}: {{input.options.style ==
                'checkbox-group' ? ('feedbacks.title.select.MultipleChoice'|translate) :
                ('feedbacks.title.select.SingleSelect'|translate) }}</p>
              <p class="margin-top-small">{{'feedbacks.title.select.AmountOfResponses'|translate}}: {{
                handlerResult.amountOfResponses }}</p>
              <p class="margin-top-small" *ngFor="let response of handlerResult.separateResponseWithCount">
                {{ response }}
              </p>
            </ng-container>

          </div>

          <div *ngIf="input.type === 'text'" class="margin-top">
            <p>{{ input.label.get(currentLang)}} {{input.required ? '*' :
              ''}}</p>
            <ng-container *ngIf="textInputMap && textInputMap.get(input.identifier)">

              <div class="text-response-container">
                <div class="text-responses">
                  <div class="response-with-button"
                    *ngFor="let textResponse of textInputMap.get(input.identifier).currentTiles">
                    <div class="response-container">
                      <label>{{textResponse.createdAt | date: 'dd.MM.yyyy hh:mm'}}</label>
                      <p>{{textResponse.textInput}}</p>
                    </div>
                    <scale-button (click)="showSingularModal(textResponse)"
                      class="black-ghost-color ghost-padding-reset" variant="ghost" [style.color]="'#000000'">
                      <scale-icon-action-show-password accessibility-title="show-password"
                        class="margin-right-small"></scale-icon-action-show-password>
                      {{'feedbacks.title.surveys.surveyPreviewSingle'|translate}}
                    </scale-button>
                  </div>
                </div>
                <scale-pagination page-size="3" class="scale-pagination-item" slot="action" start-element="0"
                  [attr.total-elements]="textInputMap.get(input.identifier).allTiles.length"
                  (scale-pagination)="onPageChange($event, input.identifier)">
                </scale-pagination>
              </div>
            </ng-container>

          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="modal-wripper">
    <div></div>
    <app-survey-preview-modal [showModal]="showModal" (isModalOpen)="closeModal($event)"
      [previewMode]="'Individual Response Preview'" [surveyResults]="surveyResults"
      [survey]="survey"></app-survey-preview-modal>
  </div>

  <div class="modal-wripper" *ngIf="isSingularModalOpen">
    <div></div>
    <app-survey-preview-modal [showModal]="isSingularModalOpen" (isModalOpen)="closeSingularModal($event)"
      [previewMode]="'Individual Response Preview'" [survey]="copiedSurvey"
      [isSinglePage]="true"></app-survey-preview-modal>
  </div>

  <ng-template #dataMissing>
    <div class="margin-top-large container-survey-results">
      <img [src]="'assets/svg/info_magenta.svg'">
      <p class="margin-left-small">{{'feedbacks.title.surveys.noData'|translate}}</p>
    </div>
  </ng-template>

</section>