import { APIConst } from './enums/api.enum';

const CachableRoutePatterns = {
  // '/api/supervisor/tenant': true,
};

[
  APIConst.SUPERVISOR_COMPANY,
  APIConst.SUPERVISOR_OFFICE,
  APIConst.GET_BRANDING,
  APIConst.GET_ACTIVATIONCODES,
]
  .map((e) => ({ [`/api${e}/:id`]: true }))
  .forEach((obj) => Object.assign(CachableRoutePatterns, obj));

export { CachableRoutePatterns };
