import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-question-configurator',
  templateUrl: './text-question-configurator.component.html',
  styleUrls: ['./text-question-configurator.component.scss']
})
export class TextQuestionConfiguratorComponent implements OnInit {
  @Input() identifier: number = 0;
  @Output() textQuestionDefined = new EventEmitter<any>();
  @Input() value: any = null;
  @Input() valid: boolean = true;


  isMandatory: boolean = false;
  questionDe: string = '';
  questionEn: string = '';

  ngOnInit() {
    if (this.value) {
      // this.identifier++;
      this.isMandatory = this.value.required;
      if (this.value.label && this.value.label.has('de')) {
        this.questionDe = this.value.label.get('de');
      }
      if (this.value.label && this.value.label.has('en')) {
        this.questionEn = this.value.label.get('en');
      }
    }
  }


  textQuestionAdd(e: any, l10n: string) {
    if (l10n == 'de') {
      this.questionDe = e.detail.value;
    }
    if (l10n == 'en') {
      this.questionEn = e.detail.value;
    }
    this.textQuestionDefined.emit(this.getRatingParams());
  }

  isMandatoryOnChange(e) {
    this.isMandatory = e.detail.checked;
    this.textQuestionDefined.emit(this.getRatingParams());
  }

  private getRatingParams() {
    return {
      identifier: 'text-' + this.identifier,
      label: new Map<string, string>([
        ['de', this.questionDe],
        ['en', this.questionEn],
      ]),
      options: {
        max_length: 4000,
        style: "text-area",
        count: null,
        labels: null,

        values: null,
      },
      type: 'text',
      required: this.isMandatory,
    };
  }

}


