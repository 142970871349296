<div class=" survey-tile-wrapper page-wrapper">
  <div>
    <div class="survey-tile">
      <div class="survey-tile-card">
        <div class="survey-tile-header  margin-bottom-large">
          <h2 class="bold">{{survey.title.get(currentLang)}} </h2>
          <div class="container-survey-status ">
            <span *ngIf="survey.isActive" class="survey-status-active">A{{'feedbacks.title.surveys.active'|translate}}</span>
            <span *ngIf="survey.isActive === false" class="survey-status-inactive">Ina{{'feedbacks.title.surveys.active'|translate}}</span>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-3 survey-tile-table-header">
            <p class="bold margin-bottom-small">{{'feedbacks.title.surveys.description'|translate}}</p>
            <p class="">{{ survey.description.get(currentLang) }}</p>
          </div>
          <div class="p-col-3 survey-tile-table-header">
            <div class="survey-tile-calendar margin-bottom-small">
              <scale-icon-content-calendar></scale-icon-content-calendar>
              <p class="bold margin-left-small"> {{'feedbacks.title.surveys.duration'|translate}}</p>
            </div>
            <p>{{ survey.startDate | date: 'dd.MM.yyyy' }} - {{survey.endDate | date: 'dd.MM.yyyy'}}</p>
          </div>
          <div class="p-col-3 survey-tile-table-header">
            <p class="bold margin-bottom-small">{{'feedbacks.title.surveys.companies'|translate}}</p>
            <p>{{ survey.companyNames.length ? survey.companyNames.join(', ') :
              'feedbacks.title.surveys.empty'|translate }}</p>
          </div>
          <div class="p-col-3 survey-tile-table-header">
            <div class="survey-tile-calendar margin-bottom-small">
              <scale-icon-user-file-user accessibility-title="user"></scale-icon-user-file-user>
              <p class="bold margin-left-small">{{'feedbacks.title.surveys.submits'|translate}}</p>
            </div>
            <h3>{{survey.numberOfSubmits}}</h3>
          </div>
        </div>
        <div>
          <scale-button variant="ghost" class="survey-button-add black-ghost-color margin-top-large"
            (click)="openDetails(survey)" icon-position="after" size="large">
            {{'feedbacks.title.buttons.details'|translate}} <scale-icon-navigation-right-condensed
              accessibility-title="right-condensed"></scale-icon-navigation-right-condensed>
          </scale-button>
        </div>
      </div>
    </div>