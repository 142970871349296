<div class="brand-section">
    <h4>{{sectionHeadlineTranslationKey | translate}}</h4>
    <div class="section-description"><img *ngIf="hasIcon" src="assets/images/branding/info.png">
        <p>{{sectionDescriptionTranslationKey | translate}}</p>
    </div>


    <div class="colors" *ngFor="let item of getSorting();let index = index">
        <span [style.grid-column]="1/2" *ngIf="hasDarkColors && index == 0">Light mode</span>
        <span [style.grid-column]="2/3" *ngIf="hasDarkColors && index == 0">Dark mode</span>
        <app-brand-card class="brand-card" [style.grid-column]="1/2" [color]="colors[item]" [key]="key"
            [disableDescription]="instanceOfSufaceColor(colors)"></app-brand-card>
        <app-brand-card class="brand-card" [style.grid-column]="2/3" *ngIf="colors[item].inverseColor !== undefined"
            [color]="colors[item]" [disableDescription]="instanceOfSufaceColor(colors)" [key]="key"
            [isInverse]="true"></app-brand-card>
    </div>
</div>