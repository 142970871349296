import { formatNumber, registerLocaleData } from '@angular/common';
import { UnitConverter } from 'src/app/utils/unit-converter';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';

export const WeeklyFilter = {
  'group-by': ['weekday', 'month'],
};

export const MonthlyFilter = {
  'group-by': ['month', 'reason', 'transport-type'],
};

export function checkTooltip(tooltipModel): HTMLElement {
  let tooltipEl = document.getElementById('chartjs-tooltip');
  // Tooltip Element
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.style.border = '1px solid black';
    tooltipEl.style.background = 'white';
    tooltipEl.style.position = 'fixed';
    tooltipEl.innerHTML = '<div class="p-grid no-margin p-ai-center"></div>';
    document.body.appendChild(tooltipEl);
  }
  if (tooltipModel.tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  } else {
    tooltipEl.style.opacity = '1';
  }
  return tooltipEl;
}

export function setPosition(tooltipModel, tooltipEl): void {
  const { x: positionX, y: positionY } =
    tooltipModel.chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltipModel.tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltipModel.tooltip.caretY + 'px';
  tooltipEl.style.font = tooltipModel.tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltipModel.tooltip.options.padding +
    'px ' +
    tooltipModel.tooltip.options.padding +
    'px';
}

export function EmissionsPercentage(currentLanguage): any {
  return {
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const value: string = body
              .slice(body.lastIndexOf(' ') + 1)
              .split('.')
              .join('');

            tooltipEl.querySelector('div').innerHTML =
              UnitConverter.getFormattedWeight(Number(value.replace(',', '.')));
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
  };
}

export function WeeklyEmissions(currentLanguage: string): any {
  return {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
          callback: (value) => {
            return UnitConverter.getFormattedWeight(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const value = body.split(' ');
            const amount: string = value[1].split('.').join('');

            tooltipEl.querySelector('div').innerHTML =
              value[0] +
              ' ' +
              UnitConverter.getFormattedWeight(
                Number(amount.replace(',', '.'))
              );
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
    },
  };
}
export function WeeklyDistance(currentLanguage: string): any {
  return {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
          callback: (value) => {
            return value + ' km';
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const value = body.split(' ');
            tooltipEl.querySelector('div').innerHTML =
              value[0] + ' ' + value[1] + ' km';
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
    },
  };
}

export function CommuteOptions(currentLanguage: string): any {
  return {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
          callback: (value) => {
            return UnitConverter.getFormattedWeight(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const value = body.split(' ');
            const amount: string = value[1].split('.').join('');

            tooltipEl.querySelector('div').innerHTML =
              value[0] +
              ' ' +
              UnitConverter.getFormattedWeight(
                Number(amount.replace(',', '.'))
              );
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      legend: {
        display: false,
      },
    },
  };
}

export function TransportPercentageOptions(
  currentLanguage: string,
  translations: any
): any {
  registerLocaleData(de);
  registerLocaleData(en);
  return {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        max: 100,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
          callback: (value) => {
            return value + ' %';
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const transportType = Object.keys(translations).find(
              (key) => translations[key] === body.split(':')[0]
            );
            if (
              !tooltipEl.querySelector('div').innerHTML.includes(transportType)
            ) {
              const imgDiv = document.createElement('div');
              imgDiv.innerHTML =
                '<img alt="" src="../../../assets/svg/icons/transport/' +
                transportType +
                '.svg">';
              const labelDiv = document.createElement('div');
              const bodyParts = body.split(':');

              const value = bodyParts[1].replace(',', '.').replace(/\s+/g, '');
              labelDiv.innerHTML =
                bodyParts[0] +
                ' ' +
                formatNumber(Number(value), currentLanguage, '.0-2') +
                ' %';
              tooltipEl.querySelector('div').innerHTML = '';
              tooltipEl.querySelector('div').appendChild(imgDiv);
              tooltipEl.querySelector('div').appendChild(labelDiv);
            }
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
    },
  };
}

export function AnnualOptions(currentLanguage: string): any {
  return {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
          callback: (value) => {
            return UnitConverter.getFormattedWeight(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
        enabled: false,
        position: 'custom',
        external: (tooltipModel) => {
          const tooltipEl = checkTooltip(tooltipModel);
          if (tooltipEl === null) {
            return;
          }
          if (tooltipModel.tooltip.body) {
            const body: string = tooltipModel.tooltip.body.map(
              (model) => model.lines
            )[0][0];
            const value = body.split(' ');
            const amount: string = value[1].split('.').join('');

            tooltipEl.querySelector('div').innerHTML =
              value[0] +
              ' ' +
              UnitConverter.getFormattedWeight(
                Number(amount.replace(',', '.'))
              );
          }
          setPosition(tooltipModel, tooltipEl);
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
    },
  };
}
