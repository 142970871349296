<div class="footer">
    <div class="landing-page-wrapper">
        <div class="img-container">
            <img class="logo" [src]="mmsLogo">
        </div>

        <div class="operations">
            <ng-container *ngFor="let item of footerNavigation">
                <div *ngIf="item.mailto === undefined" class="footer-item" [id]="item.id"
                    (click)="navigateTo(item.href)">
                    <img *ngIf="item.icon" [src]="item.icon">{{item.name}}
                </div>
                <a *ngIf="item.mailto !== undefined" href="mailto:{{item.mailto}}" class="footer-item"><i
                        *ngIf="item.icon" [class]="item.icon"></i> {{item.name}}</a>
            </ng-container>
        </div>
    </div>
</div>