import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  @Input() options: string[];
  @Output() onSelectOption: EventEmitter<SelectedOptions> = new EventEmitter();
  @Output() onExit = new EventEmitter();

  public filterInput: string = '';
  public filteredOptions: string[] = [];
  public selectedOptions: string[] = [];
  public generalFilter: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.options;
    this.generalFilter = false;
  }

  public deSelectOption(option: string) {
    this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
    if (this.selectedOptions.length === 0) {
      this.generalFilter = false;
    }
    this.onSelectOption.emit({
      dialogReference: this,
      selectedOptions: this.selectedOptions,
      options: this.options,
    });
  }

  public selectAllOptions() {
    const missing: string[] = this.filteredOptions.filter(
      (option) => !this.selectedOptions.includes(option)
    );

    if (missing.length == 0) {
      for (let i = this.selectedOptions.length - 1; i >= 0; i--) {
        const option = this.selectedOptions[i];
        if (this.filteredOptions.includes(option)) {
          this.selectedOptions.splice(i, 1);
        }
      }
    } else {
      if (this.generalFilter) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions.push(...this.filteredOptions);
      }
    }
    this.generalFilter = !this.generalFilter;
    this.onSelectOption.emit({
      options: this.options,
      selectedOptions: this.selectedOptions,
      dialogReference: this,
    });
  }

  public optionIsChecked(option: string) {
    return this.selectedOptions.includes(option);
  }

  public selectOption(option: string) {
    if (this.selectedOptions.includes(option)) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
      if (this.selectedOptions.length == 0) {
        this.generalFilter = false;
      }
    } else {
      this.selectedOptions.push(option);
      if (this.selectedOptions.length === this.options.length) {
        this.generalFilter = true;
      }
    }
    this.onSelectOption.emit({
      options: this.options,
      selectedOptions: this.selectedOptions,
      dialogReference: this,
    });
  }

  public onFilterUpdate(searchTerm: string) {
    this.filteredOptions = this.options.filter((item) =>
      item.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  }

  public exit() {
    this.onExit.emit();
  }
}

export interface SelectedOptions {
  options: string[];
  selectedOptions: string[];
  dialogReference: FilterDialogComponent;
}

export interface SelectedOption {
  dialogReference: FilterDialogComponent;
  selectedOption: string;
}
