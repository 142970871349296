import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter, mergeMap } from 'rxjs/operators';
import { OfficeService } from '../../services/office.service';
import { Office } from '../../models/office';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent {
  office: Office;

  constructor(private router: Router, private officeService: OfficeService) {
    let splitUrl = [];
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        delay(100),
        mergeMap((url: NavigationEnd) => {
          splitUrl = url.url.split('/');
          return this.officeService.dashboardOffices;
        })
      )
      .subscribe((offices) => {
        this.office = offices.filter((office) => office.id === splitUrl[2])[0];
      });
  }
}
