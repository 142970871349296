import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SurveyResponse } from '../models/survey/response/survey-response';
import { SurveyResponseResult } from '../models/survey/response/survey-response-result';
import { Survey } from '../models/survey/survey';
import { Tenant } from '../models/tenant';
import { SurveyHttpService } from './http/survey-http.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  private _surveyData: BehaviorSubject<Map<Survey, SurveyResponse[]>> =
    new BehaviorSubject<Map<Survey, SurveyResponse[]>>(new Map());

  private _responseResults: BehaviorSubject<Map<string, any>> =
    new BehaviorSubject<Map<string, any>>(new Map());

  constructor(
    private surveyHttpService: SurveyHttpService,
    private tenantService: TenantService
  ) {}

  private getResponseResults(surveyId: string): void {
    this.surveyHttpService
      .getSurveyResponsesResults(surveyId)
      .pipe(
        tap((response) => {
          const current = this._responseResults.value;
          current.set(surveyId, response as SurveyResponseResult);
          this._responseResults.next(current);
        })
      )
      .subscribe();
  }

  /**
   * This method sends a request to the backend, to delete the given survey, if it has no responses and is not set as active
   * @param surveyId
   * @returns `True` if the deletion process was successful.
   */
  public deleteSurvey(surveyId: string): Observable<boolean> {
    return this.surveyHttpService.deleteSurvey(surveyId);
  }

  public createSurvey(survey: Survey) {
    return this.tenantService.loggedInTenant.pipe(
      switchMap((tenant) => {
        if (!tenant) {
          return this.tenantService.loggedInTenant.pipe(
            filter((t) => !!t),
            take(1),
            delay(1000)
          );
        }
        return of(tenant);
      }),
      switchMap((loggedInTenant) => {
        if (!loggedInTenant) {
          throw new Error('Tenant undefined');
        }
        return this.surveyHttpService.postSurvey(survey, loggedInTenant.id);
      }),
      switchMap((response) => {
        return this.surveyHttpService.getSurveyById(response.body.surveyId);
      }),
      tap((survey) => {
        const current: Map<Survey, SurveyResponse[]> = this._surveyData.value;
        current.set(survey, []);
        this._surveyData.next(current);
      })
    );
  }

  public updateSurvey(updatedSurvey: Survey) {
    return this.tenantService.loggedInTenant.pipe(
      switchMap((tenant) => {
        if (!tenant) {
          return this.tenantService.loggedInTenant.pipe(
            filter((t) => !!t),
            take(1),
            delay(1000)
          );
        }
        return of(tenant);
      }),
      switchMap((loggedInTenant) => {
        if (!loggedInTenant) {
          throw new Error('Tenant undefined');
        }
        return this.surveyHttpService.updateSurvey(
          updatedSurvey,
          loggedInTenant.id
        );
      }),
      tap((updatedSurvey: Survey) => {
        const current: Map<Survey, SurveyResponse[]> = this._surveyData.value;
        let survey = Array.from(current.keys()).find(
          (s) => s.id ?? '' === updatedSurvey.id
        );
        if (survey) {
          current.set(survey, current.get(survey));
          this._surveyData.next(current);
        } else {
          current.set(survey, []);
          this._surveyData.next(current);
        }
      })
    );
  }

  private initSurveyData(): void {
    this.tenantService.loggedInTenant
      .pipe(
        switchMap((tenant) => {
          if (!tenant) {
            return this.tenantService.loggedInTenant.pipe(
              filter((t) => !!t),
              take(1),
              delay(1000)
            );
          }
          return of(tenant);
        }),
        switchMap((tenant: Tenant) => {
          if (!tenant) {
            throw new Error('Tenant undefined');
          }
          return this.surveyHttpService.getSurveysWithResponses(tenant.id);
        }),
        tap((surveysWithResponses: Map<Survey, SurveyResponse[]>) => {
          this._surveyData.next(surveysWithResponses);
        })
      )
      .subscribe();
  }

  public get surveyData(): Observable<Map<Survey, SurveyResponse[]>> {
    console.log(
      Array.from(this._surveyData.value.keys()),
      '',
      Array.from(this._surveyData.value.keys()).length === 0
    );

    if (Array.from(this._surveyData.value.keys()).length === 0) {
      this.initSurveyData();
    }
    return this._surveyData.asObservable();
  }

  public surveyResponseResult(surveyId: string): Observable<any> {
    if (this._responseResults.value.get(surveyId) === undefined) {
      this.getResponseResults(surveyId);
    }
    return this._responseResults.pipe(map((value) => value.get(surveyId)));
  }
}
