import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  @Input() imgSrc: string;
  @Input() imgAlt: string;
  @Input() header: string;
  @Input() showToggle = false;
  @Input() transportTypesCont: number;
  @Output() hovering: EventEmitter<boolean> = new EventEmitter<boolean>();
  hover: boolean;

  constructor(public loaderService: LoaderService) {
  }

  setHover(hovering: boolean): void {
    this.hovering.emit(hovering);
    this.hover = hovering;
  }
}
