<div *ngIf="!detailsOpen" class="tenants-wrapper page-wrapper">
    <div class="tenant-list">
        <div class="intro">
            <h2>{{'admin.tenants.headline'|translate}}</h2>
            <p>{{'admin.tenants.introText'|translate}}</p>
        </div>

        <div class="tenants">
            <div class="header">
                <h3>{{'admin.tenants.list.title'|translate}} ({{tenants.length}})</h3>
                <app-text-button [fontSizeFactor]="0.8" [icon]="'assets/svg/add.svg'"
                    [text]="'admin.tenants.list.add'|translate" (click)="addTenant()"></app-text-button>
            </div>
            <div *ngFor="let tenant of tenants" class="tenant-card" (click)="openDetails(tenant)">
                <h3 class="bold">{{tenant.name}}</h3>
                <h4 class="bold">{{'admin.tenants.list.card.license'|translate}}</h4>
                <div class="license">
                    <h5 *ngIf="getDateEntry(tenant) && getDateEntry(tenant).license" class="license-name bold">
                        {{getDateEntry(tenant).license.contractName}}</h5>
                    <app-license-status *ngIf="getDateEntry(tenant) && getDateEntry(tenant).license" app-license-status
                        [licenseStatus]="getDateEntry(tenant).license.status"></app-license-status>
                    <div *ngIf="getDateEntry(tenant) === undefined || getDateEntry(tenant).license === undefined"
                        class="no-license-container">
                        <img
                            [src]="'assets/svg/info_magenta.svg'"><span>{{'admin.tenants.list.card.noLicense'|translate}}</span>
                    </div>
                </div>
                <div class="license-data" *ngIf="getDateEntry(tenant) && getDateEntry(tenant).license">
                    <img src="assets/svg/calendar.svg">
                    <span class="bold">{{'admin.tenants.list.card.active'|translate}}: <span
                            class="unbold">{{getDateEntry(tenant).license.contractFrom | date: 'dd.MM.yyyy'}} -
                            {{getDateEntry(tenant).license.contractTo | date: 'dd.MM.yyyy'}}</span></span>
                    <span class="bold">{{'admin.tenants.list.card.activationCodesTotal'|translate}}: <span
                            class="unbold">{{getDateEntry(tenant).license.amountOfCodes}}</span></span>
                    <span class="bold">{{'admin.tenants.list.card.activationCodesUsed'|translate}}: <span
                            class="unbold">{{getUsedCodes(getDateEntry(tenant))}}</span></span>
                </div>
                <app-text-button [text]="'admin.tenants.list.card.manage'|translate"
                    [icon]="'assets/images/shared/right.svg'" [iconRightAligned]="true"></app-text-button>
            </div>
        </div>
    </div>
</div>
<app-detail-view *ngIf="detailsOpen"></app-detail-view>