import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  forkJoin,
  of,
  tap,
} from 'rxjs';
import { Company } from 'src/app/models/company';
import { SurveyResponse } from 'src/app/models/survey/response/survey-response';
import { Survey } from 'src/app/models/survey/survey';
import { CompanyService } from 'src/app/services/company.service';
import { SurveyService } from 'src/app/services/survey.service';

type SurveyWithRespose = Survey & {
  numberOfSubmits: number; //
  companyNames: string[]; //
  responses: SurveyResponse[]; //
};
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // add OnPush
})
export class FeedbackComponent implements OnInit {
  showSurveyDetails: boolean;
  newSurvey: boolean = false;
  loading: boolean = true;
  isActiveModal: boolean = false;
  isEditMode: boolean = false;
  clickedSurvey: SurveyWithRespose;
  activeSurveyOld: SurveyWithRespose;
  resetSwitch: boolean = false;
  dialogToggle: string = '';
  dialogToggleButtonConfirm: string;
  dialogToggleButtonCancel: string;
  isModalConfirm: boolean;
  public static get route(): string {
    return 'feedback';
  }
  private translateService: TranslateService = inject(TranslateService);
  currentLang = this.translateService.currentLang;
  selectedCriteria: any;
  filteredSurveys: SurveyWithRespose[] = [];
  surveys: SurveyWithRespose[] = [];
  surveyCount: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedSurvey: SurveyWithRespose | null = null;

  constructor(
    private surveyService: SurveyService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.surveyService.surveyData.subscribe((surveyData) => {
      if (surveyData.size === 0) {
        this.loading = false;
      }
      this.surveys = this.getAllSurveyTitles(surveyData);

      this.surveyCount.next(surveyData.size);
      this.onFilterChanged();
    });
  }

  ngOnDestroy(): void {}

  onFilterChanged(filterParams?: any) {
    // Handle filter changes, update surveys accordingly
    if (filterParams)
      this.filteredSurveys = this.applyFilters(this.surveys, filterParams);
    else this.filteredSurveys = this.surveys;

    // Sort the filtered surveys by start date (descending) and then by title
    this.filteredSurveys.sort((a, b) => {
      // Compare start dates in descending order
      const startDateComparison = b.startDate.getTime() - a.startDate.getTime();
      // If start dates are equal, compare titles
      if (startDateComparison === 0) {
        const titleA = a.title.get('en') || '';
        const titleB = b.title.get('en') || '';
        return titleA.localeCompare(titleB);
      }
      return startDateComparison;
    });
  }

  sortByCriteria(surveys, selectedCriteria) {
    // const sortedSurveys = [...surveys];

    switch (selectedCriteria) {
      case 'Startdate ascending':
        surveys.sort((a, b) => {
          // Compare start dates in descending order
          const startDateComparison =
            a.startDate.getTime() - b.startDate.getTime();
          // If start dates are equal, compare titles
          if (startDateComparison === 0) {
            const titleA = a.title.get('en') || '';
            const titleB = b.title.get('en') || '';
            return titleA.localeCompare(titleB);
          }
          return startDateComparison;
        });
        break;
      case 'Startdate descending':
        surveys.sort((a, b) => {
          // Compare start dates in descending order
          const startDateComparison =
            b.startDate.getTime() - a.startDate.getTime();
          // If start dates are equal, compare titles
          if (startDateComparison === 0) {
            const titleA = a.title.get('en') || '';
            const titleB = b.title.get('en') || '';
            return titleA.localeCompare(titleB);
          }
          return startDateComparison;
        });
        break;
      case 'Finishdate ascescending':
        surveys.sort((a, b) => {
          const endDateComparison = a.endDate.getTime() - b.endDate.getTime();
          if (endDateComparison === 0) {
            const titleA = a.title.get('en') || '';
            const titleB = b.title.get('en') || '';
            return titleA.localeCompare(titleB);
          }
          return endDateComparison;
        });
        break;
      case 'Finishdate descending':
        surveys.sort((a, b) => {
          const endDateComparison = b.endDate.getTime() - a.endDate.getTime();
          if (endDateComparison === 0) {
            const titleA = a.title.get('en') || '';
            const titleB = b.title.get('en') || '';
            return titleA.localeCompare(titleB);
          }
          return endDateComparison;
        });
        break;
      case 'Name A-Z':
        surveys.sort((a, b) =>
          a.title
            .get(this.currentLang)
            .localeCompare(b.title.get(this.currentLang))
        );
        break;
      case 'Name Z-A':
        surveys.sort((a, b) => b.title.get(this.currentLang).localeCompare(a.title.get(this.currentLang)));
        break;
    }

    return surveys;
  }

  onSurveyTileClicked(showSurveyDetails: boolean, survey?: SurveyWithRespose) {
    this.selectedSurvey = survey;
    this.showSurveyDetails = showSurveyDetails;
  }
  newSurveyClicked($event) {
    this.newSurvey = $event;
  }

  closeModal() {
    this.isActiveModal = false;
    this.isModalConfirm = false;
  }

  afterCloseModal() {
    if (this.isModalConfirm) {
      const currentDate = new Date();
      const duration = new Date();
      duration.setMonth(currentDate.getMonth() + 3);
      this.clickedSurvey.startDate = currentDate;
      if (!this.clickedSurvey.isActive) {
        this.clickedSurvey.endDate = new Date(currentDate.getTime() - 1000);
      }
      if (this.clickedSurvey.isActive) {
        this.clickedSurvey.endDate = duration;
        this.surveyService.updateSurvey(this.activeSurveyOld).subscribe((response) => { });
      }
      this.surveyService.updateSurvey(this.clickedSurvey).subscribe((response) => { });
      this.isActiveModal = false;
    }
    if (!this.isModalConfirm) {
      this.selectedSurvey.isActive = !this.selectedSurvey.isActive;
      if (!this.clickedSurvey.isActive) {
        this.activeSurveyOld.isActive = !this.activeSurveyOld.isActive;
      }
    }
    this.isActiveModal = false;
  }

  confirmToggle() {
    this.isActiveModal = false;
    this.isModalConfirm = true;
  }

  surveyActivesToggleHandler(clickedSurvey: SurveyWithRespose, isNewSurvey?: boolean) {
    this.clickedSurvey = clickedSurvey;
    const currentDate = new Date();
    const duration = new Date();
    duration.setMonth(currentDate.getMonth() + 3);

    if (clickedSurvey.isActive && !this.isEditMode && !isNewSurvey) {
      this.dialogToggle = 'feedbacks.title.toggle.confirmationActive';
      this.dialogToggleButtonConfirm = 'feedbacks.title.toggle.setAsActive';
      this.dialogToggleButtonCancel = 'feedbacks.title.buttons.cancel'
    }
    if (!clickedSurvey.isActive && !this.isEditMode && !isNewSurvey) {
      this.isActiveModal = true;
      this.dialogToggle = 'feedbacks.title.toggle.confirmationInactive';
      this.dialogToggleButtonConfirm = 'feedbacks.title.toggle.setAsInactive';
      this.dialogToggleButtonCancel = 'feedbacks.title.toggle.discard'
    }
    if (clickedSurvey.isActive) {
      this.surveys.forEach(survey => {
        if (survey.id !== clickedSurvey.id && survey.isActive) {
          this.activeSurveyOld = survey;
          survey.isActive = false;
          survey.endDate = new Date(currentDate.getTime() - 1000);
          if (this.isEditMode || isNewSurvey) {
            this.surveyService.updateSurvey(survey).subscribe();
            return;
          }
          if (!this.isEditMode  || !isNewSurvey) {
            this.isActiveModal = true;
          }
        }
      });
      if (!this.isActiveModal && !this.isEditMode && !isNewSurvey) {
         clickedSurvey.startDate = new Date(currentDate.getTime());
        clickedSurvey.endDate = duration;
        this.surveyService.updateSurvey(clickedSurvey).subscribe((response) => { });
      }
    }
    if (this.isEditMode || isNewSurvey) {
      this.surveyService.updateSurvey(clickedSurvey).subscribe();
    }
  }

  applyFilters(
    surveys: SurveyWithRespose[],
    filters: any
  ): SurveyWithRespose[] {
    // filte surveys based on filter parameter s
    if (filters.customStartDate) {
      const datePartsStartDate = filters.customStartDate.split('-');
      filters.customStartDate = new Date(
        parseInt(datePartsStartDate[0], 10),
        parseInt(datePartsStartDate[1], 10) - 1,
        parseInt(datePartsStartDate[2], 10)
      );
    }
    if (filters.customEndDate) {
      const datePartsEndDate = filters.customEndDate.split('-');
      filters.customEndDate = new Date(
        parseInt(datePartsEndDate[0], 10),
        parseInt(datePartsEndDate[1], 10) - 1,
        parseInt(datePartsEndDate[2], 10) + 1
      );
    }
    return surveys.filter((survey) => {
      // true if the survey satisfies all conditions
      return (
        (!filters.timeRange ||
          this.filterByTimeRange(survey, filters.timeRange)) &&
        (!filters.customStartDate ||
          survey.startDate >= filters.customStartDate) &&
        (!filters.customEndDate || survey.endDate <= filters.customEndDate) &&
        (!filters.company || survey.companyNames.includes(filters.company)) &&
        (!filters.office ||
          survey.responses.some(
            (response) => response.officeId === filters.office
          )) &&
        (!filters.platform ||
          survey.responses.some(
            (response) => response.app.platform === filters.platform
          )) &&
        (!filters.appVersion ||
          survey.responses.some(
            (response) => response.app.version === filters.appVersion
          )) &&
        // Фильтр по активному статусу
        (!filters.isActive ||
          (filters.isActive === 'Active' && survey.isActive) ||
          (filters.isActive === 'Inactive' && !survey.isActive))
      );
    });
  }

  filterByTimeRange(survey: SurveyWithRespose, timeRange: string): boolean {
    const currentDate = new Date();
    switch (timeRange) {
      case 'lastWeek':
        const lastWeekStartDate = new Date();
        lastWeekStartDate.setDate(currentDate.getDate() - 7);
        return new Date(survey.startDate) >= lastWeekStartDate; // || currentDate <= new Date(survey.endDate);
      case 'lastMont':
        const lastMonthStartDate = new Date();
        lastMonthStartDate.setMonth(currentDate.getMonth() - 1);
        return new Date(survey.startDate) >= lastMonthStartDate; // || currentDate <= new Date(survey.endDate);
      case 'lastQuarter':
        const lastQuartStartDate = new Date();
        lastQuartStartDate.setMonth(currentDate.getMonth() - 3);
        return new Date(survey.startDate) >= lastQuartStartDate; // || currentDate <= new Date(survey.endDate);
      case 'lastYear':
        const lastYaerStartDate = new Date();
        lastYaerStartDate.setFullYear(currentDate.getFullYear() - 1);
        return new Date(survey.startDate) >= lastYaerStartDate; // || currentDate <= new Date(survey.endDate);
      case 'AllTime':
      default:
        return true;
    }
  }

  private getAllSurveyTitles(surveyData: Map<Survey, SurveyResponse[]>) {
    const surveys: SurveyWithRespose[] = [];
    const companyObservables: Observable<Company>[] = [];

    for (const [survey, surveysResponses] of surveyData) {
      const surveyWithResponse: SurveyWithRespose = {
        id: survey.id,
        referenceId: survey.referenceId,
        tenantId: '',
        title: survey.title,
        description: survey.description,
        pages: survey.pages,
        createdAt: new Date(survey.createdAt),
        appliedCompanies: survey.appliedCompanies,
        isActive: survey.isActive,
        startDate: new Date(survey.startDate),
        endDate: new Date(survey.endDate),
        numberOfSubmits: surveysResponses.length,
        companyNames: [],
        responses: surveysResponses,
        version: survey.version,
      };

      surveyWithResponse.appliedCompanies.forEach((companyId) => {
        companyObservables.push(this.companyService.get(companyId));
      });

      surveys.push(surveyWithResponse);
    }

    of(null)
      .pipe(
        concatMap(() => forkJoin(companyObservables)),
        tap((companies: Company[]) => {
          this.loading = false;
          surveys.forEach((survey, surveyIndex) => {
            survey.appliedCompanies.forEach((companyId, index) => {
              const company = companies.find((c) => c && c.id === companyId);
              if (company && !survey.companyNames.includes(company.name)) {
                survey.companyNames.push(company.name);
              }
            });
          });
        })
      )
      .subscribe(() => {
       
      });

    return surveys;
  }
}
