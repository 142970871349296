<form [formGroup]="filter">
  <div class="p-grid p-fluid">
    <h3 class="p-col-12 bold" *ngIf="!trendShowed">
      {{'filters.tripFilter' | translate}}
    </h3>
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2 pos-relative" *ngIf="!trendShowed">
      <label class="static-label">{{'common.transportType' | translate}}</label>
      <p-multiSelect [options]="transportTypeOptions" formControlName="transportType"
        [placeholder]="'common.transportType' | translate" optionLabel="name"></p-multiSelect>
      <div class="p-grid no-margin no-padding-top">
        <div class="p-col chip-container" *ngFor="let control of filter.controls.transportType.value">
          <scale-tag dismissable
            (scaleClose)="removeFromGroup(control.name, filter.controls.transportType)">{{control.name}}</scale-tag>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2" *ngIf="!trendShowed">
      <p-dropdown [options]="tripDistanceOptions" formControlName="tripDistance" [autoDisplayFirst]="false"
        showClear="true" [placeholder]="'common.tripDistance' | translate" optionLabel="name"></p-dropdown>
    </div>
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2" *ngIf="!trendShowed">
      <p-dropdown [options]="tripTimeOptions" formControlName="tripTime" [autoDisplayFirst]="false" showClear="true"
        [placeholder]="'common.tripTime' | translate" optionLabel="name"></p-dropdown>

    </div>
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2 pos-relative" *ngIf="!trendShowed">
      <label class="static-label">{{'common.tripReason' | translate}}</label>
      <p-multiSelect [options]="tripReasons" formControlName="tripReason"
        [placeholder]="'common.tripReason' | translate" optionLabel="name"></p-multiSelect>
      <div class="p-grid no-margin no-padding-top">
        <div class="p-col chip-container" *ngFor="let control of filter.controls.tripReason.value">
          <scale-tag dismissable
            (scaleClose)="removeFromGroup(control.name, filter.controls.tripReason)">{{control.name}}</scale-tag>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2 pos-relative">
      <label class="static-label">{{'common.company' | translate}}</label>
      <p-multiSelect [options]="getAllCompanies() | async" optionLabel="name" formControlName="company"
        [placeholder]="'common.company' | translate"></p-multiSelect>
      <div class="p-grid no-margin no-padding-top">
        <div class="p-col chip-container" *ngFor="let control of filter.controls.company.value">
          <scale-tag dismissable
            (scaleClose)="removeFromGroup(control.name, filter.controls.company)">{{control.name}}</scale-tag>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid p-fluid toggle-bar">
    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2 p-xxl-2">
      <div class="p-grid p-ai-center">
        <div class="p-col-fixed switch-size switch-alignment">
          <p-inputSwitch inputId="autoRecorded" formControlName="autoRecorded"></p-inputSwitch>
        </div>
        <label class="p-col" for="autoRecorded">{{ 'common.showAutoRecordedOnly' | translate }}</label>
      </div>
    </div>

    <div class="p-col-12 p-sm-6 p-xs-6 p-lg-3 p-xl-2 p-xxl-2">
      <div class="p-grid p-ai-center">
        <div class="p-col-fixed switch-size switch-alignment">
          <p-inputSwitch inputId="homeOffice" formControlName="homeOffice"></p-inputSwitch>
        </div>
        <label class="p-col" for="homeOffice">{{ 'common.showHomeofficeRecordsOnly' | translate }}</label>
      </div>
    </div>

    <div class="p-col-12 p-sm-10 p-xs-10 p-lg-6 p-xl-4">
      <fieldset class="causedByCompanyCarSection">
        <div>
          <legend class="label">{{ 'common.showCausedByCompanyCarRecordsOnly' | translate }}</legend>
          <div class="radio-group">
            <input class="radio-input" type="radio" value="All" formControlName="causedByCompanyCar" id="allRadio">
            <label for="allRadio" class="radio-label">{{ 'common.showAll' | translate }}</label>
            <input class="radio-input" type="radio" value="Only" formControlName="causedByCompanyCar" id="onlyRadio">
            <label for="onlyRadio" class="radio-label">{{ 'common.only' | translate }}</label>
            <input class="radio-input" type="radio" value="Not" formControlName="causedByCompanyCar" id="notRadio">
            <label for="notRadio" class="radio-label">{{ 'common.none' | translate }}</label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</form>