import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Office } from '../models/office';
import { TokenUtil } from '../utils/token-util';
import { ContentLoadingService } from './content-loading.service';
import { OfficeHttpService } from './http/office-http.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  private overviewOffices: BehaviorSubject<Office[]> = new BehaviorSubject<
    Office[]
  >([]);

  private _dashboardOffices: BehaviorSubject<Office[]> = new BehaviorSubject<
    Office[]
  >([]);

  private requestInProgress = false;
  private tenantID: string = '';
  private initial: boolean = true;

  constructor(
    private officeHttpService: OfficeHttpService,
    private tenantService: TenantService,
    private contentLoadingService: ContentLoadingService
  ) {
    this.tenantService.selectedTenantId.subscribe((tenantID) => {
      if (!tenantID) {
        return;
      }
      this.tenantID = tenantID;
      if (
        this._dashboardOffices.value.find((dashboardOffice) => {
          if (dashboardOffice.tenantID === tenantID) {
            return dashboardOffice;
          }
        })
      ) {
        return;
      }

      this.officeHttpService
        .getAllOfficesFor(tenantID)
        .subscribe((offices: Office[]) => {
          offices.map((office) => {
            this._dashboardOffices.value.find((dashoffice) => {
              if (office.id === dashoffice.id) {
                dashoffice.tenantID = this.tenantID;
              }
            });
          });
        });
    });
  }

  get dashboardOffices() {
    if (
      this._dashboardOffices === null ||
      this._dashboardOffices.value.length === 0
    ) {
      this.initOffices();
    }
    return this._dashboardOffices.asObservable().pipe(shareReplay(1));
  }

  initOffices() {
    if (!this.initial) {
      return;
    }
    this.initial = false;
    if (localStorage.getItem('id-token') === null) {
      return;
    }
    if (this.requestInProgress) {
      return;
    }
    this.requestInProgress = true;
    var token = TokenUtil.getTenantFromToken(localStorage.getItem('id-token'));
    this.contentLoadingService.setLoadingState(true);
    this.getAllOfficesFor(token)
      .pipe(
        tap((offices: Office[]) => {
          this._dashboardOffices.next(offices);
        })
      )
      .subscribe((_) => {
        this.contentLoadingService.setLoadingState(false);
      });
  }

  getAllOfficesFor(tenantID: string): Observable<Office[]> {
    return this.officeHttpService.getAllOfficesFor(tenantID);
  }

  get overviewOffices$(): Observable<Office[]> {
    return this.overviewOffices.asObservable();
  }

  getAllOffices(): Observable<Office[]> {
    return this.officeHttpService.getOffices().pipe(
      tap((offices: Office[]) => {
        this._dashboardOffices.next(offices);
      })
    );
  }

  addOffice(city: string, street: string, zip: string, countryCode: string) {
    let office: Office = {
      city: city,
      id: '',
      street: street,
      tenantID: TokenUtil.getTenantFromToken(localStorage.getItem('id-token')),
      zip: zip,
      countryCode: countryCode,
    };
    this.contentLoadingService.setLoadingState(true);

    this.officeHttpService
      .addOffice(office)
      .subscribe((response: HttpResponse<any>) => {
        const office: Office = {
          city: city,
          id: response.body['id'],
          street: street,
          zip: zip,
          tenantID: this.tenantID,
          countryCode: countryCode,
        };
        const updatedList: Office[] = this._dashboardOffices.value;
        updatedList.push(office);
        this.setOfficeList(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  updateOffice(office: Office) {
    this.contentLoadingService.setLoadingState(true);

    this.officeHttpService
      .updateOffice(office)
      .pipe(map((value) => value.body))
      .subscribe((response: HttpResponse<any>) => {
        const updated: Office = {
          id: office.id,
          city: response['officeCity'] ?? office.city,
          street: response['officeStreet'] ?? office.street,
          countryCode: response['officeCountryCode'] ?? office.countryCode,
          zip: response['officeZip'] ?? office.zip,
          tenantID: office.tenantID,
        };
        const updatedList: Office[] = this._dashboardOffices.value;
        updatedList[updatedList.findIndex((value) => value.id === office.id)] =
          updated;
        this.setOfficeList(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  deleteOffice(officeID: string) {
    this.contentLoadingService.setLoadingState(true);
    return this.officeHttpService
      .deleteOffice(officeID)
      .subscribe((response) => {
        const updatedList: Office[] = this._dashboardOffices.value.filter(
          (entry) => entry.id !== officeID
        );
        this.setOfficeList(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  private setOfficeList(offices: Office[]) {
    this._dashboardOffices.next(offices);
  }

  public getOffices(): Observable<Office[]> {
    return this.getAllOffices();
  }

  public getByCity(searchQuery: string): Observable<Office[]> {
    return searchQuery
      ? of(
          this._dashboardOffices.value.filter(
            (office) =>
              office.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
              office.street.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      : of(this._dashboardOffices.value);
  }

  // public get(officeId: string): Observable<Office> {
  //   return this.dashboardOffices.pipe(
  //     map((offices) => offices.find((office) => office.id === officeId)),
  //     take(1)
  //   );
  // }

  public get(officeId: string): Observable<Office> {
    const offices: Office[] = this._dashboardOffices.value;
    if (offices.length > 0) {
      const foundOffices = offices.filter((office) => office.id === officeId);
      if (foundOffices) {
        return of(foundOffices[0]);
      } else {
        return of({
          city: '?',
          zip: '00000',
          street: '?',
          id: officeId,
        } as Office);
      }
    } else {
      return of(this.dashboardOffices).pipe(
        delay(1000),
        switchMap(() => this.get(officeId))
      );
    }
  }
}
