import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeIcons } from 'primeng/api';
import { DashboardInfo } from 'src/app/models/dashboard-info';
import { DataPrivacyPageComponent } from 'src/app/pages/data-privacy-page/data-privacy-page.component';
import { ImprintPageComponent } from 'src/app/pages/imprint-page/imprint-page.component';
import { Language } from '../../app.component';
import { DashboardService } from '../../services/dashboard-info.service';

class FooterNavigationItem {
  name: string;
  id: string;
  href?: string;
  icon?: string;
  mailto?: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerNavigation: FooterNavigationItem[];
  dashboardInfo: DashboardInfo;

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private dashboardInfoService: DashboardService
  ) {}

  isLoginPage() {
    return this.router.url === '/login';
  }

  ngOnInit(): void {
    const english = this.translateService.currentLang === Language.ENGLISH;
    //Terms of service / conditions are not needed currently
    this.footerNavigation = [
      {
        name: 'contact',
        id: 'contact',
        mailto: 'ecoshift@t-systems-mms.com',
      },
      // {
      //   name: 'termsAndConditions',
      //   id: 'Terms and conditions',
      //   href: '/terms-and-conditions',
      //   width: english ? '170px' : '180px',
      // },
      {
        name: 'imprint',
        id: 'imprint',
        href: ImprintPageComponent.route,
      },
      {
        name: 'dataPrivacy',
        id: 'dataprivacy',
        href: DataPrivacyPageComponent.route,
        icon: PrimeIcons.SHIELD,
      },
    ];
    this.dashboardInfoService.dashboardInfo.subscribe((info) => {
      this.dashboardInfo = info;
    });
  }
}
