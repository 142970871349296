import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandingComponent } from '../../pages/manage/branding/branding.component';
import { DataComponent } from '../../pages/manage/data/data.component';
import { LicenseComponent } from '../../pages/manage/license/license.component';
import { UsersRolesComponent } from '../../pages/manage/users-roles/users-roles.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: BrandingComponent.route,
    pathMatch: 'full',
  },
  { path: BrandingComponent.route, component: BrandingComponent },
  { path: DataComponent.route, component: DataComponent },
  { path: UsersRolesComponent.route, component: UsersRolesComponent },
  { path: LicenseComponent.route, component: LicenseComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManageRoutingModule {}
