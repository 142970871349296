import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ActiveDirectoryAppRole,
  ActiveDirectoryUser,
} from '../models/active-directory-user';
import { Tenant } from '../models/tenant';
import { TokenUtil } from '../utils/token-util';
import { ApiService } from './api.service';
import { ContentLoadingService } from './content-loading.service';
import { TenantHttpService } from './http/tenant-http.service';
@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private _tenatlist = new BehaviorSubject<Tenant[]>([]);
  private _selectedTenantID = new BehaviorSubject<string>(''); //TODO remove later on
  private _selectedTenant = new BehaviorSubject<Tenant>(undefined);
  private _loggedInTenant = new BehaviorSubject<Tenant>(undefined);

  constructor(
    private tenantHttpService: TenantHttpService,
    private contentLoadingService: ContentLoadingService
  ) {}

  get selectedTenantId() {
    return this._selectedTenantID.asObservable();
  }

  get selectedTenant() {
    return this._selectedTenant.asObservable();
  }

  selectTenant(tenant: Tenant) {
    this._selectedTenant.next(tenant);
  }

  selectTenantID(tenantID: string) {
    this._selectedTenantID.next(tenantID);
  }

  get loggedInTenant() {
    if (this._loggedInTenant.value == undefined) {
      this.fetchSingleTenant().subscribe((response: Tenant) => {
        this._loggedInTenant.next(response);
      });
    }
    return this._loggedInTenant.asObservable();
  }

  setLoggedInTenant(tenant: Tenant) {
    this._loggedInTenant.next(tenant);
  }

  get tenantList() {
    if (this._tenatlist.value.length === 0) {
      this.fetchTenants();
    }

    return this._tenatlist.asObservable();
  }

  fetchSingleTenant(): Observable<Tenant> {
    return this.tenantHttpService.getTenant();
  }

  fetchTenants(): void {
    if (ApiService.hasIDToken()) {
      this.contentLoadingService.setLoadingState(true);

      this.tenantHttpService.getAllTenants().subscribe((tenants: Tenant[]) => {
        this._tenatlist.next(tenants);
        const idToken = localStorage.getItem('id-token');
        const tenantId = TokenUtil.getTenantFromToken(idToken);
        this._loggedInTenant.next(
          tenants.filter(
            (tenant) =>
              tenant.id.toLocaleLowerCase() === tenantId.toLocaleLowerCase()
          )[0]
        );
        this.contentLoadingService.setLoadingState(false);
      });
    }
  }

  addTenant(name: string, isActive: boolean): void {
    this.contentLoadingService.setLoadingState(true);
    this.tenantHttpService.createTenantInAAD(name).subscribe((response) => {
      const tenantId = response.body.tenantId;
      const status = response.status;
      if (status && status == 201) {
        const updatedList: Tenant[] = this._tenatlist.value;
        updatedList.push({
          id: tenantId,
          isActive: isActive,
          name: name,
        });
        this._tenatlist.next(updatedList);
        this.contentLoadingService.setLoadingState(false);
      }
    });
  }

  updateTenant(tenantId: string, name: string) {
    this.contentLoadingService.setLoadingState(true);
    this.tenantHttpService
      .updateTenant(tenantId, name)
      .subscribe((response) => {
        const updatedTenants: Tenant[] = this._tenatlist.value;
        const entry: Tenant = updatedTenants.filter(
          (tenant) => tenant.id === tenantId
        )[0];
        entry.name = name;
        const index = updatedTenants.findIndex(
          (value) => value.id === tenantId
        );
        updatedTenants[index] = entry;
        this._tenatlist.next(updatedTenants);
        this.selectTenant(entry);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  inivteDirectoryUser(
    name: string,
    surname: string,
    email: string,
    role: string,
    personalRole: string
  ): Observable<any> {
    return this.tenantHttpService.inviteDirectoryUser(
      name,
      surname,
      email,
      role,
      this._loggedInTenant.value !== undefined
        ? this._loggedInTenant.value.id
        : TokenUtil.getTenantFromToken(localStorage.getItem('id-token')),
      personalRole
    );
  }

  inivteDirectoryOwner(
    name: string,
    surname: string,
    email: string
  ): Observable<any> {
    return this.tenantHttpService.inviteDirectoryOwner(
      name,
      surname,
      email,
      this._selectedTenant.value.id
    );
  }

  updateUserAppRole(
    userId: string,
    oldAppRoleId: string,
    newAppRoleId: string,
    senderRoleId: string
  ): Observable<any> {
    return this.tenantHttpService.updateUserAppRole(
      userId,
      oldAppRoleId,
      newAppRoleId,
      senderRoleId
    );
  }

  updateUserProfile(
    userId: string,
    name: string,
    surname: string,
    senderRoleId: string
  ): Observable<any> {
    return this.tenantHttpService.updateUserProfile(
      userId,
      name,
      surname,
      senderRoleId
    );
  }

  deleteUser(userId: string, tenantID: string) {
    return this.tenantHttpService.deleteUser(userId, tenantID);
  }

  deleteTenant(tenantID: string) {
    this.contentLoadingService.setLoadingState(true);
    this.tenantHttpService
      .deleteTenant(tenantID)
      .subscribe((response: HttpResponse<any>) => {
        const status = response.status;
        if (status && status === 200) {
          const updatedList = this._tenatlist.value;
          for (let i = 0; i < updatedList.length; i++) {
            let tenant = updatedList[i];
            if (tenant.id === tenantID) {
              const index = updatedList.indexOf(tenant, 0);
              if (index > -1) {
                updatedList.splice(index, 1);
              }
            }
          }
          this._tenatlist.next(updatedList);
          this.contentLoadingService.setLoadingState(false);
        }
      });
  }

  public fetchAllUsers(
    fromSelectedTenant: boolean = false,
    roleId?: string
  ): Observable<ActiveDirectoryUser[]> {
    let loggedInTenantId = '';

    if (!fromSelectedTenant) {
      loggedInTenantId = TokenUtil.getTenantFromToken(
        localStorage.getItem('id-token')
      );
      return this.tenantHttpService.fetchAllUsers(loggedInTenantId, roleId);
    } else {
      if (
        (fromSelectedTenant && this._selectedTenant.value === undefined) ||
        (!fromSelectedTenant && this._loggedInTenant.value === undefined)
      ) {
        this.fetchTenants();
      } else {
        loggedInTenantId = this._loggedInTenant.value.id;
      }
      return this.tenantHttpService.fetchAllUsers(
        this._selectedTenant.value.id,
        roleId
      );
    }
  }

  public fetchAllAppRoles(): Observable<ActiveDirectoryAppRole[]> {
    return this.tenantHttpService.fetchAllActiveDirectoryAppRoles();
  }
}
