<div class="heading-item">
    <h3>{{'feedbacks.title.rating.rating'|translate}}</h3>
    <scale-checkbox label="{{'feedbacks.title.buttons.mandatory'|translate}}" [attr.checked]="isMandatory" class="padding-checkbox"
        (scale-change)="isMandatoryOnChange($event)"></scale-checkbox>
</div>
<div class="margin-top-sm">
    <div class="p-grid">
        <scale-divider class="scale-divider-item margin-bottom-xl"></scale-divider>
        <div class="p-col-6">
            <!-- <div class="rating-style">
                <label for="ratingType" class="label-title ">{{'feedbacks.title.rating.style'|translate}}</label>
                <scale-dropdown-select (scale-change)="ratingStyleChange($event)" [attr.value]="ratingStyle"
                    class="scale-dropdown-select-item">
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="stars">{{'feedbacks.title.rating.stars'|translate}}</scale-dropdown-select-item>
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="points">{{'feedbacks.title.rating.points'|translate}}</scale-dropdown-select-item>
                </scale-dropdown-select>
            </div> -->
            <div-rating-points class="rating-points">
                <div class="inline-flex">
                    <p for="numberOfOptions" class="bold margin-right-small">
                        {{'feedbacks.title.rating.pointsAmount'|translate}} {{ratingStyle === 'stars'?
                        ('feedbacks.title.rating.stars'|translate):('feedbacks.title.rating.points'|translate) }}</p>
                    <scale-tooltip content="{{'feedbacks.title.rating.tooltip'|translate}}" placement="right">
                        <scale-icon-alert-help accessibility-title="help" />
                    </scale-tooltip>
                </div>

                <scale-dropdown-select (scale-change)="numberOfOptionsChange($event)" [attr.value]="numberOfOptions"
                    class="scale-dropdown-select-item">
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="5">5</scale-dropdown-select-item>
                    <scale-dropdown-select-item class="scale-dropdown-select-item"
                        value="10">10</scale-dropdown-select-item>
                </scale-dropdown-select>
            </div-rating-points>

        </div>
        <div class="p-col-12">
            <div class="p-grid margin-top-sm">
                <div class="p-col-12 p-sm-6 p-xs-6">
                    <label class="label-title" for="questionDE">{{'feedbacks.title.surveys.questionDE'|translate}}</label>
                    <scale-textarea id="questionDE" label="{{'feedbacks.title.surveys.questionDE'|translate}}" rows="5"
                        (scaleChange)="ratingQuestionAdd($event,'de')" [attr.value]="questionDe"
                        [invalid]="!(questionDe || valid)"
                        [attr.helper-text]=" (questionDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        >
                    </scale-textarea>
                </div>
                <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
                    <label class="label-title" for="questionEN">{{'feedbacks.title.surveys.questionEN'|translate}}</label>
                    <scale-textarea id="questionEN" label="{{'feedbacks.title.surveys.questionEN'|translate}}" rows="5"
                        (scaleChange)="ratingQuestionAdd($event,'en')" [attr.value]="questionEn"
                        [invalid]="!(questionEn || valid)"
                        [attr.helper-text]=" (questionEn || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                        >
                    </scale-textarea>
                </div>
            </div>
        </div>
        <div class="p-col-12 margin-top-sm">
            <div class="p-grid">
                <div class="p-col-12 p-sm-6 p-xs-6">
                    <div class="p-grid">
                        <div class="p-col-12 p-sm-6 p-xs-6">
                            <label class="label-title" for="minValueLabel">{{'feedbacks.title.rating.minValueLabel'|translate}} (DE)</label>
                            <scale-text-field id="text-field"
                                label="{{'feedbacks.title.rating.minValueLabel'|translate}} (DE)" rows="5"
                                (scaleChange)="minValueAdd($event,'de')" id="minValueLabelDE"
                                [invalid]="!(minValueLabelDe || valid) || !isValidText(minValueLabelDe)"
                                [attr.helper-text]=" !isValidText(minValueLabelDe) ? ('feedbacks.title.rating.textRequired'|translate) : (minValueLabelDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                                [attr.value]="minValueLabelDe"></scale-text-field>
                        </div>
                        <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
                            <label class="label-title" for="maxValueLabel">{{'feedbacks.title.rating.maxValueLabel'|translate}} (DE)</label>
                            <scale-text-field id="maxValueLabelDE"
                                label="{{'feedbacks.title.rating.maxValueLabel'|translate}}" rows="5"
                                (scaleChange)="maxValueAdd($event,'de')"
                                [invalid]="!(maxValueLabelDe || valid) || !isValidText(maxValueLabelDe)"
                                [attr.helper-text]="  !isValidText(maxValueLabelDe) ? ('feedbacks.title.rating.textRequired'|translate) : (maxValueLabelDe || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                                [attr.value]="maxValueLabelDe"></scale-text-field>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-6 p-xs-6">
                    <div class="p-grid">
                        <div class="p-col-12 p-sm-6 p-xs-6">
                            <label class="label-title" for="minValueLabelEN">{{'feedbacks.title.rating.minValueLabel'|translate}} (EN)</label>
                            <scale-text-field id="minValueLabelEN"
                                label="{{'feedbacks.title.rating.minValueLabel'|translate}} (EN)" rows="5"
                                (scaleChange)="minValueAdd($event,'en') "
                                [invalid]="!(minValueLabelEn || valid) || !isValidText(minValueLabelEn)"
                                [attr.helper-text]="  !isValidText(minValueLabelEn) ? ('feedbacks.title.rating.textRequired'|translate) : (minValueLabelEn || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                                [attr.value]="minValueLabelEn"></scale-text-field>
                        </div>
                        <div class="p-col-12 p-sm-6 p-xs-6 pos-relative">
                            <label class="label-title" for="maxValueLabelEN">{{'feedbacks.title.rating.maxValueLabel'|translate}} (EN)</label>
                            <scale-text-field id="maxValueLabelEN"
                                label="{{'feedbacks.title.rating.maxValueLabel'|translate}} (EN)" rows="5"
                                (scaleChange)="maxValueAdd($event,'en')"
                                [invalid]="!(maxValueLabelEn || valid) || !isValidText(maxValueLabelEn)"
                                [attr.helper-text]=" !isValidText(maxValueLabelEn) ? ('feedbacks.title.rating.textRequired'|translate) : (maxValueLabelEn || valid)  ? '' : 'feedbacks.title.surveys.required'|translate"
                                [attr.value]="maxValueLabelEn"></scale-text-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>