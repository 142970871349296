import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivationCode } from 'src/app/models/activationcode';
import { TableData, TableRow } from 'src/app/models/table-data';
import { ActivationCodeService } from 'src/app/services/activationcode.service';
import { downloadCSV } from 'src/app/services/download.service';
import { DashboardTableComponent } from 'src/app/shared/components/dashboard-table/dashboard-table.component';
import { TableDialogComponent } from 'src/app/shared/components/table-dialog/table-dialog.component';
import {
  createIncreaseUsageDialog,
  createResetDialog,
} from '../../../services/activation-code-factory';

@Component({
  selector: 'app-activationcodes',
  templateUrl: './activationcodes.component.html',
  styleUrls: ['./activationcodes.component.scss'],
})
export class ActivationcodesComponent implements OnInit, OnDestroy {
  @ViewChild(DashboardTableComponent)
  activationCodeTable!: DashboardTableComponent;

  public activationcodeData: BehaviorSubject<TableData> = new BehaviorSubject(
    undefined
  );

  public activationCodes: ActivationCode[] = [];

  public visibleActivationCodes: ActivationCode[] = [];

  public usedActivationCodes: number = 0;
  public activationCodeSum: number = 0;

  private activationcodeDataSubscription?: Subscription;

  public selectedCodes: string[] = [];

  constructor(
    private dialog: MatDialog,
    private activationcodeService: ActivationCodeService
  ) {}

  ngOnInit(): void {
    this.initializeActivationcodeTableData();
    this.activationcodeDataSubscription =
      this.activationcodeService.selectedTenantActivationCodes.subscribe(
        (codes: ActivationCode[]) => {
          this.activationCodes = codes.sort((a, b) => {
            return a.createdAt < b.createdAt ? 1 : -1;
          });
          this.visibleActivationCodes = this.activationCodes;
          this.initialSumOfCodeUsages();
          this.initialUsedCodesCounter();
          this.initializeActivationcodeTableData();
        }
      );
  }

  ngOnDestroy(): void {
    this.activationcodeDataSubscription?.unsubscribe();
  }

  private initialUsedCodesCounter() {
    if (this.visibleActivationCodes.length == 0) {
      this.usedActivationCodes = 0;
      return;
    }
    this.usedActivationCodes = this.visibleActivationCodes.filter(
      (code) => code.usagesCounter > 0
    ).length;
    //Code below is required if every usage should count
    // .filter(
    //   (activationCode) =>
    //     activationCode.usagesCounter !== undefined &&
    //     activationCode.usagesCounter !== null
    // )
    // .map((activationCode) => Number(activationCode.usagesCounter))
    // .reduce((prev, current) => prev + current);
  }

  private initialSumOfCodeUsages() {
    if (this.visibleActivationCodes.length == 0) {
      this.activationCodeSum = 0;
      return;
    }
    this.activationCodeSum = this.visibleActivationCodes.length;
    //Code below is required if every usage should count
    // .filter(
    //   (activationCode) =>
    //     activationCode.amountOfRemainingUses !== undefined &&
    //     activationCode.amountOfRemainingUses !== null
    // )
    // .filter(
    //   (activationCode) =>
    //     activationCode.usagesCounter !== undefined &&
    //     activationCode.usagesCounter !== null
    // )
    // .map(
    //   (activationCode) =>
    //     Number(activationCode.amountOfRemainingUses) +
    //     Number(activationCode.usagesCounter)
    // )
    // .reduce((prev, current) => prev + current);
  }

  public onSearchTermChange(event) {
    this.visibleActivationCodes = this.activationCodes.filter(
      (activationCode) => activationCode.code.startsWith(event)
    );
    if (event === '') {
      this.visibleActivationCodes = this.activationCodes;
    }
    this.initialSumOfCodeUsages();
    this.initialUsedCodesCounter();
    this.initializeActivationcodeTableData();
  }

  private formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  private initializeActivationcodeTableData() {
    this.activationcodeData.next({
      tableRows: this.visibleActivationCodes.map((code) => {
        return {
          data: [
            { text: code.code },
            { text: code.amountOfRemainingUses },
            {
              text: `${this.formatDate(
                code.validationDateFrom
              )} - ${this.formatDate(code.validationDateTo)}`,
            },
          ],
          entryId: code.code,
        } as TableRow;
      }),
      columnHeaders: [
        {
          title: 'admin.activationcode.table.title.code',
          filterable: false,
          sortable: false,
          markable: true,
        },
        {
          title: 'admin.activationcode.table.title.usages',
          filterable: false,
          sortable: false,
        },
        {
          title: 'admin.activationcode.table.title.active',
          filterable: false,
          sortable: false,
        },
      ],
      editable: false,
      itemDeletion: false,
      includesCustomButton: false,
      hasSingleCustomButton: false,
      pagination: true,
      hasTwoCustomButtons: true,
    });
  }

  resetCodes(event: any) {
    this.dialog
      .open(TableDialogComponent, { data: createResetDialog() })
      .afterClosed()
      .subscribe((response) => {
        if (response === 200) {
          this.activationcodeService.resetCode(event.data[0].text);
        }
      });
  }

  onSelectedOptionsChanged(event) {
    this.selectedCodes = event.selectedOptions;
  }

  resetSelectedCodes() {
    this.activationCodeTable.deSelectAllOptions();
    this.dialog
      .open(TableDialogComponent, { data: createResetDialog() })
      .afterClosed()
      .subscribe((response) => {
        if (response === 200) {
          if (this.selectedCodes.length > 0) {
            for (let code of this.selectedCodes) {
              this.activationcodeService.resetCode(code);
            }
          }
        }
      });
  }

  increaseUsages(event: any) {
    const increaseUsageKey = 'increage-usage';
    this.dialog
      .open(TableDialogComponent, {
        data: createIncreaseUsageDialog(increaseUsageKey),
      })
      .afterClosed()
      .subscribe((response) => {
        const countToIncrease = response.get(increaseUsageKey);
        const code = event.data[0].text;

        const activationCode = this.activationCodes.filter(
          (activationCode) => activationCode.code === code
        )[0];

        if (countToIncrease > 0) {
          activationCode.amountOfRemainingUses =
            activationCode.amountOfRemainingUses + Number(countToIncrease);
          this.activationcodeService.updateActivationCode(activationCode);
        }
      });
  }

  exportToCSV(): void {
    const csvFileName = 'activation-codes.csv';
    const data = this.convertToCSV(this.activationCodes);
    downloadCSV(csvFileName, data);
  }

  convertToCSV(data: ActivationCode[]): string {
    const separator = ','; // Separator character
    const excludedKeys = ['tenantID']; // Keys to exclude from CSV
    const keys = Object.keys(data[0]).filter(
      (key) => !excludedKeys.includes(key)
    ); // Filter keys excluding excludedKeys
    const header = keys.join(separator);
    const rows = data.map((item) => {
      return keys
        .map((key) => {
          if (key === 'validationDateFrom' || key === 'validationDateTo') {
            const date = new Date(item[key]);
            return this.formatDate(date);
          } else {
            return item[key];
          }
        })
        .join(separator);
    });

    return header + '\n' + rows.join('\n');
  }

  public activationcodeTableData(): Observable<TableData> {
    return this.activationcodeData.asObservable();
  }
}
