import { HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '../models/company';
import { TokenUtil } from '../utils/token-util';
import { ContentLoadingService } from './content-loading.service';
import { CompanyHttpService } from './http/company-http.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private companyHttpService: CompanyHttpService = inject(CompanyHttpService);
  private tenantService: TenantService = inject(TenantService);
  private msalService: MsalService = inject(MsalService);
  private contentLoadingService: ContentLoadingService = inject(
    ContentLoadingService
  );
  private translateService: TranslateService = inject(TranslateService);

  private _dashboardCompanies: BehaviorSubject<Company[]> = new BehaviorSubject<
    Company[]
  >([]);

  private tenantID: string = '';
  private currentLang = this.translateService.currentLang;

  private initial: boolean = true;

  constructor() {
    this.tenantService.selectedTenantId.subscribe((tenantID) => {
      if (!tenantID) {
        tenantID = TokenUtil.getTenantFromToken(
          localStorage.getItem('id-token')
        );
      }

      this.tenantID = tenantID;
      this.initCompanies();
    });
  }

  get dashboardCompanies() {
    if (
      this._dashboardCompanies === null ||
      this._dashboardCompanies.value.length === 0
    ) {
      this.initCompanies();
    }
    return this._dashboardCompanies.asObservable();
  }

  addCompany(companyName: string) {
    this.contentLoadingService.setLoadingState(true);
    this.companyHttpService
      .addCompany(
        TokenUtil.getTenantFromToken(localStorage.getItem('id-token')),
        companyName
      )
      .subscribe((response: HttpResponse<any>) => {
        const company: Company = {
          id: response.body['id'],
          name: companyName,
          tenantID: this.tenantID,
        };
        const updatedList: Company[] = this._dashboardCompanies.value;
        updatedList.push(company);
        this.setCompanyList(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  private setCompanyList(companies: Company[]) {
    this._dashboardCompanies.next(companies);
  }

  updateCompany(companyId, companyName) {
    this.contentLoadingService.setLoadingState(true);
    const company: Company = {
      id: companyId,
      name: companyName,
      tenantID: TokenUtil.getTenantFromToken(localStorage.getItem('id-token')),
    } as Company;
    this.companyHttpService
      .updateCompany(company)
      .subscribe((response: HttpResponse<any>) => {
        const updated: Company = {
          id: response.body['id'],
          name: companyName,
          tenantID: this.tenantID,
        };
        const updatedList: Company[] = this._dashboardCompanies.value;
        updatedList[updatedList.findIndex((value) => value.id === company.id)] =
          updated;
        this.setCompanyList(updatedList);
        this.contentLoadingService.setLoadingState(false);
      });
  }

  deleteCompany(companyID: string) {
    this.contentLoadingService.setLoadingState(true);
    this.companyHttpService.deleteCompany(companyID).subscribe((response) => {
      const updatedList: Company[] = this._dashboardCompanies.value.filter(
        (entry) => entry.id !== companyID
      );
      this.setCompanyList(updatedList);
      this.contentLoadingService.setLoadingState(false);
    });
  }

  initCompanies(): void {
    if (!this.initial) {
      return;
    }
    this.initial = false;
    if (localStorage.getItem('id-token') === null) {
      return;
    }
    var token = TokenUtil.getTenantFromToken(localStorage.getItem('id-token'));
    this.contentLoadingService.setLoadingState(true);

    this.companyHttpService
      .getCompanies(token)
      .pipe(
        tap((companies: Company[]) => {
          this._dashboardCompanies.next(companies);
        })
      )
      .subscribe((_) => {
        this.contentLoadingService.setLoadingState(false);
      });
  }

  public get(companyId: string): Observable<Company> {
    const companies = this._dashboardCompanies.value;

    const foundCompanies = companies.filter(
      (company) => company.id === companyId
    );
    if (foundCompanies) {
      return of(foundCompanies[0]);
    }
    return of({
      id: companyId,
      name: this.currentLang == 'en' ? 'Unknown' : 'Unbekannt',
      tenantID: '',
    });
  }
}
