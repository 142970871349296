import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './component/dashboard.component';
import { TabViewModule } from 'primeng/tabview';
import { DashboardTitleModule } from '../../components/dashboard-title/dashboard-title.module';
import { TrendsModule } from '../trends/trends.module';
import { OverviewModule } from '../overview/overview.module';
import { RouterModule } from '@angular/router';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    TabViewModule,
    DashboardTitleModule,
    TrendsModule,
    OverviewModule,
    RouterModule,
    DashboardRoutingModule,
  ],
})
export class DashboardModule {}
