import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIConst } from '../../models/enums/api.enum';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentationHttpService {
  private apiService: ApiService = inject(ApiService);

  public getSwaggerDocumentation(): Observable<any> {
    return this.apiService
      .get({
        path: APIConst.DOCUMENTATION,
        endp: environment.apiPath,
        observe: true,
      })
      .pipe(
        map((value) => value.body),
        map((documentation) => documentation)
      );
  }
}
